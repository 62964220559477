import {GenericAction} from '../../../../config/constant';
import {WorkReportState} from '../workReport.reducer';
import {workReportCreateInitialState} from './workReportCreate.reducer';
import {
  WorkReportCreateAction,
  WorkReportCreateFailedPayload,
  WorkReportCreateFetchPayload,
  WorkReportCreateSuccessPayload,
} from './workReportCreate.type';

export const workReportCreateHandler = {
  [WorkReportCreateAction.FETCH]: (
    state: WorkReportState,
    action: GenericAction<WorkReportCreateFetchPayload>,
  ): WorkReportState => ({
    ...state,
    workReportCreateLoading: true,
    workReportCreateParams: action.payload,
    action: WorkReportCreateAction.FETCH,
  }),
  [WorkReportCreateAction.SUCCESS]: (
    state: WorkReportState,
    action: GenericAction<WorkReportCreateSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportCreateParams: undefined,
    workReportCreateLoading: false,
    workReportCreateResponse: action.payload,
    action: WorkReportCreateAction.SUCCESS,
  }),
  [WorkReportCreateAction.FAILED]: (
    state: WorkReportState,
    action: GenericAction<WorkReportCreateFailedPayload>,
  ): WorkReportState => ({
    ...state,
    workReportCreateLoading: false,
    workReportCreateError: action.payload,
    action: WorkReportCreateAction.FAILED,
  }),
  [WorkReportCreateAction.RESET]: (
    state: WorkReportState,
  ): WorkReportState => ({
    ...state,
    ...workReportCreateInitialState,
    action: WorkReportCreateAction.RESET,
  }),
};
