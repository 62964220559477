import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailObservationCreateAction,
  MasterDataDetailObservationCreateFailedPayload,
  MasterDataDetailObservationCreateFetchPayload,
  MasterDataDetailObservationCreateSuccessPayload,
} from './masterDataDetailObservationCreate.type';

export const masterDataDetailObservationCreateAction = {
  fetch: createAction<MasterDataDetailObservationCreateFetchPayload>(
    MasterDataDetailObservationCreateAction.FETCH,
  ),
  success: createAction<MasterDataDetailObservationCreateSuccessPayload>(
    MasterDataDetailObservationCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailObservationCreateFailedPayload>(
    MasterDataDetailObservationCreateAction.FAILED,
  ),
  reset: createAction(MasterDataDetailObservationCreateAction.RESET),
};
