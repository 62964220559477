import {CommonError} from '../../../../../../config/constant';
import {MasterDataIndicatorValueFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataIndicatorValueCreateAction = {
  FETCH: 'MASTER_DATA_INDICATOR_VALUE_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_INDICATOR_VALUE_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_INDICATOR_VALUE_CREATE_FAILED',
  RESET: 'MASTER_DATA_INDICATOR_VALUE_CREATE_RESET',
};

export interface MasterDataIndicatorValueCreateInitialState {
  masterDataIndicatorValueCreateLoading: boolean;
  masterDataIndicatorValueCreateParams?: MasterDataIndicatorValueCreateFetchPayload;
  masterDataIndicatorValueCreateError?: MasterDataIndicatorValueCreateFailedPayload;
  masterDataIndicatorValueCreateResponse?: MasterDataIndicatorValueCreateSuccessPayload;
}

export type MasterDataIndicatorValueCreateFetchPayload = Omit<
  MasterDataIndicatorValueFormData,
  'id'
>[];

export type MasterDataIndicatorValueCreateFailedPayload = CommonError;

export interface MasterDataIndicatorValueCreateSuccessPayload {}
