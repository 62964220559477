//#region IMPORT
// Libraries
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../../user/usecases/user.reducer';
// Components
import {EvaluationReportSection} from '../sections';
// Data
import {PATH} from '../../../wrapper/entity';
import {BackOfficePermissionType} from '../../../user/entity/user.string.entity';
// Assets
import './EvaluationReportManagementWrapper.scss';
//#endregion

const EvaluationReportManagementWrapper: React.FC = () => {
  //#region GENERAL
  const {data}: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const permissions: BackOfficePermissionType | undefined =
    data?.role?.permissions;
  //#endregion

  if (!permissions?.otherAssignmentReport) return <Redirect to={PATH.HOME} />;
  return (
    <div className="evaluation-report-management-wrapper">
      <EvaluationReportSection
        isUserCanExport={
          permissions.otherAssignmentReport.havePermissionDownload
        }
      />
    </div>
  );
};

export default EvaluationReportManagementWrapper;
