import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataCategoryDeleteInitialState} from './masterDataCategoryDelete.reducer';
import {
  MasterDataCategoryDeleteAction,
  MasterDataCategoryDeleteFailedPayload,
  MasterDataCategoryDeleteFetchPayload,
  MasterDataCategoryDeleteSuccessPayload,
} from './masterDataCategoryDelete.type';

export const masterDataCategoryDeleteHandler = {
  [MasterDataCategoryDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryDeleteLoading: true,
    masterDataCategoryDeleteParams: action.payload,
    action: MasterDataCategoryDeleteAction.FETCH,
  }),
  [MasterDataCategoryDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryDeleteParams: undefined,
    masterDataCategoryDeleteLoading: false,
    masterDataCategoryDeleteResponse: action.payload,
    action: MasterDataCategoryDeleteAction.SUCCESS,
  }),
  [MasterDataCategoryDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryDeleteLoading: false,
    masterDataCategoryDeleteError: action.payload,
    action: MasterDataCategoryDeleteAction.FAILED,
  }),
  [MasterDataCategoryDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataCategoryDeleteInitialState,
    action: MasterDataCategoryDeleteAction.RESET,
  }),
};
