//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Icon from '@mdi/react';
import {mdiAccount, mdiPencil, mdiTrashCan} from '@mdi/js';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {UserRoleAccessDeleteAction} from '../../usecases/user.type';
import {userRoleAccessDeleteAction} from '../../usecases/userRoleAccessDelete/userRoleAccessDelete.action';
// Components
import {AlertDialog} from '../../../../components';
import {RoleTableWrapper} from '../../components';
// Data
import {PATH} from '../../../wrapper/entity';
import {RoleOverview} from '../../entity/user.string.entity';
// Assets
import './UserAccessManagementRoleDetail.scss';
import {IllustrationTrashImage} from '../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface NavigationParams {
  role: RoleOverview;
}
//#endregion

const UserAccessManagementRoleDetail: React.FC = () => {
  //#region GENERAL
  const history = useHistory();
  const dispatch = useDispatch();
  const userState = useSelector((state: AppState) => state.user, shallowEqual);
  const routeState = history.location.state as NavigationParams;

  const [isDeleteRole, setIsDeleteRole] = useState<string | undefined>(
    undefined,
  );
  const isLoadingDeleteRole = userState.userRoleAccessDeleteLoading;
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (_id: string) => {
    dispatch(userRoleAccessDeleteAction.fetch(_id));
  };
  //#endregion

  //#region HANDLE EDIT
  const handleEdit = () => {
    history.push(PATH.USER_ACCESS_EDIT_ROLE, {role: routeState.role});
  };
  //#endregion

  //#region ACTION HANDLER
  const userRoleActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserRoleAccessDeleteAction.SUCCESS, (): void => {
            history.goBack();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserRoleAccessDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userRoleAccessDeleteAction.reset());
          });
      });
    },
    [dispatch, history],
  );
  useEffect(() => {
    userRoleActionHandler(userState.action);
  }, [userRoleActionHandler, userState.action]);
  //#endregion

  return (
    <>
      <div className="user-access-management-role-detail">
        <div className="user-access-management-role-detail__header">
          <div className="user-access-management-role-detail__header--meta-info">
            <div className="role-detail__icon">
              <Icon path={mdiAccount} />
            </div>
            <div className="role-detail__label">
              {routeState.role.name || ''}
            </div>
          </div>
          <div className="user-access-management-role-detail__header--action">
            <div
              className="role-detail-action"
              onClick={() => setIsDeleteRole(routeState.role.id)}>
              <Icon path={mdiTrashCan} />
            </div>
            <div className="role-detail-action" onClick={handleEdit}>
              <Icon path={mdiPencil} />
            </div>
          </div>
        </div>
        <div className="user-access-management-role-detail__content">
          <RoleTableWrapper
            isDisabled
            roleValues={routeState.role.permissionType}
          />
        </div>
      </div>

      {isDeleteRole && (
        <AlertDialog
          onClose={() => setIsDeleteRole(undefined)}
          onCancel={() => setIsDeleteRole(undefined)}
          onConfirm={() => handleDelete(isDeleteRole)}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteRole}
          isLoading={isLoadingDeleteRole}
          image={<IllustrationTrashImage />}
          description={t(
            'Are you sure you want to delete the data?',
          )}></AlertDialog>
      )}
    </>
  );
};

export default UserAccessManagementRoleDetail;
