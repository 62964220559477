import {CommonError} from '../../../../config/constant';
import {WorkReportUploadFormData} from '../../entity/workReport.string.entity';

export const WorkReportUploadSupportAction = {
  FETCH: 'WORK_REPORT_UPLOAD_SUPPORT_FETCH',
  SUCCESS: 'WORK_REPORT_UPLOAD_SUPPORT_SUCCESS',
  FAILED: 'WORK_REPORT_UPLOAD_SUPPORT_FAILED',
  RESET: 'WORK_REPORT_UPLOAD_SUPPORT_RESET',
};

export interface WorkReportUploadSupportInitialState {
  workReportUploadSupportData: string;
  workReportUploadSupportLoading: boolean;
  workReportUploadSupportParams?: WorkReportUploadSupportFetchPayload;
  workReportUploadSupportError?: WorkReportUploadSupportFailedPayload;
  workReportUploadSupportResponse?: WorkReportUploadSupportSuccessPayload;
}

export type WorkReportUploadSupportResponse = {
  status: string;
  info?: number;
  data: string;
};

export type WorkReportUploadSupportFetchPayload = WorkReportUploadFormData;

export type WorkReportUploadSupportFailedPayload = CommonError;

export type WorkReportUploadSupportSuccessPayload = WorkReportUploadSupportResponse;
