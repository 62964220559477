import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationOptionListAction,
  MasterDataObservationOptionListFailedPayload,
  MasterDataObservationOptionListFetchPayload,
  MasterDataObservationOptionListSuccessPayload,
  MasterDataObservationOptionListUpdatePayload,
} from './masterDataObservationOptionList.type';

export const masterDataObservationOptionListAction = {
  fetch: createAction<MasterDataObservationOptionListFetchPayload>(
    MasterDataObservationOptionListAction.FETCH,
  ),
  success: createAction<MasterDataObservationOptionListSuccessPayload>(
    MasterDataObservationOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationOptionListFailedPayload>(
    MasterDataObservationOptionListAction.FAILED,
  ),
  update: createAction<MasterDataObservationOptionListUpdatePayload>(
    MasterDataObservationOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataObservationOptionListAction.RESET),
};
