import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataIndicatorValueDeleteAction,
  MasterDataIndicatorValueDeleteFailedPayload,
  MasterDataIndicatorValueDeleteFetchPayload,
  MasterDataIndicatorValueDeleteSuccessPayload,
} from './masterDataIndicatorValueDelete.type';

export const masterDataIndicatorValueDeleteAction = {
  fetch: createAction<MasterDataIndicatorValueDeleteFetchPayload>(
    MasterDataIndicatorValueDeleteAction.FETCH,
  ),
  success: createAction<MasterDataIndicatorValueDeleteSuccessPayload>(
    MasterDataIndicatorValueDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataIndicatorValueDeleteFailedPayload>(
    MasterDataIndicatorValueDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataIndicatorValueDeleteAction.RESET),
};
