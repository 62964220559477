import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDivisionOptionListAction,
  MasterDataDivisionOptionListFailedPayload,
  MasterDataDivisionOptionListFetchPayload,
  MasterDataDivisionOptionListSuccessPayload,
  MasterDataDivisionOptionListUpdatePayload,
} from './masterDataDivisionOptionList.type';

export const masterDataDivisionOptionListAction = {
  fetch: createAction<MasterDataDivisionOptionListFetchPayload>(
    MasterDataDivisionOptionListAction.FETCH,
  ),
  success: createAction<MasterDataDivisionOptionListSuccessPayload>(
    MasterDataDivisionOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDivisionOptionListFailedPayload>(
    MasterDataDivisionOptionListAction.FAILED,
  ),
  update: createAction<MasterDataDivisionOptionListUpdatePayload>(
    MasterDataDivisionOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataDivisionOptionListAction.RESET),
};
