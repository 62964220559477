//#region IMPORT
// Libraries
import React from 'react';
import {Divider, IconButton} from '@mui/material';
// Utils
import moment from 'moment';
import t from '../../../../../../../../lang';
import {colors} from '../../../../../../../../config/_theme';
// Data
import {MasterDataAssignmentLetter} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataAssignmentLetterManagementDetailHeader.component.style.scss';
import {
  IconArrowUpCircle,
  IconCalendar,
  IconUserProfile,
} from '../../../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  data?: MasterDataAssignmentLetter;
  pageNumber: number;
  numPages: number;
  scale: number;
  onIncreaseScale?(): void;
  onDecreaseScale?(): void;
  onChangePage?(e: React.ChangeEvent<HTMLInputElement>): void;
}
//#endregion

const MasterDataAssignmentLetterManagementDetailHeader: React.FC<Props> = ({
  data,
  pageNumber,
  numPages,
  scale,
  onIncreaseScale,
  onDecreaseScale,
  onChangePage,
}: Props) => {
  const date = moment(data?.publishDate).format('DD/MM/YYYY');
  return (
    <div className="assignment-letter-detail-header">
      <div className="assignment-letter-detail-header__detail">
        <div className="assignment-letter-detail-header__detail-row">
          <div className="assignment-letter-detail-header__detail-row--title">
            {data?.number}
          </div>
          <div className="assignment-letter-detail-header__detail-items">
            <IconArrowUpCircle
              fill={
                data?.isActive ? colors.secondary.green : colors.secondary.red
              }
            />
            <span className={`meta-status${data?.isActive ? '--active' : ''}`}>
              {data?.isActive ? t('Applicable') : t('Not Applicable')}
            </span>
          </div>
        </div>
        <div className="assignment-letter-detail-header__detail-row">
          <div className="assignment-letter-detail-header__detail-items">
            {<IconCalendar />}
            <span>{date}</span>
          </div>
          <Divider orientation="vertical" />
          <div className="assignment-letter-detail-header__detail-items">
            {<IconUserProfile />}
            <span>{data?.roleName}</span>
          </div>
        </div>
      </div>
      <div className="assignment-letter-detail-header__action">
        <div className="assignment-letter-detail-header__action--page">
          <input
            value={pageNumber}
            onChange={onChangePage}
            type="text"
            min={1}
            max={numPages}
            name="pageNumber"
          />
          <div>/</div>
          <div>{numPages}</div>
        </div>
        <Divider orientation="vertical" />
        <div className="assignment-letter-detail-header__action--scale">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDecreaseScale && onDecreaseScale();
            }}>
            -
          </IconButton>
          <input value={`${scale}%`} type="text" disabled name="scale" />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onIncreaseScale && onIncreaseScale();
            }}>
            +
          </IconButton>
        </div>
      </div>
      <div className="assignment-letter-detail-header__action"></div>
    </div>
  );
};

export default MasterDataAssignmentLetterManagementDetailHeader;
