import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {post} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataDirectorateCreateAction} from './masterDataDirectorateCreate.action';
import {
  MasterDataDirectorateCreateAction,
  MasterDataDirectorateCreateFetchPayload,
  MasterDataDirectorateCreateSuccessPayload,
} from './masterDataDirectorateCreate.type';

function* workerFetch(
  action: GenericAction<MasterDataDirectorateCreateFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);

  try {
    const res: AxiosResponse<MasterDataDirectorateCreateSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/directorate/save`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataDirectorateCreateAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(masterDataDirectorateCreateAction.failed(e.response.data));
  }
}

const masterDataDirectorateCreateWatcher = [
  takeLatest(MasterDataDirectorateCreateAction.FETCH, workerFetch),
];

export default masterDataDirectorateCreateWatcher;
