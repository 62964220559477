import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataAssignmentLetterEditAction,
  MasterDataAssignmentLetterEditFailedPayload,
  MasterDataAssignmentLetterEditFetchPayload,
  MasterDataAssignmentLetterEditSuccessPayload,
} from './masterDataAssignmentLetterEdit.type';

export const masterDataAssignmentLetterEditAction = {
  fetch: createAction<MasterDataAssignmentLetterEditFetchPayload>(
    MasterDataAssignmentLetterEditAction.FETCH,
  ),
  success: createAction<MasterDataAssignmentLetterEditSuccessPayload>(
    MasterDataAssignmentLetterEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataAssignmentLetterEditFailedPayload>(
    MasterDataAssignmentLetterEditAction.FAILED,
  ),
  reset: createAction(MasterDataAssignmentLetterEditAction.RESET),
};
