import {CommonError} from '../../../../../../config/constant';
import {MasterDataDetailObservation} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailObservationOptionListAction = {
  FETCH: 'MASTER_DATA_DETAIL_OBSERVATION_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_OBSERVATION_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_OBSERVATION_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_DETAIL_OBSERVATION_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_DETAIL_OBSERVATION_OPTION_LIST_UPDATE',
};

export interface MasterDataDetailObservationOptionListInitialState {
  masterDataDetailObservationOptionListLoading: boolean;
  masterDataDetailObservationOptionListData: MasterDataDetailObservation[];
  masterDataDetailObservationOptionListError?: MasterDataDetailObservationOptionListFailedPayload;
  masterDataDetailObservationOptionListParams?: MasterDataDetailObservationOptionListFetchPayload;
  masterDataDetailObservationOptionListResponse?: MasterDataDetailObservationOptionListSuccessPayload;
}

export type MasterDataDetailObservationOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataDetailObservation[];
};

export type MasterDataDetailObservationOptionListFailedPayload = CommonError;

export type MasterDataDetailObservationOptionListSuccessPayload = MasterDataDetailObservationOptionListResponse;

export type MasterDataDetailObservationOptionListUpdatePayload = MasterDataDetailObservationOptionListResponse;

export interface MasterDataDetailObservationOptionListFetchPayload {
  assignmentType: string;
}
