//#region IMPORT
// Libraries
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
// Utils
import {workReportExportAllAction} from '../../../usecases/workReport.action';
// Components
import {WorkReportDownloadForm} from '../../../components';
// Data
import {
  WorkReportDownloadFormData,
  WorkReportType,
} from '../../../entity/workReport.string.entity';
// Assets
import './WorkReportDownloadAllFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  isSPM?: boolean;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const WorkReportDownloadAllFormContainer: React.FC<Props> = ({
  isSPM,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: WorkReportDownloadFormData = {
    reportType: isSPM ? undefined : WorkReportType.MONTHLY,
    startMonth: undefined,
    endMonth: undefined,
  };
  //#endregion

  //#region EXPORT ALL
  const handleExportAll = useCallback(
    (_workReport: WorkReportDownloadFormData) => {
      try {
        const payload = {
          ..._workReport,
          startMonth: moment(_workReport?.startMonth, 'MMMM YYYY').format(
            'YYYY-MMMM',
          ),
          endMonth: moment(_workReport?.endMonth, 'MMMM YYYY').format(
            'YYYY-MMMM',
          ),
        };
        dispatch(workReportExportAllAction.fetch(payload));
      } catch (error) {
        console.log({error});
      }
    },
    [dispatch],
  );
  //#endregion

  //#region RENDER
  return (
    <div className="work-report-download-all-form-container">
      <WorkReportDownloadForm
        initialValues={initialValues}
        isSPM={isSPM}
        isLoading={isLoading}
        handleDownload={(_workReport) => handleExportAll(_workReport)}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default WorkReportDownloadAllFormContainer;
