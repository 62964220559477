import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDepartmentEditAction,
  MasterDataDepartmentEditFailedPayload,
  MasterDataDepartmentEditFetchPayload,
  MasterDataDepartmentEditSuccessPayload,
} from './masterDataDepartmentEdit.type';

export const masterDataDepartmentEditAction = {
  fetch: createAction<MasterDataDepartmentEditFetchPayload>(
    MasterDataDepartmentEditAction.FETCH,
  ),
  success: createAction<MasterDataDepartmentEditSuccessPayload>(
    MasterDataDepartmentEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataDepartmentEditFailedPayload>(
    MasterDataDepartmentEditAction.FAILED,
  ),
  reset: createAction(MasterDataDepartmentEditAction.RESET),
};
