//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
// Utils
import {FollowUpPICCompleteFetchPayload} from '../../../usecases/followUp.type';
import {followUpPICCompleteAction} from '../../../usecases/followUp.action';
// Components
import FollowUpRejectedForm from './components/FollowUpRejectedForm';
// Data
import {
  FollowUpFormData,
  FollowUp,
} from '../../../entity/followUp.string.entity';
// Assets
import './FollowUpRejectedFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const FollowUpRejectedFormContainer: React.FC<Props> = ({
  data,
  isLoading = false,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const initialValues: FollowUpFormData = {
    id: data?.id || '',
    fixingDate: data?.fixingDate || '',
    file: undefined,
  };
  //#endregion

  //#region HANDLE REJECT FOLLOW UP
  const handleRejectFollowUp = async (followUp: FollowUpFormData) => {
    const formattedParams: FollowUpPICCompleteFetchPayload = {
      ...followUp,
      fixingDate: moment(followUp.fixingDate, 'YYYY-MM-DD').format(
        'YYYY-MM-DD',
      ),
    };
    dispatch(followUpPICCompleteAction.fetch(formattedParams));
  };
  //#endregion

  //#region
  return (
    <div className="follow-up-rejected-form-container">
      <FollowUpRejectedForm
        findingDueDate={data?.findingDueDate}
        findingDate={data?.findingDate}
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitUser={handleRejectFollowUp}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default FollowUpRejectedFormContainer;
