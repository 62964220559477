import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataReviewIndicatorOptionListAction} from './masterDataReviewIndicatorOptionList.action';
import {
  MasterDataReviewIndicatorOptionListAction,
  MasterDataReviewIndicatorOptionListFetchPayload,
  MasterDataReviewIndicatorOptionListResponse,
  MasterDataReviewIndicatorOptionListSuccessPayload,
} from './masterDataReviewIndicatorOptionList.type';

function* workerFetch(
  action: GenericAction<MasterDataReviewIndicatorOptionListFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataReviewIndicatorOptionListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/review-indicator/ol`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataReviewIndicatorOptionListAction.success(
          res.data as MasterDataReviewIndicatorOptionListResponse,
        ),
      );
      yield put(
        masterDataReviewIndicatorOptionListAction.update(
          res.data as MasterDataReviewIndicatorOptionListResponse,
        ),
      );
    } else {
      yield put(
        masterDataReviewIndicatorOptionListAction.failed(
          res.data as CommonError,
        ),
      );
    }
  } catch (e) {
    yield put(
      masterDataReviewIndicatorOptionListAction.failed(e as CommonError),
    );
  }
}

const masterDataReviewIndicatorOptionListWatcher = [
  takeLatest(MasterDataReviewIndicatorOptionListAction.FETCH, workerFetch),
];

export default masterDataReviewIndicatorOptionListWatcher;
