//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
// Components
import {MButton, MTextField} from '../../../../../../../../components';
// Data
import {MasterDataWorkInstructionFormData} from '../../../../../../entity/masterData.string.entity';
// Assets
import './WorkInstructionForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: MasterDataWorkInstructionFormData;
  handleCancel(): void;
  handleSubmitUser(_user: MasterDataWorkInstructionFormData): void;
}
//#endregion

const WorkInstructionForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitUser,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const isEdit = !!initialValues?.id;
  //#endregion

  //#region VALIDATION
  const userAccessValidation = Yup.object().shape({
    name: Yup.string().required(t('Title is required.')),
    workInstruction: Yup.string().required(t('Work Instruction is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="work-instruction-form">
      <div className="work-instruction-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={userAccessValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitUser}>
          {({handleSubmit, isValid}): React.ReactElement => (
            <>
              <div className="work-instruction-form__section">
                <div className="work-instruction-form__section--field">
                  <div className="input-label">{t('Title')}</div>
                  <Field
                    disabled={isEdit || isLoading}
                    className="user-input"
                    name="name"
                    placeholder={t('Enter Title')}
                    component={MTextField}
                  />
                </div>
                <div className="work-instruction-form__section--field">
                  <div className="input-label">{t('Work Instruction')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    isTextArea={true}
                    textFieldRows={10}
                    name="workInstruction"
                    placeholder={t('Enter Work Instruction')}
                    component={MTextField}
                  />
                </div>
              </div>

              <div className="work-instruction-form__form--action">
                <div className="work-instruction-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="work-instruction-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={!isEdit ? t('Add') : t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default WorkInstructionForm;
