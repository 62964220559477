/* eslint-disable max-lines */
import {Moment} from 'moment';
import {SelectItemType} from '../../../components/MSelect/MSelect.component';

//#region MASTER DATA MANAGEMENT MENU
export enum MasterDataManagementType {
  OPERATIONAL = 'Operational',
  FORM = 'Form',
  OFFICE = 'Office',
}
export enum MasterDataManagementData {
  RUTE = 'Rute',
  BUS = 'Bus',
  HALTE = 'Halte',
  BUSBRT = 'Bus BRT',
  BUSBRTTENGAH = 'Bus BRT Tengah',
  BUSBRTUJUNG = 'Bus BRT Ujung',
  BUSNONBRT = 'Bus Non BRT',
  BUSNONBRTTENGAH = 'Bus Non BRT Tengah',
  BUSNONBRTUJUNG = 'Bus Non BRT Ujung',
  BUSKECIL = 'Bus Kecil',
  BUSKECILTENGAH = 'Bus Kecil Tengah',
  BUSKECILUJUNG = 'Bus Kecil Ujung',
  TRANSCARE = 'Transcare',
  ORGANIZATION = 'Daftar Organisasi',
  SURATTUGAS = 'Surat Tugas',
  INSTRUKSIKERJA = 'Instruksi Kerja',
}

//CHECK LOCATION
export enum CheckLocation {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE',
}

//OBSERVATION VALUE TYPE
export enum ObservationValueType {
  MCHOICE = 'MCHOICE',
  MSELECTION = 'MSELECTION',
}

export interface MasterDataManagement {
  id: string;
  name: MasterDataManagementData;
  type: MasterDataManagementType;
  route: string;
  dataCount: number;
  tabValue?: number;
}
//#endregion
//#region FILE UPLOAD
export interface FileUpload {
  base64?: string | unknown;
  fileLength?: number;
  fileName?: string;
}
//#endregion
//#region FORM
//#region FORM CONTROLLER
export interface MasterDataFormController {
  id: string;
  isNote: boolean;
  assignmentTypeId: string;
  categoryId: string;
  categoryName: string;
  basicServiceTypeId: string;
  basicServiceTypeName: string;
  reviewIndicatorId: string;
  reviewIndicatorName: string;
  observationId: string;
  observationName: string;
  detailIndicator: MasterDataDetailIndicator[];
  indicatorValue: MasterDataIndicatorValue[];
  detailObservation: MasterDataDetailObservation[];
  observationValue: MasterDataObservationValue[];
  checkLocation: CheckLocation;
  observationValueType: ObservationValueType;
}
export interface MasterDataFormControllerFormData {
  id?: string;
  assignmentTypeId: string;
  checkLocation: CheckLocation;
  categoryId?: string;
  basicServiceTypeId?: string;
  isNote: boolean;
  reviewIndicatorId?: string;
  detailIndicator?: Pick<MasterDataDetailIndicator, 'id'>[];
  indicatorValue?: Pick<MasterDataIndicatorValue, 'id'>[];
  observationId?: string;
  detailObservation?: Pick<MasterDataDetailObservation, 'id'>[];
  observationValueType?: string | ObservationValueType;
  observationValue?: Pick<MasterDataObservationValue, 'id'>[];
  description?: string;
  question?: string;
}
export interface MasterDataFormControllerFormValues {
  id?: string;
  assignmentTypeId: string;
  checkLocation: CheckLocation;
  categoryId?: string;
  basicServiceTypeId?: string;
  isNote: IMPORTANTNOTESTATUS;
  reviewIndicatorId?: string;
  detailIndicator?: MasterDataDetailIndicator[];
  indicatorValue?: MasterDataIndicatorValue[];
  observationId?: string;
  detailObservation?: MasterDataDetailIndicator[];
  observationValueType?: string | ObservationValueType;
  observationValue?: MasterDataObservationValue[];
  selectedDetailIndicator?: string[];
  selectedIndicatorValue?: string[];
  selectedDetailObservation?: string[];
  selectedObservationValue?: string[];
  description?: string;
  question?: string;
}

export enum IMPORTANTNOTESTATUS {
  EXIST = 'EXIST',
  NOT_EXIST = 'NOT_EXIST',
}

export type MasterDataFormControllerOrderByType =
  | 'categoryName'
  | 'basicServiceTypeName'
  | 'reviewIndicatorName'
  | 'detailIndicator'
  | 'indicatorValue';
//#endregion
//#region CATEGORY
export interface MasterDataCategory {
  id: string;
  name: string;
  assignmentType: string;
}
export interface MasterDataCategoryFormData
  extends Omit<MasterDataCategory, 'id'> {
  id?: string;
}
export type MasterDataCategoryOrderByType = 'name';
//#endregion
//#region BASIC SERVICE TYPE
export interface MasterDataBasicServiceType {
  id: string;
  name: string;
  assignmentType: string;
}
export interface MasterDataBasicServiceTypeFormData
  extends Omit<MasterDataBasicServiceType, 'id'> {
  id?: string;
}
export type MasterDataBasicServiceTypeOrderByType = 'name';
//#endregion
//#region REVIEW INDICATOR
export interface MasterDataReviewIndicator {
  id: string;
  name: string;
  departmentId: string;
  roleId: string;
  sla: number;
  spmWeight: number;
  target: number;
  departmentName: string;
  roleName: string;
  assignmentType: string;
}
export type MasterDataReviewIndicatorFormData = Omit<
  MasterDataReviewIndicator,
  'roleName' | 'departmentName'
>;
export type MasterDataReviewIndicatorOrderByType = 'name';
//#endregion
//#region DETAIL INDICATOR
export interface MasterDataDetailIndicator {
  id: string;
  name: string;
  assignmentType: string;
}
export interface MasterDataDetailIndicatorFormData
  extends Omit<MasterDataDetailIndicator, 'id'> {
  id?: string;
}
export type MasterDataDetailIndicatorOrderByType = 'name';
//#endregion
//#region INDICATOR VALUE
export interface MasterDataIndicatorValue {
  id: string;
  name: string;
  isPositiveValue: boolean;
  assignmentType: string;
}
export interface MasterDataIndicatorValueFormData
  extends Omit<MasterDataIndicatorValue, 'id' | 'isPositiveValue'> {
  id?: string;
}
export type MasterDataIndicatorValueOrderByType = 'name' | 'isPositiveValue';
//#endregion

//#region OBSERVATION
export interface MasterDataObservation {
  id: string;
  name: string;
  assignmentType: string;
}
export interface MasterDataObservationFormData
  extends Omit<MasterDataObservation, 'id'> {
  id?: string;
}

export type MasterDataObservationOrderByType = 'name';
//#endregion

//#region DETAIL OBSERVATION
export interface MasterDataDetailObservation {
  id: string;
  name: string;
  assignmentType: string;
}

export interface MasterDataDetailObservationFormData
  extends Omit<MasterDataDetailObservation, 'id'> {
  id?: string;
}
export type MasterDataDetailObservationOrderByType = 'name';
//#endregion

//#region OBSERVATION VALUE
export interface MasterDataObservationValue {
  id: string;
  name: string;
  assignmentType: string;
  isPositiveValue?: boolean;
}

export interface MasterDataObservationValueFormData
  extends Omit<MasterDataObservationValue, 'id'> {
  id?: string;
}
export type MasterDataObservationValueOrderByType = 'name' | 'isPositiveValue';
//#endregion

//#endregion

//#region MASTER DATA OFFICE

//#region DIRECTORATE
export interface MasterDataDirectorate {
  id: string;
  name: string;
  assignmentType?: string;
}
export interface MasterDataDirectorateFormData {
  id?: string;
  name: string;
}
export type MasterDataDirectorateOrderByType = 'name';
//#endregion

//#region DIVISION
export interface MasterDataDivision {
  id: string;
  name: string;
  directorateId: string;
  directorateName: string;
}

export interface MasterDataDivisionOption
  extends Omit<MasterDataDivision, 'directorateId' | 'directorateName'> {
  assignmentType?: string;
}

export type MasterDataDivisionOrderByType = 'name' | 'directorateName';
//#endregion
//#region DEPARTMENT
export interface MasterDataDepartment {
  id: string;
  name: string;
  divisionId: string;
  divisionName: string;
  directorateId: string;
  directorateName: string;
}

export interface MasterDataDepartmentOption
  extends Pick<MasterDataDepartment, 'id' | 'name'> {
  assignmentType: string;
}
export interface MasterDataDepartmentFormData {
  id?: string;
  name: string;
}
export type MasterDataDepartmentOrderByType =
  | 'name'
  | 'divisionName'
  | 'directorateName';
//#endregion
//#region WORK INSTRUCTION
export interface MasterDataWorkInstruction {
  id: string;
  name: string;
  workInstruction: string;
}
export interface MasterDataWorkInstructionFormData {
  id?: string;
  name: string;
  workInstruction: string;
}
//#endregion
//#region ASSIGNMENT LETTER
export interface MasterDataAssignmentLetter {
  id: string;
  number: string;
  publishDate: string | number;
  roleId: string;
  roleName: string;
  isActive: boolean;
  file: string;
  fileName: string;
}
export interface MasterDataAssignmentLetterFormData {
  id?: string;
  number: string;
  publishDate: Moment | string;
  roleId: string;
  file?: File | FileUpload | string;
}
export type MasterDataAssignmentLetterOrderByType =
  | 'number'
  | 'publishDate'
  | 'roleName'
  | 'isActive';
//#endregion
//#region BUS
export interface MasterDataBus {
  id: string;
  bodyNumber: string;
  busType: string;
  operator?: string;
  pool?: string;
}

export interface IMasterDataBusForm {
  id?: string;
  bodyNumber: string;
  operator: string;
  busType: string;
  pool: string;
  isActive: number;
}
export type MasterDataBusOrderByType = 'bodyNumber' | 'busType';
//#endregion
//#region RUTE BRT

export enum MasterDataRouteAssignmentType {
  BRT = 'BUSBRT',
  NBRT = 'BUSNONBRT',
  IBK = 'BUSKECIL',
}

export const MasterDataRouteAssignmentTypeArr: SelectItemType[] = [
  {
    label: 'Bus BRT',
    value: MasterDataRouteAssignmentType.BRT,
  },
  {
    label: 'Bus Non BRT',
    value: MasterDataRouteAssignmentType.NBRT,
  },
  {
    label: 'Bus Kecil',
    value: MasterDataRouteAssignmentType.IBK,
  },
];
export interface MasterDataRute {
  id: string;
  corridor: string;
  route: string;
  routeCode: string;
  busStop: string;
  busStopCode: string;
  assignmentType: string;
}

export interface IMasterDataRouteForm {
  id?: string;
  corridor: string;
  assignmentType: string;
  name: string;
  busStop: string;
  busStopCode: string;
}

export type MasterDataRuteOrderByType =
  | 'corridor'
  | 'route'
  | 'routeCode'
  | 'busStop'
  | 'busStopCode';
//#endregion
//#region COUNTER
export interface MasterDataCounter {
  route: number;
  bus: number;
  busStop: number;
  busBrtMiddle: number;
  busBrtPeak: number;
  busNonBrtMiddle: number;
  busNonBrtPeak: number;
  busMiniMiddle: number;
  busMiniPeak: number;
  transcare: number;
  organization: number;
  assignmentLetter: number;
  workInstruction: number;
}
//#endregion
//#endregion
