import {createAction} from '@reduxjs/toolkit';
import {
  EvaluationReportExportAction,
  EvaluationReportExportFailedPayload,
  EvaluationReportExportFetchPayload,
  EvaluationReportExportSuccessPayload,
} from './evaluationReportExport.type';

export const evaluationReportExportAction = {
  fetch: createAction<EvaluationReportExportFetchPayload>(
    EvaluationReportExportAction.FETCH,
  ),
  success: createAction<EvaluationReportExportSuccessPayload>(
    EvaluationReportExportAction.SUCCESS,
  ),
  failed: createAction<EvaluationReportExportFailedPayload>(
    EvaluationReportExportAction.FAILED,
  ),
  reset: createAction(EvaluationReportExportAction.RESET),
};
