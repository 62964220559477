import {connectRouter, LocationChangeAction} from 'connected-react-router';
import {AnyAction, ReducersMapObject} from 'redux';
import {History} from 'history';
import {AppState} from './Interface';
import wrapper from '../modules/wrapper/usecases/wrapper.reducer';
import user from '../modules/user/usecases/user.reducer';
import followUp from '../modules/followUp/usecases/followUp.reducer';
import masterData from '../modules/masterData/usecases/masterData.reducer';
import workReport from '../modules/workReport/usecases/workReport.reducer';
import dashboard from '../modules/dashboard/usecases/dashboard.reducer';
import notification from '../modules/notification/usecases/notification.reducer';
import evaluationReport from '../modules/evaluationReport/usecases/evaluationReport.reducer';

const Reducer = (
  history: History,
): ReducersMapObject<AppState, AnyAction & LocationChangeAction> => ({
  router: connectRouter(history),
  wrapper,
  user,
  masterData,
  followUp,
  workReport,
  dashboard,
  notification,
  evaluationReport,
});

export default Reducer;
