import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDivisionDeleteAction,
  MasterDataDivisionDeleteFailedPayload,
  MasterDataDivisionDeleteFetchPayload,
  MasterDataDivisionDeleteSuccessPayload,
} from './masterDataDivisionDelete.type';

export const masterDataDivisionDeleteAction = {
  fetch: createAction<MasterDataDivisionDeleteFetchPayload>(
    MasterDataDivisionDeleteAction.FETCH,
  ),
  success: createAction<MasterDataDivisionDeleteSuccessPayload>(
    MasterDataDivisionDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataDivisionDeleteFailedPayload>(
    MasterDataDivisionDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataDivisionDeleteAction.RESET),
};
