//#region IMPORT
// Libraries
import React from 'react';
// Data
import {EvaluationReportPreviewFindings} from '../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportCardContent.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isDetail?: boolean;
  contentData?: EvaluationReportPreviewFindings;
}
//#endregion

const EvaluationReportCardContent: React.FC<Props> = ({
  isDetail,
  contentData,
}: Props) => {
  return (
    <div className="evaluation-report-card-content">
      <div
        className={`evaluation-report-card-content__condition${
          isDetail ? '' : '-list'
        }`}>
        <div className="evaluation-report-card-content__condition-title">
          {contentData?.name}
        </div>
        <div className="evaluation-report-card-content__condition-content">
          <div className="evaluation-report-card-content__condition-content--desc">
            {typeof contentData?.value === 'string' ? (
              contentData?.value
            ) : contentData?.value && contentData?.value?.length > 1 ? (
              <div className="evaluation-report-card-content__condition-content--desc-list">
                <ul>
                  {contentData?.value.map((item, idx) => (
                    <li key={`${contentData?.name}-${idx}`}>{item}</li>
                  ))}
                </ul>
              </div>
            ) : (
              contentData?.value && contentData?.value[0]
            )}
          </div>
        </div>
      </div>
      {contentData?.image && (
        <div className="evaluation-report-card-content__findings">
          <div
            className={`evaluation-report-card-content__findings-image${
              isDetail ? '' : '-list'
            }`}>
            <img src={contentData?.image} />
          </div>
        </div>
      )}
    </div>
  );
};
export default EvaluationReportCardContent;
