import React from 'react';
import {Checkbox, TableCell, TableRow} from '@mui/material';
import {colors} from '../../../../config/_theme';
import {RolePermission} from '../../entity/user.string.entity';
import {
  IconCheckboxFilled,
  IconCheckboxOutline,
} from '../../../../assets/icons';
import './RoleTableBodyItem.component.style.scss';

interface Props {
  label: string;
  isDisabled?: boolean;
  valueType?: 'standard' | 'single' | 'download' | 'export';
  // Disable any since value obtained using object literal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionValues?: any;
  onClickCheckboxSingleValue?(value: boolean): void;
  onClickCheckbox?(permissionValue: RolePermission): void;
}

const RoleTableBodyItem: React.FC<Props> = ({
  label,
  isDisabled,
  permissionValues,
  valueType,
  onClickCheckbox,
  onClickCheckboxSingleValue,
}: Props) => {
  if (valueType === 'single')
    return (
      <TableRow>
        <TableCell className="item-data__cell role-table-body-item__page">
          {label}
        </TableCell>
        <TableCell className="item-data__cell" align="center">
          <Checkbox
            onChange={(_, checked: boolean) =>
              onClickCheckboxSingleValue && onClickCheckboxSingleValue(checked)
            }
            icon={<IconCheckboxOutline />}
            checkedIcon={<IconCheckboxFilled fill={colors.primary.blue} />}
            checked={permissionValues}
            disabled={isDisabled}
          />
        </TableCell>
      </TableRow>
    );
  return (
    <TableRow>
      <TableCell className="item-data__cell role-table-body-item__page">
        {label}
      </TableCell>
      {!!permissionValues && 'havePermissionCreate' in permissionValues && (
        <TableCell className="item-data__cell" align="center">
          <Checkbox
            onChange={(_, checked: boolean) => {
              onClickCheckbox &&
                onClickCheckbox({
                  ...permissionValues,
                  havePermissionCreate: checked,
                });
            }}
            icon={<IconCheckboxOutline />}
            checkedIcon={<IconCheckboxFilled fill={colors.primary.blue} />}
            checked={permissionValues?.havePermissionCreate}
            disabled={isDisabled}
          />
        </TableCell>
      )}
      {!!permissionValues && 'havePermissionRead' in permissionValues && (
        <TableCell className="item-data__cell" align="center">
          <Checkbox
            onChange={(_, checked: boolean) =>
              onClickCheckbox &&
              onClickCheckbox({
                ...permissionValues,
                havePermissionRead: checked,
              })
            }
            icon={<IconCheckboxOutline />}
            checkedIcon={<IconCheckboxFilled fill={colors.primary.blue} />}
            checked={permissionValues?.havePermissionRead}
            disabled={isDisabled}
          />
        </TableCell>
      )}
      {!!permissionValues && 'havePermissionUpdate' in permissionValues && (
        <TableCell className="item-data__cell" align="center">
          <Checkbox
            disabled={isDisabled}
            onChange={(_, checked: boolean) =>
              onClickCheckbox &&
              onClickCheckbox({
                ...permissionValues,
                havePermissionUpdate: checked,
              })
            }
            icon={<IconCheckboxOutline />}
            checkedIcon={<IconCheckboxFilled fill={colors.primary.blue} />}
            checked={permissionValues?.havePermissionUpdate}
          />
        </TableCell>
      )}
      {!!permissionValues && 'havePermissionDelete' in permissionValues && (
        <TableCell className="item-data__cell" align="center">
          <Checkbox
            onChange={(_, checked: boolean) =>
              onClickCheckbox &&
              onClickCheckbox({
                ...permissionValues,
                havePermissionDelete: checked,
              })
            }
            disabled={isDisabled}
            icon={<IconCheckboxOutline />}
            checkedIcon={<IconCheckboxFilled fill={colors.primary.blue} />}
            checked={permissionValues?.havePermissionDelete}
          />
        </TableCell>
      )}
      {!!permissionValues &&
        'havePermissionDownload' in permissionValues &&
        valueType === 'download' && (
          <TableCell className="item-data__cell" align="center">
            <Checkbox
              onChange={(_, checked: boolean) =>
                onClickCheckbox &&
                onClickCheckbox({
                  ...permissionValues,
                  havePermissionDownload: checked,
                })
              }
              disabled={isDisabled}
              icon={<IconCheckboxOutline />}
              checkedIcon={<IconCheckboxFilled fill={colors.primary.blue} />}
              checked={permissionValues?.havePermissionDownload}
            />
          </TableCell>
        )}
    </TableRow>
  );
};

export default RoleTableBodyItem;
