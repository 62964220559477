/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import Grid from '@mui/material/Grid';
import {BarDatum} from '@nivo/bar';
import moment, {Moment} from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {IconCash} from '../../../../../../../assets/icons';
// Components
import {
  AlertDialog,
  MDropdown,
  Modal,
  MYearpicker,
} from '../../../../../../../components';
import {
  DashboardBarChart,
  DashboardFinance,
  DashboardPieChart,
  DashboardTooltip,
} from '../../../../../components';
import DashboardFinanceFormContainer from '../../../../../components/DashboardFinanceFormContainer';
// Utils
import t from '../../../../../../../lang';
import {AppState} from '../../../../../../../config/Interface';
import {colors} from '../../../../../../../config/_theme';
import {wrapperNotifyAction} from '../../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {actionHandler} from '../../../../../../wrapper/utils';
import {
  dashboardOverviewChartSectionAction,
  dashboardOverviewExportAchievementFinalMonthlyAction,
  dashboardOverviewExportAchievementFinalQuarterAction,
  dashboardOverviewSetProfitAction,
} from '../../../../../usecases/dashboard.action';
import {DashboardState} from '../../../../../usecases/dashboard.reducer';
import {UserState} from '../../../../../../user/usecases/user.reducer';
import {
  DashboardOverviewChartSectionAction,
  DashboardOverviewExportAchievementFinalMonthlyAction,
  DashboardOverviewExportAchievementFinalQuarterAction,
  DashboardOverviewSetProfitAction,
} from '../../../../../usecases/dashboard.type';
// Data
import {
  DashboardOverviewChartMonthlyFinalScore,
  DashboardOverviewFinance,
  DASHBOARDQUARTERINTERVAL,
  PieRawDatum,
} from '../../../../../entity/dashboard.string.entity';
// Assets
import './DashboardOverviewChartList.scss';
//#endregion

const DashboardOverviewChartList: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();

  const dashboard: DashboardState = useSelector(
    (state: AppState) => state.dashboard,
    shallowEqual,
  );
  const {data}: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );

  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const permissions = data?.role?.permissions;

  //#region GENERAL YEAR FILTER
  const [selectedYearFilter, setSelectedYearFilter] = useState<
    Moment | string | null
  >(moment().year(new Date().getFullYear()));
  //#endregion

  //#region GENERAL PERIOD FILTER
  const periodFilterOption = [
    {label: DASHBOARDQUARTERINTERVAL.TW1, value: DASHBOARDQUARTERINTERVAL.TW1},
    {label: DASHBOARDQUARTERINTERVAL.TW2, value: DASHBOARDQUARTERINTERVAL.TW2},
    {label: DASHBOARDQUARTERINTERVAL.TW3, value: DASHBOARDQUARTERINTERVAL.TW3},
    {label: DASHBOARDQUARTERINTERVAL.TW4, value: DASHBOARDQUARTERINTERVAL.TW4},
  ];
  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState<string>(
    DASHBOARDQUARTERINTERVAL.TW1,
  );
  //#endregion
  //#endregion

  //#region FETCH OVERVIEW CHART
  const fetchOverviewChart = useCallback(
    (quarter: DASHBOARDQUARTERINTERVAL, year: number) => {
      dispatch(dashboardOverviewChartSectionAction.fetch({quarter, year}));
    },
    [dispatch],
  );
  //#endregion

  //#region EXPORT OVERVIEW ACHIEVEMENT FINAL MONTHLY
  const exportOverviewAchievementFinalMonthly = useCallback(
    (quarter: string, year: string | Moment | null) => {
      const payload = {
        quarter: quarter as DASHBOARDQUARTERINTERVAL,
        year: Number((year as Moment).format('YYYY')),
      };
      dispatch(
        dashboardOverviewExportAchievementFinalMonthlyAction.fetch(payload),
      );
    },
    [dispatch],
  );
  //#endregion

  //#region EXPORT OVERVIEW ACHIEVEMENT FINAL QUARTER
  const exportOverviewAchievementFinalQuarter = useCallback(
    (quarter: string, year: string | Moment | null) => {
      const payload = {
        quarter: quarter as DASHBOARDQUARTERINTERVAL,
        year: Number((year as Moment).format('YYYY')),
      };
      dispatch(
        dashboardOverviewExportAchievementFinalQuarterAction.fetch(payload),
      );
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(dashboardOverviewChartSectionAction.reset());
    fetchOverviewChart(
      selectedPeriodFilter as DASHBOARDQUARTERINTERVAL,
      Number((selectedYearFilter as Moment).format('YYYY')),
    );
    return () => {
      dispatch(dashboardOverviewChartSectionAction.reset());
    };
  }, [dispatch, fetchOverviewChart, selectedPeriodFilter, selectedYearFilter]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region SPM FINAL
  const [pieSPMFinal, setPieSPMFinal] = useState<PieRawDatum[]>([
    {
      id: 'achieved',
      label: t('Achieved'),
      value: 0,
      color: colors.primary.blue,
    },
    {
      id: 'not Achieved',
      label: t('Not Achieved'),
      value: 100,
      color: colors.text.lightGrey2,
    },
  ]);
  const [pieSPMFinalShowDetail, setPieSPMFinalShowDetail] = useState<boolean>(
    false,
  );
  const [
    pieSPMFinalDisableExport,
    setPieSPMFinalDisableExport,
  ] = useState<boolean>(true);
  //#endregion
  //#region FINAL ACHIEVEMENT
  const [FinalAchievement, setBarFinalAchievement] = useState<BarDatum[]>([]);
  const [
    barFinalAchievementShowDetail,
    setBarFinalAchievementShowDetail,
  ] = useState<boolean>(false);
  const [
    barFinalAchievementDisableExport,
    setBarFinalAchievementDisableExport,
  ] = useState<boolean>(true);
  //#endregion
  //#region PROFIT
  const [financeProfit, setFinanceProfit] = useState<
    DashboardOverviewFinance | undefined
  >({
    month: moment(new Date()).format('MMMM'),
    year: moment(new Date()).format('YYYY'),
    quarter: DASHBOARDQUARTERINTERVAL.TW1,
    value: 0,
    updatedValue: 0,
    from: '0%',
  });
  const [isEditFinanceProfit, setIsEditFinanceProfit] = useState<
    DashboardOverviewFinance | undefined
  >(undefined);
  //#endregion
  //#region CHARGE
  const [financeCharge, setFinanceCharge] = useState<
    DashboardOverviewFinance | undefined
  >({
    month: moment(new Date()).format('MMMM'),
    year: moment(new Date()).format('YYYY'),
    quarter: selectedPeriodFilter,
    value: 0,
    updatedValue: 0,
    from: '0%',
  });
  //#endregion

  //#region ACTION HANDLER
  const dashboardManagementWrapperActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(DashboardOverviewChartSectionAction.SUCCESS, (): void => {
            if (dashboard?.dashboardOverviewChartSectionResponse?.data) {
              //#region QUARTER FINAL ACHIEVEMENT SCORE
              const quarterFinalAchievementScore = dashboard
                ?.dashboardOverviewChartSectionResponse?.data
                .quarterFinalAchievementScore
                ? Number(
                    dashboard?.dashboardOverviewChartSectionResponse?.data
                      .quarterFinalAchievementScore,
                  )
                : 0;
              setPieSPMFinalDisableExport(!quarterFinalAchievementScore);
              const formattedPieSPMFinalData = [
                {
                  id: 'achieved',
                  label: t('Achieved'),
                  value: Number(quarterFinalAchievementScore.toFixed(2)),
                  color: colors.primary.blue,
                },
                {
                  id: 'not Achieved',
                  label: t('Not Achieved'),
                  value:
                    Number((100 - quarterFinalAchievementScore).toFixed(2)) > 0
                      ? Number((100 - quarterFinalAchievementScore).toFixed(2))
                      : 0,
                  color: colors.text.lightGrey2,
                },
              ];
              setPieSPMFinal(formattedPieSPMFinalData);
              //#endregion

              //#region FINAL ACHIEVEMENT
              const finalAchievementScore =
                dashboard?.dashboardOverviewChartSectionResponse?.data
                  .monthlyFinalScore;
              setBarFinalAchievementDisableExport(
                !dashboard?.dashboardOverviewChartSectionResponse?.data
                  .allowDownloadMonthlyFinalScore,
              );
              const formattedFinalAchievementScore: BarDatum[] = finalAchievementScore.map(
                (
                  item: DashboardOverviewChartMonthlyFinalScore,
                  index: number,
                ) => {
                  const brtValue = item?.brt
                    ? Number((item?.brt).toFixed(2))
                    : 0;
                  const nonBrtValue = item?.nonBrt
                    ? Number((item?.nonBrt).toFixed(2))
                    : 0;
                  const ibkValue = item?.ibk
                    ? Number((item?.ibk).toFixed(2))
                    : 0;
                  const transcareValue = item?.transcare
                    ? Number((item?.transcare).toFixed(2))
                    : 0;
                  const avgValue = (
                    (brtValue + nonBrtValue + ibkValue + transcareValue) /
                    4
                  ).toFixed(2);
                  return {
                    id: `${item?.month || ''}-${index}`,
                    label: item?.month || '',
                    brt: brtValue,
                    nonBrt: nonBrtValue,
                    ibk: ibkValue,
                    transcare: transcareValue,
                    rate: avgValue,
                    color: colors.primary.blue,
                  };
                },
              );
              setBarFinalAchievement(formattedFinalAchievementScore);
              //#endregion

              //#region PROFIT
              const profit =
                dashboard?.dashboardOverviewChartSectionResponse?.data.profit;
              const formattedProfit = {
                month: moment(new Date()).format('MMMM'),
                year: moment(new Date()).format('YYYY'),
                quarter: profit.quarter,
                value: profit.value ? Number(profit.value) : 0,
                updatedValue: profit?.percentageUp
                  ? Number(profit?.percentageUp.toFixed(2))
                  : 0,
                from: `${Number(profit?.percent.toFixed(2))}%`,
              };
              setFinanceProfit(formattedProfit);
              //#endregion

              //#region FINE
              const fine =
                dashboard?.dashboardOverviewChartSectionResponse?.data.fine;
              const formattedFine = {
                month: moment(new Date()).format('MMMM'),
                year: moment(new Date()).format('YYYY'),
                quarter: fine.quarter,
                value: fine.value ? Number(fine.value) : 0,
                updatedValue: fine.percentageUp
                  ? Number(fine.percentageUp.toFixed(2))
                  : 0,
                from: `${Number(profit?.percent).toFixed(2)}%`,
              };
              setFinanceCharge(formattedFine);
              //#endregion
            }
          })
          .addCase(DashboardOverviewChartSectionAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(dashboardOverviewChartSectionAction.reset());
          })
          .addCase(DashboardOverviewSetProfitAction.SUCCESS, (): void => {
            setIsEditFinanceProfit(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Save profit success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(DashboardOverviewSetProfitAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Save profit failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(dashboardOverviewSetProfitAction.reset());
          })
          .addCase(
            DashboardOverviewExportAchievementFinalMonthlyAction.FAILED,
            (): void => {
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Data export failed!'),
                  type: 'error',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
              dispatch(
                dashboardOverviewExportAchievementFinalMonthlyAction.reset(),
              );
            },
          )
          .addCase(
            DashboardOverviewExportAchievementFinalQuarterAction.FAILED,
            (): void => {
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Data export failed!'),
                  type: 'error',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
              dispatch(
                dashboardOverviewExportAchievementFinalQuarterAction.reset(),
              );
            },
          );
      });
    },
    [dashboard?.dashboardOverviewChartSectionResponse?.data, dispatch, refresh],
  );
  useEffect(() => {
    dashboardManagementWrapperActionHandler(dashboard.action);
  }, [dashboard.action, dashboardManagementWrapperActionHandler]);
  //#endregion
  return (
    <>
      <div className="dashboard-overview-chart-list">
        <div className="dashboard-overview-chart-list__filter">
          <MYearpicker
            inputValue={selectedYearFilter}
            maxDate={moment().endOf('year')}
            handleChange={(year) => setSelectedYearFilter(year)}
          />
          <MDropdown
            inputValue={selectedPeriodFilter}
            options={periodFilterOption}
            placeholder={t('Period')}
            handleChangeSelect={(period) => setSelectedPeriodFilter(period)}
          />
        </div>
        <Grid container spacing={3}>
          {!!permissions?.dashboardOverviewAchievementQuarterlyChart && (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key="quarter-final-achievement-score-pie-chart">
              <div className="dashboard-overview-chart-list__column">
                <div className="dashboard-overview-chart-list__card">
                  <DashboardPieChart
                    data={pieSPMFinal}
                    title={t('Quarter Final Achievement Score')}
                    tooltip={({datum}) => {
                      const tooltipData = [
                        {
                          name: datum.label as string,
                          value: `${datum.value}%`,
                        },
                      ];
                      return <DashboardTooltip data={tooltipData} />;
                    }}
                    extraLayer={
                      <div className="extra-container">
                        <div className="extra-container__point">
                          {`${pieSPMFinal[0].value}%`}
                        </div>
                        <div className="extra-container__desc">
                          {t('Achieved')}
                        </div>
                      </div>
                    }
                    selectedYearFilter={selectedYearFilter}
                    selectedPeriodFilter={selectedPeriodFilter}
                    handleExport={() =>
                      exportOverviewAchievementFinalQuarter(
                        selectedPeriodFilter,
                        selectedYearFilter,
                      )
                    }
                    isLoadingExport={
                      dashboard?.dashboardOverviewExportAchievementFinalQuarterLoading
                    }
                    isDisabledExport={pieSPMFinalDisableExport}
                    handleShowDetail={() => setPieSPMFinalShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
          {!!permissions?.dashboardOverviewAchievementFinalChart && (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key="monthly-final-achievement-score-bar-chart">
              <div className="dashboard-overview-chart-list__column">
                <div className="dashboard-overview-chart-list__card">
                  <DashboardBarChart
                    data={FinalAchievement}
                    title={t('Monthly Final Achievement Score')}
                    keys={['rate']}
                    indexBy="label"
                    minValue={0}
                    maxValue={100}
                    tooltip={({data}) => {
                      const tooltipData = [
                        {name: 'BRT', value: `${data?.brt || 0}%`},
                        {name: 'Non BRT', value: `${data?.nonBrt || 0}%`},
                        {name: 'IBK', value: `${data?.ibk || 0}%`},
                        {name: 'Transcare', value: `${data?.transcare || 0}%`},
                      ];
                      return <DashboardTooltip data={tooltipData} />;
                    }}
                    selectedYearFilter={selectedYearFilter}
                    selectedPeriodFilter={selectedPeriodFilter}
                    handleExport={() =>
                      exportOverviewAchievementFinalMonthly(
                        selectedPeriodFilter,
                        selectedYearFilter,
                      )
                    }
                    isLoadingExport={
                      dashboard?.dashboardOverviewExportAchievementFinalMonthlyLoading
                    }
                    isDisabledExport={barFinalAchievementDisableExport}
                    handleShowDetail={() =>
                      setBarFinalAchievementShowDetail(true)
                    }
                  />
                </div>
              </div>
            </Grid>
          )}
          {!!permissions?.dashboardOverviewProfitAndFineChart && (
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4} key="finance">
              <div className="dashboard-overview-chart-list__column">
                <div className="dashboard-overview-chart-list__card">
                  <DashboardFinance
                    icon={<IconCash />}
                    title={t('Profit')}
                    value={financeProfit?.value}
                    desc={`dari ${financeProfit?.quarter} ${financeProfit?.from}`}
                    info={financeProfit?.updatedValue}
                    {...(permissions.dashboardOverviewProfitAndFineChart
                      .havePermissionUpdate && {
                      handleEdit: () => setIsEditFinanceProfit(financeProfit),
                    })}
                  />
                </div>
                <div className="dashboard-overview-chart-list__card">
                  <DashboardFinance
                    icon={<IconCash fill={colors.secondary.red} />}
                    title={t('Charge')}
                    value={financeCharge?.value}
                    desc={`dari ${financeCharge?.quarter} ${financeCharge?.from}`}
                    info={financeCharge?.updatedValue}
                    isFine
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      {pieSPMFinalShowDetail && (
        <Modal
          onClose={() => setPieSPMFinalShowDetail(false)}
          isOpen={!!pieSPMFinalShowDetail}
          maxWidth="sm">
          <DashboardPieChart
            data={pieSPMFinal}
            title={t('Quarter Final Achievement Score')}
            tooltip={({datum}) => {
              const tooltipData = [
                {name: datum.label as string, value: `${datum.value}%`},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            extraLayer={
              <div className="extra-container">
                <div className="extra-container__point">
                  {`${pieSPMFinal[0].value}%`}
                </div>
                <div className="extra-container__desc">{t('Achieved')}</div>
              </div>
            }
            selectedYearFilter={selectedYearFilter}
            selectedPeriodFilter={selectedPeriodFilter}
            handleExport={() =>
              exportOverviewAchievementFinalQuarter(
                selectedPeriodFilter,
                selectedYearFilter,
              )
            }
            isLoadingExport={
              dashboard?.dashboardOverviewExportAchievementFinalQuarterLoading
            }
            isDisabledExport={pieSPMFinalDisableExport}
            isDetail
          />
        </Modal>
      )}

      {barFinalAchievementShowDetail && (
        <Modal
          onClose={() => setBarFinalAchievementShowDetail(false)}
          isOpen={!!barFinalAchievementShowDetail}
          maxWidth="sm">
          <DashboardBarChart
            data={FinalAchievement}
            title={t('Monthly Final Achievement Score')}
            keys={['rate']}
            indexBy="label"
            minValue={0}
            maxValue={100}
            tooltip={({data}) => {
              const tooltipData = [
                {name: 'BRT', value: `${data?.brt || 0}%`},
                {name: 'Non BRT', value: `${data?.nonBrt || 0}%`},
                {name: 'IBK', value: `${data?.ibk || 0}%`},
                {name: 'Transcare', value: `${data?.transcare || 0}%`},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            selectedYearFilter={selectedYearFilter}
            selectedPeriodFilter={selectedPeriodFilter}
            handleExport={() =>
              exportOverviewAchievementFinalMonthly(
                selectedPeriodFilter,
                selectedYearFilter,
              )
            }
            isLoadingExport={
              dashboard?.dashboardOverviewExportAchievementFinalMonthlyLoading
            }
            isDisabledExport={barFinalAchievementDisableExport}
            isDetail
          />
        </Modal>
      )}

      {isEditFinanceProfit && (
        <Modal
          onClose={() => setIsEditFinanceProfit(undefined)}
          isOpen={!!isEditFinanceProfit}
          title={t('Edit Profit')}>
          <DashboardFinanceFormContainer
            data={isEditFinanceProfit}
            year={selectedYearFilter}
            quarter={selectedPeriodFilter}
            isLoading={dashboard.dashboardOverviewSetProfitLoading}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            setIsEditFinanceProfit(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default DashboardOverviewChartList;
