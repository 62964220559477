import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataRute} from '../../../../entity/masterData.string.entity';

export const MasterDataRuteListAction = {
  FETCH: 'MASTER_DATA_RUTE_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_RUTE_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_RUTE_LIST_FAILED',
  RESET: 'MASTER_DATA_RUTE_LIST_RESET',
  UPDATE: 'MASTER_DATA_RUTE_LIST_UPDATE',
};

export interface MasterDataRuteListInitialState {
  masterDataRuteListLoading: boolean;
  masterDataRuteListData: MasterDataRute[];
  masterDataRuteListError?: MasterDataRuteListFailedPayload;
  masterDataRuteListParams?: MasterDataRuteListFetchPayload;
  masterDataRuteListResponse?: MasterDataRuteListSuccessPayload;
}

export type MasterDataRuteListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataRute[]>;
};

export type MasterDataRuteListFailedPayload = CommonError;

export type MasterDataRuteListSuccessPayload = MasterDataRuteListResponse;

export type MasterDataRuteListUpdatePayload = MasterDataRuteListResponse;

export interface MasterDataRuteListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
