import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataRouteCreateAction,
  MasterDataRouteCreateFailedPayload,
  MasterDataRouteCreateFetchPayload,
  MasterDataRouteCreateSuccessPayload,
} from './masterDataRouteCreate.type';

export const masterDataRouteCreateAction = {
  fetch: createAction<MasterDataRouteCreateFetchPayload>(
    MasterDataRouteCreateAction.FETCH,
  ),
  success: createAction<MasterDataRouteCreateSuccessPayload>(
    MasterDataRouteCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataRouteCreateFailedPayload>(
    MasterDataRouteCreateAction.FAILED,
  ),
  reset: createAction(MasterDataRouteCreateAction.RESET),
};
