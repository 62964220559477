import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDetailIndicatorCreateInitialState} from './masterDataDetailIndicatorCreate.reducer';
import {
  MasterDataDetailIndicatorCreateAction,
  MasterDataDetailIndicatorCreateFailedPayload,
  MasterDataDetailIndicatorCreateFetchPayload,
  MasterDataDetailIndicatorCreateSuccessPayload,
} from './masterDataDetailIndicatorCreate.type';

export const masterDataDetailIndicatorCreateHandler = {
  [MasterDataDetailIndicatorCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorCreateLoading: true,
    masterDataDetailIndicatorCreateParams: action.payload,
    action: MasterDataDetailIndicatorCreateAction.FETCH,
  }),
  [MasterDataDetailIndicatorCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorCreateParams: undefined,
    masterDataDetailIndicatorCreateLoading: false,
    masterDataDetailIndicatorCreateResponse: action.payload,
    action: MasterDataDetailIndicatorCreateAction.SUCCESS,
  }),
  [MasterDataDetailIndicatorCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorCreateLoading: false,
    masterDataDetailIndicatorCreateError: action.payload,
    action: MasterDataDetailIndicatorCreateAction.FAILED,
  }),
  [MasterDataDetailIndicatorCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailIndicatorCreateInitialState,
    action: MasterDataDetailIndicatorCreateAction.RESET,
  }),
};
