import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userLoginInitialState} from './userLogin.reducer';
import {
  UserLoginAction,
  UserLoginFailedPayload,
  UserLoginFetchPayload,
  UserLoginSuccessPayload,
} from './userLogin.type';

export const userLoginHandler = {
  [UserLoginAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserLoginFetchPayload>,
  ): UserState => ({
    ...state,
    userLoginLoading: true,
    userLoginParams: action.payload,
    action: UserLoginAction.FETCH,
  }),
  [UserLoginAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserLoginSuccessPayload>,
  ): UserState => ({
    ...state,
    userLoginParams: undefined,
    userLoginLoading: false,
    userLoginResponse: action.payload,
    action: UserLoginAction.SUCCESS,
  }),
  [UserLoginAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserLoginFailedPayload>,
  ): UserState => ({
    ...state,
    userLoginLoading: false,
    userLoginError: action.payload,
    action: UserLoginAction.FAILED,
  }),
  [UserLoginAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userLoginInitialState,
    action: UserLoginAction.RESET,
  }),
};
