/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RangeInput} from '@mui/lab/DateRangePicker/RangeTypes';
import {useHistory} from 'react-router';
import {Moment} from 'moment';
// Utils
import t from '../../../../../lang';
import {AppState} from '../../../../../config/Interface';
import {FollowUpState} from '../../../usecases/followUp.reducer';
import {UserState} from '../../../../user/usecases/user.reducer';
import {
  followUpDetailAction,
  followUpListAction,
  followUpPICCompleteAction,
  followUpPICConfirmAction,
} from '../../../usecases/followUp.action';
import {
  FollowUpListAction,
  FollowUpPICCompleteAction,
  FollowUpPICConfirmAction,
} from '../../../usecases/followUp.type';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {Modal, MSpinner, MTablePagination} from '../../../../../components';
import {
  FollowUpEmpty,
  FollowUpFilter,
  FollowUpNotification,
} from '../../../components';
import {FollowUpOnProgressDetail, FollowUpOnProgressList} from './components';
import FollowUpOnProgressFormContainer from '../FollowUpOnProgressFormContainer';
// Data
import {AssignmentType, PATH, SortType} from '../../../../wrapper/entity';
import {
  FollowUp,
  followUpFilterOption,
  FollowUpStatus,
} from '../../../entity/followUp.string.entity';
import {BackOfficePermissionType} from '../../../../user/entity/user.string.entity';
// Assets
import './FollowUpOnProgressSection.scss';
//#endregion

//#region INTERFACE
interface Props {
  defaultOpenedDetail?: FollowUp;
  isSPMOfficer?: boolean;
}
//#endregion
const FollowUpOnProgressSection: React.FC<Props> = ({
  defaultOpenedDetail,
  isSPMOfficer,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const history = useHistory();

  const followUpState: FollowUpState = useSelector(
    (state: AppState) => state.followUp,
    shallowEqual,
  );

  const {data}: UserState = useSelector((state: AppState) => state.user);
  const permissions: BackOfficePermissionType | undefined =
    data?.role?.permissions;
  const isCanConfirm = !!permissions?.followUpTabInProgress
    ?.havePermissionCreate;
  const isCanComplete = !!permissions?.followUpTabInProgress
    ?.havePermissionUpdate;

  const [isUploadFollowUp, setIsUploadFollowUp] = useState<
    FollowUp | undefined
  >(undefined);
  const [isViewFollowUp, setIsViewFollowUp] = useState<FollowUp | undefined>(
    defaultOpenedDetail,
  );
  const [expiredFindings, setExpiredFindings] = useState<number>(0);
  const [isPICConfirm, setIsPICConfirm] = useState<string>('');

  const isLoadingFollowUp: boolean = followUpState.followUpListLoading;
  const isLoadingPICConfirm = followUpState.followUpPICConfirmLoading;
  const isLoadingPICComplete = followUpState.followUpPICCompleteLoading;
  //#endregion

  //#region FILTER
  const [selectedToggleFilter, setSelectedToggleFilter] = useState<
    string | null
  >(
    defaultOpenedDetail
      ? followUpFilterOption.EXPIRED
      : followUpFilterOption.ALL,
  );

  const [selectedCheckmarksFilter, setSelectedCheckmarksFilter] = useState<
    string[]
  >([
    AssignmentType.HALTE,
    AssignmentType.BUSBRT,
    AssignmentType.BUSNONBRT,
    AssignmentType.BUSKECIL,
    AssignmentType.TRANSCARE,
  ]);

  const [selectedDateFilter, setSelectedDateFilter] = useState<
    RangeInput<unknown>
  >([null, null]);

  const [selectedSort, setSelectedSort] = useState<SortType>(SortType.DESC);
  //#endregion

  //#region PAGINATION CONTROL
  const [followUpListPage, setFollowUpListPage] = useState<number>(0);

  const [followUpListCount, setFollowUpListCount] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (_: unknown, _page: number) => {
    setFollowUpListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setFollowUpListPage(0);
  };
  //#endregion

  //#region HANDLE CHANGE DEFAULT DETAIL
  useEffect(() => {
    let changeDefaultDetail = true;
    if (changeDefaultDetail) {
      setIsViewFollowUp(defaultOpenedDetail);
      if (defaultOpenedDetail)
        setSelectedToggleFilter(followUpFilterOption.EXPIRED);
    }

    return () => {
      changeDefaultDetail = false;
    };
  }, [defaultOpenedDetail]);
  //#endregion

  //#region FETCH FOLLOW UP
  const fetchFollowUpList = useCallback(() => {
    dispatch(
      followUpListAction.fetch({
        page: followUpListPage,
        size: rowsPerPage,
        status: FollowUpStatus.ONPROGRESS,
        ...(selectedCheckmarksFilter.length === 0
          ? {}
          : {filter: selectedCheckmarksFilter.join()}),
        sort: selectedSort,
        ...(selectedDateFilter.filter((item) => item).length === 2
          ? {
              startDate: (selectedDateFilter[0] as Moment).format('YYYY-MM-DD'),
              endDate: (selectedDateFilter[1] as Moment).format('YYYY-MM-DD'),
            }
          : {}),
        ...(selectedToggleFilter === followUpFilterOption.NORMAL
          ? {isExpired: false}
          : selectedToggleFilter === followUpFilterOption.EXPIRED
          ? {isExpired: true}
          : {}),
      }),
    );
  }, [
    dispatch,
    followUpListPage,
    rowsPerPage,
    selectedCheckmarksFilter,
    selectedDateFilter,
    selectedSort,
    selectedToggleFilter,
  ]);
  //#endregion

  //#region CONFIRM FOLLOW UP
  const handleConfirmFollowUp = (followUpId: string) => {
    setIsPICConfirm(followUpId);
    dispatch(followUpPICConfirmAction.fetch({id: followUpId}));
  };
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(followUpListAction.reset());
    fetchFollowUpList();

    return () => {
      dispatch(followUpListAction.reset());
    };
  }, [dispatch, fetchFollowUpList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const followUpPICCompleteActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(FollowUpListAction.SUCCESS, (): void => {
            const data = followUpState.followUpListResponse?.data;
            const totalElement = data?.followUps?.totalElements;
            const totalExpired = data?.expired;
            if (data) {
              setExpiredFindings(totalExpired ?? 0);
            }
            setFollowUpListCount(totalElement ?? 0);
          })
          .addCase(FollowUpListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(followUpListAction.reset());
          })
          .addCase(FollowUpPICConfirmAction.SUCCESS, (): void => {
            setIsViewFollowUp(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data confirmed success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(FollowUpPICConfirmAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data confirmed failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(followUpPICConfirmAction.reset());
          })
          .addCase(FollowUpPICCompleteAction.SUCCESS, (): void => {
            setIsUploadFollowUp(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data completed success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(FollowUpPICCompleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data completed failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(followUpPICCompleteAction.reset());
          });
      });
    },
    [dispatch, followUpState.followUpListResponse?.data, refresh],
  );
  useEffect(() => {
    followUpPICCompleteActionHandler(followUpState.action);
  }, [followUpState.action, followUpPICCompleteActionHandler]);
  //#endregion

  //#region DATA LOAD
  const emptyData = () => {
    return (
      <div className="follow-up-approved-section__content--empty">
        <FollowUpEmpty
          text={t(
            'No findings. If the officer has carried out an assessment, the findings will be entered here, OK?',
          )}
        />
      </div>
    );
  };

  const loadingData = () => {
    return (
      <div className="follow-up-approved-section__content--loading">
        <MSpinner size={40} />
      </div>
    );
  };

  const dataLoad = () => {
    if (isLoadingFollowUp) {
      return loadingData();
    } else {
      if (followUpState.followUpListData.length === 0) return emptyData();
      return (
        <div className="follow-up-on-progress-section__content--list">
          <FollowUpOnProgressList
            data={followUpState.followUpListData}
            isLoading={{
              isLoading: isLoadingPICConfirm,
              id: isPICConfirm,
            }}
            isSPMOfficer={isSPMOfficer}
            isCanConfirm={isCanConfirm}
            isCanComplete={isCanComplete}
            onViewItem={(_followUp: FollowUp) => setIsViewFollowUp(_followUp)}
            onAction={(_followUp: FollowUp) => {
              if (_followUp.findingDueDate) {
                setIsUploadFollowUp(_followUp);
              } else {
                handleConfirmFollowUp(_followUp.id);
              }
            }}
          />
          <MTablePagination
            className="follow-up-list-pagination"
            rowsPerPageOptions={[20, 30, 40]}
            count={followUpListCount}
            rowsPerPage={rowsPerPage}
            page={followUpListPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      );
    }
  };
  //#endregion

  return (
    <>
      <div className="follow-up-on-progress-section">
        <div className="follow-up-on-progress-section__filter">
          <FollowUpFilter
            selectedToggleFilter={selectedToggleFilter}
            handleChangeToggleFilter={(
              e: React.MouseEvent<HTMLElement>,
              value: string | null,
            ) => {
              setFollowUpListPage(0);
              setSelectedToggleFilter(value);
            }}
            selectedCheckmarksFilter={selectedCheckmarksFilter}
            checkmarksFilterPlaceholder={t('Choose Type')}
            handleChangeCheckmarksFilter={(value: string) => {
              setFollowUpListPage(0);
              setSelectedCheckmarksFilter(
                typeof value === 'string' ? value.split(',') : value,
              );
            }}
            selectedDateFilter={selectedDateFilter}
            handleChangeDateFilter={(date: RangeInput<unknown>) => {
              setFollowUpListPage(0);
              setSelectedDateFilter(date);
            }}
            handleResetFilter={() => {
              setFollowUpListPage(0);
              setSelectedToggleFilter(followUpFilterOption.ALL);
              setSelectedCheckmarksFilter([
                AssignmentType.HALTE,
                AssignmentType.BUSBRT,
                AssignmentType.BUSNONBRT,
                AssignmentType.BUSKECIL,
                AssignmentType.TRANSCARE,
              ]);
              setSelectedDateFilter([null, null]);
            }}
            selectedSort={selectedSort}
            handleSort={(sort) => {
              setSelectedSort(sort as SortType);
            }}
          />
        </div>
        {!!expiredFindings &&
          !isLoadingFollowUp &&
          followUpState.followUpListData.length !== 0 && (
            <div className="follow-up-on-progress-section__notification">
              <FollowUpNotification
                text={`${expiredFindings} ${t('findings has expired')}`}
                actionLabel={t('See Findings')}
                onAction={() => {
                  setFollowUpListPage(0);
                  setSelectedToggleFilter(followUpFilterOption.EXPIRED);
                }}
              />
            </div>
          )}
        <div className="follow-up-on-progress-section__content">
          {dataLoad()}
        </div>
      </div>

      <Modal
        onClose={() => setIsUploadFollowUp(undefined)}
        title={t('Upload Follow Up')}
        isOpen={!!isUploadFollowUp}>
        <FollowUpOnProgressFormContainer
          data={isUploadFollowUp}
          isLoading={isLoadingPICComplete}
          onCancel={() => setIsUploadFollowUp(undefined)}
        />
      </Modal>

      {isViewFollowUp && (
        <Modal
          onClose={() => {
            dispatch(followUpDetailAction.reset());
            defaultOpenedDetail && history.replace(PATH.TINDAK_LANJUT);
            setIsViewFollowUp(undefined);
          }}
          isOpen={!!isViewFollowUp}>
          <FollowUpOnProgressDetail
            isSPMOfficer={isSPMOfficer}
            detailData={isViewFollowUp}
            isLoading={{
              isLoading: isLoadingPICConfirm,
              id: isPICConfirm,
            }}
            isCanConfirm={isCanConfirm}
            isCanComplete={isCanComplete}
            onAction={(_followUp: FollowUp) => {
              if (_followUp.findingDueDate) {
                setIsViewFollowUp(undefined);
                setIsUploadFollowUp(_followUp);
              } else {
                handleConfirmFollowUp(_followUp.id);
              }
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default FollowUpOnProgressSection;
