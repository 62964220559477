import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationValueEditAction,
  MasterDataObservationValueEditFailedPayload,
  MasterDataObservationValueEditFetchPayload,
  MasterDataObservationValueEditSuccessPayload,
} from './masterDataObservationValueEdit.type';

export const masterDataObservationValueEditAction = {
  fetch: createAction<MasterDataObservationValueEditFetchPayload>(
    MasterDataObservationValueEditAction.FETCH,
  ),
  success: createAction<MasterDataObservationValueEditSuccessPayload>(
    MasterDataObservationValueEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationValueEditFailedPayload>(
    MasterDataObservationValueEditAction.FAILED,
  ),
  reset: createAction(MasterDataObservationValueEditAction.RESET),
};
