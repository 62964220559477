/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../../../utils';
// Components
import DetailObservationManagementListTableHeader from '../DetailObservationManagementListTableHeader';
import DetailObservationManagementListTableItem from '../DetailObservationManagementListTableItem';
// Data
import {
  MasterDataDetailObservation,
  MasterDataDetailObservationOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  detailObservationList: MasterDataDetailObservation[];
  sortBy: SortTable;
  orderBy: MasterDataDetailObservationOrderByType;
  onEditDetailObservation?(
    _detailObservation: MasterDataDetailObservation,
  ): void;
  onDeleteDetailObservation?(
    _detailObservation: MasterDataDetailObservation,
  ): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataDetailObservationOrderByType,
  ): void;
}
//#endregion

const DetailObservationManagementList: React.FC<Props> = ({
  detailObservationList,
  sortBy,
  orderBy,
  onEditDetailObservation,
  onDeleteDetailObservation,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <DetailObservationManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            detailObservationList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <DetailObservationManagementListTableItem
              {...(onEditDetailObservation && {
                onEditItem: () => onEditDetailObservation(item),
              })}
              {...(onDeleteDetailObservation && {
                onDeleteItem: () => onDeleteDetailObservation(item),
              })}
              key={`detail-observation-list-item-data-${item.id}-${index}`}
              data={item}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailObservationManagementList;
