import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {httpPut} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataRouteDeleteAction} from './masterDataRouteDelete.action';
import {
  MasterDataRouteDeleteAction,
  MasterDataRouteDeleteFetchPayload,
  MasterDataRouteDeleteSuccessPayload,
} from './masterDataRouteDelete.type';

function* workerFetch(
  action: GenericAction<MasterDataRouteDeleteFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id} = action.payload;
  try {
    const res: AxiosResponse<MasterDataRouteDeleteSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/route/delete/${id}`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataRouteDeleteAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(masterDataRouteDeleteAction.failed(e.response.data));
  }
}

const masterDataRouteDeleteWatcher = [
  takeLatest(MasterDataRouteDeleteAction.FETCH, workerFetch),
];

export default masterDataRouteDeleteWatcher;
