import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataAssignmentLetterCreateAction,
  MasterDataAssignmentLetterCreateFailedPayload,
  MasterDataAssignmentLetterCreateFetchPayload,
  MasterDataAssignmentLetterCreateSuccessPayload,
} from './masterDataAssignmentLetterCreate.type';

export const masterDataAssignmentLetterCreateAction = {
  fetch: createAction<MasterDataAssignmentLetterCreateFetchPayload>(
    MasterDataAssignmentLetterCreateAction.FETCH,
  ),
  success: createAction<MasterDataAssignmentLetterCreateSuccessPayload>(
    MasterDataAssignmentLetterCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataAssignmentLetterCreateFailedPayload>(
    MasterDataAssignmentLetterCreateAction.FAILED,
  ),
  reset: createAction(MasterDataAssignmentLetterCreateAction.RESET),
};
