import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataAssignmentLetter} from '../../../../entity/masterData.string.entity';

export const MasterDataAssignmentLetterListAction = {
  FETCH: 'MASTER_DATA_ ASSIGNMENT_LETTER_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_ ASSIGNMENT_LETTER_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_ASSIGNMENT_LETTER_LIST_FAILED',
  RESET: 'MASTER_DATA_ ASSIGNMENT_LETTER_LIST_RESET',
  UPDATE: 'MASTER_DATA_ASSIGNMENT_LETTER_LIST_UPDATE',
};

export interface MasterDataAssignmentLetterListInitialState {
  masterDataAssignmentLetterListLoading: boolean;
  masterDataAssignmentLetterListData: MasterDataAssignmentLetter[];
  masterDataAssignmentLetterListError?: MasterDataAssignmentLetterListFailedPayload;
  masterDataAssignmentLetterListParams?: MasterDataAssignmentLetterListFetchPayload;
  masterDataAssignmentLetterListResponse?: MasterDataAssignmentLetterListSuccessPayload;
}

export type MasterDataAssignmentLetterListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataAssignmentLetter[]>;
};

export type MasterDataAssignmentLetterListFailedPayload = CommonError;

export type MasterDataAssignmentLetterListSuccessPayload = MasterDataAssignmentLetterListResponse;

export type MasterDataAssignmentLetterListUpdatePayload = MasterDataAssignmentLetterListResponse;

export interface MasterDataAssignmentLetterListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
}
