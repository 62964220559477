/* eslint-disable max-lines */
export default [
  //#region GENERAL
  {
    en: 'Add',
    id: 'Tambah',
  },
  {
    en: 'Edit',
    id: 'Ubah',
  },
  {
    en: 'Delete',
    id: 'Hapus',
  },
  {
    en: 'Cancel',
    id: 'Batalkan',
  },
  {
    en: 'Save',
    id: 'Simpan',
  },
  {
    en: 'Waiting to confirm',
    id: 'Menunggu konfirmasi',
  },
  {
    en: 'Waiting to complete',
    id: 'Menunggu diselesaikan',
  },
  {
    en: 'Send',
    id: 'Kirim',
  },
  {
    en: 'Back',
    id: 'Kembali',
  },
  {
    en: 'Yes, Cancel',
    id: 'Ya, Batalkan',
  },
  {
    en: 'Are you sure?',
    id: 'Apakah anda yakin?',
  },
  {
    en: 'Are you sure you want to delete the data?',
    id: 'Apakah anda yakin akan mengapus data?',
  },
  {
    en: 'Process will not be saved',
    id: 'Proses tidak akan tersimpan',
  },
  {
    en: 'Data fetch failed!',
    id: 'Data gagal diperoleh!',
  },
  {
    en: 'Data export failed!',
    id: 'Data gagal diekspor!',
  },
  {
    en: 'Data added success!',
    id: 'Data berhasil ditambah!',
  },
  {
    en: 'Data added failed!',
    id: 'Data gagal ditambah!',
  },
  {
    en: 'Data saved success!',
    id: 'Data berhasil disimpan!',
  },
  {
    en: 'Data saved failed!',
    id: 'Data gagal disimpan!',
  },
  {
    en: 'Division (parent) has departments (child)!',
    id: 'Gagal menghapus divisi, terdapat department yang aktif',
  },
  {
    en: 'Directorate (parent) has divisions (child)!',
    id: 'Gagal menghapus direktorat, terdapat divisi yang aktif',
  },
  {
    en: 'Data deleted success!',
    id: 'Data berhasil dihapus!',
  },
  {
    en: 'Data deleted failed!',
    id: 'Data gagal dihapus!',
  },
  {
    en: 'Data moved success!',
    id: 'Data berhasil dipindahkan!',
  },
  {
    en: 'Data moved failed!',
    id: 'Data gagal dipindahkan!',
  },
  {
    en: 'Positive value update success!',
    id: 'Pembaruan nilai positif berhasil!',
  },
  {
    en: 'Positive value update failed!',
    id: 'Pembaruan nilai positif gagal!',
  },
  {
    en: 'Rows Per Page',
    id: 'Baris per Halaman',
  },
  {
    en: 'of',
    id: 'dari',
  },
  {
    en: 'Yesterday',
    id: 'Kemarin',
  },
  {
    en: 'Today',
    id: 'Hari ini',
  },
  {
    en: 'Tomorrow',
    id: 'Besok',
  },
  {
    en: '[last] dddd',
    id: 'dddd [lalu]',
  },

  //#endregion

  //#region PATH
  {
    en: 'Edit Access Role',
    id: 'Ubah Role Access ',
  },
  {
    en: 'Add Access Role',
    id: 'Tambah Role Access ',
  },
  {
    en: 'Detail Access Role',
    id: 'Detail Role Access ',
  },
  //#endregion
  //#region USER FORM
  {
    en: 'User',
    id: 'Pengguna',
  },
  {
    en: 'Add User Access',
    id: 'Tambah User Access',
  },
  {
    en: 'Edit User Access',
    id: 'Ubah User Access',
  },
  {
    en: 'Fullname',
    id: 'Nama Lengkap',
  },
  {
    en: 'Enter Fullname',
    id: 'Masukkan Nama Lengkap',
  },
  {
    en: 'Fullname is required.',
    id: 'Nama Lengkap harus diisi.',
  },
  {
    en: 'NIK',
    id: 'NIK',
  },
  {
    en: 'Enter NIK',
    id: 'Masukkan NIK',
  },
  {
    en: 'NIK is required.',
    id: 'NIK harus diisi.',
  },
  {
    en: 'Enter Email',
    id: 'Masukkan Email',
  },
  {
    en: 'Name',
    id: 'Nama',
  },
  {
    en: 'Employee Name',
    id: 'Nama Karyawan',
  },
  {
    en: 'Active',
    id: 'Aktif',
  },
  {
    en: 'Inactive',
    id: 'Tidak Aktif',
  },
  {
    en: 'Search User Access',
    id: 'Cari User Access',
  },
  {
    en: 'Page',
    id: 'Halaman',
  },
  {
    en: 'Evaluation',
    id: 'Penilaian',
  },
  {
    en: 'Account Information',
    id: 'Informasi Akun',
  },
  {
    en: 'Dashboard',
    id: 'Dashboard',
  },
  {
    en: 'Evaluation Form',
    id: 'Formulir Penilaian',
  },
  {
    en: 'Evaluation Report',
    id: 'Laporan Penilaian',
  },
  {
    en: 'Work Report',
    id: 'Laporan Kerja',
  },
  {
    en: 'Download Data',
    id: 'Unduh Data',
  },
  {
    en: 'Master Data',
    id: 'Master Data',
  },
  {
    en: 'User Type',
    id: 'Tipe User',
  },
  {
    en: 'The selected user will be activated',
    id: 'User yang dipilih akan diaktifkan',
  },
  {
    en: 'The selected user will be deactivated',
    id: 'User yang dipilih akan dinonaktifkan',
  },
  {
    en: 'User activation update success!',
    id: 'Pembaruan aktivasi pengguna berhasil!',
  },
  {
    en: 'User activation update failed!',
    id: 'Pembaruan aktivasi pengguna gagal!',
  },
  {
    en: 'The selected assignment letter will be activated',
    id: 'Surat Tugas yang dipilih akan diaktifkan',
  },
  {
    en: 'The selected assignment letter will be deactivated',
    id: 'Surat Tugas yang dipilih akan dinonaktifkan',
  },
  {
    en: 'Yes, Deactivate',
    id: 'Ya, Nonaktifkan',
  },
  {
    en: 'Yes, Activate',
    id: 'Ya, Aktifkan',
  },
  {
    en: 'must be filled in at least',
    id: 'harus diisi minimal',
  },
  {
    en: 'letters',
    id: 'huruf',
  },
  //#endregion
  //#region LOGIN
  {
    en: 'Forget Password?',
    id: 'Lupa kata sandi?',
  },
  {
    en: 'Sign in',
    id: 'Masuk',
  },
  {
    en: 'Password is required.',
    id: 'Password harus diisi.',
  },
  {
    en: 'Email is required.',
    id: 'Email harus diisi.',
  },
  {
    en: 'Must be a valid email.',
    id: 'Email tidak valid.',
  },
  {
    en: 'Must be a valid TransJakarta email.',
    id: 'Harus menggunakkan email TransJakarta yang valid.',
  },
  {
    en: 'Password must be a mixture of text & numbers.',
    id: 'Password merupakan gabungan dari huruf dan angka.',
  },
  {
    en: 'Password min 6 character.',
    id: 'Password minimal 6 karakter.',
  },
  {
    en: 'TransJakarta service quality in hand',
    id: 'Kualitas layanan TransJakarta dalam genggaman',
  },
  {
    en: 'Login success!',
    id: 'Berhasil login!',
  },
  {
    en: 'Login failed!',
    id: 'Gagal login!',
  },
  //#endregion
  //#region FORGET PASSWORD
  {
    en: 'Submit',
    id: 'Kirim',
  },
  {
    en: 'Input your email',
    id: 'Masukkan email anda',
  },
  {
    en: 'Input your Transjakarta email to get verification code',
    id: 'Masukkan email Transjakarta anda untuk mendapatkan kode verifikasi',
  },
  {
    en: 'Email verification success!',
    id: 'Konfirmasi email berhasil!',
  },
  {
    en: 'Email verification failed!',
    id: 'Konfirmasi email gagal!',
  },
  {
    en: 'Resend Email verification success!',
    id: 'Kirim ulang konfirmasi email berhasil!',
  },
  {
    en: 'Resend Email verification failed!',
    id: 'Kirim ulang konfirmasi email gagal!',
  },
  //#endregion
  //#region CHANGE PASSWORD
  {
    en: 'Change Password',
    id: 'Ubah Kata Sandi',
  },
  {
    en: 'Password',
    id: 'Kata Sandi',
  },
  {
    en: 'Confirm Password',
    id: 'Konfirmasi Kata Sandi',
  },
  {
    en: 'Enter Password',
    id: 'Masukkan Kata Sandi',
  },
  {
    en: 'Enter Confirm Password',
    id: 'Masukkan Konfirmasi Kata Sandi',
  },
  {
    en: 'New Password',
    id: 'Kata Sandi Baru',
  },
  {
    en: 'Confirm New Password',
    id: 'Konfirmasi Kata Sandi Baru',
  },
  {
    en: 'Enter New Password',
    id: 'Masukkan Kata Sandi Baru',
  },
  {
    en: 'Enter Confirm New Password',
    id: 'Masukkan Konfirmasi Kata Sandi Baru',
  },
  {
    en: 'characters',
    id: 'karakter',
  },
  {
    en: 'number',
    id: 'angka',
  },
  {
    en: 'Password changed success!',
    id: 'Kata sandi berhasil diubah!',
  },
  {
    en: 'Password changed failed!',
    id: 'Kata sandi gagal diubah!',
  },
  //#endregion
  //#region OTP
  {
    en: 'Security Code',
    id: 'Kode Keamanan',
  },
  {
    en: 'Verification code has been sent to email',
    id: 'Kode verifikasi telah dikirim ke email',
  },
  {
    en: 'Not receive code?',
    id: 'Kode tidak diterima? ',
  },
  {
    en: 'Resend',
    id: 'Kirim ulang',
  },
  {
    en: 'Verification',
    id: 'Verifikasi',
  },
  {
    en: 'Verification success!',
    id: 'Verifikasi berhasil!',
  },
  {
    en: 'Verification failed!',
    id: 'Verifikasi gagal!',
  },
  //#endregion
  //#region Role
  {
    en: 'Role',
    id: 'Peran',
  },
  {
    en: 'Access',
    id: 'Akses',
  },
  {
    en: 'Search Role',
    id: 'Cari Peran',
  },
  {
    en: 'Enter Role',
    id: 'Masukkan Peran',
  },
  {
    en: 'Choose Role',
    id: 'Pilih Peran',
  },
  {
    en: 'Role is required.',
    id: 'Peran harus diisi.',
  },
  {
    en: 'Level',
    id: 'Tingkat',
  },
  {
    en: 'Choose Level',
    id: 'Pilih Tingkat',
  },
  {
    en: 'Level is required.',
    id: 'Tingkat harus diisi.',
  },
  {
    en: 'Evaluation',
    id: 'Penilaian',
  },
  {
    en: 'Evaluation Identity',
    id: 'Identitas Penilaian',
  },
  {
    en: 'Evaluation List',
    id: 'Daftar Penilaian',
  },
  {
    en: 'Evaluation Form',
    id: 'Formulir Penilaian',
  },
  {
    en: 'Evaluation Draft',
    id: 'Draft Penilaian',
  },
  {
    en: 'Schedule',
    id: 'Jadwal',
  },
  {
    en: 'Schedule Identity',
    id: 'Identitas Jadwal',
  },
  {
    en: 'Schedule List',
    id: 'Daftar Jadwal',
  },
  {
    en: 'Schedule Draft',
    id: 'Draft Jadwal',
  },
  {
    en: 'History',
    id: 'Riwayat',
  },
  {
    en: 'Evaluation History',
    id: 'Riwayat Penilaian',
  },
  {
    en: 'Schedule History',
    id: 'Riwayat Jadwal',
  },
  {
    en: 'Follow Up',
    id: 'Tindak Lanjut',
  },
  {
    en: 'Tab In Progress',
    id: 'Tab Dalam Proses',
  },
  {
    en: 'Tab Waiting For Verification',
    id: 'Tab Menunggu Verifikasi',
  },
  {
    en: 'Tab Approved',
    id: 'Tab Disetujui',
  },
  {
    en: 'Tab Rejected',
    id: 'Tab Ditolak',
  },
  {
    en: 'Tab Expired',
    id: 'Tab Kadaluarsa',
  },
  {
    en: 'Notification',
    id: 'Notifikasi',
  },
  {
    en: 'Notification Daily Assignment',
    id: 'Notifikasi jadwal harian petugas',
  },
  {
    en: 'Notification Follow Up Evaluation Report',
    id: 'Notifikasi tindak lanjut yang perlu diverifikasi',
  },
  {
    en: 'Notification New Assignment Evaluation Report',
    id: 'Notifikasi penilaian masuk',
  },
  {
    en: 'Notification Rejected Assignment Evaluation Report',
    id: 'Notifikasi penilaian yang ditolak oleh koordinator',
  },
  {
    en: 'Notification All Finding',
    id: 'Notifikasi semua temuan yang masuk',
  },
  {
    en: 'Notification Finding Confirm Reminder',
    id: 'Notifikasi reminder konfirmasi temuan',
  },
  {
    en: 'Notification Expired Finding',
    id: 'Notifikasi temuan sudah kadaluarsa',
  },
  {
    en: 'Notification Expired Notes from Chief',
    id: 'Notifikasi catatan kadaluarsa dari Kepala Departemen',
  },
  {
    en: 'Notification Expired Notes from Directorate',
    id: 'Notifikasi catatan kadaluarsa dari Direksi',
  },
  {
    en: 'Notification Finding Per Department',
    id: 'Notifikasi temuan sesuai dengan Departemennya',
  },
  {
    en: 'Notification Rejected Follow Up',
    id: 'Notifikasi laporan tindak lanjut ditolak dan harus diperbaiki',
  },
  {
    en: 'Achievement Final Chart',
    id: 'Chart Ketercapaian Final',
  },
  {
    en: 'Achievement Quarterly Chart',
    id: 'Chart Ketercapaian Triwulan Rekon',
  },
  {
    en: 'Profit And Fine Chart',
    id: 'Chart Laba dan Denda Rekon',
  },
  {
    en: 'Best Bus Stop Chart',
    id: 'Chart Halte Terbaik',
  },
  {
    en: 'Best Operator Chart',
    id: 'Chart Operator Terbaik',
  },
  {
    en: 'Dashboard Notes',
    id: 'Catatan Dashboard',
  },
  {
    en: 'Directorate Notes',
    id: 'Catatan Direksi',
  },
  {
    en: 'Analytics Qualitative Notes',
    id: 'Catatan Analisis Kualitatif',
  },
  {
    en: 'Service Achievement Final Chart',
    id: 'Chart Nilai Ketercapaian Final Semua Jenis Layanan',
  },
  {
    en: 'Gap Chart',
    id: 'Chart Gap Tiap Jenis Layanan Per Kategori',
  },
  {
    en: 'Weight Chart',
    id: 'Chart Selisih Bobot Tercapai dan Bobot Target',
  },
  {
    en: 'Finding Chart',
    id: 'Chart Jumlah Temuan Perhari yang Didapat dan Ditindaklanjuti',
  },
  {
    en: 'Rated Service Chart',
    id: 'Chart Capaian Pengawasan SPM',
  },
  {
    en: 'Dashboard Department',
    id: 'Dashboard Departemen',
  },
  {
    en: 'Department Achievement Chart',
    id: 'Chart Capaian Departemen',
  },
  {
    en: 'Department Weight Chart',
    id: 'Chart Selisih Bobot Tercapai dan Bobot Target Departemen',
  },
  {
    en: 'Profit And Fine Forecast Chart',
    id: 'Chart Laba dan Denda Forecast',
  },
  {
    en: 'Department Finding Chart',
    id:
      'Chart Jumlah Temuan Perhari yang Didapat dan Ditindaklanjuti Departemen',
  },
  {
    en: 'Best Department Chart',
    id: 'Chart Departemen Terbaik',
  },
  {
    en: 'Organization List',
    id: 'Daftar Organisasi',
  },
  {
    en: 'Work Report',
    id: 'Laporan Kerja',
  },
  {
    en: 'Assignment Report',
    id: 'Laporan Penilaian',
  },
  {
    en: 'Assignment Form',
    id: 'Formulir Penilaian',
  },
  {
    en: 'Corridor',
    id: 'Koridor',
  },
  {
    en: 'Coordinator Rejection Note',
    id: 'Catatan Penolakan Koordinator',
  },
  {
    en: 'Chief of Department Note',
    id: 'Catatan Kepala Departemen',
  },

  //#endregion
  //#region USER ACCOUNT
  {
    en: 'Logout',
    id: 'Keluar',
  },
  {
    en: 'Account',
    id: 'Akun',
  },
  {
    en: 'Edit Photo Profile',
    id: 'Ubah Foto Profil',
  },
  {
    en: 'Choose Photo',
    id: 'Pilih Foto',
  },
  {
    en: 'Save Photo',
    id: 'Simpan Foto',
  },
  {
    en: 'Profile picture changed success!',
    id: 'Foto profil berhasil diubah!',
  },
  {
    en: 'Profile picture changed failed!',
    id: 'Foto profil gagal diubah!',
  },
  {
    en: 'Phone Number',
    id: 'No Ponsel',
  },
  //#endregion

  //#region MASTER DATA
  //#region MASTER DATA WRAPPER
  {
    en: 'Search Master Data',
    id: 'Cari Master Data',
  },
  {
    en: 'Operational',
    id: 'Operasional',
  },
  {
    en: 'Form Assessment',
    id: 'Formulir Penilaian',
  },
  //#endregion
  //#region MASTER DATA CATEGORY
  {
    en: 'Category',
    id: 'Kategori',
  },
  {
    en: 'Search Master Data Category',
    id: 'Cari Master Data Kategori',
  },
  {
    en: 'Add Category',
    id: 'Tambah Kategori',
  },
  {
    en: 'Edit Category',
    id: 'Ubah Kategori',
  },
  {
    en: 'Enter Category',
    id: 'Masukkan Kategori',
  },
  {
    en: 'Choose Category',
    id: 'Pilih Kategori',
  },
  {
    en: 'Category is required.',
    id: 'Kategori harus diisi.',
  },
  //#endregion
  //#region MASTER DATA DETAIL OBSERVATION
  {
    en: 'Detail Observation',
    id: 'Rincian Pengamatan',
  },
  {
    en: 'Search Master Data Detail Observation',
    id: 'Cari Master Data Rincian Pengamatan',
  },
  {
    en: 'Add Detail Observation',
    id: 'Tambah Rincian Pengamatan',
  },
  {
    en: 'Edit Detail Observation',
    id: 'Ubah Rincian Pengamatan',
  },
  {
    en: 'Enter Detail Observation',
    id: 'Masukkan Rincian Pengamatan',
  },
  {
    en: 'Choose Detail Observation',
    id: 'Pilih Rincian Pengamatan',
  },
  {
    en: 'Detail Observation is required.',
    id: 'Rincian Pengamatan harus diisi.',
  },
  //#endregion
  //#region MASTER DATA OBSERVATION VALUE
  {
    en: 'Observation Value',
    id: 'Nilai Pengamatan',
  },
  {
    en: 'Search Master Data Observation Value',
    id: 'Cari Master Data Nilai Pengamatan',
  },
  {
    en: 'Add Observation Value',
    id: 'Tambah Nilai Pengamatan',
  },
  {
    en: 'Edit Observation Value',
    id: 'Ubah Nilai Pengamatan',
  },
  {
    en: 'Enter Observation Value',
    id: 'Masukkan Nilai Pengamatan',
  },
  {
    en: 'Choose Observation Value',
    id: 'Pilih Nilai Pengamatan',
  },
  {
    en: 'Select Observation Value ',
    id: 'Pilih Nilai Pengamatan ',
  },
  {
    en: 'Choose Observation Value Type',
    id: 'Pilih Tipe Nilai Pengamatan',
  },
  {
    en: 'Selected observation value will be ',
    id: 'Nilai pengamatan yang anda pilih akan menjadi ',
  },
  {
    en: 'Observation Value is required.',
    id: 'Nilai Pengamatan harus diisi.',
  },
  //#endregion
  //#region BASIC SERVICE TYPE
  {
    en: 'Basic Service Type',
    id: 'Jenis Pelayanan Dasar',
  },
  {
    en: 'Search Master Data Basic Service Type',
    id: 'Cari Master Data Jenis Pelayanan Dasar',
  },
  {
    en: 'Edit Basic Service Type',
    id: 'Ubah Jenis Pelayanan Dasar',
  },
  {
    en: 'Add Basic Service Type',
    id: 'Tambah Jenis Pelayanan Dasar',
  },
  {
    en: 'Enter Basic Service Type',
    id: 'Masukkan Jenis Pelayanan Dasar',
  },
  {
    en: 'Choose Basic Service Type',
    id: 'Pilih Jenis Pelayanan Dasar',
  },
  {
    en: 'Basic Service Type is required.',
    id: 'Jenis Pelayanan Dasar harus diisi.',
  },
  //#endregion
  //#region Review Indicator
  {
    en: 'Review Indicator',
    id: 'Indikator Penilaian',
  },
  {
    en: 'Search Master Data Review Indicator',
    id: 'Cari Master Data Indikator Penilaian',
  },
  {
    en: 'Edit Review Indicator',
    id: 'Ubah Indikator Penilaian',
  },
  {
    en: 'Add Review Indicator',
    id: 'Tambah Indikator Penilaian',
  },
  {
    en: 'Enter Review Indicator',
    id: 'Masukkan Indikator Penilaian',
  },
  {
    en: 'Choose Review Indicator',
    id: 'Pilih Indikator Penilaian',
  },
  {
    en: 'Review Indicator is required.',
    id: 'Indikator Penilaian harus diisi.',
  },
  {
    en: 'SPM Weight',
    id: 'Bobot SPM',
  },
  {
    en: 'Enter SPM Weight',
    id: 'Masukkan Bobot Capaian',
  },
  {
    en: 'SPM Weight is required.',
    id: 'Bobot Capaian harus diisi.',
  },
  {
    en: 'Enter Target',
    id: 'Masukkan Nilai Target Capaian',
  },
  {
    en: 'Target is required.',
    id: 'Nilai Target Capaian harus diisi.',
  },
  {
    en: 'Enter SLA',
    id: 'Masukkan SLA',
  },
  {
    en: 'SLA is required.',
    id: 'SLA harus diisi.',
  },
  {
    en: 'days',
    id: 'hari',
  },
  {
    en: 'Department PIC',
    id: 'Departemen Penanggung Jawab',
  },
  {
    en: 'Choose Department PIC',
    id: 'Pilih Departemen Penanggung Jawab',
  },
  {
    en: 'Choose Role',
    id: 'Pilih Role',
  },
  //#endregion
  //#region MASTER DATA OBSERVATION
  {
    en: 'Observation',
    id: 'Pengamatan',
  },
  {
    en: 'Search Master Data Observation',
    id: 'Cari Master Data Pengamatan',
  },
  {
    en: 'Add Observation',
    id: 'Tambah Pengamatan',
  },
  {
    en: 'Edit Observation',
    id: 'Ubah Pengamatan',
  },
  {
    en: 'Enter Observation',
    id: 'Masukkan Pengamatan',
  },
  {
    en: 'Choose Observation',
    id: 'Pilih Pengamatan',
  },
  {
    en: 'Observation is required.',
    id: 'Pengamatan harus diisi.',
  },
  //#endregion
  //#region MASTER DATA OBSERVATION
  {
    en: 'Detail Indicator',
    id: 'Rincian Indikator',
  },
  {
    en: 'Search Master Data Detail Indicator',
    id: 'Cari Master Data Rincian Indikator',
  },
  {
    en: 'Add Detail Indicator',
    id: 'Tambah Rincian Indikator',
  },
  {
    en: 'Edit Detail Indicator',
    id: 'Ubah Rincian Indikator',
  },
  {
    en: 'Enter Detail Indicator',
    id: 'Masukkan Rincian Indikator',
  },
  {
    en: 'Choose Detail Indicator',
    id: 'Pilih Rincian Indikator',
  },
  {
    en: 'Detail Indicator is required.',
    id: 'Rincian Indikator harus diisi.',
  },
  //#endregion
  //#region MASTER INDICATOR VALUE
  {
    en: 'Indicator Value',
    id: 'Nilai Indikator',
  },
  {
    en: 'Search Master Data Indicator Value',
    id: 'Cari Master Data Nilai Indikator',
  },
  {
    en: 'Add Indicator Value',
    id: 'Tambah Nilai Indikator',
  },
  {
    en: 'Edit Indicator Value',
    id: 'Ubah Nilai Indikator',
  },
  {
    en: 'Enter Indicator Value',
    id: 'Masukkan Nilai Indikator',
  },
  {
    en: 'Choose Indicator Value',
    id: 'Pilih Nilai Indikator',
  },
  {
    en: 'Select Indicator Value ',
    id: 'Pilih Nilai Indikator ',
  },
  {
    en: 'Indicator Value is required.',
    id: 'Nilai Indikator harus diisi.',
  },
  {
    en: 'Selected indicator value will be ',
    id: 'Nilai indikator yang anda pilih akan menjadi ',
  },
  {
    en: 'positive value',
    id: 'nilai positif',
  },
  {
    en: 'negative value',
    id: 'nilai negatif',
  },
  {
    en: 'Yes, Agree',
    id: 'Ya, Setuju',
  },
  {
    en: 'Positive',
    id: 'Positif',
  },
  {
    en: 'Negative',
    id: 'Negatif  ',
  },
  //#endregion
  //#region MASTER DATA FORM
  {
    en: 'Is there any important notes?',
    id: 'Apakah ada catatan penting?',
  },
  {
    en: 'Exist',
    id: 'Ada',
  },
  {
    en: 'Not Exist',
    id: 'Tidak Ada',
  },
  {
    en: 'Check Location',
    id: 'Lokasi Pengecekan',
  },
  {
    en: 'Check Location is required.',
    id: 'Lokasi Pengecekan harus diisi.',
  },
  {
    en: 'Inside',
    id: 'Dalam',
  },
  {
    en: 'Outside',
    id: 'Luar',
  },
  {
    en: 'Multiple Choice',
    id: 'Pilihan Ganda',
  },
  {
    en: 'Checklist',
    id: 'Ceklis',
  },
  //#endregion
  //#region MASTER DATA BUS STOP
  {
    en: 'Bus Stop Form',
    id: 'Formulir Halte',
  },
  {
    en: 'Search Master Data Bus Stop Form',
    id: 'Cari Master Data Formulir Halte',
  },
  {
    en: 'Add Bus Stop Form',
    id: 'Tambah Formulir Halte',
  },
  {
    en: 'Edit Bus Stop Form',
    id: 'Ubah Formulir Halte',
  },
  {
    en: 'Enter Bus Stop Form',
    id: 'Masukkan Formulir Halte',
  },
  //#endregion
  //#region MASTER DATA MIDDLE BUS BRT
  {
    en: 'Middle Bus BRT Form',
    id: 'Formulir Bus BRT Tengah',
  },
  {
    en: 'Search Master Data Middle Bus BRT Form',
    id: 'Cari Master Data Formulir Bus BRT Tengah',
  },
  {
    en: 'Add Middle Bus BRT Form',
    id: 'Tambah Formulir Bus BRT Tengah',
  },
  {
    en: 'Edit Middle Bus BRT Form',
    id: 'Ubah Formulir Bus BRT Tengah',
  },
  {
    en: 'Enter Middle Bus BRT Form',
    id: 'Masukkan Formulir Bus BRT Tengah',
  },
  //#endregion
  //#region MASTER DATA END BUS BRT
  {
    en: 'End Bus BRT Form',
    id: 'Formulir Bus BRT Ujung',
  },
  {
    en: 'Search Master Data End Bus BRT Form',
    id: 'Cari Master Data Formulir Bus BRT Ujung',
  },
  {
    en: 'Add End Bus BRT Form',
    id: 'Tambah Formulir Bus BRT Ujung',
  },
  {
    en: 'Edit End Bus BRT Form',
    id: 'Ubah Formulir Bus BRT Ujung',
  },
  {
    en: 'Enter End Bus BRT Form',
    id: 'Masukkan Formulir Bus BRT Ujung',
  },
  //#endregion
  //#region MASTER DATA MIDDLE BUS NON BRT
  {
    en: 'Middle Bus Non BRT Form',
    id: 'Formulir Bus Non BRT Tengah',
  },
  {
    en: 'Search Master Data Middle Bus Non BRT Form',
    id: 'Cari Master Data Formulir Bus Non BRT Tengah',
  },
  {
    en: 'Add Middle Bus Non BRT Form',
    id: 'Tambah Formulir Bus Non BRT Tengah',
  },
  {
    en: 'Edit Middle Bus Non BRT Form',
    id: 'Ubah Formulir Bus Non BRT Tengah',
  },
  {
    en: 'Enter Middle Bus Non BRT Form',
    id: 'Masukkan Formulir Bus Non BRT Tengah',
  },
  //#endregion
  //#region MASTER DATA END BUS NON BRT
  {
    en: 'End Bus Non BRT Form',
    id: 'Formulir Bus Non BRT Ujung',
  },
  {
    en: 'Search Master Data End Bus Non BRT Form',
    id: 'Cari Master Data Formulir Bus Non BRT Ujung',
  },
  {
    en: 'Add End Bus Non BRT Form',
    id: 'Tambah Formulir Bus Non BRT Ujung',
  },
  {
    en: 'Edit End Bus Non BRT Form',
    id: 'Ubah Formulir Bus Non BRT Ujung',
  },
  {
    en: 'Enter End Bus Non BRT Form',
    id: 'Masukkan Formulir Bus Non BRT Ujung',
  },
  //#endregion
  //#region MASTER DATA MIDDLE MINI BUS
  {
    en: 'Middle Mini Bus Form',
    id: 'Formulir Bus Kecil Tengah',
  },
  {
    en: 'Search Master Data Middle Mini Bus Form',
    id: 'Cari Master Data Formulir Bus Kecil Tengah',
  },
  {
    en: 'Add Middle Mini Bus Form',
    id: 'Tambah Formulir Bus Kecil Tengah',
  },
  {
    en: 'Edit Middle Mini Bus Form',
    id: 'Ubah Formulir Bus Kecil Tengah',
  },
  {
    en: 'Enter Middle Mini Bus Form',
    id: 'Masukkan Formulir Bus Kecil Tengah',
  },
  //#endregion
  //#region MASTER DATA END MINI BUS
  {
    en: 'End Mini Bus Form',
    id: 'Formulir Bus Kecil Ujung',
  },
  {
    en: 'Search Master Data End Mini Bus Form',
    id: 'Cari Master Data Formulir Bus Kecil Ujung',
  },
  {
    en: 'Add End Mini Bus Form',
    id: 'Tambah Formulir Bus Kecil Ujung',
  },
  {
    en: 'Edit End Mini Bus Form',
    id: 'Ubah Formulir Bus Kecil Ujung',
  },
  {
    en: 'Enter End Mini Bus Form',
    id: 'Masukkan Formulir Bus Kecil Ujung',
  },
  //#region MASTER DATA TRANSCARE
  {
    en: 'Transcare Form',
    id: 'Formulir Transcare',
  },
  {
    en: 'Search Master Data Transcare Form',
    id: 'Cari Master Data Formulir Transcare',
  },
  {
    en: 'Add Transcare Form',
    id: 'Tambah Formulir Transcare',
  },
  {
    en: 'Edit Transcare Form',
    id: 'Ubah Formulir Transcare',
  },
  {
    en: 'Enter Transcare Form',
    id: 'Masukkan Formulir Transcare',
  },
  //#endregion
  //#region MASTER DATA DIRECTORATE
  {
    en: 'Directorate',
    id: 'Direktorat',
  },
  {
    en: 'Search Master Data Directorate',
    id: 'Cari Master Data Direktorat',
  },
  {
    en: 'Add Directorate',
    id: 'Tambah Direktorat',
  },
  {
    en: 'Edit Directorate',
    id: 'Ubah Direktorat',
  },
  {
    en: 'Enter Directorate',
    id: 'Masukkan Direktorat',
  },
  {
    en: 'Choose Directorate',
    id: 'Pilih Direktorat',
  },
  {
    en: 'Directorate is required.',
    id: 'Direktorat harus diisi.',
  },
  //#endregion
  //#region MASTER DATA DIVISION
  {
    en: 'Division',
    id: 'Divisi',
  },
  {
    en: 'Search Master Data Division',
    id: 'Cari Master Data Divisi',
  },
  {
    en: 'Add Division',
    id: 'Tambah Divisi',
  },
  {
    en: 'Edit Division',
    id: 'Ubah Divisi',
  },
  {
    en: 'Enter Division',
    id: 'Masukkan Divisi',
  },
  {
    en: 'Choose Division',
    id: 'Pilih Divisi',
  },
  {
    en: 'Division is required.',
    id: 'Divisi harus diisi.',
  },
  //#endregion
  //#region MASTER DATA DEPARTMENT
  {
    en: 'Department',
    id: 'Departemen',
  },
  {
    en: 'Search Master Data Department',
    id: 'Cari Master Data Departemen',
  },
  {
    en: 'Add Department',
    id: 'Tambah Departemen',
  },
  {
    en: 'Edit Department',
    id: 'Ubah Departemen',
  },
  {
    en: 'Enter Department',
    id: 'Masukkan Departemen',
  },
  {
    en: 'Choose Department',
    id: 'Pilih Departemen',
  },
  {
    en: 'Department is required.',
    id: 'Departemen harus diisi.',
  },
  //#endregion
  //#region MASTER DATA WORK INSTRUCTION
  {
    en: 'Work Instruction',
    id: 'Instruksi Kerja',
  },
  {
    en: 'Add Work Instruction',
    id: 'Tambah Instruksi Kerja',
  },
  {
    en: 'Edit Work Instruction',
    id: 'Ubah Instruksi Kerja',
  },
  {
    en: 'Enter Work Instruction',
    id: 'Masukkan Instruksi Kerja',
  },
  {
    en: 'Work Instruction is required.',
    id: 'Instruksi Kerja harus diisi.',
  },
  //#endregion
  //#region TITLE
  {
    en: 'Title',
    id: 'Judul',
  },
  {
    en: 'Enter Title',
    id: 'Masukkan Judul',
  },
  {
    en: 'Title is required.',
    id: 'Judul harus diisi.',
  },
  //#endregion
  //#region MASTER DATA ASSIGNMENT LETTER
  {
    en: 'Assignment Letter',
    id: 'Surat Tugas',
  },
  {
    en: 'Search Master Data Assignment Letter',
    id: 'Cari Master Data Surat Tugas',
  },
  {
    en: 'Add Assignment Letter',
    id: 'Tambah Surat Tugas',
  },
  {
    en: 'Edit Assignment Letter',
    id: 'Ubah Surat Tugas',
  },
  {
    en: 'Enter Assignment Letter',
    id: 'Masukkan Surat Tugas',
  },
  {
    en: 'Assignment Letter is required.',
    id: 'Surat Tugas harus diisi.',
  },
  {
    en: 'Letter Number',
    id: 'Nomor Surat',
  },
  {
    en: 'Enter Letter Number',
    id: 'Masukkan Nomor Surat',
  },
  {
    en: 'Letter Number is required.',
    id: 'Nomor Surat harus diisi.',
  },
  {
    en: 'Publish Date',
    id: 'Tanggal Dikeluarkan',
  },
  {
    en: 'Publish Date is required.',
    id: 'Tanggal Dikeluarkan harus diisi.',
  },
  {
    en: 'Choose User',
    id: 'Pilih Pengguna',
  },
  {
    en: 'Upload File',
    id: 'Unggah File',
  },
  {
    en: 'Drop your .pdf file here, or',
    id: 'Letakkan file .pdf anda disini, atau',
  },
  {
    en: 'Drop your image file here, or',
    id: 'Letakkan file gambar anda disini, atau',
  },
  {
    en: 'Choose File',
    id: 'Pilih File',
  },
  {
    en: 'Assignment Letter File is required.',
    id: 'File Surat Tugas harus diisi.',
  },
  {
    en: 'Maximum Assignment Letter File Size is 5MB.',
    id: 'Maksimal Ukuran File Surat Tugas adalah 5MB.',
  },
  {
    en: 'Assignment Letter File Format does not supported.',
    id: 'Format File Surat Tugas tidak sesuai.',
  },
  {
    en: 'Applicable',
    id: 'Berlaku',
  },
  {
    en: 'Not Applicable',
    id: 'Tidak Berlaku',
  },
  {
    en: 'Assignment Letter activation update success!',
    id: 'Pembaruan aktivasi surat tugas berhasil!',
  },
  {
    en: 'Assignment Letter activation update failed!',
    id: 'Pembaruan aktivasi surat tugas gagal!',
  },
  //#endregion
  //#endregion
  //#region FOLLOW UP
  {
    en: 'On Progress',
    id: 'Dalam Proses',
  },
  {
    en: 'Waiting for Verification',
    id: 'Menunggu Verifikasi',
  },
  {
    en: 'Approved',
    id: 'Disetujui',
  },
  {
    en: 'Rejected',
    id: 'Ditolak',
  },
  {
    en: 'All',
    id: 'Semua',
  },
  {
    en: 'Expired',
    id: 'Kadaluarsa',
  },
  {
    en: 'Findings',
    id: 'Temuan',
  },
  {
    en: 'Revision',
    id: 'Revisi',
  },
  {
    en: 'Confirm Findings',
    id: 'Konfirmasi Temuan',
  },
  {
    en: 'Complete Follow Up',
    id: 'Selesaikan Tindak Lanjut',
  },
  {
    en: 'Add Revision',
    id: 'Tambah Revisi',
  },
  {
    en: 'Upload Follow Up',
    id: 'Unggah Tindak Lanjut',
  },
  {
    en: 'Send Follow Up',
    id: 'Kirim Tindak Lanjut',
  },
  {
    en: 'Revision Date',
    id: 'Tanggal Revisi',
  },
  {
    en: 'Fixing Date',
    id: 'Tanggal Perbaikan',
  },
  {
    en: 'Choose Date',
    id: 'Pilih Tanggal',
  },
  {
    en: 'Fixing Date is required.',
    id: 'Tanggal Perbaikan harus diisi.',
  },
  {
    en: 'Revision Date is required.',
    id: 'Tanggal Revisi harus diisi.',
  },
  {
    en: 'File is required.',
    id: 'File harus diisi.',
  },
  {
    en: 'Maximum File Size is 5MB.',
    id: 'Maksimal Ukuran File adalah 5MB.',
  },
  {
    en: 'File Format does not supported.',
    id: 'Format File tidak sesuai.',
  },
  {
    en: 'Upload Revision',
    id: 'Unggah Revisi',
  },
  {
    en: 'Send Revision',
    id: 'Kirim Revisi',
  },
  {
    en: 'Send Expired Note',
    id: 'Kirim Catatan Kadaluarsa',
  },
  {
    en: 'Expired Note',
    id: 'Catatan Kadaluarsa',
  },
  {
    en: 'Enter Expired Note',
    id: 'Masukkan Catatan Kadaluarsa',
  },
  {
    en: 'Expired Note is required.',
    id: 'Catatan Kadaluarsa harus diisi.',
  },
  {
    en: 'Choose Type',
    id: 'Pilih Jenis',
  },
  {
    en: 'others',
    id: 'lainnya',
  },
  {
    en: 'Sort:',
    id: 'Urutkan:',
  },
  {
    en: 'Latest',
    id: 'Terbaru',
  },
  {
    en: 'Oldest',
    id: 'Terlama',
  },
  {
    en: 'findings has expired',
    id: 'temuan telah kadaluarsa',
  },
  {
    en: 'See Findings',
    id: 'Lihat Temuan',
  },
  {
    en: 'Data confirmed success!',
    id: 'Data berhasil Dikonfirmasi!',
  },
  {
    en: 'Data confirmed failed!',
    id: 'Data gagal Dikonfirmasi!',
  },
  {
    en: 'Data completed success!',
    id: 'Data berhasil Diselesaikan!',
  },
  {
    en: 'Data completed failed!',
    id: 'Data gagal Diselesaikan!',
  },
  {
    en: 'Note added success!',
    id: 'Catatan berhasil ditambahkan!',
  },
  {
    en: 'Note added failed!',
    id: 'Catatan gagal ditambahkan!',
  },
  {
    en: 'Data revised success!',
    id: 'Data berhasil Direvisi!',
  },
  {
    en: 'Data revised failed!',
    id: 'Data gagal Direvisi!',
  },
  {
    en:
      'No findings. If the officer has carried out an assessment, the findings will be entered here, OK?',
    id:
      'Tidak ada temuan. Jika petugas sudah melakukan penilaian, temuan akan masuk disini ya',
  },
  {
    en: 'No follow ups are being verified',
    id: 'Tidak ada tindak lanjut yang sedang diverifikasi',
  },
  {
    en: 'No expiration findings',
    id: 'Tidak ada temuan kadaluarsa',
  },
  {
    en: 'No follow up approved',
    id: 'Tidak ada tindak lanjut yang disetujui',
  },
  {
    en: 'No follow up rejected',
    id: 'Tidak ada tindak lanjut yang ditolak',
  },
  //#endregion
  //#region NOTIFICATION
  {
    en: 'Notification',
    id: 'Notifikasi',
  },
  {
    en: 'Mark All As Read',
    id: 'Tandai Baca Semua',
  },
  {
    en: 'See All Notification',
    id: 'Lihat Semua Notifikasi',
  },
  {
    en: 'See More',
    id: 'Lihat Lebih',
  },
  {
    en: 'No notifications at this time',
    id: 'Tidak ada notifikasi saat ini',
  },
  {
    en: 'Read all data success!',
    id: 'Semua data berhasil dibaca!',
  },
  {
    en: 'Read all data failed!',
    id: 'Semua data gagal dibaca!',
  },
  //#endregion
  //#region WORK REPORT
  {
    en: 'Report Name',
    id: 'Nama Laporan',
  },
  {
    en: 'Enter Report Name',
    id: 'Masukkan Nama Laporan',
  },
  {
    en: 'Report Name is required.',
    id: 'Nama Laporan harus diisi.',
  },
  {
    en: 'Report Number',
    id: 'Nomor Laporan',
  },
  {
    en: 'Report Type',
    id: 'Jenis Laporan',
  },
  {
    en: 'Additional Report',
    id: 'Laporan Pendukung',
  },
  {
    en: 'Uploaded Date',
    id: 'Tanggal Upload',
  },
  {
    en: 'Download All',
    id: 'Unduh Semua',
  },
  {
    en: 'Year',
    id: 'Tahun',
  },
  {
    en: 'Choose Year',
    id: 'Pilih Tahun',
  },
  {
    en: 'Year is required.',
    id: 'Tahun harus diisi.',
  },
  {
    en: 'Month',
    id: 'Bulan',
  },
  {
    en: 'Choose Month',
    id: 'Pilih Bulan',
  },
  {
    en: 'Month is required.',
    id: 'Bulan harus diisi.',
  },
  {en: 'Main', id: 'Utama'},
  {en: 'Support', id: 'Pendukung'},
  {
    en: 'Main File',
    id: 'File Utama',
  },
  {
    en: 'Main File is required.',
    id: 'File Utama harus diisi.',
  },
  {
    en: 'Main File Format does not supported.',
    id: 'Format File Utama tidak sesuai.',
  },
  {
    en: 'Maximum Main File Size is 5MB.',
    id: 'Maksimal Ukuran File Utama adalah 5MB.',
  },
  {
    en: 'Support File',
    id: 'File Pendukung',
  },
  {
    en: 'Support File is required.',
    id: 'File Pendukung harus diisi.',
  },
  {
    en: 'Support File Format does not supported.',
    id: 'Format File Pendukung tidak sesuai.',
  },
  {
    en: 'Maximum Support File Size is 5MB.',
    id: 'Maksimal Ukuran File Pendukung adalah 5MB.',
  },
  {
    en: 'Unable to Add File in Previous Month.',
    id: 'Tidak Dapat Menambahkan File pada Bulan Sebelumnya.',
  },
  {
    en: 'Choose Month And Year',
    id: 'Pilih Bulan Dan Tahun',
  },
  {
    en: 'Choose Report Type',
    id: 'Pilih Laporan',
  },
  {
    en: 'Report Type is required.',
    id: 'Jenis Laporan harus diisi.',
  },
  {
    en: 'Start Month',
    id: 'Bulan Awal',
  },
  {
    en: 'Choose Start Month',
    id: 'Pilih Bulan Awal',
  },
  {
    en: 'Start Month is required.',
    id: 'Bulan Awal harus diisi.',
  },
  {
    en: 'End Month',
    id: 'Bulan Akhir',
  },
  {
    en: 'Choose End Month',
    id: 'Pilih Bulan Akhir',
  },
  {
    en: 'End Month is required.',
    id: 'Bulan Akhir harus diisi.',
  },
  {
    en: 'Add Monthly Reconciliation Report',
    id: 'Tambah Laporan Rekonsiliasi Bulanan',
  },
  {
    en: 'Edit Monthly Reconciliation Report',
    id: 'Ubah Laporan Rekonsiliasi Bulanan',
  },
  {
    en: 'Add Monthly Report',
    id: 'Tambah Laporan Bulanan',
  },
  {
    en: 'Edit Monthly Report',
    id: 'Ubah Laporan Bulanan',
  },
  //#endregion
  //#region DASHBOARD
  {
    en: 'Achieved',
    id: 'Tercapai',
  },
  {
    en: 'Not Achieved',
    id: 'Tidak Tercapai',
  },
  {
    en: 'Finished',
    id: 'Selesai',
  },
  {
    en: 'Unfinished',
    id: 'Belum Selesai',
  },
  {
    en: 'Profit',
    id: 'Laba',
  },
  {
    en: 'Charge',
    id: 'Denda',
  },
  {
    en: 'Year',
    id: 'Tahun',
  },
  {
    en: 'Month',
    id: 'Bulan',
  },
  {
    en: 'Period',
    id: 'Periode',
  },
  {
    en: 'Week',
    id: 'Minggu',
  },
  {
    en: 'See All',
    id: 'Lihat Semua',
  },
  {
    en: 'Number of Service Types checked',
    id: 'Jumlah Jenis Layanan yang diperiksa',
  },
  {
    en: 'updated on',
    id: 'diperbarui pada',
  },
  {
    en: 'and',
    id: 'dan',
  },
  {
    en: 'more',
    id: 'lainnya',
  },
  {
    en: 'comment on this chart',
    id: 'mengomentari grafik',
  },
  {
    en: 'Add Comment',
    id: 'Tambah Komentar',
  },
  {
    en: 'Enter Comment',
    id: 'Masukkan Komentar',
  },
  {
    en: 'Comment is required.',
    id: 'Komentar harus diisi.',
  },
  {
    en: 'Edit Profit',
    id: 'Ubah Profit',
  },
  {
    en: 'Save profit success!',
    id: 'Laba berhasil disimpan!',
  },
  {
    en: 'Save profit failed!',
    id: 'Laba gagal disimpan!',
  },
  {
    en: 'Quarter',
    id: 'Triwulan',
  },
  {
    en: 'Quarter 1',
    id: 'Triwulan 1',
  },
  {
    en: 'Quarter 2',
    id: 'Triwulan 2',
  },
  {
    en: 'Quarter 3',
    id: 'Triwulan 3',
  },
  {
    en: 'Quarter 4',
    id: 'Triwulan 4',
  },
  {
    en: 'Choose Quarter',
    id: 'Pilih Triwulan',
  },
  {
    en: 'Quarter is required.',
    id: 'Triwulan harus diisi.',
  },
  {
    en: 'Enter Profit Value',
    id: 'Masukkan Nilai Laba',
  },
  {
    en: 'Profit Value is required.',
    id: 'Nilai Laba harus diisi.',
  },
  {
    en: 'Quarter Final Achievement Score',
    id: 'Nilai Capaian Final Triwulan',
  },
  {
    en: 'Monthly Final Achievement Score',
    id: 'Nilai Capaian Final Bulanan',
  },
  {
    en: 'Best Stop',
    id: 'Halte Terbaik',
  },
  {
    en: 'Best Operator',
    id: 'Operator Terbaik',
  },
  {
    en: 'Best Department',
    id: 'Departemen Terbaik',
  },
  //#endregion
  //#region MASTER DATA OPERATIONAL BUS
  {
    en: 'Search Master Data Bus',
    id: 'Cari Master Data Bus',
  },
  {
    en: 'Bus Number',
    id: 'Nomor Bus',
  },
  {
    en: 'Bus Type',
    id: 'Jenis Bus',
  },
  {
    en: 'Body Number',
    id: 'Nomor Bus',
  },
  {
    en: 'Body Number is required.',
    id: 'Nomor Bus harus diisi.',
  },
  {
    en: 'Enter Body Number',
    id: 'Masukkan Nomor Bus',
  },
  {
    en: 'Enter Operator',
    id: 'Masukkan Operator',
  },
  {
    en: 'Operator is required.',
    id: 'Operator harus diisi.',
  },
  {
    en: 'Pool is required.',
    id: 'Pool harus diisi.',
  },
  {
    en: 'Enter Pool',
    id: 'Masukkan Pool',
  },
  {
    en: 'Bus Type is required.',
    id: 'Jenis Bus harus diisi.',
  },
  {
    en: 'Choose Bus Type',
    id: 'Pilih Jenis Bus',
  },
  {
    en: 'Add Bus',
    id: 'Tambah Bus',
  },
  {
    en: 'Edit Bus',
    id: 'Ubah Bus',
  },

  //#endregion
  //#region MASTER DATA OPERATIONAL RUTE
  {
    en: 'Master Data Route',
    id: 'Master Data Rute',
  },
  {
    en: 'Enter Corridor Number',
    id: 'Masukkan Nomor Koridor',
  },
  {
    en: 'Corridor Number is required.',
    id: 'Nomor Koridor harus diisi.',
  },
  {
    en: 'Enter Route Code',
    id: 'Masukkan Nomor Rute',
  },
  {
    en: 'Assignment Type is required.',
    id: 'Tipe Rute harus diisi.',
  },
  {
    en: 'Route Name',
    id: 'Nama Rute',
  },
  {
    en: 'Corridor Number',
    id: 'Nomor Koridor',
  },
  {
    en: 'Corridor is required.',
    id: 'Nomor Koridor harus diisi.',
  },
  {
    en: 'Choose Assignment Type',
    id: 'Pilih Tipe Rute',
  },
  {
    en: 'Route Code is required.',
    id: 'Nomor Rute harus diisi.',
  },
  {
    en: 'Add Route',
    id: 'Tambah Rute',
  },
  {
    en: 'Edit Route',
    id: 'Ubah Rute',
  },
  {
    en: 'Enter Route Name',
    id: 'Masukkan Nama Rute',
  },
  {
    en: 'Route Name is required.',
    id: 'Nama Rute harus diisi.',
  },
  {
    en: 'Enter Bus Stop Name',
    id: 'Masukkan Nama Halte',
  },
  {
    en: 'Bus Stop Name is required.',
    id: 'Nama Halte harus diisi.',
  },
  {
    en: 'Enter Bus Stop Code',
    id: 'Masukkan Kode Halte',
  },
  {
    en: 'Bus Stop Code is required.',
    id: 'Kode Halte harus diisi.',
  },
  {
    en: 'Search Master Data Route BRT',
    id: 'Cari Master Data Rute BRT',
  },
  {
    en: 'Search Master Data Route Non BRT',
    id: 'Cari Master Data Rute Non BRT',
  },
  {
    en: 'Search Master Data Route Bus Kecil',
    id: 'Cari Master Data Rute Bus Kecil',
  },
  {
    en: 'Route',
    id: 'Rute',
  },
  {
    en: 'Route Code',
    id: 'Nomor Rute',
  },
  {
    en: 'Bus Stop',
    id: 'Halte',
  },
  {
    en: 'Bus Stop Code',
    id: 'Kode Halte',
  },
  //#endregion
  //#region EVALUATION REPORT
  {
    en: 'Evaluation Report',
    id: 'Laporan Penilaian',
  },
  {
    en: 'Search Evaluation Report',
    id: 'Cari Laporan Penilaian',
  },
  {
    en: 'Location',
    id: 'Lokasi',
  },
  {
    en: 'Officer Name',
    id: 'Nama Petugas',
  },
  {
    en: 'No evaluation report',
    id: 'Tidak ada laporan penilaian',
  },
  {
    en: 'No evaluation report detail',
    id: 'Tidak ada rincian laporan penilaian',
  },
  {
    en: 'Important Notes',
    id: 'Catatan Penting',
  },
  {
    en: 'Start Date',
    id: 'Tanggal Awal',
  },
  {
    en: 'Choose Start Date',
    id: 'Pilih Tanggal Awal',
  },
  {
    en: 'Start Date is required.',
    id: 'Tanggal Awal wajib diisi.',
  },
  {
    en: 'End Date',
    id: 'Tanggal Akhir',
  },
  {
    en: 'Choose End Date',
    id: 'Pilih Tanggal Akhir',
  },

  {
    en: 'End Date is required.',
    id: 'Tanggal Akhir wajib diisi.',
  },

  {
    en: 'Assignment type',
    id: 'Tipe Tugas',
  },
  {
    en: 'Route type',
    id: 'Tipe Rute',
  },
  {
    en: 'Choose Assignment type',
    id: 'Pilih Tipe Tugas',
  },
  {
    en: 'Choose Route type',
    id: 'Pilih Tipe Rute',
  },

  //#endregion
];
