//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {
  MasterDataWorkInstructionEditAction,
  MasterDataWorkInstructionListAction,
} from '../../../../usecases/masterData.type';
import {
  masterDataWorkInstructionEditAction,
  masterDataWorkInstructionListAction,
} from '../../../../usecases/masterData.action';
// Components
import {Modal} from '../../../../../../components';
import {MasterDataWorkInstructionManagementList} from './components';
import MasterDataWorkInstructionDetail from '../MasterDataWorkInstructionDetail';
import MasterDataWorkInstructionFormContainer from '../MasterDataWorkInstructionFormContainer';
// Data
import {MasterDataWorkInstruction} from '../../../../entity/masterData.string.entity';
import {PATH} from '../../../../../wrapper/entity';
// Assets
import './MasterDataWorkInstructionSection.scss';
//#endregion

const MasterDataWorkInstructionSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [isViewWorkInstruction, setIsViewWorkInstruction] = useState<
    MasterDataWorkInstruction | undefined
  >(undefined);
  const [isEditWorkInstruction, setIsEditWorkInstruction] = useState<
    MasterDataWorkInstruction | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingEditWorkInstruction =
    masterData.masterDataWorkInstructionEditLoading;
  //#endregion

  //#region FETCH WORK INSTRUCTION
  const fetchWorkInstructionList = useCallback(() => {
    dispatch(masterDataWorkInstructionListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataWorkInstructionListAction.reset());
    fetchWorkInstructionList();

    return () => {
      dispatch(masterDataWorkInstructionListAction.reset());
    };
  }, [dispatch, fetchWorkInstructionList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataWorkInstructionActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataWorkInstructionListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataWorkInstructionListAction.reset());
          })
          .addCase(MasterDataWorkInstructionEditAction.SUCCESS, (): void => {
            setIsEditWorkInstruction(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataWorkInstructionEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataWorkInstructionEditAction.reset());
          });
      });
    },
    [dispatch, refresh],
  );
  useEffect(() => {
    masterDataWorkInstructionActionHandler(masterData.action);
  }, [masterData.action, masterDataWorkInstructionActionHandler]);
  //#endregion

  if (!permissions?.masterWorkAssignmentInstruction)
    return <Redirect to={PATH.HOME} />;
  return (
    <div className="master-data-work-instruction-section">
      <>
        <div className="master-data-work-instruction-section__content">
          <MasterDataWorkInstructionManagementList
            onClickItem={(_workInstruction: MasterDataWorkInstruction) => {
              setIsViewWorkInstruction(_workInstruction);
            }}
            data={masterData.masterDataWorkInstructionListData}
          />
        </div>
      </>

      {isViewWorkInstruction && (
        <Modal
          title={`${t('Work Instruction')} ${isViewWorkInstruction?.name}`}
          onClose={() => setIsViewWorkInstruction(undefined)}
          isOpen={!!isViewWorkInstruction}>
          <MasterDataWorkInstructionDetail
            {...(permissions?.masterWorkAssignmentInstruction
              .havePermissionUpdate && {
              onEdit: () => {
                setIsEditWorkInstruction(isViewWorkInstruction);
                setIsViewWorkInstruction(undefined);
              },
            })}
            data={isViewWorkInstruction}
          />
        </Modal>
      )}

      {isEditWorkInstruction && (
        <Modal
          onClose={() => setIsEditWorkInstruction(undefined)}
          isOpen={!!isEditWorkInstruction}
          title={t('Edit Work Instruction')}>
          <MasterDataWorkInstructionFormContainer
            data={isEditWorkInstruction}
            isLoading={isLoadingEditWorkInstruction}
            onCancel={() => setIsEditWorkInstruction(undefined)}
          />
        </Modal>
      )}
    </div>
  );
};

export default MasterDataWorkInstructionSection;
