//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataBasicServiceTypeCreateAction} from '../../../../usecases/section/form/masterDataBasicServiceTypeCreate/masterDataBasicServiceTypeCreate.action';
// Components
import {MasterDataBasicTypeFormCreateForm} from './components';
// Data
import {MasterDataBasicServiceTypeCreateFetchPayload} from '../../../../usecases/masterData.type';
import {BasicTypeFormCreateInitialValues} from './components/MasterDataBasicTypeFormCreateForm/MasterDataBasicTypeFormCreateForm.component';
// Assets
import './MasterDataBasicServiceTypeFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataBasicServiceTypeFormCreateContainer: React.FC<Props> = ({
  assignmentType,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: BasicTypeFormCreateInitialValues = {
    basicServiceTypes: [
      {
        name: '',
      },
    ],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitBasicServiceType = (
    formValue: BasicTypeFormCreateInitialValues,
  ) => {
    const formattedValue: MasterDataBasicServiceTypeCreateFetchPayload =
      formValue?.basicServiceTypes.map((item) => ({
        name: item.name,
        assignmentType,
      })) || [];
    dispatch(masterDataBasicServiceTypeCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-basic-service-type-form-create-container">
      <MasterDataBasicTypeFormCreateForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitBasicServiceType={handleSubmitBasicServiceType}
      />
    </div>
  );
  //#endregion
};

export default MasterDataBasicServiceTypeFormCreateContainer;
