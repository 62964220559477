import {AxiosResponse} from 'axios';
import {call, put, takeLatest, select} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {post} from '../../../../services/http';
import {UserState} from '../user.reducer';
import {userDataAction} from '../userData/userData.action';
import {userChangePasswordAction} from './userChangePassword.action';
import {
  UserChangePasswordAction,
  UserChangePasswordFetchPayload,
  UserChangePasswordSuccessPayload,
} from './userChangePassword.type';

function* workerFetch(action: GenericAction<UserChangePasswordFetchPayload>) {
  try {
    const user: UserState = yield select((state: AppState) => state.user);

    const res: AxiosResponse<UserChangePasswordSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/change_password`,
      action.payload,
      {
        headers: {
          Authorization: `${user?.tokenType || 'Bearer'} ${
            user.accessToken || user.userOTPPasswordResponse?.data?.accessToken
          }`,
        },
      },
    );
    if (res.status === 200) {
      yield put(userChangePasswordAction.success({}));
      yield put(
        userDataAction.fetch({
          accessToken: user?.accessToken ?? '',
          tokenType: user?.tokenType,
          data: {
            ...user?.data,
            isFirstLogin: false,
          },
          isLogin: user?.isLogin,
          isFirstLogin: false,
          lastSuccessLogin: user?.lastSuccessLogin,
          userLevel: user?.userLevel,
        }),
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userChangePasswordAction.failed(e.response.data.payload));
  }
}

const userChangePasswordWatcher = [
  takeLatest(UserChangePasswordAction.FETCH, workerFetch),
];

export default userChangePasswordWatcher;
