//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
// Components
import {MButton, MTextField} from '../../../../../../../../components';
// Data
import {MasterDataDetailIndicatorFormData} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDetailIndicatorFormEditForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  initialValues: MasterDataDetailIndicatorFormData;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitDetailIndicator(
    formValue: MasterDataDetailIndicatorFormData,
  ): void;
}
//#endregion

const MasterDataDetailIndicatorFormCreateForm: React.FC<Props> = ({
  initialValues,
  isLoading,
  handleSubmitDetailIndicator,
  handleCancel,
}: Props) => {
  //#region RENDER
  //#region VALIDATION
  const detailIndicatorValidation = Yup.object().shape({
    name: Yup.string()
      .required(t('Detail Indicator is required.'))
      .min(
        3,
        `${t('Detail Indicator')} ${t('must be filled in at least')} ${3} ${t(
          'letters',
        )}.`,
      ),
  });
  //#endregion

  return (
    <div className="master-data-detail-indicator-form-edit-form">
      <div className="master-data-detail-indicator-form-edit-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={detailIndicatorValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitDetailIndicator}>
          {({handleSubmit, isValid}): React.ReactElement => (
            <>
              <div className="master-data-detail-indicator-form-edit-form__section">
                <div className="master-data-detail-indicator-form-edit-form__section--field">
                  <div className="input-label">{t('Detail Indicator')}</div>
                  <Field
                    className="user-input"
                    name="name"
                    type="text"
                    placeholder={t('Enter Detail Indicator')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
              </div>

              <div className="master-data-detail-indicator-form-edit-form__form--action">
                <div className="master-data-detail-indicator-form-edit-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="master-data-detail-indicator-form-edit-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataDetailIndicatorFormCreateForm;
