import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataObservationCreateInitialState} from './masterDataObservationCreate.reducer';
import {
  MasterDataObservationCreateAction,
  MasterDataObservationCreateFailedPayload,
  MasterDataObservationCreateFetchPayload,
  MasterDataObservationCreateSuccessPayload,
} from './masterDataObservationCreate.type';

export const masterDataObservationCreateHandler = {
  [MasterDataObservationCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationCreateLoading: true,
    masterDataObservationCreateParams: action.payload,
    action: MasterDataObservationCreateAction.FETCH,
  }),
  [MasterDataObservationCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationCreateParams: undefined,
    masterDataObservationCreateLoading: false,
    masterDataObservationCreateResponse: action.payload,
    action: MasterDataObservationCreateAction.SUCCESS,
  }),
  [MasterDataObservationCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationCreateLoading: false,
    masterDataObservationCreateError: action.payload,
    action: MasterDataObservationCreateAction.FAILED,
  }),
  [MasterDataObservationCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationCreateInitialState,
    action: MasterDataObservationCreateAction.RESET,
  }),
};
