import {
  MasterDataDivisionOptionListAction,
  MasterDataDivisionOptionListSuccessPayload,
  MasterDataDivisionOptionListFailedPayload,
  MasterDataDivisionOptionListFetchPayload,
} from './masterDataDivisionOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDivisionOptionListInitialState} from './masterDataDivisionOptionList.reducer';

export const masterDataDivisionOptionListHandler = {
  [MasterDataDivisionOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDivisionOptionListLoading: true,
      masterDataDivisionOptionListParams: action.payload,
      action: MasterDataDivisionOptionListAction.FETCH,
    };
  },
  [MasterDataDivisionOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionOptionListLoading: false,
    masterDataDivisionOptionListResponse: action.payload,
    action: MasterDataDivisionOptionListAction.SUCCESS,
  }),
  [MasterDataDivisionOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionOptionListLoading: false,
    masterDataDivisionOptionListData: [
      ...state.masterDataDivisionOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataDivisionOptionListAction.UPDATE,
  }),
  [MasterDataDivisionOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionOptionListLoading: false,
    masterDataDivisionOptionListError: action.payload,
    action: MasterDataDivisionOptionListAction.FAILED,
  }),
  [MasterDataDivisionOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDivisionOptionListInitialState,
    action: MasterDataDivisionOptionListAction.RESET,
  }),
};
