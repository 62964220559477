import {CommonError} from '../../../../../../config/constant';
import {DashboardSPMChartSection} from '../../../../entity/dashboard.string.entity';

export const DashboardSPMChartSectionAction = {
  FETCH: 'DASHBOARD_SPM_CHART_SECTION_FETCH',
  SUCCESS: 'DASHBOARD_SPM_CHART_SECTION_SUCCESS',
  FAILED: 'DASHBOARD_SPM_CHART_SECTION_FAILED',
  RESET: 'DASHBOARD_SPM_CHART_SECTION_RESET',
};

export interface DashboardSPMChartSectionInitialState {
  dashboardSPMChartSectionLoading: boolean;
  dashboardSPMChartSectionError?: DashboardSPMChartSectionFailedPayload;
  dashboardSPMChartSectionResponse?: DashboardSPMChartSectionSuccessPayload;
  dashboardSPMChartSectionParams?: DashboardSPMChartSectionFetchPayload;
}

export interface DashboardSPMChartSectionFetchPayload {
  month: number;
  year: number;
}

export interface DashboardSPMChartSectionResponse {
  status: string;
  info?: number;
  data: DashboardSPMChartSection;
}

export type DashboardSPMChartSectionFailedPayload = CommonError;

export type DashboardSPMChartSectionSuccessPayload = DashboardSPMChartSectionResponse;

export type DashboardSPMChartSectionUpdatePayload = DashboardSPMChartSectionResponse;
