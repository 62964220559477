import {createAction} from '@reduxjs/toolkit';
import {
  DashboardTabDepartmentListAction,
  DashboardTabDepartmentListFailedPayload,
  DashboardTabDepartmentListSuccessPayload,
  DashboardTabDepartmentListUpdatePayload,
} from './dashboardTabDepartmentList.type';

export const dashboardTabDepartmentListAction = {
  fetch: createAction(DashboardTabDepartmentListAction.FETCH),
  success: createAction<DashboardTabDepartmentListSuccessPayload>(
    DashboardTabDepartmentListAction.SUCCESS,
  ),
  failed: createAction<DashboardTabDepartmentListFailedPayload>(
    DashboardTabDepartmentListAction.FAILED,
  ),
  update: createAction<DashboardTabDepartmentListUpdatePayload>(
    DashboardTabDepartmentListAction.UPDATE,
  ),
  reset: createAction(DashboardTabDepartmentListAction.RESET),
};
