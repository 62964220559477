import {
  MasterDataDetailObservationOptionListAction,
  MasterDataDetailObservationOptionListSuccessPayload,
  MasterDataDetailObservationOptionListFailedPayload,
  MasterDataDetailObservationOptionListFetchPayload,
} from './masterDataDetailObservationOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDetailObservationOptionListInitialState} from './masterDataDetailObservationOptionList.reducer';

export const masterDataDetailObservationOptionListHandler = {
  [MasterDataDetailObservationOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDetailObservationOptionListLoading: true,
      masterDataDetailObservationOptionListParams: action.payload,
      action: MasterDataDetailObservationOptionListAction.FETCH,
    };
  },
  [MasterDataDetailObservationOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationOptionListLoading: false,
    masterDataDetailObservationOptionListResponse: action.payload,
    action: MasterDataDetailObservationOptionListAction.SUCCESS,
  }),
  [MasterDataDetailObservationOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationOptionListLoading: false,
    masterDataDetailObservationOptionListData: [
      ...state.masterDataDetailObservationOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataDetailObservationOptionListAction.UPDATE,
  }),
  [MasterDataDetailObservationOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationOptionListLoading: false,
    masterDataDetailObservationOptionListError: action.payload,
    action: MasterDataDetailObservationOptionListAction.FAILED,
  }),
  [MasterDataDetailObservationOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailObservationOptionListInitialState,
    action: MasterDataDetailObservationOptionListAction.RESET,
  }),
};
