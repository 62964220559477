/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  masterDataIndicatorValueListAction,
  masterDataIndicatorValueDeleteAction,
  masterDataIndicatorValueSetPositiveValueAction,
  masterDataIndicatorValueCreateAction,
  masterDataIndicatorValueEditAction,
} from '../../../../usecases/masterData.action';
import {
  MasterDataIndicatorValueCreateAction,
  MasterDataIndicatorValueDeleteAction,
  MasterDataIndicatorValueEditAction,
  MasterDataIndicatorValueListAction,
  MasterDataIndicatorValueSetPositiveValueAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataIndicatorValueManagementList} from './components';
import MasterDataIndicatorValueFormCreateContainer from '../MasterDataIndicatorValueFormCreateContainer';
import MasterDataIndicatorValueFormEditContainer from '../MasterDataIndicatorValueFormEditContainer';
// Data
import {
  MasterDataIndicatorValue,
  MasterDataIndicatorValueOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataIndicatorValueSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataIndicatorValueSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddIndicatorValue, setAddIndicatorValue] = useState(false);
  const [isDeleteIndicatorValue, setIsDeleteIndicatorValue] = useState<
    MasterDataIndicatorValue | undefined
  >(undefined);
  const [isEditIndicatorValue, setIsEditIndicatorValue] = useState<
    MasterDataIndicatorValue | undefined
  >(undefined);
  const [isChangeStatusValue, setIsChangeStatusValue] = useState<
    MasterDataIndicatorValue | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddIndicatorValue =
    masterData.masterDataIndicatorValueCreateLoading;
  const isLoadingEditIndicatorValue =
    masterData.masterDataIndicatorValueEditLoading;
  const isLoadingDeleteIndicatorValue =
    masterData.masterDataIndicatorValueDeleteLoading;
  const isLoadingStatusIndicatorValue =
    masterData.masterDataIndicatorValueSetPositiveValueLoading;

  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataIndicatorValueListPage,
    setMasterDataIndicatorValueListPage,
  ] = useState<number>(0);
  const [
    masterDataIndicatorValueListCount,
    setMasterDataIndicatorValueListCount,
  ] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataIndicatorValueListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataIndicatorValueListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataIndicatorValueOrderByType>(
    'name',
  );

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataIndicatorValueOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH INDICATOR VALUE
  const fetchIndicatorValueList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataIndicatorValueListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataIndicatorValueListAction.reset());
    fetchIndicatorValueList(
      masterDataIndicatorValueListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataIndicatorValueListAction.reset());
    };
  }, [
    dispatch,
    fetchIndicatorValueList,
    masterDataIndicatorValueListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  //#endregion

  //#region ACTION HANDLER
  const masterDataIndicatorValueActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataIndicatorValueListAction.UPDATE, (): void => {
            setMasterDataIndicatorValueListCount(
              masterData?.masterDataIndicatorValueListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataIndicatorValueListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataIndicatorValueListAction.reset());
          })
          .addCase(MasterDataIndicatorValueCreateAction.SUCCESS, (): void => {
            setAddIndicatorValue(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataIndicatorValueCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataIndicatorValueCreateAction.reset());
          })
          .addCase(MasterDataIndicatorValueEditAction.SUCCESS, (): void => {
            setIsEditIndicatorValue(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataIndicatorValueEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataIndicatorValueEditAction.reset());
          })
          .addCase(MasterDataIndicatorValueDeleteAction.SUCCESS, (): void => {
            setIsDeleteIndicatorValue(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataIndicatorValueDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataIndicatorValueDeleteAction.reset());
          })
          .addCase(
            MasterDataIndicatorValueSetPositiveValueAction.SUCCESS,
            (): void => {
              setIsChangeStatusValue(undefined);
              refresh();
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Positive value update success!'),
                  type: 'success',
                  isSnackbar: true,
                  action: WrapperNotifyAction.FETCH,
                }),
              );
            },
          )
          .addCase(
            MasterDataIndicatorValueSetPositiveValueAction.FAILED,
            (): void => {
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Positive value update failed!'),
                  type: 'error',
                  isSnackbar: true,
                  action: WrapperNotifyAction.FETCH,
                }),
              );
              dispatch(masterDataIndicatorValueSetPositiveValueAction.reset());
            },
          );
      });
    },
    [
      dispatch,
      masterData?.masterDataIndicatorValueListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataIndicatorValueActionHandler(masterData.action);
  }, [masterData.action, masterDataIndicatorValueActionHandler]);
  //#endregion

  //#region HANDLE SET POSITIVE
  const handleChangeStatus = (id: string) => {
    dispatch(masterDataIndicatorValueSetPositiveValueAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataIndicatorValueDeleteAction.fetch({id}));
  };
  //#endregion

  return (
    <>
      <div className="master-data-indicator-value-section">
        <div className="master-data-indicator-value-section__header">
          <MSearchInput
            inputClassName="master-data-indicator-value-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Indicator Value')}
            setInputValue={(search: string) => {
              setMasterDataIndicatorValueListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-indicator-value-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddIndicatorValue(true)}
              />
            </div>
          )}
        </div>
        <MasterDataIndicatorValueManagementList
          indicatorValueList={masterData.masterDataIndicatorValueListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditIndicatorValue: (_IndicatorValue: MasterDataIndicatorValue) =>
              setIsEditIndicatorValue(_IndicatorValue),
            onChangeStatus: (_IndicatorValue: MasterDataIndicatorValue) =>
              setIsChangeStatusValue(_IndicatorValue),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteIndicatorValue: (
              _IndicatorValue: MasterDataIndicatorValue,
            ) => setIsDeleteIndicatorValue(_IndicatorValue),
          })}
        />
        <MTablePagination
          className="master-data-indicator-value-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataIndicatorValueListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataIndicatorValueListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddIndicatorValue(false)}
        title={t('Add Indicator Value')}
        isOpen={isAddIndicatorValue}>
        <MasterDataIndicatorValueFormCreateContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddIndicatorValue}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditIndicatorValue && (
        <Modal
          onClose={() => setIsEditIndicatorValue(undefined)}
          isOpen={!!isEditIndicatorValue}
          title={t('Edit Indicator Value')}>
          <MasterDataIndicatorValueFormEditContainer
            assignmentType={assignmentType}
            data={isEditIndicatorValue}
            isLoading={isLoadingEditIndicatorValue}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteIndicatorValue && (
        <AlertDialog
          onClose={() => setIsDeleteIndicatorValue(undefined)}
          onCancel={() => setIsDeleteIndicatorValue(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteIndicatorValue.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteIndicatorValue}
          isLoading={isLoadingDeleteIndicatorValue}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isChangeStatusValue && (
        <AlertDialog
          onClose={() => setIsChangeStatusValue(undefined)}
          onCancel={() => setIsChangeStatusValue(undefined)}
          onConfirm={() => handleChangeStatus(isChangeStatusValue.id)}
          cancelText={t('Cancel')}
          confirmText={t('Yes, Agree')}
          isOpen={!!isChangeStatusValue}
          isLoading={isLoadingStatusIndicatorValue}
          title={`
                  ${t('Select Indicator Value ')}
                  ${
                    isChangeStatusValue.isPositiveValue
                      ? t('Negative')
                      : t('Positive')
                  }? `}
          isReverseButton={true}
          description={`
            ${t('Selected indicator value will be ')}
            ${
              isChangeStatusValue.isPositiveValue
                ? t('negative value')
                : t('positive value')
            }? `}></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddIndicatorValue(false)
              : setIsEditIndicatorValue(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataIndicatorValueSection;
