import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userRoleAccessEditInitialState} from './userRoleAccessEdit.reducer';
import {
  UserRoleAccessEditAction,
  UserRoleAccessEditFailedPayload,
  UserRoleAccessEditFetchPayload,
  UserRoleAccessEditSuccessPayload,
} from './userRoleAccessEdit.type';

export const userRoleAccessEditHandler = {
  [UserRoleAccessEditAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserRoleAccessEditFetchPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessEditLoading: true,
    userRoleAccessEditParams: action.payload,
    action: UserRoleAccessEditAction.FETCH,
  }),
  [UserRoleAccessEditAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserRoleAccessEditSuccessPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessEditParams: undefined,
    userRoleAccessEditLoading: false,
    userRoleAccessEditResponse: action.payload,
    action: UserRoleAccessEditAction.SUCCESS,
  }),
  [UserRoleAccessEditAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserRoleAccessEditFailedPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessEditLoading: false,
    userRoleAccessEditError: action.payload,
    action: UserRoleAccessEditAction.FAILED,
  }),
  [UserRoleAccessEditAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userRoleAccessEditInitialState,
    action: UserRoleAccessEditAction.RESET,
  }),
};
