import {CommonError} from '../../../../../../config/constant';
import {MasterDataDirectorate} from '../../../../entity/masterData.string.entity';

export const MasterDataDirectorateOptionListAction = {
  FETCH: 'MASTER_DATA_DIRECTORATE_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DIRECTORATE_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DIRECTORATE_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_DIRECTORATE_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_DIRECTORATE_OPTION_LIST_UPDATE',
};

export interface MasterDataDirectorateOptionListInitialState {
  masterDataDirectorateOptionListLoading: boolean;
  masterDataDirectorateOptionListData: MasterDataDirectorate[];
  masterDataDirectorateOptionListError?: MasterDataDirectorateOptionListFailedPayload;
  masterDataDirectorateOptionListParams?: MasterDataDirectorateOptionListFetchPayload;
  masterDataDirectorateOptionListResponse?: MasterDataDirectorateOptionListSuccessPayload;
}

export type MasterDataDirectorateOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataDirectorate[];
};

export type MasterDataDirectorateOptionListFailedPayload = CommonError;

export type MasterDataDirectorateOptionListSuccessPayload = MasterDataDirectorateOptionListResponse;

export type MasterDataDirectorateOptionListUpdatePayload = MasterDataDirectorateOptionListResponse;

export interface MasterDataDirectorateOptionListFetchPayload {}
