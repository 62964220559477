//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import {MasterDataDetailIndicatorCreateFetchPayload} from '../../../../usecases/masterData.type';
// Utils
import {masterDataDetailIndicatorCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDetailIndicatorFormCreate} from './components';
import {DetailIndicatorFormCreateInitialValues} from './components/MasterDataDetailIndicatorFormCreate/MasterDataDetailIndicatorFormCreate.component';
// Assets
import './MasterDataDetailIndicatorFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDetailIndicatorFormCreateContainer: React.FC<Props> = ({
  assignmentType,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: DetailIndicatorFormCreateInitialValues = {
    detailIndicators: [{name: ''}],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDetailIndicator = (
    formValue: DetailIndicatorFormCreateInitialValues,
  ) => {
    const formattedValue: MasterDataDetailIndicatorCreateFetchPayload =
      formValue?.detailIndicators.map((item) => ({
        name: item.name,
        assignmentType,
      })) || [];
    dispatch(masterDataDetailIndicatorCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-detail-indicator-form-create-container">
      <MasterDataDetailIndicatorFormCreate
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitDetailIndicator={handleSubmitDetailIndicator}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataDetailIndicatorFormCreateContainer;
