/* eslint-disable max-lines */
import {createReducer} from '@reduxjs/toolkit';

//#region TYPE
//#region FORM
//#region FORM CONTROLLER
import {MasterDataFormControllerCreateInitialState} from './section/form/masterDataFormControllerCreate/masterDataFormControllerCreate.type';
import {MasterDataFormControllerListInitialState} from './section/form/masterDataFormControllerList/masterDataFormControllerList.type';
import {MasterDataFormControllerDeleteInitialState} from './section/form/masterDataFormControllerDelete/masterDataFormControllerDelete.type';
import {MasterDataFormControllerEditInitialState} from './section/form/masterDataFormControllerEdit/masterDataFormControllerEdit.type';
import {MasterDataFormControllerMoveInitialState} from './section/form/masterDataFormControllerMove/masterDataFormControllerMove.type';
//#endregion
//#region BASIC SERVICE TYPE
import {MasterDataBasicServiceTypeCreateInitialState} from './section/form/masterDataBasicServiceTypeCreate/masterDataBasicServiceTypeCreate.type';
import {MasterDataBasicServiceTypeListInitialState} from './section/form/masterDataBasicServiceTypeList/masterDataBasicServiceTypeList.type';
import {MasterDataBasicServiceTypeMoveInitialState} from './section/form/masterDataBasicServiceTypeMove/masterDataBasicServiceTypeMove.type';
import {MasterDataBasicServiceTypeOptionListInitialState} from './section/form/masterDataBasicServiceTypeOptionList/masterDataBasicServiceTypeOptionList.type';
import {MasterDataBasicServiceTypeDeleteInitialState} from './section/form/masterDataBasicServiceTypeDelete/masterDataBasicServiceTypeDelete.type';
import {MasterDataBasicServiceTypeEditInitialState} from './section/form/masterDataBasicServiceTypeEdit/masterDataBasicServiceTypeEdit.type';
//#endregion
//#region CATEGORY
import {MasterDataCategoryCreateInitialState} from './section/form/masterDataCategoryCreate/masterDataCategoryCreate.type';
import {MasterDataCategoryListInitialState} from './section/form/masterDataCategoryList/masterDataCategoryList.type';
import {MasterDataCategoryDeleteInitialState} from './section/form/masterDataCategoryDelete/masterDataCategoryDelete.type';
import {MasterDataCategoryEditInitialState} from './section/form/masterDataCategoryEdit/masterDataCategoryEdit.type';
import {MasterDataCategoryMoveInitialState} from './section/form/masterDataCategoryMove/masterDataCategoryMove.type';
import {MasterDataCategoryOptionListInitialState} from './section/form/masterDataCategoryOptionList/masterDataCategoryOptionList.type';
//#endregion
//#region REVIEW INDICATOR
import {MasterDataReviewIndicatorCreateInitialState} from './section/form/masterDataReviewIndicatorCreate/masterDataReviewIndicatorCreate.type';
import {MasterDataReviewIndicatorListInitialState} from './section/form/masterDataReviewIndicatorList/masterDataReviewIndicatorList.type';
import {MasterDataReviewIndicatorOptionListInitialState} from './section/form/masterDataReviewIndicatorOptionList/masterDataReviewIndicatorOptionList.type';
import {MasterDataReviewIndicatorDeleteInitialState} from './section/form/masterDataReviewIndicatorDelete/masterDataReviewIndicatorDelete.type';
import {MasterDataReviewIndicatorEditInitialState} from './section/form/masterDataReviewIndicatorEdit/masterDataReviewIndicatorEdit.type';
import {MasterDataReviewIndicatorMoveInitialState} from './section/form/masterDataReviewIndicatorMove/masterDataReviewIndicatorMove.type';
//#endregion
//#region DETAIL INDICATOR
import {MasterDataDetailIndicatorCreateInitialState} from './section/form/masterDataDetailIndicatorCreate/masterDataDetailIndicatorCreate.type';
import {MasterDataDetailIndicatorListInitialState} from './section/form/masterDataDetailIndicatorList/masterDataDetailIndicatorList.type';
import {MasterDataDetailIndicatorOptionListInitialState} from './section/form/masterDataDetailIndicatorOptionList/masterDataDetailIndicatorOptionList.type';
import {MasterDataDetailIndicatorDeleteInitialState} from './section/form/masterDataDetailIndicatorDelete/masterDataDetailIndicatorDelete.type';
import {MasterDataDetailIndicatorEditInitialState} from './section/form/masterDataDetailIndicatorEdit/masterDataDetailIndicatorEdit.type';
//#endregion
//#region INDICATOR VALUE
import {MasterDataIndicatorValueCreateInitialState} from './section/form/masterDataIndicatorValueCreate/masterDataIndicatorValueCreate.type';
import {MasterDataIndicatorValueListInitialState} from './section/form/masterDataIndicatorValueList/masterDataIndicatorValueList.type';
import {MasterDataIndicatorValueOptionListInitialState} from './section/form/masterDataIndicatorValueOptionList/masterDataIndicatorValueOptionList.type';
import {MasterDataIndicatorValueDeleteInitialState} from './section/form/masterDataIndicatorValueDelete/masterDataIndicatorValueDelete.type';
import {MasterDataIndicatorValueEditInitialState} from './section/form/masterDataIndicatorValueEdit/masterDataIndicatorValueEdit.type';
import {MasterDataIndicatorValueSetPositiveValueInitialState} from './section/form/masterDataIndicatorValueSetPositiveValue/masterDataIndicatorValueSetPositiveValue.type';
//#endregion
//#region DETAIL OBSERVATION
import {MasterDataDetailObservationCreateInitialState} from './section/form/masterDataDetailObservationCreate/masterDataDetailObservationCreate.type';
import {MasterDataDetailObservationListInitialState} from './section/form/masterDataDetailObservationList/masterDataDetailObservationList.type';
import {MasterDataDetailObservationOptionListInitialState} from './section/form/masterDataDetailObservationOptionList/masterDataDetailObservationOptionList.type';
import {MasterDataDetailObservationDeleteInitialState} from './section/form/masterDataDetailObservationDelete/masterDataDetailObservationDelete.type';
import {MasterDataDetailObservationEditInitialState} from './section/form/masterDataDetailObservationEdit/masterDataDetailObservationEdit.type';
//#endregion
//#region OBSERVATION VALUE
import {MasterDataObservationValueCreateInitialState} from './section/form/masterDataObservationValueCreate/masterDataObservationValueCreate.type';
import {MasterDataObservationValueListInitialState} from './section/form/masterDataObservationValueList/masterDataObservationValueList.type';
import {MasterDataObservationValueOptionListInitialState} from './section/form/masterDataObservationValueOptionList/masterDataObservationValueOptionList.type';
import {MasterDataObservationValueDeleteInitialState} from './section/form/masterDataObservationValueDelete/masterDataObservationValueDelete.type';
import {MasterDataObservationValueSetPositiveValueInitialState} from './section/form/masterDataObservationValueSetPositiveValue/masterDataObservationValueSetPositiveValue.type';
import {MasterDataObservationValueEditInitialState} from './section/form/masterDataObservationValueEdit/masterDataObservationValueEdit.type';
//#endregion
//#region OBSERVATION
import {MasterDataObservationCreateInitialState} from './section/form/masterDataObservationCreate/masterDataObservationCreate.type';
import {MasterDataObservationListInitialState} from './section/form/masterDataObservationList/masterDataObservationList.type';
import {MasterDataObservationOptionListInitialState} from './section/form/masterDataObservationOptionList/masterDataObservationOptionList.type';
import {MasterDataObservationDeleteInitialState} from './section/form/masterDataObservationDelete/masterDataObservationDelete.type';
import {MasterDataObservationEditInitialState} from './section/form/masterDataObservationEdit/masterDataObservationEdit.type';
//#endregion
//#endregion

//#region OFFICE
//#region DIRECTORATE
import {MasterDataDirectorateCreateInitialState} from './section/office/masterDataDirectorateCreate/masterDataDirectorateCreate.type';
import {MasterDataDirectorateListInitialState} from './section/office/masterDataDirectorateList/masterDataDirectorateList.type';
import {MasterDataDirectorateDeleteInitialState} from './section/office/masterDataDirectorateDelete/masterDataDirectorateDelete.type';
import {MasterDataDirectorateEditInitialState} from './section/office/masterDataDirectorateEdit/masterDataDirectorateEdit.type';
import {MasterDataDirectorateOptionListInitialState} from './section/office/masterDataDirectorateOptionList/masterDataDirectorateOptionList.type';
//#endregion
//#region DIVISION
import {MasterDataDivisionCreateInitialState} from './section/office/masterDataDivisionCreate/masterDataDivisionCreate.type';
import {MasterDataDivisionListInitialState} from './section/office/masterDataDivisionList/masterDataDivisionList.type';
import {MasterDataDivisionDeleteInitialState} from './section/office/masterDataDivisionDelete/masterDataDivisionDelete.type';
import {MasterDataDivisionEditInitialState} from './section/office/masterDataDivisionEdit/masterDataDivisionEdit.type';
import {MasterDataDivisionOptionListInitialState} from './section/office/masterDataDivisionOptionList/masterDataDivisionOptionList.type';
//#endregion
//#region DEPARTMENT
import {MasterDataDepartmentCreateInitialState} from './section/office/masterDataDepartmentCreate/masterDataDepartmentCreate.type';
import {MasterDataDepartmentListInitialState} from './section/office/masterDataDepartmentList/masterDataDepartmentList.type';
import {MasterDataDepartmentDeleteInitialState} from './section/office/masterDataDepartmentDelete/masterDataDepartmentDelete.type';
import {MasterDataDepartmentEditInitialState} from './section/office/masterDataDepartmentEdit/masterDataDepartmentEdit.type';
import {MasterDataDepartmentOptionListInitialState} from './section/office/masterDataDepartmentOptionList/masterDataDepartmentOptionList.type';
//#endregion
//#region WORK INSTRUCTION
import {MasterDataWorkInstructionListInitialState} from './section/office/masterDataWorkInstructionList/masterDataWorkInstructionList.type';
import {MasterDataWorkInstructionEditInitialState} from './section/office/masterDataWorkInstructionEdit/masterDataWorkInstructionEdit.type';
//#endregion
//#region ASSIGNMENT LETTER
import {MasterDataAssignmentLetterCreateInitialState} from './section/office/masterDataAssignmentLetterCreate/masterDataAssignmentLetterCreate.type';
import {MasterDataAssignmentLetterListInitialState} from './section/office/masterDataAssignmentLetterList/masterDataAssignmentLetterList.type';
import {MasterDataAssignmentLetterDeleteInitialState} from './section/office/masterDataAssignmentLetterDelete/masterDataAssignmentLetterDelete.type';
import {MasterDataAssignmentLetterEditInitialState} from './section/office/masterDataAssignmentLetterEdit/masterDataAssignmentLetterEdit.type';
import {MasterDataAssignmentLetterSetActiveInitialState} from './section/office/masterDataAssignmentLetterSetActive/masterDataAssignmentLetterSetActive.type';
//#endregion
//#endregion

//#region OPERATIONAL
//#region BUS
import {MasterDataBusListInitialState} from './section/operational/masterDataBusList/masterDataBusList.type';
import {MasterDataBusCreateInitialState} from './section/operational/masterDataBusCreate/masterDataBusCreate.type';
import {MasterDataBusDeleteInitialState} from './section/operational/masterDataBusDelete/masterDataBusDelete.type';
import {MasterDataBusEditInitialState} from './section/operational/masterDataBusEdit/masterDataBusEdit.type';

import {MasterDataBusOptionListInitialState} from './section/operational/masterDataBusOptionList/masterDataBusOptionList.type';
//#endregion
//#region RUTE

import {MasterDataRuteListInitialState} from './section/operational/masterDataRuteList/masterDataRuteList.type';
import {MasterDataRouteCreateInitialState} from './section/operational/masterDataRouteCreate/masterDataRouteCreate.type';
import {MasterDataRouteDeleteInitialState} from './section/operational/masterDataRouteDelete/masterDataRouteDelete.type';
import {MasterDataRouteEditInitialState} from './section/operational/masterDataRouteEdit/masterDataRouteEdit.type';

//#endregion
//#region COUNTER
import {MasterDataCounterListInitialState} from './section/operational/masterDataCounterList/masterDataCounterList.type';
//#endregion
//#endregion
//#endregion

//#region REDUCER
//#region FORM
//#region FORM CONTROLLER
import {masterDataFormControllerCreateInitialState} from './section/form/masterDataFormControllerCreate/masterDataFormControllerCreate.reducer';
import {masterDataFormControllerListInitialState} from './section/form/masterDataFormControllerList/masterDataFormControllerList.reducer';
import {masterDataFormControllerDeleteInitialState} from './section/form/masterDataFormControllerDelete/masterDataFormControllerDelete.reducer';
import {masterDataFormControllerEditInitialState} from './section/form/masterDataFormControllerEdit/masterDataFormControllerEdit.reducer';
import {masterDataFormControllerMoveInitialState} from './section/form/masterDataFormControllerMove/masterDataFormControllerMove.reducer';
//#endregion
//#region BASIC SERVICE TYPE
import {masterDataBasicServiceTypeCreateInitialState} from './section/form/masterDataBasicServiceTypeCreate/masterDataBasicServiceTypeCreate.reducer';
import {masterDataBasicServiceTypeListInitialState} from './section/form/masterDataBasicServiceTypeList/masterDataBasicServiceTypeList.reducer';
import {masterDataBasicServiceTypeMoveInitialState} from './section/form/masterDataBasicServiceTypeMove/masterDataBasicServiceTypeMove.reducer';
import {masterDataBasicServiceTypeOptionListInitialState} from './section/form/masterDataBasicServiceTypeOptionList/masterDataBasicServiceTypeOptionList.reducer';
import {masterDataBasicServiceTypeDeleteInitialState} from './section/form/masterDataBasicServiceTypeDelete/masterDataBasicServiceTypeDelete.reducer';
import {masterDataBasicServiceTypeEditInitialState} from './section/form/masterDataBasicServiceTypeEdit/masterDataBasicServiceTypeEdit.reducer';
//#endregion
//#region CATEGORY
import {masterDataCategoryCreateInitialState} from './section/form/masterDataCategoryCreate/masterDataCategoryCreate.reducer';
import {masterDataCategoryListInitialState} from './section/form/masterDataCategoryList/masterDataCategoryList.reducer';
import {masterDataCategoryMoveInitialState} from './section/form/masterDataCategoryMove/masterDataCategoryMove.reducer';
import {masterDataCategoryDeleteInitialState} from './section/form/masterDataCategoryDelete/masterDataCategoryDelete.reducer';
import {masterDataCategoryEditInitialState} from './section/form/masterDataCategoryEdit/masterDataCategoryEdit.reducer';
import {masterDataCategoryOptionListInitialState} from './section/form/masterDataCategoryOptionList/masterDataCategoryOptionList.reducer';
//#endregion
//#region REVIEW INDICATOR
import {masterDataReviewIndicatorCreateInitialState} from './section/form/masterDataReviewIndicatorCreate/masterDataReviewIndicatorCreate.reducer';
import {masterDataReviewIndicatorListInitialState} from './section/form/masterDataReviewIndicatorList/masterDataReviewIndicatorList.reducer';
import {masterDataReviewIndicatorMoveInitialState} from './section/form/masterDataReviewIndicatorMove/masterDataReviewIndicatorMove.reducer';
import {masterDataReviewIndicatorOptionListInitialState} from './section/form/masterDataReviewIndicatorOptionList/masterDataReviewIndicatorOptionList.reducer';
import {masterDataReviewIndicatorDeleteInitialState} from './section/form/masterDataReviewIndicatorDelete/masterDataReviewIndicatorDelete.reducer';
import {masterDataReviewIndicatorEditInitialState} from './section/form/masterDataReviewIndicatorEdit/masterDataReviewIndicatorEdit.reducer';
//#endregion
//#region DETAIL INDICATOR
import {masterDataDetailIndicatorCreateInitialState} from './section/form/masterDataDetailIndicatorCreate/masterDataDetailIndicatorCreate.reducer';
import {masterDataDetailIndicatorListInitialState} from './section/form/masterDataDetailIndicatorList/masterDataDetailIndicatorList.reducer';
import {masterDataDetailIndicatorOptionListInitialState} from './section/form/masterDataDetailIndicatorOptionList/masterDataDetailIndicatorOptionList.reducer';
import {masterDataDetailIndicatorDeleteInitialState} from './section/form/masterDataDetailIndicatorDelete/masterDataDetailIndicatorDelete.reducer';
import {masterDataDetailIndicatorEditInitialState} from './section/form/masterDataDetailIndicatorEdit/masterDataDetailIndicatorEdit.reducer';
//#endregion
//#region INDICATOR VALUE
import {masterDataIndicatorValueCreateInitialState} from './section/form/masterDataIndicatorValueCreate/masterDataIndicatorValueCreate.reducer';
import {masterDataIndicatorValueListInitialState} from './section/form/masterDataIndicatorValueList/masterDataIndicatorValueList.reducer';
import {masterDataIndicatorValueOptionListInitialState} from './section/form/masterDataIndicatorValueOptionList/masterDataIndicatorValueOptionList.reducer';
import {masterDataIndicatorValueDeleteInitialState} from './section/form/masterDataIndicatorValueDelete/masterDataIndicatorValueDelete.reducer';
import {masterDataIndicatorValueEditInitialState} from './section/form/masterDataIndicatorValueEdit/masterDataIndicatorValueEdit.reducer';
import {masterDataIndicatorValueSetPositiveValueInitialState} from './section/form/masterDataIndicatorValueSetPositiveValue/masterDataIndicatorValueSetPositiveValue.reducer';
//#endregion
//#region DETAIL OBSERVATION
import {masterDataDetailObservationCreateInitialState} from './section/form/masterDataDetailObservationCreate/masterDataDetailObservationCreate.reducer';
import {masterDataDetailObservationListInitialState} from './section/form/masterDataDetailObservationList/masterDataDetailObservationList.reducer';
import {masterDataDetailObservationOptionListInitialState} from './section/form/masterDataDetailObservationOptionList/masterDataDetailObservationOptionList.reducer';
import {masterDataDetailObservationDeleteInitialState} from './section/form/masterDataDetailObservationDelete/masterDataDetailObservationDelete.reducer';
import {masterDataDetailObservationEditInitialState} from './section/form/masterDataDetailObservationEdit/masterDataDetailObservationEdit.reducer';
//#endregion
//#region OBSERVATION VALUE
import {masterDataObservationValueCreateInitialState} from './section/form/masterDataObservationValueCreate/masterDataObservationValueCreate.reducer';
import {masterDataObservationValueListInitialState} from './section/form/masterDataObservationValueList/masterDataObservationValueList.reducer';
import {masterDataObservationValueOptionListInitialState} from './section/form/masterDataObservationValueOptionList/masterDataObservationValueOptionList.reducer';
import {masterDataObservationValueDeleteInitialState} from './section/form/masterDataObservationValueDelete/masterDataObservationValueDelete.reducer';
import {masterDataObservationValueSetPositiveValueInitialState} from './section/form/masterDataObservationValueSetPositiveValue/masterDataObservationValueSetPositiveValue.reducer';
import {masterDataObservationValueEditInitialState} from './section/form/masterDataObservationValueEdit/masterDataObservationValueEdit.reducer';
//#endregion
//#region OBSERVATION
import {masterDataObservationCreateInitialState} from './section/form/masterDataObservationCreate/masterDataObservationCreate.reducer';
import {masterDataObservationOptionListInitialState} from './section/form/masterDataObservationOptionList/masterDataObservationOptionList.reducer';
import {masterDataObservationListInitialState} from './section/form/masterDataObservationList/masterDataObservationList.reducer';
import {masterDataObservationDeleteInitialState} from './section/form/masterDataObservationDelete/masterDataObservationDelete.reducer';
import {masterDataObservationEditInitialState} from './section/form/masterDataObservationEdit/masterDataObservationEdit.reducer';
//#endregion
//#endregion

//#region OFFICE
//#region DIRECTORATE
import {masterDataDirectorateCreateInitialState} from './section/office/masterDataDirectorateCreate/masterDataDirectorateCreate.reducer';
import {masterDataDirectorateListInitialState} from './section/office/masterDataDirectorateList/masterDataDirectorateList.reducer';
import {masterDataDirectorateDeleteInitialState} from './section/office/masterDataDirectorateDelete/masterDataDirectorateDelete.reducer';
import {masterDataDirectorateEditInitialState} from './section/office/masterDataDirectorateEdit/masterDataDirectorateEdit.reducer';
import {masterDataDirectorateOptionListInitialState} from './section/office/masterDataDirectorateOptionList/masterDataDirectorateOptionList.reducer';
//#endregion
//#region DIVISION
import {masterDataDivisionCreateInitialState} from './section/office/masterDataDivisionCreate/masterDataDivisionCreate.reducer';
import {masterDataDivisionListInitialState} from './section/office/masterDataDivisionList/masterDataDivisionList.reducer';
import {masterDataDivisionDeleteInitialState} from './section/office/masterDataDivisionDelete/masterDataDivisionDelete.reducer';
import {masterDataDivisionEditInitialState} from './section/office/masterDataDivisionEdit/masterDataDivisionEdit.reducer';
import {masterDataDivisionOptionListInitialState} from './section/office/masterDataDivisionOptionList/masterDataDivisionOptionList.reducer';
//#endregion
//#region DEPARTMENT
import {masterDataDepartmentCreateInitialState} from './section/office/masterDataDepartmentCreate/masterDataDepartmentCreate.reducer';
import {masterDataDepartmentListInitialState} from './section/office/masterDataDepartmentList/masterDataDepartmentList.reducer';
import {masterDataDepartmentDeleteInitialState} from './section/office/masterDataDepartmentDelete/masterDataDepartmentDelete.reducer';
import {masterDataDepartmentEditInitialState} from './section/office/masterDataDepartmentEdit/masterDataDepartmentEdit.reducer';
import {masterDataDepartmentOptionListInitialState} from './section/office/masterDataDepartmentOptionList/masterDataDepartmentOptionList.reducer';
//#endregion
//#region WORK INSTRUCTION
import {masterDataWorkInstructionListInitialState} from './section/office/masterDataWorkInstructionList/masterDataWorkInstructionList.reducer';
import {masterDataWorkInstructionEditInitialState} from './section/office/masterDataWorkInstructionEdit/masterDataWorkInstructionEdit.reducer';
//#endregion
//#region ASSIGNMENT LETTER
import {masterDataAssignmentLetterCreateInitialState} from './section/office/masterDataAssignmentLetterCreate/masterDataAssignmentLetterCreate.reducer';
import {masterDataAssignmentLetterListInitialState} from './section/office/masterDataAssignmentLetterList/masterDataAssignmentLetterList.reducer';
import {masterDataAssignmentLetterDeleteInitialState} from './section/office/masterDataAssignmentLetterDelete/masterDataAssignmentLetterDelete.reducer';
import {masterDataAssignmentLetterEditInitialState} from './section/office/masterDataAssignmentLetterEdit/masterDataAssignmentLetterEdit.reducer';
import {masterDataAssignmentLetterSetActiveInitialState} from './section/office/masterDataAssignmentLetterSetActive/masterDataAssignmentLetterSetActive.reducer';
//#endregion
//#endregion

//#region OPERATIONAL
//#region BUS
import {masterDataBusCreateInitialState} from './section/operational/masterDataBusCreate/masterDataBusCreate.reducer';
import {masterDataBusDeleteInitialState} from './section/operational/masterDataBusDelete/masterDataBusDelete.reducer';
import {masterDataBusEditInitialState} from './section/operational/masterDataBusEdit/masterDataBusEdit.reducer';
import {masterDataBusListInitialState} from './section/operational/masterDataBusList/masterDataBusList.reducer';
import {masterDataBusOptionListInitialState} from './section/operational/masterDataBusOptionList/masterDataBusOptionList.reducer';
//#endregion
//#region RUTE
import {masterDataRouteCreateInitialState} from './section/operational/masterDataRouteCreate/masterDataRouteCreate.reducer';
import {masterDataRouteDeleteInitialState} from './section/operational/masterDataRouteDelete/masterDataRouteDelete.reducer';
import {masterDataRouteEditInitialState} from './section/operational/masterDataRouteEdit/masterDataRouteEdit.reducer';
import {masterDataRuteListInitialState} from './section/operational/masterDataRuteList/masterDataRuteList.reducer';
//#endregion
//#region COUNTER
import {masterDataCounterListInitialState} from './section/operational/masterDataCounterList/masterDataCounterList.reducer';
//#endregion
//#endregion
//#endregion

import masterDataHandler from './masterData.handler';

export interface MasterDataState
  //#region FORM
  //#region BASIC SERVICE TYPE
  extends MasterDataBasicServiceTypeCreateInitialState,
    MasterDataBasicServiceTypeListInitialState,
    MasterDataBasicServiceTypeOptionListInitialState,
    MasterDataBasicServiceTypeDeleteInitialState,
    MasterDataBasicServiceTypeEditInitialState,
    MasterDataBasicServiceTypeMoveInitialState,
    //#endregion
    //#region FORM CONTROLLER
    MasterDataFormControllerCreateInitialState,
    MasterDataFormControllerListInitialState,
    MasterDataFormControllerDeleteInitialState,
    MasterDataFormControllerEditInitialState,
    MasterDataFormControllerMoveInitialState,
    //#endregion
    //#region CATEGORY
    MasterDataCategoryCreateInitialState,
    MasterDataCategoryListInitialState,
    MasterDataCategoryOptionListInitialState,
    MasterDataCategoryDeleteInitialState,
    MasterDataCategoryEditInitialState,
    MasterDataCategoryMoveInitialState,
    //#endregion
    //#region DETAIL OBSERVATION
    MasterDataDetailObservationCreateInitialState,
    MasterDataDetailObservationListInitialState,
    MasterDataDetailObservationOptionListInitialState,
    MasterDataDetailObservationDeleteInitialState,
    MasterDataDetailObservationEditInitialState,
    //#endregion
    //#region REVIEW INDICATOR
    MasterDataReviewIndicatorCreateInitialState,
    MasterDataReviewIndicatorListInitialState,
    MasterDataReviewIndicatorOptionListInitialState,
    MasterDataReviewIndicatorDeleteInitialState,
    MasterDataReviewIndicatorEditInitialState,
    MasterDataReviewIndicatorMoveInitialState,
    //#endregion
    //#region DETAIL INDICATOR
    MasterDataDetailIndicatorCreateInitialState,
    MasterDataDetailIndicatorListInitialState,
    MasterDataDetailIndicatorOptionListInitialState,
    MasterDataDetailIndicatorDeleteInitialState,
    MasterDataDetailIndicatorEditInitialState,
    //#endregion
    //#region INDICATOR VALUE
    MasterDataIndicatorValueCreateInitialState,
    MasterDataIndicatorValueListInitialState,
    MasterDataIndicatorValueOptionListInitialState,
    MasterDataIndicatorValueDeleteInitialState,
    MasterDataIndicatorValueEditInitialState,
    MasterDataIndicatorValueSetPositiveValueInitialState,
    //#endregion
    //#region OBSERVATION VALUE
    MasterDataObservationValueCreateInitialState,
    MasterDataObservationValueListInitialState,
    MasterDataObservationValueOptionListInitialState,
    MasterDataObservationValueDeleteInitialState,
    MasterDataObservationValueSetPositiveValueInitialState,
    MasterDataObservationValueEditInitialState,
    //#endregion,
    //#region OBSERVATION
    MasterDataObservationCreateInitialState,
    MasterDataObservationListInitialState,
    MasterDataObservationOptionListInitialState,
    MasterDataObservationDeleteInitialState,
    MasterDataObservationEditInitialState,
    //#endregion
    //#endregion
    //#endregion
    //#region OFFICE
    //#region DIRECTORATE
    MasterDataDirectorateCreateInitialState,
    MasterDataDirectorateListInitialState,
    MasterDataDirectorateDeleteInitialState,
    MasterDataDirectorateEditInitialState,
    MasterDataDirectorateOptionListInitialState,
    //#endregion
    //#region DIVISION
    MasterDataDivisionCreateInitialState,
    MasterDataDivisionListInitialState,
    MasterDataDivisionDeleteInitialState,
    MasterDataDivisionEditInitialState,
    MasterDataDivisionOptionListInitialState,
    //#endregion
    //#region DEPARTMENT
    MasterDataDepartmentCreateInitialState,
    MasterDataDepartmentListInitialState,
    MasterDataDepartmentDeleteInitialState,
    MasterDataDepartmentEditInitialState,
    MasterDataDepartmentOptionListInitialState,
    //#endregion
    //#region WORK INSTRUCTION
    MasterDataWorkInstructionListInitialState,
    MasterDataWorkInstructionEditInitialState,
    //#endregion
    //#region ASSIGNMENT LETTER
    MasterDataAssignmentLetterCreateInitialState,
    MasterDataAssignmentLetterListInitialState,
    MasterDataAssignmentLetterDeleteInitialState,
    MasterDataAssignmentLetterEditInitialState,
    MasterDataAssignmentLetterSetActiveInitialState,
    //#endregion
    //#endregion
    //#region OPERATIONAL
    //#region BUS
    MasterDataBusCreateInitialState,
    MasterDataBusEditInitialState,
    MasterDataBusDeleteInitialState,
    MasterDataBusListInitialState,
    MasterDataBusOptionListInitialState,
    //#endregion
    //#region RUTE
    MasterDataRouteCreateInitialState,
    MasterDataRouteEditInitialState,
    MasterDataRouteDeleteInitialState,
    MasterDataRuteListInitialState,
    //#endregion
    //#region COUNTER
    MasterDataCounterListInitialState {
  //#endregion
  //#endregion
  action: string;
}

export const masterDataInitialState: MasterDataState = {
  //#region FORM
  //#region FORM CONTROLLER
  ...masterDataFormControllerCreateInitialState,
  ...masterDataFormControllerListInitialState,
  ...masterDataFormControllerDeleteInitialState,
  ...masterDataFormControllerEditInitialState,
  ...masterDataFormControllerMoveInitialState,
  //#endregion
  //#region BASIC SERVICE TYPE
  ...masterDataBasicServiceTypeCreateInitialState,
  ...masterDataBasicServiceTypeListInitialState,
  ...masterDataBasicServiceTypeOptionListInitialState,
  ...masterDataBasicServiceTypeMoveInitialState,
  ...masterDataBasicServiceTypeDeleteInitialState,
  ...masterDataBasicServiceTypeEditInitialState,
  //#endregion
  //#region CATEGORY
  ...masterDataCategoryCreateInitialState,
  ...masterDataCategoryListInitialState,
  ...masterDataCategoryDeleteInitialState,
  ...masterDataCategoryEditInitialState,
  ...masterDataCategoryMoveInitialState,
  ...masterDataCategoryOptionListInitialState,
  //#endregion
  //#region DETAIL OBSERVATION
  ...masterDataDetailObservationCreateInitialState,
  ...masterDataDetailObservationListInitialState,
  ...masterDataDetailObservationOptionListInitialState,
  ...masterDataDetailObservationDeleteInitialState,
  ...masterDataDetailObservationEditInitialState,
  //#endregion
  //#region REVIEW INDICATOR
  ...masterDataReviewIndicatorCreateInitialState,
  ...masterDataReviewIndicatorListInitialState,
  ...masterDataReviewIndicatorOptionListInitialState,
  ...masterDataReviewIndicatorDeleteInitialState,
  ...masterDataReviewIndicatorEditInitialState,
  ...masterDataReviewIndicatorMoveInitialState,
  //#endregion
  //#region DETAIL INDICATOR
  ...masterDataDetailIndicatorCreateInitialState,
  ...masterDataDetailIndicatorListInitialState,
  ...masterDataDetailIndicatorOptionListInitialState,
  ...masterDataDetailIndicatorDeleteInitialState,
  ...masterDataDetailIndicatorEditInitialState,
  //#endregion
  //#region INDICATOR VALUE
  ...masterDataIndicatorValueCreateInitialState,
  ...masterDataIndicatorValueListInitialState,
  ...masterDataIndicatorValueOptionListInitialState,
  ...masterDataIndicatorValueDeleteInitialState,
  ...masterDataIndicatorValueEditInitialState,
  ...masterDataIndicatorValueSetPositiveValueInitialState,
  //#endregion
  //#region OBSERVATION VALUE
  ...masterDataObservationValueCreateInitialState,
  ...masterDataObservationValueListInitialState,
  ...masterDataObservationValueOptionListInitialState,
  ...masterDataObservationValueDeleteInitialState,
  ...masterDataObservationValueSetPositiveValueInitialState,
  ...masterDataObservationValueEditInitialState,
  //#endregion
  //#region OBSERVATION
  ...masterDataObservationCreateInitialState,
  ...masterDataObservationListInitialState,
  ...masterDataObservationOptionListInitialState,
  ...masterDataObservationDeleteInitialState,
  ...masterDataObservationEditInitialState,
  //#endregion
  //#endregion
  //#region OFFICE
  //#region DIRECTORATE
  ...masterDataDirectorateCreateInitialState,
  ...masterDataDirectorateListInitialState,
  ...masterDataDirectorateDeleteInitialState,
  ...masterDataDirectorateEditInitialState,
  ...masterDataDirectorateOptionListInitialState,
  //#endregion
  //#region DIVISION
  ...masterDataDivisionCreateInitialState,
  ...masterDataDivisionListInitialState,
  ...masterDataDivisionDeleteInitialState,
  ...masterDataDivisionEditInitialState,
  ...masterDataDivisionOptionListInitialState,
  //#endregion
  //#region DEPARTMENT
  ...masterDataDepartmentCreateInitialState,
  ...masterDataDepartmentListInitialState,
  ...masterDataDepartmentDeleteInitialState,
  ...masterDataDepartmentEditInitialState,
  ...masterDataDepartmentOptionListInitialState,
  //#endregion
  //#region WORK INSTRUCTION
  ...masterDataWorkInstructionListInitialState,
  ...masterDataWorkInstructionEditInitialState,
  //#endregion
  //#region ASSIGNMENT LETTER
  ...masterDataAssignmentLetterCreateInitialState,
  ...masterDataAssignmentLetterListInitialState,
  ...masterDataAssignmentLetterDeleteInitialState,
  ...masterDataAssignmentLetterEditInitialState,
  ...masterDataAssignmentLetterSetActiveInitialState,
  //#endregion
  //#endregion
  //#region OPERATIONAL
  //#region BUS
  ...masterDataBusEditInitialState,
  ...masterDataBusCreateInitialState,
  ...masterDataBusDeleteInitialState,
  ...masterDataBusListInitialState,
  ...masterDataBusOptionListInitialState,
  //#endregion
  //#region RUTE
  ...masterDataRouteEditInitialState,
  ...masterDataRouteCreateInitialState,
  ...masterDataRouteDeleteInitialState,
  ...masterDataRuteListInitialState,
  //#endregion
  //#region COUNTER
  ...masterDataCounterListInitialState,
  //#endregion
  //#endregion
  action: '',
};

export default createReducer(masterDataInitialState, masterDataHandler);
