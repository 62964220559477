import {createAction} from '@reduxjs/toolkit';
import {
  DashboardDepartmentChartSectionAction,
  DashboardDepartmentChartSectionFailedPayload,
  DashboardDepartmentChartSectionFetchPayload,
  DashboardDepartmentChartSectionSuccessPayload,
} from './dashboardDepartmentChartSection.type';

export const dashboardDepartmentChartSectionAction = {
  fetch: createAction<DashboardDepartmentChartSectionFetchPayload>(
    DashboardDepartmentChartSectionAction.FETCH,
  ),
  success: createAction<DashboardDepartmentChartSectionSuccessPayload>(
    DashboardDepartmentChartSectionAction.SUCCESS,
  ),
  failed: createAction<DashboardDepartmentChartSectionFailedPayload>(
    DashboardDepartmentChartSectionAction.FAILED,
  ),
  reset: createAction(DashboardDepartmentChartSectionAction.RESET),
};
