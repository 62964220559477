import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataObservationValueSetPositiveValueInitialState} from './masterDataObservationValueSetPositiveValue.reducer';
import {
  MasterDataObservationValueSetPositiveValueAction,
  MasterDataObservationValueSetPositiveValueFailedPayload,
  MasterDataObservationValueSetPositiveValueFetchPayload,
  MasterDataObservationValueSetPositiveValueSuccessPayload,
} from './masterDataObservationValueSetPositiveValue.type';

export const masterDataObservationValueSetPositiveValueHandler = {
  [MasterDataObservationValueSetPositiveValueAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueSetPositiveValueFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueSetPositiveValueLoading: true,
    masterDataObservationValueSetPositiveValueParams: action.payload,
    action: MasterDataObservationValueSetPositiveValueAction.FETCH,
  }),
  [MasterDataObservationValueSetPositiveValueAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueSetPositiveValueSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueSetPositiveValueParams: undefined,
    masterDataObservationValueSetPositiveValueLoading: false,
    masterDataObservationValueSetPositiveValueResponse: action.payload,
    action: MasterDataObservationValueSetPositiveValueAction.SUCCESS,
  }),
  [MasterDataObservationValueSetPositiveValueAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueSetPositiveValueFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueSetPositiveValueLoading: false,
    masterDataObservationValueSetPositiveValueError: action.payload,
    action: MasterDataObservationValueSetPositiveValueAction.FAILED,
  }),
  [MasterDataObservationValueSetPositiveValueAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationValueSetPositiveValueInitialState,
    action: MasterDataObservationValueSetPositiveValueAction.RESET,
  }),
};
