import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDirectorateEditInitialState} from './masterDataDirectorateEdit.reducer';
import {
  MasterDataDirectorateEditAction,
  MasterDataDirectorateEditFailedPayload,
  MasterDataDirectorateEditFetchPayload,
  MasterDataDirectorateEditSuccessPayload,
} from './masterDataDirectorateEdit.type';

export const masterDataDirectorateEditHandler = {
  [MasterDataDirectorateEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateEditLoading: true,
    masterDataDirectorateEditParams: action.payload,
    action: MasterDataDirectorateEditAction.FETCH,
  }),
  [MasterDataDirectorateEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateEditParams: undefined,
    masterDataDirectorateEditLoading: false,
    masterDataDirectorateEditResponse: action.payload,
    action: MasterDataDirectorateEditAction.SUCCESS,
  }),
  [MasterDataDirectorateEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateEditLoading: false,
    masterDataDirectorateEditError: action.payload,
    action: MasterDataDirectorateEditAction.FAILED,
  }),
  [MasterDataDirectorateEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDirectorateEditInitialState,
    action: MasterDataDirectorateEditAction.RESET,
  }),
};
