import {
  MasterDataReviewIndicatorOptionListAction,
  MasterDataReviewIndicatorOptionListSuccessPayload,
  MasterDataReviewIndicatorOptionListFailedPayload,
  MasterDataReviewIndicatorOptionListFetchPayload,
} from './masterDataReviewIndicatorOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataReviewIndicatorOptionListInitialState} from './masterDataReviewIndicatorOptionList.reducer';

export const masterDataReviewIndicatorOptionListHandler = {
  [MasterDataReviewIndicatorOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataReviewIndicatorOptionListLoading: true,
      masterDataReviewIndicatorOptionListParams: action.payload,
      action: MasterDataReviewIndicatorOptionListAction.FETCH,
    };
  },
  [MasterDataReviewIndicatorOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorOptionListLoading: false,
    masterDataReviewIndicatorOptionListResponse: action.payload,
    action: MasterDataReviewIndicatorOptionListAction.SUCCESS,
  }),
  [MasterDataReviewIndicatorOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorOptionListLoading: false,
    masterDataReviewIndicatorOptionListData: [
      ...state.masterDataReviewIndicatorOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataReviewIndicatorOptionListAction.UPDATE,
  }),
  [MasterDataReviewIndicatorOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorOptionListLoading: false,
    masterDataReviewIndicatorOptionListError: action.payload,
    action: MasterDataReviewIndicatorOptionListAction.FAILED,
  }),
  [MasterDataReviewIndicatorOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataReviewIndicatorOptionListInitialState,
    action: MasterDataReviewIndicatorOptionListAction.RESET,
  }),
};
