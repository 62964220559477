import {
  MasterDataCounterListAction,
  MasterDataCounterListSuccessPayload,
  MasterDataCounterListFailedPayload,
  MasterDataCounterListFetchPayload,
} from './masterDataCounterList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataCounterListInitialState} from './masterDataCounterList.reducer';

export const masterDataCounterListHandler = {
  [MasterDataCounterListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCounterListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataCounterListLoading: true,
      masterDataCounterListParams: action.payload,
      action: MasterDataCounterListAction.FETCH,
    };
  },
  [MasterDataCounterListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCounterListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCounterListLoading: false,
    masterDataCounterListResponse: action.payload,
    action: MasterDataCounterListAction.SUCCESS,
  }),
  [MasterDataCounterListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCounterListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCounterListLoading: false,
    masterDataCounterListError: action.payload,
    action: MasterDataCounterListAction.FAILED,
  }),
  [MasterDataCounterListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataCounterListInitialState,
    action: MasterDataCounterListAction.RESET,
  }),
};
