import React from 'react';
import t from '../../../../../../lang';
import {MasterDataManagement} from '../../../../entity/masterData.string.entity';
import {masterDataManagementDataToIcon} from '../../../../utils';
import './MasterDataManagementWrapperListItem.component.style.scss';

interface Props {
  item: MasterDataManagement;
  onClickItem(): void;
}

const MasterDataManagementWrapperListItem: React.FC<Props> = ({
  item,
  onClickItem,
}: Props) => {
  return (
    <div
      className="master-data-management-wrapper-list-item"
      onClick={onClickItem}>
      <div className="master-data-management-wrapper-list-item__icon">
        {masterDataManagementDataToIcon(item.name)}
      </div>
      <div className="master-data-management-wrapper-list-item__meta">
        <div className="master-data-management-wrapper-list-item__meta--name">
          {item.name}
        </div>
        <div className="master-data-management-wrapper-list-item__meta--info">{`${
          item.dataCount
        } ${t('data')}`}</div>
      </div>
    </div>
  );
};

export default MasterDataManagementWrapperListItem;
