import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {post} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataRouteCreateAction} from './masterDataRouteCreate.action';
import {
  MasterDataRouteCreateAction,
  MasterDataRouteCreateFetchPayload,
  MasterDataRouteCreateSuccessPayload,
} from './masterDataRouteCreate.type';

function* workerFetch(
  action: GenericAction<MasterDataRouteCreateFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);

  try {
    const res: AxiosResponse<MasterDataRouteCreateSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/route/save`,
      {routes: [action.payload]},
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataRouteCreateAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(masterDataRouteCreateAction.failed(e.response.data));
  }
}

const masterDataRouteCreateWatcher = [
  takeLatest(MasterDataRouteCreateAction.FETCH, workerFetch),
];

export default masterDataRouteCreateWatcher;
