import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {notificationListAction} from './notificationList.action';
import {
  NotificationListAction,
  NotificationListFetchPayload,
  NotificationListResponse,
  NotificationListSuccessPayload,
} from './notificationList.type';

function* workerFetch(action: GenericAction<NotificationListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<NotificationListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/notification/find-all`,
      {...action.payload, sort: 'createdAt,desc'},
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        notificationListAction.success(res.data as NotificationListResponse),
      );
      yield put(
        notificationListAction.update(res.data as NotificationListResponse),
      );
    } else {
      yield put(notificationListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(notificationListAction.failed(e as CommonError));
  }
}

const notificationListWatcher = [
  takeLatest(NotificationListAction.FETCH, workerFetch),
];

export default notificationListWatcher;
