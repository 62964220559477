import React, {ReactElement} from 'react';
import {useLocation} from 'react-router';
import {
  IconHalte,
  IconBusBRT,
  IconBusNonBRT,
  IconBusKecil,
  IconTranscare,
} from '../../../assets/icons';
import t from '../../../lang';
import {AssignmentType} from '../../wrapper/entity';
import {FollowUpStatus} from '../entity/followUp.string.entity';

export const followUpManagementDataToIcon = (
  type: AssignmentType,
): {icon: string | ReactElement; isCorner: boolean} => {
  const obj: {
    [key: string]: {icon: string | ReactElement; isCorner: boolean};
  } = {
    [AssignmentType.HALTE]: {icon: <IconHalte />, isCorner: false},
    [AssignmentType.BUSBRT]: {
      icon: <IconBusBRT />,
      isCorner: false,
    },
    [AssignmentType.BUSBRTTENGAH]: {
      icon: <IconBusBRT />,
      isCorner: false,
    },
    [AssignmentType.BUSBRTUJUNG]: {
      icon: <IconBusBRT />,
      isCorner: true,
    },
    [AssignmentType.BUSNONBRT]: {
      icon: <IconBusNonBRT />,
      isCorner: false,
    },
    [AssignmentType.BUSNONBRTTENGAH]: {
      icon: <IconBusNonBRT />,
      isCorner: false,
    },
    [AssignmentType.BUSNONBRTUJUNG]: {
      icon: <IconBusNonBRT />,
      isCorner: true,
    },
    [AssignmentType.BUSKECIL]: {
      icon: <IconBusKecil />,
      isCorner: false,
    },
    [AssignmentType.BUSKECILTENGAH]: {
      icon: <IconBusKecil />,
      isCorner: false,
    },
    [AssignmentType.BUSKECILUJUNG]: {
      icon: <IconBusKecil />,
      isCorner: true,
    },
    [AssignmentType.TRANSCARE]: {
      icon: <IconTranscare />,
      isCorner: false,
    },
    DEFAULT: {icon: <IconHalte />, isCorner: false},
  };
  return obj[type] || obj.DEFAULT;
};

export const statusToLabel = (
  status?: FollowUpStatus,
  isWaitToConfirm?: boolean,
  isSPMOfficer?: boolean,
): string | undefined => {
  const obj: {
    [key: string]: string | undefined;
  } = {
    [FollowUpStatus.ACCEPTED]: t('Approved'),
    [FollowUpStatus.ONPROGRESS]: !isSPMOfficer
      ? undefined
      : isWaitToConfirm
      ? t('Waiting to confirm')
      : t('Waiting to complete'),
    [FollowUpStatus.REJECTED]: t('Rejected'),
    [FollowUpStatus.WAITING]: t('Waiting for Verification'),
    DEFAULT: t('Approved'),
  };
  if (status) return obj[status] || obj.DEFAULT;

  return obj.Default;
};

export const useLinkParams = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const useQueryParams = (
  params: string | string[],
): Record<string, string | null> => {
  const searchResult = useLocation().search;
  if (typeof params === 'string') {
    return {[params]: new URLSearchParams(searchResult).get(params)};
  }

  return Object?.fromEntries(
    params.map((v) => [v, new URLSearchParams(searchResult).get(v)]),
  );
};

export const findDefaultTabs = (
  userLevel: number,
  followUpType: string | null,
): number => {
  if (!followUpType) return 0;
  if ([6, 9].includes(userLevel))
    return ['EXPIRED', 'WAITING', 'ACCEPTED', 'REJECTED'].indexOf(followUpType);
  return ['EXPIRED', 'ACCEPTED'].indexOf(followUpType);
};
