import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDepartmentCreateInitialState} from './masterDataDepartmentCreate.reducer';
import {
  MasterDataDepartmentCreateAction,
  MasterDataDepartmentCreateFailedPayload,
  MasterDataDepartmentCreateFetchPayload,
  MasterDataDepartmentCreateSuccessPayload,
} from './masterDataDepartmentCreate.type';

export const masterDataDepartmentCreateHandler = {
  [MasterDataDepartmentCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentCreateLoading: true,
    masterDataDepartmentCreateParams: action.payload,
    action: MasterDataDepartmentCreateAction.FETCH,
  }),
  [MasterDataDepartmentCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentCreateParams: undefined,
    masterDataDepartmentCreateLoading: false,
    masterDataDepartmentCreateResponse: action.payload,
    action: MasterDataDepartmentCreateAction.SUCCESS,
  }),
  [MasterDataDepartmentCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentCreateLoading: false,
    masterDataDepartmentCreateError: action.payload,
    action: MasterDataDepartmentCreateAction.FAILED,
  }),
  [MasterDataDepartmentCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDepartmentCreateInitialState,
    action: MasterDataDepartmentCreateAction.RESET,
  }),
};
