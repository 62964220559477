//#region IMPORT
import React from 'react';
import classnames from 'classnames';

import './FixedAutoComplete.component.scss';
import {FieldProps} from 'formik';
import t from '../../lang';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {TextField} from '@mui/material';
import {IconCaretArrowDown} from '../../assets/icons';
//#endregion
interface Props extends FieldProps<string> {
  options: string[];
  selectedOptions?: string[];
  isInputFree?: boolean;
  isDisabled?: boolean;
  isMultiple?: boolean;
  className?: string;
  noOptionsText?: string;
  popupIcon?: React.ReactNode;
  errormessage?: string;
  helperText?: string;
  onChangeAutoComplete(_: unknown, value: string[] | string | null): void;
}

const FixedAutoComplete: React.FC<Props> = ({
  options,
  selectedOptions,
  isMultiple,
  isInputFree,
  isDisabled,
  className,
  noOptionsText,
  helperText,
  errormessage,
  form,
  field,
  popupIcon = <IconCaretArrowDown />,
  onChangeAutoComplete,
  ...otherProps
}: Props) => {
  const filter = createFilterOptions<string>();
  const isError = errormessage || form.errors[field.name];
  const inputClasses = classnames(
    'fixed-auto-complete__input',
    {
      'fixed-auto-complete__input--error': isError,
    },
    className,
  );
  const classes = classnames('fixed-auto-complete', className);
  return (
    <div className={classes}>
      <Autocomplete
        disabled={isDisabled}
        className="fixed-auto-complete__field"
        multiple={isMultiple}
        freeSolo={isInputFree}
        noOptionsText={noOptionsText || t('Options not found')}
        popupIcon={popupIcon}
        onChange={(_: unknown, value: string[] | string | null) => {
          const filteredValue =
            typeof value === 'string'
              ? value.replace(t('Use'), '').replaceAll('"', '')
              : value;
          onChangeAutoComplete(_, filteredValue);
        }}
        value={selectedOptions}
        filterOptions={(options, params) => {
          const filtered = filter(options, params).filter(
            (item) => !selectedOptions?.includes(item),
          );
          if (filtered.length === 0 && isInputFree) {
            filtered.push(`${t('Use')} "${params.inputValue}"`);
          }
          return filtered;
        }}
        onBlur={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldValue = (e.target as any)?.value;
          isInputFree && fieldValue && onChangeAutoComplete('', fieldValue);
        }}
        options={options}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField className={inputClasses} {...otherProps} {...params} />
        )}
      />

      {(errormessage || form.errors[field.name]) && (
        <div className="fixed-auto-complete__errorText error--text">
          {errormessage || form.errors[field.name]}
        </div>
      )}
      {helperText && !form.errors && (
        <div className="fixed-auto-complete__helperText helper-text">
          {helperText}
        </div>
      )}
    </div>
  );
};

export default FixedAutoComplete;
