import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataDetailObservation} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailObservationListAction = {
  FETCH: 'MASTER_DATA_DETAIL_OBSERVATION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_OBSERVATION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_OBSERVATION_LIST_FAILED',
  RESET: 'MASTER_DATA_DETAIL_OBSERVATION_LIST_RESET',
  UPDATE: 'MASTER_DATA_DETAIL_OBSERVATION_LIST_UPDATE',
};

export interface MasterDataDetailObservationListInitialState {
  masterDataDetailObservationListLoading: boolean;
  masterDataDetailObservationListData: MasterDataDetailObservation[];
  masterDataDetailObservationListError?: MasterDataDetailObservationListFailedPayload;
  masterDataDetailObservationListParams?: MasterDataDetailObservationListFetchPayload;
  masterDataDetailObservationListResponse?: MasterDataDetailObservationListSuccessPayload;
}

export type MasterDataDetailObservationListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataDetailObservation[]>;
};

export type MasterDataDetailObservationListFailedPayload = CommonError;

export type MasterDataDetailObservationListSuccessPayload = MasterDataDetailObservationListResponse;

export type MasterDataDetailObservationListUpdatePayload = MasterDataDetailObservationListResponse;

export interface MasterDataDetailObservationListFetchPayload {
  size?: number;
  page?: number;
  assignmentType: string;
  search?: string;
}
