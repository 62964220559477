import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataReviewIndicatorEditInitialState} from './masterDataReviewIndicatorEdit.reducer';
import {
  MasterDataReviewIndicatorEditAction,
  MasterDataReviewIndicatorEditFailedPayload,
  MasterDataReviewIndicatorEditFetchPayload,
  MasterDataReviewIndicatorEditSuccessPayload,
} from './masterDataReviewIndicatorEdit.type';

export const masterDataReviewIndicatorEditHandler = {
  [MasterDataReviewIndicatorEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorEditLoading: true,
    masterDataReviewIndicatorEditParams: action.payload,
    action: MasterDataReviewIndicatorEditAction.FETCH,
  }),
  [MasterDataReviewIndicatorEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorEditParams: undefined,
    masterDataReviewIndicatorEditLoading: false,
    masterDataReviewIndicatorEditResponse: action.payload,
    action: MasterDataReviewIndicatorEditAction.SUCCESS,
  }),
  [MasterDataReviewIndicatorEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorEditLoading: false,
    masterDataReviewIndicatorEditError: action.payload,
    action: MasterDataReviewIndicatorEditAction.FAILED,
  }),
  [MasterDataReviewIndicatorEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataReviewIndicatorEditInitialState,
    action: MasterDataReviewIndicatorEditAction.RESET,
  }),
};
