import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDirectorateListAction,
  MasterDataDirectorateListFailedPayload,
  MasterDataDirectorateListFetchPayload,
  MasterDataDirectorateListSuccessPayload,
  MasterDataDirectorateListUpdatePayload,
} from './masterDataDirectorateList.type';

export const masterDataDirectorateListAction = {
  fetch: createAction<MasterDataDirectorateListFetchPayload>(
    MasterDataDirectorateListAction.FETCH,
  ),
  success: createAction<MasterDataDirectorateListSuccessPayload>(
    MasterDataDirectorateListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDirectorateListFailedPayload>(
    MasterDataDirectorateListAction.FAILED,
  ),
  update: createAction<MasterDataDirectorateListUpdatePayload>(
    MasterDataDirectorateListAction.UPDATE,
  ),
  reset: createAction(MasterDataDirectorateListAction.RESET),
};
