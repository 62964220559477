import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataFormControllerEditAction,
  MasterDataFormControllerEditFailedPayload,
  MasterDataFormControllerEditFetchPayload,
  MasterDataFormControllerEditSuccessPayload,
} from './masterDataFormControllerEdit.type';

export const masterDataFormControllerEditAction = {
  fetch: createAction<MasterDataFormControllerEditFetchPayload>(
    MasterDataFormControllerEditAction.FETCH,
  ),
  success: createAction<MasterDataFormControllerEditSuccessPayload>(
    MasterDataFormControllerEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataFormControllerEditFailedPayload>(
    MasterDataFormControllerEditAction.FAILED,
  ),
  reset: createAction(MasterDataFormControllerEditAction.RESET),
};
