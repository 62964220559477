import {Locale} from '../config/Language';
import {lang} from '../config/Config';

type Lang = {
  en: string;
  id: string;
};

function translator(key: string, unique?: string): string {
  const deviceLanguage = lang();

  if (key == null) {
    return '';
  }

  let langMap: number | undefined;

  if (unique == null) {
    langMap = Locale.findIndex((find: Lang) => find.en === key);
  } else {
    langMap = Locale.findIndex(
      (find: Lang) => find.en === key && find.en === unique,
    );
  }

  if (
    langMap !== undefined &&
    langMap >= 0 &&
    Locale[langMap][deviceLanguage] != null
  ) {
    return Locale[langMap][deviceLanguage];
  }

  return key;
}

export default translator;
