import {
  DashboardOverviewExportAchievementFinalQuarterAction,
  DashboardOverviewExportAchievementFinalQuarterSuccessPayload,
  DashboardOverviewExportAchievementFinalQuarterFailedPayload,
  DashboardOverviewExportAchievementFinalQuarterFetchPayload,
} from './dashboardOverviewExportAchievementFinalQuarter.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardOverviewExportAchievementFinalQuarterInitialState} from './dashboardOverviewExportAchievementFinalQuarter.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardOverviewExportAchievementFinalQuarterHandler = {
  [DashboardOverviewExportAchievementFinalQuarterAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewExportAchievementFinalQuarterFetchPayload>,
  ): DashboardState => {
    return {
      ...state,
      dashboardOverviewExportAchievementFinalQuarterParams: action.payload,
      dashboardOverviewExportAchievementFinalQuarterLoading: true,
      action: DashboardOverviewExportAchievementFinalQuarterAction.FETCH,
    };
  },
  [DashboardOverviewExportAchievementFinalQuarterAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewExportAchievementFinalQuarterSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewExportAchievementFinalQuarterLoading: false,
    dashboardOverviewExportAchievementFinalQuarterResponse: action.payload,
    action: DashboardOverviewExportAchievementFinalQuarterAction.SUCCESS,
  }),
  [DashboardOverviewExportAchievementFinalQuarterAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewExportAchievementFinalQuarterFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewExportAchievementFinalQuarterLoading: false,
    dashboardOverviewExportAchievementFinalQuarterError: action.payload,
    action: DashboardOverviewExportAchievementFinalQuarterAction.FAILED,
  }),
  [DashboardOverviewExportAchievementFinalQuarterAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardOverviewExportAchievementFinalQuarterInitialState,
    action: DashboardOverviewExportAchievementFinalQuarterAction.RESET,
  }),
};
