/* eslint-disable @typescript-eslint/ban-types */
import Axios, {AxiosResponse, AxiosError} from 'axios';
import qs, {ParsedUrlQueryInput} from 'querystring';

const axiosInstance = Axios;

export function post<T, D, H>(
  apiUri: string,
  data?: D,
  headers?: H,
): Promise<AxiosResponse<T>> {
  return axiosInstance.post(apiUri, data, headers);
}

export function get<T, H>(
  apiUri: string,
  qsObject?: object,
  headers?: H,
): Promise<AxiosResponse<T>> {
  return axiosInstance.get(
    `${apiUri}${
      qsObject ? '?' + qs.stringify(qsObject as ParsedUrlQueryInput) : ''
    }`,
    headers,
  );
}

export function httpDelete<T, H, D>(
  apiUri: string,
  headers?: H,
  data?: D,
): Promise<AxiosResponse<T>> {
  return axiosInstance.delete(apiUri, data ? {headers, data} : headers);
}

export function patch<T, D, H>(
  apiUri: string,
  data?: D,
  headers?: H,
): Promise<AxiosResponse<T>> {
  return axiosInstance.patch(apiUri, data, headers);
}

export function httpPut<T, D, H>(
  apiUri: string,
  data?: D,
  headers?: H,
): Promise<AxiosResponse<T>> {
  return axiosInstance.put(apiUri, data, headers);
}

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    // if (error.response?.status === 401) {
    //   store.dispatch(userTokenFetch());
    // }
    return Promise.reject(error);
  },
);
