import {createAction} from '@reduxjs/toolkit';
import {
  FollowUpKadepListAction,
  FollowUpKadepListFailedPayload,
  FollowUpKadepListFetchPayload,
  FollowUpKadepListSuccessPayload,
  FollowUpKadepListUpdatePayload,
} from './followUpKadepList.type';

export const followUpKadepListAction = {
  fetch: createAction<FollowUpKadepListFetchPayload>(
    FollowUpKadepListAction.FETCH,
  ),
  success: createAction<FollowUpKadepListSuccessPayload>(
    FollowUpKadepListAction.SUCCESS,
  ),
  failed: createAction<FollowUpKadepListFailedPayload>(
    FollowUpKadepListAction.FAILED,
  ),
  update: createAction<FollowUpKadepListUpdatePayload>(
    FollowUpKadepListAction.UPDATE,
  ),
  reset: createAction(FollowUpKadepListAction.RESET),
};
