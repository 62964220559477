import {AxiosResponse} from 'axios';
import moment from 'moment';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {
  downloadBlobResponse,
  generateFileName,
  generateHeaders,
} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {FILEEXT} from '../../../../../wrapper/entity';
import {DASHBOARDEXPORTFILENAME} from '../../../../entity/dashboard.string.entity';
import {dashboardOverviewExportAchievementFinalQuarterAction} from './dashboardOverviewExportAchievementFinalQuarter.action';
import {
  DashboardOverviewExportAchievementFinalQuarterAction,
  DashboardOverviewExportAchievementFinalQuarterFetchPayload,
  DashboardOverviewExportAchievementFinalQuarterResponse,
  DashboardOverviewExportAchievementFinalQuarterSuccessPayload,
} from './dashboardOverviewExportAchievementFinalQuarter.type';

function* workerFetch(
  action: GenericAction<DashboardOverviewExportAchievementFinalQuarterFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<DashboardOverviewExportAchievementFinalQuarterSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/dashboard/export-final-quarter`,
      action.payload,
      {
        ...generateHeaders({
          authorization: {
            token: userData?.accessToken || '',
            tokenType: userData?.tokenType || '',
          },
        }),
        responseType: 'blob',
      },
    );
    if (res.status === 200 && res.data) {
      downloadBlobResponse(
        [(res.data as unknown) as Blob],
        generateFileName(
          `${DASHBOARDEXPORTFILENAME.QUARTERLY} ${action.payload.quarter} ${
            action.payload.year
          } (${moment().format('DD-MM-YYYY HH:mm')})`,
          FILEEXT.XLSX,
        ),
      );
      yield put(
        dashboardOverviewExportAchievementFinalQuarterAction.success(({
          file: res.data,
        } as unknown) as DashboardOverviewExportAchievementFinalQuarterResponse),
      );
    } else {
      yield put(
        dashboardOverviewExportAchievementFinalQuarterAction.failed(
          res.data as CommonError,
        ),
      );
    }
  } catch (e) {
    yield put(
      dashboardOverviewExportAchievementFinalQuarterAction.failed(
        e as CommonError,
      ),
    );
  }
}

const dashboardOverviewExportAchievementFinalQuarterWatcher = [
  takeLatest(
    DashboardOverviewExportAchievementFinalQuarterAction.FETCH,
    workerFetch,
  ),
];

export default dashboardOverviewExportAchievementFinalQuarterWatcher;
