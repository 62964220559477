import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userRoleAccessCreateInitialState} from './userRoleAccessCreate.reducer';
import {
  UserRoleAccessCreateAction,
  UserRoleAccessCreateFailedPayload,
  UserRoleAccessCreateFetchPayload,
  UserRoleAccessCreateSuccessPayload,
} from './userRoleAccessCreate.type';

export const userRoleAccessCreateHandler = {
  [UserRoleAccessCreateAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserRoleAccessCreateFetchPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessCreateLoading: true,
    userRoleAccessCreateParams: action.payload,
    action: UserRoleAccessCreateAction.FETCH,
  }),
  [UserRoleAccessCreateAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserRoleAccessCreateSuccessPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessCreateParams: undefined,
    userRoleAccessCreateLoading: false,
    userRoleAccessCreateResponse: action.payload,
    action: UserRoleAccessCreateAction.SUCCESS,
  }),
  [UserRoleAccessCreateAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserRoleAccessCreateFailedPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessCreateLoading: false,
    userRoleAccessCreateError: action.payload,
    action: UserRoleAccessCreateAction.FAILED,
  }),
  [UserRoleAccessCreateAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userRoleAccessCreateInitialState,
    action: UserRoleAccessCreateAction.RESET,
  }),
};
