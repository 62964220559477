//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDepartmentCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDepartmentFormCreateForm} from './components';
import {DepartmentFormCreateInitialValues} from './components/MasterDataDepartmentFormCreateForm/MasterDataDepartmentFormCreateForm.component';
// Assets
import './MasterDataDepartmentFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDepartmentFormCreateContainer: React.FC<Props> = ({
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: DepartmentFormCreateInitialValues = {
    divisionId: '',
    directorateId: '',
    departments: [
      {
        name: '',
      },
    ],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDepartment = (
    formValue: DepartmentFormCreateInitialValues,
  ) => {
    const departmentTemp = formValue.departments.map((item) => {
      return {name: item.name};
    });
    const formattedValue = {
      ...formValue,
      data: departmentTemp || [],
    };
    dispatch(masterDataDepartmentCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-department-form-create-container">
      <MasterDataDepartmentFormCreateForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitDepartment={handleSubmitDepartment}
      />
    </div>
  );
  //#endregion
};

export default MasterDataDepartmentFormCreateContainer;
