import {GenericAction} from '../../../../config/constant';
import {WorkReportState} from '../workReport.reducer';
import {workReportUploadSupportInitialState} from './workReportUploadSupport.reducer';
import {
  WorkReportUploadSupportAction,
  WorkReportUploadSupportFailedPayload,
  WorkReportUploadSupportFetchPayload,
  WorkReportUploadSupportSuccessPayload,
} from './workReportUploadSupport.type';

export const workReportUploadSupportHandler = {
  [WorkReportUploadSupportAction.FETCH]: (
    state: WorkReportState,
    action: GenericAction<WorkReportUploadSupportFetchPayload>,
  ): WorkReportState => ({
    ...state,
    workReportUploadSupportLoading: true,
    workReportUploadSupportParams: action.payload,
    action: WorkReportUploadSupportAction.FETCH,
  }),
  [WorkReportUploadSupportAction.SUCCESS]: (
    state: WorkReportState,
    action: GenericAction<WorkReportUploadSupportSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportUploadSupportData: action.payload.data,
    workReportUploadSupportParams: undefined,
    workReportUploadSupportLoading: false,
    workReportUploadSupportResponse: action.payload,
    action: WorkReportUploadSupportAction.SUCCESS,
  }),
  [WorkReportUploadSupportAction.FAILED]: (
    state: WorkReportState,
    action: GenericAction<WorkReportUploadSupportFailedPayload>,
  ): WorkReportState => ({
    ...state,
    workReportUploadSupportLoading: false,
    workReportUploadSupportError: action.payload,
    action: WorkReportUploadSupportAction.FAILED,
  }),
  [WorkReportUploadSupportAction.RESET]: (
    state: WorkReportState,
  ): WorkReportState => ({
    ...state,
    ...workReportUploadSupportInitialState,
    action: WorkReportUploadSupportAction.RESET,
  }),
};
