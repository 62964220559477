import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataFormControllerMoveInitialState} from './masterDataFormControllerMove.reducer';
import {
  MasterDataFormControllerMoveAction,
  MasterDataFormControllerMoveFailedPayload,
  MasterDataFormControllerMoveFetchPayload,
  MasterDataFormControllerMoveSuccessPayload,
} from './masterDataFormControllerMove.type';

export const masterDataFormControllerMoveHandler = {
  [MasterDataFormControllerMoveAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerMoveFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerMoveLoading: true,
    masterDataFormControllerMoveParams: action.payload,
    action: MasterDataFormControllerMoveAction.FETCH,
  }),
  [MasterDataFormControllerMoveAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerMoveSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerMoveParams: undefined,
    masterDataFormControllerMoveLoading: false,
    masterDataFormControllerMoveResponse: action.payload,
    action: MasterDataFormControllerMoveAction.SUCCESS,
  }),
  [MasterDataFormControllerMoveAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerMoveFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerMoveLoading: false,
    masterDataFormControllerMoveError: action.payload,
    action: MasterDataFormControllerMoveAction.FAILED,
  }),
  [MasterDataFormControllerMoveAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataFormControllerMoveInitialState,
    action: MasterDataFormControllerMoveAction.RESET,
  }),
};
