import {createReducer} from '@reduxjs/toolkit';

import {EvaluationReportListInitialState} from './evaluationReportList/evaluationReportList.type';
import {EvaluationReportDetailInitialState} from './evaluationReportDetail/evaluationReportDetail.type';
import {EvaluationReportExportInitialState} from './evaluationReportExport/evaluationReportExport.type';

import {evaluationReportListInitialState} from './evaluationReportList/evaluationReportList.reducer';
import {evaluationReportDetailInitialState} from './evaluationReportDetail/evaluationReportDetail.reducer';
import {evaluationReportExportInitialState} from './evaluationReportExport/evaluationReportExport.reducer';

import evaluationReportHandler from './evaluationReport.handler';

export interface EvaluationReportState
  extends EvaluationReportListInitialState,
    EvaluationReportDetailInitialState,
    EvaluationReportExportInitialState {
  action: string;
}

export const evaluationReportInitialState: EvaluationReportState = {
  ...evaluationReportListInitialState,
  ...evaluationReportDetailInitialState,
  ...evaluationReportExportInitialState,
  action: '',
};

export default createReducer(
  evaluationReportInitialState,
  evaluationReportHandler,
);
