import {
  DashboardDepartmentChartSectionAction,
  DashboardDepartmentChartSectionSuccessPayload,
  DashboardDepartmentChartSectionFailedPayload,
  DashboardDepartmentChartSectionFetchPayload,
} from './dashboardDepartmentChartSection.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardDepartmentChartSectionInitialState} from './dashboardDepartmentChartSection.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardDepartmentChartSectionHandler = {
  [DashboardDepartmentChartSectionAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardDepartmentChartSectionFetchPayload>,
  ): DashboardState => {
    return {
      ...state,
      dashboardDepartmentChartSectionParams: action.payload,
      dashboardDepartmentChartSectionLoading: true,
      action: DashboardDepartmentChartSectionAction.FETCH,
    };
  },
  [DashboardDepartmentChartSectionAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardDepartmentChartSectionSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardDepartmentChartSectionLoading: false,
    dashboardDepartmentChartSectionResponse: action.payload,
    action: DashboardDepartmentChartSectionAction.SUCCESS,
  }),
  [DashboardDepartmentChartSectionAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardDepartmentChartSectionFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardDepartmentChartSectionLoading: false,
    dashboardDepartmentChartSectionError: action.payload,
    action: DashboardDepartmentChartSectionAction.FAILED,
  }),
  [DashboardDepartmentChartSectionAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardDepartmentChartSectionInitialState,
    action: DashboardDepartmentChartSectionAction.RESET,
  }),
};
