import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataRouteCreateInitialState} from './masterDataRouteCreate.reducer';
import {
  MasterDataRouteCreateAction,
  MasterDataRouteCreateFailedPayload,
  MasterDataRouteCreateFetchPayload,
  MasterDataRouteCreateSuccessPayload,
} from './masterDataRouteCreate.type';

export const masterDataRouteCreateHandler = {
  [MasterDataRouteCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteCreateLoading: true,
    masterDataRouteCreateParams: action.payload,
    action: MasterDataRouteCreateAction.FETCH,
  }),
  [MasterDataRouteCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteCreateParams: undefined,
    masterDataRouteCreateLoading: false,
    masterDataRouteCreateResponse: action.payload,
    action: MasterDataRouteCreateAction.SUCCESS,
  }),
  [MasterDataRouteCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteCreateLoading: false,
    masterDataRouteCreateError: action.payload,
    action: MasterDataRouteCreateAction.FAILED,
  }),
  [MasterDataRouteCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataRouteCreateInitialState,
    action: MasterDataRouteCreateAction.RESET,
  }),
};
