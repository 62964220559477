import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataObservationValueEditInitialState} from './masterDataObservationValueEdit.reducer';
import {
  MasterDataObservationValueEditAction,
  MasterDataObservationValueEditFailedPayload,
  MasterDataObservationValueEditFetchPayload,
  MasterDataObservationValueEditSuccessPayload,
} from './masterDataObservationValueEdit.type';

export const masterDataObservationValueEditHandler = {
  [MasterDataObservationValueEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueEditLoading: true,
    masterDataObservationValueEditParams: action.payload,
    action: MasterDataObservationValueEditAction.FETCH,
  }),
  [MasterDataObservationValueEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueEditParams: undefined,
    masterDataObservationValueEditLoading: false,
    masterDataObservationValueEditResponse: action.payload,
    action: MasterDataObservationValueEditAction.SUCCESS,
  }),
  [MasterDataObservationValueEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueEditLoading: false,
    masterDataObservationValueEditError: action.payload,
    action: MasterDataObservationValueEditAction.FAILED,
  }),
  [MasterDataObservationValueEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationValueEditInitialState,
    action: MasterDataObservationValueEditAction.RESET,
  }),
};
