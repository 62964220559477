import {CommonError} from '../../../../config/constant';
import {WorkReportUploadFormData} from '../../entity/workReport.string.entity';

export const WorkReportUploadAction = {
  FETCH: 'WORK_REPORT_UPLOAD_FETCH',
  SUCCESS: 'WORK_REPORT_UPLOAD_SUCCESS',
  FAILED: 'WORK_REPORT_UPLOAD_FAILED',
  RESET: 'WORK_REPORT_UPLOAD_RESET',
};

export interface WorkReportUploadInitialState {
  workReportUploadData: string;
  workReportUploadLoading: boolean;
  workReportUploadParams?: WorkReportUploadFetchPayload;
  workReportUploadError?: WorkReportUploadFailedPayload;
  workReportUploadResponse?: WorkReportUploadSuccessPayload;
}

export type WorkReportUploadResponse = {
  status: string;
  info?: number;
  data: string;
};

export type WorkReportUploadFetchPayload = WorkReportUploadFormData;

export type WorkReportUploadFailedPayload = CommonError;

export type WorkReportUploadSuccessPayload = WorkReportUploadResponse;
