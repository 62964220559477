import * as React from 'react';

const IconCheckboxOutline = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.334 2H7.665C4.267 2 2 4.433 2 7.916v8.168C2 19.571 4.261 22 7.665 22h8.668C19.738 22 22 19.57 22 16.084V7.916C22 4.429 19.738 2 16.334 2ZM7.665 3.5h8.669c2.55 0 4.166 1.735 4.166 4.416v8.168c0 2.681-1.616 4.416-4.167 4.416H7.665c-2.55 0-4.165-1.735-4.165-4.416V7.916C3.5 5.238 5.12 3.5 7.665 3.5Zm8.425 5.597a.75.75 0 0 0-1.06 0l-4.217 4.215L8.97 11.47l-.084-.073a.75.75 0 0 0-.977 1.133l2.374 2.373.084.073a.75.75 0 0 0 .977-.073l4.746-4.746.072-.084a.75.75 0 0 0-.072-.976Z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconCheckboxOutline;
