//#region IMPORT
// Libraries
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
// Utils
import moment from 'moment';
import {urlToFile} from '../../../../../../utils';
import {
  masterDataAssignmentLetterCreateAction,
  masterDataAssignmentLetterEditAction,
} from '../../../../usecases/masterData.action';
// Components
import AssignmentLetterForm from './components/AssignmentLetterForm';
// Data
import {
  MasterDataAssignmentLetter,
  MasterDataAssignmentLetterFormData,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataAssignmentLetterFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: MasterDataAssignmentLetter;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataAssignmentLetterFormContainer: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
}: Props) => {
  const dispatch = useDispatch();
  const fileType = 'application/pdf';
  const [currentFile, setCurrentFile] = useState<File>();

  //#region FILE INITIALVALUES
  useEffect(() => {
    let isDispatch = true;
    if (isDispatch) {
      urlToFile(data?.file ?? '', data?.fileName ?? '', fileType).then((res) =>
        setCurrentFile(res),
      );
    }
    return () => {
      isDispatch = false;
    };
  }, [data?.file, data?.fileName]);
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataAssignmentLetterFormData = {
    id: data?.id || '',
    number: data?.number || '',
    publishDate: data?.publishDate ? moment(data?.publishDate) : '',
    roleId: data?.roleId || '',
    file: currentFile || undefined,
  };
  //#endregion

  //#region
  return (
    <div className="master-data-assignment-letter-form-container">
      <AssignmentLetterForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitUser={(_assignmentLetter) => {
          _assignmentLetter = {
            ..._assignmentLetter,
            publishDate: moment(_assignmentLetter.publishDate).format(
              'YYYY-MM-DD',
            ),
          };
          if (_assignmentLetter.id) {
            dispatch(
              masterDataAssignmentLetterEditAction.fetch(_assignmentLetter),
            );
          } else {
            dispatch(
              masterDataAssignmentLetterCreateAction.fetch(_assignmentLetter),
            );
          }
        }}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default MasterDataAssignmentLetterFormContainer;
