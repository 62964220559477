//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDirectorateEditAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDirectorateFormEdit} from './components';
// Data
import {MasterDataDirectorateFormData} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDirectorateFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: MasterDataDirectorateFormData;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDirectorateFormEditContainer: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataDirectorateFormData = {
    id: data.id || '',
    name: data.name || '',
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDirectorate = (
    formValue: MasterDataDirectorateFormData,
  ) => {
    const directorateTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: directorateTemp || '',
    };
    dispatch(masterDataDirectorateEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-directorate-form-edit-container">
      <MasterDataDirectorateFormEdit
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitDirectorate={handleSubmitDirectorate}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default MasterDataDirectorateFormEditContainer;
