//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../../../../lang';
import {colors} from '../../../../../../../config/_theme';
import {followUpManagementDataToIcon} from '../../../../../../followUp/utils';
// Components
import {FollowUpChip} from '../../../../../../followUp/components';
// Data
import {EvaluationReport} from '../../../../../entity/evaluationReport.string.entity';
import {AssignmentType} from '../../../../../../wrapper/entity';
// Assets
import './EvaluationReportListItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: EvaluationReport;
  handleClick(_evaluationReport: EvaluationReport): void;
}
//#endregion

const EvaluationReportListItem: React.FC<Props> = ({
  data,
  handleClick,
}: Props) => {
  //#region GENERAL
  const {icon} = followUpManagementDataToIcon(
    data?.assignmentType || AssignmentType.HALTE,
  );
  //#endregion

  return (
    <div
      className="evaluation-report-list-item"
      onClick={(e) => {
        e.stopPropagation();
        handleClick(data);
      }}>
      <div className="evaluation-report-list-item-upper">
        <div className="evaluation-report-list-item-upper__icon">{icon}</div>
        <div className="evaluation-report-list-item-upper__title">
          {data?.assignmentTypeCode || '-'}
        </div>
        {data?.routeType && (
          <div className="evaluation-report-list-item-upper__type">
            <FollowUpChip
              text={data?.routeType}
              backgroundColor={colors.primary.blue1}
              color={colors.primary.blue}
            />
          </div>
        )}
      </div>

      <div className="evaluation-report-list-item-detail">
        {data?.corridor && (
          <div className="evaluation-report-list-item-detail-text">
            {`${t('Corridor')} ${data?.corridor}`}
          </div>
        )}
        <div className="evaluation-report-list-item-detail-text">
          {data?.routeName || data?.location || data?.busStop}
        </div>
      </div>
    </div>
  );
};
export default EvaluationReportListItem;
