import {createAction} from '@reduxjs/toolkit';
import {
  DashboardOverviewSetProfitAction,
  DashboardOverviewSetProfitFailedPayload,
  DashboardOverviewSetProfitFetchPayload,
  DashboardOverviewSetProfitSuccessPayload,
} from './dashboardOverviewSetProfit.type';

export const dashboardOverviewSetProfitAction = {
  fetch: createAction<DashboardOverviewSetProfitFetchPayload>(
    DashboardOverviewSetProfitAction.FETCH,
  ),
  success: createAction<DashboardOverviewSetProfitSuccessPayload>(
    DashboardOverviewSetProfitAction.SUCCESS,
  ),
  failed: createAction<DashboardOverviewSetProfitFailedPayload>(
    DashboardOverviewSetProfitAction.FAILED,
  ),
  reset: createAction(DashboardOverviewSetProfitAction.RESET),
};
