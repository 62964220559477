/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  MasterDataFormControllerCreateAction,
  MasterDataFormControllerDeleteAction,
  MasterDataFormControllerEditAction,
  MasterDataFormControllerListAction,
  MasterDataFormControllerMoveAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {masterDataManagementDataToLabel} from '../../../../utils';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {
  masterDataFormControllerCreateAction,
  masterDataFormControllerDeleteAction,
  masterDataFormControllerEditAction,
  masterDataFormControllerListAction,
  masterDataFormControllerMoveAction,
} from '../../../../usecases/masterData.action';
// Components
import {
  MButton,
  Modal,
  MSearchInput,
  AlertDialog,
} from '../../../../../../components';
import MasterDataFormControllerFormContainer from '../MasterDataFormControllerFormContainer';
import {FormControllerManagementList} from './components';
// Data
import {MasterDataFormController} from '../../../../entity/masterData.string.entity';
// Assets
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
import './MasterDataFormControllerSection.scss';
import MTablePagination from '../../../../../../components/MTablePagination';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataFormControllerSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddFormController, setAddFormController] = useState(false);
  const [isDeleteFormController, setIsDeleteFormController] = useState<
    MasterDataFormController | undefined
  >(undefined);
  const [isEditFormController, setIsEditFormController] = useState<
    MasterDataFormController | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddFormController =
    masterData.masterDataFormControllerCreateLoading;
  const isLoadingEditFormController =
    masterData.masterDataFormControllerEditLoading;
  const isLoadingDeleteFormController =
    masterData.masterDataFormControllerDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataFormControllerListPage,
    setMasterDataFormControllerListPage,
  ] = useState<number>(0);
  const [
    masterDataFormControllerListCount,
    setMasterDataFormControllerListCount,
  ] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataFormControllerListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataFormControllerListPage(0);
  };
  //#endregion

  //#region FETCH FORM CONTROLLER
  const fetchFormControllerList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataFormControllerListAction.fetch({
          page,
          search: !search ? undefined : search,
          size,
          assignmentType,
        }),
      );
    },
    [dispatch, assignmentType],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataFormControllerListAction.reset());
    fetchFormControllerList(
      masterDataFormControllerListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataFormControllerListAction.reset());
    };
  }, [
    dispatch,
    fetchFormControllerList,
    masterDataFormControllerListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataFormControllerActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataFormControllerListAction.UPDATE, (): void => {
            setMasterDataFormControllerListCount(
              masterData?.masterDataFormControllerListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataFormControllerListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataFormControllerListAction.reset());
          })
          .addCase(MasterDataFormControllerCreateAction.SUCCESS, (): void => {
            setAddFormController(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataFormControllerCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataFormControllerCreateAction.reset());
          })
          .addCase(MasterDataFormControllerEditAction.SUCCESS, (): void => {
            setIsEditFormController(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataFormControllerEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataFormControllerEditAction.reset());
          })
          .addCase(MasterDataFormControllerDeleteAction.SUCCESS, (): void => {
            setIsDeleteFormController(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataFormControllerDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataFormControllerDeleteAction.reset());
          })
          .addCase(MasterDataFormControllerMoveAction.SUCCESS, (): void => {
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved success!'),
                type: 'success',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataFormControllerMoveAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataFormControllerMoveAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataFormControllerListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataFormControllerActionHandler(masterData.action);
  }, [masterData.action, masterDataFormControllerActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataFormControllerDeleteAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE MOVE
  const handleMove = (id: string, isUp?: boolean) => {
    dispatch(
      masterDataFormControllerMoveAction.fetch({
        id,
        ...(isUp ? {isUp: true} : {isDown: true}),
      }),
    );
  };
  //#endregion

  return (
    <>
      <div className="master-data-form-controller-section">
        <div className="master-data-form-controller-section__header">
          <MSearchInput
            inputClassName="master-data-form-controller-section__header--search"
            inputValue={searchValue}
            placeholder={t(
              `Search Master Data ${masterDataManagementDataToLabel(
                assignmentType,
              )}`,
            )}
            setInputValue={(search: string) => {
              setMasterDataFormControllerListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-form-controller-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddFormController(true)}
              />
            </div>
          )}
        </div>
        <FormControllerManagementList
          formControllerList={masterData.masterDataFormControllerListData}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditFormController: (_formController: MasterDataFormController) =>
              setIsEditFormController(_formController),
            onMoveUp: (id: string) => handleMove(id, true),
            onMoveDown: (id: string) => handleMove(id),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteFormController: (
              _formController: MasterDataFormController,
            ) => setIsDeleteFormController(_formController),
          })}
        />
        <MTablePagination
          className="master-data-form-controller-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataFormControllerListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataFormControllerListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddFormController(false)}
        title={t(`Add ${masterDataManagementDataToLabel(assignmentType)}`)}
        isOpen={isAddFormController}>
        <MasterDataFormControllerFormContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddFormController}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditFormController && (
        <Modal
          onClose={() => setIsEditFormController(undefined)}
          isOpen={!!isEditFormController}
          title={t(`Edit ${masterDataManagementDataToLabel(assignmentType)}`)}>
          <MasterDataFormControllerFormContainer
            assignmentType={assignmentType}
            data={isEditFormController}
            isLoading={isLoadingEditFormController}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteFormController && (
        <AlertDialog
          onClose={() => setIsDeleteFormController(undefined)}
          onCancel={() => setIsDeleteFormController(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteFormController.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteFormController}
          isLoading={isLoadingDeleteFormController}
          image={<IllustrationTrashImage />}
          description={t(
            'Are you sure you want to delete the data?',
          )}></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddFormController(false)
              : setIsEditFormController(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataFormControllerSection;
