import {createAction} from '@reduxjs/toolkit';
import {
  UserAccessListAction,
  UserAccessListFailedPayload,
  UserAccessListFetchPayload,
  UserAccessListSuccessPayload,
  UserAccessListUpdatePayload,
} from './userAccessList.type';

export const userAccessListAction = {
  fetch: createAction<UserAccessListFetchPayload>(UserAccessListAction.FETCH),
  success: createAction<UserAccessListSuccessPayload>(
    UserAccessListAction.SUCCESS,
  ),
  failed: createAction<UserAccessListFailedPayload>(
    UserAccessListAction.FAILED,
  ),
  update: createAction<UserAccessListUpdatePayload>(
    UserAccessListAction.UPDATE,
  ),
  reset: createAction(UserAccessListAction.RESET),
};
