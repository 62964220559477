import {followUpListHandler} from './followUpList/followUpList.handler';
import {followUpKadepListHandler} from './followUpKadepList/followUpKadepList.handler';
import {followUpExpiredNoteCreateHandler} from './followUpExpiredNoteCreate/followUpExpiredNoteCreate.handler';
import {followUpPICConfirmHandler} from './followUpPICConfirm/followUpPICConfirm.handler';
import {followUpPICCompleteHandler} from './followUpPICComplete/followUpPICComplete.handler';
import {followUpDetailHandler} from './followUpDetail/followUpDetail.handler';

export default {
  ...followUpListHandler,
  ...followUpKadepListHandler,
  ...followUpExpiredNoteCreateHandler,
  ...followUpPICConfirmHandler,
  ...followUpPICCompleteHandler,
  ...followUpDetailHandler,
};
