//#region IMPORT
// Libraries
import React from 'react';
// Assets
import './FollowUpEmpty.component.style.scss';
import {IllustrationNoData} from '../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  text: string;
}
//#endregion

const FollowUpEmpty: React.FC<Props> = ({text}: Props) => {
  return (
    <div className="follow-up-empty">
      <div className="follow-up-empty-icon">
        <IllustrationNoData />
      </div>
      <div className="follow-up-empty-text">{text}</div>
    </div>
  );
};
export default FollowUpEmpty;
