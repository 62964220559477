import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataRuteListAction,
  MasterDataRuteListFailedPayload,
  MasterDataRuteListFetchPayload,
  MasterDataRuteListSuccessPayload,
  MasterDataRuteListUpdatePayload,
} from './masterDataRuteList.type';

export const masterDataRuteListAction = {
  fetch: createAction<MasterDataRuteListFetchPayload>(
    MasterDataRuteListAction.FETCH,
  ),
  success: createAction<MasterDataRuteListSuccessPayload>(
    MasterDataRuteListAction.SUCCESS,
  ),
  failed: createAction<MasterDataRuteListFailedPayload>(
    MasterDataRuteListAction.FAILED,
  ),
  update: createAction<MasterDataRuteListUpdatePayload>(
    MasterDataRuteListAction.UPDATE,
  ),
  reset: createAction(MasterDataRuteListAction.RESET),
};
