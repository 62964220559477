import {CommonData, CommonError} from '../../../../config/constant';
import {SortType} from '../../../wrapper/entity';
import {FollowUp, FollowUpStatus} from '../../entity/followUp.string.entity';

export const FollowUpListAction = {
  FETCH: 'FOLLOW_UP_LIST_FETCH',
  SUCCESS: 'FOLLOW_UP_LIST_SUCCESS',
  FAILED: 'FOLLOW_UP_LIST_FAILED',
  RESET: 'FOLLOW_UP_LIST_RESET',
  UPDATE: 'FOLLOW_UP_LIST_UPDATE',
};

export interface FollowUpListInitialState {
  followUpListLoading: boolean;
  followUpListData: FollowUp[];
  followUpListError?: FollowUpListFailedPayload;
  followUpListParams?: FollowUpListFetchPayload;
  followUpListResponse?: FollowUpListSuccessPayload;
}

export interface FollowUpResponseData {
  expired?: number;
  followUps?: CommonData<FollowUp[]>;
}
export type FollowUpListResponse = {
  status: string;
  info?: number;
  data: FollowUpResponseData;
};

export type FollowUpListFailedPayload = CommonError;

export type FollowUpListSuccessPayload = FollowUpListResponse;

export type FollowUpListUpdatePayload = FollowUp[];

export interface FollowUpListFetchPayload {
  page?: number;
  size?: number;
  status?: FollowUpStatus;
  sort?: SortType;
  filter?: string;
  startDate?: string;
  endDate?: string;
  isExpired?: boolean;
}
