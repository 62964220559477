import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataDirectorate} from '../../../../entity/masterData.string.entity';

export const MasterDataDirectorateListAction = {
  FETCH: 'MASTER_DATA_DIRECTORATE_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DIRECTORATE_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DIRECTORATE_LIST_FAILED',
  RESET: 'MASTER_DATA_DIRECTORATE_LIST_RESET',
  UPDATE: 'MASTER_DATA_DIRECTORATE_LIST_UPDATE',
};

export interface MasterDataDirectorateListInitialState {
  masterDataDirectorateListLoading: boolean;
  masterDataDirectorateListData: MasterDataDirectorate[];
  masterDataDirectorateListError?: MasterDataDirectorateListFailedPayload;
  masterDataDirectorateListParams?: MasterDataDirectorateListFetchPayload;
  masterDataDirectorateListResponse?: MasterDataDirectorateListSuccessPayload;
}

export type MasterDataDirectorateListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataDirectorate[]>;
};

export type MasterDataDirectorateListFailedPayload = CommonError;

export type MasterDataDirectorateListSuccessPayload = MasterDataDirectorateListResponse;

export type MasterDataDirectorateListUpdatePayload = MasterDataDirectorateListResponse;

export interface MasterDataDirectorateListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
}
