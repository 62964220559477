/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
import {AppState} from '../../../../../../../../config/Interface';
import {MasterDataState} from '../../../../../../usecases/masterData.reducer';
import {
  masterDataCategoryOptionListAction,
  masterDataBasicServiceTypeOptionListAction,
  masterDataDetailIndicatorOptionListAction,
  masterDataDetailObservationOptionListAction,
  masterDataIndicatorValueOptionListAction,
  masterDataObservationOptionListAction,
  masterDataObservationValueOptionListAction,
  masterDataReviewIndicatorOptionListAction,
} from '../../../../../../usecases/masterData.action';
import {
  getOptionFromBasicServiceTypeList,
  getOptionFromCategoryList,
  getOptionFromObservationList,
  getOptionFromReviewIndicatorList as getOptionFromReviewIndicatorOptionList,
} from '../../../../../../utils';
// Components
import {
  MButton,
  MSelect,
  FixedAutoComplete,
  MRadioButton,
  MSelectIcon,
} from '../../../../../../../../components';
// Data
import {
  CheckLocation,
  IMPORTANTNOTESTATUS,
  MasterDataFormControllerFormValues,
  ObservationValueType,
} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataFormControllerForm.component.style.scss';
import {
  IconCheckboxFilled,
  IconMultipleChoice,
} from '../../../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  initialValues: MasterDataFormControllerFormValues;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitFormController(
    formValue: MasterDataFormControllerFormValues,
  ): void;
}
//#endregion

const MasterDataFormControllerForm: React.FC<Props> = ({
  assignmentType,
  initialValues,
  isLoading,
  handleCancel,
  handleSubmitFormController,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const isEdit = !!initialValues?.id;
  const masterData: MasterDataState = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  //#endregion

  //#region FETCH BASIC SERVICE TYPE
  const fetchBasicServiceTypeList = useCallback(() => {
    dispatch(
      masterDataBasicServiceTypeOptionListAction.fetch({assignmentType}),
    );
  }, [assignmentType, dispatch]);
  //#endregion

  //#region FETCH CATEGORY
  const fetchCategoryList = useCallback(() => {
    dispatch(masterDataCategoryOptionListAction.fetch({assignmentType}));
  }, [assignmentType, dispatch]);
  //#endregion

  //#region FETCH REVIEW INDICATOR
  const fetchReviewIndicatorList = useCallback(() => {
    dispatch(masterDataReviewIndicatorOptionListAction.fetch({assignmentType}));
  }, [assignmentType, dispatch]);
  //#endregion

  //#region FETCH DETAIL INDICATOR
  const fetchDetailIndicatorList = useCallback(() => {
    dispatch(masterDataDetailIndicatorOptionListAction.fetch({assignmentType}));
  }, [assignmentType, dispatch]);
  //#endregion

  //#region FETCH INDICATOR VALUE
  const fetchIndicatorValueList = useCallback(() => {
    dispatch(masterDataIndicatorValueOptionListAction.fetch({assignmentType}));
  }, [assignmentType, dispatch]);
  //#endregion

  //#region FETCH DETAIL OBSERVATION
  const fetchDetailObservationList = useCallback(() => {
    dispatch(
      masterDataDetailObservationOptionListAction.fetch({assignmentType}),
    );
  }, [assignmentType, dispatch]);
  //#endregion

  //#region FETCH OBSERVATION VALUE
  const fetchObservationValueList = useCallback(() => {
    dispatch(
      masterDataObservationValueOptionListAction.fetch({assignmentType}),
    );
  }, [assignmentType, dispatch]);
  //#endregion

  //#region FETCH OBSERVATION
  const fetchObservationList = useCallback(() => {
    dispatch(masterDataObservationOptionListAction.fetch({assignmentType}));
  }, [assignmentType, dispatch]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataBasicServiceTypeOptionListAction.reset());
    dispatch(masterDataReviewIndicatorOptionListAction.reset());
    dispatch(masterDataDetailIndicatorOptionListAction.reset());
    dispatch(masterDataCategoryOptionListAction.reset());
    dispatch(masterDataDetailObservationOptionListAction.reset());
    dispatch(masterDataObservationValueOptionListAction.reset());
    dispatch(masterDataIndicatorValueOptionListAction.reset());
    dispatch(masterDataObservationOptionListAction.reset());
    fetchBasicServiceTypeList();
    fetchCategoryList();
    fetchDetailIndicatorList();
    fetchReviewIndicatorList();
    fetchDetailObservationList();
    fetchObservationValueList();
    fetchIndicatorValueList();
    fetchObservationList();

    return () => {
      dispatch(masterDataReviewIndicatorOptionListAction.reset());
      dispatch(masterDataIndicatorValueOptionListAction.reset());
      dispatch(masterDataObservationOptionListAction.reset());
      dispatch(masterDataObservationValueOptionListAction.reset());
      dispatch(masterDataBasicServiceTypeOptionListAction.reset());
      dispatch(masterDataDetailObservationOptionListAction.reset());
      dispatch(masterDataDetailIndicatorOptionListAction.reset());
      dispatch(masterDataCategoryOptionListAction.reset());
    };
  }, [
    dispatch,
    fetchBasicServiceTypeList,
    fetchCategoryList,
    fetchDetailIndicatorList,
    fetchDetailObservationList,
    fetchIndicatorValueList,
    fetchObservationList,
    fetchObservationValueList,
    fetchReviewIndicatorList,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const formControllerValidation = Yup.object().shape({
    checkLocation: Yup.string().required(t('Check Location is required.')),
    categoryId: Yup.string().required(t('Category is required.')),
    isNote: Yup.string(),
    reviewIndicatorId: Yup.string().when('isNote', {
      is: IMPORTANTNOTESTATUS.NOT_EXIST,
      then: Yup.string().required(t('Review Indicator is required.')),
      otherwise: Yup.string().notRequired(),
    }),
    selectedIndicatorValue: Yup.array().when('isNote', {
      is: IMPORTANTNOTESTATUS.NOT_EXIST,
      then: Yup.array().min(1, t('Indicator Value is required.')),
      otherwise: Yup.array().notRequired(),
    }),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-form-controller-form">
      <div className="master-data-form-controller-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={formControllerValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitFormController}>
          {({
            handleSubmit,
            setFieldValue,
            values,
            isValid,
          }): React.ReactElement => (
            <>
              <div className="master-data-form-controller-form__section">
                <div className="master-data-form-controller-form__section--field">
                  <div className="input-label">{t('Check Location')}</div>
                  <Field
                    className="user-input"
                    name="checkLocation"
                    options={[
                      {label: 'Inside', value: CheckLocation.INSIDE},
                      {label: 'Outside', value: CheckLocation.OUTSIDE},
                    ]}
                    disabled={isLoading}
                    component={MRadioButton}
                  />
                </div>
                <div className="master-data-form-controller-form__section--field">
                  <div className="input-label">{t('Category')}</div>
                  <Field
                    name="categoryId"
                    placeholder={t('Choose Category')}
                    options={[
                      ...getOptionFromCategoryList(
                        masterData.masterDataCategoryOptionListData,
                      ),
                      {label: 'Not Exist', value: ''},
                    ]}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
                <div className="master-data-form-controller-form__section--field">
                  <div className="input-label">{t('Basic Service Type')}</div>
                  <Field
                    name="basicServiceTypeId"
                    placeholder={t('Choose Basic Service Type')}
                    options={[
                      ...getOptionFromBasicServiceTypeList(
                        masterData.masterDataBasicServiceTypeOptionListData,
                      ),
                      {label: 'Not Exist', value: ''},
                    ]}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
                <div className="master-data-form-controller-form__section--field">
                  <div className="input-label">
                    {t('Is there any important notes?')}
                  </div>
                  <Field
                    className="user-input"
                    name="isNote"
                    options={[
                      {label: 'Exist', value: IMPORTANTNOTESTATUS.EXIST},
                      {
                        label: 'Not Exist',
                        value: IMPORTANTNOTESTATUS.NOT_EXIST,
                      },
                    ]}
                    disabled={isLoading}
                    component={MRadioButton}
                  />
                </div>
                {values.isNote === IMPORTANTNOTESTATUS.NOT_EXIST && (
                  <>
                    <div className="master-data-form-controller-form__section--field">
                      <div className="input-label">{t('Review Indicator')}</div>
                      <Field
                        name="reviewIndicatorId"
                        placeholder={t('Choose Review Indicator')}
                        options={[
                          ...getOptionFromReviewIndicatorOptionList(
                            masterData.masterDataReviewIndicatorOptionListData,
                          ),
                          {label: 'Not Exist', value: ''},
                        ]}
                        disabled={isLoading}
                        component={MSelect}
                      />
                    </div>
                    <div className="master-data-form-controller-form__section--field">
                      <div className="input-label">{t('Detail Indicator')}</div>
                      <Field
                        name="selectedDetailIndicator"
                        selectedOptions={
                          values?.selectedDetailIndicator &&
                          values?.selectedDetailIndicator?.length > 0
                            ? values.selectedDetailIndicator
                            : []
                        }
                        options={masterData.masterDataDetailIndicatorOptionListData.map(
                          (item) => item.name,
                        )}
                        onChangeAutoComplete={(
                          _: unknown,
                          detailIndicatorValue: string[],
                        ) => {
                          const selectedDetailIndicator = detailIndicatorValue.map(
                            (item) =>
                              masterData.masterDataDetailIndicatorOptionListData.find(
                                (_detailIndicator) =>
                                  _detailIndicator.name === item,
                              ),
                          );
                          setFieldValue(
                            'detailIndicator',
                            selectedDetailIndicator,
                          );
                          setFieldValue(
                            'selectedDetailIndicator',
                            detailIndicatorValue,
                          );
                        }}
                        isMultiple
                        placeholder={t('Choose Detail Indicator')}
                        noOptionsText={t('Detail Indicator not found')}
                        disabled={isLoading}
                        component={FixedAutoComplete}
                      />
                    </div>
                    <div className="master-data-form-controller-form__section--field">
                      <div className="input-label">{t('Indicator Value')}</div>
                      <Field
                        name="selectedIndicatorValue"
                        selectedOptions={
                          values.selectedIndicatorValue &&
                          values.selectedIndicatorValue.length > 0
                            ? values.selectedIndicatorValue
                            : []
                        }
                        options={masterData.masterDataIndicatorValueOptionListData.map(
                          (item) => item.name,
                        )}
                        onChangeAutoComplete={(
                          _: unknown,
                          indicatorValue: string[],
                        ) => {
                          const selectedIndicatorValue = indicatorValue.map(
                            (item) =>
                              masterData.masterDataIndicatorValueOptionListData.find(
                                (_indicator) => _indicator.name === item,
                              ),
                          );
                          setFieldValue(
                            'indicatorValue',
                            selectedIndicatorValue,
                          );
                          setFieldValue(
                            'selectedIndicatorValue',
                            indicatorValue,
                          );
                        }}
                        isMultiple
                        placeholder={t('Choose Indicator Value')}
                        noOptionsText={t('Indicator Value not found')}
                        disabled={isLoading}
                        component={FixedAutoComplete}
                      />
                    </div>
                    <div className="master-data-form-controller-form__section--field">
                      <div className="input-label">{t('Observation')}</div>
                      <Field
                        name="observationId"
                        placeholder={t('Choose Observation')}
                        options={[
                          ...getOptionFromObservationList(
                            masterData.masterDataObservationOptionListData,
                          ),
                          {label: 'Not Exist', value: ''},
                        ]}
                        handleChangeSelect={(observationId: string) => {
                          if (!observationId)
                            setFieldValue(
                              'observationValueType',
                              ObservationValueType.MCHOICE,
                              true,
                            );
                        }}
                        disabled={isLoading}
                        component={MSelect}
                      />
                    </div>
                    <div className="master-data-form-controller-form__section--field">
                      <div className="input-label">
                        {t('Detail Observation')}
                      </div>
                      <Field
                        name="selectedDetailObservation"
                        selectedOptions={
                          values?.selectedDetailObservation &&
                          values?.selectedDetailObservation?.length > 0
                            ? values.selectedDetailObservation
                            : []
                        }
                        options={masterData.masterDataDetailObservationOptionListData.map(
                          (item) => item.name,
                        )}
                        onChangeAutoComplete={(
                          _: unknown,
                          detailObservationValue: string[],
                        ) => {
                          const selectedDetailObservation = detailObservationValue.map(
                            (item) =>
                              masterData.masterDataDetailObservationOptionListData.find(
                                (_detailObservation) =>
                                  _detailObservation.name === item,
                              ),
                          );
                          setFieldValue(
                            'detailObservation',
                            selectedDetailObservation,
                          );
                          setFieldValue(
                            'selectedDetailObservation',
                            detailObservationValue,
                          );
                        }}
                        isMultiple
                        placeholder={t('Choose Detail Observation')}
                        noOptionsText={t('Detail Observation not found')}
                        disabled={isLoading}
                        component={FixedAutoComplete}
                      />
                    </div>
                    <div className="master-data-form-controller-form__section--field">
                      <div className="input-label">
                        {t('Observation Value')}
                      </div>
                      <Field
                        name="observationValueType"
                        placeholder={t('Choose Observation Value Type')}
                        options={[
                          {
                            label: 'Multiple Choice',
                            icon: <IconMultipleChoice />,
                            value: ObservationValueType.MCHOICE,
                          },
                          {
                            label: 'Checklist',
                            icon: <IconCheckboxFilled />,
                            value: ObservationValueType.MSELECTION,
                          },
                        ]}
                        onBlur={() => {}}
                        disabled={
                          isLoading ||
                          !values.observationId ||
                          !values.observationValue?.length
                        }
                        component={MSelectIcon}
                      />
                      <Field
                        name="selectedObservationValue"
                        selectedOptions={
                          values?.selectedObservationValue &&
                          values?.selectedObservationValue?.length > 0
                            ? values.selectedObservationValue
                            : []
                        }
                        options={masterData.masterDataObservationValueOptionListData.map(
                          (item) => item.name,
                        )}
                        onChangeAutoComplete={(
                          _: unknown,
                          observationValue: string[],
                        ) => {
                          const selectedObservationValue = observationValue.map(
                            (item) =>
                              masterData.masterDataObservationValueOptionListData.find(
                                (_observation) => _observation.name === item,
                              ),
                          );
                          setFieldValue(
                            'observationValue',
                            selectedObservationValue,
                          );
                          setFieldValue(
                            'selectedObservationValue',
                            observationValue,
                          );
                          if (!selectedObservationValue?.length)
                            setFieldValue(
                              'observationValueType',
                              ObservationValueType.MCHOICE,
                              true,
                            );
                        }}
                        isMultiple
                        placeholder={t('Choose Observation Value')}
                        noOptionsText={t('Observation Value not found')}
                        disabled={isLoading}
                        component={FixedAutoComplete}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="master-data-form-controller-form__form--action">
                <div className="master-data-form-controller-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="master-data-form-controller-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={!isEdit ? t('Add') : t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataFormControllerForm;
