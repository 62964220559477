import {CommonError} from '../../../../../../config/constant';
import {MasterDataBasicServiceType} from '../../../../entity/masterData.string.entity';

export const MasterDataBasicServiceTypeOptionListAction = {
  FETCH: 'MASTER_DATA_BASIC_SERVICE_TYPE_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_BASIC_SERVICE_TYPE_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_BASIC_SERVICE_TYPE_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_BASIC_SERVICE_TYPE_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_BASIC_SERVICE_TYPE_OPTION_LIST_UPDATE',
};

export interface MasterDataBasicServiceTypeOptionListInitialState {
  masterDataBasicServiceTypeOptionListLoading: boolean;
  masterDataBasicServiceTypeOptionListData: MasterDataBasicServiceType[];
  masterDataBasicServiceTypeOptionListError?: MasterDataBasicServiceTypeOptionListFailedPayload;
  masterDataBasicServiceTypeOptionListParams?: MasterDataBasicServiceTypeOptionListFetchPayload;
  masterDataBasicServiceTypeOptionListResponse?: MasterDataBasicServiceTypeOptionListSuccessPayload;
}

export type MasterDataBasicServiceTypeOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataBasicServiceType[];
};

export type MasterDataBasicServiceTypeOptionListFailedPayload = CommonError;

export type MasterDataBasicServiceTypeOptionListSuccessPayload = MasterDataBasicServiceTypeOptionListResponse;

export type MasterDataBasicServiceTypeOptionListUpdatePayload = MasterDataBasicServiceTypeOptionListResponse;

export interface MasterDataBasicServiceTypeOptionListFetchPayload {
  assignmentType: string;
}
