/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {stableSort, getComparator} from '../../../../../../../../utils';
// Components
import MasterDataBusManagementListTableHeader from '../MasterDataBusManagementListTableHeader';
import MasterDataBusManagementListTableItem from '../MasterDataBusManagementListTableItem';
// Data
import {
  MasterDataBus,
  MasterDataBusOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  busList: MasterDataBus[];
  sortBy: SortTable;
  orderBy: MasterDataBusOrderByType;
  onEditBus(bus: MasterDataBus): void;
  onDeleteBus(bus: MasterDataBus): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataBusOrderByType,
  ): void;
}
//#endregion

const MasterDataBusManagementList: React.FC<Props> = ({
  busList,
  sortBy,
  orderBy,
  onHandleSort,
  onEditBus,
  onDeleteBus,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: 'calc(70vh)'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataBusManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(busList as any[], getComparator(sortBy, orderBy)).map(
            (item: any, index: number) => (
              <MasterDataBusManagementListTableItem
                key={`master-data-bus-list-item-data-${item.id}-${index}`}
                data={item}
                index={index + 1}
                onEditItem={() => {
                  onEditBus(item);
                }}
                onDeleteItem={() => {
                  onDeleteBus(item);
                }}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataBusManagementList;
