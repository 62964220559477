import {GenericAction} from '../../../../config/constant';
import {userInitialState, UserState} from '../user.reducer';
import {userDataInitialState} from './userData.reducer';
import {UserDataAction, UserDataFetchPayload} from './userData.type';

export const userDataHandler = {
  [UserDataAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserDataFetchPayload>,
  ): UserState => {
    return {
      ...state,
      ...action.payload,
      isLogin: action.payload.isLogin,
      isFirstLogin: action.payload.isFirstLogin,
      userLevel: action.payload.userLevel,
      action: UserDataAction.FETCH,
    };
  },
  [UserDataAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userDataInitialState,
    action: UserDataAction.RESET,
  }),
  [UserDataAction.LOGOUT]: (): UserState => ({
    ...userInitialState,
    action: UserDataAction.LOGOUT,
  }),
};
