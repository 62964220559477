import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {httpPut} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataDepartmentEditAction} from './masterDataDepartmentEdit.action';
import {
  MasterDataDepartmentEditAction,
  MasterDataDepartmentEditFetchPayload,
  MasterDataDepartmentEditSuccessPayload,
} from './masterDataDepartmentEdit.type';

function* workerFetch(
  action: GenericAction<MasterDataDepartmentEditFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id, ...otherProps} = action.payload;
  try {
    const res: AxiosResponse<MasterDataDepartmentEditSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/department/save/${id}`,
      otherProps,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataDepartmentEditAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(masterDataDepartmentEditAction.failed(e.response.data));
  }
}

const masterDataDepartmentEditWatcher = [
  takeLatest(MasterDataDepartmentEditAction.FETCH, workerFetch),
];

export default masterDataDepartmentEditWatcher;
