import {
  MasterDataCategoryOptionListAction,
  MasterDataCategoryOptionListSuccessPayload,
  MasterDataCategoryOptionListFailedPayload,
  MasterDataCategoryOptionListFetchPayload,
} from './masterDataCategoryOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataCategoryOptionListInitialState} from './masterDataCategoryOptionList.reducer';

export const masterDataCategoryOptionListHandler = {
  [MasterDataCategoryOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataCategoryOptionListLoading: true,
      masterDataCategoryOptionListParams: action.payload,
      action: MasterDataCategoryOptionListAction.FETCH,
    };
  },
  [MasterDataCategoryOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryOptionListLoading: false,
    masterDataCategoryOptionListResponse: action.payload,
    action: MasterDataCategoryOptionListAction.SUCCESS,
  }),
  [MasterDataCategoryOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryOptionListLoading: false,
    masterDataCategoryOptionListData: [
      ...state.masterDataCategoryOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataCategoryOptionListAction.UPDATE,
  }),
  [MasterDataCategoryOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryOptionListLoading: false,
    masterDataCategoryOptionListError: action.payload,
    action: MasterDataCategoryOptionListAction.FAILED,
  }),
  [MasterDataCategoryOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataCategoryOptionListInitialState,
    action: MasterDataCategoryOptionListAction.RESET,
  }),
};
