import {createReducer} from '@reduxjs/toolkit';

import {WorkReportListInitialState} from './workReportList/workReportList.type';
import {WorkReportUploadInitialState} from './workReportUpload/workReportUpload.type';
import {WorkReportUploadSupportInitialState} from './workReportUploadSupport/workReportUploadSupport.type';
import {WorkReportCreateInitialState} from './workReportCreate/workReportCreate.type';
import {WorkReportEditInitialState} from './workReportEdit/workReportEdit.type';
import {WorkReportDeleteInitialState} from './workReportDelete/workReportDelete.type';
import {WorkReportExportAllInitialState} from './workReportExportAll/workReportExportAll.type';

import {workReportListInitialState} from './workReportList/workReportList.reducer';
import {workReportUploadInitialState} from './workReportUpload/workReportUpload.reducer';
import {workReportUploadSupportInitialState} from './workReportUploadSupport/workReportUploadSupport.reducer';
import {workReportCreateInitialState} from './workReportCreate/workReportCreate.reducer';
import {workReportEditInitialState} from './workReportEdit/workReportEdit.reducer';
import {workReportDeleteInitialState} from './workReportDelete/workReportDelete.reducer';
import {workReportExportAllInitialState} from './workReportExportAll/workReportExportAll.reducer';

import workReportHandler from './workReport.handler';

export interface WorkReportState
  extends WorkReportListInitialState,
    WorkReportUploadInitialState,
    WorkReportUploadSupportInitialState,
    WorkReportCreateInitialState,
    WorkReportEditInitialState,
    WorkReportDeleteInitialState,
    WorkReportExportAllInitialState {
  action: string;
}

export const workReportInitialState: WorkReportState = {
  ...workReportListInitialState,
  ...workReportUploadInitialState,
  ...workReportUploadSupportInitialState,
  ...workReportCreateInitialState,
  ...workReportEditInitialState,
  ...workReportDeleteInitialState,
  ...workReportExportAllInitialState,
  action: '',
};

export default createReducer(workReportInitialState, workReportHandler);
