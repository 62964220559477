import React from 'react';
import {DefaultRawDatum, PieSvgProps, ResponsivePie} from '@nivo/pie';
import {LegendProps} from '@nivo/legends';
import './MPieChart.scss';

//#region INTERFACE
export interface PieRawDatum extends DefaultRawDatum {
  label?: string;
  color?: string;
}
interface Props extends Omit<PieSvgProps<PieRawDatum>, 'height' | 'width'> {
  legends?: LegendProps[];
  extraLayer?: React.ReactElement;
}
//#endregion

const MPieChart: React.FC<Props> = ({
  colors = {datum: 'data.color'},
  enableArcLabels = false,
  enableArcLinkLabels = false,
  innerRadius = 0.75,
  margin = {top: 40, right: 20, bottom: 60, left: 20},
  legends,
  extraLayer,
  ...props
}: Props) => {
  return (
    <div className="m-pie-chart">
      <ResponsivePie
        colors={colors}
        enableArcLabels={enableArcLabels}
        enableArcLinkLabels={enableArcLinkLabels}
        innerRadius={innerRadius}
        margin={margin}
        legends={legends}
        {...props}
      />
      {extraLayer && <div className="m-pie-chart__extra">{extraLayer}</div>}
    </div>
  );
};

export default MPieChart;
