import {CommonError} from '../../../../../../config/constant';

export const MasterDataBusCreateAction = {
  FETCH: 'MASTER_DATA_BUS_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_BUS_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_BUS_CREATE_FAILED',
  RESET: 'MASTER_DATA_BUS_CREATE_RESET',
};

export interface MasterDataBusCreateInitialState {
  masterDataBusCreateLoading: boolean;
  masterDataBusCreateParams?: MasterDataBusCreateFetchPayload;
  masterDataBusCreateError?: MasterDataBusCreateFailedPayload;
  masterDataBusCreateResponse?: MasterDataBusCreateSuccessPayload;
}

export interface MasterDataBusCreateFetchPayload {
  bodyNumber: string;
  operator: string;
  busType: string;
  pool: string;
  isActive: number;
}

export type MasterDataBusCreateFailedPayload = CommonError;

export interface MasterDataBusCreateSuccessPayload {}
