//#region IMPORT
// Libraries
import React from 'react';
import Icon from '@mdi/react';
// Utils
import {colors} from '../../../../config/_theme';
// Assets
import './FollowUpChip.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  backgroundColor?: string;
  color?: string;
  icon?: string | React.ReactElement;
  text: string;
}
//#endregion

const FollowUpChip: React.FC<Props> = ({
  backgroundColor = colors.text.lightGrey5,
  color = colors.light,
  icon,
  text,
}: Props) => {
  const renderIcon = (icon: string | React.ReactElement) => {
    if (typeof icon === 'string') return <Icon path={icon} />;
    return icon;
  };
  return (
    <div className="follow-up-chip" style={{background: backgroundColor}}>
      {icon ? (
        <div className="follow-up-chip-icon">{renderIcon(icon)}</div>
      ) : null}
      <div className="follow-up-chip-text" style={{color: color}}>
        {text}
      </div>
    </div>
  );
};
export default FollowUpChip;
