//#region IMPORT
// Libraries
import React from 'react';
import {Grid} from '@mui/material';
// Data
import {DashboardServiceData} from '../../entity/dashboard.string.entity';
// Assets
import './DashboardServiceType.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: DashboardServiceData[];
  title: string;
}
//#endregion

const DashboardServiceType: React.FC<Props> = ({data, title}: Props) => {
  return (
    <div className="dashboard-service-type">
      <div className="dashboard-service-type__header">
        <div className="dashboard-service-type__header--title">{title}</div>
      </div>
      <div className="dashboard-service-type__content">
        <Grid container spacing={3}>
          {data.map((item: DashboardServiceData, idx: number) => (
            <Grid
              item
              xs={6}
              key={`dashboard-service-type-item${idx}-${item.id}`}>
              <div className="dashboard-service-type__content--item">
                <div
                  className="dashboard-service-type__content--item-icon"
                  style={{backgroundColor: item.backgroundColor}}>
                  {item.icon}
                </div>
                <div className="dashboard-service-type__content--column">
                  <div className="dashboard-service-type__content--item-title">
                    {item.name}
                  </div>
                  <div className="dashboard-service-type__content--item-value">
                    {item.value}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
export default DashboardServiceType;
