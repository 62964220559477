import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBasicServiceTypeEditAction,
  MasterDataBasicServiceTypeEditFailedPayload,
  MasterDataBasicServiceTypeEditFetchPayload,
  MasterDataBasicServiceTypeEditSuccessPayload,
} from './masterDataBasicServiceTypeEdit.type';

export const masterDataBasicServiceTypeEditAction = {
  fetch: createAction<MasterDataBasicServiceTypeEditFetchPayload>(
    MasterDataBasicServiceTypeEditAction.FETCH,
  ),
  success: createAction<MasterDataBasicServiceTypeEditSuccessPayload>(
    MasterDataBasicServiceTypeEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataBasicServiceTypeEditFailedPayload>(
    MasterDataBasicServiceTypeEditAction.FAILED,
  ),
  reset: createAction(MasterDataBasicServiceTypeEditAction.RESET),
};
