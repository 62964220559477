import {CommonError} from '../../../../config/constant';
import {User, UserAccountFormData} from '../../entity/user.string.entity';

export const UserAccountEditAction = {
  FETCH: 'USER_ACCOUNT_EDIT_FETCH',
  SUCCESS: 'USER_ACCOUNT_EDIT_SUCCESS',
  FAILED: 'USER_ACCOUNT_EDIT_FAILED',
  RESET: 'USER_ACCOUNT_EDIT_RESET',
};

export interface UserAccountEditInitialState {
  userAccountEditLoading: boolean;
  userAccountEditParams?: UserAccountEditFetchPayload;
  userAccountEditError?: UserAccountEditFailedPayload;
  userAccountEditResponse?: UserAccountEditSuccessPayload;
}

export type UserAccountEditFetchPayload = UserAccountFormData;
export type UserAccountEditFailedPayload = CommonError;

export type UserAccountEditSuccessPayload = {
  data: User;
};
