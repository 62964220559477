import {CommonError} from '../../../../config/constant';
import {UserData} from '../../entity/user.string.entity';

export const UserLoginAction = {
  FETCH: 'USER_LOGIN_FETCH',
  SUCCESS: 'USER_LOGIN_SUCCESS',
  FAILED: 'USER_LOGIN_FAILED',
  RESET: 'USER_LOGIN_RESET',
};

export interface UserLoginInitialState {
  userLoginLoading: boolean;
  userLoginParams?: UserLoginFetchPayload;
  userLoginError?: UserLoginFailedPayload;
  userLoginResponse?: UserLoginSuccessPayload;
}

export type UserLoginFetchPayload = {
  email: string;
  password: string;
};

export type UserLoginFailedPayload = CommonError;

export type UserLoginSuccessPayload = {
  data: UserData;
};
