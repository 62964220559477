//#region IMPORT
// Libraries
import React from 'react';
import {TableHead, TableRow, TableCell, TableSortLabel} from '@mui/material';
// Utils
import t from '../../../../../../../../lang';
import {SortTable} from '../../../../../../../../config/constant';
// Data
import {MasterDataRuteOrderByType} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  isIBK?: boolean;
  sortBy: SortTable;
  orderBy: MasterDataRuteOrderByType;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataRuteOrderByType,
  ): void;
}
//#endregion

const MasterDataRuteBRTManagementListTableHeader: React.FC<Props> = ({
  isIBK,
  sortBy,
  orderBy,
  onHandleSort,
}: Props) => {
  //#region SORT HANDLER
  const createSortHandler = (property: MasterDataRuteOrderByType) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onHandleSort(event, property);
  };
  //#endregion

  return (
    <TableHead className="master-rute-brt-management-list-table-header">
      <TableRow className="master-rute-brt-management-list-table-header__row">
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="table-header-label">
          {t('No')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'corridor' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'corridor'}
            direction={orderBy === 'corridor' ? sortBy : 'asc'}
            onClick={createSortHandler('corridor')}>
            {isIBK ? t('Route Code') : t('Corridor')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'route' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'route'}
            direction={orderBy === 'route' ? sortBy : 'asc'}
            onClick={createSortHandler('route')}>
            {t('Route')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'busStop' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'busStop'}
            direction={orderBy === 'busStop' ? sortBy : 'asc'}
            onClick={createSortHandler('busStop')}>
            {t('Bus Stop')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'busStopCode' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'busStopCode'}
            direction={orderBy === 'busStopCode' ? sortBy : 'asc'}
            onClick={createSortHandler('busStopCode')}>
            {t('Bus Stop Code')}
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default MasterDataRuteBRTManagementListTableHeader;
