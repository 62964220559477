//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Components
import MasterDataBasicServiceTypeManagementListTableHeader from '../MasterDataBasicServiceTypeManagementListTableHeader';
import MasterDataBasicServiceTypeManagementListTableItem from '../MasterDataBasicServiceTypeManagementListTableItem';
// Data
import {MasterDataBasicServiceType} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  basicServiceTypeList: MasterDataBasicServiceType[];
  onEditBasicServiceType?(_basicServiceType: MasterDataBasicServiceType): void;
  onDeleteBasicServiceType?(
    _basicServiceType: MasterDataBasicServiceType,
  ): void;
  onMoveUp?(id: string): void;
  onMoveDown?(id: string): void;
}
//#endregion

const MasterDataBasicServiceTypeManagementList: React.FC<Props> = ({
  basicServiceTypeList,
  onEditBasicServiceType,
  onDeleteBasicServiceType,
  onMoveUp,
  onMoveDown,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataBasicServiceTypeManagementListTableHeader />
        <TableBody>
          {basicServiceTypeList.map(
            (item: MasterDataBasicServiceType, index: number) => (
              <MasterDataBasicServiceTypeManagementListTableItem
                {...(onEditBasicServiceType && {
                  onEditItem: () => onEditBasicServiceType(item),
                })}
                {...(onDeleteBasicServiceType && {
                  onDeleteItem: () => onDeleteBasicServiceType(item),
                })}
                {...(onMoveUp && {
                  onMoveUpItem: () => onMoveUp(item.id),
                })}
                {...(onMoveDown && {
                  onMoveDownItem: () => onMoveDown(item.id),
                })}
                key={`master-data-basic-service-type-list-item-data-${item.id}-${index}`}
                data={item}
                index={index + 1}
                isFirst={index === 0}
                isLast={index === basicServiceTypeList.length - 1}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataBasicServiceTypeManagementList;
