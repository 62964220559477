import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDivisionCreateInitialState} from './masterDataDivisionCreate.reducer';
import {
  MasterDataDivisionCreateAction,
  MasterDataDivisionCreateFailedPayload,
  MasterDataDivisionCreateFetchPayload,
  MasterDataDivisionCreateSuccessPayload,
} from './masterDataDivisionCreate.type';

export const masterDataDivisionCreateHandler = {
  [MasterDataDivisionCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionCreateLoading: true,
    masterDataDivisionCreateParams: action.payload,
    action: MasterDataDivisionCreateAction.FETCH,
  }),
  [MasterDataDivisionCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionCreateParams: undefined,
    masterDataDivisionCreateLoading: false,
    masterDataDivisionCreateResponse: action.payload,
    action: MasterDataDivisionCreateAction.SUCCESS,
  }),
  [MasterDataDivisionCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionCreateLoading: false,
    masterDataDivisionCreateError: action.payload,
    action: MasterDataDivisionCreateAction.FAILED,
  }),
  [MasterDataDivisionCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDivisionCreateInitialState,
    action: MasterDataDivisionCreateAction.RESET,
  }),
};
