import {CommonError} from '../../../../../../config/constant';
import {MasterDataCounter} from '../../../../entity/masterData.string.entity';

export const MasterDataCounterListAction = {
  FETCH: 'MASTER_DATA_COUNTER_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_COUNTER_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_COUNTER_LIST_FAILED',
  RESET: 'MASTER_DATA_COUNTER_LIST_RESET',
};

export interface MasterDataCounterListInitialState {
  masterDataCounterListLoading: boolean;
  masterDataCounterListError?: MasterDataCounterListFailedPayload;
  masterDataCounterListParams?: MasterDataCounterListFetchPayload;
  masterDataCounterListResponse?: MasterDataCounterListSuccessPayload;
}

export type MasterDataCounterListFailedPayload = CommonError;

export type MasterDataCounterListSuccessPayload = {data: MasterDataCounter};

export interface MasterDataCounterListFetchPayload {}
