import {AxiosResponse} from 'axios';
import moment from 'moment';
import {call, put, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {post} from '../../../../services/http';
import {userDataAction} from '../userData/userData.action';
import {userOTPPasswordAction} from './userOTPPassword.action';
import {
  UserOTPPasswordAction,
  UserOTPPasswordFetchPayload,
  UserOTPPasswordSuccessPayload,
} from './userOTPPassword.type';

function* workerFetch(action: GenericAction<UserOTPPasswordFetchPayload>) {
  try {
    const res: AxiosResponse<UserOTPPasswordSuccessPayload> = yield call(
      post,
      `${Env.api}/open/confirm_otp`,
      action.payload,
    );
    if (res.status === 200) {
      const {data} = res.data;
      yield put(userOTPPasswordAction.success(res.data));
      yield put(
        userDataAction.fetch({
          accessToken: data?.accessToken || '',
          tokenType: 'Bearer',
          data: data,
          isFirstLogin: data?.isFirstLogin,
          lastSuccessLogin: moment().toISOString(),
        }),
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userOTPPasswordAction.failed(e.response.data.payload));
  }
}

const userOTPPasswordWatcher = [
  takeLatest(UserOTPPasswordAction.FETCH, workerFetch),
];

export default userOTPPasswordWatcher;
