import {
  MasterDataDetailObservationListAction,
  MasterDataDetailObservationListSuccessPayload,
  MasterDataDetailObservationListFailedPayload,
  MasterDataDetailObservationListFetchPayload,
} from './masterDataDetailObservationList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDetailObservationListInitialState} from './masterDataDetailObservationList.reducer';

export const masterDataDetailObservationListHandler = {
  [MasterDataDetailObservationListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDetailObservationListLoading: true,
      masterDataDetailObservationListParams: action.payload,
      action: MasterDataDetailObservationListAction.FETCH,
    };
  },
  [MasterDataDetailObservationListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationListLoading: false,
    masterDataDetailObservationListResponse: action.payload,
    action: MasterDataDetailObservationListAction.SUCCESS,
  }),
  [MasterDataDetailObservationListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationListLoading: false,
    masterDataDetailObservationListData: [
      ...state.masterDataDetailObservationListData,
      ...action.payload.data.content,
    ],
    action: MasterDataDetailObservationListAction.UPDATE,
  }),
  [MasterDataDetailObservationListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationListLoading: false,
    masterDataDetailObservationListError: action.payload,
    action: MasterDataDetailObservationListAction.FAILED,
  }),
  [MasterDataDetailObservationListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailObservationListInitialState,
    action: MasterDataDetailObservationListAction.RESET,
  }),
};
