//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {Collapse, Grid} from '@mui/material';
// Components
import {
  EvaluationReportCollapse,
  EvaluationReportIndicator,
  EvaluationReportNote,
  EvaluationReportObservation,
} from '../../../../../components';
// Data
import {
  EvaluationReportDetailList,
  EvaluationReportDetailPreview,
  EvaluationReportFindings,
  EvaluationReportPreviewFindings,
} from '../../../../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportDetailItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: EvaluationReportDetailList;
  handlePreview(_previewFindings: EvaluationReportPreviewFindings): void;
}
//#endregion

const EvaluationReportDetailItem: React.FC<Props> = ({
  data,
  handlePreview,
}: Props) => {
  //#region GENERAL
  const [isCollapseOpened, setIsCollapseOpened] = useState(true);
  //#endregion

  //#region HANDLE COLLAPSE
  const handleCollapse = () => {
    setIsCollapseOpened(!isCollapseOpened);
  };
  //#endregion

  return (
    <div className="evaluation-report-detail-item">
      <EvaluationReportCollapse
        title={data.categoryName}
        isCollapseOpened={isCollapseOpened}
        handleCollapse={handleCollapse}
      />
      <Collapse
        in={isCollapseOpened}
        timeout="auto"
        unmountOnExit
        className="evaluation-report-detail-item__collapse">
        {data.previewFormReviews.map(
          (
            basicServiceTypeItem: EvaluationReportDetailPreview,
            basicServiceTypeIdx: number,
          ) => (
            <div
              key={`evaluation-report-detail-item__category-${basicServiceTypeItem.basicServiceTypeName}-${basicServiceTypeIdx}`}
              className="evaluation-report-detail-item__category">
              {basicServiceTypeItem.basicServiceTypeName && (
                <div className="evaluation-report-detail-item__category-title">
                  {basicServiceTypeItem.basicServiceTypeName}
                </div>
              )}
              <div className="evaluation-report-detail-item__category-item">
                {basicServiceTypeItem.previewReviews.map(
                  (
                    findingItem: EvaluationReportFindings,
                    findingIdx: number,
                  ) => (
                    <Grid
                      container
                      spacing={3}
                      key={`evaluation-report-findings-${findingItem.reviewFormId}-${findingIdx}`}>
                      {(!!findingItem.indicatorValue ||
                        !!findingItem.detailIndicators) && (
                        <Grid
                          item
                          xs={12}
                          key={`evaluation-report-indicator-${findingItem.reviewFormId}-${findingIdx}`}>
                          <EvaluationReportIndicator
                            reviewIndex={findingIdx + 1}
                            indicatorData={findingItem}
                            handlePreview={handlePreview}
                          />
                        </Grid>
                      )}
                      {(!!findingItem.observationValue ||
                        !!findingItem.multipleObservationValue ||
                        !!findingItem.detailObservations) && (
                        <Grid
                          item
                          xs={12}
                          key={`evaluation-report-observation-${findingItem.reviewFormId}-${findingIdx}`}>
                          <EvaluationReportObservation
                            reviewIndex={findingIdx + 1}
                            observationData={findingItem}
                            handlePreview={handlePreview}
                          />
                        </Grid>
                      )}
                      {!!findingItem.noteValue && (
                        <Grid
                          item
                          xs={12}
                          key={`evaluation-report-note-${findingItem.reviewFormId}-${findingIdx}`}>
                          <EvaluationReportNote
                            noteData={findingItem}
                            handlePreview={handlePreview}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ),
                )}
              </div>
            </div>
          ),
        )}
      </Collapse>
    </div>
  );
};
export default EvaluationReportDetailItem;
