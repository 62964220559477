import {UserDataInitialState} from './userData.type';

export const userDataInitialState: UserDataInitialState = {
  accessToken: undefined,
  refreshToken: undefined,
  tokenType: undefined,
  data: undefined,
  isLogin: false,
  isFirstLogin: false,
  lastSuccessLogin: undefined,
  userLevel: undefined,
};
