import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataDivision} from '../../../../entity/masterData.string.entity';

export const MasterDataDivisionListAction = {
  FETCH: 'MASTER_DATA_DIVISION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DIVISION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DIVISION_LIST_FAILED',
  RESET: 'MASTER_DATA_DIVISION_LIST_RESET',
  UPDATE: 'MASTER_DATA_DIVISION_LIST_UPDATE',
};

export interface MasterDataDivisionListInitialState {
  masterDataDivisionListLoading: boolean;
  masterDataDivisionListData: MasterDataDivision[];
  masterDataDivisionListError?: MasterDataDivisionListFailedPayload;
  masterDataDivisionListParams?: MasterDataDivisionListFetchPayload;
  masterDataDivisionListResponse?: MasterDataDivisionListSuccessPayload;
}

export type MasterDataDivisionListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataDivision[]>;
};

export type MasterDataDivisionListFailedPayload = CommonError;

export type MasterDataDivisionListSuccessPayload = MasterDataDivisionListResponse;

export type MasterDataDivisionListUpdatePayload = MasterDataDivisionListResponse;

export interface MasterDataDivisionListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
}
