import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailObservationEditAction,
  MasterDataDetailObservationEditFailedPayload,
  MasterDataDetailObservationEditFetchPayload,
  MasterDataDetailObservationEditSuccessPayload,
} from './masterDataDetailObservationEdit.type';

export const masterDataDetailObservationEditAction = {
  fetch: createAction<MasterDataDetailObservationEditFetchPayload>(
    MasterDataDetailObservationEditAction.FETCH,
  ),
  success: createAction<MasterDataDetailObservationEditSuccessPayload>(
    MasterDataDetailObservationEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailObservationEditFailedPayload>(
    MasterDataDetailObservationEditAction.FAILED,
  ),
  reset: createAction(MasterDataDetailObservationEditAction.RESET),
};
