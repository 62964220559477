import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {dashboardTabDepartmentListAction} from './dashboardTabDepartmentList.action';
import {
  DashboardTabDepartmentListAction,
  DashboardTabDepartmentListResponse,
  DashboardTabDepartmentListSuccessPayload,
} from './dashboardTabDepartmentList.type';

function* workerFetch() {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<DashboardTabDepartmentListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/dashboard/department`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        dashboardTabDepartmentListAction.success(
          res.data as DashboardTabDepartmentListResponse,
        ),
      );
      yield put(
        dashboardTabDepartmentListAction.update(
          res.data as DashboardTabDepartmentListResponse,
        ),
      );
    } else {
      yield put(
        dashboardTabDepartmentListAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(dashboardTabDepartmentListAction.failed(e as CommonError));
  }
}

const dashboardTabDepartmentListWatcher = [
  takeLatest(DashboardTabDepartmentListAction.FETCH, workerFetch),
];

export default dashboardTabDepartmentListWatcher;
