import {
  DashboardOverviewChartSectionAction,
  DashboardOverviewChartSectionSuccessPayload,
  DashboardOverviewChartSectionFailedPayload,
  DashboardOverviewChartSectionFetchPayload,
} from './dashboardOverviewChartSection.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardOverviewChartSectionInitialState} from './dashboardOverviewChartSection.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardOverviewChartSectionHandler = {
  [DashboardOverviewChartSectionAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewChartSectionFetchPayload>,
  ): DashboardState => {
    return {
      ...state,
      dashboardOverviewChartSectionParams: action.payload,
      dashboardOverviewChartSectionLoading: true,
      action: DashboardOverviewChartSectionAction.FETCH,
    };
  },
  [DashboardOverviewChartSectionAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewChartSectionSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewChartSectionLoading: false,
    dashboardOverviewChartSectionResponse: action.payload,
    action: DashboardOverviewChartSectionAction.SUCCESS,
  }),
  [DashboardOverviewChartSectionAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewChartSectionFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewChartSectionLoading: false,
    dashboardOverviewChartSectionError: action.payload,
    action: DashboardOverviewChartSectionAction.FAILED,
  }),
  [DashboardOverviewChartSectionAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardOverviewChartSectionInitialState,
    action: DashboardOverviewChartSectionAction.RESET,
  }),
};
