import {CommonError} from '../../../../config/constant';

export const FollowUpPICConfirmAction = {
  FETCH: 'FOLLOW_UP_PIC_CONFIRM_FETCH',
  SUCCESS: 'FOLLOW_UP_PIC_CONFIRM_SUCCESS',
  FAILED: 'FOLLOW_UP_PIC_CONFIRM_FAILED',
  RESET: 'FOLLOW_UP_PIC_CONFIRM_RESET',
};

export interface FollowUpPICConfirmInitialState {
  followUpPICConfirmLoading: boolean;
  followUpPICConfirmParams?: FollowUpPICConfirmFetchPayload;
  followUpPICConfirmError?: FollowUpPICConfirmFailedPayload;
  followUpPICConfirmResponse?: FollowUpPICConfirmSuccessPayload;
}

export type FollowUpPICConfirmFetchPayload = {id: string};
export type FollowUpPICConfirmFailedPayload = CommonError;

export interface FollowUpPICConfirmSuccessPayload {}
