//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataWorkInstructionEditAction} from '../../../../usecases/masterData.action';
// Components
import WorkInstructionForm from './components/WorkInstructionForm';
// Data
import {
  MasterDataWorkInstruction,
  MasterDataWorkInstructionFormData,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataWorkInstructionFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: MasterDataWorkInstruction;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataWorkInstructionFormContainer: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataWorkInstructionFormData = {
    id: data?.id || '',
    name: data?.name || '',
    workInstruction: data?.workInstruction || '',
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-work-instruction-form-container">
      <WorkInstructionForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitUser={(_workInstruction) => {
          if (_workInstruction.id) {
            dispatch(
              masterDataWorkInstructionEditAction.fetch(_workInstruction),
            );
          }
        }}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataWorkInstructionFormContainer;
