/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {RangeInput} from '@mui/lab/DateRangePicker/RangeTypes';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {Moment} from 'moment';
// Utils
import t from '../../../../../lang';
import {AppState} from '../../../../../config/Interface';
import {EvaluationReportState} from '../../../usecases/evaluationReport.reducer';
import {
  evaluationReportExportAction,
  evaluationReportListAction,
} from '../../../usecases/evaluationReport.action';
import {
  EvaluationReportExportAction,
  EvaluationReportListAction,
} from '../../../usecases/evaluationReport.type';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {EvaluationReportFilter} from '../../../components';
import {EvaluationReportList} from './components';
import {FollowUpEmpty} from '../../../../followUp/components';
import {
  MButton,
  Modal,
  MSpinner,
  MTablePagination,
} from '../../../../../components';
// Data
import {EvaluationReport} from '../../../entity/evaluationReport.string.entity';
import {AssignmentType, PATH, SortType} from '../../../../wrapper/entity';
// Assets
import './EvaluationReportSection.scss';
import EvaluationReportExportFormContainer from '../EvaluationReportExportFormContainer';
//#endregion
interface Props {
  isUserCanExport?: boolean;
}

const EvaluationReportSection: React.FC<Props> = ({isUserCanExport}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    isDownloadAllEvaluationReport,
    setIsDownloadAllEvaluationReport,
  ] = useState(false);

  const evaluationReportData: EvaluationReportState = useSelector(
    (state: AppState) => state.evaluationReport,
    shallowEqual,
  );

  const isLoadingEvaluationReportExport =
    evaluationReportData.evaluationReportExportLoading;

  const isLoadingEvaluationReport: boolean =
    evaluationReportData.evaluationReportListLoading;
  //#endregion

  //#region FILTER
  const [searchValue, setSearchValue] = useState('');

  const [selectedCheckmarksFilter, setSelectedCheckmarksFilter] = useState<
    string[]
  >([
    AssignmentType.HALTE,
    AssignmentType.BUSBRT,
    AssignmentType.BUSNONBRT,
    AssignmentType.BUSKECIL,
    AssignmentType.TRANSCARE,
  ]);

  const [selectedDateFilter, setSelectedDateFilter] = useState<
    RangeInput<unknown>
  >([null, null]);

  const [selectedSort, setSelectedSort] = useState<SortType>(SortType.DESC);
  //#endregion

  //#region PAGINATION CONTROL
  const [
    evaluationReportListPage,
    setEvaluationReportListPage,
  ] = useState<number>(0);

  const [
    evaluationReportListCount,
    setEvaluationReportListCount,
  ] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setEvaluationReportListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setEvaluationReportListPage(0);
  };
  //#endregion

  //#region FETCH EVALUATION REPORT
  const fetchEvaluationReportList = useCallback(() => {
    dispatch(
      evaluationReportListAction.fetch({
        page: evaluationReportListPage,
        size: rowsPerPage,
        ...(searchValue ? {search: searchValue} : {}),
        ...(selectedCheckmarksFilter.length === 0
          ? {}
          : {filter: selectedCheckmarksFilter.join()}),
        sort: selectedSort,
        ...(selectedDateFilter.filter((item) => item).length === 2
          ? {
              startDate: (selectedDateFilter[0] as Moment).format('YYYY-MM-DD'),
              endDate: (selectedDateFilter[1] as Moment).format('YYYY-MM-DD'),
            }
          : {}),
      }),
    );
  }, [
    dispatch,
    evaluationReportListPage,
    rowsPerPage,
    searchValue,
    selectedCheckmarksFilter,
    selectedDateFilter,
    selectedSort,
  ]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(evaluationReportListAction.reset());
    fetchEvaluationReportList();

    return () => {
      dispatch(evaluationReportListAction.reset());
    };
  }, [dispatch, fetchEvaluationReportList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const evaluationReportActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(EvaluationReportListAction.UPDATE, (): void => {
            const totalElement =
              evaluationReportData.evaluationReportListResponse?.data
                ?.totalElements;
            setEvaluationReportListCount(totalElement ?? 0);
          })
          .addCase(EvaluationReportListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(evaluationReportListAction.reset());
          })
          .addCase(EvaluationReportExportAction.SUCCESS, (): void => {
            setIsDownloadAllEvaluationReport(false);
          })
          .addCase(EvaluationReportExportAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data export failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(evaluationReportExportAction.reset());
          });
      });
    },
    [
      dispatch,
      evaluationReportData.evaluationReportListResponse?.data.totalElements,
    ],
  );
  useEffect(() => {
    evaluationReportActionHandler(evaluationReportData.action);
  }, [evaluationReportData.action, evaluationReportActionHandler]);
  //#endregion

  //#region HANDLE DETAIL
  const handleDetail = (_evaluationReport: EvaluationReport) => {
    if (_evaluationReport.id) {
      history.push(PATH.LAPORAN_PENILAIAN_DETAIL, {
        transReviewId: _evaluationReport.id,
      });
    }
  };
  //#endregion

  //#region DATA LOAD
  const emptyData = () => {
    return (
      <div className="evaluation-report-section__content--empty">
        <FollowUpEmpty text={t('No evaluation report')} />
      </div>
    );
  };

  const loadingData = () => {
    return (
      <div className="evaluation-report-section__content--loading">
        <MSpinner size={40} />
      </div>
    );
  };

  const dataLoad = () => {
    if (isLoadingEvaluationReport) {
      return loadingData();
    } else {
      if (evaluationReportData.evaluationReportListData.length === 0)
        return emptyData();
      return (
        <div className="evaluation-report-section__content--list">
          <EvaluationReportList
            data={evaluationReportData.evaluationReportListData}
            handleClick={(_evaluationReport: EvaluationReport) =>
              handleDetail(_evaluationReport)
            }
          />
          <MTablePagination
            className="evaluation-report-list-pagination"
            rowsPerPageOptions={[25, 50, 100]}
            count={evaluationReportListCount}
            rowsPerPage={rowsPerPage}
            page={evaluationReportListPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      );
    }
  };
  //#endregion

  return (
    <div className="evaluation-report-section">
      <div className="evaluation-report-section__filter">
        <EvaluationReportFilter
          searchFilterValue={searchValue}
          searchFilterPlaceholder={t('Search Evaluation Report')}
          handleChangeSearchFilter={(search: string) => {
            setEvaluationReportListPage(0);
            setSearchValue(search);
          }}
          selectedCheckmarksFilter={selectedCheckmarksFilter}
          checkmarksFilterPlaceholder={t('Choose Type')}
          handleChangeCheckmarksFilter={(value: string) => {
            setEvaluationReportListPage(0);
            setSelectedCheckmarksFilter(
              typeof value === 'string' ? value.split(',') : value,
            );
          }}
          selectedDateFilter={selectedDateFilter}
          handleChangeDateFilter={(date: RangeInput<unknown>) => {
            setEvaluationReportListPage(0);
            setSelectedDateFilter(date);
          }}
          handleResetFilter={() => {
            setEvaluationReportListPage(0);
            setSearchValue('');
            setSelectedCheckmarksFilter([
              AssignmentType.HALTE,
              AssignmentType.BUSBRT,
              AssignmentType.BUSNONBRT,
              AssignmentType.BUSKECIL,
              AssignmentType.TRANSCARE,
            ]);
            setSelectedDateFilter([null, null]);
          }}
          selectedSort={selectedSort}
          handleSort={(sort) => {
            setSelectedSort(sort as SortType);
          }}
        />
        {isUserCanExport && (
          <div className="evaluation-report-section__filter--export">
            <MButton
              buttonLabel={t('Export Data')}
              handleClick={() => setIsDownloadAllEvaluationReport(true)}
            />
          </div>
        )}
      </div>
      <div className="evaluation-report-section__content">{dataLoad()}</div>
      {isUserCanExport && isDownloadAllEvaluationReport && (
        <Modal
          onClose={() => setIsDownloadAllEvaluationReport(false)}
          isOpen={!!isDownloadAllEvaluationReport}
          title={t('Export Penilaian')}>
          <EvaluationReportExportFormContainer
            isLoading={isLoadingEvaluationReportExport}
            onCancel={() => setIsDownloadAllEvaluationReport(false)}
          />
        </Modal>
      )}
    </div>
  );
};

export default EvaluationReportSection;
