import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationValueSetPositiveValueAction,
  MasterDataObservationValueSetPositiveValueFailedPayload,
  MasterDataObservationValueSetPositiveValueFetchPayload,
  MasterDataObservationValueSetPositiveValueSuccessPayload,
} from './masterDataObservationValueSetPositiveValue.type';

export const masterDataObservationValueSetPositiveValueAction = {
  fetch: createAction<MasterDataObservationValueSetPositiveValueFetchPayload>(
    MasterDataObservationValueSetPositiveValueAction.FETCH,
  ),
  success: createAction<MasterDataObservationValueSetPositiveValueSuccessPayload>(
    MasterDataObservationValueSetPositiveValueAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationValueSetPositiveValueFailedPayload>(
    MasterDataObservationValueSetPositiveValueAction.FAILED,
  ),
  reset: createAction(MasterDataObservationValueSetPositiveValueAction.RESET),
};
