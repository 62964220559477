import {CommonError} from '../../../../../../config/constant';
import {MasterDataWorkInstructionFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataWorkInstructionEditAction = {
  FETCH: 'MASTER_DATA_WORK_INSTRUCTION_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_WORK_INSTRUCTION_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_WORK_INSTRUCTION_EDIT_FAILED',
  RESET: 'MASTER_DATA_WORK_INSTRUCTION_EDIT_RESET',
};

export interface MasterDataWorkInstructionEditInitialState {
  masterDataWorkInstructionEditLoading: boolean;
  masterDataWorkInstructionEditParams?: MasterDataWorkInstructionEditFetchPayload;
  masterDataWorkInstructionEditError?: MasterDataWorkInstructionEditFailedPayload;
  masterDataWorkInstructionEditResponse?: MasterDataWorkInstructionEditSuccessPayload;
}

export type MasterDataWorkInstructionEditFetchPayload = MasterDataWorkInstructionFormData;

export type MasterDataWorkInstructionEditFailedPayload = CommonError;

export interface MasterDataWorkInstructionEditSuccessPayload {}
