import {CommonError} from '../../../../../../config/constant';
import {DashboardOverviewBestSection} from '../../../../entity/dashboard.string.entity';

export const DashboardOverviewBestSectionAction = {
  FETCH: 'DASHBOARD_OVERVIEW_BEST_SECTION_FETCH',
  SUCCESS: 'DASHBOARD_OVERVIEW_BEST_SECTION_SUCCESS',
  FAILED: 'DASHBOARD_OVERVIEW_BEST_SECTION_FAILED',
  RESET: 'DASHBOARD_OVERVIEW_BEST_SECTION_RESET',
};

export interface DashboardOverviewBestSectionInitialState {
  dashboardOverviewBestSectionLoading: boolean;
  dashboardOverviewBestSectionError?: DashboardOverviewBestSectionFailedPayload;
  dashboardOverviewBestSectionResponse?: DashboardOverviewBestSectionSuccessPayload;
  dashboardOverviewBestSectionParams?: DashboardOverviewBestSectionFetchPayload;
}

export interface DashboardOverviewBestSectionFetchPayload {
  year: number;
  month: number;
}

export interface DashboardOverviewBestSectionResponse {
  status: string;
  info?: number;
  data: DashboardOverviewBestSection;
}

export type DashboardOverviewBestSectionFailedPayload = CommonError;

export type DashboardOverviewBestSectionSuccessPayload = DashboardOverviewBestSectionResponse;

export type DashboardOverviewBestSectionUpdatePayload = DashboardOverviewBestSectionResponse;
