//#region IMPORT
// Libraries
import React, {useCallback, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import {Card, Grid} from '@mui/material';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../usecases/user.reducer';
import {UserLoginAction, UserLoginFetchPayload} from '../../usecases/user.type';
import {userLoginAction} from '../../usecases/userLogin/userLogin.action';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {MButton, MTextField} from '../../../../components';
// Data
import {emailRegex} from '../../../../config/constant';
import {PATH} from '../../../wrapper/entity';
// Assets
import './UserLogin.scss';
import {LogoSPM} from '../../../../assets';
import illustration_home from '../../../../assets/illustrations/illustration_home.png';
//#endregion

const UserLogin: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const history = useHistory();

  const user: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const {action} = user;

  const isLoading = user.userLoginLoading;
  //#endregion

  //#region Login ACTIVATION
  const handleLogin = (value: UserLoginFetchPayload) => {
    const payload: UserLoginFetchPayload = {
      email: value.email,
      password: value.password,
    };
    dispatch(userLoginAction.fetch(payload));
  };
  //#endregion

  //#region ACTION HANDLER
  const userLoginActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserLoginAction.SUCCESS, (): void => {
            const isFirstLogin = user.isFirstLogin;
            if (isFirstLogin) {
              history.replace(PATH.CHANGE_PASSWORD);
            } else {
              history.replace(PATH.HOME);
            }
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Login success!'),
                type: 'success',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserLoginAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Login failed!'),
                type: 'info',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userLoginAction.reset());
          });
      });
    },
    [dispatch, history, user.isFirstLogin],
  );
  useEffect(() => {
    userLoginActionHandler(action);
  }, [action, userLoginActionHandler]);
  //#endregion

  //#region VALIDATION
  const loginValidation = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, t('Must be a valid email.'))
      .required(t('Email is required.')),
    password: Yup.string()
      .required(t('Password is required.'))
      .min(6, t('Password min 6 character.')),
  });
  //#endregion

  return (
    <div className="user-login">
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="user-login__banner">
            <div className="user-login__banner--image">
              <img src={illustration_home} />
            </div>
            <div className="user-login__banner--title">
              {t('TransJakarta service quality in hand')}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className="user-login__container">
            <div className="user-login__container--logo">
              <LogoSPM width={208} height={36} />
            </div>
            <Card variant="outlined" className="user-login__container--content">
              <div className="user-login-content">
                <div className="user-login-content__form">
                  <Formik
                    validationSchema={loginValidation}
                    initialValues={{email: '', password: ''}}
                    onSubmit={handleLogin}>
                    {({handleSubmit}): React.ReactElement => (
                      <>
                        <div className="user-login-content__form--field">
                          <div className="input-label">{t('Email')}</div>
                          <Field
                            type="email"
                            name="email"
                            placeholder={t('Email')}
                            component={MTextField}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="user-login-content__form--field">
                          <div className="input-label">{t('Password')}</div>
                          <Field
                            type="password"
                            name="password"
                            placeholder={t('Password')}
                            component={MTextField}
                            disabled={isLoading}
                          />
                        </div>
                        <div className="user-login-content__form--sub-actions">
                          <span
                            onClick={() => history.push(PATH.FORGET_PASSWORD)}
                            className="sub-actions-forget-password">
                            {t('Forget Password?')}
                          </span>
                        </div>

                        <div className="user-login-content__form--actions">
                          <MButton
                            buttonLabel={t('Sign in')}
                            handleClick={handleSubmit}
                            isLoading={isLoading}
                          />
                        </div>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </Card>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserLogin;
