import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataIndicatorValueOptionListAction,
  MasterDataIndicatorValueOptionListFailedPayload,
  MasterDataIndicatorValueOptionListFetchPayload,
  MasterDataIndicatorValueOptionListSuccessPayload,
  MasterDataIndicatorValueOptionListUpdatePayload,
} from './masterDataIndicatorValueOptionList.type';

export const masterDataIndicatorValueOptionListAction = {
  fetch: createAction<MasterDataIndicatorValueOptionListFetchPayload>(
    MasterDataIndicatorValueOptionListAction.FETCH,
  ),
  success: createAction<MasterDataIndicatorValueOptionListSuccessPayload>(
    MasterDataIndicatorValueOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataIndicatorValueOptionListFailedPayload>(
    MasterDataIndicatorValueOptionListAction.FAILED,
  ),
  update: createAction<MasterDataIndicatorValueOptionListUpdatePayload>(
    MasterDataIndicatorValueOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataIndicatorValueOptionListAction.RESET),
};
