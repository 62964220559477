//#region IMPORT
// Libraries
import React from 'react';
import Icon from '@mdi/react';
// Assets
import './FollowUpNotification.component.style.scss';
import {IconInfo} from '../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  icon?: string | React.ReactElement;
  text: string;
  actionLabel?: string;
  onAction?(): void;
}
//#endregion

const FollowUpNotification: React.FC<Props> = ({
  icon = <IconInfo />,
  text,
  actionLabel,
  onAction,
}: Props) => {
  const renderIcon = (icon: string | React.ReactElement) => {
    if (typeof icon === 'string') return <Icon path={icon} />;
    return icon;
  };
  return (
    <div className="follow-up-notification">
      {icon && (
        <div className="follow-up-notification__icon">{renderIcon(icon)}</div>
      )}
      <div className="follow-up-notification__text">{text}</div>
      {actionLabel && (
        <div
          className="follow-up-notification__action"
          onClick={() => onAction && onAction()}>
          {actionLabel}
        </div>
      )}
    </div>
  );
};
export default FollowUpNotification;
