import {createAction} from '@reduxjs/toolkit';
import {
  DashboardDepartmentBestSectionAction,
  DashboardDepartmentBestSectionFailedPayload,
  DashboardDepartmentBestSectionFetchPayload,
  DashboardDepartmentBestSectionSuccessPayload,
} from './dashboardDepartmentBestSection.type';

export const dashboardDepartmentBestSectionAction = {
  fetch: createAction<DashboardDepartmentBestSectionFetchPayload>(
    DashboardDepartmentBestSectionAction.FETCH,
  ),
  success: createAction<DashboardDepartmentBestSectionSuccessPayload>(
    DashboardDepartmentBestSectionAction.SUCCESS,
  ),
  failed: createAction<DashboardDepartmentBestSectionFailedPayload>(
    DashboardDepartmentBestSectionAction.FAILED,
  ),
  reset: createAction(DashboardDepartmentBestSectionAction.RESET),
};
