import {
  EvaluationReportExportAction,
  EvaluationReportExportSuccessPayload,
  EvaluationReportExportFailedPayload,
  EvaluationReportExportFetchPayload,
} from './evaluationReportExport.type';
import {EvaluationReportState} from '../evaluationReport.reducer';
import {GenericAction} from '../../../../config/constant';
import {evaluationReportExportInitialState} from './evaluationReportExport.reducer';

export const evaluationReportExportHandler = {
  [EvaluationReportExportAction.FETCH]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportExportFetchPayload>,
  ): EvaluationReportState => {
    return {
      ...state,
      evaluationReportExportLoading: true,
      evaluationReportExportParams: action.payload,
      action: EvaluationReportExportAction.FETCH,
    };
  },
  [EvaluationReportExportAction.SUCCESS]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportExportSuccessPayload>,
  ): EvaluationReportState => ({
    ...state,
    evaluationReportExportLoading: false,
    evaluationReportExportResponse: action.payload,
    action: EvaluationReportExportAction.SUCCESS,
  }),
  [EvaluationReportExportAction.FAILED]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportExportFailedPayload>,
  ): EvaluationReportState => ({
    ...state,
    evaluationReportExportLoading: false,
    evaluationReportExportError: action.payload,
    action: EvaluationReportExportAction.FAILED,
  }),
  [EvaluationReportExportAction.RESET]: (
    state: EvaluationReportState,
  ): EvaluationReportState => ({
    ...state,
    ...evaluationReportExportInitialState,
    action: EvaluationReportExportAction.RESET,
  }),
};
