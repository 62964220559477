import {createAction} from '@reduxjs/toolkit';
import {
  DashboardOverviewBestSectionAction,
  DashboardOverviewBestSectionFailedPayload,
  DashboardOverviewBestSectionFetchPayload,
  DashboardOverviewBestSectionSuccessPayload,
} from './dashboardOverviewBestSection.type';

export const dashboardOverviewBestSectionAction = {
  fetch: createAction<DashboardOverviewBestSectionFetchPayload>(
    DashboardOverviewBestSectionAction.FETCH,
  ),
  success: createAction<DashboardOverviewBestSectionSuccessPayload>(
    DashboardOverviewBestSectionAction.SUCCESS,
  ),
  failed: createAction<DashboardOverviewBestSectionFailedPayload>(
    DashboardOverviewBestSectionAction.FAILED,
  ),
  reset: createAction(DashboardOverviewBestSectionAction.RESET),
};
