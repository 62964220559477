import {CommonError} from '../../../../../../config/constant';
import {MasterDataCategory} from '../../../../entity/masterData.string.entity';

export const MasterDataCategoryOptionListAction = {
  FETCH: 'MASTER_DATA_CATEGORY_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_CATEGORY_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_CATEGORY_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_CATEGORY_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_CATEGORY_OPTION_LIST_UPDATE',
};

export interface MasterDataCategoryOptionListInitialState {
  masterDataCategoryOptionListLoading: boolean;
  masterDataCategoryOptionListData: MasterDataCategory[];
  masterDataCategoryOptionListError?: MasterDataCategoryOptionListFailedPayload;
  masterDataCategoryOptionListParams?: MasterDataCategoryOptionListFetchPayload;
  masterDataCategoryOptionListResponse?: MasterDataCategoryOptionListSuccessPayload;
}

export type MasterDataCategoryOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataCategory[];
};

export type MasterDataCategoryOptionListFailedPayload = CommonError;

export type MasterDataCategoryOptionListSuccessPayload = MasterDataCategoryOptionListResponse;

export type MasterDataCategoryOptionListUpdatePayload = MasterDataCategoryOptionListResponse;

export interface MasterDataCategoryOptionListFetchPayload {
  assignmentType: string;
}
