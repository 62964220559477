//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../../../lang';
// Components
import {MButton} from '../../../../../../components';
// Assets
import './UserAccountPreview.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  imageData?: File;
  imagePreview: string;
  isLoading?: boolean;
  handleSubmit(): void;
}
//#endregion

const UserAccountPreview: React.FC<Props> = ({
  imageData,
  imagePreview,
  isLoading,
  handleSubmit,
}: Props) => {
  return (
    <div className="user-account-preview">
      <div className="user-account-preview__section">
        <img src={imagePreview} alt={imageData?.name ?? ''} loading="lazy" />
      </div>
      <div className="user-account-preview__form--action">
        <div className="user-account-preview-action">
          <MButton
            buttonLabel={t('Save Photo')}
            handleClick={handleSubmit}
            isDisabled={!imagePreview || isLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default UserAccountPreview;
