import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBusOptionListAction,
  MasterDataBusOptionListFailedPayload,
  MasterDataBusOptionListFetchPayload,
  MasterDataBusOptionListSuccessPayload,
  MasterDataBusOptionListUpdatePayload,
} from './masterDataBusOptionList.type';

export const masterDataBusOptionListAction = {
  fetch: createAction<MasterDataBusOptionListFetchPayload>(
    MasterDataBusOptionListAction.FETCH,
  ),
  success: createAction<MasterDataBusOptionListSuccessPayload>(
    MasterDataBusOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataBusOptionListFailedPayload>(
    MasterDataBusOptionListAction.FAILED,
  ),
  update: createAction<MasterDataBusOptionListUpdatePayload>(
    MasterDataBusOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataBusOptionListAction.RESET),
};
