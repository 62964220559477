import {createAction} from '@reduxjs/toolkit';
import {
  UserAccessDeleteAction,
  UserAccessDeleteFailedPayload,
  UserAccessDeleteFetchPayload,
  UserAccessDeleteSuccessPayload,
} from './userAccessDelete.type';

export const userAccessDeleteAction = {
  fetch: createAction<UserAccessDeleteFetchPayload>(
    UserAccessDeleteAction.FETCH,
  ),
  success: createAction<UserAccessDeleteSuccessPayload>(
    UserAccessDeleteAction.SUCCESS,
  ),
  failed: createAction<UserAccessDeleteFailedPayload>(
    UserAccessDeleteAction.FAILED,
  ),
  reset: createAction(UserAccessDeleteAction.RESET),
};
