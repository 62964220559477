//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataBasicServiceTypeEditAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataBasicTypeFormEditForm} from './components';
// Data
import {MasterDataBasicServiceTypeFormData} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataBasicServiceTypeFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  data: MasterDataBasicServiceTypeFormData;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataBasicServiceTypeFormEditContainer: React.FC<Props> = ({
  assignmentType,
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataBasicServiceTypeFormData = {
    id: data.id || '',
    name: data.name || '',
    assignmentType: data?.assignmentType || assignmentType,
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitBasicServiceType = (
    formValue: MasterDataBasicServiceTypeFormData,
  ) => {
    const basicServiceTypesTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: basicServiceTypesTemp || '',
    };
    dispatch(masterDataBasicServiceTypeEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-basic-service-type-form-edit-container">
      <MasterDataBasicTypeFormEditForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitBasicServiceType={handleSubmitBasicServiceType}
      />
    </div>
  );
  //#endregion
};

export default MasterDataBasicServiceTypeFormEditContainer;
