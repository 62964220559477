/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../usecases/user.reducer';
import {userRoleAccessListAction} from '../../usecases/userRoleAccessList/userRoleAccessList.action';
import {UserRoleAccessListAction} from '../../usecases/user.type';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {MButton, MSearchInput} from '../../../../components';
import {UserAccessManagementListRoleSection} from './components';
// Data
import {PATH} from '../../../wrapper/entity';
import {RoleOverview} from '../../entity/user.string.entity';
// Assets
import './UserAccessManagementRoleSection.scss';
//#endregion

const UserAccessManagementRoleSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const user: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  //#endregion

  //#region HANDLE ADD ROLE
  const handleAddRole = () => {
    history.push(PATH.USER_ACCESS_CREATE_ROLE);
  };
  //#endregion

  //#region HANDLE OPEN DETAIL ROLE
  const handleOpenDetailRole = (_role: RoleOverview) => {
    history.push(PATH.USER_ACCESS_DETAIL_ROLE, {
      role: _role,
    });
  };
  //#endregion

  //#region FETCH ROLE
  const fetchRoleList = useCallback(
    (search?: string) => {
      dispatch(userRoleAccessListAction.fetch({search}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(userRoleAccessListAction.reset());
    fetchRoleList(searchValue);

    return () => {
      dispatch(userRoleAccessListAction.reset());
    };
  }, [dispatch, fetchRoleList, searchValue]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const roleActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder.addCase(UserRoleAccessListAction.FAILED, (): void => {
          dispatch(
            wrapperNotifyAction.fetch({
              text: t('Data fetch failed!'),
              type: 'error',
              action: WrapperNotifyAction.FETCH,
            }),
          );
          dispatch(userRoleAccessListAction.reset());
        });
      });
    },
    [dispatch],
  );
  useEffect(() => {
    roleActionHandler(user.action);
  }, [user.action, roleActionHandler]);
  //#endregion

  return (
    <div className="user-access-management-role-section">
      <>
        <div className="user-access-management-role-section__header">
          <MSearchInput
            inputClassName="user-access-management-role-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Role')}
            setInputValue={setSearchValue}
          />

          <div className="user-access-management-role-section__header--add-action">
            <MButton buttonLabel={t('Add')} handleClick={handleAddRole} />
          </div>
        </div>

        <div className="user-access-management-role-section__content">
          <UserAccessManagementListRoleSection
            onClickItem={(_role: RoleOverview) => {
              handleOpenDetailRole(_role);
            }}
            data={user.userRoleAccessListData}
          />
        </div>
      </>
    </div>
  );
};

export default UserAccessManagementRoleSection;
