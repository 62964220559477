import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDirectorateEditAction,
  MasterDataDirectorateEditFailedPayload,
  MasterDataDirectorateEditFetchPayload,
  MasterDataDirectorateEditSuccessPayload,
} from './masterDataDirectorateEdit.type';

export const masterDataDirectorateEditAction = {
  fetch: createAction<MasterDataDirectorateEditFetchPayload>(
    MasterDataDirectorateEditAction.FETCH,
  ),
  success: createAction<MasterDataDirectorateEditSuccessPayload>(
    MasterDataDirectorateEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataDirectorateEditFailedPayload>(
    MasterDataDirectorateEditAction.FAILED,
  ),
  reset: createAction(MasterDataDirectorateEditAction.RESET),
};
