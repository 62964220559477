import {
  MasterDataBasicServiceTypeListAction,
  MasterDataBasicServiceTypeListSuccessPayload,
  MasterDataBasicServiceTypeListFailedPayload,
  MasterDataBasicServiceTypeListFetchPayload,
} from './masterDataBasicServiceTypeList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataBasicServiceTypeListInitialState} from './masterDataBasicServiceTypeList.reducer';

export const masterDataBasicServiceTypeListHandler = {
  [MasterDataBasicServiceTypeListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataBasicServiceTypeListLoading: true,
      masterDataBasicServiceTypeListParams: action.payload,
      action: MasterDataBasicServiceTypeListAction.FETCH,
    };
  },
  [MasterDataBasicServiceTypeListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeListLoading: false,
    masterDataBasicServiceTypeListResponse: action.payload,
    action: MasterDataBasicServiceTypeListAction.SUCCESS,
  }),
  [MasterDataBasicServiceTypeListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeListLoading: false,
    masterDataBasicServiceTypeListData: [
      ...state.masterDataBasicServiceTypeListData,
      ...action.payload.data.content,
    ],
    action: MasterDataBasicServiceTypeListAction.UPDATE,
  }),
  [MasterDataBasicServiceTypeListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeListLoading: false,
    masterDataBasicServiceTypeListError: action.payload,
    action: MasterDataBasicServiceTypeListAction.FAILED,
  }),
  [MasterDataBasicServiceTypeListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBasicServiceTypeListInitialState,
    action: MasterDataBasicServiceTypeListAction.RESET,
  }),
};
