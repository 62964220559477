//#region IMPORT
// Libraries
import React from 'react';
import moment from 'moment';
import {Grid} from '@mui/material';
// Components
import EvaluationReportListItem from '../EvaluationReportListItem';
// Data
import {
  EvaluationReport,
  EvaluationReportManagement,
} from '../../../../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportList.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: EvaluationReportManagement[];
  handleClick(_evaluationReport: EvaluationReport): void;
}
//#endregion

const EvaluationReportList: React.FC<Props> = ({data, handleClick}: Props) => {
  return (
    <div className="evaluation-report-list">
      {data?.map((item: EvaluationReportManagement, index: number) => (
        <div key={index} className="evaluation-report-list__container">
          <div className="evaluation-report-list__container-date">
            {moment(item.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
          </div>
          <div className="evaluation-report-list__container-content">
            <Grid container spacing={3}>
              {item?.reviews?.map(
                (
                  evaluationReportDetail: EvaluationReport,
                  evaluationReportIndex: number,
                ) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      className="evaluation-report-list__container-content--item"
                      key={`${evaluationReportDetail.id}-${evaluationReportIndex}`}>
                      <EvaluationReportListItem
                        data={evaluationReportDetail}
                        handleClick={handleClick}
                      />
                    </Grid>
                  );
                },
              )}
            </Grid>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EvaluationReportList;
