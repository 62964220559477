/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../usecases/user.reducer';
import {
  UserRoleAccessCreateAction,
  UserRoleAccessEditAction,
} from '../../usecases/user.type';
import {userRoleAccessCreateAction} from '../../usecases/userRoleAccessCreate/userRoleAccessCreate.action';
import {userRoleAccessEditAction} from '../../usecases/userRoleAccessEdit/userRoleAccessEdit.action';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {RoleForm} from './components';
// Data
import {PATH} from '../../../wrapper/entity';
import {
  PermissionType,
  RoleFormData,
  RoleOverview,
} from '../../entity/user.string.entity';
// Assets
import './UserAccessManagementRoleFormContainer.scss';
import {AlertDialog} from '../../../../components';
//#endregion

//#region INTERFACE
interface NavigationParams {
  role: RoleOverview;
}
//#endregion

const UserAccessManagementRoleFormContainer: React.FC = () => {
  //#region GENERAL
  const history = useHistory();
  const dispatch = useDispatch();
  const user: UserState = useSelector((state: AppState) => state.user);
  const routeState = history.location.state as NavigationParams;
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const isLoadingAddRole = user.userRoleAccessCreateLoading;
  const isLoadingEditRole = user.userRoleAccessEditLoading;
  //#endregion

  //#region ACTION HANDLER
  const userActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserRoleAccessCreateAction.SUCCESS, (): void => {
            history.replace(PATH.USER_ACCESS);
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserRoleAccessCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userRoleAccessCreateAction.reset());
          })
          .addCase(UserRoleAccessEditAction.SUCCESS, (): void => {
            history.replace(PATH.USER_ACCESS);
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserRoleAccessEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userRoleAccessEditAction.reset());
          });
      });
    },
    [dispatch, history],
  );
  useEffect(() => {
    userActionHandler(user.action);
  }, [user.action, userActionHandler]);
  //#endregion

  //#region CANCEL
  const onCancel = () => {
    history.goBack();
  };
  //#endregion

  //#region INITIAL VALUES
  const initialValues: RoleFormData = {
    id: routeState?.role?.id,
    name: routeState?.role?.name || '',
    userLevel: routeState?.role?.userLevel || '',
    permissionType: routeState?.role?.permissionType || defaultPermission,
  };
  //#endregion

  return (
    <div className="user-access-management-role-form-container">
      <RoleForm
        initialValues={initialValues}
        isLoading={initialValues?.id ? isLoadingEditRole : isLoadingAddRole}
        handleSubmitRole={(_role: RoleFormData) => {
          if (_role?.id) {
            dispatch(userRoleAccessEditAction.fetch(_role));
          } else {
            dispatch(userRoleAccessCreateAction.fetch(_role));
          }
        }}
        handleCancel={() => setIsCancelForm('Create')}
      />

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            setIsCancelForm(undefined);
            onCancel();
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </div>
  );
};

export default UserAccessManagementRoleFormContainer;

//#region TYPE
const defaultPermission: PermissionType = {
  backOfficePermissions: {
    dashboardOverviewAchievementFinalChart: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    dashboardOverviewAchievementQuarterlyChart: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    dashboardOverviewAnalyticsQualitative: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    dashboardOverviewBestBusStopChart: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    dashboardOverviewBestOperatorChart: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    dashboardOverviewDirectorateNotes: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    dashboardOverviewProfitAndFineChart: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpTabApprove: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpTabExpired: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpTabInProgress: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpTabRejected: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpTabWaitingForVerification: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    hasDashboardInternalFindingChart: false,
    hasDashboardInternalGapChart: false,
    hasDashboardInternalRatedServiceChart: false,
    hasDashboardInternalServiceAchievementFinalChart: false,
    hasDashboardInternalWeightChart: false,
    hasDashboardPICAchivementChart: false,
    hasDashboardPICBestDepartmentChart: false,
    hasDashboardPICDifferenceChart: false,
    hasDashboardPICFindingChart: false,
    hasDashboardPICProfitAndFineChart: false,
    masterBusData: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    masterEvaluationForm: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    masterRouteData: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    masterWorkAssignment: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    masterWorkAssignmentInstruction: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    masterOrganization: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    notificationAllFinding: false,
    notificationConfirmReminder: false,
    notificationExpiredFinding: false,
    notificationExpiredNotesFromChief: false,
    notificationExpiredNotesFromDirectorate: false,
    notificationFindingPerDepartment: false,
    notificationRejectedFollowUp: false,
    otherAssignmentForm: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
      havePermissionDownload: false,
    },
    otherAssignmentReport: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
      havePermissionDownload: false,
    },
    otherWorkReport: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
      havePermissionDownload: false,
    },
  },
  mobilePermissions: {
    evaluationDraft: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    evaluationForm: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    evaluationHistory: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    evaluationIdentity: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    evaluationList: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpApproved: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpExpired: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpInProgress: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpRejected: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    followUpWaitingForVerification: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    notificationDailyAssignment: false,
    notificationFollowUpEvaluationReport: false,
    notificationNewAssignmentEvaluationReport: false,
    notificationRejectedAssignmentEvaluationReport: false,
    scheduleDraft: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    scheduleIdentity: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    scheduleList: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
    scheduleHistory: {
      havePermissionCreate: false,
      havePermissionRead: false,
      havePermissionDelete: false,
      havePermissionUpdate: false,
    },
  },
};
//#endregion
