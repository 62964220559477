export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailTJRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@transjakarta.co.id\s*$/;

export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&,.\-_]{6,}$/;

export const numberRegex = /\d+/;

export const phoneNumberRegex = /^8[0-9]*$/;

export enum ERROR_CODES {
  BODY_INVALID_DATA = 901,
  BODY_MISSING_DATA = 902,
  USER_WRONG_PASSWORD = 903,
  FORBIDDEN_ACCESS = 931,
  LOGIN_EMAIL_FAILED = 404,
  LOGIN_EMAIL_UNAUTHORIZED = 400,
  RESULT_NOT_FOUND = 941,
  RESULT_DUPLICATE_DATA = 942,
  EXPIRED_AUTH_TOKEN = 961,
  EXPIRED_VERIFICATION_ACCESS = 962,
  UNKNOWN_ERROR = 999,
}

export interface CommonData<T> {
  content: T;
  last: boolean;
  maxPage: number;
  offset: number;
  page: number;
  size: number;
  totalElements: number;
  unReadCount?: number;
}
export interface CommonError {
  message?: string;
  status?: number | string;
  response?: {
    data?: {
      error?: {
        code: ERROR_CODES;
      };
    };
    status: number;
  };
  info?: {
    field?: string;
    message?: string;
    id?: string;
  };
}

export type SortTable = 'asc' | 'desc';

export type GenericAction<T> = {
  type: 'error' | 'success';
  payload: T;
};

export interface CommonSuccess {
  success: boolean;
  message?: string;
}
