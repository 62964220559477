import {GenericAction} from '../../../../config/constant';
import {FollowUpState} from '../followUp.reducer';
import {followUpPICConfirmInitialState} from './followUpPICConfirm.reducer';
import {
  FollowUpPICConfirmAction,
  FollowUpPICConfirmFailedPayload,
  FollowUpPICConfirmFetchPayload,
  FollowUpPICConfirmSuccessPayload,
} from './followUpPICConfirm.type';

export const followUpPICConfirmHandler = {
  [FollowUpPICConfirmAction.FETCH]: (
    state: FollowUpState,
    action: GenericAction<FollowUpPICConfirmFetchPayload>,
  ): FollowUpState => ({
    ...state,
    followUpPICConfirmLoading: true,
    followUpPICConfirmParams: action.payload,
    action: FollowUpPICConfirmAction.FETCH,
  }),
  [FollowUpPICConfirmAction.SUCCESS]: (
    state: FollowUpState,
    action: GenericAction<FollowUpPICConfirmSuccessPayload>,
  ): FollowUpState => ({
    ...state,
    followUpPICConfirmParams: undefined,
    followUpPICConfirmLoading: false,
    followUpPICConfirmResponse: action.payload,
    action: FollowUpPICConfirmAction.SUCCESS,
  }),
  [FollowUpPICConfirmAction.FAILED]: (
    state: FollowUpState,
    action: GenericAction<FollowUpPICConfirmFailedPayload>,
  ): FollowUpState => ({
    ...state,
    followUpPICConfirmLoading: false,
    followUpPICConfirmError: action.payload,
    action: FollowUpPICConfirmAction.FAILED,
  }),
  [FollowUpPICConfirmAction.RESET]: (state: FollowUpState): FollowUpState => ({
    ...state,
    ...followUpPICConfirmInitialState,
    action: FollowUpPICConfirmAction.RESET,
  }),
};
