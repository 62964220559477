import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataAssignmentLetterDeleteAction,
  MasterDataAssignmentLetterDeleteFailedPayload,
  MasterDataAssignmentLetterDeleteFetchPayload,
  MasterDataAssignmentLetterDeleteSuccessPayload,
} from './masterDataAssignmentLetterDelete.type';

export const masterDataAssignmentLetterDeleteAction = {
  fetch: createAction<MasterDataAssignmentLetterDeleteFetchPayload>(
    MasterDataAssignmentLetterDeleteAction.FETCH,
  ),
  success: createAction<MasterDataAssignmentLetterDeleteSuccessPayload>(
    MasterDataAssignmentLetterDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataAssignmentLetterDeleteFailedPayload>(
    MasterDataAssignmentLetterDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataAssignmentLetterDeleteAction.RESET),
};
