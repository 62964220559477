import {CommonError} from '../../../../../../config/constant';

export const MasterDataObservationValueDeleteAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_VALUE_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_VALUE_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_VALUE_DELETE_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_VALUE_DELETE_RESET',
};

export interface MasterDataObservationValueDeleteInitialState {
  masterDataObservationValueDeleteLoading: boolean;
  masterDataObservationValueDeleteParams?: MasterDataObservationValueDeleteFetchPayload;
  masterDataObservationValueDeleteError?: MasterDataObservationValueDeleteFailedPayload;
  masterDataObservationValueDeleteResponse?: MasterDataObservationValueDeleteSuccessPayload;
}

export interface MasterDataObservationValueDeleteFetchPayload {
  id: string;
}
export type MasterDataObservationValueDeleteFailedPayload = CommonError;

export interface MasterDataObservationValueDeleteSuccessPayload {}
