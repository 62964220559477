/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../config/constant';
import {getComparator, stableSort} from '../../../../utils';
// Components
import {
  WorkReportManagementListTableHeader,
  WorkReportManagementListTableItem,
} from '..';
// Data
import {
  WorkReport,
  WorkReportOrderByType,
} from '../../entity/workReport.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  workReportList: WorkReport[];
  isAdmin?: boolean;
  sortBy: SortTable;
  orderBy: WorkReportOrderByType;
  onViewWorkReport?(_workReport: WorkReport): void;
  onEditWorkReport?(_workReport: WorkReport): void;
  onDeleteWorkReport?(_workReport: WorkReport): void;
  onDownloadWorkReport?(isMain: boolean, _workReport: WorkReport): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: WorkReportOrderByType,
  ): void;
}
//#endregion

const WorkReportManagementList: React.FC<Props> = ({
  workReportList,
  isAdmin,
  sortBy,
  orderBy,
  onViewWorkReport,
  onEditWorkReport,
  onDeleteWorkReport,
  onDownloadWorkReport,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: 'calc(70vh)'}}
      className="work-report-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="work-report-management-list__container-table">
        <WorkReportManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            workReportList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <WorkReportManagementListTableItem
              {...(onViewWorkReport && {
                onViewItem: () => onViewWorkReport(item),
              })}
              {...(onDeleteWorkReport && {
                onDeleteItem: () => onDeleteWorkReport(item),
              })}
              {...(onEditWorkReport && {
                onEditItem: () => onEditWorkReport(item),
              })}
              {...(onDownloadWorkReport && {
                onDownloadItem: (isMain) => onDownloadWorkReport(isMain, item),
              })}
              key={`work-report-list-item-data-${item.id}-${index}`}
              data={item}
              isAdmin={isAdmin}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WorkReportManagementList;
