import {CommonError} from '../../../../../../config/constant';

export const MasterDataDetailObservationDeleteAction = {
  FETCH: 'MASTER_DATA_DETAIL_OBSERVATION_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_OBSERVATION_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_OBSERVATION_DELETE_FAILED',
  RESET: 'MASTER_DATA_DETAIL_OBSERVATION_DELETE_RESET',
};

export interface MasterDataDetailObservationDeleteInitialState {
  masterDataDetailObservationDeleteLoading: boolean;
  masterDataDetailObservationDeleteParams?: MasterDataDetailObservationDeleteFetchPayload;
  masterDataDetailObservationDeleteError?: MasterDataDetailObservationDeleteFailedPayload;
  masterDataDetailObservationDeleteResponse?: MasterDataDetailObservationDeleteSuccessPayload;
}

export interface MasterDataDetailObservationDeleteFetchPayload {
  id: string;
}
export type MasterDataDetailObservationDeleteFailedPayload = CommonError;

export interface MasterDataDetailObservationDeleteSuccessPayload {}
