//#region IMPORT
// Libraries
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import React, {useCallback, useEffect} from 'react';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {numberRegex, passwordRegex} from '../../../../../../config/constant';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {UserState} from '../../../../usecases/user.reducer';
import {UserChangePasswordAction} from '../../../../usecases/user.type';
import {userChangePasswordAction} from '../../../../usecases/userChangePassword/userChangePassword.action';
// Components
import {MButton, MTextField} from '../../../../../../components';
import UserChangePasswordValidatorItem from '../UserChangePasswordValidatorItem';
// Data
import {PATH} from '../../../../../wrapper/entity';
// Assets
import './UserChangePasswordForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  onCancel?(): void;
}
//#endregion

const UserChangePasswordForm: React.FC<Props> = ({onCancel}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const history = useHistory();
  const user: UserState = useSelector((state: AppState) => state.user);
  const {isLogin, isFirstLogin} = user;
  const isLoadingPasswordChange = user.userChangePasswordLoading;
  //#endregion

  //#region ACTION HANDLER
  const userActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserChangePasswordAction.SUCCESS, (): void => {
            if (user.userChangePasswordResponse) {
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Password changed success!'),
                  type: 'success',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
              dispatch(userChangePasswordAction.reset());
              if (onCancel) {
                onCancel();
              } else {
                if (isLogin && isFirstLogin) {
                  history.replace(PATH.HOME);
                } else {
                  history.replace(PATH.LOGIN);
                }
              }
            }
          })
          .addCase(UserChangePasswordAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Password changed failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userChangePasswordAction.reset());
          });
      });
    },
    [
      user.userChangePasswordResponse,
      dispatch,
      onCancel,
      isLogin,
      isFirstLogin,
      history,
    ],
  );

  useEffect(() => {
    userActionHandler(user.action);
  }, [user.action, userActionHandler]);

  //#region ONCHANGE PASSWORD
  const onChangePassword = (values: {
    password: string;
    confirmPassword: string;
  }) => {
    dispatch(
      userChangePasswordAction.fetch({
        password: values.password,
        confPassword: values.confirmPassword,
      }),
    );
  };
  //#endregion

  //#region VALIDATION
  const changePasswordValidation = Yup.object().shape({
    password: Yup.string().required('').matches(passwordRegex, ' ').min(6, ''),
    confirmPassword: Yup.string()
      .required('')
      .oneOf([Yup.ref('password'), null], ''),
  });
  //#endregion

  //#region RENDER
  return (
    <Formik
      validationSchema={changePasswordValidation}
      initialValues={{password: '', confirmPassword: ''}}
      onSubmit={onChangePassword}>
      {({handleSubmit, values, isValid}): React.ReactElement => (
        <>
          <div className="user-change-password-container__form--field">
            <div className="input-label">
              {onCancel ? t('New Password') : t('Password')}
            </div>
            <Field
              type="password"
              name="password"
              placeholder={
                onCancel ? t('Enter New Password') : t('Enter Password')
              }
              component={MTextField}
              disabled={isLoadingPasswordChange}
            />
          </div>
          <div className="user-change-password-container__form--field">
            <div className="input-label">
              {onCancel ? t('Confirm New Password') : t('Confirm Password')}
            </div>
            <Field
              type="password"
              name="confirmPassword"
              placeholder={
                onCancel
                  ? t('Enter Confirm New Password')
                  : t('Enter Confirm Password')
              }
              component={MTextField}
              disabled={isLoadingPasswordChange}
            />
          </div>

          <div className="user-change-password-container__form--validation">
            <UserChangePasswordValidatorItem
              label={`6 ${t('characters')}`}
              isValid={values.password.length >= 6}
            />
            <UserChangePasswordValidatorItem
              label={`1 ${t('number')} (0-9)`}
              isValid={numberRegex.test(values.password)}
            />
          </div>

          <div
            className={`user-change-password-container__form${
              onCancel ? '--actionsModal' : '--actions'
            }`}>
            {onCancel ? (
              <div className="user-change-password-form-action__canceled">
                <MButton
                  buttonLabel={t('Cancel')}
                  handleClick={onCancel}
                  isDisabled={isLoadingPasswordChange}
                />
              </div>
            ) : null}
            <div className="user-change-password-form-action">
              <MButton
                buttonLabel={onCancel ? t('Save') : t('Change Password')}
                handleClick={handleSubmit}
                isLoading={isLoadingPasswordChange}
                isDisabled={!isValid || isLoadingPasswordChange}
              />
            </div>
          </div>
        </>
      )}
    </Formik>
  );
  //#endregion
};

export default UserChangePasswordForm;
