//#region IMPORT
// Libraries
import React from 'react';
import Icon from '@mdi/react';
import {mdiCheckCircle} from '@mdi/js';
import classnames from 'classnames';
// Assets
import './UserChangePasswordValidatorItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isValid?: boolean;
  label: string;
}
//#endregion

const UserChangePasswordValidatorItem: React.FC<Props> = ({
  isValid,
  label,
}: Props) => {
  //#region GENERAL
  const classes = classnames({
    'user-change-password-validator-item-disabled': !isValid,
    'user-change-password-validator-item': isValid,
  });
  //#endregion

  return (
    <div className={classes}>
      <div className={`${classes}__icon`}>
        <Icon path={mdiCheckCircle} />
      </div>
      <div className={`${classes}__label`}>{label}</div>
    </div>
  );
};

export default UserChangePasswordValidatorItem;
