import React from 'react';
import {BarDatum, BarSvgProps, ResponsiveBar} from '@nivo/bar';
import './MBarChart.scss';

//#region INTERFACE
interface Props extends Omit<BarSvgProps<BarDatum>, 'height' | 'width'> {}
//#endregion

const MBarChart: React.FC<Props> = ({
  colors = {datum: 'data.color'},
  enableLabel = false,
  margin = {top: 40, right: 30, bottom: 60, left: 30},
  padding = 0.5,
  ...props
}: Props) => {
  return (
    <div className="m-bar-chart">
      <ResponsiveBar
        colors={colors}
        enableLabel={enableLabel}
        margin={margin}
        padding={padding}
        {...props}
      />
    </div>
  );
};

export default MBarChart;
