import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataRuteListAction} from './masterDataRuteList.action';
import {
  MasterDataRuteListAction,
  MasterDataRuteListFetchPayload,
  MasterDataRuteListResponse,
  MasterDataRuteListSuccessPayload,
} from './masterDataRuteList.type';

function* workerFetch(action: GenericAction<MasterDataRuteListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataRuteListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/route/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataRuteListAction.success(
          res.data as MasterDataRuteListResponse,
        ),
      );
      yield put(
        masterDataRuteListAction.update(res.data as MasterDataRuteListResponse),
      );
    } else {
      yield put(masterDataRuteListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(masterDataRuteListAction.failed(e as CommonError));
  }
}

const masterDataRuteListWatcher = [
  takeLatest(MasterDataRuteListAction.FETCH, workerFetch),
];

export default masterDataRuteListWatcher;
