/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../../../utils';
// Components
import MasterDataDivisionManagementListTableHeader from '../MasterDataDivisionManagementListTableHeader';
import MasterDataDivisionManagementListTableItem from '../MasterDataDivisionManagementListTableItem';
// Data
import {
  MasterDataDivision,
  MasterDataDivisionOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  divisionList: MasterDataDivision[];
  sortBy: SortTable;
  orderBy: MasterDataDivisionOrderByType;
  onEditDivision?(_division: MasterDataDivision): void;
  onDeleteDivision?(_division: MasterDataDivision): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataDivisionOrderByType,
  ): void;
}
//#endregion

const MasterDataDivisionManagementList: React.FC<Props> = ({
  divisionList,
  sortBy,
  orderBy,
  onEditDivision,
  onDeleteDivision,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataDivisionManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            divisionList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <MasterDataDivisionManagementListTableItem
              {...(onEditDivision && {
                onEditItem: () => onEditDivision(item),
              })}
              {...(onDeleteDivision && {
                onDeleteItem: () => onDeleteDivision(item),
              })}
              key={`master-data-basic-service-type-list-item-data-${item.id}-${index}`}
              data={item}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataDivisionManagementList;
