//#region IMPORT
// Libraries
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Avatar, Table, TableBody, TableCell, TableRow} from '@mui/material';
// Utils
import t from '../../../../lang';
import {toInitial} from '../../../../utils';
import {actionHandler} from '../../../wrapper/utils';
import {userAccountDetailAction} from '../../usecases/userAccountDetail/userAccountDetail.action';
import {userAccountEditAction} from '../../usecases/userAccountEdit/userAccountEdit.action';
import {UserAccountEditAction} from '../../usecases/user.type';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {UserAccountPreview} from './components';
import {MImageUpload, Modal} from '../../../../components';
// Data
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../usecases/user.reducer';
import {UserAccountFormData} from '../../entity/user.string.entity';
// Assets
import './UserAccount.scss';
//#endregion

const UserAccount: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [isEditPhoto, setIsEditPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File | undefined>(
    undefined,
  );
  const [previewPhoto, setPreviewPhoto] = useState('');
  const photoRef = useRef<HTMLInputElement>(null);

  const user: UserState = useSelector((state: AppState) => state.user);
  const userId: string = user.data?.id ?? '';
  const initialAvatar: string = toInitial(user.data?.name);
  const isLoadingPhotoUpload = user.userAccountEditLoading;
  //#endregion

  //#region FETCH USER DETAIL
  const fetchUserDetail = useCallback(
    (userId: string) => {
      dispatch(userAccountDetailAction.fetch({userId: userId}));
    },
    [dispatch],
  );
  //#endregion

  //#region EDIT PHOTO
  const handleEditPhoto = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event?.target?.files?.length) {
      const file = event.target.files[0];
      setPreviewPhoto(URL.createObjectURL(file));
      setSelectedPhoto(file);
      setIsEditPhoto(true);
    }
  };
  //#endregion

  //#region UPLOAD PHOTO
  const handleUploadPhoto = () => {
    const body: UserAccountFormData = {
      name: user.data?.name,
      phoneNumber: user.data?.phoneNumber ?? '',
      file: selectedPhoto,
    };
    dispatch(userAccountEditAction.fetch(body));
    if (photoRef.current?.value) photoRef.current.value = '';
  };
  //#endregion

  //#region HANDLE CLOSE
  const handleClose = () => {
    setIsEditPhoto(false);
    setSelectedPhoto(undefined);
    setPreviewPhoto('');
    if (photoRef.current?.value) photoRef.current.value = '';
  };
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(userAccountDetailAction.reset());
    fetchUserDetail(userId);

    return () => {
      dispatch(userAccountDetailAction.reset());
    };
  }, [dispatch, fetchUserDetail, userId]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const userAccountActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserAccountEditAction.SUCCESS, (): void => {
            setIsEditPhoto(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Profile picture changed success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserAccountEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Profile picture changed failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userAccountEditAction.reset());
          });
      });
    },
    [dispatch, refresh],
  );
  useEffect(() => {
    userAccountActionHandler(user.action);
  }, [user.action, userAccountActionHandler]);
  //#endregion

  //#region RENDER
  return (
    <>
      <div className="user-account">
        <div className="user-account__body">
          <div className="user-account__body--picture">
            {user.data?.profilePicture ? (
              <Avatar
                className="user-account__body--picture-avatar"
                alt={user.data?.name}
                src={user.data?.profilePicture}
              />
            ) : (
              <Avatar
                className="user-account__body--picture-avatar"
                alt={user.data?.name}>
                {initialAvatar}
              </Avatar>
            )}
            <MImageUpload
              label={t('Choose Photo')}
              labelClassName="user-account__body--picture-upload"
              inputRef={photoRef}
              handleUpload={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleEditPhoto(event)
              }
            />
          </div>
          <div className="user-account__body--detail">
            <Table size="small" style={{tableLayout: 'fixed'}}>
              <TableBody>
                <TableRow>
                  <TableCell className="detail-title" width="10%">
                    {t('Name')}
                  </TableCell>
                  <TableCell className="detail-desc">
                    {user.data?.name ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="detail-title">{t('NIK')}</TableCell>
                  <TableCell className="detail-desc">
                    {user.data?.nik ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="detail-title">{t('Email')}</TableCell>
                  <TableCell className="detail-desc">
                    {user.data?.email ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="detail-title">
                    {t('Phone Number')}
                  </TableCell>
                  <TableCell className="detail-desc">
                    {user.data?.phoneNumber ?? '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>

      <Modal
        onClose={() => handleClose()}
        title={t('Edit Photo Profile')}
        isOpen={isEditPhoto}>
        <UserAccountPreview
          imagePreview={previewPhoto}
          isLoading={isLoadingPhotoUpload}
          handleSubmit={() => handleUploadPhoto()}
        />
      </Modal>
    </>
  );
  //#endregion
};

export default UserAccount;
