//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../lang';
// Components
import RoleTable from '../RoleTable';
// Data
import {
  MobilePermissionType,
  RolePermission,
  RoleTableData,
} from '../../entity/user.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  isDisabled?: boolean;
  roleValues?: MobilePermissionType;
  setFieldValue?: (
    field: string,
    value: RolePermission | boolean,
    shouldValidate?: boolean | undefined,
  ) => void;
}
//#endregion

const RoleTableMobileApps: React.FC<Props> = ({
  roleValues,
  isDisabled,
  setFieldValue,
}: Props) => {
  return (
    <>
      <RoleTable
        isMobilePermission
        isDisabled={isDisabled}
        title={t('Evaluation')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={evaluationPermissions}
      />
      <RoleTable
        isMobilePermission
        isDisabled={isDisabled}
        title={t('Schedule')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={schedulePermissions}
      />
      <RoleTable
        isMobilePermission
        isDisabled={isDisabled}
        title={t('History')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={historyPermissions}
      />
      <RoleTable
        isMobilePermission
        isDisabled={isDisabled}
        title={t('Follow Up')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={followUpPermissions}
      />
      <RoleTable
        isMobilePermission
        valueType={'single'}
        headerTitle={t('Notification')}
        isDisabled={isDisabled}
        title={t('Notification')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={notificationPermissions}
      />
    </>
  );
};

const evaluationPermissions: RoleTableData[] = [
  {label: t('Evaluation Identity'), permissions: 'evaluationIdentity'},
  {label: t('Evaluation List'), permissions: 'evaluationList'},
  {label: t('Evaluation Form'), permissions: 'evaluationForm'},
  {label: t('Evaluation Draft'), permissions: 'evaluationDraft'},
];

const schedulePermissions: RoleTableData[] = [
  {label: t('Schedule Identity'), permissions: 'scheduleIdentity'},
  {label: t('Schedule List'), permissions: 'scheduleList'},
  {label: t('Schedule Draft'), permissions: 'scheduleDraft'},
];

const historyPermissions: RoleTableData[] = [
  {label: t('Evaluation History'), permissions: 'evaluationHistory'},
  {label: t('Schedule History'), permissions: 'scheduleHistory'},
];

const followUpPermissions: RoleTableData[] = [
  {
    label: t('Tab In Progress'),
    permissions: 'followUpInProgress',
  },
  {
    label: t('Tab Waiting For Verification'),
    permissions: 'followUpWaitingForVerification',
  },
  {
    label: t('Tab Approved'),
    permissions: 'followUpApproved',
  },
  {
    label: t('Tab Rejected'),
    permissions: 'followUpRejected',
  },
  {
    label: t('Tab Expired'),
    permissions: 'followUpExpired',
  },
];

const notificationPermissions: RoleTableData[] = [
  {
    label: t('Notification Daily Assignment'),
    permissions: 'notificationDailyAssignment',
  },
  {
    label: t('Notification New Assignment Evaluation Report'),
    permissions: 'notificationNewAssignmentEvaluationReport',
  },
  {
    label: t('Notification Rejected Assignment Evaluation Report'),
    permissions: 'notificationRejectedAssignmentEvaluationReport',
  },
  {
    label: t('Notification Follow Up Evaluation Report'),
    permissions: 'notificationFollowUpEvaluationReport',
  },
];

export default RoleTableMobileApps;
