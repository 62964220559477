import {CommonError} from '../../../../../../config/constant';
import {
  DashboardOverviewChartSection,
  DASHBOARDQUARTERINTERVAL,
} from '../../../../entity/dashboard.string.entity';

export const DashboardOverviewChartSectionAction = {
  FETCH: 'DASHBOARD_OVERVIEW_CHART_SECTION_FETCH',
  SUCCESS: 'DASHBOARD_OVERVIEW_CHART_SECTION_SUCCESS',
  FAILED: 'DASHBOARD_OVERVIEW_CHART_SECTION_FAILED',
  RESET: 'DASHBOARD_OVERVIEW_CHART_SECTION_RESET',
};

export interface DashboardOverviewChartSectionInitialState {
  dashboardOverviewChartSectionLoading: boolean;
  dashboardOverviewChartSectionError?: DashboardOverviewChartSectionFailedPayload;
  dashboardOverviewChartSectionResponse?: DashboardOverviewChartSectionSuccessPayload;
  dashboardOverviewChartSectionParams?: DashboardOverviewChartSectionFetchPayload;
}

export interface DashboardOverviewChartSectionFetchPayload {
  quarter: DASHBOARDQUARTERINTERVAL;
  year: number;
}

export interface DashboardOverviewChartSectionResponse {
  status: string;
  info?: number;
  data: DashboardOverviewChartSection;
}

export type DashboardOverviewChartSectionFailedPayload = CommonError;

export type DashboardOverviewChartSectionSuccessPayload = DashboardOverviewChartSectionResponse;

export type DashboardOverviewChartSectionUpdatePayload = DashboardOverviewChartSectionResponse;
