import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataDivisionOptionListAction} from './masterDataDivisionOptionList.action';
import {
  MasterDataDivisionOptionListAction,
  MasterDataDivisionOptionListFetchPayload,
  MasterDataDivisionOptionListResponse,
  MasterDataDivisionOptionListSuccessPayload,
} from './masterDataDivisionOptionList.type';

function* workerFetch(
  action: GenericAction<MasterDataDivisionOptionListFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataDivisionOptionListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/division/ol`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataDivisionOptionListAction.success(
          res.data as MasterDataDivisionOptionListResponse,
        ),
      );
      yield put(
        masterDataDivisionOptionListAction.update(
          res.data as MasterDataDivisionOptionListResponse,
        ),
      );
    } else {
      yield put(
        masterDataDivisionOptionListAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(masterDataDivisionOptionListAction.failed(e as CommonError));
  }
}

const masterDataDivisionOptionListWatcher = [
  takeLatest(MasterDataDivisionOptionListAction.FETCH, workerFetch),
];

export default masterDataDivisionOptionListWatcher;
