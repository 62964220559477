//#region IMPORT
// Libraries
import React from 'react';
// Components
import FollowUpWaitingListItem from '../FollowUpWaitingListItem';
// Data
import {FollowUp} from '../../../../../entity/followUp.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  detailData?: FollowUp;
  onViewItem?(detailData: FollowUp): void;
}
//#endregion

const FollowUpWaitingDetail: React.FC<Props> = ({
  detailData,
  onViewItem,
}: Props) => {
  return (
    <div className="follow-up-waiting-detail__container">
      {detailData ? (
        <FollowUpWaitingListItem
          data={detailData}
          isDetail
          onViewItem={() => onViewItem && onViewItem(detailData)}
        />
      ) : null}
    </div>
  );
};

export default FollowUpWaitingDetail;
