import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDivisionDeleteInitialState} from './masterDataDivisionDelete.reducer';
import {
  MasterDataDivisionDeleteAction,
  MasterDataDivisionDeleteFailedPayload,
  MasterDataDivisionDeleteFetchPayload,
  MasterDataDivisionDeleteSuccessPayload,
} from './masterDataDivisionDelete.type';

export const masterDataDivisionDeleteHandler = {
  [MasterDataDivisionDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionDeleteLoading: true,
    masterDataDivisionDeleteParams: action.payload,
    action: MasterDataDivisionDeleteAction.FETCH,
  }),
  [MasterDataDivisionDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionDeleteParams: undefined,
    masterDataDivisionDeleteLoading: false,
    masterDataDivisionDeleteResponse: action.payload,
    action: MasterDataDivisionDeleteAction.SUCCESS,
  }),
  [MasterDataDivisionDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionDeleteLoading: false,
    masterDataDivisionDeleteError: action.payload,
    action: MasterDataDivisionDeleteAction.FAILED,
  }),
  [MasterDataDivisionDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDivisionDeleteInitialState,
    action: MasterDataDivisionDeleteAction.RESET,
  }),
};
