//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router';
// Utils
import t from '../../../../../lang';
import {AppState} from '../../../../../config/Interface';
import {EvaluationReportState} from '../../../usecases/evaluationReport.reducer';
import {evaluationReportDetailAction} from '../../../usecases/evaluationReport.action';
import {EvaluationReportDetailAction} from '../../../usecases/evaluationReport.type';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {Divider, Tab, Tabs} from '@mui/material';
import {
  EvaluationReportDetailHeader,
  EvaluationReportDetailItem,
} from './components';
import {EvaluationReportCardContent} from '../../../components';
import {FollowUpEmpty} from '../../../../followUp/components';
import {Modal, MSpinner} from '../../../../../components';
// Data
import {
  EvaluationReportDetailList,
  EvaluationReportPreviewFindings,
} from '../../../entity/evaluationReport.string.entity';
import {tabContentType} from '../../../../wrapper/entity';
// Assets
import './EvaluationReportDetail.scss';
//#endregion

//#region INTERFACE
interface NavigationParams {
  transReviewId: string;
}
//#endregion

const EvaluationReportDetail: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const location = useLocation();

  const {transReviewId} = location.state as NavigationParams;
  const evaluationReportData: EvaluationReportState = useSelector(
    (state: AppState) => state.evaluationReport,
    shallowEqual,
  );

  const [isViewEvaluationReport, setIsViewEvaluationReport] = useState<
    EvaluationReportPreviewFindings | undefined
  >(undefined);

  const isLoadingEvaluationReportDetail: boolean =
    evaluationReportData.evaluationReportDetailLoading;
  //#endregion

  //#region FETCH EVALUATION REPORT DETAIL
  const fetchEvaluationReportDetail = useCallback(() => {
    dispatch(
      evaluationReportDetailAction.fetch({
        transReviewId: transReviewId,
      }),
    );
  }, [dispatch, transReviewId]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(evaluationReportDetailAction.reset());
    fetchEvaluationReportDetail();

    return () => {
      dispatch(evaluationReportDetailAction.reset());
    };
  }, [dispatch, fetchEvaluationReportDetail]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const evaluationReportActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder.addCase(EvaluationReportDetailAction.FAILED, (): void => {
          dispatch(
            wrapperNotifyAction.fetch({
              text: t('Data fetch failed!'),
              type: 'error',
              action: WrapperNotifyAction.FETCH,
            }),
          );
          dispatch(evaluationReportDetailAction.reset());
        });
      });
    },
    [dispatch],
  );
  useEffect(() => {
    evaluationReportActionHandler(evaluationReportData.action);
  }, [evaluationReportData.action, evaluationReportActionHandler]);
  //#endregion

  //#region HANDLE TAB
  const tabRef = useRef<HTMLDivElement>(null);
  const tabItemRef = useRef<HTMLDivElement>(null);
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    let isChangeTab = true;
    if (isChangeTab) {
      if (tabItemRef && tabItemRef.current) {
        const offset = tabValue === 0 ? 500 : 100;
        tabRef?.current?.scrollTo({
          top: tabItemRef.current.offsetTop - offset,
          behavior: 'smooth',
        });
      }
    }
    return () => {
      isChangeTab = false;
    };
  }, [tabItemRef, tabValue]);

  const evaluationReportTabList: EvaluationReportDetailList[] =
    evaluationReportData.evaluationReportDetailResponse?.data
      ?.listPreviewReviews ?? [];
  const findingTabLabel: tabContentType[] = evaluationReportTabList.map(
    (item: EvaluationReportDetailList) => ({
      label: t(item.categoryName),
      component: (
        <EvaluationReportDetailItem
          data={item}
          handlePreview={(_previewFindings: EvaluationReportPreviewFindings) =>
            setIsViewEvaluationReport(_previewFindings)
          }
        />
      ),
    }),
  );
  //#endregion

  //#region DATA LOAD
  const emptyData = () => {
    return (
      <div className="evaluation-report-detail__tab-content--empty">
        <FollowUpEmpty text={t('No evaluation report detail')} />
      </div>
    );
  };

  const loadingData = () => {
    return (
      <div className="evaluation-report-detail__tab-content--loading">
        <MSpinner size={40} />
      </div>
    );
  };

  const dataLoad = () => {
    const contentData =
      evaluationReportData.evaluationReportDetailResponse?.data;
    if (isLoadingEvaluationReportDetail) {
      return loadingData();
    } else {
      if (!contentData) return emptyData();
      return (
        <div className="evaluation-report-detail">
          {findingTabLabel.length > 0 && (
            <div className="evaluation-report-detail__tab">
              <Tabs
                variant="scrollable"
                orientation="vertical"
                value={tabValue}
                onChange={handleChangeTab}
                className="evaluation-report-detail__tab-header">
                {findingTabLabel.map((item: tabContentType, idx: number) => (
                  <Tab
                    key={`tab-${item.label}-${idx}`}
                    className="evaluation-report-detail__tab-item"
                    id={`tab-${idx}`}
                    aria-controls={`tabpanel-${idx}`}
                    label={item.label}
                  />
                ))}
              </Tabs>
            </div>
          )}
          <div className="evaluation-report-detail__tab-panel">
            <div className="evaluation-report-detail__tab-content" ref={tabRef}>
              <EvaluationReportDetailHeader data={contentData} />
              <Divider orientation="horizontal" />
              {findingTabLabel.length > 0 &&
                findingTabLabel.map((item: tabContentType, idx: number) => (
                  <div
                    key={`evaluation-report-detail-tab-label-${item.label}-${idx}`}
                    className="evaluation-report-detail__tab-content--item"
                    ref={tabValue === idx ? tabItemRef : null}
                    tabIndex={idx}>
                    {item.component}
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }
  };
  //#endregion

  return (
    <>
      {dataLoad()}
      {isViewEvaluationReport && (
        <Modal
          onClose={() => setIsViewEvaluationReport(undefined)}
          isOpen={!!isViewEvaluationReport}
          maxWidth="md">
          <EvaluationReportCardContent
            contentData={isViewEvaluationReport}
            isDetail
          />
        </Modal>
      )}
    </>
  );
};

export default EvaluationReportDetail;
