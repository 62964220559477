import {CommonError} from '../../../../../../config/constant';
import {MasterDataIndicatorValue} from '../../../../entity/masterData.string.entity';

export const MasterDataIndicatorValueOptionListAction = {
  FETCH: 'MASTER_DATA_INDICATOR_VALUE_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_INDICATOR_VALUE_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_INDICATOR_VALUE_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_INDICATOR_VALUE_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_INDICATOR_VALUE_OPTION_LIST_UPDATE',
};

export interface MasterDataIndicatorValueOptionListInitialState {
  masterDataIndicatorValueOptionListLoading: boolean;
  masterDataIndicatorValueOptionListData: Omit<
    MasterDataIndicatorValue,
    'isPositiveValue'
  >[];
  masterDataIndicatorValueOptionListError?: MasterDataIndicatorValueOptionListFailedPayload;
  masterDataIndicatorValueOptionListParams?: MasterDataIndicatorValueOptionListFetchPayload;
  masterDataIndicatorValueOptionListResponse?: MasterDataIndicatorValueOptionListSuccessPayload;
}

export type MasterDataIndicatorValueOptionListResponse = {
  status: string;
  count?: number;
  data: Omit<MasterDataIndicatorValue, 'isPositiveValue'>[];
};

export type MasterDataIndicatorValueOptionListFailedPayload = CommonError;

export type MasterDataIndicatorValueOptionListSuccessPayload = MasterDataIndicatorValueOptionListResponse;

export type MasterDataIndicatorValueOptionListUpdatePayload = MasterDataIndicatorValueOptionListResponse;

export interface MasterDataIndicatorValueOptionListFetchPayload {
  assignmentType: string;
}
