//#region IMPORT
// Libraries
import React from 'react';
// Data
import {MasterDataWorkInstruction} from '../../../../../../entity/masterData.string.entity';
// Assets
import {IconWorkInstruction} from '../../../../../../../../assets/icons';
import './MasterDataWorkInstructionManagementListItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  item: MasterDataWorkInstruction;
  onClickItem(): void;
}
//#endregion

const MasterDataWorkInstructionManagementListItem: React.FC<Props> = ({
  item,
  onClickItem,
}: Props) => {
  return (
    <div
      className="master-data-work-instruction-management-list-item"
      onClick={onClickItem}>
      <div className="master-data-work-instruction-management-list-item__icon">
        <IconWorkInstruction />
      </div>
      <div className="master-data-work-instruction-management-list-item__meta">
        <div className="master-data-work-instruction-management-list-item__meta--name">
          {item.name}
        </div>
      </div>
    </div>
  );
};

export default MasterDataWorkInstructionManagementListItem;
