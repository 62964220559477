import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataAssignmentLetterEditInitialState} from './masterDataAssignmentLetterEdit.reducer';
import {
  MasterDataAssignmentLetterEditAction,
  MasterDataAssignmentLetterEditFailedPayload,
  MasterDataAssignmentLetterEditFetchPayload,
  MasterDataAssignmentLetterEditSuccessPayload,
} from './masterDataAssignmentLetterEdit.type';

export const masterDataAssignmentLetterEditHandler = {
  [MasterDataAssignmentLetterEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterEditLoading: true,
    masterDataAssignmentLetterEditParams: action.payload,
    action: MasterDataAssignmentLetterEditAction.FETCH,
  }),
  [MasterDataAssignmentLetterEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterEditParams: undefined,
    masterDataAssignmentLetterEditLoading: false,
    masterDataAssignmentLetterEditResponse: action.payload,
    action: MasterDataAssignmentLetterEditAction.SUCCESS,
  }),
  [MasterDataAssignmentLetterEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterEditLoading: false,
    masterDataAssignmentLetterEditError: action.payload,
    action: MasterDataAssignmentLetterEditAction.FAILED,
  }),
  [MasterDataAssignmentLetterEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataAssignmentLetterEditInitialState,
    action: MasterDataAssignmentLetterEditAction.RESET,
  }),
};
