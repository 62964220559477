//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../lang';
// Components
import {UserChangePasswordForm} from './components';
// Assets
import './UserChangePassword.scss';
//#endregion

const UserChangePassword: React.FC = () => {
  return (
    <div className="user-change-password">
      <div className="user-change-password-container">
        <div className="user-change-password-container__header">
          {t('Change Password')}
        </div>
        <UserChangePasswordForm />
      </div>
    </div>
  );
};

export default UserChangePassword;
