//#region IMPORT
// Libraries
import React from 'react';
import {Avatar} from '@mui/material';
// Utils
import {toInitial} from '../../../../utils';
// Components
import {MIconButton} from '../../../../components';
// Data
import {DashboardCommentData} from '../../entity/dashboard.string.entity';
// Assets
import './DashboardCommentItem.component.style.scss';
import {mdiDotsHorizontal} from '@mdi/js';
//#endregion

//#region INTERFACE
interface Props {
  data: DashboardCommentData;
}
//#endregion

const DashboardCommentItem: React.FC<Props> = ({data}: Props) => {
  const initialAvatar: string = toInitial(data.userName);
  return (
    <div className="dashboard-comment-item">
      <div className="dashboard-comment-item__icon">
        {data.profilePicture ? (
          <Avatar
            className="dashboard-comment-preview__icon--avatar"
            alt={data.userName}
            src={data.profilePicture}
          />
        ) : (
          <Avatar
            className="dashboard-comment-preview__icon--avatar"
            alt={data.userName}>
            {initialAvatar}
          </Avatar>
        )}
      </div>
      <div className="dashboard-comment-item__content">
        <div className="dashboard-comment-item__content--title">
          <span>{`${data.userName}  `}</span> {data.dateTime}
        </div>
        <div className="dashboard-comment-item__content--desc">
          {data.comment}
        </div>
      </div>
      <div className="dashboard-comment-item__action">
        <MIconButton icon={mdiDotsHorizontal} />
      </div>
    </div>
  );
};
export default DashboardCommentItem;
