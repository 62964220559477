import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataReviewIndicatorCreateAction,
  MasterDataReviewIndicatorCreateFailedPayload,
  MasterDataReviewIndicatorCreateFetchPayload,
  MasterDataReviewIndicatorCreateSuccessPayload,
} from './masterDataReviewIndicatorCreate.type';

export const masterDataReviewIndicatorCreateAction = {
  fetch: createAction<MasterDataReviewIndicatorCreateFetchPayload>(
    MasterDataReviewIndicatorCreateAction.FETCH,
  ),
  success: createAction<MasterDataReviewIndicatorCreateSuccessPayload>(
    MasterDataReviewIndicatorCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataReviewIndicatorCreateFailedPayload>(
    MasterDataReviewIndicatorCreateAction.FAILED,
  ),
  reset: createAction(MasterDataReviewIndicatorCreateAction.RESET),
};
