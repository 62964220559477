/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../../../utils';
// Components
import MasterDataDetailIndicatorManagementListTableHeader from '../MasterDataDetailIndicatorManagementListTableHeader';
import MasterDataDetailIndicatorManagementListTableItem from '../MasterDataDetailIndicatorManagementListTableItem';
// Data
import {
  MasterDataDetailIndicator,
  MasterDataDetailIndicatorOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  detailIndicator: MasterDataDetailIndicator[];
  sortBy: SortTable;
  orderBy: MasterDataDetailIndicatorOrderByType;
  onEditDetailIndicator?(_detailIndicator: MasterDataDetailIndicator): void;
  onDeleteDetailIndicator?(_detailIndicator: MasterDataDetailIndicator): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataDetailIndicatorOrderByType,
  ): void;
}
//#endregion

const MasterDataDetailIndicatorManagementList: React.FC<Props> = ({
  detailIndicator,
  sortBy,
  orderBy,
  onEditDetailIndicator,
  onDeleteDetailIndicator,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataDetailIndicatorManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            detailIndicator as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <MasterDataDetailIndicatorManagementListTableItem
              {...(onEditDetailIndicator && {
                onEditItem: () => onEditDetailIndicator(item),
              })}
              {...(onDeleteDetailIndicator && {
                onDeleteItem: () => onDeleteDetailIndicator(item),
              })}
              key={`master-data-detail-indicator-list-item-data-${item.id}-${index}`}
              data={item}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataDetailIndicatorManagementList;
