//#region IMPORT
// Libraries
import React, {ReactElement, useCallback, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Field, FieldArray, FieldArrayRenderProps, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
import {AppState} from '../../../../../../../../config/Interface';
import {getOptionFromDirectorateList} from '../../../../../../../../utils';
import {masterDataDirectorateOptionListAction} from '../../../../../../usecases/masterData.action';
import {MasterDataDivisionCreateFetchPayload} from '../../../../../../usecases/masterData.type';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../../../components';
// Data
import {MasterDataDivision} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDivisionFormCreateForm.component.style.scss';
//#endregion

//#region TYPE
export interface DivisionFormCreateInitialValues
  extends Omit<MasterDataDivisionCreateFetchPayload, 'data'> {
  divisions: Pick<MasterDataDivision, 'name'>[];
}
//#endregion

//#region INTERFACE
interface Props {
  initialValues: DivisionFormCreateInitialValues;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitDivision(formValue: DivisionFormCreateInitialValues): void;
}
//#endregion

const MasterDataDivisionFormCreateForm: React.FC<Props> = ({
  initialValues,
  isLoading,
  handleSubmitDivision,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );

  const isLoadingDirectorate =
    masterData.masterDataDirectorateOptionListLoading;
  //#endregion

  //#region FETCH DIRECTORATE
  const fetchDirectorateOptionList = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.reset());
    fetchDirectorateOptionList();

    return () => {
      dispatch(masterDataDirectorateOptionListAction.reset());
    };
  }, [dispatch, fetchDirectorateOptionList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const divisionValidation = Yup.object().shape({
    directorateId: Yup.string().required(t('Directorate is required.')),
    divisions: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(t('Division is required.'))
          .min(
            3,
            `${t('Division')} ${t('must be filled in at least')} ${3} ${t(
              'letters',
            )}.`,
          ),
      }),
    ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-division-create-form">
      <div className="master-data-division-create-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={divisionValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitDivision}>
          {({handleSubmit, values, isValid}): React.ReactElement => (
            <>
              <div className="master-data-division-create-form__section">
                <div className="master-data-division-create-form__section--field">
                  <div className="input-label">{t('Directorate')}</div>
                  <Field
                    className="user-input"
                    name="directorateId"
                    placeholder={t('Choose Directorate')}
                    options={getOptionFromDirectorateList(
                      masterData.masterDataDirectorateOptionListData,
                    )}
                    disabled={isLoading || isLoadingDirectorate}
                    component={MSelect}
                  />
                </div>
                <FieldArray
                  name="divisions"
                  render={(
                    arrayHelper: FieldArrayRenderProps,
                  ): ReactElement => (
                    <div className="master-data-division-create-form__section">
                      {values.divisions.map((_, index: number) => (
                        <div
                          key={`master-data-division-create-item-${index}`}
                          className="master-data-division-create-form__section--field">
                          <div className="input-label">
                            {`${t('Division')} ${index + 1}`}
                          </div>
                          <Field
                            className="user-input"
                            name={`divisions.${index}.name`}
                            type="text"
                            placeholder={t('Enter Division')}
                            disabled={isLoading}
                            component={MTextField}
                          />
                        </div>
                      ))}

                      <div
                        className="master-data-division-create-form__form--add"
                        onClick={
                          isLoading
                            ? undefined
                            : () => arrayHelper.push({name: ''})
                        }>
                        + {t('Add Division')}
                      </div>
                    </div>
                  )}
                />

                <div className="master-data-division-create-form__form--action">
                  <div className="master-data-division-create-form-action__canceled">
                    <MButton
                      isDisabled={isLoading}
                      buttonLabel={t('Cancel')}
                      handleClick={handleCancel}
                    />
                  </div>
                  <div className="master-data-division-create-form-action">
                    <MButton
                      isDisabled={!isValid || isLoading}
                      isLoading={isLoading}
                      buttonLabel={t('Add')}
                      handleClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataDivisionFormCreateForm;
