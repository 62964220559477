//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
import {AppState} from '../../../../../../../../config/Interface';
import {
  getOptionFromDirectorateList,
  getOptionFromDivisionList,
} from '../../../../../../../../utils';
import {
  masterDataDivisionOptionListAction,
  masterDataDirectorateOptionListAction,
} from '../../../../../../usecases/masterData.action';
import {MasterDataDepartmentEditFetchPayload} from '../../../../../../usecases/masterData.type';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../../../components';
// Assets
import './MasterDataDepartmentFormEditForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  initialValues: MasterDataDepartmentEditFetchPayload;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitDepartment(formValue: MasterDataDepartmentEditFetchPayload): void;
}
//#endregion

const MasterDataDepartmentFormCreateForm: React.FC<Props> = ({
  initialValues,
  isLoading,
  handleSubmitDepartment,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const [directorateId, setDirectorateId] = useState(
    initialValues.directorateId,
  );

  const isLoadingDirectorate =
    masterData.masterDataDirectorateOptionListLoading;
  const isLoadingDivision = masterData.masterDataDivisionOptionListLoading;
  //#endregion

  //#region FETCH DIVISION
  const fetchDivisionOptionList = useCallback(() => {
    if (directorateId)
      dispatch(
        masterDataDivisionOptionListAction.fetch({
          directorateId: directorateId,
        }),
      );
  }, [dispatch, directorateId]);

  useEffect(() => {
    dispatch(masterDataDivisionOptionListAction.reset());
    fetchDivisionOptionList();

    return () => {
      dispatch(masterDataDivisionOptionListAction.reset());
    };
  }, [dispatch, fetchDivisionOptionList]);
  //#endregion

  //#region FETCH DIRECTORATE
  const fetchDirectorateOptionList = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.reset());
    fetchDirectorateOptionList();

    return () => {
      dispatch(masterDataDirectorateOptionListAction.reset());
    };
  }, [dispatch, fetchDirectorateOptionList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const departmentValidation = Yup.object().shape({
    directorateId: Yup.string().required(t('Directorate is required.')),
    divisionId: Yup.string().required(t('Division is required.')),
    name: Yup.string()
      .required(t('Department is required.'))
      .min(
        3,
        `${t('Department')} ${t('must be filled in at least')} ${3} ${t(
          'letters',
        )}.`,
      ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-department-form-edit-form">
      <div className="master-data-department-form-edit-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={departmentValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitDepartment}>
          {({handleSubmit, isValid}): React.ReactElement => (
            <>
              <div className="master-data-department-form-edit-form__section">
                <div className="master-data-department-form-edit-form__section--field">
                  <div className="input-label">{t('Directorate')}</div>
                  <Field
                    className="user-input"
                    name="directorateId"
                    placeholder={t('Choose Directorate')}
                    options={getOptionFromDirectorateList(
                      masterData.masterDataDirectorateOptionListData,
                    )}
                    handleChangeSelect={(e: string) => {
                      setDirectorateId(e);
                    }}
                    disabled={isLoading || isLoadingDirectorate}
                    component={MSelect}
                  />
                </div>
                <div className="master-data-department-form-edit-form__section--field">
                  <div className="input-label">{t('Division')}</div>
                  <Field
                    className="user-input"
                    name="divisionId"
                    placeholder={t('Choose Division')}
                    options={getOptionFromDivisionList(
                      masterData.masterDataDivisionOptionListData,
                    )}
                    disabled={isLoading || isLoadingDivision}
                    component={MSelect}
                  />
                </div>
                <div className="master-data-department-form-edit-form__section--field">
                  <div className="input-label">{t('Department')}</div>
                  <Field
                    className="user-input"
                    name="name"
                    type="text"
                    placeholder={t('Enter Department')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
              </div>

              <div className="master-data-department-form-edit-form__form--action">
                <div className="master-data-department-form-edit-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="master-data-department-form-edit-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataDepartmentFormCreateForm;
