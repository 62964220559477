import {
  MasterDataObservationValueOptionListAction,
  MasterDataObservationValueOptionListSuccessPayload,
  MasterDataObservationValueOptionListFailedPayload,
  MasterDataObservationValueOptionListFetchPayload,
} from './masterDataObservationValueOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataObservationValueOptionListInitialState} from './masterDataObservationValueOptionList.reducer';

export const masterDataObservationValueOptionListHandler = {
  [MasterDataObservationValueOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataObservationValueOptionListLoading: true,
      masterDataObservationValueOptionListParams: action.payload,
      action: MasterDataObservationValueOptionListAction.FETCH,
    };
  },
  [MasterDataObservationValueOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueOptionListLoading: false,
    masterDataObservationValueOptionListResponse: action.payload,
    action: MasterDataObservationValueOptionListAction.SUCCESS,
  }),
  [MasterDataObservationValueOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueOptionListLoading: false,
    masterDataObservationValueOptionListData: [
      ...state.masterDataObservationValueOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataObservationValueOptionListAction.UPDATE,
  }),
  [MasterDataObservationValueOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueOptionListLoading: false,
    masterDataObservationValueOptionListError: action.payload,
    action: MasterDataObservationValueOptionListAction.FAILED,
  }),
  [MasterDataObservationValueOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationValueOptionListInitialState,
    action: MasterDataObservationValueOptionListAction.RESET,
  }),
};
