import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDepartmentDeleteAction,
  MasterDataDepartmentDeleteFailedPayload,
  MasterDataDepartmentDeleteFetchPayload,
  MasterDataDepartmentDeleteSuccessPayload,
} from './masterDataDepartmentDelete.type';

export const masterDataDepartmentDeleteAction = {
  fetch: createAction<MasterDataDepartmentDeleteFetchPayload>(
    MasterDataDepartmentDeleteAction.FETCH,
  ),
  success: createAction<MasterDataDepartmentDeleteSuccessPayload>(
    MasterDataDepartmentDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataDepartmentDeleteFailedPayload>(
    MasterDataDepartmentDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataDepartmentDeleteAction.RESET),
};
