import {Tabs, Tab} from '@mui/material';
import React from 'react';
import {IconArrowLeftCircle, IconArrowRightCircle} from '../../assets/icons';
import './MTabs.scss';

interface Props {
  tabsValue: number;
  handleChangeTab(_: unknown, tabsValue: number): void;
  tabLabel: string[];
  variant?: 'standard' | 'scrollable' | 'fullWidth';
}

const MTabs: React.FC<Props> = ({
  tabsValue,
  handleChangeTab,
  tabLabel,
  variant,
}: Props) => {
  return (
    <Tabs
      variant={variant}
      value={tabsValue}
      {...(variant === 'scrollable' ? {scrollButtons: true} : {})}
      allowScrollButtonsMobile
      onChange={(_, value) => handleChangeTab(_, value)}
      textColor="primary"
      ScrollButtonComponent={(scrollProps) => {
        const {className, direction, disabled, onClick} = scrollProps;
        if (direction === 'left' && !disabled) {
          return (
            <div className={className} onClick={() => onClick()}>
              <IconArrowLeftCircle />
            </div>
          );
        } else if (direction === 'right' && !disabled) {
          return (
            <div className={className} onClick={() => onClick()}>
              <IconArrowRightCircle />
            </div>
          );
        } else {
          return null;
        }
      }}
      className={`m-tabs${variant === 'scrollable' ? '-scrollable' : ''}`}>
      {tabLabel.map((item: string, index: number) => (
        <Tab
          key={`tab-${item}-${index}`}
          className="m-tabs-item"
          label={item}
          id={`simple-tab-${index}`}
          aria-controls={`simple-tabpanel-${index}`}
        />
      ))}
    </Tabs>
  );
};

export default MTabs;
