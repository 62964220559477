import {
  FollowUpListAction,
  FollowUpListSuccessPayload,
  FollowUpListFailedPayload,
  FollowUpListFetchPayload,
  FollowUpListUpdatePayload,
} from './followUpList.type';
import {FollowUpState} from '../followUp.reducer';
import {GenericAction} from '../../../../config/constant';
import {followUpListInitialState} from './followUpList.reducer';

export const followUpListHandler = {
  [FollowUpListAction.FETCH]: (
    state: FollowUpState,
    action: GenericAction<FollowUpListFetchPayload>,
  ): FollowUpState => {
    return {
      ...state,
      followUpListLoading: true,
      followUpListParams: action.payload,
      action: FollowUpListAction.FETCH,
    };
  },
  [FollowUpListAction.SUCCESS]: (
    state: FollowUpState,
    action: GenericAction<FollowUpListSuccessPayload>,
  ): FollowUpState => ({
    ...state,
    followUpListLoading: false,
    followUpListResponse: action.payload,
    action: FollowUpListAction.SUCCESS,
  }),
  [FollowUpListAction.UPDATE]: (
    state: FollowUpState,
    action: GenericAction<FollowUpListUpdatePayload>,
  ): FollowUpState => ({
    ...state,
    followUpListLoading: false,
    followUpListData: [...state.followUpListData, ...action.payload],
    action: FollowUpListAction.UPDATE,
  }),
  [FollowUpListAction.FAILED]: (
    state: FollowUpState,
    action: GenericAction<FollowUpListFailedPayload>,
  ): FollowUpState => ({
    ...state,
    followUpListLoading: false,
    followUpListError: action.payload,
    action: FollowUpListAction.FAILED,
  }),
  [FollowUpListAction.RESET]: (state: FollowUpState): FollowUpState => ({
    ...state,
    ...followUpListInitialState,
    action: FollowUpListAction.RESET,
  }),
};
