import {ToggleButton, ToggleButtonGroup} from '@mui/material';
import React from 'react';
import {OptionType} from '../../modules/wrapper/entity';
import './MToggleButton.component.style.scss';

interface Props {
  className?: string;
  selected?: string | string[] | null;
  options: OptionType[];
  handleChangeToggle?(
    e: React.MouseEvent<HTMLElement>,
    value: string | string[] | null,
  ): void;
  size?: 'small' | 'medium' | 'large' | undefined;
  isMultiple?: boolean;
}

const MToggleButton: React.FC<Props> = ({
  className = '',
  options,
  selected,
  handleChangeToggle,
  isMultiple = false,
  size = 'small',
}: Props) => {
  return (
    <div className={`m-toggle-button ${className}`}>
      <ToggleButtonGroup
        onChange={handleChangeToggle}
        value={selected}
        exclusive={!isMultiple}
        size={size}>
        {options.map((optionItem: OptionType, idx: number) => {
          return (
            <ToggleButton
              key={`m-toggle-button-item${idx}`}
              value={optionItem.value}>
              {optionItem.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

export default MToggleButton;
