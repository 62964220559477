import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {post} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../user.reducer';
import {userRoleAccessCreateAction} from './userRoleAccessCreate.action';
import {
  UserRoleAccessCreateAction,
  UserRoleAccessCreateFetchPayload,
  UserRoleAccessCreateSuccessPayload,
} from './userRoleAccessCreate.type';

function* workerFetch(action: GenericAction<UserRoleAccessCreateFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<UserRoleAccessCreateSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/role/save/`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(userRoleAccessCreateAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userRoleAccessCreateAction.failed(e.response.data.payload));
  }
}

const userRoleAccessCreateWatcher = [
  takeLatest(UserRoleAccessCreateAction.FETCH, workerFetch),
];

export default userRoleAccessCreateWatcher;
