//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataBusCreateAction} from '../../../../usecases/section/operational/masterDataBusCreate/masterDataBusCreate.action';
import {masterDataBusEditAction} from '../../../../usecases/section/operational/masterDataBusEdit/masterDataBusEdit.action';
// Components
import BusForm from './components/BusForm';
// Data
import {
  IMasterDataBusForm,
  MasterDataBus,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataBusFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: MasterDataBus;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataBusFormContainer: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: IMasterDataBusForm = {
    id: data?.id,
    bodyNumber: data?.bodyNumber || '',
    busType: data?.busType || '',
    operator: data?.operator || '',
    pool: data?.pool || '',
    isActive: 1,
  };
  //#endregion

  return (
    <div className="master-data-bus-form-container">
      <BusForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitBus={(_bus) => {
          if (_bus.id) {
            dispatch(
              masterDataBusEditAction.fetch({..._bus, id: _bus.id || ''}),
            );
            return;
          }
          dispatch(masterDataBusCreateAction.fetch(_bus));
        }}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default MasterDataBusFormContainer;
