/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {FieldProps} from 'formik';
import NumberFormat, {NumberFormatProps} from 'react-number-format';

interface Props extends FieldProps<string> {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: {target: {name: string; value: string}}) => void;
  name: string;
  disabled?: boolean;
  decimalScale?: string;
  maxCurrency?: string;
}
const MTextField: React.FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form,
  field,
  disabled,
  name,
  decimalScale,
  maxCurrency,
  inputRef,
  onChange,
  ...otherProps
}: Props) => {
  //#region GENERAL
  const isZeroPrefix = (val: string) => /^[0]/.test(val);
  const MAX_CURRENCY_VALUE = 9999999999999999;
  //#endregion

  return (
    <NumberFormat
      {...otherProps}
      {...(field && field)}
      disabled={disabled}
      getInputRef={inputRef}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={decimalScale ? Number(decimalScale) : 0}
      prefix="Rp "
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values: NumberFormatProps) => {
        return (
          !isZeroPrefix(values.value as string) &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (values as any)?.value <=
            (Number(maxCurrency) || MAX_CURRENCY_VALUE) &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (values as any)?.value >= 0
        );
      }}
    />
  );
};

export default MTextField;
