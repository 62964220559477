import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../user.reducer';
import {userRoleAccessListAction} from './userRoleAccessList.action';
import {
  UserRoleAccessListAction,
  UserRoleAccessListFetchPayload,
  UserRoleAccessListResponse,
  UserRoleAccessListSuccessPayload,
} from './userRoleAccessList.type';

function* workerFetch(action: GenericAction<UserRoleAccessListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<UserRoleAccessListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/role/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.data) {
      yield put(
        userRoleAccessListAction.success(
          res.data as UserRoleAccessListResponse,
        ),
      );
      yield put(
        userRoleAccessListAction.update(res.data as UserRoleAccessListResponse),
      );
    } else {
      yield put(
        userRoleAccessListAction.failed((res.data as unknown) as CommonError),
      );
    }
  } catch (e) {
    yield put(userRoleAccessListAction.failed(e as CommonError));
  }
}

const userRoleAccessListWatcher = [
  takeLatest(UserRoleAccessListAction.FETCH, workerFetch),
];

export default userRoleAccessListWatcher;
