/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import moment from 'moment';
// Utils
import t from '../../../../../../lang';
import {actionHandler} from '../../../../../wrapper/utils';
import {
  masterDataAssignmentLetterCreateAction,
  masterDataAssignmentLetterEditAction,
  masterDataAssignmentLetterListAction,
} from '../../../../usecases/masterData.action';
import {
  MasterDataAssignmentLetterCreateAction,
  MasterDataAssignmentLetterDeleteAction,
  MasterDataAssignmentLetterEditAction,
  MasterDataAssignmentLetterListAction,
  MasterDataAssignmentLetterSetActiveAction,
} from '../../../../usecases/masterData.type';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {AppState} from '../../../../../../config/Interface';
import {
  masterDataAssignmentLetterDeleteAction,
  masterDataAssignmentLetterSetActiveAction,
} from '../../../../usecases/masterData.action';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
  MBottomSheet,
} from '../../../../../../components';
import {
  MasterDataAssignmentLetterManagementDetail,
  MasterDataAssignmentLetterManagementDetailHeader,
  MasterDataAssignmentLetterManagementList,
} from './components';
import MasterDataAssignmentLetterFormContainer from '../MasterDataAssignmentLetterFormContainer';
// Data
import {
  MasterDataAssignmentLetter,
  MasterDataAssignmentLetterOrderByType,
} from '../../../../entity/masterData.string.entity';
import {PATH} from '../../../../../wrapper/entity';
// Assets
import './MasterDataAssignmentLetterSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

const MasterDataAssignmentLetterSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [
    masterDataAssignmentLetterList,
    setMasterDataAssignmentLetterList,
  ] = useState<MasterDataAssignmentLetter[]>([]);
  const [isAddAssignmentLetter, setAddAssignmentLetter] = useState(false);
  const [isViewAssignmentLetter, setIsViewAssignmentLetter] = useState<
    MasterDataAssignmentLetter | undefined
  >(undefined);
  const [isEditAssignmentLetter, setIsEditAssignmentLetter] = useState<
    MasterDataAssignmentLetter | undefined
  >(undefined);
  const [isDeleteAssignmentLetter, setIsDeleteAssignmentLetter] = useState<
    MasterDataAssignmentLetter | undefined
  >(undefined);
  const [isActivateAssignmentLetter, setIsActivateAssignmentLetter] = useState<
    MasterDataAssignmentLetter | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddAssignmentLetter =
    masterData.masterDataAssignmentLetterCreateLoading;
  const isLoadingEditAssignmentLetter =
    masterData.masterDataAssignmentLetterEditLoading;
  const isLoadingDeleteAssignmentLetter =
    masterData.masterDataAssignmentLetterDeleteLoading;
  const isLoadingSetActiveAssignmentLetter =
    masterData.masterDataAssignmentLetterSetActiveLoading;
  //#endregion

  //#region PDF VIEWER CONTROL
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(100);

  const handlePDFChangePage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(event.currentTarget.value.replace(/[^0-9]+/g, ''));
    setPageNumber(val);
  };

  const handlePDFIncreaseScale = () => {
    if (scale < 300) {
      const increased = scale + 10;
      setScale(increased);
    }
  };

  const handlePDFDecreaseScale = () => {
    if (scale > 10) {
      const decreased = scale - 10;
      setScale(decreased);
    }
  };

  const handleCloseViewAssignmentLetter = () => {
    setIsViewAssignmentLetter(undefined);
    setPageNumber(1);
    setNumPages(1);
    setScale(100);
  };
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataAssignmentLetterListPage,
    setMasterDataAssignmentLetterListPage,
  ] = useState<number>(0);

  const [
    masterDataAssignmentLetterListCount,
    setMasterDataAssignmentLetterListCount,
  ] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataAssignmentLetterListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataAssignmentLetterListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('desc');
  const [orderBy, setOrderBy] = useState<MasterDataAssignmentLetterOrderByType>(
    'isActive',
  );

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataAssignmentLetterOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH ASSIGNMENT LETTER
  const fetchAssignmentLetterList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataAssignmentLetterListAction.fetch({page, search, size}),
      );
    },
    [dispatch],
  );
  //#endregion

  //#region HANDLE SET ACTIVE
  const handleActivation = (id: string) => {
    dispatch(masterDataAssignmentLetterSetActiveAction.fetch({id}));
  };
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataAssignmentLetterListAction.reset());
    fetchAssignmentLetterList(
      masterDataAssignmentLetterListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataAssignmentLetterListAction.reset());
    };
  }, [
    dispatch,
    fetchAssignmentLetterList,
    masterDataAssignmentLetterListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataAssignmentLetterActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataAssignmentLetterListAction.UPDATE, (): void => {
            const totalElement =
              masterData?.masterDataAssignmentLetterListResponse?.data
                ?.totalElements;
            const data: MasterDataAssignmentLetter[] =
              masterData.masterDataAssignmentLetterListData;
            if (data) {
              const dataTemp: MasterDataAssignmentLetter[] = data.map(
                (item: MasterDataAssignmentLetter) => {
                  return {
                    ...item,
                    publishDate: moment(
                      item.publishDate,
                      'YYYY-MM-DD',
                    ).valueOf(),
                  };
                },
              );
              setMasterDataAssignmentLetterList(dataTemp);
              setMasterDataAssignmentLetterListCount(totalElement ?? 0);
            }
          })
          .addCase(MasterDataAssignmentLetterListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataAssignmentLetterListAction.reset());
          })
          .addCase(MasterDataAssignmentLetterCreateAction.SUCCESS, (): void => {
            setAddAssignmentLetter(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataAssignmentLetterCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataAssignmentLetterCreateAction.reset());
          })
          .addCase(MasterDataAssignmentLetterEditAction.SUCCESS, (): void => {
            setIsEditAssignmentLetter(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataAssignmentLetterEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataAssignmentLetterEditAction.reset());
          })
          .addCase(MasterDataAssignmentLetterDeleteAction.SUCCESS, (): void => {
            setIsDeleteAssignmentLetter(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataAssignmentLetterDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataAssignmentLetterDeleteAction.reset());
          })
          .addCase(
            MasterDataAssignmentLetterSetActiveAction.SUCCESS,
            (): void => {
              setIsActivateAssignmentLetter(undefined);
              refresh();
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Assignment Letter activation update success!'),
                  type: 'success',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
            },
          )
          .addCase(
            MasterDataAssignmentLetterSetActiveAction.FAILED,
            (): void => {
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Assignment Letter activation update failed!'),
                  type: 'error',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
              dispatch(masterDataAssignmentLetterSetActiveAction.reset());
            },
          );
      });
    },
    [
      dispatch,
      masterData.masterDataAssignmentLetterListData,
      masterData?.masterDataAssignmentLetterListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataAssignmentLetterActionHandler(masterData.action);
  }, [masterData.action, masterDataAssignmentLetterActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataAssignmentLetterDeleteAction.fetch({id}));
  };
  //#endregion

  if (!permissions?.masterWorkAssignment) return <Redirect to={PATH.HOME} />;
  return (
    <>
      <div className="master-data-assignment-letter-section">
        <div className="master-data-assignment-letter-section__header">
          <MSearchInput
            inputClassName="master-data-assignment-letter-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Assignment Letter')}
            setInputValue={(search: string) => {
              setMasterDataAssignmentLetterListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions.masterWorkAssignment.havePermissionCreate && (
            <div className="master-data-assignment-letter-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddAssignmentLetter(true)}
              />
            </div>
          )}
        </div>
        <MasterDataAssignmentLetterManagementList
          assignmentLetterList={masterDataAssignmentLetterList}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          onViewAssignmentLetter={(
            _assignmentLetter: MasterDataAssignmentLetter,
          ) => setIsViewAssignmentLetter(_assignmentLetter)}
          {...(permissions?.masterWorkAssignment.havePermissionUpdate && {
            onEditAssignmentLetter: (
              _assignmentLetter: MasterDataAssignmentLetter,
            ) => setIsEditAssignmentLetter(_assignmentLetter),
            onActivateAssignmentLetter: (
              _assignmentLetter: MasterDataAssignmentLetter,
            ) => setIsActivateAssignmentLetter(_assignmentLetter),
          })}
          {...(permissions?.masterWorkAssignment.havePermissionDelete && {
            onDeleteAssignmentLetter: (
              _assignmentLetter: MasterDataAssignmentLetter,
            ) => setIsDeleteAssignmentLetter(_assignmentLetter),
          })}
        />
        <MTablePagination
          className="master-data-assignment-letter-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataAssignmentLetterListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataAssignmentLetterListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddAssignmentLetter(false)}
        title={t('Add Assignment Letter')}
        isOpen={isAddAssignmentLetter}>
        <MasterDataAssignmentLetterFormContainer
          isLoading={isLoadingAddAssignmentLetter}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isViewAssignmentLetter && (
        <MBottomSheet
          onClose={handleCloseViewAssignmentLetter}
          isOpen={!!isViewAssignmentLetter}
          titleComponent={
            <MasterDataAssignmentLetterManagementDetailHeader
              data={isViewAssignmentLetter}
              pageNumber={pageNumber}
              numPages={numPages}
              scale={scale}
              onIncreaseScale={handlePDFIncreaseScale}
              onDecreaseScale={handlePDFDecreaseScale}
              onChangePage={handlePDFChangePage}
            />
          }>
          <MasterDataAssignmentLetterManagementDetail
            onNumPages={(pdf) => setNumPages(pdf.numPages)}
            numPages={numPages}
            pageNumber={pageNumber}
            scale={scale}
            data={isViewAssignmentLetter}
          />
        </MBottomSheet>
      )}

      {isEditAssignmentLetter && (
        <Modal
          onClose={() => setIsEditAssignmentLetter(undefined)}
          isOpen={!!isEditAssignmentLetter}
          title={t('Edit Assignment Letter')}>
          <MasterDataAssignmentLetterFormContainer
            data={isEditAssignmentLetter}
            isLoading={isLoadingEditAssignmentLetter}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteAssignmentLetter && (
        <AlertDialog
          onClose={() => setIsDeleteAssignmentLetter(undefined)}
          onCancel={() => setIsDeleteAssignmentLetter(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteAssignmentLetter.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteAssignmentLetter}
          isLoading={isLoadingDeleteAssignmentLetter}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isActivateAssignmentLetter && (
        <AlertDialog
          onClose={() => setIsActivateAssignmentLetter(undefined)}
          onCancel={() => setIsActivateAssignmentLetter(undefined)}
          onConfirm={() => handleActivation(isActivateAssignmentLetter.id)}
          cancelText={t('Cancel')}
          confirmText={
            isActivateAssignmentLetter.isActive
              ? t('Yes, Deactivate')
              : t('Yes, Activate')
          }
          isOpen={!!isActivateAssignmentLetter}
          isLoading={isLoadingSetActiveAssignmentLetter}
          title={t('Are you sure?')}
          isReverseButton={true}
          description={
            isActivateAssignmentLetter.isActive
              ? t('The selected assignment letter will be deactivated')
              : t('The selected assignment letter will be activated')
          }></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddAssignmentLetter(false)
              : setIsEditAssignmentLetter(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataAssignmentLetterSection;
