import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataObservation} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationListAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_LIST_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_LIST_RESET',
  UPDATE: 'MASTER_DATA_OBSERVATION_LIST_UPDATE',
};

export interface MasterDataObservationListInitialState {
  masterDataObservationListLoading: boolean;
  masterDataObservationListData: MasterDataObservation[];
  masterDataObservationListError?: MasterDataObservationListFailedPayload;
  masterDataObservationListParams?: MasterDataObservationListFetchPayload;
  masterDataObservationListResponse?: MasterDataObservationListSuccessPayload;
}

export type MasterDataObservationListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataObservation[]>;
};

export type MasterDataObservationListFailedPayload = CommonError;

export type MasterDataObservationListSuccessPayload = MasterDataObservationListResponse;

export type MasterDataObservationListUpdatePayload = MasterDataObservationListResponse;

export interface MasterDataObservationListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
