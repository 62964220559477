import {createAction} from '@reduxjs/toolkit';
import {
  UserRoleAccessListAction,
  UserRoleAccessListFailedPayload,
  UserRoleAccessListFetchPayload,
  UserRoleAccessListSuccessPayload,
  UserRoleAccessListUpdatePayload,
} from './userRoleAccessList.type';

export const userRoleAccessListAction = {
  fetch: createAction<UserRoleAccessListFetchPayload>(
    UserRoleAccessListAction.FETCH,
  ),
  success: createAction<UserRoleAccessListSuccessPayload>(
    UserRoleAccessListAction.SUCCESS,
  ),
  failed: createAction<UserRoleAccessListFailedPayload>(
    UserRoleAccessListAction.FAILED,
  ),
  update: createAction<UserRoleAccessListUpdatePayload>(
    UserRoleAccessListAction.UPDATE,
  ),
  reset: createAction(UserRoleAccessListAction.RESET),
};
