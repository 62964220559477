import {CommonError} from '../../../../../../config/constant';
import {MasterDataDetailObservationFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailObservationCreateAction = {
  FETCH: 'MASTER_DATA_DETAIL_OBSERTAVION_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_OBSERTAVION_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_OBSERTAVION_CREATE_FAILED',
  RESET: 'MASTER_DATA_DETAIL_OBSERTAVION_CREATE_RESET',
};

export interface MasterDataDetailObservationCreateInitialState {
  masterDataDetailObservationCreateLoading: boolean;
  masterDataDetailObservationCreateParams?: MasterDataDetailObservationCreateFetchPayload;
  masterDataDetailObservationCreateError?: MasterDataDetailObservationCreateFailedPayload;
  masterDataDetailObservationCreateResponse?: MasterDataDetailObservationCreateSuccessPayload;
}

export type MasterDataDetailObservationCreateFetchPayload = Omit<
  MasterDataDetailObservationFormData,
  'id'
>[];

export type MasterDataDetailObservationCreateFailedPayload = CommonError;

export interface MasterDataDetailObservationCreateSuccessPayload {}
