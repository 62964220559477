import LocalGlobal from '../lang/LocaleGlobal';

interface Lang {
  en: string;
  id: string;
}

export const Locale: Lang[] = [...LocalGlobal];

export const Availability = ['en', 'id'];
