import React from 'react';
import {DateRangePicker, DateRangePickerDay} from '@mui/lab';
import {RangeInput} from '@mui/lab/DateRangePicker/RangeTypes';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {InputAdornment, InputLabel, TextField} from '@mui/material';
import t from '../../lang';
import {IconCalendar} from '../../assets/icons';
import './MDateRangepicker.component.style.scss';
import moment, {Moment} from 'moment';

interface Props {
  disabled?: boolean;
  className?: string;
  inputValue: RangeInput<unknown>;
  toolbarText?: string;
  format?: string;
  label?: string;
  placeholder?: string;
  calendars?: 1 | 2 | 3;
  handleChange?(date: RangeInput<unknown>): void;
}

const MDateRangepicker: React.FC<Props> = ({
  disabled = false,
  className,
  inputValue,
  toolbarText = t('Choose Date'),
  format = 'DD/MM/YYYY',
  label,
  placeholder = t('Choose Date'),
  calendars = 1,
  handleChange,
}: Props) => {
  return (
    <div className={`m-daterangepicker ${className}`}>
      {label ? (
        <InputLabel className="m-daterangepicker__label">{label}</InputLabel>
      ) : null}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateRangePicker
          disabled={disabled}
          calendars={calendars}
          inputFormat={format}
          disableMaskedInput
          showToolbar={false}
          showTodayButton={false}
          allowSameDateSelection={true}
          showDaysOutsideCurrentMonth={true}
          value={inputValue || null}
          onChange={(value) => {
            handleChange && handleChange(value);
          }}
          ToolbarComponent={() => (
            <div className="m-daterangepicker__toolbar">{toolbarText}</div>
          )}
          components={{OpenPickerIcon: IconCalendar}}
          InputAdornmentProps={{position: 'start'}}
          renderDay={(date, dateRangePickerDayProps) => {
            const isWeekend = moment(date as Moment).day() === 0;
            if (
              dateRangePickerDayProps.selected ||
              dateRangePickerDayProps.isStartOfHighlighting ||
              dateRangePickerDayProps.isEndOfHighlighting
            ) {
              return (
                <DateRangePickerDay
                  className="m-daterangepicker__date-selected"
                  {...dateRangePickerDayProps}
                />
              );
            } else if (dateRangePickerDayProps.isHighlighting) {
              return (
                <DateRangePickerDay
                  className="m-daterangepicker__date-highlight"
                  {...dateRangePickerDayProps}
                />
              );
            } else if (dateRangePickerDayProps.today) {
              return (
                <DateRangePickerDay
                  className="m-daterangepicker__date-today"
                  {...dateRangePickerDayProps}
                />
              );
            } else if (isWeekend) {
              return (
                <DateRangePickerDay
                  className="m-daterangepicker__date-weekend"
                  {...dateRangePickerDayProps}
                />
              );
            } else if (dateRangePickerDayProps.outsideCurrentMonth) {
              return (
                <DateRangePickerDay
                  className="m-daterangepicker__date-outside"
                  {...dateRangePickerDayProps}
                />
              );
            }
            return (
              <DateRangePickerDay
                className="m-daterangepicker__date"
                {...dateRangePickerDayProps}
              />
            );
          }}
          renderInput={(startProps, endProps) => {
            const {inputProps} = startProps;
            const endInputProps = endProps.inputProps;
            const customInput = `${inputProps?.value} ~ ${endInputProps?.value}`;
            const customInputProps = {
              ...inputProps,
              placeholder: placeholder,
              value:
                inputProps?.value || endInputProps?.value ? customInput : '',
            };
            const customParams = {
              ...startProps,
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconCalendar />
                  </InputAdornment>
                ),
              },
              inputProps: customInputProps,
              label: undefined,
            };

            return (
              <TextField
                className="m-daterangepicker__input"
                {...customParams}
              />
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MDateRangepicker;
