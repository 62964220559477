//#region IMPORT
// Libraries
import React, {useCallback, useEffect} from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../../../lang';
import {getOptionFromRoleList} from '../../../../../../../../utils';
import {AppState} from '../../../../../../../../config/Interface';
import {UserState} from '../../../../../../../user/usecases/user.reducer';
import {userRoleAccessListAction} from '../../../../../../../user/usecases/userRoleAccessList/userRoleAccessList.action';
// Components
import {
  MButton,
  MTextField,
  MSelect,
  MDatepickerInput,
  MDropzone,
} from '../../../../../../../../components';
// Data
import {MasterDataAssignmentLetterFormData} from '../../../../../../entity/masterData.string.entity';
// Assets
import './AssignmentLetterForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: MasterDataAssignmentLetterFormData;
  handleCancel(): void;
  handleSubmitUser(_user: MasterDataAssignmentLetterFormData): void;
}
//#endregion

const AssignmentLetterForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitUser,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const isEdit = !!initialValues?.id;
  const acceptedType = ['application/pdf'];
  const maxSize = 5 * 1000 * 1024;
  const user: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  //#endregion

  //#region FETCH ROLE
  const fetchRoleList = useCallback(() => {
    dispatch(userRoleAccessListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(userRoleAccessListAction.reset());
    fetchRoleList();

    return () => {
      dispatch(userRoleAccessListAction.reset());
    };
  }, [dispatch, fetchRoleList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const assignmentLetterValidation = Yup.object().shape({
    number: Yup.string().required(t('Letter Number is required.')),
    publishDate: Yup.string().required(t('Publish Date is required.')),
    roleId: Yup.string().required(t('Role is required.')),
    file: Yup.mixed()
      .required(t('Assignment Letter File is required.'))
      .test(
        'file-invalid-type',
        t('Assignment Letter File Format does not supported.'),
        (value) => !value || (value && acceptedType.includes(value.type)),
      )
      .test(
        'file-too-large',
        t('Maximum Assignment Letter File Size is 5MB.'),
        (value) => !value || (value && value.size <= maxSize),
      ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="assignment-letter-form">
      <div className="assignment-letter-form__form">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={assignmentLetterValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitUser}>
          {({handleSubmit, values, isValid}): React.ReactElement => (
            <>
              <div className="assignment-letter-form__section">
                <div className="assignment-letter-form__section--field">
                  <div className="input-label">{t('Letter Number')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="number"
                    placeholder={t('Enter Letter Number')}
                    component={MTextField}
                  />
                </div>
                <div className="assignment-letter-form__section--group">
                  <div className="assignment-letter-form__section--field">
                    <div className="input-label">{t('Publish Date')}</div>
                    <Field
                      disabled={isLoading}
                      className="user-input"
                      name="publishDate"
                      inputValue={values.publishDate}
                      placeholder={t('Choose Date')}
                      component={MDatepickerInput}
                    />
                  </div>
                  <div className="assignment-letter-form__section--field">
                    <div className="input-label">{t('User')}</div>
                    <Field
                      disabled={isEdit || isLoading}
                      className="user-input"
                      name="roleId"
                      placeholder={t('Choose User')}
                      options={getOptionFromRoleList(
                        user.userRoleAccessListData,
                      )}
                      component={MSelect}
                    />
                  </div>
                </div>
                <div className="assignment-letter-form__section--field">
                  <div className="input-label">{t('Upload File')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="file"
                    accept={acceptedType}
                    maxSize={maxSize}
                    component={MDropzone}
                  />
                </div>
              </div>

              <div className="assignment-letter-form__form--action">
                <div className="assignment-letter-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="assignment-letter-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={!isEdit ? t('Add') : t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default AssignmentLetterForm;
