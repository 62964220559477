import {
  DashboardDepartmentBestSectionAction,
  DashboardDepartmentBestSectionSuccessPayload,
  DashboardDepartmentBestSectionFailedPayload,
  DashboardDepartmentBestSectionFetchPayload,
} from './dashboardDepartmentBestSection.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardDepartmentBestSectionInitialState} from './dashboardDepartmentBestSection.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardDepartmentBestSectionHandler = {
  [DashboardDepartmentBestSectionAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardDepartmentBestSectionFetchPayload>,
  ): DashboardState => {
    return {
      ...state,
      dashboardDepartmentBestSectionParams: action.payload,
      dashboardDepartmentBestSectionLoading: true,
      action: DashboardDepartmentBestSectionAction.FETCH,
    };
  },
  [DashboardDepartmentBestSectionAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardDepartmentBestSectionSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardDepartmentBestSectionLoading: false,
    dashboardDepartmentBestSectionResponse: action.payload,
    action: DashboardDepartmentBestSectionAction.SUCCESS,
  }),
  [DashboardDepartmentBestSectionAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardDepartmentBestSectionFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardDepartmentBestSectionLoading: false,
    dashboardDepartmentBestSectionError: action.payload,
    action: DashboardDepartmentBestSectionAction.FAILED,
  }),
  [DashboardDepartmentBestSectionAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardDepartmentBestSectionInitialState,
    action: DashboardDepartmentBestSectionAction.RESET,
  }),
};
