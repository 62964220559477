import {
  MasterDataObservationListAction,
  MasterDataObservationListSuccessPayload,
  MasterDataObservationListFailedPayload,
  MasterDataObservationListFetchPayload,
} from './masterDataObservationList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataObservationListInitialState} from './masterDataObservationList.reducer';

export const masterDataObservationListHandler = {
  [MasterDataObservationListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataObservationListLoading: true,
      masterDataObservationListParams: action.payload,
      action: MasterDataObservationListAction.FETCH,
    };
  },
  [MasterDataObservationListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationListLoading: false,
    masterDataObservationListResponse: action.payload,
    action: MasterDataObservationListAction.SUCCESS,
  }),
  [MasterDataObservationListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationListLoading: false,
    masterDataObservationListData: [
      ...state.masterDataObservationListData,
      ...action.payload.data.content,
    ],
    action: MasterDataObservationListAction.UPDATE,
  }),
  [MasterDataObservationListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationListLoading: false,
    masterDataObservationListError: action.payload,
    action: MasterDataObservationListAction.FAILED,
  }),
  [MasterDataObservationListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationListInitialState,
    action: MasterDataObservationListAction.RESET,
  }),
};
