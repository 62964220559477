//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {
  masterDataReviewIndicatorCreateAction,
  masterDataReviewIndicatorEditAction,
} from '../../../../usecases/masterData.action';
// Components
import {MasterDataReviewIndicatorForm} from './components';
// Data
import {MasterDataReviewIndicatorFormData} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataReviewIndicatorFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  data?: MasterDataReviewIndicatorFormData;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataReviewIndicatorFormEditContainer: React.FC<Props> = ({
  assignmentType,
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataReviewIndicatorFormData = {
    id: data?.id || '',
    name: data?.name || '',
    departmentId: data?.departmentId || '',
    roleId: data?.roleId || '',
    sla: data?.sla || 0,
    spmWeight: data?.spmWeight || 0,
    target: data?.target || 0,
    assignmentType: data?.assignmentType || assignmentType,
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitReviewIndicator = (
    formValue: MasterDataReviewIndicatorFormData,
  ) => {
    const reviewIndicatorTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: reviewIndicatorTemp || '',
    };
    if (data?.id) {
      dispatch(masterDataReviewIndicatorEditAction.fetch(formattedValue));
    } else {
      dispatch(masterDataReviewIndicatorCreateAction.fetch(formattedValue));
    }
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-basic-service-type-form-container">
      <MasterDataReviewIndicatorForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitReviewIndicator={handleSubmitReviewIndicator}
      />
    </div>
  );
  //#endregion
};

export default MasterDataReviewIndicatorFormEditContainer;
