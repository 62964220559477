/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import {Field, Formik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
// Utils
import t from '../../../../../../../../lang';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../../../components';
// Assets
import {
  IMasterDataRouteForm,
  MasterDataRouteAssignmentType,
  MasterDataRouteAssignmentTypeArr,
} from '../../../../../../entity/masterData.string.entity';
import './RouteForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: IMasterDataRouteForm;
  handleCancel(): void;
  handleSubmitRoute(route: IMasterDataRouteForm): void;
}
//#endregion

const RouteForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitRoute,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const isEdit = !!initialValues?.id;
  //#endregion

  //#region VALIDATION
  const masterDataRouteValidation = Yup.object().shape({
    corridor: Yup.string().when('assignmentType', {
      is: MasterDataRouteAssignmentType.IBK,
      then: Yup.string().required(t('Route Code is required.')),
      otherwise: Yup.string().required(t('Corridor is required.')),
    }),
    assignmentType: Yup.string().required(t('Assignment Type is required.')),
    name: Yup.string().required(t('Route Name is required.')),
    busStop: Yup.string().required(t('Bus Stop Name is required.')),
    busStopCode: Yup.string().required(t('Bus Stop Code is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="route-form">
      <div className="route-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={masterDataRouteValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitRoute}>
          {({
            handleSubmit,
            setFieldValue,
            values,
            isValid,
          }): React.ReactElement => (
            <>
              <div className="route-form__section">
                <div className="route-form__section--field">
                  <div className="input-label">
                    {values.assignmentType === MasterDataRouteAssignmentType.IBK
                      ? t('Route Code')
                      : t('Corridor Number')}
                  </div>
                  <Field
                    disabled={isLoading}
                    className="route-input"
                    name="corridor"
                    placeholder={
                      values.assignmentType ===
                      MasterDataRouteAssignmentType.IBK
                        ? t('Enter Route Code')
                        : t('Enter Corridor Number')
                    }
                    component={MTextField}
                  />
                </div>
                <div className="route-form__section--field">
                  <div className="input-label">{t('Tipe rute')}</div>
                  <Field
                    className="route-input"
                    name="assignmentType"
                    placeholder={t('Choose Assignment Type')}
                    options={MasterDataRouteAssignmentTypeArr}
                    handleChangeSelect={(busType: string) => {
                      setFieldValue('busType', busType, true);
                    }}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
                <div className="route-form__section--field">
                  <div className="input-label">{t('Route Name')}</div>
                  <Field
                    disabled={isLoading}
                    className="route-input"
                    name="name"
                    placeholder={t('Enter Route Name')}
                    component={MTextField}
                  />
                </div>
                <div className="route-form__section--field">
                  <div className="input-label">{t('Bus Stop')}</div>
                  <Field
                    disabled={isLoading}
                    className="route-input"
                    name="busStop"
                    placeholder={t('Enter Bus Stop Name')}
                    component={MTextField}
                  />
                </div>
                <div className="route-form__section--field">
                  <div className="input-label">{t('Bus Stop Code')}</div>
                  <Field
                    disabled={isLoading}
                    className="route-input"
                    name="busStopCode"
                    placeholder={t('Enter Bus Stop Code')}
                    component={MTextField}
                  />
                </div>
              </div>

              <div className="bus-form__form--action">
                <div className="bus-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="bus-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={!isEdit ? t('Add') : t('Edit')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default RouteForm;
