//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {Avatar, TableCell, TableRow} from '@mui/material';
// Utils
import t from '../../../../../../lang';
import {toInitial} from '../../../../../../utils';
// Components
import {MSwitch, MIconButton} from '../../../../../../components';
// Data
import {User, UserType} from '../../../../entity/user.string.entity';
// Assets
import './UserAccessManagementListTableItem.component.style.scss';
import {mdiDotsHorizontal} from '@mdi/js';
import {IconDelete, IconEdit} from '../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: User;
  typeUser: UserType;
  onViewItem(): void;
  onEditItem(): void;
  onDeleteItem(): void;
  onActivateItem(): void;
}
//#endregion
const UserAccessManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  typeUser,
  onViewItem,
  onEditItem,
  onDeleteItem,
  onActivateItem,
}: Props) => {
  //#region GENERAL
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  const initialAvatar: string = toInitial(data?.name);
  //#endregion

  return (
    <TableRow
      onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
        e.stopPropagation();
        onViewItem();
      }}
      className="user-access-management-list-table-item">
      <TableCell className="item-data__cell">{index}</TableCell>
      <TableCell className="item-data__cell">
        <div className="item-status-row">
          {data?.profilePicture ? (
            <Avatar
              className="navigation__icon--button-avatar-picture"
              alt={data?.name}
              src={data?.profilePicture}
            />
          ) : (
            <Avatar
              className="navigation__icon--button-avatar-picture"
              alt={data?.name}>
              {initialAvatar}
            </Avatar>
          )}
          <span>{data?.name || '-'}</span>
        </div>
      </TableCell>
      {typeUser === UserType.INTERNAL ? (
        <TableCell className="item-data__cell">{data?.nik || '-'}</TableCell>
      ) : (
        <TableCell className="item-data__cell">{data?.email || '-'}</TableCell>
      )}
      <TableCell className="item-data__cell">
        {data?.role.name || '-'}
      </TableCell>
      {typeUser === UserType.INTERNAL ? (
        <TableCell className="item-data__cell">
          {data?.department || '-'}
        </TableCell>
      ) : null}
      <TableCell className="item-data__cell">
        <div
          className="item-status-row"
          onClick={(e) => {
            e.stopPropagation();
            onActivateItem();
          }}>
          <MSwitch
            checked={data?.isActive}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              onActivateItem();
            }}
          />
          <span>{data?.isActive ? t('Active') : t('Inactive')}</span>
        </div>
      </TableCell>
      <TableCell align="right" className="item-data__cell table-action">
        {isOptionOpened ? (
          <div className="item-status-action">
            <MIconButton
              onClick={() => {
                onEditItem();
                setIsOptionOpened(false);
              }}
              icon={<IconEdit />}
            />
            <MIconButton
              onClick={() => {
                onDeleteItem();
                setIsOptionOpened(false);
              }}
              icon={<IconDelete />}
            />
          </div>
        ) : (
          <MIconButton
            onClick={() => setIsOptionOpened(true)}
            icon={mdiDotsHorizontal}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default UserAccessManagementListTableItem;
