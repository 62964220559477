import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {followUpKadepListAction} from './followUpKadepList.action';
import {
  FollowUpKadepListAction,
  FollowUpKadepListFetchPayload,
  FollowUpKadepListResponse,
  FollowUpKadepListSuccessPayload,
} from './followUpKadepList.type';

function* workerFetch(action: GenericAction<FollowUpKadepListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {sort, ...rest} = action.payload;
  try {
    const res: AxiosResponse<FollowUpKadepListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/follow-up/chief-dept/find-all`,
      {
        sort: `created_at,${sort?.toLowerCase()}`,
        ...rest,
      },
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        followUpKadepListAction.success(res.data as FollowUpKadepListResponse),
      );
      yield put(
        followUpKadepListAction.update(res.data as FollowUpKadepListResponse),
      );
    } else {
      yield put(followUpKadepListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(followUpKadepListAction.failed(e as CommonError));
  }
}

const followUpKadepListWatcher = [
  takeLatest(FollowUpKadepListAction.FETCH, workerFetch),
];

export default followUpKadepListWatcher;
