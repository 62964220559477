import {CommonError} from '../../../../config/constant';
import {FollowUp} from '../../entity/followUp.string.entity';

export const FollowUpDetailAction = {
  FETCH: 'FOLLOW_UP_DETAIL_FETCH',
  SUCCESS: 'FOLLOW_UP_DETAIL_SUCCESS',
  FAILED: 'FOLLOW_UP_DETAIL_FAILED',
  RESET: 'FOLLOW_UP_DETAIL_RESET',
  UPDATE: 'FOLLOW_UP_DETAIL_UPDATE',
};

export interface FollowUpDetailInitialState {
  followUpDetailLoading: boolean;
  followUpDetailError?: FollowUpDetailFailedPayload;
  followUpDetailParams?: FollowUpDetailFetchPayload;
  followUpDetailResponse?: FollowUpDetailSuccessPayload;
}

export type FollowUpDetailResponse = {
  status: string;
  data: FollowUp;
};

export type FollowUpDetailFailedPayload = CommonError;

export type FollowUpDetailSuccessPayload = FollowUpDetailResponse;

export interface FollowUpDetailFetchPayload {
  id?: string;
}
