import {CommonError} from '../../../../../../config/constant';

export const MasterDataAssignmentLetterSetActiveAction = {
  FETCH: 'MASTER_DATA_ASSIGNMENT_LETTER_SET_ACTIVE_FETCH',
  SUCCESS: 'MASTER_DATA_ASSIGNMENT_LETTER_SET_ACTIVE_SUCCESS',
  FAILED: 'MASTER_DATA_ASSIGNMENT_LETTER_SET_ACTIVE_FAILED',
  RESET: 'MASTER_DATA_ASSIGNMENT_LETTER_SET_ACTIVE_RESET',
};

export interface MasterDataAssignmentLetterSetActiveInitialState {
  masterDataAssignmentLetterSetActiveLoading: boolean;
  masterDataAssignmentLetterSetActiveParams?: MasterDataAssignmentLetterSetActiveFetchPayload;
  masterDataAssignmentLetterSetActiveError?: MasterDataAssignmentLetterSetActiveFailedPayload;
  masterDataAssignmentLetterSetActiveResponse?: MasterDataAssignmentLetterSetActiveSuccessPayload;
}

export type MasterDataAssignmentLetterSetActiveFetchPayload = {id: string};

export type MasterDataAssignmentLetterSetActiveFailedPayload = CommonError;

export interface MasterDataAssignmentLetterSetActiveSuccessPayload {}
