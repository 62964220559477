import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userAccessEditInitialState} from './userAccessEdit.reducer';
import {
  UserAccessEditAction,
  UserAccessEditFailedPayload,
  UserAccessEditFetchPayload,
  UserAccessEditSuccessPayload,
} from './userAccessEdit.type';

export const userAccessEditHandler = {
  [UserAccessEditAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserAccessEditFetchPayload>,
  ): UserState => ({
    ...state,
    userAccessEditLoading: true,
    userAccessEditParams: action.payload,
    action: UserAccessEditAction.FETCH,
  }),
  [UserAccessEditAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserAccessEditSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccessEditParams: undefined,
    userAccessEditLoading: false,
    userAccessEditResponse: action.payload,
    action: UserAccessEditAction.SUCCESS,
  }),
  [UserAccessEditAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserAccessEditFailedPayload>,
  ): UserState => ({
    ...state,
    userAccessEditLoading: false,
    userAccessEditError: action.payload,
    action: UserAccessEditAction.FAILED,
  }),
  [UserAccessEditAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userAccessEditInitialState,
    action: UserAccessEditAction.RESET,
  }),
};
