import {
  MasterDataDirectorateOptionListAction,
  MasterDataDirectorateOptionListSuccessPayload,
  MasterDataDirectorateOptionListFailedPayload,
  MasterDataDirectorateOptionListFetchPayload,
} from './masterDataDirectorateOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDirectorateOptionListInitialState} from './masterDataDirectorateOptionList.reducer';

export const masterDataDirectorateOptionListHandler = {
  [MasterDataDirectorateOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDirectorateOptionListLoading: true,
      masterDataDirectorateOptionListParams: action.payload,
      action: MasterDataDirectorateOptionListAction.FETCH,
    };
  },
  [MasterDataDirectorateOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateOptionListLoading: false,
    masterDataDirectorateOptionListResponse: action.payload,
    action: MasterDataDirectorateOptionListAction.SUCCESS,
  }),
  [MasterDataDirectorateOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateOptionListLoading: false,
    masterDataDirectorateOptionListData: [
      ...state.masterDataDirectorateOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataDirectorateOptionListAction.UPDATE,
  }),
  [MasterDataDirectorateOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateOptionListLoading: false,
    masterDataDirectorateOptionListError: action.payload,
    action: MasterDataDirectorateOptionListAction.FAILED,
  }),
  [MasterDataDirectorateOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDirectorateOptionListInitialState,
    action: MasterDataDirectorateOptionListAction.RESET,
  }),
};
