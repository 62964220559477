import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataReviewIndicatorOptionListAction,
  MasterDataReviewIndicatorOptionListFailedPayload,
  MasterDataReviewIndicatorOptionListFetchPayload,
  MasterDataReviewIndicatorOptionListSuccessPayload,
  MasterDataReviewIndicatorOptionListUpdatePayload,
} from './masterDataReviewIndicatorOptionList.type';

export const masterDataReviewIndicatorOptionListAction = {
  fetch: createAction<MasterDataReviewIndicatorOptionListFetchPayload>(
    MasterDataReviewIndicatorOptionListAction.FETCH,
  ),
  success: createAction<MasterDataReviewIndicatorOptionListSuccessPayload>(
    MasterDataReviewIndicatorOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataReviewIndicatorOptionListFailedPayload>(
    MasterDataReviewIndicatorOptionListAction.FAILED,
  ),
  update: createAction<MasterDataReviewIndicatorOptionListUpdatePayload>(
    MasterDataReviewIndicatorOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataReviewIndicatorOptionListAction.RESET),
};
