import {CommonError} from '../../../../config/constant';
import {WorkReportFormData} from '../../entity/workReport.string.entity';

export const WorkReportCreateAction = {
  FETCH: 'WORK_REPORT_CREATE_FETCH',
  SUCCESS: 'WORK_REPORT_CREATE_SUCCESS',
  FAILED: 'WORK_REPORT_CREATE_FAILED',
  RESET: 'WORK_REPORT_CREATE_RESET',
};

export interface WorkReportCreateInitialState {
  workReportCreateLoading: boolean;
  workReportCreateParams?: WorkReportCreateFetchPayload;
  workReportCreateError?: WorkReportCreateFailedPayload;
  workReportCreateResponse?: WorkReportCreateSuccessPayload;
}

export type WorkReportCreateResponse = {
  status: string;
  info?: number;
  data: [];
};

export type WorkReportCreateFetchPayload = Omit<WorkReportFormData, 'id'>;

export type WorkReportCreateFailedPayload = CommonError;

export type WorkReportCreateSuccessPayload = WorkReportCreateResponse;
