import {
  MasterDataDepartmentOptionListAction,
  MasterDataDepartmentOptionListSuccessPayload,
  MasterDataDepartmentOptionListFailedPayload,
  MasterDataDepartmentOptionListFetchPayload,
} from './masterDataDepartmentOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDepartmentOptionListInitialState} from './masterDataDepartmentOptionList.reducer';

export const masterDataDepartmentOptionListHandler = {
  [MasterDataDepartmentOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDepartmentOptionListLoading: true,
      masterDataDepartmentOptionListParams: action.payload,
      action: MasterDataDepartmentOptionListAction.FETCH,
    };
  },
  [MasterDataDepartmentOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentOptionListLoading: false,
    masterDataDepartmentOptionListResponse: action.payload,
    action: MasterDataDepartmentOptionListAction.SUCCESS,
  }),
  [MasterDataDepartmentOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentOptionListLoading: false,
    masterDataDepartmentOptionListData: [
      ...state.masterDataDepartmentOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataDepartmentOptionListAction.UPDATE,
  }),
  [MasterDataDepartmentOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentOptionListLoading: false,
    masterDataDepartmentOptionListError: action.payload,
    action: MasterDataDepartmentOptionListAction.FAILED,
  }),
  [MasterDataDepartmentOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDepartmentOptionListInitialState,
    action: MasterDataDepartmentOptionListAction.RESET,
  }),
};
