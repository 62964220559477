/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import moment, {Moment} from 'moment';
// Utils
import t from '../../../../../../../lang';
import {AppState} from '../../../../../../../config/Interface';
import {DashboardState} from '../../../../../usecases/dashboard.reducer';
import {actionHandler} from '../../../../../../wrapper/utils';
import {dashboardOverviewBestSectionAction} from '../../../../../usecases/dashboard.action';
import {DashboardOverviewBestSectionAction} from '../../../../../usecases/dashboard.type';
import {wrapperNotifyAction} from '../../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {
  Modal,
  MMonthpicker,
  MYearpicker,
} from '../../../../../../../components';
import {DashboardLeaderBoard} from '../../../../../components';
import {dataTable} from '../../../../../components/DashboardLeaderBoard/DashboardLeaderBoard.component';
// Assets
import './DashboardOverviewBoardList.scss';
//#endregion

const DashboardOverviewBoardList: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();

  const dashboard: DashboardState = useSelector(
    (state: AppState) => state.dashboard,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  //#region GENERAL YEAR FILTER
  const [selectedYearFilter, setSelectedYearFilter] = useState<
    Moment | string | null
  >(moment().year(new Date().getFullYear()));
  //#endregion
  //#region GENERAL MONTH FILTER
  const [selectedMonthFilter, setSelectedMonthFilter] = useState<
    Moment | string | null
  >(moment().month(new Date().getMonth()));
  //#endregion
  //#endregion

  //#region BEST STOP
  const [bestBusStop, setBestBusStop] = useState<dataTable[]>([]);
  const [bestStopShowDetail, setBestStopShowDetail] = useState<boolean>(false);
  //#endregion
  //#region BEST OPERATOR
  const [bestBusOperator, setBestBusOperator] = useState<dataTable[]>([]);
  const [bestOperatorShowDetail, setBestOperatorShowDetail] = useState<boolean>(
    false,
  );
  //#endregion

  //#region FETCH OVERVIEW BEST
  const fetchOverviewBest = useCallback(
    (month: number, year: number) => {
      dispatch(dashboardOverviewBestSectionAction.fetch({month, year}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(dashboardOverviewBestSectionAction.reset());
    fetchOverviewBest(
      Number((selectedMonthFilter as Moment).format('MM')),
      Number((selectedYearFilter as Moment).format('YYYY')),
    );
    return () => {
      dispatch(dashboardOverviewBestSectionAction.reset());
    };
  }, [dispatch, fetchOverviewBest, selectedMonthFilter, selectedYearFilter]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const dashboardOverviewBestActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(DashboardOverviewBestSectionAction.SUCCESS, (): void => {
            if (dashboard?.dashboardOverviewBestSectionResponse?.data) {
              //#region BEST BUS STOP
              const bestBusStop =
                dashboard?.dashboardOverviewBestSectionResponse?.data
                  .bestBusStop;
              const formattedBestBusStop = bestBusStop.map((item) => ({
                name: item.name,
                value: `${item.percent ? Number(item.percent).toFixed(2) : 0}%`,
              }));
              setBestBusStop(formattedBestBusStop);
              //#endregion
              //#region BEST OPERATOR
              const bestOperator =
                dashboard?.dashboardOverviewBestSectionResponse?.data
                  .bestOperator;
              const formattedBestBusOperator = bestOperator.map((item) => ({
                name: item.name,
                value: `${item.percent ? Number(item.percent).toFixed(2) : 0}%`,
              }));
              setBestBusOperator(formattedBestBusOperator);
              //#endregion
            }
          })
          .addCase(DashboardOverviewBestSectionAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(dashboardOverviewBestSectionAction.reset());
          });
      });
    },
    [dashboard?.dashboardOverviewBestSectionResponse?.data, dispatch],
  );
  useEffect(() => {
    dashboardOverviewBestActionHandler(dashboard.action);
  }, [dashboard.action, dashboardOverviewBestActionHandler]);
  //#endregion

  return (
    <>
      <div className="dashboard-overview-board-list">
        <div className="dashboard-overview-board-list__filter">
          <MYearpicker
            inputValue={selectedYearFilter}
            maxDate={moment().endOf('year')}
            handleChange={(year) => setSelectedYearFilter(year)}
          />
          <MMonthpicker
            inputValue={selectedMonthFilter}
            handleChange={(month) => setSelectedMonthFilter(month)}
          />
        </div>
        <Grid container spacing={3}>
          {!!permissions?.dashboardOverviewBestBusStopChart && (
            <Grid item xs={6} key="best-stop-leader-board">
              <div className="dashboard-overview-board-list__column">
                <div className="dashboard-overview-board-list__card">
                  <DashboardLeaderBoard
                    title={t('Best Stop')}
                    data={bestBusStop.slice(0, 5)}
                    onViewAll={() => setBestStopShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
          {!!permissions?.dashboardOverviewBestOperatorChart && (
            <Grid item xs={6} key="best-operator-leader-board">
              <div className="dashboard-overview-board-list__column">
                <div className="dashboard-overview-board-list__card">
                  <DashboardLeaderBoard
                    title={t('Best Operator')}
                    data={bestBusOperator.slice(0, 5)}
                    onViewAll={() => setBestOperatorShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      {bestStopShowDetail && (
        <Modal
          onClose={() => setBestStopShowDetail(false)}
          isOpen={!!bestStopShowDetail}
          maxWidth="sm">
          <DashboardLeaderBoard title={t('Best Stop')} data={bestBusStop} />
        </Modal>
      )}

      {bestOperatorShowDetail && (
        <Modal
          onClose={() => setBestOperatorShowDetail(false)}
          isOpen={!!bestOperatorShowDetail}
          maxWidth="sm">
          <DashboardLeaderBoard
            title={t('Best Operator')}
            data={bestBusOperator}
          />
        </Modal>
      )}
    </>
  );
};

export default DashboardOverviewBoardList;
