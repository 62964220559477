import * as React from 'react';

const IconCalendar = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.41 3.525l.002.75c2.755.216 4.574 2.093 4.577 4.971L21 17.672c.004 3.139-1.968 5.07-5.128 5.075l-7.72.01c-3.14.004-5.137-1.973-5.141-5.12L3 9.308c-.004-2.897 1.752-4.77 4.506-5.022v-.75a.746.746 0 01.758-.77.747.747 0 01.762.768v.7l5.865-.008v-.7a.747.747 0 01.759-.77.753.753 0 01.76.768zM4.522 9.618l14.949-.02v-.35c-.043-2.148-1.121-3.276-3.056-3.444v.77c0 .43-.334.77-.758.77a.752.752 0 01-.762-.768v-.81l-5.865.008v.809c0 .43-.324.77-.759.77a.752.752 0 01-.76-.768l-.002-.77c-1.925.193-2.99 1.325-2.988 3.492l.001.311zm10.719 4.543v.011c.01.46.385.809.84.799a.823.823 0 00.789-.852.83.83 0 00-.82-.798.828.828 0 00-.81.84zm.815 4.488a.848.848 0 01-.821-.849.833.833 0 01.809-.851h.01c.463 0 .84.378.84.848a.845.845 0 01-.838.852zm-4.883-4.472c.02.46.396.819.85.799a.823.823 0 00.78-.861.816.816 0 00-.821-.799c-.454.02-.81.401-.809.861zm.854 4.427a.823.823 0 01-.85-.799c0-.46.354-.84.809-.86a.815.815 0 01.82.797c.02.461-.335.84-.779.862zm-4.922-4.392c.02.46.396.82.85.799.445-.02.8-.401.778-.861a.815.815 0 00-.82-.799.852.852 0 00-.808.861zm.854 4.397a.822.822 0 01-.85-.799.853.853 0 01.809-.86.815.815 0 01.82.798c.021.46-.333.84-.779.86z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconCalendar;
