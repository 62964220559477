/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../usecases/user.reducer';
import {
  UserAccessCreateAction,
  UserAccessDeleteAction,
  UserAccessEditAction,
  UserAccessListAction,
  UserAccessSetActiveAction,
} from '../../usecases/user.type';
import {userAccessCreateAction} from '../../usecases/userAccessCreate/userAccessCreate.action';
import {userAccessDeleteAction} from '../../usecases/userAccessDelete/userAccessDelete.action';
import {userAccessEditAction} from '../../usecases/userAccessEdit/userAccessEdit.action';
import {userAccessListAction} from '../../usecases/userAccessList/userAccessList.action';
import {userAccessSetActiveAction} from '../../usecases/userAccessSetActive/userAccessSetActive.action';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../config/constant';
// Components
import {UserManagementDetail} from '..';
import {
  AlertDialog,
  MButton,
  MDropdown,
  Modal,
  MSearchInput,
  MTablePagination,
} from '../../../../components';
import UserAccessManagementUserFormContainer from '../UserAccessManagementUserFormContainer';
import {UserAccessManagementList} from './components';
// Data
import {User, UserOrderByType, UserType} from '../../entity/user.string.entity';
// Assets
import './UserAccessManagementUserSection.scss';
import {IllustrationTrashImage} from '../../../../assets/illustrations';
//#endregion

const UserAccessManagementUserSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddUser, setAddUser] = useState(false);
  const [isViewUser, setIsViewUser] = useState<User | undefined>(undefined);
  const [isEditUser, setIsEditUser] = useState<User | undefined>(undefined);
  const [isDeleteUser, setIsDeleteUser] = useState<User | undefined>(undefined);
  const [typeUser, setTypeUser] = useState<UserType>(UserType.INTERNAL);
  const [isActivateUser, setIsActivateUser] = useState<User | undefined>(
    undefined,
  );
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const user: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const isLoadingAddUser = user.userAccessCreateLoading;
  const isLoadingEditUser = user.userAccessEditLoading;
  const isLoadingDeleteUser = user.userAccessDeleteLoading;
  const isLoadingActivationUser = user.userAccessSetActiveLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [userAccessListPage, setUserAccessListPage] = useState<number>(0);
  const [userAccessListCount, setUserAccessListCount] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setUserAccessListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setUserAccessListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<UserOrderByType>('name');

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: UserOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH USER
  const fetchUserList = useCallback(
    (type: UserType, page?: number, search?: string, size?: number) => {
      dispatch(userAccessListAction.fetch({type, page, search, size}));
    },
    [dispatch],
  );
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(userAccessDeleteAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE SET ACTIVE
  const handleActivation = (id: string) => {
    dispatch(userAccessSetActiveAction.fetch({id}));
  };
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(userAccessListAction.reset());
    fetchUserList(typeUser, userAccessListPage, searchValue, rowsPerPage);

    return () => {
      dispatch(userAccessListAction.reset());
    };
  }, [
    dispatch,
    fetchUserList,
    typeUser,
    userAccessListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  //#endregion

  //#region ACTION HANDLER
  const userActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserAccessListAction.UPDATE, (): void => {
            if (user?.userAccessListResponse?.data?.totalElements) {
              setUserAccessListCount(
                user?.userAccessListResponse?.data?.totalElements,
              );
            }
          })
          .addCase(UserAccessListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userAccessListAction.reset());
          })
          .addCase(UserAccessCreateAction.SUCCESS, (): void => {
            setAddUser(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserAccessCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userAccessCreateAction.reset());
          })
          .addCase(UserAccessEditAction.SUCCESS, (): void => {
            setIsEditUser(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserAccessEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userAccessEditAction.reset());
          })
          .addCase(UserAccessDeleteAction.SUCCESS, (): void => {
            setIsDeleteUser(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserAccessDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userAccessDeleteAction.reset());
          })
          .addCase(UserAccessSetActiveAction.SUCCESS, (): void => {
            setIsActivateUser(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('User activation update success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(UserAccessSetActiveAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('User activation update failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userAccessSetActiveAction.reset());
          });
      });
    },
    [dispatch, user?.userAccessListResponse?.data?.totalElements, refresh],
  );
  useEffect(() => {
    userActionHandler(user.action);
  }, [user.action, userActionHandler]);
  //#endregion

  return (
    <>
      <div className="user-access-management-user-section">
        <div className="user-access-management-user-section__header">
          <div className="user-access-management-user-section__header--action">
            <MSearchInput
              inputClassName="user-access-management-user-section__header--search"
              inputValue={searchValue}
              placeholder={t('Search User Access')}
              setInputValue={(search: string) => {
                setUserAccessListPage(0);
                setSearchValue(search);
              }}
            />

            <MDropdown
              placeholder={t('User Type')}
              inputValue={typeUser}
              options={[
                {label: 'Internal', value: UserType.INTERNAL},
                {label: 'External', value: UserType.EXTERNAL},
              ]}
              handleChangeSelect={(e) => {
                setUserAccessListPage(0);
                const val = e.toUpperCase();
                setTypeUser(UserType[val as keyof typeof UserType]);
              }}
            />
          </div>

          <div className="user-access-management-user-section__header--add-action">
            <MButton
              buttonLabel={t('Add')}
              handleClick={() => setAddUser(true)}
            />
          </div>
        </div>
        <UserAccessManagementList
          userAccessList={user.userAccessListData}
          typeUser={typeUser}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          onViewUser={(_user: User) => setIsViewUser(_user)}
          onEditUser={(_user: User) => setIsEditUser(_user)}
          onDeleteUser={(_user: User) => setIsDeleteUser(_user)}
          onActivateUser={(_user: User) => setIsActivateUser(_user)}
        />
        <MTablePagination
          className="user-access-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={userAccessListCount}
          rowsPerPage={rowsPerPage}
          page={userAccessListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddUser(false)}
        title={t('Add User Access')}
        isOpen={isAddUser}>
        <UserAccessManagementUserFormContainer
          typeUser={typeUser}
          isLoading={isLoadingAddUser}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isViewUser && (
        <Modal onClose={() => setIsViewUser(undefined)} isOpen={!!isViewUser}>
          <UserManagementDetail
            onEdit={() => {
              setIsEditUser(isViewUser);
              setIsViewUser(undefined);
            }}
            data={isViewUser}
            typeUser={typeUser}
            onCancel={() => setIsViewUser(undefined)}
          />
        </Modal>
      )}

      {isEditUser && (
        <Modal
          onClose={() => setIsEditUser(undefined)}
          isOpen={!!isEditUser}
          title={t('Edit User Access')}>
          <UserAccessManagementUserFormContainer
            data={isEditUser}
            typeUser={typeUser}
            isLoading={isLoadingEditUser}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteUser && (
        <AlertDialog
          onClose={() => setIsDeleteUser(undefined)}
          onCancel={() => setIsDeleteUser(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteUser.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isLoading={isLoadingDeleteUser}
          isOpen={!!isDeleteUser}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isActivateUser && (
        <AlertDialog
          onClose={() => setIsActivateUser(undefined)}
          onCancel={() => setIsActivateUser(undefined)}
          onConfirm={() => handleActivation(isActivateUser.id)}
          cancelText={t('Cancel')}
          confirmText={
            isActivateUser.isActive ? t('Yes, Deactivate') : t('Yes, Activate')
          }
          isOpen={!!isActivateUser}
          isLoading={isLoadingActivationUser}
          title={t('Are you sure?')}
          isReverseButton={true}
          description={
            isActivateUser.isActive
              ? t('The selected user will be deactivated')
              : t('The selected user will be activated')
          }></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddUser(false)
              : setIsEditUser(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default UserAccessManagementUserSection;
