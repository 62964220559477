import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataReviewIndicator} from '../../../../entity/masterData.string.entity';

export const MasterDataReviewIndicatorListAction = {
  FETCH: 'MASTER_DATA_REVIEW_INDICATOR_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_REVIEW_INDICATOR_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_REVIEW_INDICATOR_LIST_FAILED',
  RESET: 'MASTER_DATA_REVIEW_INDICATOR_LIST_RESET',
  UPDATE: 'MASTER_DATA_REVIEW_INDICATOR_LIST_UPDATE',
};

export interface MasterDataReviewIndicatorListInitialState {
  masterDataReviewIndicatorListLoading: boolean;
  masterDataReviewIndicatorListData: MasterDataReviewIndicator[];
  masterDataReviewIndicatorListError?: MasterDataReviewIndicatorListFailedPayload;
  masterDataReviewIndicatorListParams?: MasterDataReviewIndicatorListFetchPayload;
  masterDataReviewIndicatorListResponse?: MasterDataReviewIndicatorListSuccessPayload;
}

export type MasterDataReviewIndicatorListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataReviewIndicator[]>;
};

export type MasterDataReviewIndicatorListFailedPayload = CommonError;

export type MasterDataReviewIndicatorListSuccessPayload = MasterDataReviewIndicatorListResponse;

export type MasterDataReviewIndicatorListUpdatePayload = MasterDataReviewIndicatorListResponse;

export interface MasterDataReviewIndicatorListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
