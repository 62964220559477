import {
  NotificationListAction,
  NotificationListSuccessPayload,
  NotificationListFailedPayload,
  NotificationListFetchPayload,
} from './notificationList.type';
import {NotificationState} from '../notification.reducer';
import {GenericAction} from '../../../../config/constant';
import {notificationListInitialState} from './notificationList.reducer';

export const notificationListHandler = {
  [NotificationListAction.FETCH]: (
    state: NotificationState,
    action: GenericAction<NotificationListFetchPayload>,
  ): NotificationState => {
    return {
      ...state,
      notificationListLoading: true,
      notificationListParams: action.payload,
      action: NotificationListAction.FETCH,
    };
  },
  [NotificationListAction.SUCCESS]: (
    state: NotificationState,
    action: GenericAction<NotificationListSuccessPayload>,
  ): NotificationState => ({
    ...state,
    notificationListLoading: false,
    notificationListResponse: action.payload,
    action: NotificationListAction.SUCCESS,
  }),
  [NotificationListAction.UPDATE]: (
    state: NotificationState,
    action: GenericAction<NotificationListSuccessPayload>,
  ): NotificationState => ({
    ...state,
    notificationListLoading: false,
    notificationListData: [
      ...state.notificationListData,
      ...action.payload.data.content,
    ],
    action: NotificationListAction.UPDATE,
  }),
  [NotificationListAction.FAILED]: (
    state: NotificationState,
    action: GenericAction<NotificationListFailedPayload>,
  ): NotificationState => ({
    ...state,
    notificationListLoading: false,
    notificationListError: action.payload,
    action: NotificationListAction.FAILED,
  }),
  [NotificationListAction.RESET]: (
    state: NotificationState,
  ): NotificationState => ({
    ...state,
    ...notificationListInitialState,
    action: NotificationListAction.RESET,
  }),
};
