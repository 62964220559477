import {
  MasterDataRuteListAction,
  MasterDataRuteListSuccessPayload,
  MasterDataRuteListFailedPayload,
  MasterDataRuteListFetchPayload,
} from './masterDataRuteList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataRuteListInitialState} from './masterDataRuteList.reducer';

export const masterDataRuteListHandler = {
  [MasterDataRuteListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRuteListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataRuteListLoading: true,
      masterDataRuteListParams: action.payload,
      action: MasterDataRuteListAction.FETCH,
    };
  },
  [MasterDataRuteListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRuteListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRuteListLoading: false,
    masterDataRuteListResponse: action.payload,
    action: MasterDataRuteListAction.SUCCESS,
  }),
  [MasterDataRuteListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRuteListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRuteListLoading: false,
    masterDataRuteListData: [
      ...state.masterDataRuteListData,
      ...action.payload.data.content,
    ],
    action: MasterDataRuteListAction.UPDATE,
  }),
  [MasterDataRuteListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRuteListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRuteListLoading: false,
    masterDataRuteListError: action.payload,
    action: MasterDataRuteListAction.FAILED,
  }),
  [MasterDataRuteListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataRuteListInitialState,
    action: MasterDataRuteListAction.RESET,
  }),
};
