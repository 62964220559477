import {createAction} from '@reduxjs/toolkit';
import {
  WorkReportExportAllAction,
  WorkReportExportAllFailedPayload,
  WorkReportExportAllFetchPayload,
  WorkReportExportAllSuccessPayload,
} from './workReportExportAll.type';

export const workReportExportAllAction = {
  fetch: createAction<WorkReportExportAllFetchPayload>(
    WorkReportExportAllAction.FETCH,
  ),
  success: createAction<WorkReportExportAllSuccessPayload>(
    WorkReportExportAllAction.SUCCESS,
  ),
  failed: createAction<WorkReportExportAllFailedPayload>(
    WorkReportExportAllAction.FAILED,
  ),
  reset: createAction(WorkReportExportAllAction.RESET),
};
