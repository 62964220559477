import {CommonError} from '../../../../../../config/constant';

export const MasterDataDepartmentCreateAction = {
  FETCH: 'MASTER_DATA_DEPARTMENT_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_DEPARTMENT_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_DEPARTMENT_CREATE_FAILED',
  RESET: 'MASTER_DATA_DEPARTMENT_CREATE_RESET',
};

export interface MasterDataDepartmentCreateInitialState {
  masterDataDepartmentCreateLoading: boolean;
  masterDataDepartmentCreateParams?: MasterDataDepartmentCreateFetchPayload;
  masterDataDepartmentCreateError?: MasterDataDepartmentCreateFailedPayload;
  masterDataDepartmentCreateResponse?: MasterDataDepartmentCreateSuccessPayload;
}

export interface MasterDataDepartmentCreateFetchPayload {
  directorateId: string;
  divisionId: string;
  data: {
    name: string;
  }[];
}

export type MasterDataDepartmentCreateFailedPayload = CommonError;

export interface MasterDataDepartmentCreateSuccessPayload {}
