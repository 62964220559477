import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {post} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../user.reducer';
import {userAccessCreateAction} from './userAccessCreate.action';
import {
  UserAccessCreateAction,
  UserAccessCreateFetchPayload,
  UserAccessCreateSuccessPayload,
} from './userAccessCreate.type';

function* workerFetch(action: GenericAction<UserAccessCreateFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);

  try {
    const res: AxiosResponse<UserAccessCreateSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/user/save`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(userAccessCreateAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userAccessCreateAction.failed(e.response.data.payload));
  }
}

const userAccessCreateWatcher = [
  takeLatest(UserAccessCreateAction.FETCH, workerFetch),
];

export default userAccessCreateWatcher;
