//#region DASHBOARD OVERVIEW
import {dashboardOverviewSetProfitHandler} from './section/overview/dashboardOverviewSetProfit/dashboardOverviewSetProfit.handler';
import {dashboardOverviewBestSectionHandler} from './section/overview/dashboardOverviewBestSection/dashboardOverviewBestSection.handler';
import {dashboardOverviewChartSectionHandler} from './section/overview/dashboardOverviewChartSection/dashboardOverviewChartSection.handler';
import {dashboardOverviewExportAchievementFinalMonthlyHandler} from './section/overview/dashboardOverviewExportAchievementFinalMonthly/dashboardOverviewExportAchievementFinalMonthly.handler';
import {dashboardOverviewExportAchievementFinalQuarterHandler} from './section/overview/dashboardOverviewExportAchievementFinalQuarter/dashboardOverviewExportAchievementFinalQuarter.handler';

//#endregion
//#region DASHBOARD SPM
import {dashboardSPMChartSectionHandler} from './section/spm/dashboardSPMChartSection/dashboardSPMChartSection.handler';
//#endregion
//#region DASHBOARD DEPARTMENT
import {dashboardTabDepartmentListHandler} from './section/department/dashboardTabDepartmentList/dashboardTabDepartmentList.handler';
import {dashboardDepartmentBestSectionHandler} from './section/department/dashboardDepartmentBestSection/dashboardDepartmentBestSection.handler';
import {dashboardDepartmentChartSectionHandler} from './section/department/dashboardDepartmentChartSection/dashboardDepartmentChartSection.handler';
//#endregion

export default {
  //#region DASHBOARD SPM
  ...dashboardSPMChartSectionHandler,
  //#endregion
  //#region DASHBOARD OVERVIEW
  ...dashboardOverviewSetProfitHandler,
  ...dashboardOverviewBestSectionHandler,
  ...dashboardOverviewChartSectionHandler,
  ...dashboardOverviewExportAchievementFinalMonthlyHandler,
  ...dashboardOverviewExportAchievementFinalQuarterHandler,
  //#endregion
  //#region DASHBOARD DEPARTMENT
  ...dashboardTabDepartmentListHandler,
  ...dashboardDepartmentBestSectionHandler,
  ...dashboardDepartmentChartSectionHandler,
  //#endregion
};
