import {CommonError} from '../../../../config/constant';
import {RoleOverview} from '../../entity/user.string.entity';

export const UserRoleAccessListAction = {
  FETCH: 'USER_ROLE_ACCESS_LIST_FETCH',
  SUCCESS: 'USER_ROLE_ACCESS_LIST_SUCCESS',
  FAILED: 'USER_ROLE_ACCESS_LIST_FAILED',
  RESET: 'USER_ROLE_ACCESS_LIST_RESET',
  UPDATE: 'USER_ROLE_ACCESS_LIST_UPDATE',
};

export interface UserRoleAccessListInitialState {
  userRoleAccessListLoading: boolean;
  userRoleAccessListData: RoleOverview[];
  userRoleAccessListError?: UserRoleAccessListFailedPayload;
  userRoleAccessListParams?: UserRoleAccessListFetchPayload;
  userRoleAccessListResponse?: UserRoleAccessListSuccessPayload;
}

export type UserRoleAccessListResponse = {
  status: string;
  data: RoleOverview[];
};

export type UserRoleAccessListFailedPayload = CommonError;

export type UserRoleAccessListSuccessPayload = UserRoleAccessListResponse;

export type UserRoleAccessListUpdatePayload = UserRoleAccessListResponse;

export interface UserRoleAccessListFetchPayload {
  search?: string;
}
