import {
  MasterDataFormControllerListAction,
  MasterDataFormControllerListSuccessPayload,
  MasterDataFormControllerListFailedPayload,
  MasterDataFormControllerListFetchPayload,
} from './masterDataFormControllerList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataFormControllerListInitialState} from './masterDataFormControllerList.reducer';

export const masterDataFormControllerListHandler = {
  [MasterDataFormControllerListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataFormControllerListLoading: true,
      masterDataFormControllerListParams: action.payload,
      action: MasterDataFormControllerListAction.FETCH,
    };
  },
  [MasterDataFormControllerListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerListLoading: false,
    masterDataFormControllerListResponse: action.payload,
    action: MasterDataFormControllerListAction.SUCCESS,
  }),
  [MasterDataFormControllerListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerListLoading: false,
    masterDataFormControllerListData: [
      ...state.masterDataFormControllerListData,
      ...action.payload.data.content,
    ],
    action: MasterDataFormControllerListAction.UPDATE,
  }),
  [MasterDataFormControllerListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerListLoading: false,
    masterDataFormControllerListError: action.payload,
    action: MasterDataFormControllerListAction.FAILED,
  }),
  [MasterDataFormControllerListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataFormControllerListInitialState,
    action: MasterDataFormControllerListAction.RESET,
  }),
};
