import * as React from 'react';

const IconArrowUpCircle = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.height || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle
      cx={12}
      cy={12}
      r={10}
      fill={props.fill || '#31B057'}
      fillOpacity={0.2}
    />
    <circle
      cx={12}
      cy={12.001}
      r={6.667}
      fill={props.fill || '#31B057'}
      fillOpacity={0.2}
    />
    <circle cx={12} cy={11.999} r={3.333} fill={props.fill || '#31B057'} />
  </svg>
);

export default IconArrowUpCircle;
