import {createReducer} from '@reduxjs/toolkit';

import {userLoginInitialState} from './userLogin/userLogin.reducer';
import {userDataInitialState} from './userData/userData.reducer';
import {userForgetPasswordInitialState} from './userForgetPassword/userForgetPassword.reducer';
import {userOTPPasswordInitialState} from './userOTPPassword/userOTPPassword.reducer';
import {userChangePasswordInitialState} from './userChangePassword/userChangePassword.reducer';
import {userAccessCreateInitialState} from './userAccessCreate/userAccessCreate.reducer';
import {userAccessDeleteInitialState} from './userAccessDelete/userAccessDelete.reducer';
import {userAccessEditInitialState} from './userAccessEdit/userAccessEdit.reducer';
import {userAccessListInitialState} from './userAccessList/userAccessList.reducer';
import {userAccessSetActiveInitialState} from './userAccessSetActive/userAccessSetActive.reducer';
import {userRoleAccessEditInitialState} from './userRoleAccessEdit/userRoleAccessEdit.reducer';
import {userRoleAccessDeleteInitialState} from './userRoleAccessDelete/userRoleAccessDelete.reducer';
import {userRoleAccessListInitialState} from './userRoleAccessList/userRoleAccessList.reducer';
import {userAccountEditInitialState} from './userAccountEdit/userAccountEdit.reducer';
import {userAccountDetailInitialState} from './userAccountDetail/userAccountDetail.reducer';
import {userRoleAccessCreateInitialState} from './userRoleAccessCreate/userRoleAccessCreate.reducer';

import {UserLoginInitialState} from './userLogin/userLogin.type';
import {UserDataInitialState} from './userData/userData.type';
import {UserForgetPasswordInitialState} from './userForgetPassword/userForgetPassword.type';
import {UserOTPPasswordInitialState} from './userOTPPassword/userOTPPassword.type';
import {UserChangePasswordInitialState} from './userChangePassword/userChangePassword.type';
import {UserAccessCreateInitialState} from './userAccessCreate/userAccessCreate.type';
import {UserAccessDeleteInitialState} from './userAccessDelete/userAccessDelete.type';
import {UserAccessEditInitialState} from './userAccessEdit/userAccessEdit.type';
import {UserAccessListInitialState} from './userAccessList/userAccessList.type';
import {UserAccessSetActiveInitialState} from './userAccessSetActive/userAccessSetActive.type';
import {UserRoleAccessEditInitialState} from './userRoleAccessEdit/userRoleAccessEdit.type';
import {UserRoleAccessDeleteInitialState} from './userRoleAccessDelete/userRoleAccessDelete.type';
import {UserRoleAccessListInitialState} from './userRoleAccessList/userRoleAccessList.type';
import {UserAccountEditInitialState} from './userAccountEdit/userAccountEdit.type';
import {UserAccountDetailInitialState} from './userAccountDetail/userAccountDetail.type';
import {UserRoleAccessCreateInitialState} from './userRoleAccessCreate/userRoleAccessCreate.type';

import userHandler from './user.handler';

export interface UserState
  extends UserLoginInitialState,
    UserDataInitialState,
    UserForgetPasswordInitialState,
    UserOTPPasswordInitialState,
    UserChangePasswordInitialState,
    UserAccessCreateInitialState,
    UserAccessDeleteInitialState,
    UserAccessEditInitialState,
    UserAccessListInitialState,
    UserAccessSetActiveInitialState,
    UserRoleAccessListInitialState,
    UserRoleAccessEditInitialState,
    UserRoleAccessDeleteInitialState,
    UserAccountEditInitialState,
    UserAccountDetailInitialState,
    UserRoleAccessCreateInitialState {
  action: string;
}

export const userInitialState: UserState = {
  ...userLoginInitialState,
  ...userDataInitialState,
  ...userForgetPasswordInitialState,
  ...userOTPPasswordInitialState,
  ...userChangePasswordInitialState,
  ...userAccessCreateInitialState,
  ...userAccessDeleteInitialState,
  ...userAccessEditInitialState,
  ...userAccessListInitialState,
  ...userAccessSetActiveInitialState,
  ...userRoleAccessListInitialState,
  ...userRoleAccessEditInitialState,
  ...userRoleAccessDeleteInitialState,
  ...userAccountEditInitialState,
  ...userAccountDetailInitialState,
  ...userRoleAccessCreateInitialState,
  action: '',
};

export default createReducer(userInitialState, userHandler);
