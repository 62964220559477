import {CommonError} from '../../../../config/constant';
import {FollowUpExpiredFormData} from './../../entity/followUp.string.entity';

export const FollowUpExpiredNoteCreateAction = {
  FETCH: 'FOLLOW_UP_EXPIRED_NOTE_CREATE_FETCH',
  SUCCESS: 'FOLLOW_UP_EXPIRED_NOTE_CREATE_SUCCESS',
  FAILED: 'FOLLOW_UP_EXPIRED_NOTE_CREATE_FAILED',
  RESET: 'FOLLOW_UP_EXPIRED_NOTE_CREATE_RESET',
};

export interface FollowUpExpiredNoteCreateInitialState {
  followUpExpiredNoteCreateLoading: boolean;
  followUpExpiredNoteCreateParams?: FollowUpExpiredNoteCreateFetchPayload;
  followUpExpiredNoteCreateError?: FollowUpExpiredNoteCreateFailedPayload;
  followUpExpiredNoteCreateResponse?: FollowUpExpiredNoteCreateSuccessPayload;
}

export type FollowUpExpiredNoteCreateFetchPayload = FollowUpExpiredFormData;

export type FollowUpExpiredNoteCreateFailedPayload = CommonError;

export interface FollowUpExpiredNoteCreateSuccessPayload {}
