//#region IMPORT
// Libraries
import React from 'react';
import {RangeInput} from '@mui/lab/DateRangePicker/RangeTypes';
// Utils
import t from '../../../../lang';
// Components
import {
  MCheckMarks,
  MDateRangepicker,
  MDropdown,
  MToggleButton,
} from '../../../../components';
// Data
import {followUpFilterOption} from '../../entity/followUp.string.entity';
import {AssignmentType, SortType} from '../../../wrapper/entity';
// Assets
import './FollowUpFilter.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  selectedToggleFilter?: string | null;
  selectedCheckmarksFilter: string[];
  checkmarksFilterPlaceholder?: string;
  selectedDateFilter: RangeInput<unknown>;
  resetFilterLabel?: string;
  selectedSort: string;
  sortLabel?: string;
  handleChangeToggleFilter?(
    e: React.MouseEvent<HTMLElement>,
    value: string | null,
  ): void;
  handleChangeCheckmarksFilter?(e: unknown): void;
  handleChangeDateFilter?(date: RangeInput<unknown> | null): void;
  handleResetFilter?(): void;
  handleSort(sort: string): void;
}
//#endregion

const FollowUpFilter: React.FC<Props> = ({
  selectedToggleFilter,
  selectedCheckmarksFilter,
  checkmarksFilterPlaceholder,
  selectedDateFilter,
  resetFilterLabel = t('Reset Filter'),
  selectedSort,
  sortLabel = t('Sort:'),
  handleChangeToggleFilter,
  handleChangeCheckmarksFilter,
  handleChangeDateFilter,
  handleResetFilter,
  handleSort,
}: Props) => {
  //#region GENERAL
  const sortOptions = [
    {label: t('Latest'), value: SortType.DESC},
    {label: t('Oldest'), value: SortType.ASC},
  ];
  const toggleFilterOptions = [
    {label: t('All'), value: followUpFilterOption.ALL},
    {label: t('Normal'), value: followUpFilterOption.NORMAL},
    {label: t('Expired'), value: followUpFilterOption.EXPIRED},
  ];
  const checkmarksFilterOptions = [
    {
      label: 'Halte',
      value: AssignmentType.HALTE,
    },
    {
      label: 'Bus BRT',
      value: AssignmentType.BUSBRT,
    },
    {
      label: 'Bus Non BRT',
      value: AssignmentType.BUSNONBRT,
    },
    {
      label: 'Bus Kecil',
      value: AssignmentType.BUSKECIL,
    },
    {
      label: 'Transcare',
      value: AssignmentType.TRANSCARE,
    },
  ];
  const showReset: boolean =
    selectedToggleFilter !== followUpFilterOption.ALL ||
    selectedCheckmarksFilter.length < 5 ||
    !selectedDateFilter.every((date) => date === null);
  //#endregion

  return (
    <div className="follow-up-filter">
      {handleChangeToggleFilter && (
        <div className="follow-up-filter__toggle">
          <MToggleButton
            selected={selectedToggleFilter as string}
            options={toggleFilterOptions}
            handleChangeToggle={handleChangeToggleFilter}
          />
        </div>
      )}
      {checkmarksFilterOptions && (
        <div className="follow-up-filter__checkmarks">
          <MCheckMarks
            selected={selectedCheckmarksFilter}
            options={checkmarksFilterOptions}
            placeholder={checkmarksFilterPlaceholder}
            handleChangeCheckmarks={handleChangeCheckmarksFilter}
          />
        </div>
      )}
      {handleChangeDateFilter && (
        <div className="follow-up-filter__date">
          <MDateRangepicker
            inputValue={selectedDateFilter}
            handleChange={handleChangeDateFilter}
          />
        </div>
      )}
      {handleResetFilter && showReset && (
        <div
          className="follow-up-filter__reset"
          onClick={(e) => {
            e.stopPropagation();
            handleResetFilter();
          }}>
          {resetFilterLabel}
        </div>
      )}
      <div className="follow-up-filter__sort">
        <div className="follow-up-filter__sort-label">{sortLabel}</div>
        <div className="follow-up-filter__sort-input">
          <MDropdown
            placeholder=""
            inputWidth="10rem"
            options={sortOptions}
            inputClassName="noBorder"
            inputValue={selectedSort}
            handleChangeSelect={handleSort}
          />
        </div>
      </div>
    </div>
  );
};
export default FollowUpFilter;
