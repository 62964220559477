import {createAction} from '@reduxjs/toolkit';
import {
  WorkReportUploadSupportAction,
  WorkReportUploadSupportFailedPayload,
  WorkReportUploadSupportFetchPayload,
  WorkReportUploadSupportSuccessPayload,
} from './workReportUploadSupport.type';

export const workReportUploadSupportAction = {
  fetch: createAction<WorkReportUploadSupportFetchPayload>(
    WorkReportUploadSupportAction.FETCH,
  ),
  success: createAction<WorkReportUploadSupportSuccessPayload>(
    WorkReportUploadSupportAction.SUCCESS,
  ),
  failed: createAction<WorkReportUploadSupportFailedPayload>(
    WorkReportUploadSupportAction.FAILED,
  ),
  reset: createAction(WorkReportUploadSupportAction.RESET),
};
