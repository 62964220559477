import {Moment} from 'moment';
import {FileMeta} from '../../wrapper/entity';

//#region WORK REPORT
export enum WorkReportType {
  MONTHLY = 'Bulanan',
  RECONCILIATION = 'Rekonsiliasi Bulanan',
}

export interface WorkReport {
  id: string;
  name: string;
  reportNumber: string;
  reportType: WorkReportType;
  month: string | number;
  year: string | number;
  departmentId: string;
  departmentName?: string;
  uploadedTime: string | number;
  mainFile: string;
  mainFileName?: string;
  supportFile: string;
  supportFileName?: string;
}

export interface WorkReportDownloadFormData {
  reportType?: WorkReportType;
  startMonth?: string;
  endMonth?: string;
}

export type WorkReportOrderByType =
  | 'name'
  | 'reportNumber'
  | 'reportType'
  | 'month'
  | 'year'
  | 'departmentName'
  | 'uploadedTime';

export interface WorkReportDownload {
  file?: Blob;
}

export interface WorkReportFormData {
  id?: string;
  name: string;
  year: Moment | string;
  month: Moment | string;
  reportType: WorkReportType;
  mainFile?: File;
  supportFile?: File;
  departmentId?: string;
}

export interface WorkReportFormDataTemp extends WorkReportFormData {
  departmentName?: string;
}

export interface WorkReportUploadFormData {
  reportType: WorkReportType;
  file?: FileMeta;
}

export interface WorkReportInitialFile {
  mainFileBlob?: File;
  supportFileBlob?: File;
}
//#endregion
