import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataWorkInstructionEditAction,
  MasterDataWorkInstructionEditFailedPayload,
  MasterDataWorkInstructionEditFetchPayload,
  MasterDataWorkInstructionEditSuccessPayload,
} from './masterDataWorkInstructionEdit.type';

export const masterDataWorkInstructionEditAction = {
  fetch: createAction<MasterDataWorkInstructionEditFetchPayload>(
    MasterDataWorkInstructionEditAction.FETCH,
  ),
  success: createAction<MasterDataWorkInstructionEditSuccessPayload>(
    MasterDataWorkInstructionEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataWorkInstructionEditFailedPayload>(
    MasterDataWorkInstructionEditAction.FAILED,
  ),
  reset: createAction(MasterDataWorkInstructionEditAction.RESET),
};
