//#region IMPORT
// Libraries
import React, {ReactElement, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import * as Yup from 'yup';
import {Field, FieldArray, FieldArrayRenderProps, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
import {AppState} from '../../../../../../../../config/Interface';
import {
  getOptionFromDirectorateList,
  getOptionFromDivisionList,
} from '../../../../../../../../utils';
import {
  masterDataDirectorateOptionListAction,
  masterDataDivisionOptionListAction,
} from '../../../../../../usecases/masterData.action';
import {MasterDataDepartmentCreateFetchPayload} from '../../../../../../usecases/masterData.type';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../../../components';
// Data
import {MasterDataDepartmentFormData} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDepartmentFormCreateForm.component.style.scss';
//#endregion

//#region TYPE
export interface DepartmentFormCreateInitialValues
  extends Omit<MasterDataDepartmentCreateFetchPayload, 'data'> {
  departments: Pick<MasterDataDepartmentFormData, 'name'>[];
}
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: DepartmentFormCreateInitialValues;
  handleCancel(): void;
  handleSubmitDepartment(formValue: DepartmentFormCreateInitialValues): void;
}
//#endregion

const MasterDataDepartmentFormCreateForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitDepartment,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const [directorateId, setDirectorateId] = useState('');

  const isLoadingDirectorate =
    masterData.masterDataDirectorateOptionListLoading;
  const isLoadingDivision = masterData.masterDataDivisionOptionListLoading;
  //#endregion

  //#region FETCH DIVISION
  const fetchDivisionOptionList = useCallback(() => {
    if (directorateId)
      dispatch(
        masterDataDivisionOptionListAction.fetch({
          directorateId: directorateId,
        }),
      );
  }, [dispatch, directorateId]);

  useEffect(() => {
    dispatch(masterDataDivisionOptionListAction.reset());
    fetchDivisionOptionList();

    return () => {
      dispatch(masterDataDivisionOptionListAction.reset());
    };
  }, [dispatch, fetchDivisionOptionList]);
  //#endregion

  //#region FETCH DIRECTORATE
  const fetchDirectorateOptionList = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.reset());
    fetchDirectorateOptionList();

    return () => {
      dispatch(masterDataDirectorateOptionListAction.reset());
    };
  }, [dispatch, fetchDirectorateOptionList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const departmentValidation = Yup.object().shape({
    directorateId: Yup.string().required(t('Directorate is required.')),
    divisionId: Yup.string().required(t('Division is required.')),
    departments: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(t('Department is required.'))
          .min(
            3,
            `${t('Department')} ${t('must be filled in at least')} ${3} ${t(
              'letters',
            )}.`,
          ),
      }),
    ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-department-create-form">
      <div className="master-data-department-create-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={departmentValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitDepartment}>
          {({handleSubmit, values, isValid}): React.ReactElement => (
            <>
              <div className="master-data-department-create-form__section">
                <div className="master-data-department-create-form__section--field">
                  <div className="input-label">{t('Directorate')}</div>
                  <Field
                    className="user-input"
                    name="directorateId"
                    placeholder={t('Choose Directorate')}
                    options={getOptionFromDirectorateList(
                      masterData.masterDataDirectorateOptionListData,
                    )}
                    handleChangeSelect={(e: string) => {
                      setDirectorateId(e);
                    }}
                    disabled={isLoading || isLoadingDirectorate}
                    component={MSelect}
                  />
                </div>
                <div className="master-data-department-create-form__section--field">
                  <div className="input-label">{t('Division')}</div>
                  <Field
                    className="user-input"
                    name="divisionId"
                    placeholder={t('Choose Division')}
                    options={getOptionFromDivisionList(
                      masterData.masterDataDivisionOptionListData,
                    )}
                    disabled={isLoading || isLoadingDivision || !directorateId}
                    component={MSelect}
                  />
                </div>
                <FieldArray
                  name="departments"
                  render={(
                    arrayHelper: FieldArrayRenderProps,
                  ): ReactElement => (
                    <div className="master-data-department-create-form__section">
                      {values.departments.map((_, index: number) => (
                        <div
                          key={`master-data-department-create-item-${index}`}
                          className="master-data-department-create-form__section--field">
                          <div className="input-label">
                            {`${t('Department')} ${index + 1}`}
                          </div>
                          <Field
                            className="user-input"
                            name={`departments.${index}.name`}
                            type="text"
                            placeholder={t('Enter Department')}
                            disabled={isLoading}
                            component={MTextField}
                          />
                        </div>
                      ))}

                      <div
                        className="master-data-department-create-form__form--add"
                        onClick={
                          isLoading
                            ? undefined
                            : () => arrayHelper.push({name: ''})
                        }>
                        + {t('Add Department')}
                      </div>
                    </div>
                  )}
                />

                <div className="master-data-department-create-form__form--action">
                  <div className="master-data-department-create-form-action__canceled">
                    <MButton
                      isDisabled={isLoading}
                      buttonLabel={t('Cancel')}
                      handleClick={handleCancel}
                    />
                  </div>
                  <div className="master-data-department-create-form-action">
                    <MButton
                      isDisabled={!isValid || isLoading}
                      isLoading={isLoading}
                      buttonLabel={t('Add')}
                      handleClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataDepartmentFormCreateForm;
