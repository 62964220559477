import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDetailObservationDeleteInitialState} from './masterDataDetailObservationDelete.reducer';
import {
  MasterDataDetailObservationDeleteAction,
  MasterDataDetailObservationDeleteFailedPayload,
  MasterDataDetailObservationDeleteFetchPayload,
  MasterDataDetailObservationDeleteSuccessPayload,
} from './masterDataDetailObservationDelete.type';

export const masterDataDetailObservationDeleteHandler = {
  [MasterDataDetailObservationDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationDeleteLoading: true,
    masterDataDetailObservationDeleteParams: action.payload,
    action: MasterDataDetailObservationDeleteAction.FETCH,
  }),
  [MasterDataDetailObservationDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationDeleteParams: undefined,
    masterDataDetailObservationDeleteLoading: false,
    masterDataDetailObservationDeleteResponse: action.payload,
    action: MasterDataDetailObservationDeleteAction.SUCCESS,
  }),
  [MasterDataDetailObservationDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationDeleteLoading: false,
    masterDataDetailObservationDeleteError: action.payload,
    action: MasterDataDetailObservationDeleteAction.FAILED,
  }),
  [MasterDataDetailObservationDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailObservationDeleteInitialState,
    action: MasterDataDetailObservationDeleteAction.RESET,
  }),
};
