import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {httpPut} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataReviewIndicatorMoveAction} from './masterDataReviewIndicatorMove.action';
import {
  MasterDataReviewIndicatorMoveAction,
  MasterDataReviewIndicatorMoveFetchPayload,
  MasterDataReviewIndicatorMoveSuccessPayload,
} from './masterDataReviewIndicatorMove.type';

function* workerFetch(
  action: GenericAction<MasterDataReviewIndicatorMoveFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id, isUp, isDown, assignmentType} = action.payload;
  try {
    const res: AxiosResponse<MasterDataReviewIndicatorMoveSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/review-indicator/move/${id}${
        isUp ? `?isUp=true` : ''
      }${isDown ? `?isDown=true` : ''}&assignmentType=${assignmentType}
      `,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataReviewIndicatorMoveAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(
      masterDataReviewIndicatorMoveAction.failed(e.response.data.payload),
    );
  }
}

const masterDataReviewIndicatorMoveWatcher = [
  takeLatest(MasterDataReviewIndicatorMoveAction.FETCH, workerFetch),
];

export default masterDataReviewIndicatorMoveWatcher;
