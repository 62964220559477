import {GenericAction} from '../../../../config/constant';
import {FollowUpState} from '../followUp.reducer';
import {followUpExpiredNoteCreateInitialState} from './followUpExpiredNoteCreate.reducer';
import {
  FollowUpExpiredNoteCreateAction,
  FollowUpExpiredNoteCreateFailedPayload,
  FollowUpExpiredNoteCreateFetchPayload,
  FollowUpExpiredNoteCreateSuccessPayload,
} from './followUpExpiredNoteCreate.type';

export const followUpExpiredNoteCreateHandler = {
  [FollowUpExpiredNoteCreateAction.FETCH]: (
    state: FollowUpState,
    action: GenericAction<FollowUpExpiredNoteCreateFetchPayload>,
  ): FollowUpState => ({
    ...state,
    followUpExpiredNoteCreateLoading: true,
    followUpExpiredNoteCreateParams: action.payload,
    action: FollowUpExpiredNoteCreateAction.FETCH,
  }),
  [FollowUpExpiredNoteCreateAction.SUCCESS]: (
    state: FollowUpState,
    action: GenericAction<FollowUpExpiredNoteCreateSuccessPayload>,
  ): FollowUpState => ({
    ...state,
    followUpExpiredNoteCreateParams: undefined,
    followUpExpiredNoteCreateLoading: false,
    followUpExpiredNoteCreateResponse: action.payload,
    action: FollowUpExpiredNoteCreateAction.SUCCESS,
  }),
  [FollowUpExpiredNoteCreateAction.FAILED]: (
    state: FollowUpState,
    action: GenericAction<FollowUpExpiredNoteCreateFailedPayload>,
  ): FollowUpState => ({
    ...state,
    followUpExpiredNoteCreateLoading: false,
    followUpExpiredNoteCreateError: action.payload,
    action: FollowUpExpiredNoteCreateAction.FAILED,
  }),
  [FollowUpExpiredNoteCreateAction.RESET]: (
    state: FollowUpState,
  ): FollowUpState => ({
    ...state,
    ...followUpExpiredNoteCreateInitialState,
    action: FollowUpExpiredNoteCreateAction.RESET,
  }),
};
