/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  masterDataDetailIndicatorListAction,
  masterDataDetailIndicatorDeleteAction,
  masterDataDetailIndicatorCreateAction,
  masterDataDetailIndicatorEditAction,
} from '../../../../usecases/masterData.action';
import {
  MasterDataDetailIndicatorCreateAction,
  MasterDataDetailIndicatorDeleteAction,
  MasterDataDetailIndicatorEditAction,
  MasterDataDetailIndicatorListAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataDetailIndicatorManagementList} from './components';
import MasterDataDetailIndicatorFormCreateContainer from '../MasterDataDetailIndicatorFormCreateContainer';
import MasterDataDetailIndicatorFormEditContainer from '../MasterDataDetailIndicatorFormEditContainer';
// Data
import {
  MasterDataDetailIndicator,
  MasterDataDetailIndicatorOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDetailIndicatorSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataDetailIndicatorSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddDetailIndicator, setAddDetailIndicator] = useState(false);
  const [isDeleteDetailIndicator, setIsDeleteDetailIndicator] = useState<
    MasterDataDetailIndicator | undefined
  >(undefined);
  const [isEditDetailIndicator, setIsEditDetailIndicator] = useState<
    MasterDataDetailIndicator | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddDetailIndicator =
    masterData.masterDataDetailIndicatorCreateLoading;
  const isLoadingEditDetailIndicator =
    masterData.masterDataDetailIndicatorEditLoading;
  const isLoadingDeleteDetailIndicator =
    masterData.masterDataDetailIndicatorDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataDetailIndicatorListPage,
    setMasterDataDetailIndicatorListPage,
  ] = useState<number>(0);
  const [
    masterDataDetailIndicatorListCount,
    setMasterDataDetailIndicatorListCount,
  ] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataDetailIndicatorListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataDetailIndicatorListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataDetailIndicatorOrderByType>(
    'name',
  );

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataDetailIndicatorOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH DETAIL INDICATOR
  const fetchDetailIndicatorList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataDetailIndicatorListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataDetailIndicatorListAction.reset());
    fetchDetailIndicatorList(
      masterDataDetailIndicatorListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataDetailIndicatorListAction.reset());
    };
  }, [
    dispatch,
    fetchDetailIndicatorList,
    masterDataDetailIndicatorListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  //#endregion

  //#region ACTION HANDLER
  const masterDataDetailIndicatorActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataDetailIndicatorListAction.UPDATE, (): void => {
            setMasterDataDetailIndicatorListCount(
              masterData?.masterDataDetailIndicatorListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataDetailIndicatorListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailIndicatorListAction.reset());
          })
          .addCase(MasterDataDetailIndicatorCreateAction.SUCCESS, (): void => {
            setAddDetailIndicator(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDetailIndicatorCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailIndicatorCreateAction.reset());
          })
          .addCase(MasterDataDetailIndicatorEditAction.SUCCESS, (): void => {
            setIsEditDetailIndicator(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDetailIndicatorEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailIndicatorEditAction.reset());
          })
          .addCase(MasterDataDetailIndicatorDeleteAction.SUCCESS, (): void => {
            setIsDeleteDetailIndicator(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDetailIndicatorDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailIndicatorDeleteAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataDetailIndicatorListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataDetailIndicatorActionHandler(masterData.action);
  }, [masterData.action, masterDataDetailIndicatorActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataDetailIndicatorDeleteAction.fetch({id}));
  };
  //#endregion

  return (
    <>
      <div className="master-data-detail-indicator-section">
        <div className="master-data-detail-indicator-section__header">
          <MSearchInput
            inputClassName="master-data-detail-indicator-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Detail Indicator')}
            setInputValue={(search: string) => {
              setMasterDataDetailIndicatorListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-detail-indicator-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddDetailIndicator(true)}
              />
            </div>
          )}
        </div>
        <MasterDataDetailIndicatorManagementList
          detailIndicator={masterData.masterDataDetailIndicatorListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditDetailIndicator: (
              _detailIndicator: MasterDataDetailIndicator,
            ) => setIsEditDetailIndicator(_detailIndicator),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteDetailIndicator: (
              _detailIndicator: MasterDataDetailIndicator,
            ) => setIsDeleteDetailIndicator(_detailIndicator),
          })}
        />
        <MTablePagination
          className="vehicle-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataDetailIndicatorListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataDetailIndicatorListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddDetailIndicator(false)}
        title={t('Add Detail Indicator')}
        isOpen={isAddDetailIndicator}>
        <MasterDataDetailIndicatorFormCreateContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddDetailIndicator}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditDetailIndicator && (
        <Modal
          onClose={() => setIsEditDetailIndicator(undefined)}
          isOpen={!!isEditDetailIndicator}
          title={t('Edit Detail Indicator')}>
          <MasterDataDetailIndicatorFormEditContainer
            assignmentType={assignmentType}
            data={isEditDetailIndicator}
            isLoading={isLoadingEditDetailIndicator}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteDetailIndicator && (
        <AlertDialog
          onClose={() => setIsDeleteDetailIndicator(undefined)}
          onCancel={() => setIsDeleteDetailIndicator(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteDetailIndicator.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteDetailIndicator}
          isLoading={isLoadingDeleteDetailIndicator}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddDetailIndicator(false)
              : setIsEditDetailIndicator(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataDetailIndicatorSection;
