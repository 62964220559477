import {createAction} from '@reduxjs/toolkit';
import {
  DashboardOverviewExportAchievementFinalQuarterAction,
  DashboardOverviewExportAchievementFinalQuarterFailedPayload,
  DashboardOverviewExportAchievementFinalQuarterFetchPayload,
  DashboardOverviewExportAchievementFinalQuarterSuccessPayload,
} from './dashboardOverviewExportAchievementFinalQuarter.type';

export const dashboardOverviewExportAchievementFinalQuarterAction = {
  fetch: createAction<DashboardOverviewExportAchievementFinalQuarterFetchPayload>(
    DashboardOverviewExportAchievementFinalQuarterAction.FETCH,
  ),
  success: createAction<DashboardOverviewExportAchievementFinalQuarterSuccessPayload>(
    DashboardOverviewExportAchievementFinalQuarterAction.SUCCESS,
  ),
  failed: createAction<DashboardOverviewExportAchievementFinalQuarterFailedPayload>(
    DashboardOverviewExportAchievementFinalQuarterAction.FAILED,
  ),
  reset: createAction(
    DashboardOverviewExportAchievementFinalQuarterAction.RESET,
  ),
};
