//#region IMPORT
import {mdiClose} from '@mdi/js';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import React from 'react';
import MIconButton from '../MIconButton';

import './MBottomSheet.scss';
//#endregion

interface Props {
  children: React.ReactElement | string;
  isOpen: boolean;
  titleComponent?: React.ReactElement | string;
  onClose(): void;
}

const MBottomSheet: React.FC<Props> = ({
  children,
  isOpen,
  titleComponent,
  onClose,
}: Props) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      className="m-bottom-sheet__wrapper">
      <div className="m-bottom-sheet">
        {onClose && (
          <div className="m-bottom-sheet__close-button">
            <MIconButton
              small
              icon={mdiClose}
              onClick={onClose}
              className="m-bottom-sheet__close-button-icon"
            />
          </div>
        )}
        {titleComponent && (
          <DialogTitle className="m-bottom-sheet__title">
            <div className="m-bottom-sheet__title-content">
              {titleComponent}
            </div>
          </DialogTitle>
        )}
        <DialogContent className="m-bottom-sheet__content">
          {children}
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default MBottomSheet;
