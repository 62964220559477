import * as React from 'react';

const IconMultipleChoice = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    {...props}>
    <path
      style={{
        stroke: 'none',
        fillRule: 'evenodd',
        fill: props.fill || '#bdbdbd',
        fillOpacity: 1,
      }}
      d="M12 3.816a8.184 8.184 0 1 0 0 16.368 8.184 8.184 0 0 0 0-16.368ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm0 0"
    />
    <path
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: props.fill || '#bdbdbd',
        fillOpacity: 1,
      }}
      d="M17 12a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm0 0"
    />
  </svg>
);

export default IconMultipleChoice;
