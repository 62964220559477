import {CommonError} from '../../../../config/constant';

export const UserForgetPasswordAction = {
  FETCH: 'USER_FORGET_PASSWORD_FETCH',
  SUCCESS: 'USER_FORGET_PASSWORD_SUCCESS',
  FAILED: 'USER_FORGET_PASSWORD_FAILED',
  RESET: 'USER_FORGET_PASSWORD_RESET',
};

export interface UserForgetPasswordInitialState {
  userForgetPasswordLoading: boolean;
  userForgetPasswordParams?: UserForgetPasswordFetchPayload;
  userForgetPasswordError?: UserForgetPasswordFailedPayload;
  userForgetPasswordResponse?: UserForgetPasswordSuccessPayload;
}

export type UserForgetPasswordFetchPayload = {
  email: string;
};

export type UserForgetPasswordFailedPayload = CommonError;

export interface UserForgetPasswordSuccessPayload {
  data: {email: string};
}
