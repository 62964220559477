import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataAssignmentLetterSetActiveInitialState} from './masterDataAssignmentLetterSetActive.reducer';
import {
  MasterDataAssignmentLetterSetActiveAction,
  MasterDataAssignmentLetterSetActiveFailedPayload,
  MasterDataAssignmentLetterSetActiveFetchPayload,
  MasterDataAssignmentLetterSetActiveSuccessPayload,
} from './masterDataAssignmentLetterSetActive.type';

export const masterDataAssignmentLetterSetActiveHandler = {
  [MasterDataAssignmentLetterSetActiveAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterSetActiveFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterSetActiveLoading: true,
    masterDataAssignmentLetterSetActiveParams: action.payload,
    action: MasterDataAssignmentLetterSetActiveAction.FETCH,
  }),
  [MasterDataAssignmentLetterSetActiveAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterSetActiveSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterSetActiveParams: undefined,
    masterDataAssignmentLetterSetActiveLoading: false,
    masterDataAssignmentLetterSetActiveResponse: action.payload,
    action: MasterDataAssignmentLetterSetActiveAction.SUCCESS,
  }),
  [MasterDataAssignmentLetterSetActiveAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterSetActiveFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterSetActiveLoading: false,
    masterDataAssignmentLetterSetActiveError: action.payload,
    action: MasterDataAssignmentLetterSetActiveAction.FAILED,
  }),
  [MasterDataAssignmentLetterSetActiveAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataAssignmentLetterSetActiveInitialState,
    action: MasterDataAssignmentLetterSetActiveAction.RESET,
  }),
};
