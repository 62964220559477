import {
  UserAccountDetailAction,
  UserAccountDetailSuccessPayload,
  UserAccountDetailFailedPayload,
  UserAccountDetailFetchPayload,
} from './userAccountDetail.type';
import {UserState} from '../user.reducer';
import {GenericAction} from '../../../../config/constant';
import {userAccountDetailInitialState} from './userAccountDetail.reducer';

export const userAccountDetailHandler = {
  [UserAccountDetailAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserAccountDetailFetchPayload>,
  ): UserState => {
    return {
      ...state,
      userAccountDetailLoading: true,
      userAccountDetailParams: action.payload,
      action: UserAccountDetailAction.FETCH,
    };
  },
  [UserAccountDetailAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserAccountDetailSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccountDetailLoading: false,
    userAccountDetailResponse: action.payload,
    action: UserAccountDetailAction.SUCCESS,
  }),
  [UserAccountDetailAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserAccountDetailFailedPayload>,
  ): UserState => ({
    ...state,
    userAccountDetailLoading: false,
    userAccountDetailError: action.payload,
    action: UserAccountDetailAction.FAILED,
  }),
  [UserAccountDetailAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userAccountDetailInitialState,
    action: UserAccountDetailAction.RESET,
  }),
};
