//#region IMPORT
// Libraries
import React from 'react';
// Components
import FollowUpApprovedListItem from '../FollowUpApprovedListItem';
// Data
import {FollowUp} from '../../../../../entity/followUp.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  detailData?: FollowUp;
  onViewItem?(detailData: FollowUp): void;
}
//#endregion

const FollowUpApprovedDetail: React.FC<Props> = ({
  detailData,
  onViewItem,
}: Props) => {
  return (
    <div className="follow-up-approved-detail__container">
      {detailData ? (
        <FollowUpApprovedListItem
          data={detailData}
          isDetail
          onViewItem={() => onViewItem && onViewItem(detailData)}
        />
      ) : null}
    </div>
  );
};

export default FollowUpApprovedDetail;
