import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {dashboardDepartmentBestSectionAction} from './dashboardDepartmentBestSection.action';
import {
  DashboardDepartmentBestSectionAction,
  DashboardDepartmentBestSectionFetchPayload,
  DashboardDepartmentBestSectionResponse,
  DashboardDepartmentBestSectionSuccessPayload,
} from './dashboardDepartmentBestSection.type';

function* workerFetch(
  action: GenericAction<DashboardDepartmentBestSectionFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<DashboardDepartmentBestSectionSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/dashboard/best`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        dashboardDepartmentBestSectionAction.success(
          res.data as DashboardDepartmentBestSectionResponse,
        ),
      );
    } else {
      yield put(
        dashboardDepartmentBestSectionAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(dashboardDepartmentBestSectionAction.failed(e as CommonError));
  }
}

const dashboardDepartmentBestSectionWatcher = [
  takeLatest(DashboardDepartmentBestSectionAction.FETCH, workerFetch),
];

export default dashboardDepartmentBestSectionWatcher;
