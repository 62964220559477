import masterDataSaga from '../modules/masterData/usecases/masterData.saga';
import userSaga from '../modules/user/usecases/user.saga';
import followUpSaga from '../modules/followUp/usecases/followUp.saga';
import workReportSaga from '../modules/workReport/usecases/workReport.saga';
import dashboardSaga from '../modules/dashboard/usecases/dashboard.saga';
import notificationSaga from '../modules/notification/usecases/notification.saga';
import evaluationReportSaga from '../modules/evaluationReport/usecases/evaluationReport.saga';

export default [
  ...userSaga,
  ...masterDataSaga,
  ...followUpSaga,
  ...workReportSaga,
  ...dashboardSaga,
  ...notificationSaga,
  ...evaluationReportSaga,
];
