//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import {MasterDataDetailObservationCreateFetchPayload} from '../../../../usecases/masterData.type';
// Utils
import {masterDataDetailObservationCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDetailObservationFormCreate} from './components';
import {DetailObservationFormCreateInitialValues} from './components/MasterDataDetailObservationFormCreate/MasterDataDetailObservationFormCreate.component';
// Assets
import './MasterDataDetailObservationFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDetailObservationFormCreateContainer: React.FC<Props> = ({
  assignmentType,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: DetailObservationFormCreateInitialValues = {
    detailObservation: [{name: ''}],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDetailObservation = (
    formValue: DetailObservationFormCreateInitialValues,
  ) => {
    const formattedValue: MasterDataDetailObservationCreateFetchPayload =
      formValue?.detailObservation.map((item) => ({
        name: item.name,
        assignmentType,
      })) || [];
    dispatch(masterDataDetailObservationCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-detail-observation-form-create-container">
      <MasterDataDetailObservationFormCreate
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitDetailObservation={handleSubmitDetailObservation}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataDetailObservationFormCreateContainer;
