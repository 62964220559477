import workReportList from './workReportList/workReportList.saga';
import workReportUpload from './workReportUpload/workReportUpload.saga';
import workReportUploadSupport from './workReportUploadSupport/workReportUploadSupport.saga';
import workReportCreate from './workReportCreate/workReportCreate.saga';
import workReportEdit from './workReportEdit/workReportEdit.saga';
import workReportDelete from './workReportDelete/workReportDelete.saga';
import workReportExportAll from './workReportExportAll/workReportExportAll.saga';

export default [
  ...workReportList,
  ...workReportUpload,
  ...workReportUploadSupport,
  ...workReportCreate,
  ...workReportEdit,
  ...workReportDelete,
  ...workReportExportAll,
];
