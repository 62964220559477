//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../../user/usecases/user.reducer';
import {FollowUpState} from '../../usecases/followUp.reducer';
import {findDefaultTabs, useQueryParams} from '../../utils';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import FollowUpKadepManagementWrapper from '../FollowUpKadepManagementWrapper';
import FollowUpKadivManagementWrapper from '../FollowUpKadivManagementWrapper';
import FollowUpManagementWrapper from '../FollowUpManagementWrapper';
import FollowUpSPMManagementWrapper from '../FollowUpSPMManagementWrapper';
// Data
import {PATH} from '../../../wrapper/entity';
import {BackOfficePermissionType} from '../../../user/entity/user.string.entity';
import {FollowUp} from '../../entity/followUp.string.entity';
import {followUpDetailAction} from '../../usecases/followUp.action';
import {FollowUpDetailAction} from '../../usecases/followUp.type';
//#endregion

const FollowUpWrapper: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const followUpState: FollowUpState = useSelector(
    (state: AppState) => state.followUp,
    shallowEqual,
  );
  const {id, followUpType} = useQueryParams(['id', 'followUpType']);

  const [isDefaultDetail, setIsDefaultDetail] = useState<FollowUp | undefined>(
    undefined,
  );

  const {data, userLevel}: UserState = useSelector(
    (state: AppState) => state.user,
  );
  const permissions: BackOfficePermissionType | undefined =
    data?.role?.permissions;
  const userLevelData: number = userLevel
    ? Number(userLevel?.replace('USERLEVEL', ''))
    : 8;
  //#endregion

  //#region FETCH FOLLOW UP DETAIL
  const fetchFollowUpDetail = useCallback(() => {
    if (id) {
      dispatch(
        followUpDetailAction.fetch({
          id: id,
        }),
      );
    }
  }, [dispatch, id]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(followUpDetailAction.reset());
    fetchFollowUpDetail();

    return () => {
      dispatch(followUpDetailAction.reset());
    };
  }, [dispatch, fetchFollowUpDetail]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const followUpRejectedActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(FollowUpDetailAction.SUCCESS, (): void => {
            const detailData: FollowUp | undefined =
              followUpState.followUpDetailResponse?.data;
            setIsDefaultDetail(detailData);
          })
          .addCase(FollowUpDetailAction.RESET, (): void => {
            setIsDefaultDetail(undefined);
          })
          .addCase(FollowUpDetailAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(followUpDetailAction.reset());
          });
      });
    },
    [dispatch, followUpState.followUpDetailResponse?.data],
  );
  useEffect(() => {
    followUpRejectedActionHandler(followUpState.action);
  }, [followUpState.action, followUpRejectedActionHandler]);
  //#endregion

  const isChiefOfDepartment =
    userLevelData === 4 &&
    !!permissions?.followUpTabApprove &&
    !!permissions?.followUpTabExpired;
  const isPIC =
    userLevelData === 6 &&
    !!permissions?.followUpTabApprove &&
    !!permissions?.followUpTabInProgress &&
    !!permissions?.followUpTabRejected &&
    !!permissions?.followUpTabWaitingForVerification;

  const isSPMOfficer = userLevelData === 9;

  const isAdminSPM =
    userLevelData === 5 &&
    !!permissions?.followUpTabApprove &&
    !!permissions?.followUpTabInProgress;
  const isChiefofDivision = userLevelData === 3;

  if (!userLevelData) return <Redirect to={PATH.HOME} />;
  if (isChiefOfDepartment)
    return (
      <FollowUpKadepManagementWrapper
        defaultOpenedDetail={isDefaultDetail}
        defaultOpenedTab={findDefaultTabs(userLevelData, followUpType)}
      />
    );
  if (isPIC)
    return (
      <FollowUpManagementWrapper
        defaultOpenedDetail={isDefaultDetail}
        defaultOpenedTab={findDefaultTabs(userLevelData, followUpType)}
      />
    );
  if (isSPMOfficer) {
    return (
      <FollowUpManagementWrapper
        isSPMOfficer
        defaultOpenedDetail={isDefaultDetail}
        defaultOpenedTab={findDefaultTabs(userLevelData, followUpType)}
      />
    );
  }
  if (isAdminSPM)
    return (
      <FollowUpSPMManagementWrapper
        defaultOpenedDetail={isDefaultDetail}
        defaultOpenedTab={findDefaultTabs(userLevelData, followUpType)}
      />
    );
  if (isChiefofDivision)
    return (
      <FollowUpKadivManagementWrapper defaultOpenedDetail={isDefaultDetail} />
    );
  return <Redirect to={PATH.HOME} />;
};

export default FollowUpWrapper;
