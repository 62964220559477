import createEncryptor from 'redux-persist-transform-encrypt';
import createTransform from 'redux-persist/es/createTransform';
import storage from 'redux-persist/lib/storage';
import omitBy from 'lodash/omitBy';
import {WebStorage} from 'redux-persist/es/types';

interface StoreConfig {
  key: string;
  storage: WebStorage;
  whitelist: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transforms: any[]; //using any since the typing from redux persist accept any
}

interface ReduxPersistConfig {
  active: boolean;
  reducerVersion: string;
  storeConfig: StoreConfig;
}

const encryptor: unknown = createEncryptor({
  secretKey: 'this-is-secret-key',
});

const blacklistTransform = createTransform(
  (inboundState: Record<string, unknown>) => {
    const newInboundState = omitBy(
      inboundState as Record<string, unknown>,
      (value, stateKey) =>
        stateKey.includes('Loading') ||
        stateKey === 'action' ||
        stateKey === 'notify',
    );
    return newInboundState;
  },
);

const reduxPersist: ReduxPersistConfig = {
  active: true,
  reducerVersion: '1.0',
  storeConfig: {
    key: 'root',
    storage: storage,
    whitelist: ['user', 'wrapper'],
    transforms: [blacklistTransform, encryptor],
  },
};
export default reduxPersist;
