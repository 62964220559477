//#region IMPORT
// Libraries
import {Grid} from '@mui/material';
import React from 'react';
// Utils
import {getAlphabeticNum} from '../../../../utils';
// Components
import {EvaluationReportCardContent} from '..';
// Data
import {
  EvaluationDetailIndicator,
  EvaluationReportFindings,
  EvaluationReportPreviewFindings,
} from '../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportIndicator.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  reviewIndex: number;
  indicatorData?: EvaluationReportFindings;
  handlePreview(_previewFindings: EvaluationReportPreviewFindings): void;
}
//#endregion

const EvaluationReportIndicator: React.FC<Props> = ({
  reviewIndex,
  indicatorData,
  handlePreview,
}: Props) => {
  //#region SINGLE INDICATOR
  const singleIndicator = () => {
    const singleIndicatorContent: EvaluationReportPreviewFindings = {
      name: indicatorData?.reviewIndicatorName
        ? `${reviewIndex}. ${indicatorData?.reviewIndicatorName}`
        : '',
      value: indicatorData?.indicatorValue ?? '',
      image: indicatorData?.indicatorImg ?? '',
    };

    return (
      <Grid
        item
        xs={6}
        key={`evaluation-report-indicator__detail-${indicatorData?.reviewIndicatorId}-${reviewIndex}`}
        onClick={(e) => {
          e.stopPropagation();
          !!singleIndicatorContent.image &&
            handlePreview(singleIndicatorContent);
        }}>
        <div
          className={`evaluation-report-indicator__card${
            singleIndicatorContent.image ? 's' : ''
          }`}>
          <EvaluationReportCardContent contentData={singleIndicatorContent} />
        </div>
      </Grid>
    );
  };
  //#endregion

  //#region MULTI INDICATOR
  const multiIndicator = (multiIndicator: EvaluationDetailIndicator[]) => {
    return (
      <div className="evaluation-report-indicator__multi">
        <div className="evaluation-report-indicator__multi-title">
          {indicatorData?.reviewIndicatorName
            ? `${reviewIndex}. ${indicatorData?.reviewIndicatorName}`
            : ''}
        </div>
        <Grid container spacing={3}>
          {multiIndicator.map(
            (
              multiIndicatorItem: EvaluationDetailIndicator,
              multiIndicatorIdx: number,
            ) => {
              const detailIndicatorContent: EvaluationReportPreviewFindings = {
                name: `${getAlphabeticNum(multiIndicatorIdx + 1)}. ${
                  multiIndicatorItem.detailIndicatorName
                }`,
                value: multiIndicatorItem.indicatorValue,
                image: multiIndicatorItem.indicatorImg,
              };
              return (
                <Grid
                  item
                  xs={6}
                  key={`evaluation-report-indicator__detail-${indicatorData?.reviewIndicatorId}-${multiIndicatorIdx}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    !!detailIndicatorContent.image &&
                      handlePreview(detailIndicatorContent);
                  }}>
                  <div
                    className={`evaluation-report-indicator__card${
                      detailIndicatorContent.image ? 's' : ''
                    }`}>
                    <EvaluationReportCardContent
                      contentData={detailIndicatorContent}
                    />
                  </div>
                </Grid>
              );
            },
          )}
        </Grid>
      </div>
    );
  };
  //#endregion

  //#region INDICATOR RESULT
  const indicatorResult = () => {
    const detailIndicators: EvaluationDetailIndicator[] =
      indicatorData?.detailIndicators || [];

    if (detailIndicators && !!detailIndicators?.length) {
      return multiIndicator(detailIndicators);
    }
    return singleIndicator();
  };
  //#endregion

  return <div className="evaluation-report-indicator">{indicatorResult()}</div>;
};
export default EvaluationReportIndicator;
