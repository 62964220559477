import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataWorkInstructionEditInitialState} from './masterDataWorkInstructionEdit.reducer';
import {
  MasterDataWorkInstructionEditAction,
  MasterDataWorkInstructionEditFailedPayload,
  MasterDataWorkInstructionEditFetchPayload,
  MasterDataWorkInstructionEditSuccessPayload,
} from './masterDataWorkInstructionEdit.type';

export const masterDataWorkInstructionEditHandler = {
  [MasterDataWorkInstructionEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataWorkInstructionEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataWorkInstructionEditLoading: true,
    masterDataWorkInstructionEditParams: action.payload,
    action: MasterDataWorkInstructionEditAction.FETCH,
  }),
  [MasterDataWorkInstructionEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataWorkInstructionEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataWorkInstructionEditParams: undefined,
    masterDataWorkInstructionEditLoading: false,
    masterDataWorkInstructionEditResponse: action.payload,
    action: MasterDataWorkInstructionEditAction.SUCCESS,
  }),
  [MasterDataWorkInstructionEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataWorkInstructionEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataWorkInstructionEditLoading: false,
    masterDataWorkInstructionEditError: action.payload,
    action: MasterDataWorkInstructionEditAction.FAILED,
  }),
  [MasterDataWorkInstructionEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataWorkInstructionEditInitialState,
    action: MasterDataWorkInstructionEditAction.RESET,
  }),
};
