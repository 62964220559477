import {createAction} from '@reduxjs/toolkit';
import {
  UserForgetPasswordAction,
  UserForgetPasswordFailedPayload,
  UserForgetPasswordFetchPayload,
  UserForgetPasswordSuccessPayload,
} from './userForgetPassword.type';

export const userForgetPasswordAction = {
  fetch: createAction<UserForgetPasswordFetchPayload>(
    UserForgetPasswordAction.FETCH,
  ),
  success: createAction<UserForgetPasswordSuccessPayload>(
    UserForgetPasswordAction.SUCCESS,
  ),
  failed: createAction<UserForgetPasswordFailedPayload>(
    UserForgetPasswordAction.FAILED,
  ),
  reset: createAction(UserForgetPasswordAction.RESET),
};
