import {InputLabel, MenuItem, Select} from '@mui/material';
import {FieldProps} from 'formik';
import React from 'react';
import t from '../../lang';
import classnames from 'classnames';
import './MSelectIcon.component.style.scss';
import {IconCaretArrowDown} from '../../assets/icons';

export interface SelectIconItemType {
  label: string;
  icon: string | React.ReactElement;
  value: string;
}
interface Props extends FieldProps<string> {
  disabled?: boolean;
  className?: string;
  errormessage?: string;
  helperText?: string;
  options: SelectIconItemType[];
  label: string;
  placeholder: string;
  iconComponent?: React.ElementType;
  handleChangeSelect?(e: unknown): void;
}

const MSelectIcon: React.FC<Props> = ({
  disabled = false,
  className,
  options,
  field,
  helperText,
  errormessage,
  label,
  placeholder,
  form,
  iconComponent = IconCaretArrowDown,
  handleChangeSelect,
  ...otherProps
}: Props) => {
  const isError = errormessage || form.errors[field.name];
  const classes = classnames(
    'm-selectIcon',
    {
      'm-selectIcon--error': isError,
    },
    className,
  );

  return (
    <div className={`${classes} ${className}`}>
      <InputLabel className="m-selectIcon__label">{label}</InputLabel>
      <Select
        {...field}
        className="m-selectIcon__field"
        placeholder={placeholder}
        displayEmpty
        disabled={disabled}
        IconComponent={iconComponent}
        onChange={(e) => {
          const val = e?.target?.value;
          handleChangeSelect && handleChangeSelect(val);
          form.setFieldValue(field.name, val);
        }}
        renderValue={(selected) => {
          const selectedItem = options.find((item) => item.value === selected);

          if (!selected)
            return (
              <div className="m-selectIcon__field--placeholder">
                {placeholder}
              </div>
            );
          return (
            <div className="m-selectIcon__field--value">
              {selectedItem?.icon}
              {t(selectedItem?.label || '')}
            </div>
          );
        }}
        {...otherProps}>
        {options.map((_option: SelectIconItemType, idx: number) => (
          <MenuItem
            key={`option-item-${_option}-${idx}`}
            className="m-selectIcon__field--item"
            value={_option.value}>
            {_option.icon}
            {t(_option.label)}
          </MenuItem>
        ))}
      </Select>

      {(errormessage || form.errors[field.name]) && (
        <div className="m-selectIcon__errorText error--text">
          {errormessage || form.errors[field.name]}
        </div>
      )}
      {helperText && !form.errors && (
        <div className="m-selectIcon__helperText helper-text">{helperText}</div>
      )}
    </div>
  );
};

export default MSelectIcon;
