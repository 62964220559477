import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataBus} from '../../../../entity/masterData.string.entity';

export const MasterDataBusListAction = {
  FETCH: 'MASTER_DATA_BUS_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_BUS_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_BUS_LIST_FAILED',
  RESET: 'MASTER_DATA_BUS_LIST_RESET',
  UPDATE: 'MASTER_DATA_BUS_LIST_UPDATE',
};

export interface MasterDataBusListInitialState {
  masterDataBusListLoading: boolean;
  masterDataBusListData: MasterDataBus[];
  masterDataBusListError?: MasterDataBusListFailedPayload;
  masterDataBusListParams?: MasterDataBusListFetchPayload;
  masterDataBusListResponse?: MasterDataBusListSuccessPayload;
}

export type MasterDataBusListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataBus[]>;
};

export type MasterDataBusListFailedPayload = CommonError;

export type MasterDataBusListSuccessPayload = MasterDataBusListResponse;

export type MasterDataBusListUpdatePayload = MasterDataBusListResponse;

export interface MasterDataBusListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  busType?: string;
}
