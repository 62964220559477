import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBasicServiceTypeListAction,
  MasterDataBasicServiceTypeListFailedPayload,
  MasterDataBasicServiceTypeListFetchPayload,
  MasterDataBasicServiceTypeListSuccessPayload,
  MasterDataBasicServiceTypeListUpdatePayload,
} from './masterDataBasicServiceTypeList.type';

export const masterDataBasicServiceTypeListAction = {
  fetch: createAction<MasterDataBasicServiceTypeListFetchPayload>(
    MasterDataBasicServiceTypeListAction.FETCH,
  ),
  success: createAction<MasterDataBasicServiceTypeListSuccessPayload>(
    MasterDataBasicServiceTypeListAction.SUCCESS,
  ),
  failed: createAction<MasterDataBasicServiceTypeListFailedPayload>(
    MasterDataBasicServiceTypeListAction.FAILED,
  ),
  update: createAction<MasterDataBasicServiceTypeListUpdatePayload>(
    MasterDataBasicServiceTypeListAction.UPDATE,
  ),
  reset: createAction(MasterDataBasicServiceTypeListAction.RESET),
};
