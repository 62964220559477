import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataBusDeleteInitialState} from './masterDataBusDelete.reducer';
import {
  MasterDataBusDeleteAction,
  MasterDataBusDeleteFailedPayload,
  MasterDataBusDeleteFetchPayload,
  MasterDataBusDeleteSuccessPayload,
} from './masterDataBusDelete.type';

export const masterDataBusDeleteHandler = {
  [MasterDataBusDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusDeleteLoading: true,
    masterDataBusDeleteParams: action.payload,
    action: MasterDataBusDeleteAction.FETCH,
  }),
  [MasterDataBusDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusDeleteParams: undefined,
    masterDataBusDeleteLoading: false,
    masterDataBusDeleteResponse: action.payload,
    action: MasterDataBusDeleteAction.SUCCESS,
  }),
  [MasterDataBusDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusDeleteLoading: false,
    masterDataBusDeleteError: action.payload,
    action: MasterDataBusDeleteAction.FAILED,
  }),
  [MasterDataBusDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBusDeleteInitialState,
    action: MasterDataBusDeleteAction.RESET,
  }),
};
