/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  masterDataBasicServiceTypeListAction,
  masterDataBasicServiceTypeDeleteAction,
  masterDataBasicServiceTypeMoveAction,
  masterDataBasicServiceTypeCreateAction,
  masterDataBasicServiceTypeEditAction,
} from '../../../../usecases/masterData.action';
import {
  MasterDataBasicServiceTypeCreateAction,
  MasterDataBasicServiceTypeDeleteAction,
  MasterDataBasicServiceTypeEditAction,
  MasterDataBasicServiceTypeListAction,
  MasterDataBasicServiceTypeMoveAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataBasicServiceTypeManagementList} from './components';
import MasterDataBasicServiceTypeFormCreateContainer from '../MasterDataBasicServiceTypeFormCreateContainer';
import MasterDataBasicServiceTypeFormEditContainer from '../MasterDataBasicServiceTypeFormEditContainer';
// Data
import {MasterDataBasicServiceType} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataBasicServiceTypeSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataBasicServiceTypeSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddBasicServiceType, setAddBasicServiceType] = useState(false);
  const [isDeleteBasicServiceType, setIsDeleteBasicServiceType] = useState<
    MasterDataBasicServiceType | undefined
  >(undefined);
  const [isEditBasicServiceType, setIsEditBasicServiceType] = useState<
    MasterDataBasicServiceType | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddBasicServiceType =
    masterData.masterDataBasicServiceTypeCreateLoading;
  const isLoadingEditBasicServiceType =
    masterData.masterDataBasicServiceTypeEditLoading;
  const isLoadingDeleteBasicServiceType =
    masterData.masterDataBasicServiceTypeDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataBasicServiceTypeListPage,
    setMasterDataBasicServiceTypeListPage,
  ] = useState<number>(0);

  const [
    masterDataBasicServiceTypeListCount,
    setMasterDataBasicServiceTypeListCount,
  ] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataBasicServiceTypeListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataBasicServiceTypeListPage(0);
  };
  //#endregion

  //#region FETCH BASIC SERVICE TYPE
  const fetchBasicServiceTypeList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataBasicServiceTypeListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataBasicServiceTypeListAction.reset());
    fetchBasicServiceTypeList(
      masterDataBasicServiceTypeListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataBasicServiceTypeListAction.reset());
    };
  }, [
    dispatch,
    fetchBasicServiceTypeList,
    masterDataBasicServiceTypeListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataBasicServiceTypeActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataBasicServiceTypeListAction.UPDATE, (): void => {
            setMasterDataBasicServiceTypeListCount(
              masterData?.masterDataBasicServiceTypeListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataBasicServiceTypeListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBasicServiceTypeListAction.reset());
          })
          .addCase(MasterDataBasicServiceTypeCreateAction.SUCCESS, (): void => {
            setAddBasicServiceType(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataBasicServiceTypeCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBasicServiceTypeCreateAction.reset());
          })
          .addCase(MasterDataBasicServiceTypeEditAction.SUCCESS, (): void => {
            setIsEditBasicServiceType(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataBasicServiceTypeEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBasicServiceTypeEditAction.reset());
          })
          .addCase(MasterDataBasicServiceTypeDeleteAction.SUCCESS, (): void => {
            setIsDeleteBasicServiceType(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataBasicServiceTypeDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBasicServiceTypeDeleteAction.reset());
          })
          .addCase(MasterDataBasicServiceTypeMoveAction.SUCCESS, (): void => {
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved success!'),
                type: 'success',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataBasicServiceTypeMoveAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBasicServiceTypeMoveAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataBasicServiceTypeListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataBasicServiceTypeActionHandler(masterData.action);
  }, [masterData.action, masterDataBasicServiceTypeActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataBasicServiceTypeDeleteAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE MOVE
  const handleMove = (id: string, isUp?: boolean) => {
    dispatch(
      masterDataBasicServiceTypeMoveAction.fetch({
        id,
        ...(isUp ? {isUp: true} : {isDown: true}),
        assignmentType,
      }),
    );
  };
  //#endregion

  return (
    <>
      <div className="master-data-basic-service-type-section">
        <div className="master-data-basic-service-type-section__header">
          <MSearchInput
            inputClassName="master-data-basic-service-type-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Basic Service Type')}
            setInputValue={(search: string) => {
              setMasterDataBasicServiceTypeListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-basic-service-type-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddBasicServiceType(true)}
              />
            </div>
          )}
        </div>
        <MasterDataBasicServiceTypeManagementList
          basicServiceTypeList={masterData.masterDataBasicServiceTypeListData}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditBasicServiceType: (
              _basicServiceType: MasterDataBasicServiceType,
            ) => setIsEditBasicServiceType(_basicServiceType),
            onMoveUp: (id: string) => handleMove(id, true),
            onMoveDown: (id: string) => handleMove(id),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteBasicServiceType: (
              _basicServiceType: MasterDataBasicServiceType,
            ) => setIsDeleteBasicServiceType(_basicServiceType),
          })}
        />
        <MTablePagination
          className="master-data-basic-service-type-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataBasicServiceTypeListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataBasicServiceTypeListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddBasicServiceType(false)}
        title={t('Add Basic Service Type')}
        isOpen={isAddBasicServiceType}>
        <MasterDataBasicServiceTypeFormCreateContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddBasicServiceType}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditBasicServiceType && (
        <Modal
          onClose={() => setIsEditBasicServiceType(undefined)}
          isOpen={!!isEditBasicServiceType}
          title={t('Edit Basic Service Type')}>
          <MasterDataBasicServiceTypeFormEditContainer
            assignmentType={assignmentType}
            data={isEditBasicServiceType}
            isLoading={isLoadingEditBasicServiceType}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteBasicServiceType && (
        <AlertDialog
          onClose={() => setIsDeleteBasicServiceType(undefined)}
          onCancel={() => setIsDeleteBasicServiceType(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteBasicServiceType.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteBasicServiceType}
          isLoading={isLoadingDeleteBasicServiceType}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddBasicServiceType(false)
              : setIsEditBasicServiceType(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataBasicServiceTypeSection;
