interface colorsType {
  primary: {
    blue: string;
    blueBackground: string;
    blue1: string;
    blue2: string;
    blue3: string;
    blue4: string;
    blue5: string;
    blue6: string;
    blue7: string;
    blue8: string;
    blue9: string;
  };
  secondary: {
    green: string;
    green1: string;
    green2: string;
    green3: string;
    green4: string;
    red: string;
    red1: string;
    red2: string;
    red3: string;
    red4: string;
    red5: string;
    red6: string;
    yellow: string;
    yellow1: string;
  };

  text: {
    primary: string;
    lightGrey1: string;
    lightGrey2: string;
    lightGrey3: string;
    lightGrey4: string;
    lightGrey5: string;
    lightGrey6: string;
    lightGrey7: string;
    lightGrey8: string;
    lightGrey9: string;
  };
  light: string;
  disabled: string;
  border: string;
  grey: string;
  icon: string;
}

export const colors: colorsType = {
  primary: {
    blue: '#4A90E2',
    blueBackground: '#4F7FC',
    blue1: '#F4F7FC',
    blue2: '#EDF4FC',
    blue3: '#DBE9F9',
    blue4: '#C9DEF6',
    blue5: '#A4C7F0',
    blue6: '#4383CE',
    blue7: '#3972B6',
    blue8: '#106FDA',
    blue9: '#EFF8FC',
  },
  secondary: {
    green: '#27AE60',
    green1: '#E9F7EF',
    green2: '#D4EFDF',
    green3: '#BEE7CF',
    green4: '#93D6AF',
    red: '#EB5757',
    red1: '#FDEEEE',
    red2: '#FBDDDD',
    red3: '#F9CDCD',
    red4: '#F5ABAB',
    red5: '#D24B4B',
    red6: '#BF4444',
    yellow: '#ECAC4C',
    yellow1: '#FFF2E6',
  },

  text: {
    primary: '#333333',
    lightGrey1: '#F5F5F5',
    lightGrey2: '#EBEBEB',
    lightGrey3: '#E0E0E0',
    lightGrey4: '#BDBDBD',
    lightGrey5: '#8E8E8E',
    lightGrey6: '#757575',
    lightGrey7: '#4F4F4F',
    lightGrey8: '#E5E5E5',
    lightGrey9: '#EBEBEB',
  },
  light: '#FFFFFF',
  disabled: '#BDBDBD',
  border: '#E0E0E0',
  grey: '#8E8E8E',
  icon: '#757575',
};
