//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../lang';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../components';
import {RoleTableWrapper} from '../../../../components';
// Data
import {RoleFormData, UserLevel} from '../../../../entity/user.string.entity';
// Assets
import './RoleForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: RoleFormData;
  handleCancel(): void;
  handleSubmitRole(_role: RoleFormData): void;
}
//#endregion

const RoleForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitRole,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const userLevelOption = [
    {label: `${t('Level')} 0`, value: UserLevel.SUPERUSER},
    {label: `${t('Level')} 1`, value: UserLevel.DISHUB},
    {label: `${t('Level')} 2`, value: UserLevel.DIREKSI},
    {label: `${t('Level')} 3`, value: UserLevel.KEPALADIVISI},
    {label: `${t('Level')} 4`, value: UserLevel.KEPALADEPARTMENT},
    {label: `${t('Level')} 5`, value: UserLevel.KEPALASEKSISPM},
    {label: `${t('Level')} 6`, value: UserLevel.PICDEPARTAMENT},
    {label: `${t('Level')} 7`, value: UserLevel.KOORDINATOR},
    {label: `${t('Level')} 8`, value: UserLevel.PETUGAS},
    {label: `${t('Level')} 9`, value: UserLevel.SPMOFFICER},
  ];
  //#endregion

  //#region VALIDATION
  const roleAccessValidation = Yup.object().shape({
    name: Yup.string()
      .required(t('Role is required.'))
      .min(
        3,
        `${t('Role')} ${t('must be filled in at least')} ${3} ${t('letters')}.`,
      ),
    userLevel: Yup.string().required(t('Level is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="role-form">
      <div className="role-form__container">
        <Formik
          initialValues={initialValues}
          validationSchema={roleAccessValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={(value) => {
            handleSubmitRole(value);
          }}>
          {({
            handleSubmit,
            values,
            isValid,
            setFieldValue,
          }): React.ReactElement => (
            <>
              <div className="role-form__section">
                <div className="role-form__container--section">
                  <div className="role-form__container--section-field">
                    <div className="input-label">{t('Role')}</div>
                    <Field
                      className="detail-input-multi"
                      name="name"
                      disabled={isLoading}
                      placeholder={t('Enter Role')}
                      component={MTextField}
                    />
                  </div>
                  <div className="role-form__container--section-field">
                    <div className="input-label">{t('Level')}</div>
                    <Field
                      className="detail-input-multi"
                      name="userLevel"
                      placeholder={t('Choose Level')}
                      options={userLevelOption}
                      disabled={isLoading}
                      component={MSelect}
                    />
                  </div>
                </div>
              </div>
              <div className="role-form__section">
                <div className="role-form__container--section">
                  <RoleTableWrapper
                    roleValues={values.permissionType}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div className="role-form__action">
                <div className="role-form__action--button">
                  <MButton
                    isOutline
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="role-form__action--button">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default RoleForm;
