import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBasicServiceTypeOptionListAction,
  MasterDataBasicServiceTypeOptionListFailedPayload,
  MasterDataBasicServiceTypeOptionListFetchPayload,
  MasterDataBasicServiceTypeOptionListSuccessPayload,
  MasterDataBasicServiceTypeOptionListUpdatePayload,
} from './masterDataBasicServiceTypeOptionList.type';

export const masterDataBasicServiceTypeOptionListAction = {
  fetch: createAction<MasterDataBasicServiceTypeOptionListFetchPayload>(
    MasterDataBasicServiceTypeOptionListAction.FETCH,
  ),
  success: createAction<MasterDataBasicServiceTypeOptionListSuccessPayload>(
    MasterDataBasicServiceTypeOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataBasicServiceTypeOptionListFailedPayload>(
    MasterDataBasicServiceTypeOptionListAction.FAILED,
  ),
  update: createAction<MasterDataBasicServiceTypeOptionListUpdatePayload>(
    MasterDataBasicServiceTypeOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataBasicServiceTypeOptionListAction.RESET),
};
