/*
 * Disable any since using object literal to get value object
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {TableContainer, Paper, Table, TableBody, Collapse} from '@mui/material';
// Utils
import t from '../../../../lang';
// Components
import RoleTableHeader from '../RoleTableHeader';
import RoleTableBodyItem from '../RoleTableBodyItem';
import RoleTableCollapse from '../RoleTableCollapse';
// Data
import {
  BackOfficePermissionType,
  MobilePermissionType,
  RolePermission,
  RoleTableData,
} from '../../entity/user.string.entity';
// Assets
import './RoleTable.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  title: string;
  headerTitle?: string;
  isDisabled?: boolean;
  valueType?: 'standard' | 'single' | 'download' | 'export';
  isMobilePermission?: boolean;
  roleValues?: MobilePermissionType | BackOfficePermissionType;
  permissionList: RoleTableData[];
  setFieldValue?: (
    field: string,
    value: RolePermission | boolean,
    shouldValidate?: boolean | undefined,
  ) => void;
}
//#endregion

const RoleTable: React.FC<Props> = ({
  title,
  isDisabled,
  valueType = 'standard',
  headerTitle,
  permissionList,
  isMobilePermission,
  roleValues,
  setFieldValue,
}: Props) => {
  //#region GENERAL
  const [isCollapseOpened, setIsCollapseOpened] = useState(true);
  //#endregion

  //#region HANDLE COLLAPSE
  const handleCollapse = () => {
    setIsCollapseOpened(!isCollapseOpened);
  };
  //#endregion

  return (
    <div className="role-table">
      <RoleTableCollapse
        title={title}
        isCollapseOpened={isCollapseOpened}
        handleCollapse={handleCollapse}
      />
      <Collapse in={isCollapseOpened} timeout="auto" unmountOnExit>
        <TableContainer
          variant="outlined"
          component={Paper}
          className="role-table__container">
          <Table className="role-table__container-table">
            <RoleTableHeader headerTitle={headerTitle} valueType={valueType} />
            <TableBody>
              {permissionList &&
                permissionList.map((item: RoleTableData, idx: number) => {
                  const _permissionValue: any = item?.permissions
                    ? roleValues
                      ? (roleValues as any)[item.permissions]
                      : undefined
                    : undefined;
                  return (
                    <RoleTableBodyItem
                      valueType={valueType}
                      onClickCheckboxSingleValue={(value: boolean) => {
                        setFieldValue &&
                          setFieldValue(
                            `permissionType.${
                              isMobilePermission
                                ? 'mobilePermissions.'
                                : 'backOfficePermissions.'
                            }${item.permissions}`,
                            value,
                          );
                      }}
                      permissionValues={_permissionValue}
                      key={`role-table-item-${idx}`}
                      label={t(item.label)}
                      isDisabled={isDisabled}
                      onClickCheckbox={(permissionValue: RolePermission) => {
                        setFieldValue &&
                          setFieldValue(
                            `permissionType.${
                              isMobilePermission
                                ? 'mobilePermissions.'
                                : 'backOfficePermissions.'
                            }${item.permissions}`,
                            permissionValue,
                          );
                      }}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
};

export default RoleTable;
