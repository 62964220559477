import React from 'react';
import Button from '@mui/material/Button';
import './MImageUpload.scss';

interface Props {
  label: string;
  labelClassName: string;
  inputRef: React.RefObject<HTMLInputElement>;
  handleUpload(e: React.ChangeEvent<HTMLInputElement>): void;
}

const MImageUpload: React.FC<Props> = ({
  label,
  labelClassName,
  inputRef,
  handleUpload,
}: Props) => {
  return (
    <div className="m-image-upload">
      <input
        accept="image/*"
        className="m-image-upload-input"
        id="m-image-upload-input"
        type="file"
        ref={inputRef}
        onChange={handleUpload}
      />
      <label htmlFor="m-image-upload-input">
        <div className={`m-image-upload-label ${labelClassName}`}>
          <Button component="span">{label}</Button>
        </div>
      </label>
    </div>
  );
};

export default MImageUpload;
