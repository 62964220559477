import {CommonError} from '../../../../../../config/constant';

export const MasterDataDetailIndicatorDeleteAction = {
  FETCH: 'MASTER_DATA_DETAIL_Indicator_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_Indicator_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_Indicator_DELETE_FAILED',
  RESET: 'MASTER_DATA_DETAIL_Indicator_DELETE_RESET',
};

export interface MasterDataDetailIndicatorDeleteInitialState {
  masterDataDetailIndicatorDeleteLoading: boolean;
  masterDataDetailIndicatorDeleteParams?: MasterDataDetailIndicatorDeleteFetchPayload;
  masterDataDetailIndicatorDeleteError?: MasterDataDetailIndicatorDeleteFailedPayload;
  masterDataDetailIndicatorDeleteResponse?: MasterDataDetailIndicatorDeleteSuccessPayload;
}

export interface MasterDataDetailIndicatorDeleteFetchPayload {
  id: string;
}
export type MasterDataDetailIndicatorDeleteFailedPayload = CommonError;

export interface MasterDataDetailIndicatorDeleteSuccessPayload {}
