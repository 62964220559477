import {createAction} from '@reduxjs/toolkit';
import {
  FollowUpListAction,
  FollowUpListFailedPayload,
  FollowUpListFetchPayload,
  FollowUpListSuccessPayload,
  FollowUpListUpdatePayload,
} from './followUpList.type';

export const followUpListAction = {
  fetch: createAction<FollowUpListFetchPayload>(FollowUpListAction.FETCH),
  success: createAction<FollowUpListSuccessPayload>(FollowUpListAction.SUCCESS),
  failed: createAction<FollowUpListFailedPayload>(FollowUpListAction.FAILED),
  update: createAction<FollowUpListUpdatePayload>(FollowUpListAction.UPDATE),
  reset: createAction(FollowUpListAction.RESET),
};
