import {CommonError} from '../../../../config/constant';

export const UserChangePasswordAction = {
  FETCH: 'USER_CHANGE_PASSWORD_FETCH',
  SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
  FAILED: 'USER_CHANGE_PASSWORD_FAILED',
  RESET: 'USER_CHANGE_PASSWORD_RESET',
};

export interface UserChangePasswordInitialState {
  userChangePasswordLoading: boolean;
  userChangePasswordParams?: UserChangePasswordFetchPayload;
  userChangePasswordError?: UserChangePasswordFailedPayload;
  userChangePasswordResponse?: UserChangePasswordSuccessPayload;
}

export type UserChangePasswordFetchPayload = {
  password: string;
  confPassword: string;
};

export type UserChangePasswordFailedPayload = CommonError;

export type UserChangePasswordSuccessPayload = unknown;
