import React from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import MSpinner from '../MSpinner/index';
import './MButton.scss';

interface Props {
  buttonLabel: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOutline?: boolean;
  className?: boolean;
  startIcon?: React.ReactElement;
  handleClick(): void;
}

const MButton: React.FC<Props> = ({
  isLoading,
  isDisabled,
  isOutline,
  buttonLabel,
  handleClick,
  startIcon,
}: Props) => {
  const _isDisabled = isLoading || isDisabled;
  const classes = classnames({
    'm-button-disabled': _isDisabled,
    'm-button-outline': isOutline,
    'm-button': !_isDisabled && !isOutline,
  });

  return (
    <Button
      disabled={_isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      startIcon={isLoading ? <MSpinner size={15} color="inherit" /> : startIcon}
      className={classes}>
      {buttonLabel}
    </Button>
  );
};

export default MButton;
