import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataFormControllerCreateInitialState} from './masterDataFormControllerCreate.reducer';
import {
  MasterDataFormControllerCreateAction,
  MasterDataFormControllerCreateFailedPayload,
  MasterDataFormControllerCreateFetchPayload,
  MasterDataFormControllerCreateSuccessPayload,
} from './masterDataFormControllerCreate.type';

export const masterDataFormControllerCreateHandler = {
  [MasterDataFormControllerCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerCreateLoading: true,
    masterDataFormControllerCreateParams: action.payload,
    action: MasterDataFormControllerCreateAction.FETCH,
  }),
  [MasterDataFormControllerCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerCreateParams: undefined,
    masterDataFormControllerCreateLoading: false,
    masterDataFormControllerCreateResponse: action.payload,
    action: MasterDataFormControllerCreateAction.SUCCESS,
  }),
  [MasterDataFormControllerCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerCreateLoading: false,
    masterDataFormControllerCreateError: action.payload,
    action: MasterDataFormControllerCreateAction.FAILED,
  }),
  [MasterDataFormControllerCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataFormControllerCreateInitialState,
    action: MasterDataFormControllerCreateAction.RESET,
  }),
};
