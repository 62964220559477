import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataIndicatorValueCreateInitialState} from './masterDataIndicatorValueCreate.reducer';
import {
  MasterDataIndicatorValueCreateAction,
  MasterDataIndicatorValueCreateFailedPayload,
  MasterDataIndicatorValueCreateFetchPayload,
  MasterDataIndicatorValueCreateSuccessPayload,
} from './masterDataIndicatorValueCreate.type';

export const masterDataIndicatorValueCreateHandler = {
  [MasterDataIndicatorValueCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueCreateLoading: true,
    masterDataIndicatorValueCreateParams: action.payload,
    action: MasterDataIndicatorValueCreateAction.FETCH,
  }),
  [MasterDataIndicatorValueCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueCreateParams: undefined,
    masterDataIndicatorValueCreateLoading: false,
    masterDataIndicatorValueCreateResponse: action.payload,
    action: MasterDataIndicatorValueCreateAction.SUCCESS,
  }),
  [MasterDataIndicatorValueCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueCreateLoading: false,
    masterDataIndicatorValueCreateError: action.payload,
    action: MasterDataIndicatorValueCreateAction.FAILED,
  }),
  [MasterDataIndicatorValueCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataIndicatorValueCreateInitialState,
    action: MasterDataIndicatorValueCreateAction.RESET,
  }),
};
