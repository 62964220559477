import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDirectorateDeleteAction,
  MasterDataDirectorateDeleteFailedPayload,
  MasterDataDirectorateDeleteFetchPayload,
  MasterDataDirectorateDeleteSuccessPayload,
} from './masterDataDirectorateDelete.type';

export const masterDataDirectorateDeleteAction = {
  fetch: createAction<MasterDataDirectorateDeleteFetchPayload>(
    MasterDataDirectorateDeleteAction.FETCH,
  ),
  success: createAction<MasterDataDirectorateDeleteSuccessPayload>(
    MasterDataDirectorateDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataDirectorateDeleteFailedPayload>(
    MasterDataDirectorateDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataDirectorateDeleteAction.RESET),
};
