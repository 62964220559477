import {GenericAction} from '../../../../config/constant';
import {WorkReportState} from '../workReport.reducer';
import {workReportUploadInitialState} from './workReportUpload.reducer';
import {
  WorkReportUploadAction,
  WorkReportUploadFailedPayload,
  WorkReportUploadFetchPayload,
  WorkReportUploadSuccessPayload,
} from './workReportUpload.type';

export const workReportUploadHandler = {
  [WorkReportUploadAction.FETCH]: (
    state: WorkReportState,
    action: GenericAction<WorkReportUploadFetchPayload>,
  ): WorkReportState => ({
    ...state,
    workReportUploadLoading: true,
    workReportUploadParams: action.payload,
    action: WorkReportUploadAction.FETCH,
  }),
  [WorkReportUploadAction.SUCCESS]: (
    state: WorkReportState,
    action: GenericAction<WorkReportUploadSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportUploadData: action.payload.data,
    workReportUploadParams: undefined,
    workReportUploadLoading: false,
    workReportUploadResponse: action.payload,
    action: WorkReportUploadAction.SUCCESS,
  }),
  [WorkReportUploadAction.FAILED]: (
    state: WorkReportState,
    action: GenericAction<WorkReportUploadFailedPayload>,
  ): WorkReportState => ({
    ...state,
    workReportUploadLoading: false,
    workReportUploadError: action.payload,
    action: WorkReportUploadAction.FAILED,
  }),
  [WorkReportUploadAction.RESET]: (
    state: WorkReportState,
  ): WorkReportState => ({
    ...state,
    ...workReportUploadInitialState,
    action: WorkReportUploadAction.RESET,
  }),
};
