import {createAction} from '@reduxjs/toolkit';
import {
  UserAccountDetailAction,
  UserAccountDetailFailedPayload,
  UserAccountDetailFetchPayload,
  UserAccountDetailSuccessPayload,
} from './userAccountDetail.type';

export const userAccountDetailAction = {
  fetch: createAction<UserAccountDetailFetchPayload>(
    UserAccountDetailAction.FETCH,
  ),
  success: createAction<UserAccountDetailSuccessPayload>(
    UserAccountDetailAction.SUCCESS,
  ),
  failed: createAction<UserAccountDetailFailedPayload>(
    UserAccountDetailAction.FAILED,
  ),
  reset: createAction(UserAccountDetailAction.RESET),
};
