import {
  MasterDataIndicatorValueListAction,
  MasterDataIndicatorValueListSuccessPayload,
  MasterDataIndicatorValueListFailedPayload,
  MasterDataIndicatorValueListFetchPayload,
} from './masterDataIndicatorValueList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataIndicatorValueListInitialState} from './masterDataIndicatorValueList.reducer';

export const masterDataIndicatorValueListHandler = {
  [MasterDataIndicatorValueListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataIndicatorValueListLoading: true,
      masterDataIndicatorValueListParams: action.payload,
      action: MasterDataIndicatorValueListAction.FETCH,
    };
  },
  [MasterDataIndicatorValueListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueListLoading: false,
    masterDataIndicatorValueListResponse: action.payload,
    action: MasterDataIndicatorValueListAction.SUCCESS,
  }),
  [MasterDataIndicatorValueListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueListLoading: false,
    masterDataIndicatorValueListData: [
      ...state.masterDataIndicatorValueListData,
      ...action.payload.data.content,
    ],
    action: MasterDataIndicatorValueListAction.UPDATE,
  }),
  [MasterDataIndicatorValueListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueListLoading: false,
    masterDataIndicatorValueListError: action.payload,
    action: MasterDataIndicatorValueListAction.FAILED,
  }),
  [MasterDataIndicatorValueListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataIndicatorValueListInitialState,
    action: MasterDataIndicatorValueListAction.RESET,
  }),
};
