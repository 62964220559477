/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  MasterDataObservationValueCreateAction,
  MasterDataObservationValueDeleteAction,
  MasterDataObservationValueEditAction,
  MasterDataObservationValueListAction,
  MasterDataObservationValueSetPositiveValueAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {
  masterDataObservationValueCreateAction,
  masterDataObservationValueDeleteAction,
  masterDataObservationValueEditAction,
  masterDataObservationValueListAction,
  masterDataObservationValueSetPositiveValueAction,
} from '../../../../usecases/masterData.action';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import MasterDataObservationValueFormCreateContainer from '../MasterDataObservationValueFormCreateContainer';
import MasterDataObservationValueFormEditContainer from '../MasterDataObservationValueFormEditContainer';
import {ObservationValueManagementList} from './components';
// Data
import {
  MasterDataObservationValue,
  MasterDataObservationValueOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataObservationValueSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataObservationValueSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddObservationValue, setAddObservationValue] = useState(false);
  const [isDeleteObservationValue, setIsDeleteObservationValue] = useState<
    MasterDataObservationValue | undefined
  >(undefined);
  const [isEditObservationValue, setIsEditObservationValue] = useState<
    MasterDataObservationValue | undefined
  >(undefined);
  const [isChangeStatusValue, setIsChangeStatusValue] = useState<
    MasterDataObservationValue | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddObservationValue =
    masterData.masterDataObservationValueCreateLoading;
  const isLoadingEditObservationValue =
    masterData.masterDataObservationValueEditLoading;
  const isLoadingDeleteObservationValue =
    masterData.masterDataObservationValueDeleteLoading;
  const isLoadingSetPositiveValueObservationValue =
    masterData.masterDataObservationValueSetPositiveValueLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataObservationValueListPage,
    setMasterDataObservationValueListPage,
  ] = useState<number>(0);
  const [
    masterDataObservationValueListCount,
    setMasterDataObservationValueListCount,
  ] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataObservationValueListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataObservationValueListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataObservationValueOrderByType>(
    'name',
  );

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataObservationValueOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH OBSERVATION VALUE
  const fetchObservationValueList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataObservationValueListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataObservationValueListAction.reset());
    fetchObservationValueList(
      masterDataObservationValueListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataObservationValueListAction.reset());
    };
  }, [
    dispatch,
    fetchObservationValueList,
    masterDataObservationValueListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataObservationValueActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataObservationValueListAction.UPDATE, (): void => {
            setMasterDataObservationValueListCount(
              masterData?.masterDataObservationValueListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataObservationValueListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationValueListAction.reset());
          })
          .addCase(MasterDataObservationValueCreateAction.SUCCESS, (): void => {
            setAddObservationValue(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataObservationValueCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationValueCreateAction.reset());
          })
          .addCase(MasterDataObservationValueEditAction.SUCCESS, (): void => {
            setIsEditObservationValue(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataObservationValueEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationValueEditAction.reset());
          })
          .addCase(MasterDataObservationValueDeleteAction.SUCCESS, (): void => {
            setIsDeleteObservationValue(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataObservationValueDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationValueDeleteAction.reset());
          })
          .addCase(
            MasterDataObservationValueSetPositiveValueAction.SUCCESS,
            (): void => {
              setIsChangeStatusValue(undefined);
              refresh();
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Positive value update success!'),
                  type: 'success',
                  isSnackbar: true,
                  action: WrapperNotifyAction.FETCH,
                }),
              );
            },
          )
          .addCase(
            MasterDataObservationValueSetPositiveValueAction.FAILED,
            (): void => {
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Positive value update failed!'),
                  type: 'error',
                  isSnackbar: true,
                  action: WrapperNotifyAction.FETCH,
                }),
              );
              dispatch(
                masterDataObservationValueSetPositiveValueAction.reset(),
              );
            },
          );
      });
    },
    [
      dispatch,
      masterData?.masterDataObservationValueListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataObservationValueActionHandler(masterData.action);
  }, [masterData.action, masterDataObservationValueActionHandler]);
  //#endregion

  //#region HANDLE SET POSITIVE
  const handleChangeStatus = (id: string) => {
    dispatch(masterDataObservationValueSetPositiveValueAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataObservationValueDeleteAction.fetch({id}));
  };
  //#endregion

  return (
    <>
      <div className="master-data-observation-value-section">
        <div className="master-data-observation-value-section__header">
          <MSearchInput
            inputClassName="master-data-observation-value-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Observation Value')}
            setInputValue={(search: string) => {
              setMasterDataObservationValueListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-observation-value-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddObservationValue(true)}
              />
            </div>
          )}
        </div>
        <ObservationValueManagementList
          observationValueList={masterData.masterDataObservationValueListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditObservationValue: (
              _observationValue: MasterDataObservationValue,
            ) => setIsEditObservationValue(_observationValue),
            onChangeStatus: (_observationValue: MasterDataObservationValue) =>
              setIsChangeStatusValue(_observationValue),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteObservationValue: (
              _observationValue: MasterDataObservationValue,
            ) => setIsDeleteObservationValue(_observationValue),
          })}
        />
        <MTablePagination
          className="master-data-observation-value-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataObservationValueListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataObservationValueListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddObservationValue(false)}
        title={t('Add Observation Value')}
        isOpen={isAddObservationValue}>
        <MasterDataObservationValueFormCreateContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddObservationValue}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditObservationValue && (
        <Modal
          onClose={() => setIsEditObservationValue(undefined)}
          isOpen={!!isEditObservationValue}
          title={t('Edit Observation Value')}>
          <MasterDataObservationValueFormEditContainer
            assignmentType={assignmentType}
            data={isEditObservationValue}
            isLoading={isLoadingEditObservationValue}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteObservationValue && (
        <AlertDialog
          onClose={() => setIsDeleteObservationValue(undefined)}
          onCancel={() => setIsDeleteObservationValue(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteObservationValue.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteObservationValue}
          isLoading={isLoadingDeleteObservationValue}
          image={<IllustrationTrashImage />}
          description={t(
            'Are you sure you want to delete the data?',
          )}></AlertDialog>
      )}

      {isChangeStatusValue && (
        <AlertDialog
          onClose={() => setIsChangeStatusValue(undefined)}
          onCancel={() => setIsChangeStatusValue(undefined)}
          onConfirm={() => handleChangeStatus(isChangeStatusValue.id)}
          cancelText={t('Cancel')}
          confirmText={t('Yes, Agree')}
          isOpen={!!isChangeStatusValue}
          isLoading={isLoadingSetPositiveValueObservationValue}
          title={`
                  ${t('Select Observation Value ')}
                  ${
                    isChangeStatusValue.isPositiveValue
                      ? t('Negative')
                      : t('Positive')
                  }? `}
          isReverseButton={true}
          description={`
            ${t('Selected observation value will be ')}
            ${
              isChangeStatusValue.isPositiveValue
                ? t('negative value')
                : t('positive value')
            }? `}></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddObservationValue(false)
              : setIsEditObservationValue(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataObservationValueSection;
