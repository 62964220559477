import {CommonData, CommonError} from '../../../../config/constant';
import {NotificationProps} from '../../entity/notification.string.entity';

export const NotificationListAction = {
  FETCH: 'NOTIFICATION_LIST_FETCH',
  SUCCESS: 'NOTIFICATION_LIST_SUCCESS',
  FAILED: 'NOTIFICATION_LIST_FAILED',
  RESET: 'NOTIFICATION_LIST_RESET',
  UPDATE: 'NOTIFICATION_LIST_UPDATE',
};

export interface NotificationListInitialState {
  notificationListLoading: boolean;
  notificationListData: NotificationProps[];
  notificationListError?: NotificationListFailedPayload;
  notificationListParams?: NotificationListFetchPayload;
  notificationListResponse?: NotificationListSuccessPayload;
}

export type NotificationListResponse = {
  status: string;
  count?: number;
  data: CommonData<NotificationProps[]>;
};

export type NotificationListFailedPayload = CommonError;

export type NotificationListSuccessPayload = NotificationListResponse;

export type NotificationListUpdatePayload = NotificationListResponse;

export interface NotificationListFetchPayload {
  size?: number;
  page?: number;
}
