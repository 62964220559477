import {CommonError} from '../../../../config/constant';
import {WorkReportFormData} from '../../entity/workReport.string.entity';

export const WorkReportEditAction = {
  FETCH: 'WORK_REPORT_EDIT_FETCH',
  SUCCESS: 'WORK_REPORT_EDIT_SUCCESS',
  FAILED: 'WORK_REPORT_EDIT_FAILED',
  RESET: 'WORK_REPORT_EDIT_RESET',
};

export interface WorkReportEditInitialState {
  workReportEditLoading: boolean;
  workReportEditParams?: WorkReportEditFetchPayload;
  workReportEditError?: WorkReportEditFailedPayload;
  workReportEditResponse?: WorkReportEditSuccessPayload;
}

export type WorkReportEditResponse = {
  status: string;
  info?: number;
  data: [];
};

export type WorkReportEditFetchPayload = WorkReportFormData;

export type WorkReportEditFailedPayload = CommonError;

export type WorkReportEditSuccessPayload = WorkReportEditResponse;
