import {mdiMagnify} from '@mdi/js';
import Icon from '@mdi/react';
import {TextField, InputAdornment} from '@mui/material';
import React from 'react';
import './MSearchInput.scss';

interface Props {
  inputClassName?: string;
  inputValue: string;
  placeholder?: string;
  setInputValue(val: string): void;
  handleSearch?(_inputValue: string): void;
}

const MSearchInput: React.FC<Props> = ({
  inputClassName,
  inputValue,
  placeholder,
  setInputValue,
  handleSearch,
}: Props) => {
  return (
    <div className="m-search-input">
      <TextField
        className={`m-search-input__field ${inputClassName || ''}`}
        type="text"
        value={inputValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <div className="input-prefix-icon">
                <Icon path={mdiMagnify} />
              </div>
            </InputAdornment>
          ),
        }}
        placeholder={placeholder || 'filter'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          const val = e.currentTarget.value;
          const isPrefixWhiteSpacePrevented = /^$|^\S/.test(val);
          const isDoubleWhiteSpace = /\s{2,}/.test(val);
          if (isPrefixWhiteSpacePrevented && !isDoubleWhiteSpace) {
            setInputValue(val);
          }
        }}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>): void => {
          const key = e.keyCode ? e.keyCode : e.charCode;
          if (handleSearch && key === 13) {
            handleSearch(inputValue);
          }
        }}
        onBlur={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ): void => {
          const val = e.currentTarget.value;
          handleSearch && handleSearch(val);
        }}
      />
    </div>
  );
};
export default MSearchInput;
