import {
  MasterDataDetailIndicatorOptionListAction,
  MasterDataDetailIndicatorOptionListSuccessPayload,
  MasterDataDetailIndicatorOptionListFailedPayload,
  MasterDataDetailIndicatorOptionListFetchPayload,
} from './masterDataDetailIndicatorOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDetailIndicatorOptionListInitialState} from './masterDataDetailIndicatorOptionList.reducer';

export const masterDataDetailIndicatorOptionListHandler = {
  [MasterDataDetailIndicatorOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDetailIndicatorOptionListLoading: true,
      masterDataDetailIndicatorOptionListParams: action.payload,
      action: MasterDataDetailIndicatorOptionListAction.FETCH,
    };
  },
  [MasterDataDetailIndicatorOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorOptionListLoading: false,
    masterDataDetailIndicatorOptionListResponse: action.payload,
    action: MasterDataDetailIndicatorOptionListAction.SUCCESS,
  }),
  [MasterDataDetailIndicatorOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorOptionListLoading: false,
    masterDataDetailIndicatorOptionListData: [
      ...state.masterDataDetailIndicatorOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataDetailIndicatorOptionListAction.UPDATE,
  }),
  [MasterDataDetailIndicatorOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorOptionListLoading: false,
    masterDataDetailIndicatorOptionListError: action.payload,
    action: MasterDataDetailIndicatorOptionListAction.FAILED,
  }),
  [MasterDataDetailIndicatorOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailIndicatorOptionListInitialState,
    action: MasterDataDetailIndicatorOptionListAction.RESET,
  }),
};
