import * as React from 'react';

const IconUpload = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 21}
    height={props.height || 20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.261 5.858c.699-1.047 2.261-2.524 4.656-2.524 1.775 0 3.042.9 3.84 1.942a5.943 5.943 0 0 1 1.07 2.287 4.948 4.948 0 0 1 2.088.845c.996.716 1.752 1.904 1.752 3.676 0 1.772-.756 2.96-1.752 3.677-.953.686-2.073.906-2.831.906h-3.75V11.18l1.078 1.078a.833.833 0 0 0 1.178-1.179l-2.5-2.5a.833.833 0 0 0-1.179 0l-2.5 2.5a.835.835 0 1 0 1.179 1.179l1.077-1.078v5.488H6.751c-.898 0-2.225-.262-3.352-1.073-1.17-.842-2.065-2.238-2.065-4.343S2.23 7.75 3.399 6.907c.937-.674 2.01-.969 2.862-1.05v.001Z"
      fill={props.fill || '#4A90E2'}
    />
  </svg>
);

export default IconUpload;
