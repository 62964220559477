import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {post} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataObservationCreateAction} from './masterDataObservationCreate.action';
import {
  MasterDataObservationCreateAction,
  MasterDataObservationCreateFetchPayload,
  MasterDataObservationCreateSuccessPayload,
} from './masterDataObservationCreate.type';

function* workerFetch(
  action: GenericAction<MasterDataObservationCreateFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);

  try {
    const res: AxiosResponse<MasterDataObservationCreateSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/observation/save`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataObservationCreateAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(
      masterDataObservationCreateAction.failed(e.response.data.payload),
    );
  }
}

const masterDataObservationCreateWatcher = [
  takeLatest(MasterDataObservationCreateAction.FETCH, workerFetch),
];

export default masterDataObservationCreateWatcher;
