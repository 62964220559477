//#region IMPORT
// Libraries
import React from 'react';
import moment, {Moment} from 'moment';
// Utils
import t from '../../../../lang';
// Components
import {MDatepicker, MDropdown} from '../../../../components';
// Assets
import './WorkReportFilter.component.style.scss';
//#endregion

export interface OptionType {
  label: string;
  value: string;
}

//#region INTERFACE
interface Props {
  selectedWorkReportTypeFilter?: string | null;
  workReportTypeFilterOptions?: OptionType[];
  workReportTypeFilterPlaceholder?: string;
  handleWorkReportTypeFilter?(workReportType: string): void;
  selectedDepartmentFilter?: string | null;
  departmentFilterOptions?: OptionType[];
  departmentFilterPlaceholder?: string;
  handleDepartmentFilter?(department: string): void;
  selectedDateFilter?: Moment | string | null;
  handleChangeDateFilter?(date: Moment | string | null): void;
  resetFilterLabel?: string;
  handleResetFilter?(): void;
  selectedSort?: string;
  sortLabel?: string;
  sortOptions?: OptionType[];
  handleSort?(sort: string): void;
}
//#endregion

const WorkReportFilter: React.FC<Props> = ({
  selectedWorkReportTypeFilter,
  workReportTypeFilterOptions,
  workReportTypeFilterPlaceholder = t('Report Type'),
  handleWorkReportTypeFilter,
  selectedDepartmentFilter,
  departmentFilterOptions,
  departmentFilterPlaceholder = t('Department'),
  handleDepartmentFilter,
  selectedDateFilter,
  handleChangeDateFilter,
  resetFilterLabel = t('Reset Filter'),
  handleResetFilter,
  selectedSort,
  sortLabel = t('Sort:'),
  sortOptions,
  handleSort,
}: Props) => {
  return (
    <div className="work-report-filter">
      {workReportTypeFilterOptions && (
        <div className="work-report-filter__type">
          <div className="work-report-filter__type-input">
            <MDropdown
              placeholder={workReportTypeFilterPlaceholder}
              options={workReportTypeFilterOptions}
              inputValue={selectedWorkReportTypeFilter || ''}
              handleChangeSelect={handleWorkReportTypeFilter}
            />
          </div>
        </div>
      )}
      {departmentFilterOptions && (
        <div className="work-report-filter__department">
          <div className="work-report-filter__department-input">
            <MDropdown
              placeholder={departmentFilterPlaceholder}
              options={departmentFilterOptions}
              inputValue={selectedDepartmentFilter || ''}
              handleChangeSelect={handleDepartmentFilter}
            />
          </div>
        </div>
      )}
      {handleChangeDateFilter && (
        <div className="work-report-filter__date">
          <MDatepicker
            inputValue={selectedDateFilter || ''}
            placeholder={t('Choose Month And Year')}
            toolbarText={t('Choose Month And Year')}
            views={['month', 'year']}
            format="MMMM YYYY"
            maxDate={moment().endOf('year')}
            handleChange={handleChangeDateFilter}
          />
        </div>
      )}
      {handleResetFilter &&
        (selectedWorkReportTypeFilter ||
          selectedDepartmentFilter ||
          selectedDateFilter) && (
          <div
            className="work-report-filter__reset"
            onClick={(e) => {
              e.stopPropagation();
              handleResetFilter();
            }}>
            {resetFilterLabel}
          </div>
        )}
      {sortOptions && selectedSort && (
        <div className="work-report-filter__sort">
          <div className="work-report-filter__sort-label">{sortLabel}</div>
          <div className="work-report-filter__sort-input">
            <MDropdown
              placeholder=""
              inputWidth="10rem"
              options={sortOptions}
              inputClassName="noBorder"
              inputValue={selectedSort}
              handleChangeSelect={handleSort}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default WorkReportFilter;
