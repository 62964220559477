import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBasicServiceTypeCreateAction,
  MasterDataBasicServiceTypeCreateFailedPayload,
  MasterDataBasicServiceTypeCreateFetchPayload,
  MasterDataBasicServiceTypeCreateSuccessPayload,
} from './masterDataBasicServiceTypeCreate.type';

export const masterDataBasicServiceTypeCreateAction = {
  fetch: createAction<MasterDataBasicServiceTypeCreateFetchPayload>(
    MasterDataBasicServiceTypeCreateAction.FETCH,
  ),
  success: createAction<MasterDataBasicServiceTypeCreateSuccessPayload>(
    MasterDataBasicServiceTypeCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataBasicServiceTypeCreateFailedPayload>(
    MasterDataBasicServiceTypeCreateAction.FAILED,
  ),
  reset: createAction(MasterDataBasicServiceTypeCreateAction.RESET),
};
