//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDepartmentEditAction} from '../../../../usecases/masterData.action';
import {MasterDataDepartmentEditFetchPayload} from '../../../../usecases/masterData.type';
// Components
import {MasterDataBasicTypeFormEditForm} from './components';
// Assets
import './MasterDataDepartmentFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: MasterDataDepartmentEditFetchPayload;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDepartmentFormEditContainer: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataDepartmentEditFetchPayload = {
    id: data.id || '',
    directorateId: data.directorateId,
    divisionId: data.divisionId,
    name: data.name || '',
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDepartment = (
    formValue: MasterDataDepartmentEditFetchPayload,
  ) => {
    const departmentTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: departmentTemp || '',
    };
    dispatch(masterDataDepartmentEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-basic-service-type-form-edit-container">
      <MasterDataBasicTypeFormEditForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitDepartment={handleSubmitDepartment}
      />
    </div>
  );
  //#endregion
};

export default MasterDataDepartmentFormEditContainer;
