import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataReviewIndicatorEditAction,
  MasterDataReviewIndicatorEditFailedPayload,
  MasterDataReviewIndicatorEditFetchPayload,
  MasterDataReviewIndicatorEditSuccessPayload,
} from './masterDataReviewIndicatorEdit.type';

export const masterDataReviewIndicatorEditAction = {
  fetch: createAction<MasterDataReviewIndicatorEditFetchPayload>(
    MasterDataReviewIndicatorEditAction.FETCH,
  ),
  success: createAction<MasterDataReviewIndicatorEditSuccessPayload>(
    MasterDataReviewIndicatorEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataReviewIndicatorEditFailedPayload>(
    MasterDataReviewIndicatorEditAction.FAILED,
  ),
  reset: createAction(MasterDataReviewIndicatorEditAction.RESET),
};
