import React from 'react';

export enum PATH {
  HOME = '/',
  LOGIN = '/login',
  CHANGE_PASSWORD = '/change-password',
  FORGET_PASSWORD = '/forget-password',
  USER_ACCOUNT = '/user-account',
  USER_ACCESS = '/user-access',
  USER_ACCESS_CREATE_ROLE = '/user-access/create',
  USER_ACCESS_EDIT_ROLE = '/user-access/edit',
  USER_ACCESS_DETAIL_ROLE = '/user-access/detail',
  FORMULIR_PENILAIAN = '/formulir-penilaian',
  LAPORAN_PENILAIAN = '/laporan-penilaian',
  LAPORAN_PENILAIAN_DETAIL = '/laporan-penilaian/detail',
  TINDAK_LANJUT = '/tindak-lanjut',
  LAPORAN_KERJA = '/laporan-kerja',
  UNDUH_DATA = '/unduh-data',
  MASTER_DATA = '/master-data',
  MASTER_DATA_OPERATIONAL = '/master-data/operational',
  MASTER_DATA_FORM_ASSESSMENT = '/master-data/formulir-penilaian',
  MASTER_DATA_OFFICE = '/master-data/office',
  MASTER_DATA_OFFICE_WORK_INSTRUCTION = '/master-data/office/instruksi-kerja',
  MASTER_DATA_OFFICE_ASSIGNMENT_LETTER = '/master-data/office/surat-tugas',
  MASTER_DATA_OPERATIONAL_BUS = '/master-data/operational/bus',
  MASTER_DATA_OPERATIONAL_RUTE = '/master-data/operational/rute',
  NOTIFICATION = '/notification',
}

export interface tabContentType {
  label: string;
  component: React.ReactElement;
}

export enum AssignmentType {
  HALTE = 'HALTE',
  BUSBRT = 'BUSBRT',
  BUSBRTTENGAH = 'BUSBRTTENGAH',
  BUSBRTUJUNG = 'BUSBRTUJUNG',
  BUSNONBRT = 'BUSNONBRT',
  BUSNONBRTTENGAH = 'BUSNONBRTTENGAH',
  BUSNONBRTUJUNG = 'BUSNONBRTUJUNG',
  BUSKECIL = 'BUSKECIL',
  BUSKECILTENGAH = 'BUSKECILTENGAH',
  BUSKECILUJUNG = 'BUSKECILUJUNG',
  TRANSCARE = 'TRANSCARE',
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface OptionType {
  label: string;
  value: string;
}

export interface FileMeta {
  base64?: string | unknown;
  fileName?: string;
  fileLength?: number;
}

export enum FILEEXT {
  XLSX = 'xlsx',
}
