import {CommonError} from '../../../../../../config/constant';

export const MasterDataReviewIndicatorDeleteAction = {
  FETCH: 'MASTER_DATA_REVIEW_INDICATOR_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_REVIEW_INDICATOR_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_REVIEW_INDICATOR_DELETE_FAILED',
  RESET: 'MASTER_DATA_REVIEW_INDICATOR_DELETE_RESET',
};

export interface MasterDataReviewIndicatorDeleteInitialState {
  masterDataReviewIndicatorDeleteLoading: boolean;
  masterDataReviewIndicatorDeleteParams?: MasterDataReviewIndicatorDeleteFetchPayload;
  masterDataReviewIndicatorDeleteError?: MasterDataReviewIndicatorDeleteFailedPayload;
  masterDataReviewIndicatorDeleteResponse?: MasterDataReviewIndicatorDeleteSuccessPayload;
}

export interface MasterDataReviewIndicatorDeleteFetchPayload {
  id: string;
}
export type MasterDataReviewIndicatorDeleteFailedPayload = CommonError;

export interface MasterDataReviewIndicatorDeleteSuccessPayload {}
