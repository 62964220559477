import {CommonError} from '../../../../../../config/constant';

export const MasterDataFormControllerDeleteAction = {
  FETCH: 'MASTER_DATA_FORM_CONTROLLER_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_FORM_CONTROLLER_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_FORM_CONTROLLER_DELETE_FAILED',
  RESET: 'MASTER_DATA_FORM_CONTROLLER_DELETE_RESET',
};

export interface MasterDataFormControllerDeleteInitialState {
  masterDataFormControllerDeleteLoading: boolean;
  masterDataFormControllerDeleteParams?: MasterDataFormControllerDeleteFetchPayload;
  masterDataFormControllerDeleteError?: MasterDataFormControllerDeleteFailedPayload;
  masterDataFormControllerDeleteResponse?: MasterDataFormControllerDeleteSuccessPayload;
}

export interface MasterDataFormControllerDeleteFetchPayload {
  id: string;
}
export type MasterDataFormControllerDeleteFailedPayload = CommonError;

export interface MasterDataFormControllerDeleteSuccessPayload {}
