import {CommonError} from '../../../../config/constant';

export const NotificationReadAllAction = {
  FETCH: 'NOTIFICATION_READ_ALL_FETCH',
  SUCCESS: 'NOTIFICATION_READ_ALL_SUCCESS',
  FAILED: 'NOTIFICATION_READ_ALL_FAILED',
  RESET: 'NOTIFICATION_READ_ALL_RESET',
};

export interface NotificationReadAllInitialState {
  notificationReadAllLoading: boolean;
  notificationReadAllError?: NotificationReadAllFailedPayload;
  notificationReadAllParams?: NotificationReadAllFetchPayload;
  notificationReadAllResponse?: NotificationReadAllSuccessPayload;
}

export type NotificationReadAllFailedPayload = CommonError;

export interface NotificationReadAllSuccessPayload {}

export interface NotificationReadAllFetchPayload {}
