import {
  MasterDataIndicatorValueOptionListAction,
  MasterDataIndicatorValueOptionListSuccessPayload,
  MasterDataIndicatorValueOptionListFailedPayload,
  MasterDataIndicatorValueOptionListFetchPayload,
} from './masterDataIndicatorValueOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataIndicatorValueOptionListInitialState} from './masterDataIndicatorValueOptionList.reducer';

export const masterDataIndicatorValueOptionListHandler = {
  [MasterDataIndicatorValueOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataIndicatorValueOptionListLoading: true,
      masterDataIndicatorValueOptionListParams: action.payload,
      action: MasterDataIndicatorValueOptionListAction.FETCH,
    };
  },
  [MasterDataIndicatorValueOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueOptionListLoading: false,
    masterDataIndicatorValueOptionListResponse: action.payload,
    action: MasterDataIndicatorValueOptionListAction.SUCCESS,
  }),
  [MasterDataIndicatorValueOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueOptionListLoading: false,
    masterDataIndicatorValueOptionListData: [
      ...state.masterDataIndicatorValueOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataIndicatorValueOptionListAction.UPDATE,
  }),
  [MasterDataIndicatorValueOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueOptionListLoading: false,
    masterDataIndicatorValueOptionListError: action.payload,
    action: MasterDataIndicatorValueOptionListAction.FAILED,
  }),
  [MasterDataIndicatorValueOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataIndicatorValueOptionListInitialState,
    action: MasterDataIndicatorValueOptionListAction.RESET,
  }),
};
