import {InputLabel, MenuItem, Select} from '@mui/material';
import {FieldProps} from 'formik';
import React from 'react';
import t from '../../lang';
import classnames from 'classnames';
import './MSelect.component.style.scss';
import {IconCaretArrowDown} from '../../assets/icons';

export interface SelectItemType {
  label: string;
  value: string;
}
interface Props extends FieldProps<string> {
  disabled?: boolean;
  className?: string;
  errormessage?: string;
  helperText?: string;
  options: SelectItemType[];
  label: string;
  placeholder: string;
  iconComponent?: React.ElementType;
  handleChangeSelect?(e: unknown): void;
}

const MSelect: React.FC<Props> = ({
  disabled = false,
  className,
  options,
  field,
  helperText,
  errormessage,
  label,
  placeholder,
  form,
  iconComponent = IconCaretArrowDown,
  handleChangeSelect,
  ...otherProps
}: Props) => {
  const isError = errormessage || form.errors[field.name];
  const classes = classnames(
    'm-select',
    {
      'm-select--error': isError,
    },
    className,
  );

  return (
    <div className={`${classes} ${className}`}>
      <InputLabel className="m-select__label">{label}</InputLabel>
      <Select
        {...field}
        className="m-select__field"
        placeholder={placeholder}
        displayEmpty
        disabled={disabled}
        IconComponent={iconComponent}
        onChange={(e) => {
          const val = e?.target?.value;
          handleChangeSelect && handleChangeSelect(val);
          form.setFieldValue(field.name, val);
        }}
        renderValue={(selected) => {
          const selectedLabel = options.find((item) => item.value === selected)
            ?.label;
          if (!selected)
            return (
              <div className="m-select__field--placeholder">{placeholder}</div>
            );
          return selectedLabel;
        }}
        {...otherProps}>
        {options.map((_option: SelectItemType, idx: number) => (
          <MenuItem
            key={`option-item-${_option}-${idx}`}
            className="m-select__field--item"
            value={_option.value}>
            {t(_option.label)}
          </MenuItem>
        ))}
      </Select>

      {(errormessage || form.errors[field.name]) && (
        <div className="m-select__errorText error--text">
          {errormessage || form.errors[field.name]}
        </div>
      )}
      {helperText && !form.errors && (
        <div className="m-select__helperText helper-text">{helperText}</div>
      )}
    </div>
  );
};

export default MSelect;
