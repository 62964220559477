//#region IMPORT
// Libraries
import React from 'react';
import {Divider} from '@mui/material';
// Assets
import './FollowUpDivider.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  orientations?: 'horizontal' | 'vertical';
}
//#endregion

const FollowUpDivider: React.FC<Props> = ({
  orientations = 'horizontal',
}: Props) => {
  return (
    <div className={`follow-up-divider__${orientations}`}>
      <Divider orientation={orientations} />
    </div>
  );
};
export default FollowUpDivider;
