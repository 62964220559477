import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailIndicatorCreateAction,
  MasterDataDetailIndicatorCreateFailedPayload,
  MasterDataDetailIndicatorCreateFetchPayload,
  MasterDataDetailIndicatorCreateSuccessPayload,
} from './masterDataDetailIndicatorCreate.type';

export const masterDataDetailIndicatorCreateAction = {
  fetch: createAction<MasterDataDetailIndicatorCreateFetchPayload>(
    MasterDataDetailIndicatorCreateAction.FETCH,
  ),
  success: createAction<MasterDataDetailIndicatorCreateSuccessPayload>(
    MasterDataDetailIndicatorCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailIndicatorCreateFailedPayload>(
    MasterDataDetailIndicatorCreateAction.FAILED,
  ),
  reset: createAction(MasterDataDetailIndicatorCreateAction.RESET),
};
