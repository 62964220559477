//#region FORM
//#region FORM CONTROLLER
import masterDataFormControllerCreate from './section/form/masterDataFormControllerCreate/masterDataFormControllerCreate.saga';
import masterDataFormControllerList from './section/form/masterDataFormControllerList/masterDataFormControllerList.saga';
import masterDataFormControllerDelete from './section/form/masterDataFormControllerDelete/masterDataFormControllerDelete.saga';
import masterDataFormControllerEdit from './section/form/masterDataFormControllerEdit/masterDataFormControllerEdit.saga';
import masterDataFormControllerMove from './section/form/masterDataFormControllerMove/masterDataFormControllerMove.saga';
//#endregion
//#region BASIC SERVICE TYPE
import masterDataBasicServiceTypeCreate from './section/form/masterDataBasicServiceTypeCreate/masterDataBasicServiceTypeCreate.saga';
import masterDataBasicServiceTypeList from './section/form/masterDataBasicServiceTypeList/masterDataBasicServiceTypeList.saga';
import masterDataBasicServiceTypeOptionList from './section/form/masterDataBasicServiceTypeOptionList/masterDataBasicServiceTypeOptionList.saga';
import masterDataBasicServiceTypeDelete from './section/form/masterDataBasicServiceTypeDelete/masterDataBasicServiceTypeDelete.saga';
import masterDataBasicServiceTypeEdit from './section/form/masterDataBasicServiceTypeEdit/masterDataBasicServiceTypeEdit.saga';
import masterDataBasicServiceTypeMove from './section/form/masterDataBasicServiceTypeMove/masterDataBasicServiceTypeMove.saga';
//#endregion
//#region CATEGORY
import masterDataCategoryCreate from './section/form/masterDataCategoryCreate/masterDataCategoryCreate.saga';
import masterDataCategoryList from './section/form/masterDataCategoryList/masterDataCategoryList.saga';
import masterDataCategoryDelete from './section/form/masterDataCategoryDelete/masterDataCategoryDelete.saga';
import masterDataCategoryEdit from './section/form/masterDataCategoryEdit/masterDataCategoryEdit.saga';
import masterDataCategoryOptionList from './section/form/masterDataCategoryOptionList/masterDataCategoryOptionList.saga';
import masterDataCategoryMove from './section/form/masterDataCategoryMove/masterDataCategoryMove.saga';
//#endregion
//#region REVIEW INDICATOR
import masterDataReviewIndicatorCreate from './section/form/masterDataReviewIndicatorCreate/masterDataReviewIndicatorCreate.saga';
import masterDataReviewIndicatorList from './section/form/masterDataReviewIndicatorList/masterDataReviewIndicatorList.saga';
import masterDataReviewIndicatorOptionList from './section/form/masterDataReviewIndicatorOptionList/masterDataReviewIndicatorOptionList.saga';
import masterDataReviewIndicatorDelete from './section/form/masterDataReviewIndicatorDelete/masterDataReviewIndicatorDelete.saga';
import masterDataReviewIndicatorEdit from './section/form/masterDataReviewIndicatorEdit/masterDataReviewIndicatorEdit.saga';
import masterDataReviewIndicatorMove from './section/form/masterDataReviewIndicatorMove/masterDataReviewIndicatorMove.saga';
//#endregion
//#region DETAIL INDICATOR
import masterDataDetailIndicatorCreate from './section/form/masterDataDetailIndicatorCreate/masterDataDetailIndicatorCreate.saga';
import masterDataDetailIndicatorList from './section/form/masterDataDetailIndicatorList/masterDataDetailIndicatorList.saga';
import masterDataDetailIndicatorOptionList from './section/form/masterDataDetailIndicatorOptionList/masterDataDetailIndicatorOptionList.saga';
import masterDataDetailIndicatorDelete from './section/form/masterDataDetailIndicatorDelete/masterDataDetailIndicatorDelete.saga';
import masterDataDetailIndicatorEdit from './section/form/masterDataDetailIndicatorEdit/masterDataDetailIndicatorEdit.saga';
//#endregion
//#region INDICATOR VALUE
import masterDataIndicatorValueCreate from './section/form/masterDataIndicatorValueCreate/masterDataIndicatorValueCreate.saga';
import masterDataIndicatorValueList from './section/form/masterDataIndicatorValueList/masterDataIndicatorValueList.saga';
import masterDataIndicatorValueOptionList from './section/form/masterDataIndicatorValueOptionList/masterDataIndicatorValueOptionList.saga';
import masterDataIndicatorValueDelete from './section/form/masterDataIndicatorValueDelete/masterDataIndicatorValueDelete.saga';
import masterDataIndicatorValueSetPositiveValue from './section/form/masterDataIndicatorValueSetPositiveValue/masterDataIndicatorValueSetPositiveValue.saga';
import masterDataIndicatorValueEdit from './section/form/masterDataIndicatorValueEdit/masterDataIndicatorValueEdit.saga';
//#endregion
//#region DETAIL OBSERVATION
import masterDataDetailObservationCreate from './section/form/masterDataDetailObservationCreate/masterDataDetailObservationCreate.saga';
import masterDataDetailObservationList from './section/form/masterDataDetailObservationList/masterDataDetailObservationList.saga';
import masterDataDetailObservationOptionList from './section/form/masterDataDetailObservationOptionList/masterDataDetailObservationOptionList.saga';
import masterDataDetailObservationDelete from './section/form/masterDataDetailObservationDelete/masterDataDetailObservationDelete.saga';
import masterDataDetailObservationEdit from './section/form/masterDataDetailObservationEdit/masterDataDetailObservationEdit.saga';
//#endregion
//#region OBSERVATION VALUE
import masterDataObservationValueCreate from './section/form/masterDataObservationValueCreate/masterDataObservationValueCreate.saga';
import masterDataObservationValueList from './section/form/masterDataObservationValueList/masterDataObservationValueList.saga';
import masterDataObservationValueOptionList from './section/form/masterDataObservationValueOptionList/masterDataObservationValueOptionList.saga';
import masterDataObservationValueDelete from './section/form/masterDataObservationValueDelete/masterDataObservationValueDelete.saga';
import masterDataObservationValueSetPositiveValue from './section/form/masterDataObservationValueSetPositiveValue/masterDataObservationValueSetPositiveValue.saga';
import masterDataObservationValueEdit from './section/form/masterDataObservationValueEdit/masterDataObservationValueEdit.saga';
//#endregion
//#region OBSERVATION
import masterDataObservationCreate from './section/form/masterDataObservationCreate/masterDataObservationCreate.saga';
import masterDataObservationList from './section/form/masterDataObservationList/masterDataObservationList.saga';
import masterDataObservationOptionList from './section/form/masterDataObservationOptionList/masterDataObservationOptionList.saga';
import masterDataObservationDelete from './section/form/masterDataObservationDelete/masterDataObservationDelete.saga';
import masterDataObservationEdit from './section/form/masterDataObservationEdit/masterDataObservationEdit.saga';
//#endregion
//#endregion

//#region OFFICE
//#region DIRECTORATE
import masterDataDirectorateCreate from './section/office/masterDataDirectorateCreate/masterDataDirectorateCreate.saga';
import masterDataDirectorateList from './section/office/masterDataDirectorateList/masterDataDirectorateList.saga';
import masterDataDirectorateDelete from './section/office/masterDataDirectorateDelete/masterDataDirectorateDelete.saga';
import masterDataDirectorateEdit from './section/office/masterDataDirectorateEdit/masterDataDirectorateEdit.saga';
import masterDataDirectorateOptionList from './section/office/masterDataDirectorateOptionList/masterDataDirectorateOptionList.saga';
//#endregion
//#region DIVISION
import masterDataDivisionCreate from './section/office/masterDataDivisionCreate/masterDataDivisionCreate.saga';
import masterDataDivisionList from './section/office/masterDataDivisionList/masterDataDivisionList.saga';
import masterDataDivisionDelete from './section/office/masterDataDivisionDelete/masterDataDivisionDelete.saga';
import masterDataDivisionEdit from './section/office/masterDataDivisionEdit/masterDataDivisionEdit.saga';
import masterDataDivisionOptionList from './section/office/masterDataDivisionOptionList/masterDataDivisionOptionList.saga';
//#endregion
//#region DEPARTMENT
import masterDataDepartmentCreate from './section/office/masterDataDepartmentCreate/masterDataDepartmentCreate.saga';
import masterDataDepartmentList from './section/office/masterDataDepartmentList/masterDataDepartmentList.saga';
import masterDataDepartmentDelete from './section/office/masterDataDepartmentDelete/masterDataDepartmentDelete.saga';
import masterDataDepartmentEdit from './section/office/masterDataDepartmentEdit/masterDataDepartmentEdit.saga';
import masterDataDepartmentOptionList from './section/office/masterDataDepartmentOptionList/masterDataDepartmentOptionList.saga';
//#endregion
//#region WORK INSTRUCTION
import masterDataWorkInstructionList from './section/office/masterDataWorkInstructionList/masterDataWorkInstructionList.saga';
import masterDataWorkInstructionEdit from './section/office/masterDataWorkInstructionEdit/masterDataWorkInstructionEdit.saga';
//#endregion
//#region ASSIGNMENT LETTER
import masterDataAssignmentLetterCreate from './section/office/masterDataAssignmentLetterCreate/masterDataAssignmentLetterCreate.saga';
import masterDataAssignmentLetterList from './section/office/masterDataAssignmentLetterList/masterDataAssignmentLetterList.saga';
import masterDataAssignmentLetterDelete from './section/office/masterDataAssignmentLetterDelete/masterDataAssignmentLetterDelete.saga';
import masterDataAssignmentLetterEdit from './section/office/masterDataAssignmentLetterEdit/masterDataAssignmentLetterEdit.saga';
import masterDataAssignmentLetterSetActive from './section/office/masterDataAssignmentLetterSetActive/masterDataAssignmentLetterSetActive.saga';
//#endregion
//#endregion

//#region OPERATIONAL
//#region BUS
import masterDataBusList from './section/operational/masterDataBusList/masterDataBusList.saga';
import masterDataBusCreate from './section/operational/masterDataBusCreate/masterDataBusCreate.saga';
import masterDataBusDelete from './section/operational/masterDataBusDelete/masterDataBusDelete.saga';
import masterDataBusEdit from './section/operational/masterDataBusEdit/masterDataBusEdit.saga';
import masterDataBusOptionList from './section/operational/masterDataBusOptionList/masterDataBusOptionList.saga';
//#endregion
//#region RUTE
import masterDataRouteCreate from './section/operational/masterDataRouteCreate/masterDataRouteCreate.saga';
import masterDataRouteDelete from './section/operational/masterDataRouteDelete/masterDataRouteDelete.saga';
import masterDataRouteEdit from './section/operational/masterDataRouteEdit/masterDataRouteEdit.saga';
import masterDataRuteList from './section/operational/masterDataRuteList/masterDataRuteList.saga';
//#endregion
//#region COUNTER
import masterDataCounterList from './section/operational/masterDataCounterList/masterDataCounterList.saga';
//#endregion
//#endregion

export default [
  //#region FORM
  //#region FORM CONTROLLER
  ...masterDataFormControllerCreate,
  ...masterDataFormControllerList,
  ...masterDataFormControllerDelete,
  ...masterDataFormControllerEdit,
  ...masterDataFormControllerMove,
  //#endregion
  //#region BASIC SERVICE TYPE
  ...masterDataBasicServiceTypeCreate,
  ...masterDataBasicServiceTypeList,
  ...masterDataBasicServiceTypeOptionList,
  ...masterDataBasicServiceTypeDelete,
  ...masterDataBasicServiceTypeEdit,
  ...masterDataBasicServiceTypeMove,
  //#endregion
  //#region CATEGORY
  ...masterDataCategoryCreate,
  ...masterDataCategoryList,
  ...masterDataCategoryDelete,
  ...masterDataCategoryEdit,
  ...masterDataCategoryMove,
  ...masterDataCategoryOptionList,
  //#endregion
  //#region REVIEW INDICATOR
  ...masterDataReviewIndicatorCreate,
  ...masterDataReviewIndicatorList,
  ...masterDataReviewIndicatorOptionList,
  ...masterDataReviewIndicatorDelete,
  ...masterDataReviewIndicatorEdit,
  ...masterDataReviewIndicatorMove,
  //#endregion
  //#region DETAIL INDICATOR
  ...masterDataDetailIndicatorCreate,
  ...masterDataDetailIndicatorList,
  ...masterDataDetailIndicatorOptionList,
  ...masterDataDetailIndicatorDelete,
  ...masterDataDetailIndicatorEdit,
  //#endregion
  //#region INDICATOR VALUE
  ...masterDataIndicatorValueCreate,
  ...masterDataIndicatorValueList,
  ...masterDataIndicatorValueOptionList,
  ...masterDataIndicatorValueDelete,
  ...masterDataIndicatorValueEdit,
  ...masterDataIndicatorValueSetPositiveValue,
  //#endregion
  //#region DETAIL OBSERVATION
  ...masterDataDetailObservationCreate,
  ...masterDataDetailObservationList,
  ...masterDataDetailObservationOptionList,
  ...masterDataDetailObservationDelete,
  ...masterDataDetailObservationEdit,
  //#endregion
  //#region OBSERVATION VALUE
  ...masterDataObservationValueCreate,
  ...masterDataObservationValueList,
  ...masterDataObservationValueOptionList,
  ...masterDataObservationValueDelete,
  ...masterDataObservationValueEdit,
  ...masterDataObservationValueSetPositiveValue,
  //#endregion
  //#region OBSERVATION
  ...masterDataObservationCreate,
  ...masterDataObservationList,
  ...masterDataObservationOptionList,
  ...masterDataObservationDelete,
  ...masterDataObservationEdit,
  //#endregion
  //#endregion

  //#region OFFICE
  //#region DIRECTORATE
  ...masterDataDirectorateCreate,
  ...masterDataDirectorateList,
  ...masterDataDirectorateDelete,
  ...masterDataDirectorateEdit,
  ...masterDataDirectorateOptionList,
  //#endregion
  //#region DIVISION
  ...masterDataDivisionCreate,
  ...masterDataDivisionList,
  ...masterDataDivisionDelete,
  ...masterDataDivisionEdit,
  ...masterDataDivisionOptionList,
  //#endregion
  //#region DEPARTMENT
  ...masterDataDepartmentCreate,
  ...masterDataDepartmentList,
  ...masterDataDepartmentDelete,
  ...masterDataDepartmentEdit,
  ...masterDataDepartmentOptionList,
  //#endregion
  //#region WORK INSTRUCTION
  ...masterDataWorkInstructionList,
  ...masterDataWorkInstructionEdit,
  //#endregion
  //#region ASSIGNMENT LETTER
  ...masterDataAssignmentLetterCreate,
  ...masterDataAssignmentLetterList,
  ...masterDataAssignmentLetterDelete,
  ...masterDataAssignmentLetterEdit,
  ...masterDataAssignmentLetterSetActive,
  //#endregion
  //#endregion

  //#region OPERATIONAL
  //#region BUS
  ...masterDataBusList,
  ...masterDataBusCreate,
  ...masterDataBusEdit,
  ...masterDataBusDelete,
  ...masterDataBusOptionList,
  //#endregion
  //#region RUTE
  ...masterDataRouteCreate,
  ...masterDataRouteEdit,
  ...masterDataRouteDelete,
  ...masterDataRuteList,
  //#endregion
  //#region COUNTER
  ...masterDataCounterList,
  //#endregion
  //#endregion
];
