import {CommonError} from '../../../../../../config/constant';

export const MasterDataBusEditAction = {
  FETCH: 'MASTER_DATA_BUS_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_BUS_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_BUS_EDIT_FAILED',
  RESET: 'MASTER_DATA_BUS_EDIT_RESET',
};

export interface MasterDataBusEditInitialState {
  masterDataBusEditLoading: boolean;
  masterDataBusEditParams?: MasterDataBusEditFetchPayload;
  masterDataBusEditError?: MasterDataBusEditFailedPayload;
  masterDataBusEditResponse?: MasterDataBusEditSuccessPayload;
}

export interface MasterDataBusEditFetchPayload {
  id: string;
  bodyNumber: string;
  operator: string;
  busType: string;
  pool: string;
  isActive: number;
}

export type MasterDataBusEditFailedPayload = CommonError;

export interface MasterDataBusEditSuccessPayload {}
