import Grid from '@mui/material/Grid';
import React from 'react';
import t from '../../../../../../lang';
import {
  MasterDataManagement,
  MasterDataManagementType,
} from '../../../../entity/masterData.string.entity';
import MasterDataManagementWrapperListItem from '../MasterDataManagementWrapperListItem';
import './MasterDataManagementWrapperList.component.style.scss';

interface Props {
  data: MasterDataManagement[];
  onClickItem(_masterDataWrapper: MasterDataManagement): void;
}

const MasterDataManagementWrapperList: React.FC<Props> = ({
  data,
  onClickItem,
}: Props) => {
  const operationalData: MasterDataManagement[] = data.filter(
    (item) => item.type === MasterDataManagementType.OPERATIONAL,
  );
  const formAssessmentData: MasterDataManagement[] = data.filter(
    (item) => item.type === MasterDataManagementType.FORM,
  );
  const officeData: MasterDataManagement[] = data.filter(
    (item) => item.type === MasterDataManagementType.OFFICE,
  );

  return (
    <div className="master-data-management-wrapper-list">
      {operationalData.length > 0 ? (
        <div className="master-data-management-wrapper-list__type">
          <div className="master-data-management-wrapper-list__type--name">
            {t('Operational')}
          </div>
          <Grid container spacing={3}>
            {operationalData.map(
              (item: MasterDataManagement, index: number) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                    key={`master-data-management-wrapper-${item.name}-${item.id}-${index}`}>
                    <MasterDataManagementWrapperListItem
                      item={item}
                      onClickItem={() => onClickItem(item)}
                    />
                  </Grid>
                );
              },
            )}
          </Grid>
        </div>
      ) : null}
      {formAssessmentData.length > 0 ? (
        <div className="master-data-management-wrapper-list__type">
          <div className="master-data-management-wrapper-list__type--name">
            {t('Form Assessment')}
          </div>
          <Grid container spacing={3}>
            {formAssessmentData.map(
              (item: MasterDataManagement, index: number) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                    key={`master-data-management-wrapper-${item.name}-${item.id}-${index}`}>
                    <MasterDataManagementWrapperListItem
                      item={item}
                      onClickItem={() => onClickItem(item)}
                    />
                  </Grid>
                );
              },
            )}
          </Grid>
        </div>
      ) : null}
      {officeData.length > 0 ? (
        <div className="master-data-management-wrapper-list__type">
          <div className="master-data-management-wrapper-list__type--name">
            {t('Office')}
          </div>
          <Grid container spacing={3}>
            {officeData.map((item: MasterDataManagement, index: number) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                  key={`master-data-management-wrapper-${item.name}-${item.id}-${index}`}>
                  <MasterDataManagementWrapperListItem
                    item={item}
                    onClickItem={() => onClickItem(item)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : null}
    </div>
  );
};

export default MasterDataManagementWrapperList;
