import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataBusCreateInitialState} from './masterDataBusCreate.reducer';
import {
  MasterDataBusCreateAction,
  MasterDataBusCreateFailedPayload,
  MasterDataBusCreateFetchPayload,
  MasterDataBusCreateSuccessPayload,
} from './masterDataBusCreate.type';

export const masterDataBusCreateHandler = {
  [MasterDataBusCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusCreateLoading: true,
    masterDataBusCreateParams: action.payload,
    action: MasterDataBusCreateAction.FETCH,
  }),
  [MasterDataBusCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusCreateParams: undefined,
    masterDataBusCreateLoading: false,
    masterDataBusCreateResponse: action.payload,
    action: MasterDataBusCreateAction.SUCCESS,
  }),
  [MasterDataBusCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusCreateLoading: false,
    masterDataBusCreateError: action.payload,
    action: MasterDataBusCreateAction.FAILED,
  }),
  [MasterDataBusCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBusCreateInitialState,
    action: MasterDataBusCreateAction.RESET,
  }),
};
