//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../../../lang';
// Components
import {MButton} from '../../../../../../components';
// Data
import {MasterDataWorkInstruction} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataWorkInstructionDetail.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: MasterDataWorkInstruction;
  onEdit?(): void;
}
//#endregion

const MasterDataWorkInstructionDetail: React.FC<Props> = ({
  data,
  onEdit,
}: Props) => {
  return (
    <div className="master-data-work-instruction-detail">
      <div className="master-data-work-instruction-detail__meta-wi">
        <div className="master-data-work-instruction-detail__meta-wi--content">
          {data?.workInstruction ?? '-'}
        </div>
      </div>

      {onEdit && (
        <div className="master-data-work-instruction-detail__action">
          <div className="master-data-work-instruction-detail__action--edit">
            <MButton buttonLabel={t('Edit')} handleClick={onEdit} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MasterDataWorkInstructionDetail;
