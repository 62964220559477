import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataIndicatorValue} from '../../../../entity/masterData.string.entity';

export const MasterDataIndicatorValueListAction = {
  FETCH: 'MASTER_DATA_INDICATOR_VALUE_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_INDICATOR_VALUE_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_INDICATOR_VALUE_LIST_FAILED',
  RESET: 'MASTER_DATA_INDICATOR_VALUE_LIST_RESET',
  UPDATE: 'MASTER_DATA_INDICATOR_VALUE_LIST_UPDATE',
};

export interface MasterDataIndicatorValueListInitialState {
  masterDataIndicatorValueListLoading: boolean;
  masterDataIndicatorValueListData: MasterDataIndicatorValue[];
  masterDataIndicatorValueListError?: MasterDataIndicatorValueListFailedPayload;
  masterDataIndicatorValueListParams?: MasterDataIndicatorValueListFetchPayload;
  masterDataIndicatorValueListResponse?: MasterDataIndicatorValueListSuccessPayload;
}

export type MasterDataIndicatorValueListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataIndicatorValue[]>;
};

export type MasterDataIndicatorValueListFailedPayload = CommonError;

export type MasterDataIndicatorValueListSuccessPayload = MasterDataIndicatorValueListResponse;

export type MasterDataIndicatorValueListUpdatePayload = MasterDataIndicatorValueListResponse;

export interface MasterDataIndicatorValueListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
