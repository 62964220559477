import React from 'react';
import {DefaultRawDatum} from '@nivo/pie';

//#region GENERAL
export enum DASHBOARDQUARTERINTERVAL {
  TW1 = 'TW 1',
  TW2 = 'TW 2',
  TW3 = 'TW 3',
  TW4 = 'TW 4',
}

export enum DASHBOARDWEEKLYINTERVAL {
  M1 = 'M 1',
  M2 = 'M 2',
  M3 = 'M 3',
  M4 = 'M 4',
}

export enum DASHBOARDSERVICETYPE {
  HALTE = 'HALTE',
  BUSBRT = 'BRT',
  BUSNONBRT = 'NON BRT',
  BUSKECIL = 'IBK',
  TRANSCARE = 'TRANSCARE',
}

export enum DASHBOARDEXPORTFILENAME {
  MONTHLY = 'Laporan Final Bulanan Rekon',
  QUARTERLY = 'Laporan Final Triwulan Rekon',
}
export interface DashboardTableListItem {
  percent: string;
  name: string;
}
//#endregion

//#region DASHBOARD
export interface PieRawDatum extends DefaultRawDatum {
  label?: string;
  color?: string;
}

export interface DashboardServiceData {
  id: string;
  name: string;
  icon: React.ReactElement;
  value: string | number;
  backgroundColor?: string;
}

export interface DashboardCommentData {
  id: string;
  userName: string;
  profilePicture: string;
  comment: string;
  dateTime: string;
}

export interface DashboardCommentFormData {
  id: string;
  comment: string;
}
//#endregion

//#region DASHBOARD OVERVIEW
//#region DASHBOARD OVERVIEW CHART SECTION
export interface DashboardOverviewChartMonthlyFinalScore {
  month?: string;
  brt?: number;
  nonBrt?: number;
  ibk?: number;
  transcare?: number;
}
export interface DashboardOverviewProfitAndFine {
  value: number;
  quarter: string;
  percent: number;
  percentageUp: number;
}
export interface DashboardOverviewChartSection {
  quarterFinalAchievementScore?: number | null;
  monthlyFinalScore: DashboardOverviewChartMonthlyFinalScore[];
  profit: DashboardOverviewProfitAndFine;
  fine: DashboardOverviewProfitAndFine;
  allowDownloadMonthlyFinalScore: boolean;
}
//#endregion

//#region DASHBOARD OVERVIEW BEST SECTION
export interface DashboardOverviewBestSection {
  bestBusStop: DashboardTableListItem[];
  bestOperator: DashboardTableListItem[];
  bestDepartment: DashboardTableListItem[];
}
//#endregion
//#region DASHBOARD OVERVIEW FINANCE
export interface DashboardOverviewFinance {
  id?: string;
  month?: string;
  year?: string;
  quarter?: string;
  value?: number;
  updatedValue?: number;
  from?: string;
}
export interface DashboardOverviewFinanceFormData {
  year?: string;
  quarter?: string;
  value?: number;
}
//#endregion
//#region DASHBOARD OVERVIEW EXPORT EXCEL
export interface DashboardOverviewExportExcel {
  file?: Blob;
}
//#endregion
//#endregion

//#region DASHBOARD SPM
//#region DASHBOARD SPM CHART SECTION
export interface DashboardSPMChartFinding {
  total: number;
  finished: number;
  unFinished: number;
}
export interface DashboardSPMTotalServiceTypeChecked {
  halte: number;
  brt: number;
  nonbrt: number;
  ibk: number;
  transcare: number;
}
//#endregion
export interface DashboardSPMPieChart {
  percentage?: string;
}

export interface DashboardSPMAchievementObservationSPM {
  name: string;
  percent: string;
  category: DashboardTableListItem[];
}

export interface DashboardSPMAchievementAndTargetObservationSPM {
  name: string;
  target?: string;
  achievement?: string;
}
export interface DashboardSPMChartSection {
  totalServiceTypeChecked?: DashboardSPMTotalServiceTypeChecked;
  finding: DashboardSPMChartFinding;
  achievementObservationSPM: DashboardSPMPieChart;
  achievementInternal: DashboardSPMPieChart;
  achievementObservationSPMService: DashboardSPMAchievementObservationSPM[];
  achievementInternalSPMService: DashboardSPMAchievementObservationSPM[];
  achievementAndTargetObservationSPM: DashboardSPMAchievementAndTargetObservationSPM[];
  achievementInternalSPMDepartment: DashboardTableListItem[];
}
//#endregion
//#endregion

//#region DASHBOARD DEPARTMENT
//#region DASHBOARD TAB DEPARTMENT LIST
export interface DashboardTabDepartment {
  id: string;
  name: string;
}
//#endregion
//#region DASHBOARD DEPARTMENT BEST SECTION
export interface DashboardDepartmentBestSection {
  bestBusStop: DashboardTableListItem[];
  bestOperator: DashboardTableListItem[];
  bestDepartment: DashboardTableListItem[];
}
//#endregion
//#region DASHBOARD DEPARTMENT CHART SECTION
export interface DashboardDepartmentChartFinding {
  totalFinding: number;
  finishedFinding: number;
  unFinishedFinding: number;
}
export interface DashboardDepartmentChartAchievementAndTarget {
  achievementPercent: string;
  targetPercent: string;
  departmentName: string;
  percent: string;
}
export interface DashboardDepartmentChartForecast {
  profit: number;
  fine: number;
}
export interface DashboardDepartmentChartSection {
  departmentAchievementScore?: string;
  finding: DashboardDepartmentChartFinding;
  achievementAndTarget: DashboardDepartmentChartAchievementAndTarget;
  forecast: DashboardDepartmentChartForecast;
}
//#endregion
//#endregion
