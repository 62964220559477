import {CommonData, CommonError} from '../../../../config/constant';
import {
  WorkReport,
  WorkReportType,
} from '../../entity/workReport.string.entity';

export const WorkReportListAction = {
  FETCH: 'WORK_REPORT_LIST_FETCH',
  SUCCESS: 'WORK_REPORT_LIST_SUCCESS',
  FAILED: 'WORK_REPORT_LIST_FAILED',
  RESET: 'WORK_REPORT_LIST_RESET',
  UPDATE: 'WORK_REPORT_LIST_UPDATE',
};

export interface WorkReportListInitialState {
  workReportListLoading: boolean;
  workReportListData: WorkReport[];
  workReportListError?: WorkReportListFailedPayload;
  workReportListParams?: WorkReportListFetchPayload;
  workReportListResponse?: WorkReportListSuccessPayload;
}

export type WorkReportListResponse = {
  status: string;
  count?: number;
  data: CommonData<WorkReport[]>;
};

export type WorkReportListFailedPayload = CommonError;

export type WorkReportListSuccessPayload = WorkReportListResponse;

export type WorkReportListUpdatePayload = WorkReportListResponse;

export interface WorkReportListFetchPayload {
  search?: string;
  size?: number;
  page?: number;
  month?: string;
  year?: string;
  reportType?: WorkReportType;
  departmentId?: string;
}
