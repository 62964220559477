import {CommonError} from '../../../../config/constant';
import {routeType} from '../../../followUp/entity/followUp.string.entity';
import {AssignmentType} from '../../../wrapper/entity';
import {EvaluationReportExport} from '../../entity/evaluationReport.string.entity';

export const EvaluationReportExportAction = {
  FETCH: 'EVALUATION_REPORT_EXPORT_FETCH',
  SUCCESS: 'EVALUATION_REPORT_EXPORT_SUCCESS',
  FAILED: 'EVALUATION_REPORT_EXPORT_FAILED',
  RESET: 'EVALUATION_REPORT_EXPORT_RESET',
};

export interface EvaluationReportExportInitialState {
  evaluationReportExportLoading: boolean;
  evaluationReportExportError?: EvaluationReportExportFailedPayload;
  evaluationReportExportParams?: EvaluationReportExportFetchPayload;
  evaluationReportExportResponse?: EvaluationReportExportSuccessPayload;
}

export type EvaluationReportExportResponse = {
  status?: string;
  data: EvaluationReportExport;
};

export type EvaluationReportExportFailedPayload = CommonError;

export type EvaluationReportExportSuccessPayload = EvaluationReportExportResponse;

export interface EvaluationReportExportFetchPayload {
  startDate: string;
  endDate: string;
  assignmentType?: AssignmentType;
  routeType?: routeType;
}
