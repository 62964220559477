//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import {MasterDataCategoryCreateFetchPayload} from '../../../../usecases/masterData.type';
// Utils
import {masterDataCategoryCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataCategoryFormCreate} from './components';
import {CategoryFormCreateInitialValues} from './components/MasterDataCategoryFormCreate/MasterDataCategoryFormCreate.component';
// Assets
import './MasterDataCategoryFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataCategoryFormCreateContainer: React.FC<Props> = ({
  assignmentType,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: CategoryFormCreateInitialValues = {
    category: [{name: ''}],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitCategory = (formValue: CategoryFormCreateInitialValues) => {
    const formattedValue: MasterDataCategoryCreateFetchPayload =
      formValue?.category.map((item) => ({
        name: item.name,
        assignmentType,
      })) || [];
    dispatch(masterDataCategoryCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-category-form-create-container">
      <MasterDataCategoryFormCreate
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitCategory={handleSubmitCategory}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataCategoryFormCreateContainer;
