import {CommonError} from '../../../../config/constant';

export const FollowUpPICCompleteAction = {
  FETCH: 'FOLLOW_UP_PIC_COMPLETE_FETCH',
  SUCCESS: 'FOLLOW_UP_PIC_COMPLETE_SUCCESS',
  FAILED: 'FOLLOW_UP_PIC_COMPLETE_FAILED',
  RESET: 'FOLLOW_UP_PIC_COMPLETE_RESET',
};

export interface FollowUpPICCompleteInitialState {
  followUpPICCompleteLoading: boolean;
  followUpPICCompleteParams?: FollowUpPICCompleteFetchPayload;
  followUpPICCompleteError?: FollowUpPICCompleteFailedPayload;
  followUpPICCompleteResponse?: FollowUpPICCompleteSuccessPayload;
}

export type FollowUpPICCompleteFetchPayload = {
  id: string;
  file?: File;
  fixingDate: string;
};
export type FollowUpPICCompleteFailedPayload = CommonError;

export interface FollowUpPICCompleteSuccessPayload {}
