import {workReportListHandler} from './workReportList/workReportList.handler';
import {workReportUploadHandler} from './workReportUpload/workReportUpload.handler';
import {workReportUploadSupportHandler} from './workReportUploadSupport/workReportUploadSupport.handler';
import {workReportCreateHandler} from './workReportCreate/workReportCreate.handler';
import {workReportEditHandler} from './workReportEdit/workReportEdit.handler';
import {workReportDeleteHandler} from './workReportDelete/workReportDelete.handler';
import {workReportExportAllHandler} from './workReportExportAll/workReportExportAll.handler';

export default {
  ...workReportListHandler,
  ...workReportUploadHandler,
  ...workReportUploadSupportHandler,
  ...workReportCreateHandler,
  ...workReportEditHandler,
  ...workReportDeleteHandler,
  ...workReportExportAllHandler,
};
