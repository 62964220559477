//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
// Utils
import {FollowUpPICCompleteFetchPayload} from '../../../usecases/followUp.type';
import {followUpPICCompleteAction} from '../../../usecases/followUp.action';
// Components
import FollowUpOnProgressForm from './components/FollowUpOnProgressForm';
// Data
import {
  FollowUpFormData,
  FollowUp,
} from '../../../entity/followUp.string.entity';
// Assets
import './FollowUpOnProgressFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const FollowUpOnProgressFormContainer: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const initialValues: FollowUpFormData = {
    id: data?.id || '',
    fixingDate: data?.fixingDate || '',
    file: undefined,
  };
  //#endregion

  //#region HANDLE SUBMIT FOLLOW UP
  const handleSubmitFollowUp = async (followUp: FollowUpFormData) => {
    const formattedParams: FollowUpPICCompleteFetchPayload = {
      ...followUp,
      fixingDate: moment(followUp.fixingDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD',
      ),
    };
    dispatch(followUpPICCompleteAction.fetch(formattedParams));
  };
  //#endregion

  return (
    <div className="follow-up-on-progress-form-container">
      <FollowUpOnProgressForm
        findingDueDate={data?.findingDueDate}
        findingDate={data?.findingDate}
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitFollowUp={handleSubmitFollowUp}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default FollowUpOnProgressFormContainer;
