import {
  EvaluationReportDetailAction,
  EvaluationReportDetailSuccessPayload,
  EvaluationReportDetailFailedPayload,
  EvaluationReportDetailFetchPayload,
} from './evaluationReportDetail.type';
import {EvaluationReportState} from '../evaluationReport.reducer';
import {GenericAction} from '../../../../config/constant';
import {evaluationReportDetailInitialState} from './evaluationReportDetail.reducer';

export const evaluationReportDetailHandler = {
  [EvaluationReportDetailAction.FETCH]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportDetailFetchPayload>,
  ): EvaluationReportState => {
    return {
      ...state,
      evaluationReportDetailLoading: true,
      evaluationReportDetailParams: action.payload,
      action: EvaluationReportDetailAction.FETCH,
    };
  },
  [EvaluationReportDetailAction.SUCCESS]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportDetailSuccessPayload>,
  ): EvaluationReportState => ({
    ...state,
    evaluationReportDetailLoading: false,
    evaluationReportDetailResponse: action.payload,
    action: EvaluationReportDetailAction.SUCCESS,
  }),
  [EvaluationReportDetailAction.FAILED]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportDetailFailedPayload>,
  ): EvaluationReportState => ({
    ...state,
    evaluationReportDetailLoading: false,
    evaluationReportDetailError: action.payload,
    action: EvaluationReportDetailAction.FAILED,
  }),
  [EvaluationReportDetailAction.RESET]: (
    state: EvaluationReportState,
  ): EvaluationReportState => ({
    ...state,
    ...evaluationReportDetailInitialState,
    action: EvaluationReportDetailAction.RESET,
  }),
};
