import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataAssignmentLetterCreateInitialState} from './masterDataAssignmentLetterCreate.reducer';
import {
  MasterDataAssignmentLetterCreateAction,
  MasterDataAssignmentLetterCreateFailedPayload,
  MasterDataAssignmentLetterCreateFetchPayload,
  MasterDataAssignmentLetterCreateSuccessPayload,
} from './masterDataAssignmentLetterCreate.type';

export const masterDataAssignmentLetterCreateHandler = {
  [MasterDataAssignmentLetterCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterCreateLoading: true,
    masterDataAssignmentLetterCreateParams: action.payload,
    action: MasterDataAssignmentLetterCreateAction.FETCH,
  }),
  [MasterDataAssignmentLetterCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterCreateParams: undefined,
    masterDataAssignmentLetterCreateLoading: false,
    masterDataAssignmentLetterCreateResponse: action.payload,
    action: MasterDataAssignmentLetterCreateAction.SUCCESS,
  }),
  [MasterDataAssignmentLetterCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterCreateLoading: false,
    masterDataAssignmentLetterCreateError: action.payload,
    action: MasterDataAssignmentLetterCreateAction.FAILED,
  }),
  [MasterDataAssignmentLetterCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataAssignmentLetterCreateInitialState,
    action: MasterDataAssignmentLetterCreateAction.RESET,
  }),
};
