import {
  MasterDataWorkInstructionListAction,
  MasterDataWorkInstructionListSuccessPayload,
  MasterDataWorkInstructionListFailedPayload,
  MasterDataWorkInstructionListFetchPayload,
} from './masterDataWorkInstructionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataWorkInstructionListInitialState} from './masterDataWorkInstructionList.reducer';

export const masterDataWorkInstructionListHandler = {
  [MasterDataWorkInstructionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataWorkInstructionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataWorkInstructionListLoading: true,
      masterDataWorkInstructionListParams: action.payload,
      action: MasterDataWorkInstructionListAction.FETCH,
    };
  },
  [MasterDataWorkInstructionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataWorkInstructionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataWorkInstructionListLoading: false,
    masterDataWorkInstructionListResponse: action.payload,
    action: MasterDataWorkInstructionListAction.SUCCESS,
  }),
  [MasterDataWorkInstructionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataWorkInstructionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataWorkInstructionListLoading: false,
    masterDataWorkInstructionListData: [
      ...state.masterDataWorkInstructionListData,
      ...action.payload.data,
    ],
    action: MasterDataWorkInstructionListAction.UPDATE,
  }),
  [MasterDataWorkInstructionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataWorkInstructionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataWorkInstructionListLoading: false,
    masterDataWorkInstructionListError: action.payload,
    action: MasterDataWorkInstructionListAction.FAILED,
  }),
  [MasterDataWorkInstructionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataWorkInstructionListInitialState,
    action: MasterDataWorkInstructionListAction.RESET,
  }),
};
