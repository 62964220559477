import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBasicServiceTypeMoveAction,
  MasterDataBasicServiceTypeMoveFailedPayload,
  MasterDataBasicServiceTypeMoveFetchPayload,
  MasterDataBasicServiceTypeMoveSuccessPayload,
} from './masterDataBasicServiceTypeMove.type';

export const masterDataBasicServiceTypeMoveAction = {
  fetch: createAction<MasterDataBasicServiceTypeMoveFetchPayload>(
    MasterDataBasicServiceTypeMoveAction.FETCH,
  ),
  success: createAction<MasterDataBasicServiceTypeMoveSuccessPayload>(
    MasterDataBasicServiceTypeMoveAction.SUCCESS,
  ),
  failed: createAction<MasterDataBasicServiceTypeMoveFailedPayload>(
    MasterDataBasicServiceTypeMoveAction.FAILED,
  ),
  reset: createAction(MasterDataBasicServiceTypeMoveAction.RESET),
};
