//#region IMPORT
// Libraries
import React from 'react';
// Assets
import './UserManagementDetailItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  title: string;
  subtitle: string;
}
//#endregion

const UserManagementDetailItem: React.FC<Props> = ({
  title,
  subtitle,
}: Props) => {
  return (
    <div className="user-management-detail-item">
      <div className="user-management-detail-item__title">{title}</div>
      <div className="user-management-detail-item__subtitle">{subtitle}</div>
    </div>
  );
};

export default UserManagementDetailItem;
