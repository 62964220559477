//#region IMPORT
// Libraries
import React from 'react';
import {RangeInput} from '@mui/lab/DateRangePicker/RangeTypes';
// Utils
import t from '../../../../lang';
// Components
import {
  MCheckMarks,
  MDateRangepicker,
  MDropdown,
  MSearchInput,
} from '../../../../components';
// Data
import {AssignmentType, SortType} from '../../../wrapper/entity';
// Assets
import './EvaluationReportFilter.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  searchFilterValue: string;
  searchFilterPlaceholder?: string;
  selectedCheckmarksFilter: string[];
  checkmarksFilterPlaceholder?: string;
  selectedDateFilter: RangeInput<unknown>;
  resetFilterLabel?: string;
  selectedSort: string;
  sortLabel?: string;
  handleChangeSearchFilter?(search: string): void;
  handleChangeCheckmarksFilter?(e: unknown): void;
  handleChangeDateFilter?(date: RangeInput<unknown> | null): void;
  handleResetFilter?(): void;
  handleSort(sort: string): void;
}
//#endregion

const EvaluationReportFilter: React.FC<Props> = ({
  searchFilterValue,
  searchFilterPlaceholder,
  selectedCheckmarksFilter,
  checkmarksFilterPlaceholder,
  selectedDateFilter,
  resetFilterLabel = t('Reset Filter'),
  selectedSort,
  sortLabel = t('Sort:'),
  handleChangeSearchFilter,
  handleChangeCheckmarksFilter,
  handleChangeDateFilter,
  handleResetFilter,
  handleSort,
}: Props) => {
  //#region GENERAL
  const sortOptions = [
    {label: t('Latest'), value: SortType.DESC},
    {label: t('Oldest'), value: SortType.ASC},
  ];
  const checkmarksFilterOptions = [
    {
      label: 'Halte',
      value: AssignmentType.HALTE,
    },
    {
      label: 'Bus BRT',
      value: AssignmentType.BUSBRT,
    },
    {
      label: 'Bus Non BRT',
      value: AssignmentType.BUSNONBRT,
    },
    {
      label: 'Bus Kecil',
      value: AssignmentType.BUSKECIL,
    },
    {
      label: 'Transcare',
      value: AssignmentType.TRANSCARE,
    },
  ];
  const showReset: boolean =
    searchFilterValue !== '' ||
    selectedCheckmarksFilter.length < 5 ||
    !selectedDateFilter.every((date) => date === null);
  //#endregion

  return (
    <div className="evaluation-report-filter">
      {handleChangeSearchFilter && (
        <div className="evaluation-report-filter__search">
          <MSearchInput
            inputClassName="evaluation-report-filter__search--input"
            inputValue={searchFilterValue}
            placeholder={searchFilterPlaceholder}
            setInputValue={(searchValue) => {
              handleChangeSearchFilter && handleChangeSearchFilter(searchValue);
            }}
          />
        </div>
      )}
      {checkmarksFilterOptions && (
        <div className="evaluation-report-filter__checkmarks">
          <MCheckMarks
            selected={selectedCheckmarksFilter}
            options={checkmarksFilterOptions}
            placeholder={checkmarksFilterPlaceholder}
            handleChangeCheckmarks={handleChangeCheckmarksFilter}
          />
        </div>
      )}
      {handleChangeDateFilter && (
        <div className="evaluation-report-filter__date">
          <MDateRangepicker
            inputValue={selectedDateFilter}
            handleChange={handleChangeDateFilter}
          />
        </div>
      )}
      {handleResetFilter && showReset && (
        <div
          className="evaluation-report-filter__reset"
          onClick={(e) => {
            e.stopPropagation();
            handleResetFilter();
          }}>
          {resetFilterLabel}
        </div>
      )}
      <div className="evaluation-report-filter__sort">
        <div className="evaluation-report-filter__sort-label">{sortLabel}</div>
        <div className="evaluation-report-filter__sort-input">
          <MDropdown
            placeholder=""
            inputWidth="10rem"
            options={sortOptions}
            inputClassName="noBorder"
            inputValue={selectedSort}
            handleChangeSelect={handleSort}
          />
        </div>
      </div>
    </div>
  );
};
export default EvaluationReportFilter;
