//#region IMPORT
// Libraries
import React, {useEffect, useState, useCallback} from 'react';
import {MTabPanel, MTabs} from '../../../../components';
// Utils
import t from '../../../../lang';
// Components
import {
  DashboardOverviewSection,
  DashboardSPMSection,
  DashboardDepartmentSection,
} from '../sections';
// Data
import {tabContentType} from '../../../wrapper/entity';
// Assets
import './DashboardManagementWrapper.scss';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {dashboardTabDepartmentListAction} from '../../usecases/dashboard.action';
import {DashboardState} from '../../usecases/dashboard.reducer';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../../user/usecases/user.reducer';
import {actionHandler} from '../../../wrapper/utils';
import {DashboardTabDepartmentListAction} from '../../usecases/dashboard.type';
import {DashboardTabDepartment} from '../../entity/dashboard.string.entity';
//#endregion

const DashboardManagementWrapper: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();

  const dashboard: DashboardState = useSelector(
    (state: AppState) => state.dashboard,
    shallowEqual,
  );
  const {data, userLevel}: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const permissions = data?.role?.permissions;
  const userLevelData: number = userLevel
    ? Number(userLevel?.replace('USERLEVEL', ''))
    : 8;

  const hasOverview =
    !!permissions?.dashboardOverviewAchievementFinalChart ||
    !!permissions?.dashboardOverviewAchievementQuarterlyChart ||
    !!permissions?.dashboardOverviewProfitAndFineChart ||
    !!permissions?.dashboardOverviewBestBusStopChart ||
    !!permissions?.dashboardOverviewBestOperatorChart;
  const hasSPM =
    permissions?.hasDashboardInternalServiceAchievementFinalChart ||
    permissions?.hasDashboardInternalGapChart ||
    permissions?.hasDashboardInternalWeightChart ||
    permissions?.hasDashboardInternalFindingChart ||
    permissions?.hasDashboardInternalRatedServiceChart;
  const hasDepartment =
    permissions?.hasDashboardPICAchivementChart ||
    permissions?.hasDashboardPICDifferenceChart ||
    permissions?.hasDashboardPICProfitAndFineChart ||
    permissions?.hasDashboardPICFindingChart ||
    permissions?.hasDashboardPICBestDepartmentChart;

  const isChiefofDivision = userLevelData === 3;

  const departmentList = data?.departmentId;
  const [departmentTabList, setDepartmentTabList] = useState<
    DashboardTabDepartment[]
  >([]);
  //#endregion

  //#region FETCH DEPARTMENT TAB LIST
  const fetchDepartmentTabList = useCallback(() => {
    dispatch(dashboardTabDepartmentListAction.fetch());
  }, [dispatch]);
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(dashboardTabDepartmentListAction.reset());
    fetchDepartmentTabList();
    return () => {
      dispatch(dashboardTabDepartmentListAction.reset());
    };
  }, [dispatch, fetchDepartmentTabList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const dashboardManagementWrapperActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder.addCase(DashboardTabDepartmentListAction.SUCCESS, (): void => {
          if (dashboard?.dashboardTabDepartmentListResponse?.data) {
            setDepartmentTabList(
              dashboard.dashboardTabDepartmentListResponse?.data,
            );
          }
        });
      });
    },
    [dashboard.dashboardTabDepartmentListResponse?.data],
  );
  useEffect(() => {
    dashboardManagementWrapperActionHandler(dashboard.action);
  }, [dashboard.action, dashboardManagementWrapperActionHandler]);
  //#endregion

  //#region HANDLE TAB
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };

  const departmentTabLabel: tabContentType[] = departmentTabList
    .filter((item) => {
      if (isChiefofDivision) {
        return item;
      }
      return item.id === departmentList;
    })
    .map((item) => ({
      label: item.name,
      component: <DashboardDepartmentSection data={item} />,
    }));

  const dashboardTabLabel: tabContentType[] = [
    ...(hasOverview
      ? [{label: t('Overview'), component: <DashboardOverviewSection />}]
      : []),
    ...(hasSPM ? [{label: t('SPM'), component: <DashboardSPMSection />}] : []),
    ...(hasDepartment ? departmentTabLabel : []),
  ];
  //#endregion

  return (
    <div className="dashboard-management-wrapper">
      <div className="dashboard-management-wrapper__tab">
        <MTabs
          variant="scrollable"
          tabsValue={tabValue}
          handleChangeTab={handleChangeTab}
          tabLabel={dashboardTabLabel.map((item) => item.label)}
        />
      </div>
      <div className="dashboard-management-wrapper__tab-panel">
        {dashboardTabLabel.map((item: tabContentType, index: number) => (
          <MTabPanel
            key={`dashboard-management-tab-label-${item.label}-${index}`}
            value={tabValue}
            index={index}
            className="dashboard-management-wrapper__tab-panel--item">
            {item.component}
          </MTabPanel>
        ))}
      </div>
    </div>
  );
};

export default DashboardManagementWrapper;
