import {CommonError} from '../../../../config/constant';
import {RoleFormData} from '../../entity/user.string.entity';

export const UserRoleAccessCreateAction = {
  FETCH: 'USER_ROLE_ACCESS_CREATE_FETCH',
  SUCCESS: 'USER_ROLE_ACCESS_CREATE_SUCCESS',
  FAILED: 'USER_ROLE_ACCESS_CREATE_FAILED',
  RESET: 'USER_ROLE_ACCESS_CREATE_RESET',
};

export interface UserRoleAccessCreateInitialState {
  userRoleAccessCreateLoading: boolean;
  userRoleAccessCreateParams?: UserRoleAccessCreateFetchPayload;
  userRoleAccessCreateError?: UserRoleAccessCreateFailedPayload;
  userRoleAccessCreateResponse?: UserRoleAccessCreateSuccessPayload;
}

export type UserRoleAccessCreateFetchPayload = RoleFormData;
export type UserRoleAccessCreateFailedPayload = CommonError;

export interface UserRoleAccessCreateSuccessPayload {}
