//#region IMPORT
// Libraries
import React from 'react';
import {useSelector} from 'react-redux';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import {Avatar} from '@mui/material';
// Utils
import t from '../../../../lang';
import {toInitial} from '../../../../utils';
// Components
import {MButton, MTextField} from '../../../../components';
// Data
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../../user/usecases/user.reducer';
import {DashboardCommentFormData} from '../../entity/dashboard.string.entity';
// Assets
import './DashboardCommentForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isPreview?: boolean;
  handleCancelComment(): void;
  handleAddComment(_comment: DashboardCommentFormData): void;
}
//#endregion

const DashboardCommentForm: React.FC<Props> = ({
  isPreview = false,
  handleCancelComment,
  handleAddComment,
}: Props) => {
  //#region GENERAL
  const user: UserState = useSelector((state: AppState) => state.user);
  const initialAvatar: string = toInitial(user.data?.name);
  //#endregion

  //#region VALIDATION
  const dashboardCommentValidation = Yup.object().shape({
    comment: Yup.string().required(t('Comment is required.')),
  });
  //#endregion

  return (
    <div className="dashboard-comment-form">
      <Formik
        initialValues={{id: user.data?.id || '', comment: ''}}
        validationSchema={dashboardCommentValidation}
        onSubmit={handleAddComment}>
        {({handleSubmit, isValid}): React.ReactElement => (
          <div className="dashboard-comment-form__form">
            <div className="dashboard-comment-form__section">
              {isPreview ? null : (
                <div className="dashboard-comment-form__section--icon">
                  {user.data?.profilePicture ? (
                    <Avatar
                      className="dashboard-comment-form__section--icon-avatar"
                      alt={user.data?.name}
                      src={user.data?.profilePicture}
                    />
                  ) : (
                    <Avatar
                      className="dashboard-comment-form__section--icon-avatar"
                      alt={user.data?.name}>
                      {initialAvatar}
                    </Avatar>
                  )}
                </div>
              )}
              <div className="dashboard-comment-form__section--field">
                <Field
                  className="dashboard-input"
                  isTextArea={true}
                  textFieldRows={3}
                  name="comment"
                  placeholder={t('Enter Comment')}
                  component={MTextField}
                />
              </div>
            </div>

            <div className="dashboard-comment-form__form--action">
              <div className="dashboard-comment-form-action__canceled">
                <MButton
                  buttonLabel={t('Cancel')}
                  handleClick={handleCancelComment}
                />
              </div>
              <div className="dashboard-comment-form-action">
                <MButton
                  isDisabled={!isValid}
                  buttonLabel={t('Send')}
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
export default DashboardCommentForm;
