/* eslint-disable  @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// Data
import {WorkReport} from '../../entity/workReport.string.entity';
// Assets
import './WorkReportDetail.component.style.scss';

//#endregion

//#region INTERFACE
interface Props {
  data?: WorkReport;
  pageNumber?: number;
  scale?: number;
  orientation?: 'portrait' | 'landscape';
  onNumPages?(pdf: any): void;
}
//#endregion

const WorkReportDetail: React.FC<Props> = ({
  data,
  pageNumber = 1,
  scale = 100,
  orientation = 'portrait',
  onNumPages,
}: Props) => {
  const fixedScale = scale / 100;
  return (
    <div className="work-report-detail">
      <Document
        className="work-report-detail__document"
        file={data?.mainFile}
        onLoadSuccess={(pdf: any) => onNumPages && onNumPages(pdf)}>
        <Page
          className="work-report-detail__page"
          orientation={orientation}
          pageNumber={pageNumber}
          scale={fixedScale}
        />
      </Document>
    </div>
  );
};

export default WorkReportDetail;
