//#region IMPORT
// Libraries
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  AppBar,
  Badge,
} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router';
// Utils
import t from '../../../../lang';
import {colors} from '../../../../config/_theme';
import {toInitial} from '../../../../utils';
import {AppState} from '../../../../config/Interface';
import {formPathToLabel, pathToLabel} from '../../utils';
import {userDataAction} from '../../../user/usecases/userData/userData.action';
import {actionHandler} from '../../utils';
import {NotificationListAction} from '../../../notification/usecases/notification.type';
// Component
import {Modal} from '../../../../components';
import {UserChangePasswordForm} from '../../../user/views/UserChangePassword/components';
import {NotificationSection} from '../../../notification/views/sections';
// Data
import {PATH} from '../../entity';
import {UserData} from '../../../user/entity/user.string.entity';
// Assets
import './Navigation.component.style.scss';
import {LogoSPM} from '../../../../assets';
import {
  IconNotification,
  IconCaretArrowUp,
  IconCaretArrowDown,
} from '../../../../assets/icons';
//#endregion

const Navigation: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const userData: UserData | undefined = useSelector(
    (state: AppState) => state.user.data,
  );
  const notificationData = useSelector((state: AppState) => state.notification);

  const [isMenuVisible, setMenuVisible] = useState<null | HTMLElement>(null);
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [
    isNotificationVisible,
    setNotificationVisible,
  ] = useState<null | HTMLElement>(null);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const initialAvatar: string = toInitial(userData?.name);
  const customHover = {
    ':hover': {
      bgcolor: colors.primary.blue2,
    },
  };
  //#endregion

  //#region ACTIONS
  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setMenuVisible(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuVisible(null);
  };
  const handleLogout = () => {
    dispatch(userDataAction.logout());
  };

  const handleNavigation = (path: PATH) => {
    history.push(path);
    handleCloseMenu();
    handleCloseNotification();
  };

  const handleChangePassword = () => {
    setIsChangePassword(true);
    handleCloseMenu();
  };

  const handleOpenNotification = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setNotificationVisible(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setNotificationVisible(null);
  };
  //#endregion

  //#region ACTION HANDLER
  const notificationActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder.addCase(NotificationListAction.UPDATE, (): void => {
          if (notificationData?.notificationListResponse) {
            setNotificationCount(
              notificationData?.notificationListResponse?.data?.unReadCount ??
                0,
            );
          }
        });
      });
    },
    [notificationData.notificationListResponse],
  );
  useEffect(() => {
    notificationActionHandler(notificationData.action);
  }, [notificationData.action, notificationActionHandler]);
  //#endregion

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.06)',
          backgroundColor: colors.light,
        }}>
        <div className="navigation">
          <Toolbar className="navigation__icon">
            <div className="navigation__icon--logo">
              <LogoSPM width={123} height={22} />
            </div>
            <span className="navigation__icon--title">
              {`${pathToLabel(location.pathname as PATH)} ${
                location.pathname === PATH.MASTER_DATA_FORM_ASSESSMENT
                  ? formPathToLabel(location.search)
                  : ''
              }`}
            </span>

            <div className="navigation__icon-wrapper">
              <Badge
                badgeContent={notificationCount}
                max={99}
                color="error"
                overlap="circular">
                <IconButton
                  edge="start"
                  className={`navigation__icon--button-action${
                    isNotificationVisible ? '--selected' : ''
                  }`}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleOpenNotification}
                  sx={customHover}>
                  <IconNotification
                    fill={isNotificationVisible ? colors.primary.blue : ''}
                  />
                </IconButton>
              </Badge>
              {userData && (
                <Button
                  className={`navigation__icon--button-avatar${
                    isMenuVisible ? '--selected' : ''
                  }`}
                  onClick={handleOpenMenu}
                  sx={customHover}>
                  <>
                    {userData?.profilePicture ? (
                      <Avatar
                        className="navigation__icon--button-avatar-picture"
                        alt={userData?.name}
                        src={userData?.profilePicture}
                      />
                    ) : (
                      <Avatar
                        className="navigation__icon--button-avatar-picture"
                        alt={userData?.name}>
                        {initialAvatar}
                      </Avatar>
                    )}
                    <span className="navigation__icon--button-avatar-text">
                      {`${userData.name}`}
                    </span>
                    {isMenuVisible ? (
                      <IconCaretArrowUp />
                    ) : (
                      <IconCaretArrowDown />
                    )}
                  </>
                </Button>
              )}
              <Menu
                id="menu-appbar"
                anchorEl={isMenuVisible}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                className="navigation__menu"
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={!!isMenuVisible}
                onClose={handleCloseMenu}>
                <MenuItem onClick={() => handleNavigation(PATH.USER_ACCOUNT)}>
                  <div className="navigation-menuItem">
                    <div className="navigation-menuItem__label body1">
                      {t('Account')}
                    </div>
                  </div>
                </MenuItem>
                <MenuItem onClick={() => handleChangePassword()}>
                  <div className="navigation-menuItem">
                    <div className="navigation-menuItem__label body1">
                      {t('Change Password')}
                    </div>
                  </div>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <div className="navigation-menuItem">
                    <div className="navigation-menuItem__label body1">
                      {t('Logout')}
                    </div>
                  </div>
                </MenuItem>
              </Menu>

              {isNotificationVisible ? (
                <Menu
                  id="menu-notification"
                  anchorEl={isNotificationVisible}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  className="navigation__notification"
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={!!isNotificationVisible}
                  onClose={handleCloseNotification}>
                  <div className="notification-menu">
                    <NotificationSection
                      isAllPage={false}
                      showAll={() => handleNavigation(PATH.NOTIFICATION)}
                      handleClose={handleCloseNotification}
                    />
                  </div>
                </Menu>
              ) : null}
            </div>
          </Toolbar>
        </div>
      </AppBar>

      <Modal
        onClose={() => setIsChangePassword(false)}
        title={t('Change Password')}
        isOpen={isChangePassword}>
        <div className="user-change-password-modal-container">
          <UserChangePasswordForm onCancel={() => setIsChangePassword(false)} />
        </div>
      </Modal>
    </>
  );
};

export default Navigation;
