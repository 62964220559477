//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import moment from 'moment';
// Utils
import t from '../../../../lang';
// Components
import {MButton, MSelect, MDatepickerInput} from '../../../../components';
// Data
import {
  WorkReportDownloadFormData,
  WorkReportType,
} from '../../entity/workReport.string.entity';
import {OptionType} from '../../../wrapper/entity';
// Assets
import './WorkReportDownloadForm.component.style.scss';
//#endregion

interface Props {
  isSPM?: boolean;
  isLoading?: boolean;
  initialValues: WorkReportDownloadFormData;
  workReportTypeOptions?: OptionType[];
  handleCancel(): void;
  handleDownload(_workReport: WorkReportDownloadFormData): void;
}
const WorkReportDownloadForm: React.FC<Props> = ({
  isSPM,
  isLoading,
  initialValues,
  workReportTypeOptions = [
    {label: WorkReportType.MONTHLY, value: WorkReportType.MONTHLY},
    {
      label: WorkReportType.RECONCILIATION,
      value: WorkReportType.RECONCILIATION,
    },
  ],
  handleCancel,
  handleDownload,
}: Props) => {
  //#region VALIDATION
  const workReportDownloadFormValidation = Yup.object().shape({
    reportType: Yup.string().required(t('Report Type is required.')),
    startMonth: Yup.string().required(t('Start Month is required.')),
    endMonth: Yup.string().required(t('End Month is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="work-report-download-form">
      <div className="work-report-download-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={workReportDownloadFormValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleDownload}>
          {({handleSubmit, values, isValid}): React.ReactElement => {
            return (
              <>
                <div className="work-report-download-form__section">
                  <div className="work-report-download-form__section--field">
                    <div className="input-label">{t('Report Type')}</div>
                    <Field
                      disabled={!isSPM || isLoading}
                      className="user-input"
                      name="reportType"
                      placeholder={t('Choose Report Type')}
                      options={workReportTypeOptions}
                      component={MSelect}
                    />
                  </div>
                  <div className="work-report-download-form__section--group">
                    <div className="work-report-download-form__section--field">
                      <div className="input-label">{t('Start Month')}</div>
                      <Field
                        disabled={isLoading}
                        className="user-input"
                        name="startMonth"
                        inputValue={values.startMonth}
                        placeholder={t('Choose Start Month')}
                        toolbarText={t('Choose Start Month')}
                        views={['month', 'year']}
                        format="MMMM YYYY"
                        maxDate={moment().endOf('year')}
                        component={MDatepickerInput}
                      />
                    </div>
                    <div className="work-report-download-form__section--field">
                      <div className="input-label">{t('End Month')}</div>
                      <Field
                        disabled={isLoading}
                        className="user-input"
                        name="endMonth"
                        inputValue={values.endMonth}
                        placeholder={t('Choose End Month')}
                        toolbarText={t('Choose End Month')}
                        views={['month', 'year']}
                        format="MMMM YYYY"
                        maxDate={moment().endOf('year')}
                        component={MDatepickerInput}
                      />
                    </div>
                  </div>
                </div>

                <div className="work-report-download-form__form--action">
                  <div className="work-report-download-form-action__canceled">
                    <MButton
                      isDisabled={isLoading}
                      buttonLabel={t('Cancel')}
                      handleClick={handleCancel}
                    />
                  </div>
                  <div className="work-report-download-form-action">
                    <MButton
                      isDisabled={!isValid || isLoading}
                      isLoading={isLoading}
                      buttonLabel={t('Download File')}
                      handleClick={handleSubmit}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default WorkReportDownloadForm;
