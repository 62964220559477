import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailIndicatorListAction,
  MasterDataDetailIndicatorListFailedPayload,
  MasterDataDetailIndicatorListFetchPayload,
  MasterDataDetailIndicatorListSuccessPayload,
  MasterDataDetailIndicatorListUpdatePayload,
} from './masterDataDetailIndicatorList.type';

export const masterDataDetailIndicatorListAction = {
  fetch: createAction<MasterDataDetailIndicatorListFetchPayload>(
    MasterDataDetailIndicatorListAction.FETCH,
  ),
  success: createAction<MasterDataDetailIndicatorListSuccessPayload>(
    MasterDataDetailIndicatorListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailIndicatorListFailedPayload>(
    MasterDataDetailIndicatorListAction.FAILED,
  ),
  update: createAction<MasterDataDetailIndicatorListUpdatePayload>(
    MasterDataDetailIndicatorListAction.UPDATE,
  ),
  reset: createAction(MasterDataDetailIndicatorListAction.RESET),
};
