import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataBasicServiceTypeEditInitialState} from './masterDataBasicServiceTypeEdit.reducer';
import {
  MasterDataBasicServiceTypeEditAction,
  MasterDataBasicServiceTypeEditFailedPayload,
  MasterDataBasicServiceTypeEditFetchPayload,
  MasterDataBasicServiceTypeEditSuccessPayload,
} from './masterDataBasicServiceTypeEdit.type';

export const masterDataBasicServiceTypeEditHandler = {
  [MasterDataBasicServiceTypeEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeEditLoading: true,
    masterDataBasicServiceTypeEditParams: action.payload,
    action: MasterDataBasicServiceTypeEditAction.FETCH,
  }),
  [MasterDataBasicServiceTypeEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeEditParams: undefined,
    masterDataBasicServiceTypeEditLoading: false,
    masterDataBasicServiceTypeEditResponse: action.payload,
    action: MasterDataBasicServiceTypeEditAction.SUCCESS,
  }),
  [MasterDataBasicServiceTypeEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeEditLoading: false,
    masterDataBasicServiceTypeEditError: action.payload,
    action: MasterDataBasicServiceTypeEditAction.FAILED,
  }),
  [MasterDataBasicServiceTypeEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBasicServiceTypeEditInitialState,
    action: MasterDataBasicServiceTypeEditAction.RESET,
  }),
};
