import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDetailIndicatorDeleteInitialState} from './masterDataDetailIndicatorDelete.reducer';
import {
  MasterDataDetailIndicatorDeleteAction,
  MasterDataDetailIndicatorDeleteFailedPayload,
  MasterDataDetailIndicatorDeleteFetchPayload,
  MasterDataDetailIndicatorDeleteSuccessPayload,
} from './masterDataDetailIndicatorDelete.type';

export const masterDataDetailIndicatorDeleteHandler = {
  [MasterDataDetailIndicatorDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorDeleteLoading: true,
    masterDataDetailIndicatorDeleteParams: action.payload,
    action: MasterDataDetailIndicatorDeleteAction.FETCH,
  }),
  [MasterDataDetailIndicatorDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorDeleteParams: undefined,
    masterDataDetailIndicatorDeleteLoading: false,
    masterDataDetailIndicatorDeleteResponse: action.payload,
    action: MasterDataDetailIndicatorDeleteAction.SUCCESS,
  }),
  [MasterDataDetailIndicatorDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorDeleteLoading: false,
    masterDataDetailIndicatorDeleteError: action.payload,
    action: MasterDataDetailIndicatorDeleteAction.FAILED,
  }),
  [MasterDataDetailIndicatorDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailIndicatorDeleteInitialState,
    action: MasterDataDetailIndicatorDeleteAction.RESET,
  }),
};
