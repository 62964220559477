//#region IMPORT
// Libraries
import React from 'react';
// Components
import FollowUpExpiredListItem from '../FollowUpExpiredListItem';
// Data
import {
  FollowUp,
  FollowUpExpiredFormData,
  FollowUpLoadingItem,
} from '../../../../../entity/followUp.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  detailData?: FollowUp;
  isLoading?: FollowUpLoadingItem;
  isCanSendNote?: boolean;
  onViewItem?(detailData: FollowUp): void;
  handleSubmitExpired(note: FollowUpExpiredFormData): void;
}
//#endregion

const FollowUpExpiredDetail: React.FC<Props> = ({
  detailData,
  isLoading,
  isCanSendNote,
  onViewItem,
  handleSubmitExpired,
}: Props) => {
  return (
    <div className="follow-up-expired-detail__container">
      {detailData ? (
        <FollowUpExpiredListItem
          data={detailData}
          isLoading={isLoading?.isLoading && isLoading.id === detailData.id}
          isCanSendNote={isCanSendNote}
          isDetail
          onViewItem={() => onViewItem && onViewItem(detailData)}
          handleSubmitExpired={handleSubmitExpired}
        />
      ) : null}
    </div>
  );
};

export default FollowUpExpiredDetail;
