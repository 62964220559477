//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../lang';
import {colors} from '../../../../config/_theme';
import {followUpManagementDataToIcon, statusToLabel} from '../../utils';
// Components
import FollowUpChip from '../FollowUpChip';
// Data
import {FollowUp, FollowUpStatus} from '../../entity/followUp.string.entity';
// Assets
import './FollowUpCardHeader.component.style.scss';
import {IconTimeCircle} from '../../../../assets/icons';
import moment from 'moment';
import {AssignmentType} from '../../../wrapper/entity';
import {getTimeDifference} from '../../../../utils';
//#endregion

//#region INTERFACE
interface Props {
  headerData?: FollowUp;
  isSPMOfficer?: boolean;
  status?: FollowUpStatus;
  statusColors?: 'blue' | 'green' | 'red';
}
//#endregion

const FollowUpCardHeader: React.FC<Props> = ({
  headerData,
  status,
  isSPMOfficer,
  statusColors = 'blue',
}: Props) => {
  //#region GENERAL
  const {icon} = followUpManagementDataToIcon(
    headerData?.assignmentType || AssignmentType.HALTE,
  );
  const isCorner = headerData?.routeType === 'Ujung';
  //#endregion

  const confirmDueDate = headerData?.confirmDueDate
    ? moment(headerData?.confirmDueDate, 'YYYY-MM-DD HH:mm')
    : undefined;
  const dueDate = headerData?.findingDueDate
    ? moment(headerData?.findingDueDate, 'YYYY-MM-DD HH:mm')
    : undefined;
  const findingDate = headerData?.findingDate
    ? moment(headerData?.findingDate, 'YYYY-MM-DD HH:mm').format(
        'DD MMM YYYY HH:mm',
      )
    : '-';
  const hasExpired = headerData?.isExpired;
  const hasInjury = !headerData?.findingDueDate;
  const hasInjuryConfirmation = !headerData?.confirmDueDate;

  const showTimer =
    !hasExpired &&
    headerData?.status !== FollowUpStatus.WAITING &&
    headerData?.status !== FollowUpStatus.ACCEPTED &&
    headerData?.status !== FollowUpStatus.REJECTED;

  const expiredTimer = showTimer && getTimeDifference(dueDate);

  const expiredConfirmTimer =
    !expiredTimer && showTimer && getTimeDifference(confirmDueDate);

  return (
    <div className="follow-up-card-header">
      <div className="follow-up-card-header-upper">
        <div className="follow-up-card-header-upper__icon">{icon}</div>
        <div className="follow-up-card-header-upper__title">
          <div className="follow-up-card-header-upper__title-text">
            {headerData?.assignmentTypeCode || '-'}
          </div>
          <div className="follow-up-card-header-upper__title-date">
            {findingDate}
          </div>
        </div>
        {status && (
          <div className="follow-up-card-header-upper__status">
            <div
              className={`follow-up-card-header-upper__status-${statusColors}`}>
              {statusToLabel(status, hasInjury, isSPMOfficer)}
            </div>
          </div>
        )}
        {!isSPMOfficer && expiredTimer && (
          <div className="follow-up-card-header-upper__chip">
            <FollowUpChip
              text={expiredTimer}
              icon={
                <IconTimeCircle
                  fill={colors.secondary[hasInjury ? 'red' : 'green']}
                />
              }
              backgroundColor={colors.secondary[hasInjury ? 'red1' : 'green1']}
              color={colors.secondary[hasInjury ? 'red' : 'green']}
            />
          </div>
        )}
        {!isSPMOfficer && expiredConfirmTimer && (
          <div className="follow-up-card-header-upper__chip">
            <FollowUpChip
              text={expiredConfirmTimer}
              icon={
                <IconTimeCircle
                  fill={
                    colors.secondary[hasInjuryConfirmation ? 'red' : 'green']
                  }
                />
              }
              backgroundColor={
                colors.secondary[hasInjuryConfirmation ? 'red1' : 'green1']
              }
              color={colors.secondary[hasInjuryConfirmation ? 'red' : 'green']}
            />
          </div>
        )}
      </div>

      <div className="follow-up-card-header-detail">
        {headerData?.corridor && (
          <div className="follow-up-card-header-detail-text">
            {`${t('Corridor')} ${headerData?.corridor}`}
          </div>
        )}
        <div className="follow-up-card-header-detail-text">
          {headerData?.routeName || headerData?.location}
        </div>
      </div>

      {(isCorner || hasExpired) && (
        <div className="follow-up-card-header-type">
          {isCorner && (
            <FollowUpChip
              text="Ujung"
              backgroundColor={colors.primary.blue1}
              color={colors.primary.blue}
            />
          )}
          {hasExpired && (
            <FollowUpChip
              text={t('Expired')}
              icon={<IconTimeCircle fill={colors.light} />}
              backgroundColor={colors.text.lightGrey5}
              color={colors.light}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default FollowUpCardHeader;
