import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataCategoryOptionListAction,
  MasterDataCategoryOptionListFailedPayload,
  MasterDataCategoryOptionListFetchPayload,
  MasterDataCategoryOptionListSuccessPayload,
  MasterDataCategoryOptionListUpdatePayload,
} from './masterDataCategoryOptionList.type';

export const masterDataCategoryOptionListAction = {
  fetch: createAction<MasterDataCategoryOptionListFetchPayload>(
    MasterDataCategoryOptionListAction.FETCH,
  ),
  success: createAction<MasterDataCategoryOptionListSuccessPayload>(
    MasterDataCategoryOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataCategoryOptionListFailedPayload>(
    MasterDataCategoryOptionListAction.FAILED,
  ),
  update: createAction<MasterDataCategoryOptionListUpdatePayload>(
    MasterDataCategoryOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataCategoryOptionListAction.RESET),
};
