import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationValueOptionListAction,
  MasterDataObservationValueOptionListFailedPayload,
  MasterDataObservationValueOptionListFetchPayload,
  MasterDataObservationValueOptionListSuccessPayload,
  MasterDataObservationValueOptionListUpdatePayload,
} from './masterDataObservationValueOptionList.type';

export const masterDataObservationValueOptionListAction = {
  fetch: createAction<MasterDataObservationValueOptionListFetchPayload>(
    MasterDataObservationValueOptionListAction.FETCH,
  ),
  success: createAction<MasterDataObservationValueOptionListSuccessPayload>(
    MasterDataObservationValueOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationValueOptionListFailedPayload>(
    MasterDataObservationValueOptionListAction.FAILED,
  ),
  update: createAction<MasterDataObservationValueOptionListUpdatePayload>(
    MasterDataObservationValueOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataObservationValueOptionListAction.RESET),
};
