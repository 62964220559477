//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {
  masterDataFormControllerCreateAction,
  masterDataFormControllerEditAction,
} from '../../../../usecases/masterData.action';
// Components
import {MasterDataFormControllerForm} from './components';
// Data
import {
  CheckLocation,
  IMPORTANTNOTESTATUS,
  MasterDataFormController,
  MasterDataFormControllerFormData,
  MasterDataFormControllerFormValues,
  ObservationValueType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataFormControllerFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  data?: MasterDataFormController;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataFormControllerFormEditContainer: React.FC<Props> = ({
  assignmentType,
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataFormControllerFormValues = {
    id: data?.id || '',
    assignmentTypeId: assignmentType,
    checkLocation: data?.checkLocation || CheckLocation.OUTSIDE,
    categoryId: data?.categoryId || '',
    basicServiceTypeId: data?.basicServiceTypeId || '',
    isNote: data?.isNote
      ? IMPORTANTNOTESTATUS.EXIST
      : IMPORTANTNOTESTATUS.NOT_EXIST,
    reviewIndicatorId: data?.reviewIndicatorId || '',
    detailIndicator: data?.detailIndicator || [],
    indicatorValue: data?.indicatorValue || [],
    observationId: data?.observationId || '',
    detailObservation: data?.detailObservation || [],
    observationValueType:
      data?.observationValueType || ObservationValueType.MCHOICE,
    observationValue: data?.observationValue || [],
    selectedDetailIndicator:
      data?.detailIndicator.map((item) => item.name) || [],
    selectedIndicatorValue: data?.indicatorValue.map((item) => item.name) || [],
    selectedDetailObservation:
      data?.detailObservation.map((item) => item.name) || [],
    selectedObservationValue:
      data?.observationValue.map((item) => item.name) || [],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitFormController = (
    formValue: MasterDataFormControllerFormValues,
  ) => {
    const formattedData: MasterDataFormControllerFormData = {
      ...(formValue.id ? {id: formValue?.id} : {}),
      assignmentTypeId: assignmentType,
      checkLocation: formValue.checkLocation,
      categoryId: formValue?.categoryId,
      ...(formValue?.basicServiceTypeId
        ? {basicServiceTypeId: formValue?.basicServiceTypeId}
        : {}),
      ...(formValue.isNote === IMPORTANTNOTESTATUS.NOT_EXIST
        ? {
            isNote: false,
            reviewIndicatorId: formValue?.reviewIndicatorId,
            detailIndicator: formValue?.detailIndicator
              ? formValue?.detailIndicator.map((item) => ({id: item.id}))
              : [],
            indicatorValue: formValue?.indicatorValue
              ? formValue?.indicatorValue.map((item) => ({id: item.id}))
              : [],
            ...(formValue.observationId
              ? {observationId: formValue?.observationId}
              : {}),
            detailObservation: formValue?.detailObservation
              ? formValue?.detailObservation.map((item) => ({id: item.id}))
              : [],
            ...(formValue.observationValueType
              ? {observationValueType: formValue.observationValueType}
              : {}),
            observationValue: formValue?.observationValue
              ? formValue?.observationValue.map((item) => ({id: item.id}))
              : [],
          }
        : {
            isNote: true,
          }),
    };

    if (data?.id) {
      dispatch(masterDataFormControllerEditAction.fetch(formattedData));
    } else {
      dispatch(masterDataFormControllerCreateAction.fetch(formattedData));
    }
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-form-controller-form-container">
      <MasterDataFormControllerForm
        assignmentType={assignmentType}
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitFormController={handleSubmitFormController}
      />
    </div>
  );
  //#endregion
};

export default MasterDataFormControllerFormEditContainer;
