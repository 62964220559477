import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataIndicatorValueListAction,
  MasterDataIndicatorValueListFailedPayload,
  MasterDataIndicatorValueListFetchPayload,
  MasterDataIndicatorValueListSuccessPayload,
  MasterDataIndicatorValueListUpdatePayload,
} from './masterDataIndicatorValueList.type';

export const masterDataIndicatorValueListAction = {
  fetch: createAction<MasterDataIndicatorValueListFetchPayload>(
    MasterDataIndicatorValueListAction.FETCH,
  ),
  success: createAction<MasterDataIndicatorValueListSuccessPayload>(
    MasterDataIndicatorValueListAction.SUCCESS,
  ),
  failed: createAction<MasterDataIndicatorValueListFailedPayload>(
    MasterDataIndicatorValueListAction.FAILED,
  ),
  update: createAction<MasterDataIndicatorValueListUpdatePayload>(
    MasterDataIndicatorValueListAction.UPDATE,
  ),
  reset: createAction(MasterDataIndicatorValueListAction.RESET),
};
