import {
  MasterDataObservationOptionListAction,
  MasterDataObservationOptionListSuccessPayload,
  MasterDataObservationOptionListFailedPayload,
  MasterDataObservationOptionListFetchPayload,
} from './masterDataObservationOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataObservationOptionListInitialState} from './masterDataObservationOptionList.reducer';

export const masterDataObservationOptionListHandler = {
  [MasterDataObservationOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataObservationOptionListLoading: true,
      masterDataObservationOptionListParams: action.payload,
      action: MasterDataObservationOptionListAction.FETCH,
    };
  },
  [MasterDataObservationOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationOptionListLoading: false,
    masterDataObservationOptionListResponse: action.payload,
    action: MasterDataObservationOptionListAction.SUCCESS,
  }),
  [MasterDataObservationOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationOptionListLoading: false,
    masterDataObservationOptionListData: [
      ...state.masterDataObservationOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataObservationOptionListAction.UPDATE,
  }),
  [MasterDataObservationOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationOptionListLoading: false,
    masterDataObservationOptionListError: action.payload,
    action: MasterDataObservationOptionListAction.FAILED,
  }),
  [MasterDataObservationOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationOptionListInitialState,
    action: MasterDataObservationOptionListAction.RESET,
  }),
};
