//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
// Utils
import t from '../../../../../lang';
import {AppState} from '../../../../../config/Interface';
import {getNotificationIcon, refactorByDate} from '../../../utils';
import {
  notificationListAction,
  notificationReadAllAction,
} from '../../../usecases/notification.action';
import {
  NotificationListAction,
  NotificationReadAllAction,
} from '../../../usecases/notification.type';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.action';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {NotificationList} from './components';
import {MSpinner} from '../../../../../components';
// Data
import {
  NotificationBadgeType,
  NotificationManagement,
  NotificationProps,
} from '../../../entity/notification.string.entity';
// Assets
import './NotificationSection.scss';
//#endregion

//#region INTERFACE
interface Props {
  isAllPage?: boolean;
  showAll?(): void;
  handleClose?(): void;
}
//#endregion

const NotificationSection: React.FC<Props> = ({
  isAllPage = true,
  showAll,
  handleClose,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const history = useHistory();
  const [notificationListData, setNotificationListData] = useState<
    NotificationProps[]
  >([]);
  const [
    formattedNotificationListData,
    setFormattedNotificationListData,
  ] = useState<NotificationManagement[]>([]);
  const [isNotRead, setIsNotRead] = useState<boolean>(false);

  const notificationData = useSelector(
    (state: AppState) => state.notification,
    shallowEqual,
  );

  const isLoadingNotification = notificationData.notificationListLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [notificationPageNumber, setNotificationPageNumber] = useState<number>(
    0,
  );
  const [notificationPageCount, setNotificationPageCount] = useState<number>(0);
  const notificationPageSize = 20;

  const handleChangePage = (_: unknown, _page: number) => {
    if (_page <= notificationPageCount) {
      setNotificationPageNumber(_page);
    }
  };
  //#endregion

  //#region READ ALL NOTIFICATION LIST
  const readAllNotificationList = useCallback(() => {
    dispatch(notificationReadAllAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region FETCH NOTIFICATION LIST
  const fetchNotificationList = useCallback(
    (page?: number, size?: number) => {
      dispatch(notificationListAction.fetch({page, size}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(notificationListAction.reset());
    fetchNotificationList(notificationPageNumber, notificationPageSize);

    return () => {
      dispatch(notificationListAction.reset());
    };
  }, [dispatch, fetchNotificationList, notificationPageNumber]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region DATA REFACTOR
  const dataRefactor = useCallback(
    (notificationData: NotificationProps[]) => {
      let notificationTempData: NotificationProps[] = [];
      if (notificationPageNumber > 0) {
        notificationTempData = [...notificationListData, ...notificationData];
      } else {
        notificationTempData = notificationData;
      }

      setNotificationListData(notificationTempData);
      const data = isAllPage
        ? notificationTempData
        : notificationTempData.slice(0, 10);
      const dataTemp = data?.map((dataItem) => ({
        ...dataItem,
        icon: getNotificationIcon(dataItem.notifImg),
      }));
      const isNotRead = dataTemp.some((item) => item.notifRead === false);
      setIsNotRead(isNotRead);
      const formattedNotificationListData: NotificationManagement[] = refactorByDate(
        dataTemp,
      );
      setFormattedNotificationListData(formattedNotificationListData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAllPage, notificationPageNumber],
  );
  //#endregion

  //#region HANDLE CLICK
  const handleClickNotification = (_notifProps: NotificationProps) => {
    const urlArr: string[] = _notifProps.notifDirection.split('/') ?? [];
    const urlDirection: string = urlArr[1] ?? '';
    const urlParamsId: string = urlArr[2] ?? '';
    const urlParamsType: NotificationBadgeType | null = _notifProps.notifTypeDetail as NotificationBadgeType;
    const urlSearchParam = new URLSearchParams({
      ...(urlParamsId ? {id: urlParamsId} : {}),
      ...(urlParamsType ? {followUpType: urlParamsType} : {}),
    }).toString();
    handleClose && handleClose();
    history.push({
      pathname: urlDirection,
      ...(urlSearchParam ? {search: urlSearchParam} : {}),
    });
  };
  //#endregion

  //#region ACTION HANDLER
  const notificationActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(NotificationListAction.UPDATE, (): void => {
            if (notificationData?.notificationListResponse) {
              dataRefactor(
                notificationData.notificationListResponse.data.content,
              );
              setNotificationPageCount(
                notificationData?.notificationListResponse?.data?.maxPage ?? 0,
              );
            }
          })
          .addCase(NotificationListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(notificationListAction.reset());
          })
          .addCase(NotificationReadAllAction.SUCCESS, (): void => {
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Read all data success!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(NotificationReadAllAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Read all data failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(notificationReadAllAction.reset());
          });
      });
    },
    [
      dataRefactor,
      dispatch,
      notificationData.notificationListResponse,
      refresh,
    ],
  );
  useEffect(() => {
    notificationActionHandler(notificationData.action);
  }, [notificationData.action, notificationActionHandler]);
  //#endregion

  const emptyData = () => {
    return (
      <div className="notification-section__empty">
        {t('No notifications at this time')}
      </div>
    );
  };

  const loadingData = () => {
    return (
      <div className="notification-section__loading">
        <MSpinner size={40} />
      </div>
    );
  };

  return (
    <>
      <div className="notification-section">
        <div className="notification-section__header">
          {isAllPage ? null : (
            <div className="notification-section__header-title">
              {t('Notification')}
            </div>
          )}
          {formattedNotificationListData.length > 0 && isNotRead && (
            <div
              className="notification-section__header-action"
              onClick={(event) => {
                event.stopPropagation();
                readAllNotificationList();
              }}>
              {t('Mark All As Read')}
            </div>
          )}
        </div>
        {formattedNotificationListData.length == 0 &&
          isLoadingNotification &&
          loadingData()}
        {formattedNotificationListData.length > 0 && (
          <>
            <div
              className="notification-section__content"
              style={isAllPage ? {} : {maxWidth: '50rem'}}>
              <NotificationList
                data={formattedNotificationListData}
                handleClick={handleClickNotification}
              />
            </div>
            <div className="notification-section__footer">
              {!isAllPage && (
                <div
                  className="notification-section__footer-action"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClose && handleClose();
                    showAll && showAll();
                  }}>
                  {t('See All Notification')}
                </div>
              )}
              {isAllPage && notificationPageNumber < notificationPageCount && (
                <div
                  className="notification-section__footer-action"
                  onClick={(event) => {
                    event.stopPropagation;
                    if (!isLoadingNotification) {
                      handleChangePage(event, notificationPageNumber + 1);
                    }
                  }}>
                  {isLoadingNotification && <MSpinner size={20} />}{' '}
                  {t('See More')}
                </div>
              )}
            </div>
          </>
        )}
        {formattedNotificationListData.length == 0 &&
          !isLoadingNotification &&
          emptyData()}
      </div>
    </>
  );
};

export default NotificationSection;
