import {CommonError} from '../../../../../../config/constant';
import {MasterDataDetailIndicatorFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailIndicatorEditAction = {
  FETCH: 'MASTER_DATA_DETAIL_INDICATOR_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_INDICATOR_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_INDICATOR_EDIT_FAILED',
  RESET: 'MASTER_DATA_DETAIL_INDICATOR_EDIT_RESET',
};

export interface MasterDataDetailIndicatorEditInitialState {
  masterDataDetailIndicatorEditLoading: boolean;
  masterDataDetailIndicatorEditParams?: MasterDataDetailIndicatorEditFetchPayload;
  masterDataDetailIndicatorEditError?: MasterDataDetailIndicatorEditFailedPayload;
  masterDataDetailIndicatorEditResponse?: MasterDataDetailIndicatorEditSuccessPayload;
}

export type MasterDataDetailIndicatorEditFetchPayload = MasterDataDetailIndicatorFormData;

export type MasterDataDetailIndicatorEditFailedPayload = CommonError;

export interface MasterDataDetailIndicatorEditSuccessPayload {}
