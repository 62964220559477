import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDirectorateDeleteInitialState} from './masterDataDirectorateDelete.reducer';
import {
  MasterDataDirectorateDeleteAction,
  MasterDataDirectorateDeleteFailedPayload,
  MasterDataDirectorateDeleteFetchPayload,
  MasterDataDirectorateDeleteSuccessPayload,
} from './masterDataDirectorateDelete.type';

export const masterDataDirectorateDeleteHandler = {
  [MasterDataDirectorateDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateDeleteLoading: true,
    masterDataDirectorateDeleteParams: action.payload,
    action: MasterDataDirectorateDeleteAction.FETCH,
  }),
  [MasterDataDirectorateDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateDeleteParams: undefined,
    masterDataDirectorateDeleteLoading: false,
    masterDataDirectorateDeleteResponse: action.payload,
    action: MasterDataDirectorateDeleteAction.SUCCESS,
  }),
  [MasterDataDirectorateDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateDeleteLoading: false,
    masterDataDirectorateDeleteError: action.payload,
    action: MasterDataDirectorateDeleteAction.FAILED,
  }),
  [MasterDataDirectorateDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDirectorateDeleteInitialState,
    action: MasterDataDirectorateDeleteAction.RESET,
  }),
};
