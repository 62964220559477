import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationValueDeleteAction,
  MasterDataObservationValueDeleteFailedPayload,
  MasterDataObservationValueDeleteFetchPayload,
  MasterDataObservationValueDeleteSuccessPayload,
} from './masterDataObservationValueDelete.type';

export const masterDataObservationValueDeleteAction = {
  fetch: createAction<MasterDataObservationValueDeleteFetchPayload>(
    MasterDataObservationValueDeleteAction.FETCH,
  ),
  success: createAction<MasterDataObservationValueDeleteSuccessPayload>(
    MasterDataObservationValueDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationValueDeleteFailedPayload>(
    MasterDataObservationValueDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataObservationValueDeleteAction.RESET),
};
