import {CommonError} from '../../../../../../config/constant';

export const MasterDataRouteCreateAction = {
  FETCH: 'MASTER_DATA_ROUTE_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_ROUTE_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_ROUTE_CREATE_FAILED',
  RESET: 'MASTER_DATA_ROUTE_CREATE_RESET',
};

export interface MasterDataRouteCreateInitialState {
  masterDataRouteCreateLoading: boolean;
  masterDataRouteCreateParams?: MasterDataRouteCreateFetchPayload;
  masterDataRouteCreateError?: MasterDataRouteCreateFailedPayload;
  masterDataRouteCreateResponse?: MasterDataRouteCreateSuccessPayload;
}

export interface MasterDataRouteCreateFetchPayload {
  corridor: string;
  assignmentType: string;
  name: string;
  busStop: string;
  busStopCode: string;
}

export type MasterDataRouteCreateFailedPayload = CommonError;

export interface MasterDataRouteCreateSuccessPayload {}
