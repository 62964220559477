import {createAction} from '@reduxjs/toolkit';
import {
  EvaluationReportDetailAction,
  EvaluationReportDetailFailedPayload,
  EvaluationReportDetailFetchPayload,
  EvaluationReportDetailSuccessPayload,
} from './evaluationReportDetail.type';

export const evaluationReportDetailAction = {
  fetch: createAction<EvaluationReportDetailFetchPayload>(
    EvaluationReportDetailAction.FETCH,
  ),
  success: createAction<EvaluationReportDetailSuccessPayload>(
    EvaluationReportDetailAction.SUCCESS,
  ),
  failed: createAction<EvaluationReportDetailFailedPayload>(
    EvaluationReportDetailAction.FAILED,
  ),
  reset: createAction(EvaluationReportDetailAction.RESET),
};
