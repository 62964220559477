import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDepartmentCreateAction,
  MasterDataDepartmentCreateFailedPayload,
  MasterDataDepartmentCreateFetchPayload,
  MasterDataDepartmentCreateSuccessPayload,
} from './masterDataDepartmentCreate.type';

export const masterDataDepartmentCreateAction = {
  fetch: createAction<MasterDataDepartmentCreateFetchPayload>(
    MasterDataDepartmentCreateAction.FETCH,
  ),
  success: createAction<MasterDataDepartmentCreateSuccessPayload>(
    MasterDataDepartmentCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataDepartmentCreateFailedPayload>(
    MasterDataDepartmentCreateAction.FAILED,
  ),
  reset: createAction(MasterDataDepartmentCreateAction.RESET),
};
