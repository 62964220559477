import {
  MasterDataAssignmentLetterListAction,
  MasterDataAssignmentLetterListSuccessPayload,
  MasterDataAssignmentLetterListFailedPayload,
  MasterDataAssignmentLetterListFetchPayload,
} from './masterDataAssignmentLetterList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataAssignmentLetterListInitialState} from './masterDataAssignmentLetterList.reducer';

export const masterDataAssignmentLetterListHandler = {
  [MasterDataAssignmentLetterListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataAssignmentLetterListLoading: true,
      masterDataAssignmentLetterListParams: action.payload,
      action: MasterDataAssignmentLetterListAction.FETCH,
    };
  },
  [MasterDataAssignmentLetterListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterListLoading: false,
    masterDataAssignmentLetterListResponse: action.payload,
    action: MasterDataAssignmentLetterListAction.SUCCESS,
  }),
  [MasterDataAssignmentLetterListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterListLoading: false,
    masterDataAssignmentLetterListData: [
      ...state.masterDataAssignmentLetterListData,
      ...action.payload.data.content,
    ],
    action: MasterDataAssignmentLetterListAction.UPDATE,
  }),
  [MasterDataAssignmentLetterListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterListLoading: false,
    masterDataAssignmentLetterListError: action.payload,
    action: MasterDataAssignmentLetterListAction.FAILED,
  }),
  [MasterDataAssignmentLetterListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataAssignmentLetterListInitialState,
    action: MasterDataAssignmentLetterListAction.RESET,
  }),
};
