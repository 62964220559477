/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  masterDataRouteCreateAction,
  masterDataRouteDeleteAction,
  masterDataRouteEditAction,
  masterDataRuteListAction,
} from '../../../../usecases/masterData.action';
import {
  MasterDataRouteCreateAction,
  MasterDataRouteDeleteAction,
  MasterDataRouteEditAction,
  MasterDataRuteListAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  AlertDialog,
  MButton,
  Modal,
  MSearchInput,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataRuteBRTManagementList} from './components';
// Data
import {
  MasterDataRute,
  MasterDataRuteOrderByType,
  MasterDataRouteAssignmentType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataRuteBRTSection.scss';
import MasterDataRouteFormContainer from '../MasterDataRouteFormContainer';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

const MasterDataRuteBRTSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddRoute, setIsAddRoute] = useState(false);
  const [isEditRoute, setIsEditRoute] = useState<MasterDataRute | undefined>(
    undefined,
  );
  const [isDeleteRoute, setIsDeleteRoute] = useState<
    MasterDataRute | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const isLoadingAddRoute = masterData.masterDataRouteCreateLoading;
  const isLoadingEditRoute = masterData.masterDataRouteEditLoading;
  const isLoadingDeleteRoute = masterData.masterDataRouteDeleteLoading;
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataRuteBRTListPage,
    setMasterDataRuteBRTListPage,
  ] = useState<number>(0);

  const [
    masterDataRuteBRTListCount,
    setMasterDataRuteBRTListCount,
  ] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataRuteBRTListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataRuteBRTListPage(0);
  };
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataRouteDeleteAction.fetch({id}));
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataRuteOrderByType>('corridor');

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataRuteOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH RUTE BRT LIST
  const fetchRuteBRTList = useCallback(
    (page?: number, size?: number, search?: string) => {
      dispatch(
        masterDataRuteListAction.fetch({
          page,
          search,
          size,
          assignmentType: 'BUSBRT',
        }),
      );
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataRuteListAction.reset());
    fetchRuteBRTList(masterDataRuteBRTListPage, rowsPerPage, searchValue);

    return () => {
      dispatch(masterDataRuteListAction.reset());
    };
  }, [
    dispatch,
    fetchRuteBRTList,
    masterDataRuteBRTListPage,
    rowsPerPage,
    searchValue,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataRuteBRTActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataRuteListAction.UPDATE, (): void => {
            setMasterDataRuteBRTListCount(
              masterData?.masterDataRuteListResponse?.data?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataRuteListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataRuteListAction.reset());
          })
          .addCase(MasterDataRouteCreateAction.SUCCESS, (): void => {
            setIsAddRoute(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataRouteCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataRouteCreateAction.reset());
          })
          .addCase(MasterDataRouteEditAction.SUCCESS, (): void => {
            setIsEditRoute(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataRouteEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataRouteEditAction.reset());
          })
          .addCase(MasterDataRouteDeleteAction.SUCCESS, (): void => {
            setIsDeleteRoute(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataRouteDeleteAction.FAILED, (): void => {
            const errorMessage = masterData.masterDataRouteDeleteError?.info
              ?.message
              ? t(masterData.masterDataRouteDeleteError?.info?.message)
              : t('Data deleted failed!');
            dispatch(
              wrapperNotifyAction.fetch({
                text: errorMessage,
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataRouteDeleteAction.reset());
          });
      });
    },
    [
      dispatch,
      refresh,
      masterData?.masterDataRuteListResponse?.data?.totalElements,
      masterData.masterDataRouteDeleteError?.info?.message,
    ],
  );

  useEffect(() => {
    masterDataRuteBRTActionHandler(masterData.action);
  }, [masterData.action, masterDataRuteBRTActionHandler]);
  //#endregion

  return (
    <>
      <div className="master-data-rute-brt-section">
        <div className="master-data-rute-brt-section__header">
          <div className="master-data-rute-brt-section__header--action">
            <MSearchInput
              inputClassName="master-data-rute-brt-section__header--search"
              inputValue={searchValue}
              placeholder={t('Search Master Data Route BRT')}
              setInputValue={(search: string) => {
                setMasterDataRuteBRTListPage(0);
                setSearchValue(search);
              }}
            />
            <div className="master-data-rute-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setIsAddRoute(true)}
              />
            </div>
          </div>
        </div>

        <MasterDataRuteBRTManagementList
          ruteBRTList={masterData.masterDataRuteListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          onEditRoute={setIsEditRoute}
          onDeleteRoute={setIsDeleteRoute}
        />
        <MTablePagination
          className="master-data-rute-brt-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataRuteBRTListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataRuteBRTListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setIsAddRoute(false)}
        title={t('Add Route')}
        isOpen={isAddRoute}>
        <MasterDataRouteFormContainer
          assignmentType={MasterDataRouteAssignmentType.BRT}
          isLoading={isLoadingAddRoute}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditRoute && (
        <Modal
          onClose={() => setIsEditRoute(undefined)}
          isOpen={!!isEditRoute}
          title={t('Edit Route')}>
          <MasterDataRouteFormContainer
            assignmentType={MasterDataRouteAssignmentType.BRT}
            data={isEditRoute}
            isLoading={isLoadingEditRoute}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteRoute && (
        <AlertDialog
          onClose={() => setIsDeleteRoute(undefined)}
          onCancel={() => setIsDeleteRoute(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteRoute.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteRoute}
          isLoading={isLoadingDeleteRoute}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setIsAddRoute(false)
              : setIsEditRoute(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataRuteBRTSection;
