import {CommonError} from '../../../../config/constant';
import {UserData} from '../../entity/user.string.entity';

export const UserOTPPasswordAction = {
  FETCH: 'USER_OTP_PASSWORD_FETCH',
  SUCCESS: 'USER_OTP_PASSWORD_SUCCESS',
  FAILED: 'USER_OTP_PASSWORD_FAILED',
  RESET: 'USER_OTP_PASSWORD_RESET',
};

export interface UserOTPPasswordInitialState {
  userOTPPasswordLoading: boolean;
  userOTPPasswordParams?: UserOTPPasswordFetchPayload;
  userOTPPasswordError?: UserOTPPasswordFailedPayload;
  userOTPPasswordResponse?: UserOTPPasswordSuccessPayload;
}

export type UserOTPPasswordFetchPayload = {
  email: string;
  otp: string;
};

export type UserOTPPasswordFailedPayload = CommonError;

export interface UserOTPPasswordSuccessPayload {
  data: UserData;
}
