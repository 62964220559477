//#region IMPORT
// Libraries
import React from 'react';
import Grid from '@mui/material/Grid';
// Components
import FollowUpExpiredListItem from '../FollowUpExpiredListItem';
// Data
import {
  FollowUp,
  FollowUpExpiredFormData,
  FollowUpLoadingItem,
} from '../../../../../entity/followUp.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp[];
  isLoading?: FollowUpLoadingItem;
  isCanSendNote?: boolean;
  onViewItem(item: FollowUp): void;
  handleSubmitExpired(note: FollowUpExpiredFormData): void;
}
//#endregion

const FollowUpExpiredList: React.FC<Props> = ({
  data,
  isLoading,
  isCanSendNote,
  onViewItem,
  handleSubmitExpired,
}: Props) => {
  return (
    <div className="follow-up-expired-list__container">
      <Grid container spacing={3}>
        {data?.map((item: FollowUp, index: number) => {
          return (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key={`follow-up-expired-list-item-data-${item.id}-${index}`}>
              <FollowUpExpiredListItem
                data={item}
                isLoading={isLoading?.isLoading && isLoading.id === item.id}
                isCanSendNote={isCanSendNote}
                onViewItem={() => onViewItem(item)}
                handleSubmitExpired={handleSubmitExpired}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default FollowUpExpiredList;
