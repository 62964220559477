//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDivisionCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDivisionFormCreateForm} from './components';
import {DivisionFormCreateInitialValues} from './components/MasterDataDivisionFormCreateForm/MasterDataDivisionFormCreateForm.component';
// Assets
import './MasterDataDivisionFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDivisionFormCreateContainer: React.FC<Props> = ({
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: DivisionFormCreateInitialValues = {
    directorateId: '',
    divisions: [
      {
        name: '',
      },
    ],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDivision = (formValue: DivisionFormCreateInitialValues) => {
    const divisionTemp = formValue.divisions.map((item) => {
      return {name: item.name};
    });
    const formattedValue = {
      ...formValue,
      data: divisionTemp || [],
    };
    dispatch(masterDataDivisionCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-division-form-create-container">
      <MasterDataDivisionFormCreateForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitDivision={handleSubmitDivision}
      />
    </div>
  );
};

export default MasterDataDivisionFormCreateContainer;
