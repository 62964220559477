import userLogin from './userLogin/userLogin.saga';
import userForgetPassword from './userForgetPassword/userForgetPassword.saga';
import userOTPPassword from './userOTPPassword/userOTPPassword.saga';
import userChangePassword from './userChangePassword/userChangePassword.saga';
import userAccessCreate from './userAccessCreate/userAccessCreate.saga';
import userAccessDelete from './userAccessDelete/userAccessDelete.saga';
import userAccessEdit from './userAccessEdit/userAccessEdit.saga';
import userAccessList from './userAccessList/userAccessList.saga';
import userAccessSetActive from './userAccessSetActive/userAccessSetActive.saga';
import userRoleAccessEdit from './userRoleAccessEdit/userRoleAccessEdit.saga';
import userRoleAccessDelete from './userRoleAccessDelete/userRoleAccessDelete.saga';
import userRoleAccessList from './userRoleAccessList/userRoleAccessList.saga';
import userAccountEdit from './userAccountEdit/userAccountEdit.saga';
import userAccountDetail from './userAccountDetail/userAccountDetail.saga';
import userRoleAccessCreate from './userRoleAccessCreate/userRoleAccessCreate.saga';

export default [
  ...userLogin,
  ...userForgetPassword,
  ...userOTPPassword,
  ...userChangePassword,
  ...userAccessCreate,
  ...userAccessDelete,
  ...userAccessEdit,
  ...userAccessList,
  ...userAccessSetActive,
  ...userRoleAccessList,
  ...userRoleAccessEdit,
  ...userRoleAccessDelete,
  ...userAccountEdit,
  ...userAccountDetail,
  ...userRoleAccessCreate,
];
