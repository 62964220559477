//#region IMPORT
// Libraries
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
// Utils
import {evaluationReportExportAction} from '../../../usecases/evaluationReport.action';
// Components
// Data
import {EvaluationReportExportFormData} from '../../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportExportFormContainer.scss';
import EvaluationReportExportForm from './components/EvaluationReportExportForm';
import {EvaluationReportExportFetchPayload} from '../../../usecases/evaluationReport.type';
import {AssignmentType} from '../../../../wrapper/entity';
//#endregion

//#region INTERFACE
interface Props {
  isSPM?: boolean;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const EvaluationReportDownloadAllFormContainer: React.FC<Props> = ({
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: EvaluationReportExportFormData = {
    startDate: undefined,
    endDate: undefined,
  };
  //#endregion

  //#region EXPORT ALL
  const handleExportAll = useCallback(
    (EvaluationReport: EvaluationReportExportFormData) => {
      try {
        const payload: EvaluationReportExportFetchPayload = {
          startDate: moment(EvaluationReport?.startDate, 'DD/MM/YYYY').format(
            'YYYY-MM-DD',
          ),
          endDate: moment(EvaluationReport?.endDate, 'DD/MM/YYYY').format(
            'YYYY-MM-DD',
          ),
          ...(EvaluationReport.assignmentType && {
            assignmentType: EvaluationReport.assignmentType,
            ...(EvaluationReport.routeType &&
              [
                AssignmentType.BUSBRT,
                AssignmentType.BUSNONBRT,
                AssignmentType.BUSKECIL,
              ].includes(EvaluationReport.assignmentType) && {
                routeType: EvaluationReport.routeType,
              }),
          }),
        };
        dispatch(evaluationReportExportAction.fetch(payload));
      } catch (error) {
        console.log({error});
      }
    },
    [dispatch],
  );
  //#endregion

  //#region RENDER
  return (
    <div className="work-report-download-all-form-container">
      <EvaluationReportExportForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleDownload={(EvaluationReport) => handleExportAll(EvaluationReport)}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default EvaluationReportDownloadAllFormContainer;
