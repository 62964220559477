import * as React from 'react';

const IconComment = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.height || 20}
    height={props.height || 20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.667 5.52a3.02 3.02 0 013.02-3.02h10.626a3.02 3.02 0 013.02 3.02v6.46a3.02 3.02 0 01-3.02 3.02h-4.314l-4.521 3.19A.937.937 0 015 17.426V15h-.312a3.02 3.02 0 01-3.021-3.02V5.52z"
      fill={props.fill || '#fff'}
    />
  </svg>
);

export default IconComment;
