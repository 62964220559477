//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataObservationValueEditAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataObservationValueFormEdit} from './components';
// Data
import {MasterDataObservationValueFormData} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataObservationValueFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  data: MasterDataObservationValueFormData;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataObservationValueFormEditContainer: React.FC<Props> = ({
  assignmentType,
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataObservationValueFormData = {
    id: data.id || '',
    name: data.name || '',
    assignmentType: data?.assignmentType || assignmentType,
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitObservationValue = (
    formValue: MasterDataObservationValueFormData,
  ) => {
    const observationValueTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: observationValueTemp || '',
    };
    dispatch(masterDataObservationValueEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-observation-value-form-edit-container">
      <MasterDataObservationValueFormEdit
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitObservationValue={handleSubmitObservationValue}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataObservationValueFormEditContainer;
