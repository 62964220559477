import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataFormControllerListAction} from './masterDataFormControllerList.action';
import {
  MasterDataFormControllerListAction,
  MasterDataFormControllerListFetchPayload,
  MasterDataFormControllerListResponse,
  MasterDataFormControllerListSuccessPayload,
} from './masterDataFormControllerList.type';

function* workerFetch(
  action: GenericAction<MasterDataFormControllerListFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataFormControllerListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/form/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataFormControllerListAction.success(
          res.data as MasterDataFormControllerListResponse,
        ),
      );
      yield put(
        masterDataFormControllerListAction.update(
          res.data as MasterDataFormControllerListResponse,
        ),
      );
    } else {
      yield put(
        masterDataFormControllerListAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(masterDataFormControllerListAction.failed(e as CommonError));
  }
}

const masterDataFormControllerListWatcher = [
  takeLatest(MasterDataFormControllerListAction.FETCH, workerFetch),
];

export default masterDataFormControllerListWatcher;
