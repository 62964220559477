import {CommonError} from '../../../../../../config/constant';

export const MasterDataDepartmentEditAction = {
  FETCH: 'MASTER_DATA_DEPARTMENT_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_DEPARTMENT_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_DEPARTMENT_EDIT_FAILED',
  RESET: 'MASTER_DATA_DEPARTMENT_EDIT_RESET',
};

export interface MasterDataDepartmentEditInitialState {
  masterDataDepartmentEditLoading: boolean;
  masterDataDepartmentEditParams?: MasterDataDepartmentEditFetchPayload;
  masterDataDepartmentEditError?: MasterDataDepartmentEditFailedPayload;
  masterDataDepartmentEditResponse?: MasterDataDepartmentEditSuccessPayload;
}

export interface MasterDataDepartmentEditFetchPayload {
  id: string;
  directorateId: string;
  divisionId: string;
  name: string;
}

export type MasterDataDepartmentEditFailedPayload = CommonError;

export interface MasterDataDepartmentEditSuccessPayload {}
