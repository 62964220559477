/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import {BarDatum} from '@nivo/bar';
import moment, {Moment} from 'moment';
// Utils
import t from '../../../../../lang';
import {colors} from '../../../../../config/_theme';
import {AppState} from '../../../../../config/Interface';
import {DashboardState} from '../../../usecases/dashboard.reducer';
import {
  dashboardDepartmentBestSectionAction,
  dashboardDepartmentChartSectionAction,
} from '../../../usecases/dashboard.action';
import {
  DashboardDepartmentBestSectionAction,
  DashboardDepartmentChartSectionAction,
} from '../../../usecases/dashboard.type';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {Modal, MMonthpicker, MYearpicker} from '../../../../../components';
import {
  DashboardBarChart,
  DashboardFinance,
  DashboardLeaderBoard,
  DashboardPieChart,
  DashboardTooltip,
} from '../../../components';
// Data
import {
  DashboardTabDepartment,
  PieRawDatum,
} from '../../../entity/dashboard.string.entity';
import {dataTable} from '../../../components/DashboardLeaderBoard/DashboardLeaderBoard.component';
// Assets
import './DashboardDepartmentSection.scss';
import {IconCash} from '../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  data?: DashboardTabDepartment;
}
//#endregion

const DashboardDepartmentSection: React.FC<Props> = ({data}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();

  const dashboard: DashboardState = useSelector(
    (state: AppState) => state.dashboard,
    shallowEqual,
  );
  const user = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = user?.data?.role?.permissions;

  //#region GENERAL YEAR FILTER
  const [selectedYearFilter, setSelectedYearFilter] = useState<
    Moment | string | null
  >(moment().year(new Date().getFullYear()));
  //#endregion

  //#region GENERAL MONTH FILTER
  const [selectedMonthFilter, setSelectedMonthFilter] = useState<
    Moment | string | null
  >(moment().month(new Date().getMonth()));
  //#endregion

  //#endregion

  //#region FETCH OVERVIEW CHART
  const fetchOverviewChart = useCallback(
    (month: number, year: number) => {
      dispatch(
        dashboardDepartmentChartSectionAction.fetch({
          departmentId: data?.id || '',
          month,
          year,
        }),
      );
    },
    [data?.id, dispatch],
  );
  //#endregion

  //#region FETCH OVERVIEW BEST
  const fetchOverviewBest = useCallback(
    (month: number, year: number) => {
      dispatch(dashboardDepartmentBestSectionAction.fetch({month, year}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(dashboardDepartmentChartSectionAction.reset());
    dispatch(dashboardDepartmentBestSectionAction.reset());
    fetchOverviewChart(
      Number((selectedMonthFilter as Moment).format('MM')),
      Number((selectedYearFilter as Moment).format('YYYY')),
    );
    fetchOverviewBest(
      Number((selectedMonthFilter as Moment).format('MM')),
      Number((selectedYearFilter as Moment).format('YYYY')),
    );
    return () => {
      dispatch(dashboardDepartmentChartSectionAction.reset());
      dispatch(dashboardDepartmentBestSectionAction.reset());
    };
  }, [
    dispatch,
    fetchOverviewBest,
    fetchOverviewChart,
    selectedMonthFilter,
    selectedYearFilter,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region DEPARTMENT
  const [pieDepartment, setPieDepartment] = useState<PieRawDatum[]>([
    {
      id: 'achieved',
      label: t('Achieved'),
      value: 0,
      color: colors.primary.blue,
    },
    {
      id: 'not Achieved',
      label: t('Not Achieved'),
      value: 0,
      color: colors.text.lightGrey2,
    },
  ]);
  const [
    pieDepartmentShowDetail,
    setPieDepartmentShowDetail,
  ] = useState<boolean>(false);
  //#endregion

  //#region FINDINGS
  const [pieFindings, setPieFindings] = useState<PieRawDatum[]>([
    {
      id: 'finished',
      label: t('Finished'),
      value: 0,
      color: colors.primary.blue,
    },
    {
      id: 'unfinished',
      label: t('Unfinished'),
      value: 0,
      color: colors.primary.blue4,
    },
  ]);
  const [pieFindingsShowDetail, setPieFindingsShowDetail] = useState<boolean>(
    false,
  );
  //#endregion

  //#region QUALITY
  const [barQuality, setBarQuality] = useState<BarDatum[]>([
    {
      id: '1',
      label: '-',
      [t('Achieved')]: 0,
      [t('Target')]: 0,
      achievedColor: colors.primary.blue,
      targetColor: colors.primary.blue5,
    },
  ]);
  const [barQualityShowDetail, setBarQualityShowDetail] = useState<boolean>(
    false,
  );
  //#endregion

  //#region PROFIT AND FINE
  const [profit, setProfit] = useState<number>(0);
  const [fine, setFine] = useState<number>(0);
  //#endregion

  //#region BEST DEPARTMENT
  const [bestDepartment, setBestDepartment] = useState<dataTable[]>([]);

  const [
    bestDepartmentShowDetail,
    setBestDepartmentShowDetail,
  ] = useState<boolean>(false);
  //#endregion

  //#region ACTION HANDLER
  const dashboardManagementDepartmentActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(DashboardDepartmentChartSectionAction.SUCCESS, (): void => {
            if (dashboard?.dashboardDepartmentChartSectionResponse?.data) {
              //#region PIE CHART DEPARTMENT
              const pieChartDepartment = dashboard
                ?.dashboardDepartmentChartSectionResponse?.data
                ?.departmentAchievementScore
                ? Number(
                    dashboard?.dashboardDepartmentChartSectionResponse?.data
                      ?.departmentAchievementScore,
                  )
                : 0;

              const formattedPieDepartment = [
                {
                  id: 'achieved',
                  label: t('Achieved'),
                  value: Number(pieChartDepartment.toFixed(2)),
                  color: colors.primary.blue,
                },
                {
                  id: 'not Achieved',
                  label: t('Not Achieved'),
                  value: Number((100 - pieChartDepartment).toFixed(2)),
                  color: colors.text.lightGrey2,
                },
              ];

              setPieDepartment(formattedPieDepartment);
              //#endregion
              //#region PIE FINDINGS DEPARTMENT
              const pieFindingsDataTemp =
                dashboard.dashboardDepartmentChartSectionResponse.data.finding;

              const formattedPieFindings = [
                {
                  id: 'finished',
                  label: t('Finished'),
                  value: pieFindingsDataTemp.finishedFinding,
                  color: colors.primary.blue,
                },
                {
                  id: 'unfinished',
                  label: t('Belum Selesai'),
                  value: pieFindingsDataTemp.unFinishedFinding,
                  color: colors.text.lightGrey2,
                },
              ];

              setPieFindings(formattedPieFindings);
              //#endregion
              //#region BAR QUALITY
              const achievementAndTarget =
                dashboard?.dashboardDepartmentChartSectionResponse?.data
                  ?.achievementAndTarget;

              const formattedAchievementAndTarget = [
                {
                  id: '1',
                  label: achievementAndTarget.departmentName,
                  [t('Achieved')]: achievementAndTarget?.achievementPercent
                    ? Number(achievementAndTarget?.achievementPercent).toFixed(
                        2,
                      )
                    : 0,
                  [t('Target')]: achievementAndTarget?.targetPercent
                    ? Number(achievementAndTarget.targetPercent).toFixed(2)
                    : 0,
                  achievedColor: colors.primary.blue,
                  targetColor: colors.primary.blue5,
                },
              ];

              setBarQuality(formattedAchievementAndTarget);
              //#endregion
              //#region PROFIT AND FINE
              const profit =
                dashboard?.dashboardDepartmentChartSectionResponse?.data
                  ?.forecast?.profit || 0;
              const fine =
                dashboard?.dashboardDepartmentChartSectionResponse?.data
                  ?.forecast?.fine || 0;
              setProfit(Number(profit));
              setFine(Number(fine));
              //#endregion
            }
          })
          .addCase(DashboardDepartmentChartSectionAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(dashboardDepartmentChartSectionAction.reset());
          })
          .addCase(DashboardDepartmentBestSectionAction.SUCCESS, (): void => {
            if (dashboard?.dashboardDepartmentBestSectionResponse?.data) {
              //#region BEST DEPARTMENT
              const bestDepartment =
                dashboard?.dashboardDepartmentBestSectionResponse?.data
                  .bestDepartment;
              const formattedBestDepartment = bestDepartment?.map((item) => ({
                name: item.name ?? '',
                value: `${item.percent ? Number(item.percent).toFixed(2) : 0}%`,
              }));
              setBestDepartment(formattedBestDepartment);
              //#endregion
            }
          })
          .addCase(DashboardDepartmentBestSectionAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(dashboardDepartmentBestSectionAction.reset());
          });
      });
    },
    [
      dashboard?.dashboardDepartmentBestSectionResponse?.data,
      dashboard?.dashboardDepartmentChartSectionResponse?.data,
      dispatch,
    ],
  );
  useEffect(() => {
    dashboardManagementDepartmentActionHandler(dashboard.action);
  }, [dashboard.action, dashboardManagementDepartmentActionHandler]);
  //#endregion

  return (
    <>
      <div className="dashboard-department-section">
        <div className="dashboard-department-section__filter">
          <MYearpicker
            inputValue={selectedYearFilter}
            maxDate={moment().endOf('year')}
            handleChange={(year) => setSelectedYearFilter(year)}
          />
          <MMonthpicker
            inputValue={selectedMonthFilter}
            handleChange={(month) => setSelectedMonthFilter(month)}
          />
        </div>
        <Grid container spacing={3}>
          {permissions?.hasDashboardPICAchivementChart && (
            <Grid item xs={6} md={4} key="spm-bus-operation-pie-chart">
              <div className="dashboard-department-section__column">
                <div className="dashboard-department-section__card">
                  <DashboardPieChart
                    data={pieDepartment}
                    title={t(data?.name || '')}
                    tooltip={({datum}) => {
                      const tooltipData = [
                        {name: datum.label as string, value: `${datum.value}%`},
                      ];
                      return <DashboardTooltip data={tooltipData} />;
                    }}
                    extraLayer={
                      <div className="extra-container">
                        <div className="extra-container__point">
                          {`${pieDepartment[0].value}%`}
                        </div>
                        <div className="extra-container__desc">
                          {t('Achieved')}
                        </div>
                      </div>
                    }
                    selectedYearFilter={selectedYearFilter}
                    selectedMonthFilter={selectedMonthFilter}
                    handleShowDetail={() => setPieDepartmentShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
          {permissions?.hasDashboardPICFindingChart && (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key="findings-pie-chart">
              <div className="dashboard-department-section__column">
                <div className="dashboard-department-section__card">
                  <DashboardPieChart
                    data={pieFindings}
                    title={t('Temuan Bulan Ini')}
                    innerRadius={0.6}
                    enableArcLabels
                    enableArcLinkLabels
                    arcLinkLabel="label"
                    arcLinkLabelsColor={{from: 'color'}}
                    legends={[
                      {
                        anchor: 'bottom-left',
                        direction: 'row',
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 18,
                        translateY: 56,
                        translateX: -20,
                      },
                    ]}
                    tooltip={({datum}) => {
                      const tooltipData = [
                        {name: datum.label as string, value: datum.value},
                      ];
                      return <DashboardTooltip data={tooltipData} />;
                    }}
                    extraLayer={
                      <div className="extra-container">
                        <div className="extra-container__point">
                          {pieFindings.reduce(
                            (prev: number, {value}) =>
                              prev + (Number(value) || 0),
                            0,
                          )}
                        </div>
                        <div className="extra-container__desc">
                          {t('Findings')}
                        </div>
                      </div>
                    }
                    selectedYearFilter={selectedYearFilter}
                    selectedMonthFilter={selectedMonthFilter}
                    handleShowDetail={() => setPieFindingsShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
          {permissions?.hasDashboardPICDifferenceChart && (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key="quality-bar-chart">
              <div className="dashboard-department-section__column">
                <div className="dashboard-department-section__card">
                  <DashboardBarChart
                    data={barQuality}
                    title={t('Bobot')}
                    keys={[t('Achieved'), t('Target')]}
                    colors={[colors.primary.blue, colors.primary.blue5]}
                    groupMode="grouped"
                    indexBy="label"
                    minValue={0}
                    maxValue={100}
                    tooltip={({id, value}) => {
                      const tooltipData = [
                        {name: id as string, value: `${value}%`},
                      ];
                      return <DashboardTooltip data={tooltipData} />;
                    }}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'bottom-left',
                        direction: 'row',
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 18,
                        translateY: 56,
                        translateX: -20,
                      },
                    ]}
                    selectedYearFilter={selectedYearFilter}
                    selectedMonthFilter={selectedMonthFilter}
                    handleShowDetail={() => setBarQualityShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
          {permissions?.hasDashboardPICBestDepartmentChart && (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key="best-department-leader-board">
              <div className="dashboard-department-section__column">
                <div className="dashboard-department-section__card">
                  <DashboardLeaderBoard
                    title={t('Best Department')}
                    data={bestDepartment}
                    onViewAll={() => setBestDepartmentShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
          {permissions?.hasDashboardPICProfitAndFineChart && (
            <Grid item xs={6} sm={6} md={4} lg={4} xl={4} key="finance">
              <div className="dashboard-department-section__column">
                <div className="dashboard-department-section__card">
                  <div className="dashboard-department-section__card--title">
                    {t('Laba dan Denda Forecast')}
                  </div>
                  <DashboardFinance
                    hasBigTitle={false}
                    icon={<IconCash />}
                    title={t('Profit')}
                    value={profit}
                  />
                  <DashboardFinance
                    hasBigTitle={false}
                    icon={<IconCash fill={colors.secondary.red} />}
                    title={t('Charge')}
                    value={fine}
                    isFine
                  />
                </div>
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      {pieDepartmentShowDetail && (
        <Modal
          onClose={() => setPieDepartmentShowDetail(false)}
          isOpen={!!pieDepartmentShowDetail}
          maxWidth="sm">
          <DashboardPieChart
            data={pieDepartment}
            title={t(data?.name || '')}
            tooltip={({datum}) => {
              const tooltipData = [
                {name: datum.label as string, value: `${datum.value}%`},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            extraLayer={
              <div className="extra-container">
                <div className="extra-container__point">
                  {`${pieDepartment[0].value}%`}
                </div>
                <div className="extra-container__desc">{t('Achieved')}</div>
              </div>
            }
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {pieFindingsShowDetail && (
        <Modal
          onClose={() => setPieFindingsShowDetail(false)}
          isOpen={!!pieFindingsShowDetail}
          maxWidth="sm">
          <DashboardPieChart
            data={pieFindings}
            title={t('Temuan Bulan Ini')}
            innerRadius={0.6}
            enableArcLabels
            enableArcLinkLabels
            arcLinkLabel="label"
            arcLinkLabelsColor={{from: 'color'}}
            legends={[
              {
                anchor: 'bottom-left',
                direction: 'row',
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 18,
                translateY: 56,
                translateX: -20,
              },
            ]}
            tooltip={({datum}) => {
              const tooltipData = [
                {name: datum.label as string, value: datum.value},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            extraLayer={
              <div className="extra-container">
                <div className="extra-container__point">
                  {pieFindings.reduce(
                    (prev: number, {value}) => prev + (Number(value) || 0),
                    0,
                  )}
                </div>
                <div className="extra-container__desc">{t('Findings')}</div>
              </div>
            }
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {barQualityShowDetail && (
        <Modal
          onClose={() => setBarQualityShowDetail(false)}
          isOpen={!!barQualityShowDetail}
          maxWidth="sm">
          <DashboardBarChart
            data={barQuality}
            title={t('Bobot')}
            keys={[t('Achieved'), t('Target')]}
            colors={[colors.primary.blue, colors.primary.blue5]}
            groupMode="grouped"
            indexBy="label"
            minValue={0}
            maxValue={100}
            tooltip={({id, value}) => {
              const tooltipData = [{name: id as string, value: `${value}%`}];
              return <DashboardTooltip data={tooltipData} />;
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-left',
                direction: 'row',
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 18,
                translateY: 56,
                translateX: -20,
              },
            ]}
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {bestDepartmentShowDetail && (
        <Modal
          onClose={() => setBestDepartmentShowDetail(false)}
          isOpen={!!bestDepartmentShowDetail}
          maxWidth="sm">
          <DashboardLeaderBoard
            title={t('Best Department')}
            data={bestDepartment}
          />
        </Modal>
      )}
    </>
  );
};

export default DashboardDepartmentSection;
