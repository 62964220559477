import {CommonError} from '../../../../../../config/constant';

export const MasterDataDivisionEditAction = {
  FETCH: 'MASTER_DATA_DIVISION_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_DIVISION_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_DIVISION_EDIT_FAILED',
  RESET: 'MASTER_DATA_DIVISION_EDIT_RESET',
};

export interface MasterDataDivisionEditInitialState {
  masterDataDivisionEditLoading: boolean;
  masterDataDivisionEditParams?: MasterDataDivisionEditFetchPayload;
  masterDataDivisionEditError?: MasterDataDivisionEditFailedPayload;
  masterDataDivisionEditResponse?: MasterDataDivisionEditSuccessPayload;
}

export interface MasterDataDivisionEditFetchPayload {
  id: string;
  directorateId: string;
  name: string;
}

export type MasterDataDivisionEditFailedPayload = CommonError;

export interface MasterDataDivisionEditSuccessPayload {}
