import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataBasicServiceType} from '../../../../entity/masterData.string.entity';

export const MasterDataBasicServiceTypeListAction = {
  FETCH: 'MASTER_DATA_BASIC_SERVICE_TYPE_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_BASIC_SERVICE_TYPE_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_BASIC_SERVICE_TYPE_LIST_FAILED',
  RESET: 'MASTER_DATA_BASIC_SERVICE_TYPE_LIST_RESET',
  UPDATE: 'MASTER_DATA_BASIC_SERVICE_TYPE_LIST_UPDATE',
};

export interface MasterDataBasicServiceTypeListInitialState {
  masterDataBasicServiceTypeListLoading: boolean;
  masterDataBasicServiceTypeListData: MasterDataBasicServiceType[];
  masterDataBasicServiceTypeListError?: MasterDataBasicServiceTypeListFailedPayload;
  masterDataBasicServiceTypeListParams?: MasterDataBasicServiceTypeListFetchPayload;
  masterDataBasicServiceTypeListResponse?: MasterDataBasicServiceTypeListSuccessPayload;
}

export type MasterDataBasicServiceTypeListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataBasicServiceType[]>;
};

export type MasterDataBasicServiceTypeListFailedPayload = CommonError;

export type MasterDataBasicServiceTypeListSuccessPayload = MasterDataBasicServiceTypeListResponse;

export type MasterDataBasicServiceTypeListUpdatePayload = MasterDataBasicServiceTypeListResponse;

export interface MasterDataBasicServiceTypeListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
