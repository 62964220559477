//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {Moment} from 'moment';
import {Menu, MenuItem} from '@mui/material';
// Utils
import t from '../../../../lang';
import {colors} from '../../../../config/_theme';
// Components
import {MIconButton, MMonthpicker, MYearpicker} from '../../../../components';
// Assets
import './DashboardLeaderBoard.component.style.scss';
import {mdiDotsHorizontal} from '@mdi/js';
import {IconTrophy} from '../../../../assets/icons';
//#endregion

//#region INTERFACE
export interface dataTable {
  name: string;
  value: string | number;
}
interface Props {
  data: dataTable[];
  title: string;
  icon?: React.ReactElement;
  iconBackgroundColor?: string;
  selectedYearFilter?: Moment | string | null;
  yearFilterHandleChange?(year: Moment | string | null): void;
  selectedMonthFilter?: Moment | string | null;
  monthFilterHandleChange?(month: Moment | string | null): void;
  handleExport?(): void;
  onViewAll?(): void;
}
//#endregion

const DashboardLeaderBoard: React.FC<Props> = ({
  data,
  title,
  icon = <IconTrophy />,
  iconBackgroundColor = colors.secondary.yellow1,
  selectedYearFilter,
  yearFilterHandleChange,
  selectedMonthFilter,
  monthFilterHandleChange,
  onViewAll,
  handleExport,
}: Props) => {
  //#region GENERAL
  const [menuExportChart, setMenuExportChart] = useState<null | HTMLElement>(
    null,
  );
  const openMenuExportChart = Boolean(menuExportChart);
  //#endregion

  return (
    <div className="dashboard-leaderboard">
      <div className="dashboard-leaderboard__header">
        <div
          className="dashboard-leaderboard__header--icon"
          style={{backgroundColor: iconBackgroundColor}}>
          {icon}
        </div>
        <div className="dashboard-leaderboard__header--title">{title}</div>
        <div className="dashboard-leaderboard__header--action">
          {yearFilterHandleChange && (
            <MYearpicker
              inputValue={selectedYearFilter}
              handleChange={yearFilterHandleChange}
              inputWidth="33%"
            />
          )}
          {monthFilterHandleChange && (
            <MMonthpicker
              inputValue={selectedMonthFilter}
              handleChange={monthFilterHandleChange}
              inputWidth="33%"
            />
          )}
          {handleExport && (
            <div className="dashboard-leaderboard__header--action-export">
              <MIconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  setMenuExportChart(event.currentTarget)
                }
                icon={mdiDotsHorizontal}
              />
              <Menu
                id="basic-menu"
                anchorEl={menuExportChart}
                open={openMenuExportChart}
                onClose={() => setMenuExportChart(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExport();
                    setMenuExportChart(null);
                  }}>
                  {t('Export')}
                </MenuItem>
              </Menu>
            </div>
          )}
          {onViewAll && (
            <div
              className="dashboard-leaderboard__header--action-viewAll"
              onClick={(e) => {
                e.stopPropagation();
                onViewAll();
              }}>
              {t('See All')}
            </div>
          )}
        </div>
      </div>
      <div className="dashboard-leaderboard__content">
        <table>
          <tbody>
            {data.map((tableItem: dataTable, idx: number) => (
              <tr key={`dashboard-leaderboard-item${idx}`}>
                <td>{idx + 1}</td>
                <td width="100%">{tableItem.name}</td>
                <td className="dashboard-leaderboard__content-value">
                  {tableItem.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default DashboardLeaderBoard;
