import {CommonError} from '../../../../../../config/constant';
import {DashboardDepartmentBestSection} from '../../../../entity/dashboard.string.entity';

export const DashboardDepartmentBestSectionAction = {
  FETCH: 'DASHBOARD_DEPARTMENT_BEST_SECTION_FETCH',
  SUCCESS: 'DASHBOARD_DEPARTMENT_BEST_SECTION_SUCCESS',
  FAILED: 'DASHBOARD_DEPARTMENT_BEST_SECTION_FAILED',
  RESET: 'DASHBOARD_DEPARTMENT_BEST_SECTION_RESET',
};

export interface DashboardDepartmentBestSectionInitialState {
  dashboardDepartmentBestSectionLoading: boolean;
  dashboardDepartmentBestSectionError?: DashboardDepartmentBestSectionFailedPayload;
  dashboardDepartmentBestSectionResponse?: DashboardDepartmentBestSectionSuccessPayload;
  dashboardDepartmentBestSectionParams?: DashboardDepartmentBestSectionFetchPayload;
}

export interface DashboardDepartmentBestSectionFetchPayload {
  year: number;
  month: number;
}

export interface DashboardDepartmentBestSectionResponse {
  status: string;
  info?: number;
  data: DashboardDepartmentBestSection;
}

export type DashboardDepartmentBestSectionFailedPayload = CommonError;

export type DashboardDepartmentBestSectionSuccessPayload = DashboardDepartmentBestSectionResponse;

export type DashboardDepartmentBestSectionUpdatePayload = DashboardDepartmentBestSectionResponse;
