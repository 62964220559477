import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBusListAction,
  MasterDataBusListFailedPayload,
  MasterDataBusListFetchPayload,
  MasterDataBusListSuccessPayload,
  MasterDataBusListUpdatePayload,
} from './masterDataBusList.type';

export const masterDataBusListAction = {
  fetch: createAction<MasterDataBusListFetchPayload>(
    MasterDataBusListAction.FETCH,
  ),
  success: createAction<MasterDataBusListSuccessPayload>(
    MasterDataBusListAction.SUCCESS,
  ),
  failed: createAction<MasterDataBusListFailedPayload>(
    MasterDataBusListAction.FAILED,
  ),
  update: createAction<MasterDataBusListUpdatePayload>(
    MasterDataBusListAction.UPDATE,
  ),
  reset: createAction(MasterDataBusListAction.RESET),
};
