import {CommonError} from '../../../../../../config/constant';

export const MasterDataBusDeleteAction = {
  FETCH: 'MASTER_DATA_BUS_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_BUS_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_BUS_DELETE_FAILED',
  RESET: 'MASTER_DATA_BUS_DELETE_RESET',
};

export interface MasterDataBusDeleteInitialState {
  masterDataBusDeleteLoading: boolean;
  masterDataBusDeleteParams?: MasterDataBusDeleteFetchPayload;
  masterDataBusDeleteError?: MasterDataBusDeleteFailedPayload;
  masterDataBusDeleteResponse?: MasterDataBusDeleteSuccessPayload;
}

export interface MasterDataBusDeleteFetchPayload {
  id: string;
}
export type MasterDataBusDeleteFailedPayload = CommonError;

export interface MasterDataBusDeleteSuccessPayload {}
