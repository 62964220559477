import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataIndicatorValueDeleteInitialState} from './masterDataIndicatorValueDelete.reducer';
import {
  MasterDataIndicatorValueDeleteAction,
  MasterDataIndicatorValueDeleteFailedPayload,
  MasterDataIndicatorValueDeleteFetchPayload,
  MasterDataIndicatorValueDeleteSuccessPayload,
} from './masterDataIndicatorValueDelete.type';

export const masterDataIndicatorValueDeleteHandler = {
  [MasterDataIndicatorValueDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueDeleteLoading: true,
    masterDataIndicatorValueDeleteParams: action.payload,
    action: MasterDataIndicatorValueDeleteAction.FETCH,
  }),
  [MasterDataIndicatorValueDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueDeleteParams: undefined,
    masterDataIndicatorValueDeleteLoading: false,
    masterDataIndicatorValueDeleteResponse: action.payload,
    action: MasterDataIndicatorValueDeleteAction.SUCCESS,
  }),
  [MasterDataIndicatorValueDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueDeleteLoading: false,
    masterDataIndicatorValueDeleteError: action.payload,
    action: MasterDataIndicatorValueDeleteAction.FAILED,
  }),
  [MasterDataIndicatorValueDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataIndicatorValueDeleteInitialState,
    action: MasterDataIndicatorValueDeleteAction.RESET,
  }),
};
