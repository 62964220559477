import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataWorkInstructionListAction,
  MasterDataWorkInstructionListFailedPayload,
  MasterDataWorkInstructionListFetchPayload,
  MasterDataWorkInstructionListSuccessPayload,
  MasterDataWorkInstructionListUpdatePayload,
} from './masterDataWorkInstructionList.type';

export const masterDataWorkInstructionListAction = {
  fetch: createAction<MasterDataWorkInstructionListFetchPayload>(
    MasterDataWorkInstructionListAction.FETCH,
  ),
  success: createAction<MasterDataWorkInstructionListSuccessPayload>(
    MasterDataWorkInstructionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataWorkInstructionListFailedPayload>(
    MasterDataWorkInstructionListAction.FAILED,
  ),
  update: createAction<MasterDataWorkInstructionListUpdatePayload>(
    MasterDataWorkInstructionListAction.UPDATE,
  ),
  reset: createAction(MasterDataWorkInstructionListAction.RESET),
};
