//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {MasterDataIndicatorValueCreateFetchPayload} from '../../../../usecases/masterData.type';
import {masterDataIndicatorValueCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataIndicatorValueFormCreateForm} from './components';
// Data
import {IndicatorValueFormCreateInitialValues} from './components/MasterDataIndicatorValueFormCreateForm/MasterDataIndicatorValueFormCreateForm.component';
// Assets
import './MasterDataIndicatorValueFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataIndicatorValueFormCreateContainer: React.FC<Props> = ({
  assignmentType,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: IndicatorValueFormCreateInitialValues = {
    indicatorValues: [
      {
        name: '',
      },
    ],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitIndicatorValue = (
    formValue: IndicatorValueFormCreateInitialValues,
  ) => {
    const formattedValue: MasterDataIndicatorValueCreateFetchPayload =
      formValue?.indicatorValues.map((item) => ({
        name: item.name,
        assignmentType,
      })) || [];
    dispatch(masterDataIndicatorValueCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-indicator-value-form-create-container">
      <MasterDataIndicatorValueFormCreateForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitIndicatorValue={handleSubmitIndicatorValue}
      />
    </div>
  );
  //#endregion
};

export default MasterDataIndicatorValueFormCreateContainer;
