import {
  MasterDataBasicServiceTypeOptionListAction,
  MasterDataBasicServiceTypeOptionListSuccessPayload,
  MasterDataBasicServiceTypeOptionListFailedPayload,
  MasterDataBasicServiceTypeOptionListFetchPayload,
} from './masterDataBasicServiceTypeOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataBasicServiceTypeOptionListInitialState} from './masterDataBasicServiceTypeOptionList.reducer';

export const masterDataBasicServiceTypeOptionListHandler = {
  [MasterDataBasicServiceTypeOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataBasicServiceTypeOptionListLoading: true,
      masterDataBasicServiceTypeOptionListParams: action.payload,
      action: MasterDataBasicServiceTypeOptionListAction.FETCH,
    };
  },
  [MasterDataBasicServiceTypeOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeOptionListLoading: false,
    masterDataBasicServiceTypeOptionListResponse: action.payload,
    action: MasterDataBasicServiceTypeOptionListAction.SUCCESS,
  }),
  [MasterDataBasicServiceTypeOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeOptionListLoading: false,
    masterDataBasicServiceTypeOptionListData: [
      ...state.masterDataBasicServiceTypeOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataBasicServiceTypeOptionListAction.UPDATE,
  }),
  [MasterDataBasicServiceTypeOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeOptionListLoading: false,
    masterDataBasicServiceTypeOptionListError: action.payload,
    action: MasterDataBasicServiceTypeOptionListAction.FAILED,
  }),
  [MasterDataBasicServiceTypeOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBasicServiceTypeOptionListInitialState,
    action: MasterDataBasicServiceTypeOptionListAction.RESET,
  }),
};
