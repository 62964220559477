import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataObservationValueCreateInitialState} from './masterDataObservationValueCreate.reducer';
import {
  MasterDataObservationValueCreateAction,
  MasterDataObservationValueCreateFailedPayload,
  MasterDataObservationValueCreateFetchPayload,
  MasterDataObservationValueCreateSuccessPayload,
} from './masterDataObservationValueCreate.type';

export const masterDataObservationValueCreateHandler = {
  [MasterDataObservationValueCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueCreateLoading: true,
    masterDataObservationValueCreateParams: action.payload,
    action: MasterDataObservationValueCreateAction.FETCH,
  }),
  [MasterDataObservationValueCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueCreateParams: undefined,
    masterDataObservationValueCreateLoading: false,
    masterDataObservationValueCreateResponse: action.payload,
    action: MasterDataObservationValueCreateAction.SUCCESS,
  }),
  [MasterDataObservationValueCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueCreateLoading: false,
    masterDataObservationValueCreateError: action.payload,
    action: MasterDataObservationValueCreateAction.FAILED,
  }),
  [MasterDataObservationValueCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationValueCreateInitialState,
    action: MasterDataObservationValueCreateAction.RESET,
  }),
};
