//#region IMPORT
// IMPORT MODULE
import React from 'react';

// IMPORT CONFIG
import './SidebarGroup.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  children: React.ReactElement | string;
}
//#endregion

const SidebarGroup: React.FC<Props> = ({children}: Props) => (
  <div className="sidebar-group">
    <div className="sidebar-group__content">{children}</div>
  </div>
);

export default SidebarGroup;
