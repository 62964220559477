import {CommonError} from '../../../../config/constant';
import {User, UserAccessFormData} from '../../entity/user.string.entity';

export const UserAccessEditAction = {
  FETCH: 'USER_ACCESS_EDIT_FETCH',
  SUCCESS: 'USER_ACCESS_EDIT_SUCCESS',
  FAILED: 'USER_ACCESS_EDIT_FAILED',
  RESET: 'USER_ACCESS_EDIT_RESET',
};

export interface UserAccessEditInitialState {
  userAccessEditLoading: boolean;
  userAccessEditParams?: UserAccessEditFetchPayload;
  userAccessEditError?: UserAccessEditFailedPayload;
  userAccessEditResponse?: UserAccessEditSuccessPayload;
}

export type UserAccessEditFetchPayload = UserAccessFormData;
export type UserAccessEditFailedPayload = CommonError;

export type UserAccessEditSuccessPayload = {
  data: User;
};
