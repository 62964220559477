export enum UserType {
  INTERNAL = 'Internal',
  EXTERNAL = 'External',
}

export enum UserLevel {
  SUPERUSER = 'USERLEVEL0',
  DISHUB = 'USERLEVEL1',
  DIREKSI = 'USERLEVEL2',
  KEPALADIVISI = 'USERLEVEL3',
  KEPALADEPARTMENT = 'USERLEVEL4',
  KEPALASEKSISPM = 'USERLEVEL5',
  PICDEPARTAMENT = 'USERLEVEL6',
  KOORDINATOR = 'USERLEVEL7',
  PETUGAS = 'USERLEVEL8',
  SPMOFFICER = 'USERLEVEL9',
}

export interface UserData {
  id?: string;
  accessToken?: string;
  email?: string;
  name?: string;
  nik?: string;
  isFirstLogin?: boolean;
  isActive?: boolean;
  profilePicture?: string;
  phoneNumber?: string;
  directorate?: string;
  division?: string;
  department?: string;
  departmentId?: string;
  role?: UserDataRole;
  userLevel?: UserLevel;
}

export interface UserDataRole extends Omit<Role, 'permissions'> {
  permissions: BackOfficePermissionType;
}
export interface UserAccessFormData {
  id?: string;
  nik?: string;
  name: string;
  directorateId?: string;
  divisionId?: string;
  departmentId?: string;
  email: string;
  roleId: string;
  type: UserType;
  dateOfBirth?: string;
  userLevel: number;
}
export interface User {
  id: string;
  nik: string;
  name: string;
  directorate: string;
  division: string;
  department: string;
  directorateId: string;
  divisionId: string;
  departmentId: string;
  email: string;
  role: Role;
  isActive?: boolean;
  profilePicture?: string;
  type: UserType;
}

export interface UserAccountFormData {
  name?: string;
  phoneNumber?: string;
  file?: File;
}

export interface UserDetail extends User {
  roleId: string;
  roleName: string;
  phoneNumber: string;
  dateOfBirth: string;
}

export interface UserProfileData {
  base64?: string | unknown;
  fileName?: string;
  fileLength?: number;
}

export type UserOrderByType =
  | 'name'
  | 'nik'
  | 'role.name'
  | 'department'
  | 'email'
  | 'isActive';

export interface RolePermission {
  havePermissionCreate: boolean;
  havePermissionRead: boolean;
  havePermissionDelete: boolean;
  havePermissionUpdate: boolean;
}

export interface Role {
  id: string;
  name: string;
  userLevel?: UserLevel | string;
  permissions: PermissionType;
  accessCount?: number;
  userAccessManagement?: boolean;
}

export interface RoleOverview extends Omit<Role, 'permissions'> {
  permissionType: PermissionType;
  accessCount: number;
}
export interface RoleFormData extends Omit<Role, 'id' | 'permissions'> {
  id?: string;
  permissionType: PermissionType;
}

export interface OtherRolePermission extends RolePermission {
  havePermissionDownload: boolean;
}

export interface BackOfficePermissionType {
  dashboardOverviewAchievementFinalChart: RolePermission;
  dashboardOverviewAchievementQuarterlyChart: RolePermission;
  dashboardOverviewAnalyticsQualitative: RolePermission;
  dashboardOverviewBestBusStopChart: RolePermission;
  dashboardOverviewBestOperatorChart: RolePermission;
  dashboardOverviewDirectorateNotes: RolePermission;
  dashboardOverviewProfitAndFineChart: RolePermission;
  followUpTabApprove: RolePermission;
  followUpTabExpired: RolePermission;
  followUpTabInProgress: RolePermission;
  followUpTabRejected: RolePermission;
  followUpTabWaitingForVerification: RolePermission;
  hasDashboardInternalFindingChart: boolean;
  hasDashboardInternalGapChart: boolean;
  hasDashboardInternalRatedServiceChart: boolean;
  hasDashboardInternalServiceAchievementFinalChart: boolean;
  hasDashboardInternalWeightChart: boolean;
  hasDashboardPICAchivementChart: boolean;
  hasDashboardPICBestDepartmentChart: boolean;
  hasDashboardPICDifferenceChart: boolean;
  hasDashboardPICFindingChart: boolean;
  hasDashboardPICProfitAndFineChart: boolean;
  masterBusData: RolePermission;
  masterEvaluationForm: RolePermission;
  masterRouteData: RolePermission;
  masterWorkAssignment: RolePermission;
  masterWorkAssignmentInstruction: RolePermission;
  masterOrganization: RolePermission;
  notificationAllFinding: boolean;
  notificationConfirmReminder: boolean;
  notificationExpiredFinding: boolean;
  notificationExpiredNotesFromChief: boolean;
  notificationExpiredNotesFromDirectorate: boolean;
  notificationFindingPerDepartment: boolean;
  notificationRejectedFollowUp: boolean;
  otherAssignmentForm: OtherRolePermission;
  otherAssignmentReport: OtherRolePermission;
  otherWorkReport: OtherRolePermission;
}

export interface MobilePermissionType {
  evaluationDraft: RolePermission;
  evaluationForm: RolePermission;
  evaluationIdentity: RolePermission;
  evaluationList: RolePermission;
  evaluationHistory: RolePermission;
  followUpApproved: RolePermission;
  followUpExpired: RolePermission;
  followUpInProgress: RolePermission;
  followUpRejected: RolePermission;
  followUpWaitingForVerification: RolePermission;
  notificationDailyAssignment: boolean;
  notificationFollowUpEvaluationReport: boolean;
  notificationNewAssignmentEvaluationReport: boolean;
  notificationRejectedAssignmentEvaluationReport: boolean;
  scheduleDraft: RolePermission;
  scheduleIdentity: RolePermission;
  scheduleList: RolePermission;
  scheduleHistory: RolePermission;
}
export interface PermissionType {
  backOfficePermissions: BackOfficePermissionType;
  mobilePermissions: MobilePermissionType;
}

export interface RoleTableData {
  label: string;
  permissions: string;
}
