import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDepartmentDeleteInitialState} from './masterDataDepartmentDelete.reducer';
import {
  MasterDataDepartmentDeleteAction,
  MasterDataDepartmentDeleteFailedPayload,
  MasterDataDepartmentDeleteFetchPayload,
  MasterDataDepartmentDeleteSuccessPayload,
} from './masterDataDepartmentDelete.type';

export const masterDataDepartmentDeleteHandler = {
  [MasterDataDepartmentDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentDeleteLoading: true,
    masterDataDepartmentDeleteParams: action.payload,
    action: MasterDataDepartmentDeleteAction.FETCH,
  }),
  [MasterDataDepartmentDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentDeleteParams: undefined,
    masterDataDepartmentDeleteLoading: false,
    masterDataDepartmentDeleteResponse: action.payload,
    action: MasterDataDepartmentDeleteAction.SUCCESS,
  }),
  [MasterDataDepartmentDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentDeleteLoading: false,
    masterDataDepartmentDeleteError: action.payload,
    action: MasterDataDepartmentDeleteAction.FAILED,
  }),
  [MasterDataDepartmentDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDepartmentDeleteInitialState,
    action: MasterDataDepartmentDeleteAction.RESET,
  }),
};
