//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../../../../lang';
// Components
import {MButton} from '../../../../../../../components';
import {
  FollowUpCardContent,
  FollowUpCardHeader,
  FollowUpCardNotes,
  FollowUpDivider,
} from '../../../../../components';
// Data
import {FollowUp} from '../../../../../entity/followUp.string.entity';
// Assets
import './FollowUpOnProgressListItem.component.style.scss';
import moment from 'moment';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp;
  isLoading?: boolean;
  isDetail?: boolean;
  isCanConfirm?: boolean;
  isCanComplete?: boolean;
  isSPMOfficer?: boolean;
  onViewItem?(): void;
  onAction?(): void;
}
//#endregion

const FollowUpOnProgressListItem: React.FC<Props> = ({
  data,
  isLoading,
  isDetail,
  isCanConfirm,
  isCanComplete,
  isSPMOfficer,
  onViewItem,
  onAction,
}: Props) => {
  return (
    <div
      className="follow-up-on-progress-list-item"
      onClick={(e) => {
        e.stopPropagation();
        onViewItem && onViewItem();
      }}>
      {data?.chiefDeptNote && (
        <>
          <div className="follow-up-on-progress-list-item__notes">
            <FollowUpCardNotes
              notesData={{
                title: t('Chief of Department Note'),
                value: data?.chiefDeptNote || '-',
                date: data?.chiefDeptNoteDate
                  ? moment(data?.chiefDeptNoteDate, 'YYYY-MM-DD HH:mm').format(
                      'DD/MM/YYYY HH:mm',
                    )
                  : '-',
              }}
            />
          </div>
          <FollowUpDivider />
        </>
      )}
      <div className="follow-up-on-progress-list-item__header">
        <FollowUpCardHeader
          status={isSPMOfficer ? data?.status : undefined}
          headerData={data}
          isSPMOfficer={isSPMOfficer}
        />
      </div>
      <FollowUpDivider />
      {data?.findingImg && (
        <div className="follow-up-on-progress-list-item__content">
          <FollowUpCardContent
            title={t('Findings')}
            contentData={{
              name: data?.findingIndicator || '-',
              image: data?.findingImg,
              value: data?.finding || '-',
            }}
            isDetail={isDetail}
          />
        </div>
      )}
      {!isSPMOfficer && onAction && (
        <div className="follow-up-on-progress-list-item__actions">
          <div
            className={`follow-up-on-progress-list-item__actions${
              isDetail ? '-detail' : '-item'
            }${data?.findingDueDate ? '--followUp' : ''}`}>
            <MButton
              buttonLabel={
                data?.findingDueDate
                  ? t('Complete Follow Up')
                  : t('Confirm Findings')
              }
              isDisabled={
                (data?.isExpired && !data?.chiefDeptNote) ||
                isLoading ||
                !isCanConfirm ||
                !isCanComplete
              }
              isLoading={isLoading}
              handleClick={onAction}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default FollowUpOnProgressListItem;
