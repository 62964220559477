import followUpList from './followUpList/followUpList.saga';
import followUpKadepList from './followUpKadepList/followUpKadepList.saga';
import followUpExpiredNoteCreate from './followUpExpiredNoteCreate/followUpExpiredNoteCreate.saga';
import followUpPICConfirm from './followUpPICConfirm/followUpPICConfirm.saga';
import followUpPICComplete from './followUpPICComplete/followUpPICComplete.saga';
import followUpDetail from './followUpDetail/followUpDetail.saga';

export default [
  ...followUpList,
  ...followUpKadepList,
  ...followUpExpiredNoteCreate,
  ...followUpPICConfirm,
  ...followUpPICComplete,
  ...followUpDetail,
];
