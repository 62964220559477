//#region IMPORT
// Libraries
import React from 'react';
import {TableCell, TableHead, TableRow} from '@mui/material';
// Utils
import t from '../.../../../../../lang';
// Components
// Assets
import './RoleTableHeader.component.style.scss';
//#endregion
interface Props {
  headerTitle?: string;
  valueType?: 'standard' | 'single' | 'download' | 'export';
}
const RoleTableHeader: React.FC<Props> = ({valueType, headerTitle}: Props) => {
  if (valueType === 'single')
    return (
      <TableHead className="role-table-header">
        <TableRow className="role-table-header__row">
          <TableCell
            padding="normal"
            width="20%"
            className="role-table-header__row--item">
            {headerTitle || t('Page')}
          </TableCell>

          <TableCell
            align="center"
            padding="normal"
            width="5%"
            className="role-table-header__row--item">
            Read
          </TableCell>
          <TableCell
            align="center"
            padding="normal"
            width="5%"
            className="role-table-header__row--item"></TableCell>
          <TableCell
            align="center"
            padding="normal"
            width="5%"
            className="role-table-header__row--item"></TableCell>
          <TableCell
            align="center"
            padding="normal"
            width="5%"
            className="role-table-header__row--item"></TableCell>
          <TableCell />
          <TableCell
            align="center"
            padding="normal"
            width="5%"
            className="role-table-header__row--item"></TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
    );
  return (
    <TableHead className="role-table-header">
      <TableRow className="role-table-header__row">
        <TableCell
          padding="normal"
          width="20%"
          className="role-table-header__row--item">
          {headerTitle || t('Page')}
        </TableCell>

        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="role-table-header__row--item">
          Create
        </TableCell>
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="role-table-header__row--item">
          Read
        </TableCell>
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="role-table-header__row--item">
          Update
        </TableCell>
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="role-table-header__row--item">
          Delete
        </TableCell>
        {valueType === 'download' && (
          <TableCell
            align="center"
            padding="normal"
            width="5%"
            className="role-table-header__row--item">
            Download
          </TableCell>
        )}
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default RoleTableHeader;
