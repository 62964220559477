import {
  MasterDataReviewIndicatorListAction,
  MasterDataReviewIndicatorListSuccessPayload,
  MasterDataReviewIndicatorListFailedPayload,
  MasterDataReviewIndicatorListFetchPayload,
} from './masterDataReviewIndicatorList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataReviewIndicatorListInitialState} from './masterDataReviewIndicatorList.reducer';

export const masterDataReviewIndicatorListHandler = {
  [MasterDataReviewIndicatorListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataReviewIndicatorListLoading: true,
      masterDataReviewIndicatorListParams: action.payload,
      action: MasterDataReviewIndicatorListAction.FETCH,
    };
  },
  [MasterDataReviewIndicatorListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorListLoading: false,
    masterDataReviewIndicatorListResponse: action.payload,
    action: MasterDataReviewIndicatorListAction.SUCCESS,
  }),
  [MasterDataReviewIndicatorListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorListLoading: false,
    masterDataReviewIndicatorListData: [
      ...state.masterDataReviewIndicatorListData,
      ...action.payload.data.content,
    ],
    action: MasterDataReviewIndicatorListAction.UPDATE,
  }),
  [MasterDataReviewIndicatorListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorListLoading: false,
    masterDataReviewIndicatorListError: action.payload,
    action: MasterDataReviewIndicatorListAction.FAILED,
  }),
  [MasterDataReviewIndicatorListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataReviewIndicatorListInitialState,
    action: MasterDataReviewIndicatorListAction.RESET,
  }),
};
