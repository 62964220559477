import {GenericAction} from '../../../../../../config/constant';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardOverviewSetProfitInitialState} from './dashboardOverviewSetProfit.reducer';
import {
  DashboardOverviewSetProfitAction,
  DashboardOverviewSetProfitFailedPayload,
  DashboardOverviewSetProfitFetchPayload,
  DashboardOverviewSetProfitSuccessPayload,
} from './dashboardOverviewSetProfit.type';

export const dashboardOverviewSetProfitHandler = {
  [DashboardOverviewSetProfitAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewSetProfitFetchPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewSetProfitLoading: true,
    dashboardOverviewSetProfitParams: action.payload,
    action: DashboardOverviewSetProfitAction.FETCH,
  }),
  [DashboardOverviewSetProfitAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewSetProfitSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewSetProfitParams: undefined,
    dashboardOverviewSetProfitLoading: false,
    dashboardOverviewSetProfitResponse: action.payload,
    action: DashboardOverviewSetProfitAction.SUCCESS,
  }),
  [DashboardOverviewSetProfitAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewSetProfitFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewSetProfitLoading: false,
    dashboardOverviewSetProfitError: action.payload,
    action: DashboardOverviewSetProfitAction.FAILED,
  }),
  [DashboardOverviewSetProfitAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardOverviewSetProfitInitialState,
    action: DashboardOverviewSetProfitAction.RESET,
  }),
};
