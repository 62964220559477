//#region IMPORT
import {mdiClose} from '@mdi/js';
import {Dialog, DialogContent, DialogTitle, DialogProps} from '@mui/material';
import React from 'react';
import MIconButton from '../MIconButton';

import './Modal.scss';
//#endregion

interface Props {
  children: React.ReactElement | string;
  isOpen: boolean;
  title?: string;
  fullWidth?: boolean;
  fullScreen?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  onClose?(): void;
}

const Modal: React.FC<Props> = ({
  children,
  isOpen,
  title,
  fullWidth = true,
  fullScreen = false,
  maxWidth = 'sm',
  onClose,
}: Props) => {
  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason && reason == 'backdropClick') return;
        onClose && onClose();
      }}
      open={isOpen}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      disableEscapeKeyDown
      className="m-dialog__wrapper">
      <div className="m-dialog">
        {onClose && (
          <div className="m-dialog__close-button">
            <MIconButton small icon={mdiClose} onClick={onClose} />
          </div>
        )}
        {title && (
          <DialogTitle>
            <div className="m-dialog__title">{title}</div>
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
      </div>
    </Dialog>
  );
};

export default Modal;
