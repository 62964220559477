import {CommonError} from '../../../../../../config/constant';

export const MasterDataIndicatorValueSetPositiveValueAction = {
  FETCH: 'MASTER_DATA_INDICATOR_VALUE_SET_POSITIVE_VALUE_FETCH',
  SUCCESS: 'MASTER_DATA_INDICATOR_VALUE_SET_POSITIVE_VALUE_SUCCESS',
  FAILED: 'MASTER_DATA_INDICATOR_VALUE_SET_POSITIVE_VALUE_FAILED',
  RESET: 'MASTER_DATA_INDICATOR_VALUE_SET_POSITIVE_VALUE_RESET',
};

export interface MasterDataIndicatorValueSetPositiveValueInitialState {
  masterDataIndicatorValueSetPositiveValueLoading: boolean;
  masterDataIndicatorValueSetPositiveValueParams?: MasterDataIndicatorValueSetPositiveValueFetchPayload;
  masterDataIndicatorValueSetPositiveValueError?: MasterDataIndicatorValueSetPositiveValueFailedPayload;
  masterDataIndicatorValueSetPositiveValueResponse?: MasterDataIndicatorValueSetPositiveValueSuccessPayload;
}

export interface MasterDataIndicatorValueSetPositiveValueFetchPayload {
  id: string;
}
export type MasterDataIndicatorValueSetPositiveValueFailedPayload = CommonError;

export interface MasterDataIndicatorValueSetPositiveValueSuccessPayload {}
