import {
  MasterDataCategoryListAction,
  MasterDataCategoryListSuccessPayload,
  MasterDataCategoryListFailedPayload,
  MasterDataCategoryListFetchPayload,
} from './masterDataCategoryList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataCategoryListInitialState} from './masterDataCategoryList.reducer';

export const masterDataCategoryListHandler = {
  [MasterDataCategoryListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataCategoryListLoading: true,
      masterDataCategoryListParams: action.payload,
      action: MasterDataCategoryListAction.FETCH,
    };
  },
  [MasterDataCategoryListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryListLoading: false,
    masterDataCategoryListResponse: action.payload,
    action: MasterDataCategoryListAction.SUCCESS,
  }),
  [MasterDataCategoryListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryListLoading: false,
    masterDataCategoryListData: [
      ...state.masterDataCategoryListData,
      ...action.payload.data.content,
    ],
    action: MasterDataCategoryListAction.UPDATE,
  }),
  [MasterDataCategoryListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryListLoading: false,
    masterDataCategoryListError: action.payload,
    action: MasterDataCategoryListAction.FAILED,
  }),
  [MasterDataCategoryListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataCategoryListInitialState,
    action: MasterDataCategoryListAction.RESET,
  }),
};
