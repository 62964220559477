import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataReviewIndicatorMoveInitialState} from './masterDataReviewIndicatorMove.reducer';
import {
  MasterDataReviewIndicatorMoveAction,
  MasterDataReviewIndicatorMoveFailedPayload,
  MasterDataReviewIndicatorMoveFetchPayload,
  MasterDataReviewIndicatorMoveSuccessPayload,
} from './masterDataReviewIndicatorMove.type';

export const masterDataReviewIndicatorMoveHandler = {
  [MasterDataReviewIndicatorMoveAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorMoveFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorMoveLoading: true,
    masterDataReviewIndicatorMoveParams: action.payload,
    action: MasterDataReviewIndicatorMoveAction.FETCH,
  }),
  [MasterDataReviewIndicatorMoveAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorMoveSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorMoveParams: undefined,
    masterDataReviewIndicatorMoveLoading: false,
    masterDataReviewIndicatorMoveResponse: action.payload,
    action: MasterDataReviewIndicatorMoveAction.SUCCESS,
  }),
  [MasterDataReviewIndicatorMoveAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorMoveFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorMoveLoading: false,
    masterDataReviewIndicatorMoveError: action.payload,
    action: MasterDataReviewIndicatorMoveAction.FAILED,
  }),
  [MasterDataReviewIndicatorMoveAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataReviewIndicatorMoveInitialState,
    action: MasterDataReviewIndicatorMoveAction.RESET,
  }),
};
