import {CommonError} from '../../../../../../config/constant';
import {MasterDataIndicatorValueFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataIndicatorValueEditAction = {
  FETCH: 'MASTER_DATA_INDICATOR_VALUE_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_INDICATOR_VALUE_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_INDICATOR_VALUE_EDIT_FAILED',
  RESET: 'MASTER_DATA_INDICATOR_VALUE_EDIT_RESET',
};

export interface MasterDataIndicatorValueEditInitialState {
  masterDataIndicatorValueEditLoading: boolean;
  masterDataIndicatorValueEditParams?: MasterDataIndicatorValueEditFetchPayload;
  masterDataIndicatorValueEditError?: MasterDataIndicatorValueEditFailedPayload;
  masterDataIndicatorValueEditResponse?: MasterDataIndicatorValueEditSuccessPayload;
}

export type MasterDataIndicatorValueEditFetchPayload = MasterDataIndicatorValueFormData;

export type MasterDataIndicatorValueEditFailedPayload = CommonError;

export interface MasterDataIndicatorValueEditSuccessPayload {}
