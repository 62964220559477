import {CommonData, CommonError} from '../../../../config/constant';
import {SortType} from '../../../wrapper/entity';
import {EvaluationReportManagement} from '../../entity/evaluationReport.string.entity';

export const EvaluationReportListAction = {
  FETCH: 'EVALUATION_REPORT_LIST_FETCH',
  SUCCESS: 'EVALUATION_REPORT_LIST_SUCCESS',
  FAILED: 'EVALUATION_REPORT_LIST_FAILED',
  RESET: 'EVALUATION_REPORT_LIST_RESET',
  UPDATE: 'EVALUATION_REPORT_LIST_UPDATE',
};

export interface EvaluationReportListInitialState {
  evaluationReportListLoading: boolean;
  evaluationReportListData: EvaluationReportManagement[];
  evaluationReportListError?: EvaluationReportListFailedPayload;
  evaluationReportListParams?: EvaluationReportListFetchPayload;
  evaluationReportListResponse?: EvaluationReportListSuccessPayload;
}

export type EvaluationReportListResponse = {
  status: string;
  info?: number;
  data: CommonData<EvaluationReportManagement[]>;
};

export type EvaluationReportListFailedPayload = CommonError;

export type EvaluationReportListSuccessPayload = EvaluationReportListResponse;

export type EvaluationReportListUpdatePayload = EvaluationReportManagement[];

export interface EvaluationReportListFetchPayload {
  page?: number;
  size?: number;
  search?: string;
  sort?: SortType;
  filter?: string;
  startDate?: string;
  endDate?: string;
}
