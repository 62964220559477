import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationValueListAction,
  MasterDataObservationValueListFailedPayload,
  MasterDataObservationValueListFetchPayload,
  MasterDataObservationValueListSuccessPayload,
  MasterDataObservationValueListUpdatePayload,
} from './masterDataObservationValueList.type';

export const masterDataObservationValueListAction = {
  fetch: createAction<MasterDataObservationValueListFetchPayload>(
    MasterDataObservationValueListAction.FETCH,
  ),
  success: createAction<MasterDataObservationValueListSuccessPayload>(
    MasterDataObservationValueListAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationValueListFailedPayload>(
    MasterDataObservationValueListAction.FAILED,
  ),
  update: createAction<MasterDataObservationValueListUpdatePayload>(
    MasterDataObservationValueListAction.UPDATE,
  ),
  reset: createAction(MasterDataObservationValueListAction.RESET),
};
