import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataFormController} from '../../../../entity/masterData.string.entity';

export const MasterDataFormControllerListAction = {
  FETCH: 'MASTER_DATA_FORM_CONTROLLER_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_FORM_CONTROLLER_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_FORM_CONTROLLER_LIST_FAILED',
  RESET: 'MASTER_DATA_FORM_CONTROLLER_LIST_RESET',
  UPDATE: 'MASTER_DATA_FORM_CONTROLLER_LIST_UPDATE',
};

export interface MasterDataFormControllerListInitialState {
  masterDataFormControllerListLoading: boolean;
  masterDataFormControllerListData: MasterDataFormController[];
  masterDataFormControllerListError?: MasterDataFormControllerListFailedPayload;
  masterDataFormControllerListParams?: MasterDataFormControllerListFetchPayload;
  masterDataFormControllerListResponse?: MasterDataFormControllerListSuccessPayload;
}

export type MasterDataFormControllerListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataFormController[]>;
};

export type MasterDataFormControllerListFailedPayload = CommonError;

export type MasterDataFormControllerListSuccessPayload = MasterDataFormControllerListResponse;

export type MasterDataFormControllerListUpdatePayload = MasterDataFormControllerListResponse;

export interface MasterDataFormControllerListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
