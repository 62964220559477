/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
// Components
import MasterDataIndicatorValueManagementListTableHeader from '../MasterDataIndicatorValueManagementListTableHeader';
import MasterDataIndicatorValueManagementListTableItem from '../MasterDataIndicatorValueManagementListTableItem';
// Data
import {
  MasterDataIndicatorValue,
  MasterDataIndicatorValueOrderByType,
} from '../../../../../../entity/masterData.string.entity';
import {getComparator, stableSort} from '../../../../../../../../utils';
//#endregion

//#region INTERFACE
interface Props {
  indicatorValueList: MasterDataIndicatorValue[];
  sortBy: SortTable;
  orderBy: MasterDataIndicatorValueOrderByType;
  onEditIndicatorValue?(_indicatorValue: MasterDataIndicatorValue): void;
  onDeleteIndicatorValue?(_indicatorValue: MasterDataIndicatorValue): void;
  onChangeStatus?(_indicatorValue: MasterDataIndicatorValue): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataIndicatorValueOrderByType,
  ): void;
}
//#endregion

const MasterDataIndicatorValueManagementList: React.FC<Props> = ({
  indicatorValueList,
  sortBy,
  orderBy,
  onEditIndicatorValue,
  onDeleteIndicatorValue,
  onChangeStatus,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataIndicatorValueManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            indicatorValueList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <MasterDataIndicatorValueManagementListTableItem
              key={`master-data-indicator-value-list-item-data-${item.id}-${index}`}
              {...(onEditIndicatorValue && {
                onEditItem: () => onEditIndicatorValue(item),
              })}
              {...(onDeleteIndicatorValue && {
                onDeleteItem: () => onDeleteIndicatorValue(item),
              })}
              {...(onChangeStatus && {
                onActivateItem: () => onChangeStatus(item),
              })}
              data={item}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataIndicatorValueManagementList;
