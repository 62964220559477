import React from 'react';
import {InputLabel, MenuItem, Select} from '@mui/material';
import t from '../../lang';
import './MDropdown.component.style.scss';
import {IconCaretArrowDown} from '../../assets/icons';

export interface SelectItemType {
  label: string;
  value: string;
}
interface Props {
  label?: string;
  options: SelectItemType[];
  inputValue?: string;
  placeholder: string;
  inputClassName?: string;
  iconComponent?: React.ElementType;
  handleChangeSelect?(e: string): void;
  inputWidth?: string | number;
}

const MDropdown: React.FC<Props> = ({
  label,
  options,
  inputValue,
  placeholder,
  inputClassName,
  iconComponent = IconCaretArrowDown,
  handleChangeSelect,
  inputWidth = '14rem',
  ...otherProps
}: Props) => {
  return (
    <div
      className={`m-dropdown ${inputClassName ?? ''}`}
      style={{minWidth: inputWidth}}>
      {label ? (
        <InputLabel className="m-dropdown__label">{label}</InputLabel>
      ) : null}
      <Select
        className="m-dropdown__field"
        placeholder={placeholder}
        displayEmpty
        value={inputValue}
        IconComponent={iconComponent}
        onChange={(e) => {
          const val = e?.target?.value;
          handleChangeSelect && handleChangeSelect(val);
        }}
        renderValue={(selected) => {
          const selectedLabel = options.find((item) => item.value === selected)
            ?.label;
          if (!selected)
            return (
              <div className="m-dropdown__field--placeholder">
                {placeholder}
              </div>
            );
          return selectedLabel;
        }}
        {...otherProps}>
        {options.map((_option: SelectItemType, idx: number) => (
          <MenuItem
            key={`option-item-${_option}-${idx}`}
            className="m-dropdown__field--item"
            value={_option.value}>
            {t(_option.label)}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default MDropdown;
