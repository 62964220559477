//#region IMPORT
// Libraries
import React from 'react';
// Components
import {NotificationSection} from '../sections';
// Assets
import './NotificationManagementWrapper.scss';
//#endregion

const NotificationManagementWrapper: React.FC = () => {
  return (
    <div className="notification-management">
      <div className="notification-management-wrapper">
        <NotificationSection />
      </div>
    </div>
  );
};

export default NotificationManagementWrapper;
