import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailObservationOptionListAction,
  MasterDataDetailObservationOptionListFailedPayload,
  MasterDataDetailObservationOptionListFetchPayload,
  MasterDataDetailObservationOptionListSuccessPayload,
  MasterDataDetailObservationOptionListUpdatePayload,
} from './masterDataDetailObservationOptionList.type';

export const masterDataDetailObservationOptionListAction = {
  fetch: createAction<MasterDataDetailObservationOptionListFetchPayload>(
    MasterDataDetailObservationOptionListAction.FETCH,
  ),
  success: createAction<MasterDataDetailObservationOptionListSuccessPayload>(
    MasterDataDetailObservationOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailObservationOptionListFailedPayload>(
    MasterDataDetailObservationOptionListAction.FAILED,
  ),
  update: createAction<MasterDataDetailObservationOptionListUpdatePayload>(
    MasterDataDetailObservationOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataDetailObservationOptionListAction.RESET),
};
