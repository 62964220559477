import {
  FollowUpDetailAction,
  FollowUpDetailSuccessPayload,
  FollowUpDetailFailedPayload,
  FollowUpDetailFetchPayload,
} from './followUpDetail.type';
import {FollowUpState} from '../followUp.reducer';
import {GenericAction} from '../../../../config/constant';
import {followUpDetailInitialState} from './followUpDetail.reducer';

export const followUpDetailHandler = {
  [FollowUpDetailAction.FETCH]: (
    state: FollowUpState,
    action: GenericAction<FollowUpDetailFetchPayload>,
  ): FollowUpState => {
    return {
      ...state,
      followUpDetailLoading: true,
      followUpDetailParams: action.payload,
      action: FollowUpDetailAction.FETCH,
    };
  },
  [FollowUpDetailAction.SUCCESS]: (
    state: FollowUpState,
    action: GenericAction<FollowUpDetailSuccessPayload>,
  ): FollowUpState => ({
    ...state,
    followUpDetailLoading: false,
    followUpDetailResponse: action.payload,
    action: FollowUpDetailAction.SUCCESS,
  }),
  [FollowUpDetailAction.FAILED]: (
    state: FollowUpState,
    action: GenericAction<FollowUpDetailFailedPayload>,
  ): FollowUpState => ({
    ...state,
    followUpDetailLoading: false,
    followUpDetailError: action.payload,
    action: FollowUpDetailAction.FAILED,
  }),
  [FollowUpDetailAction.RESET]: (state: FollowUpState): FollowUpState => ({
    ...state,
    ...followUpDetailInitialState,
    action: FollowUpDetailAction.RESET,
  }),
};
