import moment from 'moment';
import {register} from 'timeago.js';
import id from 'timeago.js/lib/lang/id_ID';
import 'moment/locale/id';

type LangCode = 'en' | 'id';

interface EnvConfig {
  api: string;
  redirect: string;
}

export const lang = (): LangCode => {
  return 'id';
};

moment.locale(['id']);

register('id', id);

export const Env: EnvConfig =
  process.env.REACT_APP_ENV === 'production'
    ? {
        api: 'https://spm.transjakarta.co.id/apis-spm',
        redirect: './',
      }
    : {
        api: 'https://spm.transjakarta.co.id/apis-spm',
        redirect: './',
      };
