/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../../../utils';
// Components
import ObservationManagementListTableHeader from '../ObservationManagementListTableHeader';
import ObservationManagementListTableItem from '../ObservationManagementListTableItem';
// Data
import {
  MasterDataObservation,
  MasterDataObservationOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  observationList: MasterDataObservation[];
  sortBy: SortTable;
  orderBy: MasterDataObservationOrderByType;
  onEditObservation?(_Observation: MasterDataObservation): void;
  onDeleteObservation?(_Observation: MasterDataObservation): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataObservationOrderByType,
  ): void;
}
//#endregion

const ObservationManagementList: React.FC<Props> = ({
  observationList,
  sortBy,
  orderBy,
  onEditObservation,
  onDeleteObservation,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <ObservationManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            observationList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <ObservationManagementListTableItem
              {...(onEditObservation && {
                onEditItem: () => onEditObservation(item),
              })}
              {...(onDeleteObservation && {
                onDeleteItem: () => onDeleteObservation(item),
              })}
              key={`observation-list-item-data-${item.id}-${index}`}
              data={item}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ObservationManagementList;
