import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {
  CommonData,
  CommonError,
  GenericAction,
} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {FollowUp} from '../../entity/followUp.string.entity';
import {followUpListAction} from './followUpList.action';
import {
  FollowUpListAction,
  FollowUpListFetchPayload,
  FollowUpListResponse,
  FollowUpListSuccessPayload,
} from './followUpList.type';

function* workerFetch(action: GenericAction<FollowUpListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {sort, ...rest} = action.payload;
  try {
    const res: AxiosResponse<FollowUpListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/follow-up/find-all`,
      {
        sort: `created_at,${sort?.toLowerCase()}`,
        ...rest,
      },
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      const formattedResponseData: FollowUp[] =
        (res.data as FollowUpListResponse)?.data?.followUps?.content ||
        ((res.data as FollowUpListResponse)?.data as CommonData<FollowUp[]>)
          ?.content ||
        [];
      yield put(followUpListAction.success(res.data as FollowUpListResponse));
      yield put(followUpListAction.update(formattedResponseData));
    } else {
      yield put(followUpListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(followUpListAction.failed(e as CommonError));
  }
}

const followUpListWatcher = [takeLatest(FollowUpListAction.FETCH, workerFetch)];

export default followUpListWatcher;
