import {CommonError} from '../../../../../../config/constant';
import {MasterDataReviewIndicatorFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataReviewIndicatorCreateAction = {
  FETCH: 'MASTER_DATA_REVIEW_INDICATOR_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_REVIEW_INDICATOR_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_REVIEW_INDICATOR_CREATE_FAILED',
  RESET: 'MASTER_DATA_REVIEW_INDICATOR_CREATE_RESET',
};

export interface MasterDataReviewIndicatorCreateInitialState {
  masterDataReviewIndicatorCreateLoading: boolean;
  masterDataReviewIndicatorCreateParams?: MasterDataReviewIndicatorCreateFetchPayload;
  masterDataReviewIndicatorCreateError?: MasterDataReviewIndicatorCreateFailedPayload;
  masterDataReviewIndicatorCreateResponse?: MasterDataReviewIndicatorCreateSuccessPayload;
}

export type MasterDataReviewIndicatorCreateFetchPayload = Omit<
  MasterDataReviewIndicatorFormData,
  'id'
>;

export type MasterDataReviewIndicatorCreateFailedPayload = CommonError;

export interface MasterDataReviewIndicatorCreateSuccessPayload {}
