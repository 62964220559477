import {CommonError} from '../../../../config/constant';

export const UserAccessDeleteAction = {
  FETCH: 'USER_ACCESS_DELETE_FETCH',
  SUCCESS: 'USER_ACCESS_DELETE_SUCCESS',
  FAILED: 'USER_ACCESS_DELETE_FAILED',
  RESET: 'USER_ACCESS_DELETE_RESET',
};

export interface UserAccessDeleteInitialState {
  userAccessDeleteLoading: boolean;
  userAccessDeleteParams?: UserAccessDeleteFetchPayload;
  userAccessDeleteError?: UserAccessDeleteFailedPayload;
  userAccessDeleteResponse?: UserAccessDeleteSuccessPayload;
}

export type UserAccessDeleteFetchPayload = {
  id: string;
};
export type UserAccessDeleteFailedPayload = CommonError;

export interface UserAccessDeleteSuccessPayload {}
