/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import {Field, Formik} from 'formik';
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import * as Yup from 'yup';
// Utils
import {AppState} from '../../../../../../../../config/Interface';
import t from '../../../../../../../../lang';
import {MasterDataState} from '../../../../../../usecases/masterData.reducer';
import {getOptionFromBusTypeList} from '../../../../../../utils';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../../../components';
// Assets
import {IMasterDataBusForm} from '../../../../../../entity/masterData.string.entity';
import './BusForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: IMasterDataBusForm;
  handleCancel(): void;
  handleSubmitBus(_bus: IMasterDataBusForm): void;
}
//#endregion

const BusForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitBus,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const isEdit = !!initialValues?.id;
  const masterData: MasterDataState = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  //#endregion

  //#region VALIDATION
  const masterDataBusValidation = Yup.object().shape({
    bodyNumber: Yup.string().required(t('Body Number is required.')),
    operator: Yup.string().required(t('Operator is required.')),
    busType: Yup.string().required(t('Bus Type is required.')),
    pool: Yup.string().required(t('Pool is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="bus-form">
      <div className="bus-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={masterDataBusValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitBus}>
          {({handleSubmit, setFieldValue, isValid}): React.ReactElement => (
            <>
              <div className="bus-form__section">
                <div className="bus-form__section--field">
                  <div className="input-label">{t('Body Number')}</div>
                  <Field
                    disabled={isLoading}
                    className="bus-input"
                    name="bodyNumber"
                    placeholder={t('Enter Body Number')}
                    component={MTextField}
                  />
                </div>
                <div className="bus-form__section--field">
                  <div className="input-label">{t('Operator')}</div>
                  <Field
                    disabled={isLoading}
                    className="bus-input"
                    name="operator"
                    type="operator"
                    placeholder={t('Enter Operator')}
                    component={MTextField}
                  />
                </div>
                <div className="bus-form__section--field">
                  <div className="input-label">{t('Pool')}</div>
                  <Field
                    disabled={isLoading}
                    className="bus-input"
                    name="pool"
                    type="pool"
                    placeholder={t('Enter Pool')}
                    component={MTextField}
                  />
                </div>
                <div className="bus-form__section--field">
                  <div className="input-label">{t('Bus Type')}</div>
                  <Field
                    className="bus-input"
                    name="busType"
                    placeholder={t('Choose Bus Type')}
                    options={getOptionFromBusTypeList(
                      masterData.masterDataBusOptionListData,
                    )}
                    handleChangeSelect={(busType: string) => {
                      setFieldValue('busType', busType, true);
                    }}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
              </div>

              <div className="bus-form__form--action">
                <div className="bus-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="bus-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={!isEdit ? t('Add') : t('Edit')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default BusForm;
