//#region IMPORT
// Libraries
import {Grid} from '@mui/material';
import React from 'react';
// Utils
import t from '../../../../lang';
// Components
import {EvaluationReportCardContent} from '..';
// Data
import {
  EvaluationReportFindings,
  EvaluationReportPreviewFindings,
} from '../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportNote.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  noteData?: EvaluationReportFindings;
  handlePreview(_previewFindings: EvaluationReportPreviewFindings): void;
}
//#endregion

const EvaluationReportNote: React.FC<Props> = ({
  noteData,
  handlePreview,
}: Props) => {
  //#region NOTE
  const noteContent: EvaluationReportPreviewFindings = {
    name: t('Important Notes'),
    value: noteData?.noteValue ?? '',
    image: noteData?.noteImg ?? '',
  };
  //#endregion

  return (
    <div className="evaluation-report-note">
      <Grid
        item
        xs={6}
        key={`evaluation-report-note__detail-${noteData?.reviewIndicatorId}`}
        onClick={(e) => {
          e.stopPropagation();
          !!noteContent.image && handlePreview(noteContent);
        }}>
        <div
          className={`evaluation-report-note__card${
            noteContent.image ? 's' : ''
          }`}>
          <EvaluationReportCardContent contentData={noteContent} />
        </div>
      </Grid>
    </div>
  );
};
export default EvaluationReportNote;
