//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import moment from 'moment';
// Utils
import t from '../../../../../../lang';
// Components
import {
  MButton,
  MDatepickerInput,
  MSelect,
  MTextField,
} from '../../../../../../components';
// Data
import {
  DashboardOverviewFinanceFormData,
  DASHBOARDQUARTERINTERVAL,
} from '../../../../entity/dashboard.string.entity';
import {SelectItemType} from '../../../../../../components/MSelect/MSelect.component';
// Assets
import './DashboardFinanceForm.component.style.scss';
//#endregion

interface Props {
  isLoading?: boolean;
  initialValues: DashboardOverviewFinanceFormData;
  handleCancel(): void;
  handleSubmitFinance(_finance: DashboardOverviewFinanceFormData): void;
}
const DashboardFinanceForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitFinance,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const quarterFilterOption: SelectItemType[] = [
    {label: t('Quarter 1'), value: DASHBOARDQUARTERINTERVAL.TW1},
    {label: t('Quarter 2'), value: DASHBOARDQUARTERINTERVAL.TW2},
    {label: t('Quarter 3'), value: DASHBOARDQUARTERINTERVAL.TW3},
    {label: t('Quarter 4'), value: DASHBOARDQUARTERINTERVAL.TW4},
  ];
  //#endregion

  //#region VALIDATION
  const dashboardFinanceValidation = Yup.object().shape({
    year: Yup.string().required(t('Year is required.')),
    quarter: Yup.string().required(t('Quarter is required.')),
    value: Yup.string().required(t('Profit Value is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="dashboard-finance-form">
      <div className="dashboard-finance-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={dashboardFinanceValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitFinance}>
          {({handleSubmit, values, isValid}): React.ReactElement => (
            <>
              <div className="dashboard-finance-form__section">
                <div className="work-report-form__section--field">
                  <div className="input-label">{t('Year')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="year"
                    inputValue={values.year}
                    placeholder={t('Choose Year')}
                    toolbarText={t('Choose Year')}
                    views={['year']}
                    format="YYYY"
                    maxDate={moment().endOf('year')}
                    component={MDatepickerInput}
                  />
                </div>
                <div className="dashboard-finance-form__section--field">
                  <div className="input-label">{t('Quarter')}</div>
                  <Field
                    className="user-input"
                    name="quarter"
                    placeholder={t('Choose Quarter')}
                    options={quarterFilterOption}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
                <div className="dashboard-finance-form__section--field">
                  <div className="input-label">{t('Profit')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="value"
                    placeholder={t('Enter Profit Value')}
                    isCurrency
                    component={MTextField}
                  />
                </div>
              </div>

              <div className="dashboard-finance-form__form--action">
                <div className="dashboard-finance-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="dashboard-finance-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={t('Add')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default DashboardFinanceForm;
