import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataRouteEditAction,
  MasterDataRouteEditFailedPayload,
  MasterDataRouteEditFetchPayload,
  MasterDataRouteEditSuccessPayload,
} from './masterDataRouteEdit.type';

export const masterDataRouteEditAction = {
  fetch: createAction<MasterDataRouteEditFetchPayload>(
    MasterDataRouteEditAction.FETCH,
  ),
  success: createAction<MasterDataRouteEditSuccessPayload>(
    MasterDataRouteEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataRouteEditFailedPayload>(
    MasterDataRouteEditAction.FAILED,
  ),
  reset: createAction(MasterDataRouteEditAction.RESET),
};
