/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../../../utils';
// Components
import MasterDataAssignmentLetterManagementListTableHeader from '../MasterDataAssignmentLetterManagementListTableHeader';
import MasterDataAssignmentLetterManagementListTableItem from '../MasterDataAssignmentLetterManagementListTableItem';
// Data
import {
  MasterDataAssignmentLetter,
  MasterDataAssignmentLetterOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  assignmentLetterList: MasterDataAssignmentLetter[];
  sortBy: SortTable;
  orderBy: MasterDataAssignmentLetterOrderByType;
  onViewAssignmentLetter(_assignmentLetter: MasterDataAssignmentLetter): void;
  onEditAssignmentLetter?(_assignmentLetter: MasterDataAssignmentLetter): void;
  onDeleteAssignmentLetter?(
    _assignmentLetter: MasterDataAssignmentLetter,
  ): void;
  onActivateAssignmentLetter?(
    _assignmentLetter: MasterDataAssignmentLetter,
  ): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataAssignmentLetterOrderByType,
  ): void;
}
//#endregion

const MasterDataAssignmentLetterManagementList: React.FC<Props> = ({
  assignmentLetterList,
  sortBy,
  orderBy,
  onViewAssignmentLetter,
  onEditAssignmentLetter,
  onDeleteAssignmentLetter,
  onActivateAssignmentLetter,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: 'calc(70vh)'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataAssignmentLetterManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            assignmentLetterList as any[],
            getComparator(sortBy, orderBy),
          )
            .filter((item) => item != null)
            .map((item: any, index: number) => (
              <MasterDataAssignmentLetterManagementListTableItem
                onViewItem={() => onViewAssignmentLetter(item)}
                {...(onEditAssignmentLetter && {
                  onEditItem: () => onEditAssignmentLetter(item),
                })}
                {...(onDeleteAssignmentLetter && {
                  onDeleteItem: () => onDeleteAssignmentLetter(item),
                })}
                {...(onActivateAssignmentLetter && {
                  onActivateItem: () => onActivateAssignmentLetter(item),
                })}
                key={`master-data-assignment-letter-list-item-data-${item?.id}-${index}`}
                data={item}
                index={index + 1}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataAssignmentLetterManagementList;
