import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBusCreateAction,
  MasterDataBusCreateFailedPayload,
  MasterDataBusCreateFetchPayload,
  MasterDataBusCreateSuccessPayload,
} from './masterDataBusCreate.type';

export const masterDataBusCreateAction = {
  fetch: createAction<MasterDataBusCreateFetchPayload>(
    MasterDataBusCreateAction.FETCH,
  ),
  success: createAction<MasterDataBusCreateSuccessPayload>(
    MasterDataBusCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataBusCreateFailedPayload>(
    MasterDataBusCreateAction.FAILED,
  ),
  reset: createAction(MasterDataBusCreateAction.RESET),
};
