import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataObservationValueDeleteInitialState} from './masterDataObservationValueDelete.reducer';
import {
  MasterDataObservationValueDeleteAction,
  MasterDataObservationValueDeleteFailedPayload,
  MasterDataObservationValueDeleteFetchPayload,
  MasterDataObservationValueDeleteSuccessPayload,
} from './masterDataObservationValueDelete.type';

export const masterDataObservationValueDeleteHandler = {
  [MasterDataObservationValueDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueDeleteLoading: true,
    masterDataObservationValueDeleteParams: action.payload,
    action: MasterDataObservationValueDeleteAction.FETCH,
  }),
  [MasterDataObservationValueDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueDeleteParams: undefined,
    masterDataObservationValueDeleteLoading: false,
    masterDataObservationValueDeleteResponse: action.payload,
    action: MasterDataObservationValueDeleteAction.SUCCESS,
  }),
  [MasterDataObservationValueDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueDeleteLoading: false,
    masterDataObservationValueDeleteError: action.payload,
    action: MasterDataObservationValueDeleteAction.FAILED,
  }),
  [MasterDataObservationValueDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationValueDeleteInitialState,
    action: MasterDataObservationValueDeleteAction.RESET,
  }),
};
