import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {post} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {workReportUploadSupportAction} from './workReportUploadSupport.action';
import {
  WorkReportUploadSupportAction,
  WorkReportUploadSupportFetchPayload,
  WorkReportUploadSupportResponse,
  WorkReportUploadSupportSuccessPayload,
} from './workReportUploadSupport.type';

function* workerFetch(
  action: GenericAction<WorkReportUploadSupportFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {reportType, ...otherProps} = action.payload;

  try {
    const res: AxiosResponse<WorkReportUploadSupportSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/work-report/upload-file?reportType=${reportType}`,
      otherProps,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        workReportUploadSupportAction.success(
          res.data as WorkReportUploadSupportResponse,
        ),
      );
    } else {
      yield put(workReportUploadSupportAction.failed(res.data as CommonError));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(workReportUploadSupportAction.failed(e.response.data.payload));
  }
}

const workReportUploadSupportWatcher = [
  takeLatest(WorkReportUploadSupportAction.FETCH, workerFetch),
];

export default workReportUploadSupportWatcher;
