/* eslint-disable no-restricted-imports */
import React, {ReactElement} from 'react';
import moment from 'moment';
import _ from 'lodash';
import {
  NotificationBadgeDetail,
  NotificationBadgeType,
  NotificationManagement,
  NotificationProps,
  NotificationType,
} from '../entity/notification.string.entity';
import {
  IconChecklist,
  IconClose,
  IconComment,
  IconFollowUp,
  IconTimeCircle,
} from '../../../assets/icons';
import {colors} from '../../../config/_theme';

export const refactorByDate = (
  data: NotificationProps[],
): NotificationManagement[] => {
  const groupBy = (item: NotificationProps) =>
    moment(item.notifTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
  const temp = _.groupBy(data, groupBy);
  const result: NotificationManagement[] = Object.keys(temp).map(
    (date: string, index: number) => {
      return {
        id: `${date}-${index}`,
        date,
        notification: temp[date],
      };
    },
  );

  return result || [];
};

export const getNotificationIcon = (
  url?: string,
): React.ReactElement | undefined => {
  if (!url) return undefined;

  const icon: {[key: string]: ReactElement | undefined} = {
    [NotificationType.FOLLOWUP]: <IconFollowUp fill={colors.light} />,
    DEFAULT: undefined,
  };
  return icon[url] || icon.DEFAULT;
};

export const getNotificationBadge = (
  type?: string,
): NotificationBadgeDetail | undefined => {
  if (!type) return undefined;

  const icon: {[key: string]: NotificationBadgeDetail | undefined} = {
    [NotificationBadgeType.ACCEPTED]: {
      badge: <IconChecklist fill={colors.light} />,
      color: colors.secondary.green,
    },
    [NotificationBadgeType.REJECTED]: {
      badge: <IconClose fill={colors.light} />,
      color: colors.secondary.red,
    },
    [NotificationBadgeType.COMMENTED]: {
      badge: <IconComment fill={colors.light} />,
      color: colors.secondary.yellow,
    },
    [NotificationBadgeType.EXPIRED]: {
      badge: <IconTimeCircle fill={colors.light} />,
      color: colors.secondary.red,
    },
    DEFAULT: undefined,
  };
  return icon[type] || icon.DEFAULT;
};
