import {createAction} from '@reduxjs/toolkit';
import {
  UserLoginAction,
  UserLoginFailedPayload,
  UserLoginFetchPayload,
  UserLoginSuccessPayload,
} from './userLogin.type';

export const userLoginAction = {
  fetch: createAction<UserLoginFetchPayload>(UserLoginAction.FETCH),
  success: createAction<UserLoginSuccessPayload>(UserLoginAction.SUCCESS),
  failed: createAction<UserLoginFailedPayload>(UserLoginAction.FAILED),
  reset: createAction(UserLoginAction.RESET),
};
