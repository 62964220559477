import {
  WrapperNotifyAction,
  WrapperNotifyFetchPayload,
} from './wrapperNotify.type';
import {WrapperState} from '../wrapper.reducer';
import {GenericAction} from '../../../../config/constant';
import {wrapperNotifyInitialState} from './wrapperNotify.reducer';

export const wrapperNotifyHandler = {
  [WrapperNotifyAction.FETCH]: (
    state: WrapperState,
    action: GenericAction<WrapperNotifyFetchPayload>,
  ): WrapperState => {
    return {
      ...state,
      notification: action.payload,
      action: WrapperNotifyAction.FETCH,
    };
  },

  [WrapperNotifyAction.RESET]: (state: WrapperState): WrapperState => ({
    ...state,
    ...wrapperNotifyInitialState,
    action: WrapperNotifyAction.RESET,
  }),
};
