import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationEditAction,
  MasterDataObservationEditFailedPayload,
  MasterDataObservationEditFetchPayload,
  MasterDataObservationEditSuccessPayload,
} from './masterDataObservationEdit.type';

export const masterDataObservationEditAction = {
  fetch: createAction<MasterDataObservationEditFetchPayload>(
    MasterDataObservationEditAction.FETCH,
  ),
  success: createAction<MasterDataObservationEditSuccessPayload>(
    MasterDataObservationEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationEditFailedPayload>(
    MasterDataObservationEditAction.FAILED,
  ),
  reset: createAction(MasterDataObservationEditAction.RESET),
};
