import {CommonError} from '../../../../../../config/constant';
import {MasterDataObservationFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationEditAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_EDIT_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_EDIT_RESET',
};

export interface MasterDataObservationEditInitialState {
  masterDataObservationEditLoading: boolean;
  masterDataObservationEditParams?: MasterDataObservationEditFetchPayload;
  masterDataObservationEditError?: MasterDataObservationEditFailedPayload;
  masterDataObservationEditResponse?: MasterDataObservationEditSuccessPayload;
}

export type MasterDataObservationEditFetchPayload = MasterDataObservationFormData;

export type MasterDataObservationEditFailedPayload = CommonError;

export interface MasterDataObservationEditSuccessPayload {}
