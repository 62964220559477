import {
  WorkReportExportAllAction,
  WorkReportExportAllSuccessPayload,
  WorkReportExportAllFailedPayload,
  WorkReportExportAllFetchPayload,
} from './workReportExportAll.type';
import {WorkReportState} from '../workReport.reducer';
import {GenericAction} from '../../../../config/constant';
import {workReportExportAllInitialState} from './workReportExportAll.reducer';

export const workReportExportAllHandler = {
  [WorkReportExportAllAction.FETCH]: (
    state: WorkReportState,
    action: GenericAction<WorkReportExportAllFetchPayload>,
  ): WorkReportState => {
    return {
      ...state,
      workReportExportAllLoading: true,
      workReportExportAllParams: action.payload,
      action: WorkReportExportAllAction.FETCH,
    };
  },
  [WorkReportExportAllAction.SUCCESS]: (
    state: WorkReportState,
    action: GenericAction<WorkReportExportAllSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportExportAllLoading: false,
    workReportExportAllResponse: action.payload,
    action: WorkReportExportAllAction.SUCCESS,
  }),
  [WorkReportExportAllAction.FAILED]: (
    state: WorkReportState,
    action: GenericAction<WorkReportExportAllFailedPayload>,
  ): WorkReportState => ({
    ...state,
    workReportExportAllLoading: false,
    workReportExportAllError: action.payload,
    action: WorkReportExportAllAction.FAILED,
  }),
  [WorkReportExportAllAction.RESET]: (
    state: WorkReportState,
  ): WorkReportState => ({
    ...state,
    ...workReportExportAllInitialState,
    action: WorkReportExportAllAction.RESET,
  }),
};
