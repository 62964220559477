/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  MasterDataDepartmentCreateAction,
  MasterDataDepartmentDeleteAction,
  MasterDataDepartmentEditAction,
  MasterDataDepartmentListAction,
} from '../../../../usecases/masterData.type';
import {
  masterDataDepartmentCreateAction,
  masterDataDepartmentEditAction,
  masterDataDepartmentListAction,
} from '../../../../usecases/masterData.action';
import {masterDataDepartmentDeleteAction} from '../../../../usecases/masterData.action';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataDepartmentManagementList} from './components';
import MasterDataDepartmentFormCreateContainer from '../MasterDataDepartmentFormCreateContainer';
import MasterDataDepartmentFormEditContainer from '../MasterDataDepartmentFormEditContainer';
// Data
import {
  MasterDataDepartment,
  MasterDataDepartmentOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDepartmentSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

const MasterDataDepartmentSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddDepartment, setAddDepartment] = useState(false);
  const [isDeleteDepartment, setIsDeleteDepartment] = useState<
    MasterDataDepartment | undefined
  >(undefined);
  const [isEditDepartment, setIsEditDepartment] = useState<
    MasterDataDepartment | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddDepartment = masterData.masterDataDepartmentCreateLoading;
  const isLoadingEditDepartment = masterData.masterDataDepartmentEditLoading;
  const isLoadingDeleteDepartment =
    masterData.masterDataDepartmentDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataDepartmentListPage,
    setMasterDataDepartmentListPage,
  ] = useState<number>(0);

  const [
    masterDataDepartmentListCount,
    setMasterDataDepartmentListCount,
  ] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataDepartmentListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataDepartmentListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataDepartmentOrderByType>(
    'name',
  );

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataDepartmentOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH DEPARTMENT
  const fetchDepartmentList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(masterDataDepartmentListAction.fetch({page, search, size}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataDepartmentListAction.reset());
    fetchDepartmentList(masterDataDepartmentListPage, searchValue, rowsPerPage);

    return () => {
      dispatch(masterDataDepartmentListAction.reset());
    };
  }, [
    dispatch,
    fetchDepartmentList,
    masterDataDepartmentListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataDepartmentActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataDepartmentListAction.UPDATE, (): void => {
            setMasterDataDepartmentListCount(
              masterData?.masterDataDepartmentListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataDepartmentListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDepartmentListAction.reset());
          })
          .addCase(MasterDataDepartmentCreateAction.SUCCESS, (): void => {
            setAddDepartment(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDepartmentCreateAction.FAILED, (): void => {
            const errorMessage = masterData.masterDataDepartmentCreateError
              ?.info?.message
              ? t(masterData.masterDataDepartmentCreateError?.info?.message)
              : t('Data added failed!');
            dispatch(
              wrapperNotifyAction.fetch({
                text: errorMessage,
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDepartmentCreateAction.reset());
          })
          .addCase(MasterDataDepartmentEditAction.SUCCESS, (): void => {
            setIsEditDepartment(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDepartmentEditAction.FAILED, (): void => {
            const errorMessage = masterData.masterDataDepartmentEditError?.info
              ?.message
              ? t(masterData.masterDataDepartmentEditError?.info?.message)
              : t('Data saved failed!');
            dispatch(
              wrapperNotifyAction.fetch({
                text: errorMessage,
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDepartmentEditAction.reset());
          })
          .addCase(MasterDataDepartmentDeleteAction.SUCCESS, (): void => {
            setIsDeleteDepartment(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDepartmentDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDepartmentDeleteAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataDepartmentCreateError,
      masterData?.masterDataDepartmentEditError,
      masterData?.masterDataDepartmentListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataDepartmentActionHandler(masterData.action);
  }, [masterData.action, masterDataDepartmentActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataDepartmentDeleteAction.fetch({id}));
  };
  //#endregion

  return (
    <>
      <div className="master-data-department-section">
        <div className="master-data-department-section__header">
          <MSearchInput
            inputClassName="master-data-department-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Department')}
            setInputValue={(search: string) => {
              setMasterDataDepartmentListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterOrganization.havePermissionCreate && (
            <div className="master-data-department-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddDepartment(true)}
              />
            </div>
          )}
        </div>
        <MasterDataDepartmentManagementList
          departmentList={masterData.masterDataDepartmentListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          {...(permissions?.masterOrganization.havePermissionUpdate && {
            onEditDepartment: (_Department: MasterDataDepartment) =>
              setIsEditDepartment(_Department),
          })}
          {...(permissions?.masterOrganization.havePermissionDelete && {
            onDeleteDepartment: (_Department: MasterDataDepartment) =>
              setIsDeleteDepartment(_Department),
          })}
        />
        <MTablePagination
          className="master-data-department-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataDepartmentListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataDepartmentListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddDepartment(false)}
        title={t('Add Department')}
        isOpen={isAddDepartment}>
        <MasterDataDepartmentFormCreateContainer
          isLoading={isLoadingAddDepartment}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditDepartment && (
        <Modal
          onClose={() => setIsEditDepartment(undefined)}
          isOpen={!!isEditDepartment}
          title={t('Edit Department')}>
          <MasterDataDepartmentFormEditContainer
            data={isEditDepartment}
            isLoading={isLoadingEditDepartment}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteDepartment && (
        <AlertDialog
          onClose={() => setIsDeleteDepartment(undefined)}
          onCancel={() => setIsDeleteDepartment(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteDepartment.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteDepartment}
          isLoading={isLoadingDeleteDepartment}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddDepartment(false)
              : setIsEditDepartment(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataDepartmentSection;
