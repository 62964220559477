/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../utils';
// Components
import UserAccessManagementListTableHeader from '../UserAccessManagementListTableHeader';
import UserAccessManagementListTableItem from '../UserAccessManagementListTableItem';
// Data
import {
  User,
  UserOrderByType,
  UserType,
} from '../../../../entity/user.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  userAccessList: User[];
  typeUser: UserType;
  sortBy: SortTable;
  orderBy: UserOrderByType;
  onViewUser(_user: User): void;
  onEditUser(_user: User): void;
  onDeleteUser(_user: User): void;
  onActivateUser(_user: User): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: UserOrderByType,
  ): void;
}
//#endregion

const UserAccessManagementList: React.FC<Props> = ({
  userAccessList,
  typeUser,
  sortBy,
  orderBy,
  onViewUser,
  onEditUser,
  onDeleteUser,
  onActivateUser,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="user-access-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="user-access-management-list__container-table">
        <UserAccessManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          typeUser={typeUser}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            userAccessList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <UserAccessManagementListTableItem
              onViewItem={() => onViewUser(item)}
              onEditItem={() => onEditUser(item)}
              onDeleteItem={() => onDeleteUser(item)}
              onActivateItem={() => onActivateUser(item)}
              key={`order-list-item-data-${item.id}-${index}`}
              data={item}
              typeUser={typeUser}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UserAccessManagementList;
