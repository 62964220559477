import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userAccessDeleteInitialState} from './userAccessDelete.reducer';
import {
  UserAccessDeleteAction,
  UserAccessDeleteFailedPayload,
  UserAccessDeleteFetchPayload,
  UserAccessDeleteSuccessPayload,
} from './userAccessDelete.type';

export const userAccessDeleteHandler = {
  [UserAccessDeleteAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserAccessDeleteFetchPayload>,
  ): UserState => ({
    ...state,
    userAccessDeleteLoading: true,
    userAccessDeleteParams: action.payload,
    action: UserAccessDeleteAction.FETCH,
  }),
  [UserAccessDeleteAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserAccessDeleteSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccessDeleteParams: undefined,
    userAccessDeleteLoading: false,
    userAccessDeleteResponse: action.payload,
    action: UserAccessDeleteAction.SUCCESS,
  }),
  [UserAccessDeleteAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserAccessDeleteFailedPayload>,
  ): UserState => ({
    ...state,
    userAccessDeleteLoading: false,
    userAccessDeleteError: action.payload,
    action: UserAccessDeleteAction.FAILED,
  }),
  [UserAccessDeleteAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userAccessDeleteInitialState,
    action: UserAccessDeleteAction.RESET,
  }),
};
