import * as React from 'react';

const IconPaper = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      clipRule="evenodd"
      d="M14.737 2.762H8.084c-2.059 0-3.834 1.669-3.834 3.729v10.713c0 2.176 1.659 3.911 3.834 3.911h7.989c2.06 0 3.729-1.85 3.729-3.911V8.038l-5.065-5.276z"
      stroke={props.fill || '#BDBDBD'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.475 2.75v2.91a2.574 2.574 0 002.568 2.574c1.317.003 2.664.004 2.755-.002M14.285 15.558H8.888M12.243 10.606H8.887"
      stroke={props.fill || '#BDBDBD'}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconPaper;
