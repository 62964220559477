import {CommonError} from '../../../../../../config/constant';
import {MasterDataWorkInstruction} from '../../../../entity/masterData.string.entity';

export const MasterDataWorkInstructionListAction = {
  FETCH: 'MASTER_DATA_WORK_INSTRUCTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_WORK_INSTRUCTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_WORK_INSTRUCTION_LIST_FAILED',
  RESET: 'MASTER_DATA_WORK_INSTRUCTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_WORK_INSTRUCTION_LIST_UPDATE',
};

export interface MasterDataWorkInstructionListInitialState {
  masterDataWorkInstructionListLoading: boolean;
  masterDataWorkInstructionListData: MasterDataWorkInstruction[];
  masterDataWorkInstructionListError?: MasterDataWorkInstructionListFailedPayload;
  masterDataWorkInstructionListParams?: MasterDataWorkInstructionListFetchPayload;
  masterDataWorkInstructionListResponse?: MasterDataWorkInstructionListSuccessPayload;
}

export type MasterDataWorkInstructionListResponse = {
  status: string;
  count?: number;
  data: MasterDataWorkInstruction[];
};

export type MasterDataWorkInstructionListFailedPayload = CommonError;

export type MasterDataWorkInstructionListSuccessPayload = MasterDataWorkInstructionListResponse;

export type MasterDataWorkInstructionListUpdatePayload = MasterDataWorkInstructionListResponse;

export interface MasterDataWorkInstructionListFetchPayload {}
