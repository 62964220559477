//#region IMPORT
// Libraries
import React from 'react';
import Grid from '@mui/material/Grid';
// Components
import MasterDataWorkInstructionManagementListItem from '../MasterDataWorkInstructionManagementListItem';
// Data
import {MasterDataWorkInstruction} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  data: MasterDataWorkInstruction[];
  onClickItem(_workInstruction: MasterDataWorkInstruction): void;
}
//#endregion

const MasterDataWorkInstructionManagementList: React.FC<Props> = ({
  data,
  onClickItem,
}: Props) => {
  return (
    <div className="master-data-work-instruction-management-list">
      <Grid container spacing={3}>
        {data.map((item: MasterDataWorkInstruction, index: number) => {
          return (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              key={`master-data-work-instruction-${item.name}-${item.id}-${index}`}>
              <MasterDataWorkInstructionManagementListItem
                item={item}
                onClickItem={() => onClickItem(item)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default MasterDataWorkInstructionManagementList;
