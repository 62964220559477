import {CommonError} from '../../../../../../config/constant';

export const MasterDataDivisionDeleteAction = {
  FETCH: 'MASTER_DATA_DIVISION_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_DIVISION_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_DIVISION_DELETE_FAILED',
  RESET: 'MASTER_DATA_DIVISION_DELETE_RESET',
};

export interface MasterDataDivisionDeleteInitialState {
  masterDataDivisionDeleteLoading: boolean;
  masterDataDivisionDeleteParams?: MasterDataDivisionDeleteFetchPayload;
  masterDataDivisionDeleteError?: MasterDataDivisionDeleteFailedPayload;
  masterDataDivisionDeleteResponse?: MasterDataDivisionDeleteSuccessPayload;
}

export interface MasterDataDivisionDeleteFetchPayload {
  id: string;
}
export type MasterDataDivisionDeleteFailedPayload = CommonError;

export interface MasterDataDivisionDeleteSuccessPayload {}
