import {
  MasterDataDirectorateListAction,
  MasterDataDirectorateListSuccessPayload,
  MasterDataDirectorateListFailedPayload,
  MasterDataDirectorateListFetchPayload,
} from './masterDataDirectorateList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDirectorateListInitialState} from './masterDataDirectorateList.reducer';

export const masterDataDirectorateListHandler = {
  [MasterDataDirectorateListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDirectorateListLoading: true,
      masterDataDirectorateListParams: action.payload,
      action: MasterDataDirectorateListAction.FETCH,
    };
  },
  [MasterDataDirectorateListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateListLoading: false,
    masterDataDirectorateListResponse: action.payload,
    action: MasterDataDirectorateListAction.SUCCESS,
  }),
  [MasterDataDirectorateListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateListLoading: false,
    masterDataDirectorateListData: [
      ...state.masterDataDirectorateListData,
      ...action.payload.data.content,
    ],
    action: MasterDataDirectorateListAction.UPDATE,
  }),
  [MasterDataDirectorateListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateListLoading: false,
    masterDataDirectorateListError: action.payload,
    action: MasterDataDirectorateListAction.FAILED,
  }),
  [MasterDataDirectorateListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDirectorateListInitialState,
    action: MasterDataDirectorateListAction.RESET,
  }),
};
