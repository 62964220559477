import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataRouteEditInitialState} from './masterDataRouteEdit.reducer';
import {
  MasterDataRouteEditAction,
  MasterDataRouteEditFailedPayload,
  MasterDataRouteEditFetchPayload,
  MasterDataRouteEditSuccessPayload,
} from './masterDataRouteEdit.type';

export const masterDataRouteEditHandler = {
  [MasterDataRouteEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteEditLoading: true,
    masterDataRouteEditParams: action.payload,
    action: MasterDataRouteEditAction.FETCH,
  }),
  [MasterDataRouteEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteEditParams: undefined,
    masterDataRouteEditLoading: false,
    masterDataRouteEditResponse: action.payload,
    action: MasterDataRouteEditAction.SUCCESS,
  }),
  [MasterDataRouteEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteEditLoading: false,
    masterDataRouteEditError: action.payload,
    action: MasterDataRouteEditAction.FAILED,
  }),
  [MasterDataRouteEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataRouteEditInitialState,
    action: MasterDataRouteEditAction.RESET,
  }),
};
