//#region IMPORT
// PACKAGE IMPORT
import React from 'react';
import {Box, Toolbar} from '@mui/material';
import {shallowEqual, useSelector} from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {AppState} from '../../../../config/Interface';
import {DashboardManagementWrapper} from '../../../dashboard/views';
import {
  MasterDataManagementFormAssessment,
  MasterDataManagementOffice,
  MasterDataManagementWrapper,
  MasterDataWorkInstructionSection,
  MasterDataAssignmentLetterSection,
  MasterDataBusSection,
  MasterDataManagementOperational,
} from '../../../masterData/views';
import {
  UserLogin,
  UserAccount,
  UserChangePassword,
  UserForgetPassword,
  UserAccessManagement,
  UserAccessManagementRoleDetail,
  UserAccessManagementRoleFormContainer,
} from '../../../user/views';
import {NotificationManagementWrapper} from '../../../notification/views';
import {WorkReportManagementWrapper} from '../../../workReport/views';
import {
  EvaluationReportDetail,
  EvaluationReportManagementWrapper,
} from '../../../evaluationReport/views';
import FollowUpWrapper from '../../../followUp/views/FollowUpWrapper';
import Navigation from '../../components/Navigation';
import Sidebar from '../../components/Sidebar';
import {PATH} from '../../entity';
import Alert from '../Alert';
import Notification from '../Notification';

// COMPONENT IMPORT
import './Wrapper.scss';
//#endregion

//#region COMPONENT
const Wrapper: React.FC = () => {
  //#region GENERAL
  const {isLogin, isFirstLogin} = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  //#endregion

  const noLogin = () => {
    return (
      <Switch>
        <Route
          exact
          path={PATH.FORGET_PASSWORD}
          component={UserForgetPassword}
        />
        <Route exact path={PATH.LOGIN} component={UserLogin} />
        <Route path={PATH.CHANGE_PASSWORD} component={UserChangePassword} />
        <Redirect to={PATH.LOGIN} />
      </Switch>
    );
  };

  const firstLogin = () => {
    return (
      <Switch>
        <Route
          exact
          path={PATH.CHANGE_PASSWORD}
          component={UserChangePassword}
        />
        <Redirect to={PATH.CHANGE_PASSWORD} />
      </Switch>
    );
  };

  const isLogged = () => {
    return (
      <Switch>
        <>
          <Notification />
          <div className="wrapper-container">
            <div className="wrapper-container__content">
              <div className="wrapper-container__content--sidebar active">
                <Sidebar />
              </div>
              <div className="wrapper-container__content--page">
                <div className="wrapper-container__navigation">
                  <Navigation />
                </div>
                <Toolbar />
                <Box>
                  <Switch>
                    <Switch>
                      <Route
                        exact
                        path={PATH.HOME}
                        component={DashboardManagementWrapper}
                      />

                      <Route
                        exact
                        path={PATH.TINDAK_LANJUT}
                        component={FollowUpWrapper}
                      />

                      <Route
                        exact
                        path={PATH.MASTER_DATA}
                        component={MasterDataManagementWrapper}
                      />
                      <Route
                        exact
                        path={PATH.MASTER_DATA_FORM_ASSESSMENT}
                        component={MasterDataManagementFormAssessment}
                      />
                      <Route
                        exact
                        path={PATH.MASTER_DATA_OFFICE}
                        component={MasterDataManagementOffice}
                      />
                      <Route
                        exact
                        path={PATH.MASTER_DATA_OFFICE_WORK_INSTRUCTION}
                        component={MasterDataWorkInstructionSection}
                      />
                      <Route
                        exact
                        path={PATH.MASTER_DATA_OFFICE_ASSIGNMENT_LETTER}
                        component={MasterDataAssignmentLetterSection}
                      />
                      <Route
                        exact
                        path={PATH.MASTER_DATA_OPERATIONAL_RUTE}
                        component={MasterDataManagementOperational}
                      />
                      <Route
                        exact
                        path={PATH.MASTER_DATA_OPERATIONAL_BUS}
                        component={MasterDataBusSection}
                      />

                      <Route
                        exact
                        path={PATH.USER_ACCOUNT}
                        component={UserAccount}
                      />
                      <Route
                        exact
                        path={PATH.USER_ACCESS}
                        component={UserAccessManagement}
                      />
                      <Route
                        exact
                        path={PATH.USER_ACCESS_CREATE_ROLE}
                        component={UserAccessManagementRoleFormContainer}
                      />
                      <Route
                        exact
                        path={PATH.USER_ACCESS_EDIT_ROLE}
                        component={UserAccessManagementRoleFormContainer}
                      />
                      <Route
                        exact
                        path={PATH.USER_ACCESS_DETAIL_ROLE}
                        component={UserAccessManagementRoleDetail}
                      />

                      <Route
                        exact
                        path={PATH.NOTIFICATION}
                        component={NotificationManagementWrapper}
                      />

                      <Route
                        exact
                        path={PATH.LAPORAN_KERJA}
                        component={WorkReportManagementWrapper}
                      />

                      <Route
                        exact
                        path={PATH.LAPORAN_PENILAIAN}
                        component={EvaluationReportManagementWrapper}
                      />
                      <Route
                        exact
                        path={PATH.LAPORAN_PENILAIAN_DETAIL}
                        component={EvaluationReportDetail}
                      />

                      <Redirect to={PATH.HOME} />
                    </Switch>
                    <Redirect to={PATH.HOME} />
                  </Switch>
                </Box>
              </div>
            </div>
          </div>
        </>
      </Switch>
    );
  };

  const checkLogin = () => {
    if (isLogin) {
      if (isFirstLogin) {
        return firstLogin();
      }
      return isLogged();
    }
    return noLogin();
  };

  return (
    <div>
      <Alert />
      <Router>{checkLogin()}</Router>
    </div>
  );
};
//#endregion
export default Wrapper;
