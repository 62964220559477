import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userAccessCreateInitialState} from './userAccessCreate.reducer';
import {
  UserAccessCreateAction,
  UserAccessCreateFailedPayload,
  UserAccessCreateFetchPayload,
  UserAccessCreateSuccessPayload,
} from './userAccessCreate.type';

export const userAccessCreateHandler = {
  [UserAccessCreateAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserAccessCreateFetchPayload>,
  ): UserState => ({
    ...state,
    userAccessCreateLoading: true,
    userAccessCreateParams: action.payload,
    action: UserAccessCreateAction.FETCH,
  }),
  [UserAccessCreateAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserAccessCreateSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccessCreateParams: undefined,
    userAccessCreateLoading: false,
    userAccessCreateResponse: action.payload,
    action: UserAccessCreateAction.SUCCESS,
  }),
  [UserAccessCreateAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserAccessCreateFailedPayload>,
  ): UserState => ({
    ...state,
    userAccessCreateLoading: false,
    userAccessCreateError: action.payload,
    action: UserAccessCreateAction.FAILED,
  }),
  [UserAccessCreateAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userAccessCreateInitialState,
    action: UserAccessCreateAction.RESET,
  }),
};
