import {createAction} from '@reduxjs/toolkit';
import {
  DashboardOverviewExportAchievementFinalMonthlyAction,
  DashboardOverviewExportAchievementFinalMonthlyFailedPayload,
  DashboardOverviewExportAchievementFinalMonthlyFetchPayload,
  DashboardOverviewExportAchievementFinalMonthlySuccessPayload,
} from './dashboardOverviewExportAchievementFinalMonthly.type';

export const dashboardOverviewExportAchievementFinalMonthlyAction = {
  fetch: createAction<DashboardOverviewExportAchievementFinalMonthlyFetchPayload>(
    DashboardOverviewExportAchievementFinalMonthlyAction.FETCH,
  ),
  success: createAction<DashboardOverviewExportAchievementFinalMonthlySuccessPayload>(
    DashboardOverviewExportAchievementFinalMonthlyAction.SUCCESS,
  ),
  failed: createAction<DashboardOverviewExportAchievementFinalMonthlyFailedPayload>(
    DashboardOverviewExportAchievementFinalMonthlyAction.FAILED,
  ),
  reset: createAction(
    DashboardOverviewExportAchievementFinalMonthlyAction.RESET,
  ),
};
