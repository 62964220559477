import {
  FollowUpKadepListAction,
  FollowUpKadepListSuccessPayload,
  FollowUpKadepListFailedPayload,
  FollowUpKadepListFetchPayload,
} from './followUpKadepList.type';
import {FollowUpState} from '../followUp.reducer';
import {GenericAction} from '../../../../config/constant';
import {followUpKadepListInitialState} from './followUpKadepList.reducer';

export const followUpKadepListHandler = {
  [FollowUpKadepListAction.FETCH]: (
    state: FollowUpState,
    action: GenericAction<FollowUpKadepListFetchPayload>,
  ): FollowUpState => {
    return {
      ...state,
      followUpKadepListLoading: true,
      followUpKadepListParams: action.payload,
      action: FollowUpKadepListAction.FETCH,
    };
  },
  [FollowUpKadepListAction.SUCCESS]: (
    state: FollowUpState,
    action: GenericAction<FollowUpKadepListSuccessPayload>,
  ): FollowUpState => ({
    ...state,
    followUpKadepListLoading: false,
    followUpKadepListResponse: action.payload,
    action: FollowUpKadepListAction.SUCCESS,
  }),
  [FollowUpKadepListAction.UPDATE]: (
    state: FollowUpState,
    action: GenericAction<FollowUpKadepListSuccessPayload>,
  ): FollowUpState => ({
    ...state,
    followUpKadepListLoading: false,
    followUpKadepListData: [
      ...state.followUpKadepListData,
      ...action.payload.data.content,
    ],
    action: FollowUpKadepListAction.UPDATE,
  }),
  [FollowUpKadepListAction.FAILED]: (
    state: FollowUpState,
    action: GenericAction<FollowUpKadepListFailedPayload>,
  ): FollowUpState => ({
    ...state,
    followUpKadepListLoading: false,
    followUpKadepListError: action.payload,
    action: FollowUpKadepListAction.FAILED,
  }),
  [FollowUpKadepListAction.RESET]: (state: FollowUpState): FollowUpState => ({
    ...state,
    ...followUpKadepListInitialState,
    action: FollowUpKadepListAction.RESET,
  }),
};
