import {CommonError} from '../../../../../../config/constant';
import {MasterDataDetailIndicatorFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailIndicatorCreateAction = {
  FETCH: 'MASTER_DATA_DETAIL_INDICATOR_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_INDICATOR_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_INDICATOR_CREATE_FAILED',
  RESET: 'MASTER_DATA_DETAIL_INDICATOR_CREATE_RESET',
};

export interface MasterDataDetailIndicatorCreateInitialState {
  masterDataDetailIndicatorCreateLoading: boolean;
  masterDataDetailIndicatorCreateParams?: MasterDataDetailIndicatorCreateFetchPayload;
  masterDataDetailIndicatorCreateError?: MasterDataDetailIndicatorCreateFailedPayload;
  masterDataDetailIndicatorCreateResponse?: MasterDataDetailIndicatorCreateSuccessPayload;
}

export type MasterDataDetailIndicatorCreateFetchPayload = Omit<
  MasterDataDetailIndicatorFormData,
  'id'
>[];

export type MasterDataDetailIndicatorCreateFailedPayload = CommonError;

export interface MasterDataDetailIndicatorCreateSuccessPayload {}
