import {createAction} from '@reduxjs/toolkit';
import {
  UserAccessSetActiveAction,
  UserAccessSetActiveFailedPayload,
  UserAccessSetActiveFetchPayload,
  UserAccessSetActiveSuccessPayload,
} from './userAccessSetActive.type';

export const userAccessSetActiveAction = {
  fetch: createAction<UserAccessSetActiveFetchPayload>(
    UserAccessSetActiveAction.FETCH,
  ),
  success: createAction<UserAccessSetActiveSuccessPayload>(
    UserAccessSetActiveAction.SUCCESS,
  ),
  failed: createAction<UserAccessSetActiveFailedPayload>(
    UserAccessSetActiveAction.FAILED,
  ),
  reset: createAction(UserAccessSetActiveAction.RESET),
};
