import {CommonError} from '../../../../config/constant';

export const UserAccessSetActiveAction = {
  FETCH: 'USER_ACCESS_SET_ACTIVE_FETCH',
  SUCCESS: 'USER_ACCESS_SET_ACTIVE_SUCCESS',
  FAILED: 'USER_ACCESS_SET_ACTIVE_FAILED',
  RESET: 'USER_ACCESS_SET_ACTIVE_RESET',
};

export interface UserAccessSetActiveInitialState {
  userAccessSetActiveLoading: boolean;
  userAccessSetActiveParams?: UserAccessSetActiveFetchPayload;
  userAccessSetActiveError?: UserAccessSetActiveFailedPayload;
  userAccessSetActiveResponse?: UserAccessSetActiveSuccessPayload;
}

export type UserAccessSetActiveFetchPayload = {id: string};
export type UserAccessSetActiveFailedPayload = CommonError;

export interface UserAccessSetActiveSuccessPayload {}
