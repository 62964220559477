import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataDetailIndicator} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailIndicatorListAction = {
  FETCH: 'MASTER_DATA_DETAIL_INDICATOR_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_INDICATOR_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_INDICATOR_LIST_FAILED',
  RESET: 'MASTER_DATA_DETAIL_INDICATOR_LIST_RESET',
  UPDATE: 'MASTER_DATA_DETAIL_INDICATOR_LIST_UPDATE',
};

export interface MasterDataDetailIndicatorListInitialState {
  masterDataDetailIndicatorListLoading: boolean;
  masterDataDetailIndicatorListData: MasterDataDetailIndicator[];
  masterDataDetailIndicatorListError?: MasterDataDetailIndicatorListFailedPayload;
  masterDataDetailIndicatorListParams?: MasterDataDetailIndicatorListFetchPayload;
  masterDataDetailIndicatorListResponse?: MasterDataDetailIndicatorListSuccessPayload;
}

export type MasterDataDetailIndicatorListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataDetailIndicator[]>;
};

export type MasterDataDetailIndicatorListFailedPayload = CommonError;

export type MasterDataDetailIndicatorListSuccessPayload = MasterDataDetailIndicatorListResponse;

export type MasterDataDetailIndicatorListUpdatePayload = MasterDataDetailIndicatorListResponse;

export interface MasterDataDetailIndicatorListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
