import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataCategoryCreateAction,
  MasterDataCategoryCreateFailedPayload,
  MasterDataCategoryCreateFetchPayload,
  MasterDataCategoryCreateSuccessPayload,
} from './masterDataCategoryCreate.type';

export const masterDataCategoryCreateAction = {
  fetch: createAction<MasterDataCategoryCreateFetchPayload>(
    MasterDataCategoryCreateAction.FETCH,
  ),
  success: createAction<MasterDataCategoryCreateSuccessPayload>(
    MasterDataCategoryCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataCategoryCreateFailedPayload>(
    MasterDataCategoryCreateAction.FAILED,
  ),
  reset: createAction(MasterDataCategoryCreateAction.RESET),
};
