import * as React from 'react';

const IconFollowUp = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.83 5.693h-.216C16.584 3.1 14.525 1 11.985 1c-1.27.031-2.396.483-3.277 1.375a4.795 4.795 0 00-1.372 3.318H7.12C4.294 5.693 2 8.056 2 10.953v5.797C2 19.648 4.294 22 7.12 22h9.71c2.826 0 5.12-2.352 5.12-5.25v-5.796c0-2.898-2.294-5.26-5.12-5.26zm-7.958 0c.02-.83.338-1.606.911-2.194a3.147 3.147 0 012.202-.924c1.69 0 3.062 1.396 3.093 3.118H8.872zm7.763 3.255c0 .43-.338.787-.768.787a.784.784 0 01-.768-.787V7.73c0-.43.348-.788.768-.788.43 0 .768.357.768.788v1.218zm-8.645.788c.43 0 .768-.357.768-.788V7.73a.777.777 0 00-.768-.787.784.784 0 00-.768.787v1.218c0 .431.348.788.768.788zm-.352 8.03a2.44 2.44 0 01-1.332-1.112.346.346 0 01.003-.342.338.338 0 01.298-.166l2.045.033.008-1.333-2.06-.035a.346.346 0 01-.297-.514 2.366 2.366 0 012.179-1.204 2.439 2.439 0 012.088 1.397l5.9-.003a1.08 1.08 0 01.778.304 1.09 1.09 0 01.332.768 1.065 1.065 0 01-.669.998 1.068 1.068 0 01-.417.075l-5.97.003a2.366 2.366 0 01-1.772 1.24 2.409 2.409 0 01-1.114-.108z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconFollowUp;
