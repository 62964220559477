/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  MasterDataObservationCreateAction,
  MasterDataObservationDeleteAction,
  MasterDataObservationEditAction,
  MasterDataObservationListAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';

import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {
  masterDataObservationCreateAction,
  masterDataObservationDeleteAction,
  masterDataObservationEditAction,
  masterDataObservationListAction,
} from '../../../../usecases/masterData.action';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import MasterDataObservationFormCreateContainer from '../MasterDataObservationFormCreateContainer';
import MasterDataObservationFormEditContainer from '../MasterDataObservationFormEditContainer';
import {ObservationManagementList} from './components';
// Data
import {
  MasterDataObservation,
  MasterDataObservationOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataObservationSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataObservationSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddObservation, setAddObservation] = useState(false);
  const [isDeleteObservation, setIsDeleteObservation] = useState<
    MasterDataObservation | undefined
  >(undefined);
  const [isEditObservation, setIsEditObservation] = useState<
    MasterDataObservation | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddObservation = masterData.masterDataObservationCreateLoading;
  const isLoadingEditObservation = masterData.masterDataObservationEditLoading;
  const isLoadingDeleteObservation =
    masterData.masterDataObservationDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataObservationListPage,
    setMasterDataObservationListPage,
  ] = useState<number>(0);
  const [
    masterDataObservationListCount,
    setMasterDataObservationListCount,
  ] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataObservationListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataObservationListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataObservationOrderByType>(
    'name',
  );

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataObservationOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH OBSERVATION
  const fetchObservationList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataObservationListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataObservationListAction.reset());
    fetchObservationList(
      masterDataObservationListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataObservationListAction.reset());
    };
  }, [
    dispatch,
    fetchObservationList,
    masterDataObservationListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataObservationActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataObservationListAction.UPDATE, (): void => {
            setMasterDataObservationListCount(
              masterData?.masterDataObservationListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataObservationListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationListAction.reset());
          })
          .addCase(MasterDataObservationCreateAction.SUCCESS, (): void => {
            setAddObservation(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataObservationCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationCreateAction.reset());
          })
          .addCase(MasterDataObservationEditAction.SUCCESS, (): void => {
            setIsEditObservation(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataObservationEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationEditAction.reset());
          })
          .addCase(MasterDataObservationDeleteAction.SUCCESS, (): void => {
            setIsDeleteObservation(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataObservationDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataObservationDeleteAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataObservationListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataObservationActionHandler(masterData.action);
  }, [masterData.action, masterDataObservationActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataObservationDeleteAction.fetch({id}));
  };
  //#endregion

  return (
    <>
      <div className="master-data-observation-section">
        <div className="master-data-observation-section__header">
          <MSearchInput
            inputClassName="master-data-observation-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Observation')}
            setInputValue={(search: string) => {
              setMasterDataObservationListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-observation-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddObservation(true)}
              />
            </div>
          )}
        </div>
        <ObservationManagementList
          observationList={masterData.masterDataObservationListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditObservation: (_observation: MasterDataObservation) =>
              setIsEditObservation(_observation),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteObservation: (_observation: MasterDataObservation) =>
              setIsDeleteObservation(_observation),
          })}
        />
        <MTablePagination
          className="master-data-observation-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataObservationListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataObservationListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddObservation(false)}
        title={t('Add Observation')}
        isOpen={isAddObservation}>
        <MasterDataObservationFormCreateContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddObservation}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditObservation && (
        <Modal
          onClose={() => setIsEditObservation(undefined)}
          isOpen={!!isEditObservation}
          title={t('Edit Observation')}>
          <MasterDataObservationFormEditContainer
            assignmentType={assignmentType}
            data={isEditObservation}
            isLoading={isLoadingEditObservation}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteObservation && (
        <AlertDialog
          onClose={() => setIsDeleteObservation(undefined)}
          onCancel={() => setIsDeleteObservation(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteObservation.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteObservation}
          isLoading={isLoadingDeleteObservation}
          image={<IllustrationTrashImage />}
          description={t(
            'Are you sure you want to delete the data?',
          )}></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddObservation(false)
              : setIsEditObservation(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataObservationSection;
