/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
import {BarDatum} from '@nivo/bar';
import moment, {Moment} from 'moment';
// Utils
import t from '../../../../../lang';
import {colors} from '../../../../../config/_theme';
import {AppState} from '../../../../../config/Interface';
import {DashboardState} from '../../../usecases/dashboard.reducer';
import {dashboardSPMChartSectionAction} from '../../../usecases/dashboard.action';
import {DashboardSPMChartSectionAction} from '../../../usecases/dashboard.type';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {Modal, MMonthpicker, MYearpicker} from '../../../../../components';
import {
  DashboardBarChart,
  DashboardPieChart,
  DashboardServiceType,
  DashboardTooltip,
} from '../../../components';
// Data
import {
  DashboardServiceData,
  DASHBOARDSERVICETYPE,
  DashboardSPMAchievementAndTargetObservationSPM,
  DashboardSPMAchievementObservationSPM,
  DashboardTableListItem,
  PieRawDatum,
} from '../../../entity/dashboard.string.entity';
// Assets
import './DashboardSPMSection.scss';
import {
  IconBusBRT,
  IconBusKecil,
  IconBusNonBRT,
  IconHalte,
  IconTranscare,
} from '../../../../../assets/icons';
//#endregion

const DashboardSPMSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();

  const dashboard: DashboardState = useSelector(
    (state: AppState) => state.dashboard,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  //#region GENERAL YEAR FILTER
  const [selectedYearFilter, setSelectedYearFilter] = useState<
    Moment | string | null
  >(moment().year(new Date().getFullYear()));
  //#endregion
  //#region GENERAL MONTH FILTER
  const [selectedMonthFilter, setSelectedMonthFilter] = useState<
    Moment | string | null
  >(moment().month(new Date().getMonth()));
  //#endregion

  //#region TOTAL SERVICE TYPE CHECKED
  const [totalServiceTypeChecked, setTotalServiceTypeChecked] = useState<
    DashboardServiceData[]
  >([
    {
      id: DASHBOARDSERVICETYPE.HALTE,
      name: DASHBOARDSERVICETYPE.HALTE,
      icon: <IconHalte />,
      value: 0,
      backgroundColor: colors.secondary.green1,
    },
    {
      id: DASHBOARDSERVICETYPE.BUSBRT,
      name: DASHBOARDSERVICETYPE.BUSBRT,
      icon: <IconBusBRT />,
      value: 0,
      backgroundColor: colors.primary.blue2,
    },
    {
      id: DASHBOARDSERVICETYPE.BUSNONBRT,
      name: DASHBOARDSERVICETYPE.BUSNONBRT,
      icon: <IconBusNonBRT />,
      value: 0,
      backgroundColor: colors.secondary.yellow1,
    },
    {
      id: DASHBOARDSERVICETYPE.BUSKECIL,
      name: DASHBOARDSERVICETYPE.BUSKECIL,
      icon: <IconBusKecil />,
      value: 0,
      backgroundColor: colors.primary.blue9,
    },
    {
      id: DASHBOARDSERVICETYPE.TRANSCARE,
      name: DASHBOARDSERVICETYPE.TRANSCARE,
      icon: <IconTranscare />,
      value: 0,
      backgroundColor: colors.secondary.red1,
    },
  ]);
  //#endregion
  //#region FINDINGS
  const [pieFindings, setPieFindings] = useState<PieRawDatum[]>([
    {
      id: 'finished',
      label: t('Finished'),
      value: 0,
      color: colors.primary.blue,
    },
    {
      id: 'unfinished',
      label: t('Unfinished'),
      value: 0,
      color: colors.primary.blue4,
    },
  ]);
  const [pieFindingsShowDetail, setPieFindingsShowDetail] = useState<boolean>(
    false,
  );
  //#endregion
  //#region ACHIEVEMENT OBSERVATION SPM
  const [
    pieAchievementObservationSPM,
    setPieAchievementObservationSPM,
  ] = useState<PieRawDatum[]>([
    {
      id: 'achieved',
      label: t('Achieved'),
      value: 0,
      color: colors.primary.blue,
    },
    {
      id: 'not Achieved',
      label: t('Not Achieved'),
      value: 100,
      color: colors.text.lightGrey2,
    },
  ]);
  const [
    pieAchievementObservationSPMShowDetail,
    setPieAchievementObservationSPMShowDetail,
  ] = useState<boolean>(false);
  //#endregion
  //#region INTERNAL ACHIEVEMENT
  const [pieInternalAchievement, setPieInternalAchievement] = useState<
    PieRawDatum[]
  >([
    {
      id: 'achieved',
      label: t('Achieved'),
      value: 0,
      color: colors.primary.blue,
    },
    {
      id: 'not Achieved',
      label: t('Not Achieved'),
      value: 100,
      color: colors.text.lightGrey2,
    },
  ]);
  const [
    pieInternalAchievementShowDetail,
    setPieInternalAchievementShowDetail,
  ] = useState<boolean>(false);
  //#endregion
  //#region ACHIEVEMENT OBSERVATION SPM SERVICE
  const [
    barAchievementObservationSPMService,
    setBarAchievementObservationSPMService,
  ] = useState<BarDatum[]>([]);
  const [
    barAchievementObservationSPMServiceShowDetail,
    setBarAchievementObservationSPMServiceShowDetail,
  ] = useState<boolean>(false);
  //#endregion
  //#region ACHIEVEMENT INTERNAL SPM SERVICE
  const [
    barAchievementInternalSPMService,
    setBarAchievementInternalSPMService,
  ] = useState<BarDatum[]>([]);
  const [
    barAchievementInternalSPMServiceShowDetail,
    setBarAchievementInternalSPMServiceShowDetail,
  ] = useState<boolean>(false);
  //#endregion
  //#region ACHIEVEMENT AND TARGET OBSERVATION SPM
  const [
    barAchievementAndTargetObservationSPM,
    setBarAchievementAndTargetObservationSPM,
  ] = useState<BarDatum[]>([]);
  const [
    barAchievementAndTargetObservationSPMShowDetail,
    setBarAchievementAndTargetObservationSPMShowDetail,
  ] = useState<boolean>(false);
  //#endregion
  //#region ACHIEVEMENT INTERNAL SPM DEPARTMENT
  const [
    barAchievementInternalSPMDepartment,
    setBarAchievementInternalSPMDepartment,
  ] = useState<BarDatum[]>([]);
  const [
    barAchievementInternalSPMDepartmentShowDetail,
    setBarAchievementInternalSPMDepartmentShowDetail,
  ] = useState<boolean>(false);
  //#endregion
  //#endregion

  //#region FETCH SPM CHART
  const fetchSPMChart = useCallback(
    (year: number, month: number) => {
      dispatch(dashboardSPMChartSectionAction.fetch({year, month}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(dashboardSPMChartSectionAction.reset());
    fetchSPMChart(
      Number((selectedYearFilter as Moment).format('YYYY')),
      Number((selectedMonthFilter as Moment).format('M')),
    );
    return () => {
      dispatch(dashboardSPMChartSectionAction.reset());
    };
  }, [dispatch, fetchSPMChart, selectedYearFilter, selectedMonthFilter]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const dashboardManagementWrapperActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(DashboardSPMChartSectionAction.SUCCESS, (): void => {
            if (dashboard?.dashboardSPMChartSectionResponse?.data) {
              //#region TOTAL SERVICE TYPE CHECKED
              const totalServiceTypeCheckedTemp =
                dashboard.dashboardSPMChartSectionResponse.data
                  .totalServiceTypeChecked;
              const formattedTotalServiceTypeChecked: DashboardServiceData[] = [
                {
                  id: DASHBOARDSERVICETYPE.HALTE,
                  name: DASHBOARDSERVICETYPE.HALTE,
                  icon: <IconHalte />,
                  value: totalServiceTypeCheckedTemp?.halte ?? 0,
                  backgroundColor: colors.secondary.green1,
                },
                {
                  id: DASHBOARDSERVICETYPE.BUSBRT,
                  name: DASHBOARDSERVICETYPE.BUSBRT,
                  icon: <IconBusBRT />,
                  value: totalServiceTypeCheckedTemp?.brt ?? 0,
                  backgroundColor: colors.primary.blue2,
                },
                {
                  id: DASHBOARDSERVICETYPE.BUSNONBRT,
                  name: DASHBOARDSERVICETYPE.BUSNONBRT,
                  icon: <IconBusNonBRT />,
                  value: totalServiceTypeCheckedTemp?.nonbrt ?? 0,
                  backgroundColor: colors.secondary.yellow1,
                },
                {
                  id: DASHBOARDSERVICETYPE.BUSKECIL,
                  name: DASHBOARDSERVICETYPE.BUSKECIL,
                  icon: <IconBusKecil />,
                  value: totalServiceTypeCheckedTemp?.ibk ?? 0,
                  backgroundColor: colors.primary.blue9,
                },
                {
                  id: DASHBOARDSERVICETYPE.TRANSCARE,
                  name: DASHBOARDSERVICETYPE.TRANSCARE,
                  icon: <IconTranscare />,
                  value: totalServiceTypeCheckedTemp?.transcare ?? 0,
                  backgroundColor: colors.secondary.red1,
                },
              ];
              setTotalServiceTypeChecked(formattedTotalServiceTypeChecked);
              //#endregion
              //#region FINDINGS
              const pieFindingsDataTemp =
                dashboard.dashboardSPMChartSectionResponse.data.finding;
              const formattedPieFindings: PieRawDatum[] = [
                {
                  id: 'finished',
                  label: t('Finished'),
                  value: pieFindingsDataTemp?.finished,
                  color: colors.primary.blue,
                },
                {
                  id: 'unfinished',
                  label: t('Unfinished'),
                  value: pieFindingsDataTemp?.unFinished,
                  color: colors.primary.blue4,
                },
              ];
              setPieFindings(formattedPieFindings);
              //#endregion
              //#region ACHIEVEMENT OBSERVATION SPM
              const pieAchievementObservationSPMTemp =
                Number(
                  dashboard?.dashboardSPMChartSectionResponse?.data
                    .achievementObservationSPM.percentage,
                ) ?? 0;
              const formattedPieAchievementObservationSPM = [
                {
                  id: 'achieved',
                  label: t('Achieved'),
                  value: Number(pieAchievementObservationSPMTemp.toFixed(2)),
                  color: colors.primary.blue,
                },
                {
                  id: 'not Achieved',
                  label: t('Not Achieved'),
                  value: Number(
                    (100 - pieAchievementObservationSPMTemp).toFixed(2),
                  ),
                  color: colors.text.lightGrey2,
                },
              ];
              setPieAchievementObservationSPM(
                formattedPieAchievementObservationSPM,
              );
              //#endregion
              //#region INTERNAL ACHIEVEMENT
              const pieInternalAchievementTemp =
                Number(
                  dashboard?.dashboardSPMChartSectionResponse?.data
                    .achievementInternal.percentage,
                ) ?? 0;
              const formattedPieInternalAchievement = [
                {
                  id: 'achieved',
                  label: t('Achieved'),
                  value: Number(pieInternalAchievementTemp.toFixed(2)),
                  color: colors.primary.blue,
                },
                {
                  id: 'not Achieved',
                  label: t('Not Achieved'),
                  value: Number(
                    (100 - pieInternalAchievementTemp).toPrecision(2),
                  ),
                  color: colors.text.lightGrey2,
                },
              ];
              setPieInternalAchievement(formattedPieInternalAchievement);
              //#endregion
              //#region ACHIEVEMENT OBSERVATION SPM SERVICE
              const barAchievementObservationSPMServiceTemp =
                dashboard?.dashboardSPMChartSectionResponse?.data
                  .achievementObservationSPMService;
              const formattedBarAchievementObservationSPMService: BarDatum[] = barAchievementObservationSPMServiceTemp.map(
                (
                  item: DashboardSPMAchievementObservationSPM,
                  index: number,
                ) => {
                  const name: DASHBOARDSERVICETYPE =
                    DASHBOARDSERVICETYPE[
                      item.name as keyof typeof DASHBOARDSERVICETYPE
                    ];
                  return {
                    id: `${name || ''}-${index}`,
                    label: name || '',
                    rate: Number(item.percent).toFixed(2) ?? 0,
                    category: JSON.stringify(item.category),
                    color: colors.primary.blue,
                  };
                },
              );
              setBarAchievementObservationSPMService(
                formattedBarAchievementObservationSPMService,
              );
              //#endregion
              //#region ACHIEVEMENT INTERNAL SPM SERVICE
              const barAchievemenInternalSPMServiceTemp =
                dashboard?.dashboardSPMChartSectionResponse?.data
                  .achievementInternalSPMService;
              const formattedBarAchievementInternalSPMService: BarDatum[] = barAchievemenInternalSPMServiceTemp.map(
                (
                  item: DashboardSPMAchievementObservationSPM,
                  index: number,
                ) => {
                  return {
                    id: `${item.name || ''}-${index}`,
                    label: item.name || '',
                    rate: Number(item.percent).toFixed(2) ?? 0,
                    category: JSON.stringify(item.category),
                    color: colors.primary.blue,
                  };
                },
              );
              setBarAchievementInternalSPMService(
                formattedBarAchievementInternalSPMService,
              );
              //#endregion
              //#region ACHIEVEMENT AND TARGET OBSERVATION SPM
              const barAchievementAndTargetObservationSPMTemp =
                dashboard?.dashboardSPMChartSectionResponse?.data
                  .achievementAndTargetObservationSPM;
              const formattedBarAchievementAndTargetObservationSPM: BarDatum[] = barAchievementAndTargetObservationSPMTemp.map(
                (
                  item: DashboardSPMAchievementAndTargetObservationSPM,
                  index: number,
                ) => {
                  const name: DASHBOARDSERVICETYPE =
                    DASHBOARDSERVICETYPE[
                      item.name as keyof typeof DASHBOARDSERVICETYPE
                    ];
                  return {
                    id: `${name || ''}-${index}`,
                    label: name || '',
                    [t('Achieved')]: Number(item.achievement).toFixed(2) ?? 0,
                    [t('Target')]: Number(item.target).toFixed(2) ?? 0,
                  };
                },
              );
              setBarAchievementAndTargetObservationSPM(
                formattedBarAchievementAndTargetObservationSPM,
              );
              //#endregion
              //#region ACHIEVEMENT INTERNAL SPM DEPARTMENT
              const barAchievemenInternalSPMDepartmentTemp =
                dashboard?.dashboardSPMChartSectionResponse?.data
                  .achievementInternalSPMDepartment;
              const formattedBarAchievementInternalSPMDepartment: BarDatum[] = barAchievemenInternalSPMDepartmentTemp.map(
                (item: DashboardTableListItem, index: number) => {
                  return {
                    id: `${item?.name || ''}-${index}`,
                    label: item.name || '',
                    value: Number(item.percent).toFixed(2) ?? 0,
                    color: colors.primary.blue,
                  };
                },
              );
              setBarAchievementInternalSPMDepartment(
                formattedBarAchievementInternalSPMDepartment,
              );
              //#endregion
            }
          })
          .addCase(DashboardSPMChartSectionAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(dashboardSPMChartSectionAction.reset());
          });
      });
    },
    [dashboard?.dashboardSPMChartSectionResponse?.data, dispatch],
  );
  useEffect(() => {
    dashboardManagementWrapperActionHandler(dashboard.action);
  }, [dashboard.action, dashboardManagementWrapperActionHandler]);
  //#endregion

  return (
    <>
      <div className="dashboard-spm-section">
        <div className="dashboard-spm-section__filter">
          <MYearpicker
            inputValue={selectedYearFilter}
            maxDate={moment().endOf('year')}
            handleChange={(year) => setSelectedYearFilter(year)}
          />
          <MMonthpicker
            inputValue={selectedMonthFilter}
            handleChange={(month) => setSelectedMonthFilter(month)}
          />
        </div>
        <Grid container spacing={3}>
          {permissions?.hasDashboardInternalRatedServiceChart && (
            <Grid item xs={6} key="service-type-chart">
              <div className="dashboard-spm-section__column">
                <div className="dashboard-spm-section__card">
                  <DashboardServiceType
                    data={totalServiceTypeChecked}
                    title={t('Number of Service Types checked')}
                  />
                </div>
              </div>
            </Grid>
          )}
          {permissions?.hasDashboardInternalFindingChart && (
            <Grid item xs={6} key="findings-pie-chart">
              <div className="dashboard-spm-section__column">
                <div className="dashboard-spm-section__card">
                  <DashboardPieChart
                    data={pieFindings}
                    title={t('Temuan Bulan Ini')}
                    innerRadius={0.6}
                    enableArcLabels
                    enableArcLinkLabels
                    arcLinkLabel="label"
                    arcLinkLabelsColor={{from: 'color'}}
                    legends={[
                      {
                        anchor: 'bottom-left',
                        direction: 'row',
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 18,
                        translateY: 56,
                        translateX: -20,
                      },
                    ]}
                    tooltip={({datum}) => {
                      const tooltipData = [
                        {name: datum.label as string, value: datum.value},
                      ];
                      return <DashboardTooltip data={tooltipData} />;
                    }}
                    extraLayer={
                      <div className="extra-container">
                        <div className="extra-container__point">
                          {pieFindings.reduce(
                            (prev: number, {value}) =>
                              prev + (Number(value) || 0),
                            0,
                          )}
                        </div>
                        <div className="extra-container__desc">
                          {t('Findings')}
                        </div>
                      </div>
                    }
                    selectedYearFilter={selectedYearFilter}
                    selectedMonthFilter={selectedMonthFilter}
                    handleShowDetail={() => setPieFindingsShowDetail(true)}
                  />
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={6} key="spv-achievement-pie-chart">
            <div className="dashboard-spm-section__column">
              <div className="dashboard-spm-section__card">
                <DashboardPieChart
                  data={pieAchievementObservationSPM}
                  title={t('Capaian Pengawasan SPM')}
                  tooltip={({datum}) => {
                    const tooltipData = [
                      {name: datum.label as string, value: `${datum.value}%`},
                    ];
                    return <DashboardTooltip data={tooltipData} />;
                  }}
                  extraLayer={
                    <div className="extra-container">
                      <div className="extra-container__point">
                        {`${pieAchievementObservationSPM[0].value}%`}
                      </div>
                      <div className="extra-container__desc">
                        {t('Achieved')}
                      </div>
                    </div>
                  }
                  selectedYearFilter={selectedYearFilter}
                  selectedMonthFilter={selectedMonthFilter}
                  handleShowDetail={() =>
                    setPieAchievementObservationSPMShowDetail(true)
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} key="internal-achievement-pie-chart">
            <div className="dashboard-spm-section__column">
              <div className="dashboard-spm-section__card">
                <DashboardPieChart
                  data={pieInternalAchievement}
                  title={t('Capaian SPM Internal TJ')}
                  tooltip={({datum}) => {
                    const tooltipData = [
                      {name: datum.label as string, value: `${datum.value}%`},
                    ];
                    return <DashboardTooltip data={tooltipData} />;
                  }}
                  extraLayer={
                    <div className="extra-container">
                      <div className="extra-container__point">
                        {`${pieInternalAchievement[0].value}%`}
                      </div>
                      <div className="extra-container__desc">
                        {t('Achieved')}
                      </div>
                    </div>
                  }
                  selectedYearFilter={selectedYearFilter}
                  selectedMonthFilter={selectedMonthFilter}
                  handleShowDetail={() =>
                    setPieInternalAchievementShowDetail(true)
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} key="spv-achievement-bar-chart">
            <div className="dashboard-spm-section__column">
              <div className="dashboard-spm-section__card">
                <DashboardBarChart
                  data={barAchievementObservationSPMService}
                  title={t('Capaian Pengawasan SPM Per Jenis Layanan')}
                  keys={['rate']}
                  indexBy="label"
                  minValue={0}
                  maxValue={100}
                  tooltip={({data}) => {
                    const category: DashboardTableListItem[] = JSON.parse(
                      data.category as string,
                    );
                    const tooltipData = category?.map((categoryItem) => ({
                      name: categoryItem.name,
                      value: `${Number(categoryItem.percent).toFixed(2)}%`,
                    }));

                    return <DashboardTooltip data={tooltipData} />;
                  }}
                  selectedYearFilter={selectedYearFilter}
                  selectedMonthFilter={selectedMonthFilter}
                  handleShowDetail={() =>
                    setBarAchievementObservationSPMServiceShowDetail(true)
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} key="internal-service-type-bar-chart">
            <div className="dashboard-spm-section__column">
              <div className="dashboard-spm-section__card">
                <DashboardBarChart
                  data={barAchievementInternalSPMService}
                  title={t('Capaian SPM Internal TJ Per Jenis Layanan')}
                  keys={['rate']}
                  indexBy="label"
                  minValue={0}
                  maxValue={100}
                  tooltip={({data}) => {
                    const category: DashboardTableListItem[] = JSON.parse(
                      data.category as string,
                    );
                    const tooltipData = category?.map((categoryItem) => ({
                      name: categoryItem.name,
                      value: `${Number(categoryItem.percent).toFixed(2)}%`,
                    }));

                    return <DashboardTooltip data={tooltipData} />;
                  }}
                  selectedYearFilter={selectedYearFilter}
                  selectedMonthFilter={selectedMonthFilter}
                  handleShowDetail={() =>
                    setBarAchievementInternalSPMServiceShowDetail(true)
                  }
                />
              </div>
            </div>
          </Grid>
          {permissions?.hasDashboardInternalWeightChart && (
            <Grid item xs={6} key="target-bar-chart">
              <div className="dashboard-spm-section__column">
                <div className="dashboard-spm-section__card">
                  <DashboardBarChart
                    data={barAchievementAndTargetObservationSPM}
                    title={t('Bobot Tercapai dan Target Pengawasan SPM')}
                    keys={[t('Achieved'), t('Target')]}
                    colors={[colors.primary.blue, colors.primary.blue5]}
                    groupMode="grouped"
                    indexBy="label"
                    minValue={0}
                    maxValue={100}
                    tooltip={({id, value}) => {
                      const tooltipData = [
                        {name: id as string, value: `${value}%`},
                      ];
                      return <DashboardTooltip data={tooltipData} />;
                    }}
                    legends={[
                      {
                        dataFrom: 'keys',
                        anchor: 'bottom-left',
                        direction: 'row',
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 18,
                        translateY: 56,
                        translateX: -20,
                      },
                    ]}
                    selectedYearFilter={selectedYearFilter}
                    selectedMonthFilter={selectedMonthFilter}
                    handleShowDetail={() =>
                      setBarAchievementAndTargetObservationSPMShowDetail(true)
                    }
                  />
                </div>
              </div>
            </Grid>
          )}
          <Grid item xs={6} key="internal-achievement-bar-chart">
            <div className="dashboard-spm-section__column">
              <div className="dashboard-spm-section__card">
                <DashboardBarChart
                  data={barAchievementInternalSPMDepartment}
                  title={t('Capaian SPM Internal Semua Departemen')}
                  keys={['value']}
                  indexBy="label"
                  enableGridX
                  minValue={0}
                  maxValue={100}
                  layout="horizontal"
                  margin={{top: 40, right: 30, bottom: 60, left: 150}}
                  tooltip={({data, value}) => {
                    const tooltipData = [
                      {name: data.label as string, value: `${value}%`},
                    ];
                    return <DashboardTooltip data={tooltipData} />;
                  }}
                  selectedYearFilter={selectedYearFilter}
                  selectedMonthFilter={selectedMonthFilter}
                  handleShowDetail={() =>
                    setBarAchievementInternalSPMDepartmentShowDetail(true)
                  }
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {pieFindingsShowDetail && (
        <Modal
          onClose={() => setPieFindingsShowDetail(false)}
          isOpen={!!pieFindingsShowDetail}
          maxWidth="sm">
          <DashboardPieChart
            data={pieFindings}
            title={t('Temuan Bulan Ini')}
            innerRadius={0.6}
            enableArcLabels
            enableArcLinkLabels
            arcLinkLabel="label"
            arcLinkLabelsColor={{from: 'color'}}
            legends={[
              {
                anchor: 'bottom-left',
                direction: 'row',
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 18,
                translateY: 56,
                translateX: -20,
              },
            ]}
            tooltip={({datum}) => {
              const tooltipData = [
                {name: datum.label as string, value: datum.value},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            extraLayer={
              <div className="extra-container">
                <div className="extra-container__point">
                  {pieFindings.reduce(
                    (prev: number, {value}) => prev + (Number(value) || 0),
                    0,
                  )}
                </div>
                <div className="extra-container__desc">{t('Findings')}</div>
              </div>
            }
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {pieAchievementObservationSPMShowDetail && (
        <Modal
          onClose={() => setPieAchievementObservationSPMShowDetail(false)}
          isOpen={!!pieAchievementObservationSPMShowDetail}
          maxWidth="sm">
          <DashboardPieChart
            data={pieAchievementObservationSPM}
            title={t('Capaian Pengawasan SPM')}
            tooltip={({datum}) => {
              const tooltipData = [
                {name: datum.label as string, value: `${datum.value}%`},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            extraLayer={
              <div className="extra-container">
                <div className="extra-container__point">
                  {`${pieAchievementObservationSPM[0].value}%`}
                </div>
                <div className="extra-container__desc">{t('Achieved')}</div>
              </div>
            }
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {pieInternalAchievementShowDetail && (
        <Modal
          onClose={() => setPieInternalAchievementShowDetail(false)}
          isOpen={!!pieInternalAchievementShowDetail}
          maxWidth="sm">
          <DashboardPieChart
            data={pieInternalAchievement}
            title={t('Capaian SPM Internal TJ')}
            tooltip={({datum}) => {
              const tooltipData = [
                {name: datum.label as string, value: `${datum.value}%`},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            extraLayer={
              <div className="extra-container">
                <div className="extra-container__point">
                  {`${pieInternalAchievement[0].value}%`}
                </div>
                <div className="extra-container__desc">{t('Achieved')}</div>
              </div>
            }
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {barAchievementObservationSPMServiceShowDetail && (
        <Modal
          onClose={() =>
            setBarAchievementObservationSPMServiceShowDetail(false)
          }
          isOpen={!!barAchievementObservationSPMServiceShowDetail}
          maxWidth="sm">
          <DashboardBarChart
            data={barAchievementObservationSPMService}
            title={t('Capaian Pengawasan SPM Per Jenis Layanan')}
            keys={['rate']}
            indexBy="label"
            minValue={0}
            maxValue={100}
            tooltip={({data}) => {
              const category: DashboardTableListItem[] = JSON.parse(
                data.category as string,
              );
              const tooltipData = category?.map((categoryItem) => ({
                name: categoryItem.name,
                value: `${Number(categoryItem.percent).toFixed(2)}%`,
              }));

              return <DashboardTooltip data={tooltipData} />;
            }}
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {barAchievementInternalSPMServiceShowDetail && (
        <Modal
          onClose={() => setBarAchievementInternalSPMServiceShowDetail(false)}
          isOpen={!!barAchievementInternalSPMServiceShowDetail}
          maxWidth="sm">
          <DashboardBarChart
            data={barAchievementInternalSPMService}
            title={t('Capaian SPM Internal TJ Per Jenis Layanan')}
            keys={['rate']}
            indexBy="label"
            minValue={0}
            maxValue={100}
            tooltip={({data}) => {
              const category: DashboardTableListItem[] = JSON.parse(
                data.category as string,
              );
              const tooltipData = category?.map((categoryItem) => ({
                name: categoryItem.name,
                value: `${Number(categoryItem.percent).toFixed(2)}%`,
              }));

              return <DashboardTooltip data={tooltipData} />;
            }}
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {barAchievementAndTargetObservationSPMShowDetail && (
        <Modal
          onClose={() =>
            setBarAchievementAndTargetObservationSPMShowDetail(false)
          }
          isOpen={!!barAchievementAndTargetObservationSPMShowDetail}
          maxWidth="sm">
          <DashboardBarChart
            data={barAchievementAndTargetObservationSPM}
            title={t('Bobot Tercapai dan Target Pengawasan SPM')}
            keys={[t('Achieved'), t('Target')]}
            colors={[colors.primary.blue, colors.primary.blue5]}
            groupMode="grouped"
            indexBy="label"
            minValue={0}
            maxValue={100}
            tooltip={({id, value}) => {
              const tooltipData = [{name: id as string, value: `${value}%`}];
              return <DashboardTooltip data={tooltipData} />;
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-left',
                direction: 'row',
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 18,
                translateY: 56,
                translateX: -20,
              },
            ]}
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}

      {barAchievementInternalSPMDepartmentShowDetail && (
        <Modal
          onClose={() =>
            setBarAchievementInternalSPMDepartmentShowDetail(false)
          }
          isOpen={!!barAchievementInternalSPMDepartmentShowDetail}
          maxWidth="sm">
          <DashboardBarChart
            data={barAchievementInternalSPMDepartment}
            title={t('Capaian SPM Internal Semua Departemen')}
            keys={['value']}
            indexBy="label"
            minValue={0}
            maxValue={100}
            layout="horizontal"
            margin={{top: 40, right: 30, bottom: 60, left: 150}}
            tooltip={({data, value}) => {
              const tooltipData = [
                {name: data.label as string, value: `${value}%`},
              ];
              return <DashboardTooltip data={tooltipData} />;
            }}
            selectedYearFilter={selectedYearFilter}
            selectedMonthFilter={selectedMonthFilter}
          />
        </Modal>
      )}
    </>
  );
};

export default DashboardSPMSection;
