import {
  MasterDataDepartmentListAction,
  MasterDataDepartmentListSuccessPayload,
  MasterDataDepartmentListFailedPayload,
  MasterDataDepartmentListFetchPayload,
} from './masterDataDepartmentList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDepartmentListInitialState} from './masterDataDepartmentList.reducer';

export const masterDataDepartmentListHandler = {
  [MasterDataDepartmentListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDepartmentListLoading: true,
      masterDataDepartmentListParams: action.payload,
      action: MasterDataDepartmentListAction.FETCH,
    };
  },
  [MasterDataDepartmentListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentListLoading: false,
    masterDataDepartmentListResponse: action.payload,
    action: MasterDataDepartmentListAction.SUCCESS,
  }),
  [MasterDataDepartmentListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentListLoading: false,
    masterDataDepartmentListData: [
      ...state.masterDataDepartmentListData,
      ...action.payload.data.content,
    ],
    action: MasterDataDepartmentListAction.UPDATE,
  }),
  [MasterDataDepartmentListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentListLoading: false,
    masterDataDepartmentListError: action.payload,
    action: MasterDataDepartmentListAction.FAILED,
  }),
  [MasterDataDepartmentListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDepartmentListInitialState,
    action: MasterDataDepartmentListAction.RESET,
  }),
};
