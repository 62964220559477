import {CommonError} from '../../../../../../config/constant';

export const MasterDataRouteEditAction = {
  FETCH: 'MASTER_DATA_ROUTE_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_ROUTE_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_ROUTE_EDIT_FAILED',
  RESET: 'MASTER_DATA_ROUTE_EDIT_RESET',
};

export interface MasterDataRouteEditInitialState {
  masterDataRouteEditLoading: boolean;
  masterDataRouteEditParams?: MasterDataRouteEditFetchPayload;
  masterDataRouteEditError?: MasterDataRouteEditFailedPayload;
  masterDataRouteEditResponse?: MasterDataRouteEditSuccessPayload;
}

export interface MasterDataRouteEditFetchPayload {
  id: string;
  corridor: string;
  assignmentType: string;
  name: string;
  busStop: string;
  busStopCode: string;
}

export type MasterDataRouteEditFailedPayload = CommonError;

export interface MasterDataRouteEditSuccessPayload {}
