import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDivisionCreateAction,
  MasterDataDivisionCreateFailedPayload,
  MasterDataDivisionCreateFetchPayload,
  MasterDataDivisionCreateSuccessPayload,
} from './masterDataDivisionCreate.type';

export const masterDataDivisionCreateAction = {
  fetch: createAction<MasterDataDivisionCreateFetchPayload>(
    MasterDataDivisionCreateAction.FETCH,
  ),
  success: createAction<MasterDataDivisionCreateSuccessPayload>(
    MasterDataDivisionCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataDivisionCreateFailedPayload>(
    MasterDataDivisionCreateAction.FAILED,
  ),
  reset: createAction(MasterDataDivisionCreateAction.RESET),
};
