//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import moment, {Moment} from 'moment';
// Data
import {
  DashboardOverviewFinance,
  DashboardOverviewFinanceFormData,
  DASHBOARDQUARTERINTERVAL,
} from '../../entity/dashboard.string.entity';
import {dashboardOverviewSetProfitAction} from '../../usecases/dashboard.action';
import {DashboardOverviewSetProfitFetchPayload} from '../../usecases/dashboard.type';
// Components
import {DashboardFinanceForm} from './components';
// Assets
import './DashboardFinanceFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: DashboardOverviewFinance;
  year?: Moment | string | null;
  quarter?: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const DashboardFinanceFormContainer: React.FC<Props> = ({
  data,
  year,
  quarter,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: DashboardOverviewFinance = {
    year: moment(year).format('YYYY') || moment(new Date()).format('YYYY'),
    quarter: quarter || DASHBOARDQUARTERINTERVAL.TW1,
    value: Number(data?.value) || undefined,
  };
  //#endregion

  //#region HANDLE SUBMIT
  const handleSubmit = (_finance: DashboardOverviewFinanceFormData) => {
    const formattedPayload: DashboardOverviewSetProfitFetchPayload = {
      year:
        Number(moment(_finance.year).format('YYYY')) ||
        Number(moment(new Date()).format('YYYY')),
      quarter:
        (_finance.quarter as DASHBOARDQUARTERINTERVAL) ||
        (DASHBOARDQUARTERINTERVAL.TW1 as DASHBOARDQUARTERINTERVAL),
      value: _finance.value || 0,
    };
    dispatch(dashboardOverviewSetProfitAction.fetch(formattedPayload));
  };
  //#endregion

  return (
    <div className="dashboard-finance-form-container">
      <DashboardFinanceForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitFinance={handleSubmit}
      />
    </div>
  );
};

export default DashboardFinanceFormContainer;
