import React, {ReactElement} from 'react';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import MSpinner from '../MSpinner/index';
import './MIconButton.scss';

interface Props {
  icon: string | ReactElement;
  isLoading?: boolean;
  small?: boolean;
  className?: string;
  onClick?(e?: React.MouseEvent<HTMLButtonElement>): void;
  color?: 'primary' | 'secondary';
}

const MIconButton: React.FC<Props> = ({
  icon,
  isLoading,
  onClick,
  className,
  color,
  small,
}: Props) => {
  const isIconString = typeof icon === 'string';

  if (isLoading) return <MSpinner size={15} color="inherit" />;
  return (
    <IconButton
      {...(color ? {color: 'primary'} : {})}
      onClick={(e) => {
        e.stopPropagation();
        onClick && onClick(e);
      }}
      className={`${small ? 'm-icon-button-small' : 'm-icon-button'} ${
        className ? className : ''
      }`}>
      {isIconString ? <Icon path={icon as string} /> : icon}
    </IconButton>
  );
};

export default MIconButton;
