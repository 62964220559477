import {createAction} from '@reduxjs/toolkit';
import {
  UserChangePasswordAction,
  UserChangePasswordFailedPayload,
  UserChangePasswordFetchPayload,
  UserChangePasswordSuccessPayload,
} from './userChangePassword.type';

export const userChangePasswordAction = {
  fetch: createAction<UserChangePasswordFetchPayload>(
    UserChangePasswordAction.FETCH,
  ),
  success: createAction<UserChangePasswordSuccessPayload>(
    UserChangePasswordAction.SUCCESS,
  ),
  failed: createAction<UserChangePasswordFailedPayload>(
    UserChangePasswordAction.FAILED,
  ),
  reset: createAction(UserChangePasswordAction.RESET),
};
