import {PATH} from '../entity';
import t from '../../../lang';
import {MasterDataManagementData} from '../../masterData/entity/masterData.string.entity';

export const pathToLabel = (_path: PATH): string => {
  const label: Record<number | string, () => string> = {
    [PATH.HOME]: () => t('Dashboard'),
    [PATH.USER_ACCESS]: () => t('User Access Management'),
    [PATH.USER_ACCESS_EDIT_ROLE]: () => t('Edit Access Role'),
    [PATH.USER_ACCESS_CREATE_ROLE]: () => t('Add Access Role'),
    [PATH.USER_ACCESS_DETAIL_ROLE]: () => t('Role Access Detail'),
    [PATH.MASTER_DATA]: () => t('Master Data'),
    [PATH.MASTER_DATA_FORM_ASSESSMENT]: () => t('Master Data Formulir'),
    [PATH.MASTER_DATA_OFFICE]: () => t('Master Data Daftar Organisasi'),
    [PATH.MASTER_DATA_OFFICE_WORK_INSTRUCTION]: () =>
      t('Master Data Instruksi Kerja'),
    [PATH.MASTER_DATA_OFFICE_ASSIGNMENT_LETTER]: () =>
      t('Master Data Surat Tugas'),
    [PATH.MASTER_DATA_OPERATIONAL_BUS]: () => t('Master Data Bus'),
    [PATH.MASTER_DATA_OPERATIONAL_RUTE]: () => t('Master Data Rute'),
    [PATH.TINDAK_LANJUT]: () => t('Tindak Lanjut'),
    [PATH.NOTIFICATION]: () => t('Notification'),
    [PATH.LAPORAN_KERJA]: () => t('Laporan Kerja'),
    [PATH.LAPORAN_PENILAIAN]: () => t('Laporan Penilaian'),
    DEFAULT: () => '',
  };

  return (label[_path] || label.DEFAULT)();
};

export const formPathToLabel = (_formPath: string): string => {
  const formattedFormPath =
    _formPath?.replace('?type=', '')?.replaceAll(' ', '')?.toUpperCase() || '';
  const formLabel: Record<number | string, () => string> = {
    [MasterDataManagementData.HALTE.replaceAll(' ', '').toUpperCase()]: () =>
      t('Halte'),
    [MasterDataManagementData.BUSBRTTENGAH.replaceAll(
      ' ',
      '',
    ).toUpperCase()]: () => t('Bus BRT Tengah'),
    [MasterDataManagementData.BUSBRTUJUNG.replaceAll(
      ' ',
      '',
    ).toUpperCase()]: () => t('Bus BRT Ujung'),
    [MasterDataManagementData.BUSNONBRTTENGAH.replaceAll(
      ' ',
      '',
    ).toUpperCase()]: () => t('Bus NON BRT Tengah'),
    [MasterDataManagementData.BUSNONBRTUJUNG.replaceAll(
      ' ',
      '',
    ).toUpperCase()]: () => t('Bus NON BRT Ujung'),
    [MasterDataManagementData.BUSKECILTENGAH.replaceAll(
      ' ',
      '',
    ).toUpperCase()]: () => t('Bus Kecil Tengah'),
    [MasterDataManagementData.BUSKECILUJUNG.replaceAll(
      ' ',
      '',
    ).toUpperCase()]: () => t('Bus Kecil Ujung'),
    [MasterDataManagementData.TRANSCARE.replaceAll(
      ' ',
      '',
    ).toUpperCase()]: () => t('Transcare'),
    DEFAULT: () => '',
  };

  return (formLabel[formattedFormPath] || formLabel.DEFAULT)();
};

interface ActionBuilder {
  addCase: (type: string, fn: () => void) => ActionBuilder;
}
export const actionHandler = (
  action: string,
  cb: (builder: ActionBuilder) => void,
): ActionBuilder => {
  const cases: Record<string, () => void> = {};
  const actionBuilder: ActionBuilder = {
    addCase: (type: string, fn: () => void): ActionBuilder => {
      cases[type] = fn;
      return actionBuilder;
    },
  };

  function execute(type: string): void {
    if (cases[type]) {
      cases[type]();
    }
  }
  cb(actionBuilder);
  execute(action);
  return actionBuilder;
};
