/* eslint-disable max-lines */
import React from 'react';
import moment from 'moment';
import Icon from '@mdi/react';
import 'moment/locale/id';

import {ERROR_CODES, SortTable} from '../config/constant';
import t from '../lang';

import {SelectItemType} from '../components/MSelect/MSelect.component';
import {
  RoleOverview,
  UserLevel,
} from '../modules/user/entity/user.string.entity';
import {
  MasterDataDirectorate,
  MasterDataDepartment,
  MasterDataDepartmentOption,
  MasterDataDivisionOption,
} from '../modules/masterData/entity/masterData.string.entity';
import {FILEEXT, FileMeta} from '../modules/wrapper/entity';

export const errorCodeToLabel = (code?: number): string | undefined => {
  if (!code) return;
  const errors: Record<number | string, () => string> = {
    [ERROR_CODES.RESULT_DUPLICATE_DATA]: () =>
      t('Workshop already registered.'),
    [ERROR_CODES.USER_WRONG_PASSWORD]: () => t('Invalid password.'),
    [ERROR_CODES.LOGIN_EMAIL_FAILED]: () => t('Email is not registered.'),
    [ERROR_CODES.LOGIN_EMAIL_UNAUTHORIZED]: () => t('Account unauthorized.'),
    [ERROR_CODES.RESULT_NOT_FOUND]: () => t('Data not found.'),
    [ERROR_CODES.EXPIRED_VERIFICATION_ACCESS]: () =>
      t('Activation code already registered.'),
    DEFAULT: () => t('Unknown error.'),
  };

  return (errors[code] || errors.DEFAULT)();
};

type HeadersParam = {
  apiVersion?: string;
  authorization?: {
    tokenType: string;
    token: string;
  };
};

export const generateHeaders = (
  param: HeadersParam,
): {
  headers: {
    Authorization?: string | undefined;
    'X-Api-Version'?: string | undefined;
  };
} => {
  return {
    headers: {
      ...(param.apiVersion && {'X-Api-Version': param.apiVersion}),
      ...(param.authorization && {
        Authorization: `${param.authorization.tokenType} ${param.authorization.token}`,
      }),
    },
  };
};

export const amountValue = (value: number): string => {
  const nf = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });
  return nf.format(value).replace(',00', '');
};

export const thousandFormatter = (x: string | number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const hexToHsl = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return 'hsl(0, 0%, 0%)';

  const r = parseInt(result[1], 16) / 255;
  const g = parseInt(result[2], 16) / 255;
  const b = parseInt(result[3], 16) / 255;
  // Choosing max and min values
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  //  L - lightness calculation
  const L = (max + min) / 2;
  //  Calculating delta value
  const delta = max - min;
  //  Declaring S - saturation and H - hue
  let S = 0;
  let H = 0;
  //  S  value calculation
  S = delta === 0 ? 0 : Math.round((delta / (1 - Math.abs(2.0 * L - 1))) * 100);

  // H value calculation
  if (delta === 0) {
    H = 0;
  } else {
    switch (true) {
      case max == r:
        H = (((g - b) / delta) % 6) * 60.0;
        break;
      case max == g:
        H = (2.0 + (b - r) / delta) * 60.0;
        break;
      case max == b:
        H = (4.0 + (r - g) / delta) * 60.0;
        break;
      default:
        H = 0;
    }
  }
  //  If value is negative add 360
  H = H < 0 ? H + 360.0 : H;

  // Return color in hsl format
  return `hsl(${Math.round(H)},${S}%,${Math.round(L * 100)}%)`;
};

export const renderIcon = (
  icon: string | React.ReactElement,
): React.ReactElement => {
  if (typeof icon === 'string') return <Icon path={icon} />;
  return icon;
};

export const toInitial = (name?: string, all?: boolean): string => {
  const arrName: string[] =
    name
      ?.toUpperCase()
      .split(' ')
      .map((item: string) => item.substring(0, 1)) ?? [];
  let initialAvatar = '';
  if (all) {
    initialAvatar = arrName.join('');
  } else {
    initialAvatar =
      arrName?.length > 1
        ? [arrName[0], arrName[arrName.length - 1]].join('')
        : arrName[0];
  }
  return initialAvatar;
};

export const dateStringFormatter = (
  dateString: string | undefined,
  format: string,
): string => {
  return moment(new Date(dateString || '')).format(format);
};

export const toBase64 = (
  file: File | Blob,
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const bytesToSize = (
  bytes: number | undefined,
  decimals: number,
): string => {
  if (bytes === 0 || !bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getOptionFromRoleList = (
  data: RoleOverview[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromDirectorateList = (
  data: MasterDataDirectorate[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromDivisionList = (
  data: MasterDataDivisionOption[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromDepartmentList = (
  data: MasterDataDepartmentOption[] | MasterDataDepartment[],
): SelectItemType[] => {
  if (!data) return [];
  return (data as Array<MasterDataDepartmentOption | MasterDataDepartment>).map(
    (item: MasterDataDepartmentOption | MasterDataDepartment) => {
      if ('divisionName' in item) {
        const division: string = toInitial(item.divisionName, true);
        return {
          label: `[${division}] ${item.name}`,
          value: item.id,
        };
      }
      return {
        label: item.name,
        value: item.id,
      };
    },
  );
};

export const getUserLevelFromRoleId = (
  roleList: RoleOverview[],
  roleId: string,
): UserLevel => {
  if (!roleList || !roleId) return UserLevel.PETUGAS;
  const userLevel: UserLevel =
    (roleList.find((role) => role.id === roleId)?.userLevel as UserLevel) ||
    UserLevel.PETUGAS;
  return userLevel;
};

export const checkAcceptedType = (accept: string | string[]): string => {
  let containPDF = false;
  let containImage = false;
  let acceptTemp;
  if (typeof accept === 'string') {
    acceptTemp = accept.split('/')[0];
    containPDF = acceptTemp === 'application';
    containImage = acceptTemp === 'image';
  } else {
    acceptTemp = accept.map((item) => item.split('/')[0]);
    containPDF = acceptTemp.some((item) => item === 'application');
    containImage = acceptTemp.some((item) => item === 'image');
  }

  if (containPDF && containImage) {
    return 'allfiles';
  } else if (containPDF) {
    return 'pdf';
  } else if (containImage) {
    return 'image';
  }
  return 'allfiles';
};

export const toDataURL = async (dataUrl: string): Promise<string> => {
  const base64 = await fetch(dataUrl)
    .then((response) => response.blob())
    .then((blob) => toBase64(blob));
  return base64 as string;
};

export const dataURLtoFile = (
  dataUrl: string,
  dataFileName: string,
  dataType?: string,
): File | undefined => {
  if (dataUrl && dataFileName) {
    const arr: string[] = dataUrl.split(',');
    const mime = arr[0]?.match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const newFile = new File([u8arr], dataFileName, {type: dataType ?? mime});
    return newFile;
  }
};

export const urlToFile = async (
  url: string,
  fileName: string,
  fileType?: string,
): Promise<File | undefined> => {
  const base64 = await toDataURL(url);
  return dataURLtoFile(base64, fileName, fileType) || undefined;
};

export const getFileName = (url: string): string => {
  const urlArr = url?.split('/');
  return urlArr[urlArr?.length - 1] || '';
};

export const getTimeDifference = (past?: moment.Moment): string | undefined => {
  const current = moment();
  if (!past) return undefined;
  const hourDiff = past.diff(current, 'hours');
  const minuteDiff = past.diff(current, 'minutes') - hourDiff * 60;

  if (hourDiff < 0 || minuteDiff < 0) return undefined;
  return `${hourDiff}j ${minuteDiff}m`;
};

export const fileToFileMeta = async (file: File): Promise<FileMeta> => {
  const base64File = await toBase64(file);
  const metaPayload: FileMeta = {
    base64: base64File?.toString().split('base64,')[1],
    fileName: file.name,
    fileLength: file.size,
  };
  return metaPayload;
};

export const generateFileName = (
  fileName: string,
  ext: FILEEXT,
  hasTimeStamp?: boolean,
): string => {
  return `${fileName}${
    hasTimeStamp ? moment().format('-DD-MM-YYYY') : ''
  }.${ext}`;
};

export const downloadBlobResponse = (blob: Blob[], fileName?: string): void => {
  const url = window.URL.createObjectURL(new Blob(blob));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName || 'file.xlsx');
  document.body.appendChild(link);
  link.click();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNestedObject(obj: any, keyArr: string[]): any {
  let tmp = obj;
  keyArr.forEach(function (key) {
    tmp = tmp[key];
  });
  return tmp;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  const keyProps = orderBy.toString().split('.');
  const aProps = getNestedObject(a, keyProps);
  const bProps = getNestedObject(b, keyProps);
  if (bProps < aProps) {
    return -1;
  }
  if (bProps > aProps) {
    return 1;
  }
  return 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getComparator<Key extends keyof any>(
  order: SortTable,
  orderBy: Key,
): (
  a: {[key in Key]: number | string},
  b: {[key in Key]: number | string},
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const getAlphabeticNum = (
  num: number,
  isUpperCase?: boolean,
): string => {
  let s = '',
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  if (isUpperCase) return s || '';
  return s.toLowerCase() || '';
};

export const capitalizeFirstLetter = (str?: string): string => {
  if (str) {
    const lower = str.toLowerCase();
    return lower[0].toUpperCase() + lower.slice(1);
  }
  return '';
};
