//#region IMPORT
// Libraries
import React from 'react';
import {Moment} from 'moment';
import {PieSvgProps} from '@nivo/pie';
// Utils
import t from '../../../../lang';
// Components
import {
  MButton,
  MDropdown,
  MMonthpicker,
  MPieChart,
  MYearpicker,
} from '../../../../components';
import {DashboardComment} from '..';
// Data
import {PieRawDatum} from '../../../../components/MPieChart/MPieChart';
import {SelectItemType} from '../../../../components/MDropdown/MDropdown.component';
import {
  DashboardCommentData,
  DashboardCommentFormData,
  DASHBOARDQUARTERINTERVAL,
  DASHBOARDWEEKLYINTERVAL,
} from '../../entity/dashboard.string.entity';
// Assets
import './DashboardPieChart.component.style.scss';
import {IconPaperUpload} from '../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props extends Omit<PieSvgProps<PieRawDatum>, 'height' | 'width'> {
  title?: string;
  extraLayer?: React.ReactElement;
  selectedYearFilter?: Moment | string | null;
  yearFilterHandleChange?(year: Moment | string | null): void;
  selectedMonthFilter?: Moment | string | null;
  monthFilterHandleChange?(month: Moment | string | null): void;
  selectedPeriodFilter?: string;
  periodFilterHandleChange?(period: string): void;
  selectedWeekFilter?: string;
  weekFilterHandleChange?(week: string): void;
  handleExport?(): void;
  isLoadingExport?: boolean;
  isDisabledExport?: boolean;
  comment?: DashboardCommentData[];
  isPreviewComment?: boolean;
  handleShowDetail?(): void;
  handleCancelComment?(): void;
  handleAddComment?(comment: DashboardCommentFormData): void;
  handleOpenAddComment?(): void;
  isDetail?: boolean;
}
//#endregion

const DashboardPieChart: React.FC<Props> = ({
  title,
  extraLayer,
  selectedYearFilter,
  yearFilterHandleChange,
  selectedMonthFilter,
  monthFilterHandleChange,
  selectedPeriodFilter,
  periodFilterHandleChange,
  selectedWeekFilter,
  weekFilterHandleChange,
  handleExport,
  isLoadingExport,
  isDisabledExport,
  comment,
  isPreviewComment = false,
  handleShowDetail,
  handleCancelComment,
  handleAddComment,
  handleOpenAddComment,
  ...otherProps
}: Props) => {
  //#region GENERAL

  const periodFilterOption: SelectItemType[] = [
    {label: DASHBOARDQUARTERINTERVAL.TW1, value: DASHBOARDQUARTERINTERVAL.TW1},
    {label: DASHBOARDQUARTERINTERVAL.TW2, value: DASHBOARDQUARTERINTERVAL.TW2},
    {label: DASHBOARDQUARTERINTERVAL.TW3, value: DASHBOARDQUARTERINTERVAL.TW3},
    {label: DASHBOARDQUARTERINTERVAL.TW4, value: DASHBOARDQUARTERINTERVAL.TW4},
  ];
  const weekFilterOption: SelectItemType[] = [
    {label: DASHBOARDWEEKLYINTERVAL.M1, value: DASHBOARDWEEKLYINTERVAL.M1},
    {label: DASHBOARDWEEKLYINTERVAL.M2, value: DASHBOARDWEEKLYINTERVAL.M2},
    {label: DASHBOARDWEEKLYINTERVAL.M3, value: DASHBOARDWEEKLYINTERVAL.M3},
    {label: DASHBOARDWEEKLYINTERVAL.M4, value: DASHBOARDWEEKLYINTERVAL.M4},
  ];
  //#endregion

  return (
    <div className="dashboard-pie-chart">
      <div className="dashboard-pie-chart__header">
        {title && (
          <div className="dashboard-pie-chart__header--title">{title}</div>
        )}
        <div className="dashboard-pie-chart__header--action">
          {yearFilterHandleChange && (
            <MYearpicker
              inputValue={selectedYearFilter}
              handleChange={yearFilterHandleChange}
              inputWidth="33%"
            />
          )}
          {monthFilterHandleChange && (
            <MMonthpicker
              inputValue={selectedMonthFilter}
              handleChange={monthFilterHandleChange}
              inputWidth="33%"
            />
          )}
          {periodFilterHandleChange && (
            <MDropdown
              inputValue={selectedPeriodFilter}
              options={periodFilterOption}
              placeholder={t('Period')}
              handleChangeSelect={periodFilterHandleChange}
              inputWidth="33%"
            />
          )}
          {weekFilterHandleChange && (
            <MDropdown
              inputValue={selectedWeekFilter}
              options={weekFilterOption}
              placeholder={t('Week')}
              handleChangeSelect={weekFilterHandleChange}
              inputWidth="33%"
            />
          )}
          {handleExport && !isDisabledExport && (
            <div className="dashboard-pie-chart__header--action-export">
              <MButton
                handleClick={handleExport}
                buttonLabel={t('Export')}
                isLoading={isLoadingExport}
                isDisabled={isDisabledExport}
                startIcon={<IconPaperUpload />}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className="dashboard-pie-chart__content"
        onClick={(e) => {
          e.stopPropagation();
          handleShowDetail && handleShowDetail();
        }}>
        <MPieChart extraLayer={extraLayer} {...otherProps} />
      </div>

      {(handleAddComment || handleOpenAddComment) && (
        <div className="dashboard-bar-chart__comment">
          <DashboardComment
            commentData={comment}
            isPreview={isPreviewComment}
            handleShowAllComment={() => handleShowDetail && handleShowDetail()}
            handleCancelComment={() =>
              handleCancelComment && handleCancelComment()
            }
            handleAddComment={handleAddComment}
            handleOpenAddComment={handleOpenAddComment}
          />
        </div>
      )}
    </div>
  );
};
export default DashboardPieChart;
