import {CommonError} from '../../../../../../config/constant';
import {MasterDataDetailObservationFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailObservationEditAction = {
  FETCH: 'MASTER_DATA_DETAIL_OBSERVATION_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_OBSERVATION_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_OBSERVATION_EDIT_FAILED',
  RESET: 'MASTER_DATA_DETAIL_OBSERVATION_EDIT_RESET',
};

export interface MasterDataDetailObservationEditInitialState {
  masterDataDetailObservationEditLoading: boolean;
  masterDataDetailObservationEditParams?: MasterDataDetailObservationEditFetchPayload;
  masterDataDetailObservationEditError?: MasterDataDetailObservationEditFailedPayload;
  masterDataDetailObservationEditResponse?: MasterDataDetailObservationEditSuccessPayload;
}

export type MasterDataDetailObservationEditFetchPayload = MasterDataDetailObservationFormData;

export type MasterDataDetailObservationEditFailedPayload = CommonError;

export interface MasterDataDetailObservationEditSuccessPayload {}
