import {CommonError} from '../../../../../../config/constant';
import {MasterDataDetailIndicator} from '../../../../entity/masterData.string.entity';

export const MasterDataDetailIndicatorOptionListAction = {
  FETCH: 'MASTER_DATA_DETAIL_INDICATOR_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DETAIL_INDICATOR_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DETAIL_INDICATOR_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_DETAIL_INDICATOR_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_DETAIL_INDICATOR_OPTION_LIST_UPDATE',
};

export interface MasterDataDetailIndicatorOptionListInitialState {
  masterDataDetailIndicatorOptionListLoading: boolean;
  masterDataDetailIndicatorOptionListData: MasterDataDetailIndicator[];
  masterDataDetailIndicatorOptionListError?: MasterDataDetailIndicatorOptionListFailedPayload;
  masterDataDetailIndicatorOptionListParams?: MasterDataDetailIndicatorOptionListFetchPayload;
  masterDataDetailIndicatorOptionListResponse?: MasterDataDetailIndicatorOptionListSuccessPayload;
}

export type MasterDataDetailIndicatorOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataDetailIndicator[];
};

export type MasterDataDetailIndicatorOptionListFailedPayload = CommonError;

export type MasterDataDetailIndicatorOptionListSuccessPayload = MasterDataDetailIndicatorOptionListResponse;

export type MasterDataDetailIndicatorOptionListUpdatePayload = MasterDataDetailIndicatorOptionListResponse;

export interface MasterDataDetailIndicatorOptionListFetchPayload {
  assignmentType: string;
}
