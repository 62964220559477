import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataBusListAction} from './masterDataBusList.action';
import {
  MasterDataBusListAction,
  MasterDataBusListFetchPayload,
  MasterDataBusListResponse,
  MasterDataBusListSuccessPayload,
} from './masterDataBusList.type';

function* workerFetch(action: GenericAction<MasterDataBusListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataBusListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/bus/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataBusListAction.success(res.data as MasterDataBusListResponse),
      );
      yield put(
        masterDataBusListAction.update(res.data as MasterDataBusListResponse),
      );
    } else {
      yield put(masterDataBusListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(masterDataBusListAction.failed(e as CommonError));
  }
}

const masterDataBusListWatcher = [
  takeLatest(MasterDataBusListAction.FETCH, workerFetch),
];

export default masterDataBusListWatcher;
