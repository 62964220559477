import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataRouteDeleteAction,
  MasterDataRouteDeleteFailedPayload,
  MasterDataRouteDeleteFetchPayload,
  MasterDataRouteDeleteSuccessPayload,
} from './masterDataRouteDelete.type';

export const masterDataRouteDeleteAction = {
  fetch: createAction<MasterDataRouteDeleteFetchPayload>(
    MasterDataRouteDeleteAction.FETCH,
  ),
  success: createAction<MasterDataRouteDeleteSuccessPayload>(
    MasterDataRouteDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataRouteDeleteFailedPayload>(
    MasterDataRouteDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataRouteDeleteAction.RESET),
};
