import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import {FieldProps} from 'formik';
import React from 'react';
import t from '../../lang';
import './MRadioButton.component.style.scss';

export interface RadioButtonItemType {
  label: string;
  value: string;
}
interface Props extends FieldProps<string> {
  disabled?: boolean;
  className?: string;
  errormessage?: string;
  helperText?: string;
  options: RadioButtonItemType[];
  isHorizontal?: boolean;
  label: string;
  handleOnChange?(e: unknown): void;
}

const MRadioButton: React.FC<Props> = ({
  disabled = false,
  className,
  options,
  isHorizontal = false,
  field,
  helperText,
  errormessage,
  label,
  form,
  handleOnChange,
  ...otherProps
}: Props) => {
  return (
    <FormControl component="fieldset" className={`m-radio-button ${className}`}>
      <FormLabel component="legend" className="m-radio-button__label">
        {label}
      </FormLabel>

      <RadioGroup
        {...field}
        aria-label="gender"
        defaultValue="female"
        name={isHorizontal ? 'row-radio-buttons-group' : 'radio-buttons-group'}
        onChange={(e) => {
          const val = e?.target?.value;
          form.setFieldValue(field.name, val);
          handleOnChange && handleOnChange(val);
        }}
        {...otherProps}>
        {options.map((_option: RadioButtonItemType, idx: number) => (
          <FormControlLabel
            key={`option-item-${_option}-${idx}`}
            className="m-radio-button__field--item"
            value={_option.value}
            control={<Radio />}
            label={t(_option.label)}
            disabled={disabled}
          />
        ))}
      </RadioGroup>

      {(errormessage || form.errors[field.name]) && (
        <div className="m-radio-button__errorText error--text">
          {errormessage || form.errors[field.name]}
        </div>
      )}
      {helperText && !form.errors && (
        <div className="m-radio-button__helperText helper-text">
          {helperText}
        </div>
      )}
    </FormControl>
  );
};

export default MRadioButton;
