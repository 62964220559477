//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {TableCell, TableRow} from '@mui/material';
import {mdiChevronDown, mdiChevronUp, mdiDotsHorizontal} from '@mdi/js';
// Utils
import t from '../../../../../../../../lang';
// Components
import MIconButton from '../../../../../../../../components/MIconButton';
// Data
import {MasterDataReviewIndicator} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataReviewIndicatorManagementListTableItem.component.style.scss';
import {IconEdit, IconDelete} from '../../../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: MasterDataReviewIndicator;
  isFirst: boolean;
  isLast: boolean;
  onDeleteItem?(): void;
  onEditItem?(): void;
  onMoveUpItem?(): void;
  onMoveDownItem?(): void;
}
//#endregion
const MasterDataReviewIndicatorManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  isFirst,
  isLast,
  onDeleteItem,
  onEditItem,
  onMoveUpItem,
  onMoveDownItem,
}: Props) => {
  //#region GENERAL
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  //#endregion

  return (
    <TableRow className="master-data-review-indicator-list-table-item">
      <TableCell className="item-data__cell" align="center" width="5%">
        {index}
      </TableCell>
      <TableCell className="item-data__cell" width="15%">
        {data?.name}
      </TableCell>
      <TableCell className="item-data__cell" width="10%">
        {data?.target}%
      </TableCell>
      <TableCell className="item-data__cell" width="10%">
        {data?.spmWeight}%
      </TableCell>
      <TableCell className="item-data__cell" width="10%">
        {`${data?.sla} ${t('days')}`}
      </TableCell>
      <TableCell className="item-data__cell" width="20%">
        {`${data?.departmentName} `}
      </TableCell>
      <TableCell
        className="item-data__cell"
        width="10%">{`${data?.roleName} `}</TableCell>
      {onEditItem || onDeleteItem || onMoveUpItem || onMoveDownItem ? (
        <TableCell align="right" className="item-data__cell table-action">
          {isOptionOpened ? (
            <div className="item-status-action">
              {onEditItem && (
                <MIconButton
                  onClick={() => {
                    onEditItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconEdit />}
                />
              )}
              {onDeleteItem && (
                <MIconButton
                  onClick={() => {
                    onDeleteItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconDelete />}
                />
              )}
              {!isFirst && onMoveUpItem && (
                <MIconButton
                  onClick={() => {
                    onMoveUpItem();
                    setIsOptionOpened(false);
                  }}
                  icon={mdiChevronUp}
                />
              )}
              {!isLast && onMoveDownItem && (
                <MIconButton
                  onClick={() => {
                    onMoveDownItem();
                    setIsOptionOpened(false);
                  }}
                  icon={mdiChevronDown}
                />
              )}
            </div>
          ) : (
            <MIconButton
              onClick={() => setIsOptionOpened(true)}
              icon={mdiDotsHorizontal}
            />
          )}
        </TableCell>
      ) : (
        <TableCell />
      )}
    </TableRow>
  );
};

export default MasterDataReviewIndicatorManagementListTableItem;
