import React, {useRef} from 'react';
import {DatePicker, PickersDay} from '@mui/lab';
import {DatePickerView} from '@mui/lab/DatePicker/shared';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {InputLabel, TextField} from '@mui/material';
import t from '../../lang';
import {IconCalendar} from '../../assets/icons';
import './MDatepicker.component.style.scss';
import moment, {Moment} from 'moment';

interface Props {
  disabled?: boolean;
  className?: string;
  inputValue: Moment | string | null;
  toolbarText?: string;
  format?: string;
  label?: string;
  placeholder?: string;
  views?: DatePickerView[];
  minDate?: string | number | Moment;
  maxDate?: string | number | Moment;
  handleChange?(date: Moment | string | null): void;
}

const MDatepicker: React.FC<Props> = ({
  disabled = false,
  className,
  inputValue,
  toolbarText = t('Choose Date'),
  format = 'DD/MM/YYYY',
  label,
  placeholder = t('Choose Date'),
  views,
  maxDate,
  minDate,
  handleChange,
}: Props) => {
  const datepickerRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={`m-datepicker ${className}`}>
      {label ? (
        <InputLabel className="m-datepicker__label">{label}</InputLabel>
      ) : null}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          disabled={disabled}
          inputFormat={format}
          disableMaskedInput
          showToolbar={true}
          showTodayButton={false}
          allowSameDateSelection={true}
          showDaysOutsideCurrentMonth={true}
          value={inputValue ? inputValue : null}
          views={views}
          minDate={minDate}
          maxDate={maxDate}
          OpenPickerButtonProps={{
            ref: datepickerRef,
          }}
          onChange={(value) => {
            const date = moment(value, format);
            handleChange && handleChange(date);
          }}
          ToolbarComponent={() => (
            <div className="m-datepicker__toolbar">{toolbarText}</div>
          )}
          components={{OpenPickerIcon: IconCalendar}}
          InputAdornmentProps={{position: 'start'}}
          renderDay={(day, _value, DayComponentProps) => {
            const isWeekend = moment(day).day() === 0;
            if (DayComponentProps.today) {
              return (
                <PickersDay
                  className="m-datepicker__date-today"
                  {...DayComponentProps}
                />
              );
            } else if (DayComponentProps.selected) {
              return (
                <PickersDay
                  className="m-datepicker__date-selected"
                  {...DayComponentProps}
                />
              );
            } else if (isWeekend) {
              return (
                <PickersDay
                  className="m-datepicker__date-weekend"
                  {...DayComponentProps}
                />
              );
            } else if (DayComponentProps.outsideCurrentMonth) {
              return (
                <PickersDay
                  className="m-datepicker__date-outside"
                  {...DayComponentProps}
                />
              );
            }
            return (
              <PickersDay
                className="m-datepicker__date"
                {...DayComponentProps}
              />
            );
          }}
          renderInput={(params) => {
            const {inputProps} = params;
            const customInputProps = {
              ...inputProps,
              placeholder: placeholder,
              readOnly: true,
            };
            const customParams = {...params, inputProps: customInputProps};

            return (
              <TextField
                className="m-datepicker__input"
                {...customParams}
                onClick={() =>
                  datepickerRef.current && datepickerRef.current.click()
                }
              />
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MDatepicker;
