import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDetailIndicatorEditInitialState} from './masterDataDetailIndicatorEdit.reducer';
import {
  MasterDataDetailIndicatorEditAction,
  MasterDataDetailIndicatorEditFailedPayload,
  MasterDataDetailIndicatorEditFetchPayload,
  MasterDataDetailIndicatorEditSuccessPayload,
} from './masterDataDetailIndicatorEdit.type';

export const masterDataDetailIndicatorEditHandler = {
  [MasterDataDetailIndicatorEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorEditLoading: true,
    masterDataDetailIndicatorEditParams: action.payload,
    action: MasterDataDetailIndicatorEditAction.FETCH,
  }),
  [MasterDataDetailIndicatorEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorEditParams: undefined,
    masterDataDetailIndicatorEditLoading: false,
    masterDataDetailIndicatorEditResponse: action.payload,
    action: MasterDataDetailIndicatorEditAction.SUCCESS,
  }),
  [MasterDataDetailIndicatorEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorEditLoading: false,
    masterDataDetailIndicatorEditError: action.payload,
    action: MasterDataDetailIndicatorEditAction.FAILED,
  }),
  [MasterDataDetailIndicatorEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailIndicatorEditInitialState,
    action: MasterDataDetailIndicatorEditAction.RESET,
  }),
};
