//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDetailIndicatorEditAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDetailIndicatorFormEditForm} from './components';
// Data
import {MasterDataDetailIndicatorFormData} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDetailIndicatorFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  data: MasterDataDetailIndicatorFormData;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDetailIndicatorFormEditContainer: React.FC<Props> = ({
  assignmentType,
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataDetailIndicatorFormData = {
    id: data.id || '',
    name: data.name || '',
    assignmentType: data?.assignmentType || assignmentType,
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDetailIndicator = (
    formValue: MasterDataDetailIndicatorFormData,
  ) => {
    const detailIndicatorTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: detailIndicatorTemp || '',
    };
    dispatch(masterDataDetailIndicatorEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-detail-indicator-form-edit-container">
      <MasterDataDetailIndicatorFormEditForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitDetailIndicator={handleSubmitDetailIndicator}
      />
    </div>
  );
  //#endregion
};

export default MasterDataDetailIndicatorFormEditContainer;
