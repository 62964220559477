import {
  DashboardOverviewExportAchievementFinalMonthlyAction,
  DashboardOverviewExportAchievementFinalMonthlySuccessPayload,
  DashboardOverviewExportAchievementFinalMonthlyFailedPayload,
  DashboardOverviewExportAchievementFinalMonthlyFetchPayload,
} from './dashboardOverviewExportAchievementFinalMonthly.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardOverviewExportAchievementFinalMonthlyInitialState} from './dashboardOverviewExportAchievementFinalMonthly.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardOverviewExportAchievementFinalMonthlyHandler = {
  [DashboardOverviewExportAchievementFinalMonthlyAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewExportAchievementFinalMonthlyFetchPayload>,
  ): DashboardState => {
    return {
      ...state,
      dashboardOverviewExportAchievementFinalMonthlyParams: action.payload,
      dashboardOverviewExportAchievementFinalMonthlyLoading: true,
      action: DashboardOverviewExportAchievementFinalMonthlyAction.FETCH,
    };
  },
  [DashboardOverviewExportAchievementFinalMonthlyAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewExportAchievementFinalMonthlySuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewExportAchievementFinalMonthlyLoading: false,
    dashboardOverviewExportAchievementFinalMonthlyResponse: action.payload,
    action: DashboardOverviewExportAchievementFinalMonthlyAction.SUCCESS,
  }),
  [DashboardOverviewExportAchievementFinalMonthlyAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewExportAchievementFinalMonthlyFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewExportAchievementFinalMonthlyLoading: false,
    dashboardOverviewExportAchievementFinalMonthlyError: action.payload,
    action: DashboardOverviewExportAchievementFinalMonthlyAction.FAILED,
  }),
  [DashboardOverviewExportAchievementFinalMonthlyAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardOverviewExportAchievementFinalMonthlyInitialState,
    action: DashboardOverviewExportAchievementFinalMonthlyAction.RESET,
  }),
};
