import {createAction} from '@reduxjs/toolkit';
import {
  DashboardSPMChartSectionAction,
  DashboardSPMChartSectionFailedPayload,
  DashboardSPMChartSectionFetchPayload,
  DashboardSPMChartSectionSuccessPayload,
} from './dashboardSPMChartSection.type';

export const dashboardSPMChartSectionAction = {
  fetch: createAction<DashboardSPMChartSectionFetchPayload>(
    DashboardSPMChartSectionAction.FETCH,
  ),
  success: createAction<DashboardSPMChartSectionSuccessPayload>(
    DashboardSPMChartSectionAction.SUCCESS,
  ),
  failed: createAction<DashboardSPMChartSectionFailedPayload>(
    DashboardSPMChartSectionAction.FAILED,
  ),
  reset: createAction(DashboardSPMChartSectionAction.RESET),
};
