import * as React from 'react';

const IconUserAccess = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.463 7.527c0 2.79-2.208 5.027-4.963 5.027s-4.963-2.237-4.963-5.027S6.745 2.5 9.5 2.5s4.963 2.237 4.963 5.027zM2 18.247c0-2.585 3.454-3.232 7.5-3.232 4.067 0 7.5.67 7.5 3.254S13.545 21.5 9.5 21.5c-4.067 0-7.5-.67-7.5-3.254zM21.596 12.266c.131.07.233.18.305.291.139.229.128.509-.008.756l-.264.442a.785.785 0 01-.666.383.785.785 0 01-.399-.11.6.6 0 00-.331-.085.703.703 0 00-.697.67.762.762 0 01-.78.755h-.511c-.437 0-.784-.331-.784-.755a.697.697 0 00-.692-.67.584.584 0 00-.332.084.796.796 0 01-.399.11.786.786 0 01-.67-.382l-.26-.442a.735.735 0 01-.007-.756.78.78 0 01.301-.29.602.602 0 00.237-.236.676.676 0 00-.244-.921.753.753 0 01-.28-1.043l.253-.435a.783.783 0 011.062-.28.723.723 0 00.945-.254.57.57 0 00.087-.324.658.658 0 01.105-.39.809.809 0 01.666-.384h.531c.279 0 .531.155.67.383.068.11.113.247.102.39a.57.57 0 00.087.325.727.727 0 00.948.254.778.778 0 011.058.28l.253.435a.747.747 0 01-.279 1.043.671.671 0 00-.24.92c.06.1.127.185.233.236zm-4.16-.578c0 .578.478 1.039 1.069 1.039.59 0 1.058-.46 1.058-1.04 0-.578-.467-1.042-1.058-1.042s-1.07.464-1.07 1.043z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconUserAccess;
