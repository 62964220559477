//#region IMPORT
// Libraries
import React from 'react';
import Grid from '@mui/material/Grid';
// Components
import FollowUpWaitingListItem from '../FollowUpWaitingListItem';
// Data
import {FollowUp} from '../../../../../entity/followUp.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp[];
  onViewItem(item: FollowUp): void;
}
//#endregion

const FollowUpWaitingList: React.FC<Props> = ({data, onViewItem}: Props) => {
  return (
    <div className="follow-up-waiting-list__container">
      <Grid container spacing={3}>
        {data?.map((item: FollowUp, index: number) => {
          return (
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              key={`follow-up-waiting-list-item-data-${item.id}-${index}`}>
              <FollowUpWaitingListItem
                data={item}
                onViewItem={() => onViewItem(item)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default FollowUpWaitingList;
