import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataBasicServiceTypeCreateInitialState} from './masterDataBasicServiceTypeCreate.reducer';
import {
  MasterDataBasicServiceTypeCreateAction,
  MasterDataBasicServiceTypeCreateFailedPayload,
  MasterDataBasicServiceTypeCreateFetchPayload,
  MasterDataBasicServiceTypeCreateSuccessPayload,
} from './masterDataBasicServiceTypeCreate.type';

export const masterDataBasicServiceTypeCreateHandler = {
  [MasterDataBasicServiceTypeCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeCreateLoading: true,
    masterDataBasicServiceTypeCreateParams: action.payload,
    action: MasterDataBasicServiceTypeCreateAction.FETCH,
  }),
  [MasterDataBasicServiceTypeCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeCreateParams: undefined,
    masterDataBasicServiceTypeCreateLoading: false,
    masterDataBasicServiceTypeCreateResponse: action.payload,
    action: MasterDataBasicServiceTypeCreateAction.SUCCESS,
  }),
  [MasterDataBasicServiceTypeCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeCreateLoading: false,
    masterDataBasicServiceTypeCreateError: action.payload,
    action: MasterDataBasicServiceTypeCreateAction.FAILED,
  }),
  [MasterDataBasicServiceTypeCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBasicServiceTypeCreateInitialState,
    action: MasterDataBasicServiceTypeCreateAction.RESET,
  }),
};
