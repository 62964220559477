//#region IMPORT
// Libraries
import React, {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../usecases/user.reducer';
import {UserForgetPasswordAction} from '../../usecases/user.type';
import {userForgetPasswordAction} from '../../usecases/userForgetPassword/userForgetPassword.action';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {MButton, MTextField} from '../../../../components';
import UserOTP from '../UserOTP';
// Data
import {emailRegex} from '../../../../config/constant';
// Assets
import './UserForgetPassword.scss';
//#endregion

const UserForgetPassword: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const user: UserState = useSelector((state: AppState) => state.user);
  const [isEmailValid, setIsEmailValid] = useState<string>('');
  const isLoadingForgetPassword = user.userForgetPasswordLoading;
  //#endregion

  //#region VALIDATION
  const loginValidation = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, t('Must be a valid email.'))
      .required(t('Email is required.')),
  });
  //#endregion

  //#region ACTION HANDLER
  const userActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserForgetPasswordAction.SUCCESS, (): void => {
            if (user.userForgetPasswordParams) {
              setIsEmailValid(user.userForgetPasswordParams?.email);
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Email verification success!'),
                  type: 'success',
                  isSnackbar: true,
                  action: WrapperNotifyAction.FETCH,
                }),
              );
            }
          })
          .addCase(UserForgetPasswordAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Email verification failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userForgetPasswordAction.reset());
          });
      });
    },
    [dispatch, user.userForgetPasswordParams],
  );
  useEffect(() => {
    userActionHandler(user.action);
  }, [user.action, userActionHandler]);

  //#region

  //#region ON SUBMIT
  const onSubmit = (values: {email: string}): void => {
    dispatch(userForgetPasswordAction.fetch({email: values.email}));
  };
  //#endregion

  if (isEmailValid)
    return (
      <UserOTP email={isEmailValid} isLoading={user.userOTPPasswordLoading} />
    );
  return (
    <div className="user-forget-password">
      <div className="user-forget-password-container">
        <div className="user-forget-password-container__header">
          {t('Change Password')}
        </div>
        <div className="user-forget-password-container__info">
          {t('Input your Transjakarta email to get verification code')}
        </div>

        <Formik
          validationSchema={loginValidation}
          initialValues={{email: ''}}
          onSubmit={onSubmit}>
          {({handleSubmit, isValid}): React.ReactElement => (
            <>
              <div className="user-forget-password-container__form--field">
                <div className="input-label">{t('Email')}</div>
                <Field
                  type="email"
                  name="email"
                  placeholder={t('Input your email')}
                  component={MTextField}
                  disabled={isLoadingForgetPassword}
                />
              </div>

              <div className="user-forget-password-container__form--actions">
                <MButton
                  buttonLabel={t('Submit')}
                  handleClick={handleSubmit}
                  isLoading={isLoadingForgetPassword}
                  isDisabled={!isValid}
                />
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UserForgetPassword;
