//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
// Components
import {MTabPanel, MTabs} from '../../../../components';
import MasterDataDirectorateSection from '../sections/office/MasterDataDirectorateSection';
import MasterDataDepartmentSection from '../sections/office/MasterDataDepartmentSection';
import MasterDataDivisionSection from '../sections/office/MasterDataDivisionSection';
// Data
import {PATH, tabContentType} from '../../../wrapper/entity';
// Assets
import './MasterDataManagementOffice.scss';
//#endregion

const MasterDataManagementOffice: React.FC = () => {
  //#region GENERAL
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;
  //#endregion

  //#region HANDLE TAB
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };
  const masterDataTabLabel: tabContentType[] = [
    {label: t('Directorate'), component: <MasterDataDirectorateSection />},
    {label: t('Division'), component: <MasterDataDivisionSection />},
    {label: t('Department'), component: <MasterDataDepartmentSection />},
  ];
  //#endregion

  if (!permissions?.masterOrganization) return <Redirect to={PATH.HOME} />;
  return (
    <div className="master-data-management-office">
      <div className="master-data-management-office__tab">
        <MTabs
          variant="scrollable"
          tabsValue={tabValue}
          handleChangeTab={handleChangeTab}
          tabLabel={masterDataTabLabel.map((item) => item.label)}
        />
      </div>
      <div className="master-data-management-office__tab-panel">
        {masterDataTabLabel.map((item: tabContentType, index: number) => (
          <MTabPanel
            key={`master-data-tab-label-${item.label}-${index}`}
            value={tabValue}
            index={index}
            className="master-data-management-office__tab-panel--item">
            {item.component}
          </MTabPanel>
        ))}
      </div>
    </div>
  );
};

export default MasterDataManagementOffice;
