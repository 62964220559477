//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {TableCell, TableRow} from '@mui/material';
import {mdiDotsHorizontal} from '@mdi/js';
import moment from 'moment';
// Utils
import t from '../../../../../../../../lang';
// Components
import {MSwitch, MIconButton} from '../../../../../../../../components';
// Data
import {MasterDataAssignmentLetter} from '../../../../../../entity/masterData.string.entity';
// Assets
import {
  IconPaper,
  IconEdit,
  IconDelete,
} from '../../../../../../../../assets/icons';
import './MasterDataAssignmentLetterManagementListTableItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: MasterDataAssignmentLetter;
  onViewItem(): void;
  onDeleteItem?(): void;
  onEditItem?(): void;
  onActivateItem?(): void;
}
//#endregion

const MasterDataAssignmentLetterManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  onViewItem,
  onDeleteItem,
  onEditItem,
  onActivateItem,
}: Props) => {
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  return (
    <TableRow
      onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
        e.stopPropagation();
        onViewItem();
      }}
      className="master-data-assignment-letter-management-list-table-item">
      <TableCell className="item-data__cell" align="center" width="5%">
        {index}
      </TableCell>
      <TableCell className="item-data__cell">
        <div className="item-status-row">
          <div className="item-status-row__icon">
            <IconPaper />
          </div>
          <span>{data?.number}</span>
        </div>
      </TableCell>
      <TableCell className="item-data__cell">
        {moment(data?.publishDate).format('DD/MM/YYYY')}
      </TableCell>
      <TableCell className="item-data__cell">{data?.roleName}</TableCell>
      <TableCell className="item-data__cell">
        <div
          className="item-status-row"
          onClick={(e) => {
            onActivateItem && e.stopPropagation();
            onActivateItem && onActivateItem();
          }}>
          {onActivateItem && (
            <MSwitch
              checked={data?.isActive}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.stopPropagation();
                onActivateItem();
              }}
            />
          )}
          <span>{data?.isActive ? t('Applicable') : t('Not Applicable')}</span>
        </div>
      </TableCell>
      {(onEditItem || onDeleteItem) && (
        <TableCell align="right" className="item-data__cell table-action">
          {isOptionOpened ? (
            <div className="item-status-action">
              {onEditItem && (
                <MIconButton
                  onClick={() => {
                    onEditItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconEdit />}
                />
              )}
              {onDeleteItem && (
                <MIconButton
                  onClick={() => {
                    onDeleteItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconDelete />}
                />
              )}
            </div>
          ) : (
            <MIconButton
              onClick={() => setIsOptionOpened(true)}
              icon={mdiDotsHorizontal}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default MasterDataAssignmentLetterManagementListTableItem;
