import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationDeleteAction,
  MasterDataObservationDeleteFailedPayload,
  MasterDataObservationDeleteFetchPayload,
  MasterDataObservationDeleteSuccessPayload,
} from './masterDataObservationDelete.type';

export const masterDataObservationDeleteAction = {
  fetch: createAction<MasterDataObservationDeleteFetchPayload>(
    MasterDataObservationDeleteAction.FETCH,
  ),
  success: createAction<MasterDataObservationDeleteSuccessPayload>(
    MasterDataObservationDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationDeleteFailedPayload>(
    MasterDataObservationDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataObservationDeleteAction.RESET),
};
