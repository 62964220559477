import {CommonError} from '../../../../../../config/constant';
import {DASHBOARDQUARTERINTERVAL} from '../../../../entity/dashboard.string.entity';

export const DashboardOverviewSetProfitAction = {
  FETCH: 'DASHBOARD_OVERVIEW_SET_PROFIT_FETCH',
  SUCCESS: 'DASHBOARD_OVERVIEW_SET_PROFIT_SUCCESS',
  FAILED: 'DASHBOARD_OVERVIEW_SET_PROFIT_FAILED',
  RESET: 'DASHBOARD_OVERVIEW_SET_PROFIT_RESET',
};

export interface DashboardOverviewSetProfitInitialState {
  dashboardOverviewSetProfitLoading: boolean;
  dashboardOverviewSetProfitParams?: DashboardOverviewSetProfitFetchPayload;
  dashboardOverviewSetProfitError?: DashboardOverviewSetProfitFailedPayload;
  dashboardOverviewSetProfitResponse?: DashboardOverviewSetProfitSuccessPayload;
}

export type DashboardOverviewSetProfitFetchPayload = {
  year: number;
  quarter: DASHBOARDQUARTERINTERVAL;
  value: number;
};
export type DashboardOverviewSetProfitFailedPayload = CommonError;

export interface DashboardOverviewSetProfitSuccessPayload {}
