import React from 'react';
import {Moment} from 'moment';
import {
  FileUpload,
  MasterDataManagementData,
} from '../../masterData/entity/masterData.string.entity';
import {AssignmentType} from '../../wrapper/entity';

//#region FOLLOW UP
export enum FollowUpStatus {
  WAITING = 'WAITING',
  ONPROGRESS = 'ONPROGRESS',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  EXPIRED = 'EXPIRED',
}

export enum routeType {
  UJUNG = 'Ujung',
  TENGAH = 'Tengah',
}

export enum followUpFilterOption {
  ALL = 'ALL',
  NORMAL = 'NORMAL',
  EXPIRED = 'EXPIRED',
}

export interface FollowUp {
  assignmentType: AssignmentType;
  assignmentTypeCode: string;
  chiefDeptNote?: string;
  chiefDeptNoteDate?: string;
  rejectNote?: string;
  rejectNoteDate?: string;
  corridor: string;
  confirmDueDate?: string;
  finding: string;
  findingIndicator?: string;
  findingDate: string;
  findingDueDate: string;
  findingImg: string;
  fixingDate?: string;
  fixingDateRevision?: string;
  fixingImg?: string;
  fixingImgRevision?: string;
  id: string;
  isExpired?: boolean;
  location?: string;
  routeName: string;
  routeType?: routeType;
  status: FollowUpStatus;
}

export interface FollowUpExpiredFormData {
  id?: string;
  note?: string;
}

export interface FollowUpManagement {
  id: string;
  date: string;
  dueDate?: DueDate;
  routeId: string;
  routeName: string;
  hasExpired: boolean;
  notes: Notes;
  findings: Findings;
  assignmentType: MasterDataManagementData;
}

export interface DueDate {
  date: string;
  hasInjury: boolean;
}

export interface Notes {
  title: string;
  value: string;
  date: string;
}

export interface Findings {
  name: string;
  value: string;
  image: string;
}

export interface FollowUpData {
  name: string;
  value: string;
  image: string;
}

export interface FollowUpFormData {
  id: string;
  file?: File;
  fixingDate?: Moment | string | null;
}

export interface Revision {
  name: string;
  value: string;
  image: string;
}

export interface RevisionFormData {
  id: string;
  date: string;
  file?: FileUpload;
}

export interface FollowUpWaiting extends FollowUpManagement {
  status: string;
  followUp: FollowUpData;
  revision: Revision;
  revisionNotes: Notes;
}

export interface FollowUpApproved extends FollowUpManagement {
  status: string;
  followUp: FollowUpData;
  revision: Revision;
  revisionNotes: Notes;
}

export interface FollowUpRejected extends FollowUpManagement {
  status: string;
  followUp: FollowUpData;
  revision?: Revision;
  revisionNotes: Notes;
  haveAction: boolean;
}

export interface FollowUpExpired extends FollowUpManagement {
  status: string;
  followUp: FollowUp;
  revision?: Revision;
  revisionNotes: Notes;
  haveAction: boolean;
}

export interface FollowUpLoadingItem {
  isLoading?: boolean;
  id?: string;
}

export interface followUpTabContentType {
  label: string;
  enum: FollowUpStatus;
  component: React.ReactElement;
}
//#endregion
