/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import Grid from '@mui/material/Grid';
// Utils
import {AppState} from '../../../../../config/Interface';
import {UserState} from '../../../../user/usecases/user.reducer';
// Components
import {
  DashboardOverviewBoardList,
  DashboardOverviewChartList,
} from './components';
// Assets
import './DashboardOverviewSection.scss';
//#endregion

const DashboardOverviewSection: React.FC = () => {
  //#region GENERAL
  const {data}: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const permissions = data?.role?.permissions;
  //#endregion

  return (
    <>
      <div className="dashboard-overview-section">
        <Grid container spacing={6}>
          {(!!permissions?.dashboardOverviewAchievementFinalChart ||
            !!permissions?.dashboardOverviewAchievementQuarterlyChart ||
            !!permissions?.dashboardOverviewProfitAndFineChart) && (
            <Grid item xs={12} key="dashboard-overview-chart-list">
              <DashboardOverviewChartList />
            </Grid>
          )}
          {(!!permissions?.dashboardOverviewBestBusStopChart ||
            !!permissions?.dashboardOverviewBestOperatorChart) && (
            <Grid item xs={12} key="dashboard-overview-board-list">
              <DashboardOverviewBoardList />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};

export default DashboardOverviewSection;
