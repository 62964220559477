import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {httpPut} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../user.reducer';
import {userAccessSetActiveAction} from './userAccessSetActive.action';
import {
  UserAccessSetActiveAction,
  UserAccessSetActiveFetchPayload,
  UserAccessSetActiveSuccessPayload,
} from './userAccessSetActive.type';

function* workerFetch(action: GenericAction<UserAccessSetActiveFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id} = action.payload;
  try {
    const res: AxiosResponse<UserAccessSetActiveSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/user/set-active/${id}`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(userAccessSetActiveAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userAccessSetActiveAction.failed(e.response.data.payload));
  }
}

const userAccessSetActiveWatcher = [
  takeLatest(UserAccessSetActiveAction.FETCH, workerFetch),
];

export default userAccessSetActiveWatcher;
