import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataFormControllerCreateAction,
  MasterDataFormControllerCreateFailedPayload,
  MasterDataFormControllerCreateFetchPayload,
  MasterDataFormControllerCreateSuccessPayload,
} from './masterDataFormControllerCreate.type';

export const masterDataFormControllerCreateAction = {
  fetch: createAction<MasterDataFormControllerCreateFetchPayload>(
    MasterDataFormControllerCreateAction.FETCH,
  ),
  success: createAction<MasterDataFormControllerCreateSuccessPayload>(
    MasterDataFormControllerCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataFormControllerCreateFailedPayload>(
    MasterDataFormControllerCreateAction.FAILED,
  ),
  reset: createAction(MasterDataFormControllerCreateAction.RESET),
};
