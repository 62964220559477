import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {post} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataAssignmentLetterCreateAction} from './masterDataAssignmentLetterCreate.action';
import {
  MasterDataAssignmentLetterCreateAction,
  MasterDataAssignmentLetterCreateFetchPayload,
  MasterDataAssignmentLetterCreateSuccessPayload,
} from './masterDataAssignmentLetterCreate.type';

function* workerFetch(
  action: GenericAction<MasterDataAssignmentLetterCreateFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const formDataPayload = new FormData();
  formDataPayload.append('number', action.payload.number as string);
  formDataPayload.append('publishDate', action.payload.publishDate as string);
  formDataPayload.append('file', action.payload.file as Blob);
  formDataPayload.append('roleId', action.payload.roleId as string);

  try {
    const res: AxiosResponse<MasterDataAssignmentLetterCreateSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/assignment-letter/save`,
      formDataPayload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataAssignmentLetterCreateAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(
      masterDataAssignmentLetterCreateAction.failed(e.response.data.payload),
    );
  }
}

const masterDataAssignmentLetterCreateWatcher = [
  takeLatest(MasterDataAssignmentLetterCreateAction.FETCH, workerFetch),
];

export default masterDataAssignmentLetterCreateWatcher;
