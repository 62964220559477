//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {TableCell, TableRow} from '@mui/material';
// Data
import {MasterDataBus} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataBusManagementListTableItem.component.style.scss';
import {MIconButton} from '../../../../../../../../components';
import {IconDelete, IconEdit} from '../../../../../../../../assets/icons';
import {mdiDotsHorizontal} from '@mdi/js';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: MasterDataBus;
  onEditItem(): void;
  onDeleteItem(): void;
}
//#endregion

const MasterDataBusManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  onEditItem,
  onDeleteItem,
}: Props) => {
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  return (
    <TableRow className="master-data-bus-management-list-table-item">
      <TableCell className="item-data__cell" align="center" width="5%">
        {index}
      </TableCell>
      <TableCell className="item-data__cell" width="25%">
        {data?.bodyNumber}
      </TableCell>
      <TableCell className="item-data__cell" width="25%">
        {data?.busType}
      </TableCell>
      <TableCell align="right" className="item-data__cell table-action">
        {isOptionOpened ? (
          <div className="item-status-action">
            <MIconButton
              onClick={() => {
                onEditItem();
                setIsOptionOpened(false);
              }}
              icon={<IconEdit />}
            />
            <MIconButton
              onClick={() => {
                onDeleteItem();
                setIsOptionOpened(false);
              }}
              icon={<IconDelete />}
            />
          </div>
        ) : (
          <MIconButton
            onClick={() => setIsOptionOpened(true)}
            icon={mdiDotsHorizontal}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default MasterDataBusManagementListTableItem;
