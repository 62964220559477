import {
  UserRoleAccessListAction,
  UserRoleAccessListSuccessPayload,
  UserRoleAccessListFailedPayload,
  UserRoleAccessListFetchPayload,
} from './userRoleAccessList.type';
import {UserState} from '../user.reducer';
import {GenericAction} from '../../../../config/constant';
import {userRoleAccessListInitialState} from './userRoleAccessList.reducer';

export const userRoleAccessListHandler = {
  [UserRoleAccessListAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserRoleAccessListFetchPayload>,
  ): UserState => {
    return {
      ...state,
      userRoleAccessListLoading: true,
      userRoleAccessListParams: action.payload,
      action: UserRoleAccessListAction.FETCH,
    };
  },
  [UserRoleAccessListAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserRoleAccessListSuccessPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessListLoading: false,
    userRoleAccessListResponse: action.payload,
    action: UserRoleAccessListAction.SUCCESS,
  }),
  [UserRoleAccessListAction.UPDATE]: (
    state: UserState,
    action: GenericAction<UserRoleAccessListSuccessPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessListLoading: false,
    userRoleAccessListData: [
      ...state.userRoleAccessListData,
      ...action.payload.data,
    ],
    action: UserRoleAccessListAction.UPDATE,
  }),
  [UserRoleAccessListAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserRoleAccessListFailedPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessListLoading: false,
    userRoleAccessListError: action.payload,
    action: UserRoleAccessListAction.FAILED,
  }),
  [UserRoleAccessListAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userRoleAccessListInitialState,
    action: UserRoleAccessListAction.RESET,
  }),
};
