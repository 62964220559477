import * as React from 'react';

const IconError = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 80}
    height={props.height || 80}
    {...props}>
    <path
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: props.fill || '#EB5757',
        fillOpacity: 1,
      }}
      d="M39.688 50.156a3.134 3.134 0 0 0 3.124-3.125V25.156a3.134 3.134 0 0 0-3.124-3.125 3.134 3.134 0 0 0-3.126 3.125v21.875a3.134 3.134 0 0 0 3.126 3.125ZM44.203 61.156a4.518 4.518 0 0 1-4.516 4.516 4.518 4.518 0 0 1-4.515-4.516 4.518 4.518 0 0 1 4.516-4.515 4.518 4.518 0 0 1 4.515 4.515Zm0 0"
    />
    <path
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: props.fill || '#EB5757',
        fillOpacity: 1,
      }}
      d="M79.063 65.969 46.953 5.547C45.391 2.594 42.578.844 39.422.844h-.078c-3.172.031-5.985 1.828-7.516 4.828L1.141 66.032c-1.547 3.046-1.407 6.75.375 9.656 1.609 2.624 4.312 4.218 7.234 4.218L71.563 80h.015c2.953 0 5.656-1.61 7.25-4.266 1.766-2.953 1.86-6.703.234-9.765Zm-5.594 6.547c-.266.453-.875 1.234-1.89 1.234l-62.813-.094c-1.016 0-1.625-.765-1.907-1.219a3.71 3.71 0 0 1-.14-3.562l30.703-60.39c.453-.891 1.187-1.454 2-1.454h.016c.812 0 1.546.532 2.015 1.422l32.094 60.453c.719 1.344.422 2.766-.078 3.61Zm0 0"
    />
  </svg>
);

export default IconError;
