import {CommonError} from '../../../../../../config/constant';
import {MasterDataDivisionOption} from '../../../../entity/masterData.string.entity';

export const MasterDataDivisionOptionListAction = {
  FETCH: 'MASTER_DATA_DIVISION_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DIVISION_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DIVISION_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_DIVISION_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_DIVISION_OPTION_LIST_UPDATE',
};

export interface MasterDataDivisionOptionListInitialState {
  masterDataDivisionOptionListLoading: boolean;
  masterDataDivisionOptionListData: MasterDataDivisionOption[];
  masterDataDivisionOptionListError?: MasterDataDivisionOptionListFailedPayload;
  masterDataDivisionOptionListParams?: MasterDataDivisionOptionListFetchPayload;
  masterDataDivisionOptionListResponse?: MasterDataDivisionOptionListSuccessPayload;
}

export type MasterDataDivisionOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataDivisionOption[];
};

export type MasterDataDivisionOptionListFailedPayload = CommonError;

export type MasterDataDivisionOptionListSuccessPayload = MasterDataDivisionOptionListResponse;

export type MasterDataDivisionOptionListUpdatePayload = MasterDataDivisionOptionListResponse;

export interface MasterDataDivisionOptionListFetchPayload {
  directorateId: string;
}
