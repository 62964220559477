import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {evaluationReportDetailAction} from './evaluationReportDetail.action';
import {
  EvaluationReportDetailAction,
  EvaluationReportDetailFetchPayload,
  EvaluationReportDetailResponse,
  EvaluationReportDetailSuccessPayload,
} from './evaluationReportDetail.type';

function* workerFetch(
  action: GenericAction<EvaluationReportDetailFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<EvaluationReportDetailSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/review/preview`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        evaluationReportDetailAction.success(
          res.data as EvaluationReportDetailResponse,
        ),
      );
    } else {
      yield put(evaluationReportDetailAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(evaluationReportDetailAction.failed(e as CommonError));
  }
}

const evaluationReportDetailWatcher = [
  takeLatest(EvaluationReportDetailAction.FETCH, workerFetch),
];

export default evaluationReportDetailWatcher;
