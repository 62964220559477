import {CommonData, CommonError} from '../../../../config/constant';
import {User, UserType} from '../../entity/user.string.entity';

export const UserAccessListAction = {
  FETCH: 'USER_ACCESS_LIST_FETCH',
  SUCCESS: 'USER_ACCESS_LIST_SUCCESS',
  FAILED: 'USER_ACCESS_LIST_FAILED',
  RESET: 'USER_ACCESS_LIST_RESET',
  UPDATE: 'USER_ACCESS_LIST_UPDATE',
};

export interface UserAccessListInitialState {
  userAccessListLoading: boolean;
  userAccessListData: User[];
  userAccessListError?: UserAccessListFailedPayload;
  userAccessListParams?: UserAccessListFetchPayload;
  userAccessListResponse?: UserAccessListSuccessPayload;
}

export type UserAccessListResponse = {
  success: boolean;
  count: number;
  data: CommonData<User[]>;
};

export type UserAccessListFailedPayload = CommonError;

export type UserAccessListSuccessPayload = UserAccessListResponse;

export type UserAccessListUpdatePayload = UserAccessListResponse;

export interface UserAccessListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  type: UserType;
}
