import {createAction} from '@reduxjs/toolkit';
import {
  UserRoleAccessCreateAction,
  UserRoleAccessCreateFailedPayload,
  UserRoleAccessCreateFetchPayload,
  UserRoleAccessCreateSuccessPayload,
} from './userRoleAccessCreate.type';

export const userRoleAccessCreateAction = {
  fetch: createAction<UserRoleAccessCreateFetchPayload>(
    UserRoleAccessCreateAction.FETCH,
  ),
  success: createAction<UserRoleAccessCreateSuccessPayload>(
    UserRoleAccessCreateAction.SUCCESS,
  ),
  failed: createAction<UserRoleAccessCreateFailedPayload>(
    UserRoleAccessCreateAction.FAILED,
  ),
  reset: createAction(UserRoleAccessCreateAction.RESET),
};
