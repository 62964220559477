import {CommonError} from '../../../../../../config/constant';

export const MasterDataDepartmentDeleteAction = {
  FETCH: 'MASTER_DATA_DEPARTMENT_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_DEPARTMENT_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_DEPARTMENT_DELETE_FAILED',
  RESET: 'MASTER_DATA_DEPARTMENT_DELETE_RESET',
};

export interface MasterDataDepartmentDeleteInitialState {
  masterDataDepartmentDeleteLoading: boolean;
  masterDataDepartmentDeleteParams?: MasterDataDepartmentDeleteFetchPayload;
  masterDataDepartmentDeleteError?: MasterDataDepartmentDeleteFailedPayload;
  masterDataDepartmentDeleteResponse?: MasterDataDepartmentDeleteSuccessPayload;
}

export interface MasterDataDepartmentDeleteFetchPayload {
  id: string;
}
export type MasterDataDepartmentDeleteFailedPayload = CommonError;

export interface MasterDataDepartmentDeleteSuccessPayload {}
