import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataCounterListAction,
  MasterDataCounterListFailedPayload,
  MasterDataCounterListFetchPayload,
  MasterDataCounterListSuccessPayload,
} from './masterDataCounterList.type';

export const masterDataCounterListAction = {
  fetch: createAction<MasterDataCounterListFetchPayload>(
    MasterDataCounterListAction.FETCH,
  ),
  success: createAction<MasterDataCounterListSuccessPayload>(
    MasterDataCounterListAction.SUCCESS,
  ),
  failed: createAction<MasterDataCounterListFailedPayload>(
    MasterDataCounterListAction.FAILED,
  ),
  reset: createAction(MasterDataCounterListAction.RESET),
};
