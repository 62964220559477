import {createAction} from '@reduxjs/toolkit';
import {
  FollowUpDetailAction,
  FollowUpDetailFailedPayload,
  FollowUpDetailFetchPayload,
  FollowUpDetailSuccessPayload,
} from './followUpDetail.type';

export const followUpDetailAction = {
  fetch: createAction<FollowUpDetailFetchPayload>(FollowUpDetailAction.FETCH),
  success: createAction<FollowUpDetailSuccessPayload>(
    FollowUpDetailAction.SUCCESS,
  ),
  failed: createAction<FollowUpDetailFailedPayload>(
    FollowUpDetailAction.FAILED,
  ),
  reset: createAction(FollowUpDetailAction.RESET),
};
