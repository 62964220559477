import {CommonError} from '../../../../../../config/constant';
import {
  DashboardOverviewExportExcel,
  DASHBOARDQUARTERINTERVAL,
} from '../../../../entity/dashboard.string.entity';

export const DashboardOverviewExportAchievementFinalMonthlyAction = {
  FETCH: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_MONTHLY_FETCH',
  SUCCESS: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_MONTHLY_SUCCESS',
  FAILED: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_MONTHLY_FAILED',
  RESET: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_MONTHLY_RESET',
};
export interface DashboardOverviewExportAchievementFinalMonthlyInitialState {
  dashboardOverviewExportAchievementFinalMonthlyLoading: boolean;
  dashboardOverviewExportAchievementFinalMonthlyError?: DashboardOverviewExportAchievementFinalMonthlyFailedPayload;
  dashboardOverviewExportAchievementFinalMonthlyResponse?: DashboardOverviewExportAchievementFinalMonthlySuccessPayload;
  dashboardOverviewExportAchievementFinalMonthlyParams?: DashboardOverviewExportAchievementFinalMonthlyFetchPayload;
}

export interface DashboardOverviewExportAchievementFinalMonthlyFetchPayload {
  quarter: DASHBOARDQUARTERINTERVAL;
  year: number;
}

export interface DashboardOverviewExportAchievementFinalMonthlyResponse {
  status: string;
  info?: number;
  data: DashboardOverviewExportExcel;
}

export type DashboardOverviewExportAchievementFinalMonthlyFailedPayload = CommonError;

export type DashboardOverviewExportAchievementFinalMonthlySuccessPayload = DashboardOverviewExportAchievementFinalMonthlyResponse;

export type DashboardOverviewExportAchievementFinalMonthlyUpdatePayload = DashboardOverviewExportAchievementFinalMonthlyResponse;
