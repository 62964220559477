//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../lang';
import {colors} from '../../../../config/_theme';
// Components
import {
  DashboardCommentForm,
  DashboardCommentItem,
  DashboardCommentPreview,
} from '..';
import {MButton} from '../../../../components';
// Data
import {
  DashboardCommentData,
  DashboardCommentFormData,
} from '../../entity/dashboard.string.entity';
// Assets
import './DashboardComment.component.style.scss';
import {IconComment} from '../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  commentData?: DashboardCommentData[];
  isPreview?: boolean;
  handleShowAllComment?(): void;
  handleCancelComment?(): void;
  handleAddComment?(comment: DashboardCommentFormData): void;
  handleOpenAddComment?(): void;
}
//#endregion

const DashboardComment: React.FC<Props> = ({
  commentData,
  isPreview = false,
  handleShowAllComment,
  handleCancelComment,
  handleAddComment,
  handleOpenAddComment,
}: Props) => {
  return (
    <div className="dashboard-comment">
      {commentData && commentData.length > 0 && isPreview ? (
        <div className="dashboard-comment__preview">
          <DashboardCommentPreview
            data={commentData}
            handleShowAllComment={handleShowAllComment}
          />
        </div>
      ) : (
        <div className="dashboard-comment__list">
          {commentData?.map((item: DashboardCommentData, idx: number) => (
            <DashboardCommentItem
              key={`dashboard-comment-item${item.id}-${idx}`}
              data={item}
            />
          ))}
        </div>
      )}

      <div className="dashboard-comment__add">
        {handleOpenAddComment && (
          <div className="dashboard-comment__add--action">
            <div
              className={`dashboard-comment__add--action${
                commentData && commentData?.length > 0 ? '-fill' : '-empty'
              }`}>
              <MButton
                handleClick={handleOpenAddComment}
                buttonLabel={t('Add Comment')}
                startIcon={<IconComment fill={colors.text.lightGrey3} />}
              />
            </div>
          </div>
        )}
        {handleAddComment && handleCancelComment && (
          <DashboardCommentForm
            handleCancelComment={handleCancelComment}
            handleAddComment={handleAddComment}
          />
        )}
      </div>
    </div>
  );
};
export default DashboardComment;
