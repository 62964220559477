//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {Collapse, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiDotsHorizontal,
} from '@mdi/js';
// Utils
import t from '../../../../../../../../lang';
import {capitalizeFirstLetter} from '../../../../../../../../utils';
// Components
import MIconButton from '../../../../../../../../components/MIconButton';
// Data
import {MasterDataFormController} from '../../../../../../entity/masterData.string.entity';
// Assets
import './FormControllerManagementListTableItem.component.style.scss';
import {IconEdit, IconDelete} from '../../../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: MasterDataFormController;
  isFirst: boolean;
  isLast: boolean;
  onDeleteItem?(): void;
  onEditItem?(): void;
  onMoveUpItem?(): void;
  onMoveDownItem?(): void;
}
//#endregion

const FormControllerManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  isFirst,
  isLast,
  onDeleteItem,
  onEditItem,
  onMoveUpItem,
  onMoveDownItem,
}: Props) => {
  //#region GENERAL
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  const [isCollapseOpened, setIsCollapseOpened] = useState(false);
  //#endregion

  return (
    <React.Fragment>
      <TableRow
        className="form-controller-management-list-table-item"
        onClick={(e) => {
          e.stopPropagation();
          setIsCollapseOpened(!isCollapseOpened);
        }}>
        <TableCell
          className="item-data__cell"
          padding="none"
          align="center"
          width="3%">
          <MIconButton
            aria-label="expand row"
            small={true}
            onClick={() => setIsCollapseOpened(!isCollapseOpened)}
            icon={isCollapseOpened ? mdiChevronDown : mdiChevronRight}
          />
        </TableCell>
        <TableCell className="item-data__cell" align="center" width="5%">
          {index}
        </TableCell>
        <TableCell className="item-data__cell">{data?.categoryName}</TableCell>
        <TableCell className="item-data__cell">
          {data?.basicServiceTypeName}
        </TableCell>
        <TableCell className="item-data__cell">
          {data?.reviewIndicatorName}
        </TableCell>
        <TableCell className="item-data__cell">
          {data?.detailIndicator && data?.detailIndicator.length > 0
            ? data?.detailIndicator.map((item) => item.name).join(', ')
            : '-'}
        </TableCell>
        <TableCell className="item-data__cell">
          {data?.indicatorValue && data?.indicatorValue.length > 0
            ? data?.indicatorValue.map((item) => item.name).join(', ')
            : '-'}
        </TableCell>
        {onEditItem || onDeleteItem || onMoveUpItem || onMoveDownItem ? (
          <TableCell align="right" className="item-data__cell table-action">
            {isOptionOpened ? (
              <div className="item-status-action">
                {onEditItem && (
                  <MIconButton
                    onClick={() => {
                      onEditItem();
                      setIsOptionOpened(false);
                    }}
                    icon={<IconEdit />}
                  />
                )}
                {onDeleteItem && (
                  <MIconButton
                    onClick={() => {
                      onDeleteItem();
                      setIsOptionOpened(false);
                    }}
                    icon={<IconDelete />}
                  />
                )}
                {!isFirst && onMoveUpItem && false && (
                  <MIconButton
                    onClick={() => {
                      onMoveUpItem && onMoveUpItem();
                      setIsOptionOpened(false);
                    }}
                    icon={mdiChevronUp}
                  />
                )}
                {!isLast && onMoveDownItem && false && (
                  <MIconButton
                    onClick={() => {
                      onMoveDownItem && onMoveDownItem();
                      setIsOptionOpened(false);
                    }}
                    icon={mdiChevronDown}
                  />
                )}
              </div>
            ) : (
              <MIconButton
                onClick={() => setIsOptionOpened(true)}
                icon={mdiDotsHorizontal}
              />
            )}
          </TableCell>
        ) : (
          <TableCell />
        )}
      </TableRow>
      <TableRow className="form-controller-management-list-table-item">
        <TableCell colSpan={8} padding="none">
          <Collapse in={isCollapseOpened} timeout="auto" unmountOnExit>
            <div className="item-collapse-table">
              <div className="item-collapse-table__item">
                <Table size="small" style={{tableLayout: 'fixed'}}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="title" width="40">
                        {t('Check Location')}
                      </TableCell>
                      <TableCell width="60">
                        {t(capitalizeFirstLetter(data?.checkLocation))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="title" width="40">
                        {t('Category')}
                      </TableCell>
                      <TableCell width="60">{data?.categoryName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="title">
                        {t('Basic Service Type')}
                      </TableCell>
                      <TableCell>{data?.basicServiceTypeName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="title">
                        {t('Review Indicator')}
                      </TableCell>
                      <TableCell>{data?.reviewIndicatorName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="title">
                        {t('Detail Indicator')}
                      </TableCell>
                      <TableCell>
                        {data?.detailIndicator &&
                        data?.detailIndicator.length > 0 ? (
                          <ul>
                            {data?.detailIndicator.map(
                              (item, index: number) => (
                                <li key={`detail-indicator-${index}`}>
                                  {item.name}
                                </li>
                              ),
                            )}
                          </ul>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="title">
                        {t('Indicator Value')}
                      </TableCell>
                      <TableCell>
                        {data?.indicatorValue &&
                        data?.indicatorValue.length > 0 ? (
                          <ul>
                            {data?.indicatorValue.map((item, index: number) => (
                              <li key={`indicator-value-${index}`}>
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <div className="item-collapse-table__item">
                <Table size="small" style={{tableLayout: 'fixed'}}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="title" width="40">
                        {t('Observation')}
                      </TableCell>
                      <TableCell width="60">{data?.observationName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="title">
                        {t('Detail Observation')}
                      </TableCell>
                      <TableCell>
                        {data?.detailObservation &&
                        data?.detailObservation.length > 0 ? (
                          <ul>
                            {data?.detailObservation.map(
                              (item, index: number) => (
                                <li key={`detail-observation-${index}`}>
                                  {item.name}
                                </li>
                              ),
                            )}
                          </ul>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="title">
                        {t('Observation Value')}
                      </TableCell>
                      <TableCell>
                        {data?.observationValue &&
                        data?.observationValue.length > 0 ? (
                          <ul>
                            {data?.observationValue.map(
                              (item, index: number) => (
                                <li key={`observation-value-${index}`}>
                                  {item.name}
                                </li>
                              ),
                            )}
                          </ul>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default FormControllerManagementListTableItem;
