//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDetailObservationEditAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDetailObservationFormEdit} from './components';
// Data
import {MasterDataDetailObservationFormData} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDetailObservationFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  data: MasterDataDetailObservationFormData;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDetailObservationFormEditContainer: React.FC<Props> = ({
  assignmentType,
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataDetailObservationFormData = {
    id: data.id || '',
    name: data.name || '',
    assignmentType: data?.assignmentType || assignmentType,
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDetailObservation = (
    formValue: MasterDataDetailObservationFormData,
  ) => {
    const detailObservationTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: detailObservationTemp || '',
    };
    dispatch(masterDataDetailObservationEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-detail-observation-form-edit-container">
      <MasterDataDetailObservationFormEdit
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitDetailObservation={handleSubmitDetailObservation}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataDetailObservationFormEditContainer;
