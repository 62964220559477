import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataReviewIndicatorListAction,
  MasterDataReviewIndicatorListFailedPayload,
  MasterDataReviewIndicatorListFetchPayload,
  MasterDataReviewIndicatorListSuccessPayload,
  MasterDataReviewIndicatorListUpdatePayload,
} from './masterDataReviewIndicatorList.type';

export const masterDataReviewIndicatorListAction = {
  fetch: createAction<MasterDataReviewIndicatorListFetchPayload>(
    MasterDataReviewIndicatorListAction.FETCH,
  ),
  success: createAction<MasterDataReviewIndicatorListSuccessPayload>(
    MasterDataReviewIndicatorListAction.SUCCESS,
  ),
  failed: createAction<MasterDataReviewIndicatorListFailedPayload>(
    MasterDataReviewIndicatorListAction.FAILED,
  ),
  update: createAction<MasterDataReviewIndicatorListUpdatePayload>(
    MasterDataReviewIndicatorListAction.UPDATE,
  ),
  reset: createAction(MasterDataReviewIndicatorListAction.RESET),
};
