//#region IMPORT
// IMPORT LIBRARY
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {Persistor} from 'redux-persist/es/types';
import {ConnectedRouter} from 'connected-react-router';

// IMPORT COMPONENT
import {Wrapper} from './modules/wrapper/views';
import {history, persistor, store} from './bootstrap/ReduxStore';
import {ThemeProvider} from '@mui/material';
import theme from './config/Theme';
//#endregion

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={(persistor as unknown) as Persistor}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <Wrapper />
        </ThemeProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
