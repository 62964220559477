//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
// Components
import {MButton, MTextField} from '../../../../../../../../components';
// Data
import {MasterDataDirectorateFormData} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDirectorateFormEdit.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  initialValues: MasterDataDirectorateFormData;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitDirectorate(formValue: MasterDataDirectorateFormData): void;
}
//#endregion

const MasterDataDirectorateFormEdit: React.FC<Props> = ({
  initialValues,
  isLoading,
  handleSubmitDirectorate,
  handleCancel,
}: Props) => {
  //#region VALIDATION
  const directorateValidation = Yup.object().shape({
    name: Yup.string()
      .required(t('Directorate is required.'))
      .min(
        3,
        `${t('Directorate')} ${t('must be filled in at least')} ${3} ${t(
          'letters',
        )}.`,
      ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-directorate-form-edit">
      <div className="master-data-directorate-form-edit__form">
        <Formik
          initialValues={initialValues}
          validationSchema={directorateValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitDirectorate}>
          {({handleSubmit, isValid}): React.ReactElement => (
            <>
              <div className="master-data-directorate-form-edit__section">
                <div className="master-data-directorate-form-edit__section--field">
                  <div className="input-label">{t('Directorate')}</div>
                  <Field
                    className="user-input"
                    name="name"
                    placeholder={t('Enter Directorate')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
              </div>

              <div className="master-data-directorate-form-edit__form--action">
                <div className="master-data-directorate-form-edit-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="master-data-directorate-form-edit-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    buttonLabel={t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataDirectorateFormEdit;
