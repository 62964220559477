import {CommonError} from '../../../../config/constant';
import {
  WorkReportDownload,
  WorkReportType,
} from '../../entity/workReport.string.entity';

export const WorkReportExportAllAction = {
  FETCH: 'WORK_REPORT_EXPORT_ALL_FETCH',
  SUCCESS: 'WORK_REPORT_EXPORT_ALL_SUCCESS',
  FAILED: 'WORK_REPORT_EXPORT_ALL_FAILED',
  RESET: 'WORK_REPORT_EXPORT_ALL_RESET',
};

export interface WorkReportExportAllInitialState {
  workReportExportAllLoading: boolean;
  workReportExportAllError?: WorkReportExportAllFailedPayload;
  workReportExportAllParams?: WorkReportExportAllFetchPayload;
  workReportExportAllResponse?: WorkReportExportAllSuccessPayload;
}

export type WorkReportExportAllResponse = {
  status: string;
  count?: number;
  data: WorkReportDownload;
};

export type WorkReportExportAllFailedPayload = CommonError;

export type WorkReportExportAllSuccessPayload = WorkReportExportAllResponse;

export interface WorkReportExportAllFetchPayload {
  startMonth?: string;
  endMonth?: string;
  reportType?: WorkReportType;
}
