//#region IMPORT
// Libraries
import React from 'react';
import {mdiChevronDown, mdiChevronUp} from '@mdi/js';
// Components
import {MIconButton} from '../../../../components';
// Assets
import './RoleTableCollapse.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  title: string;
  isCollapseOpened: boolean;
  handleCollapse(): void;
}
//#endregion

const RoleTableCollapse: React.FC<Props> = ({
  title,
  isCollapseOpened,
  handleCollapse,
}: Props) => {
  return (
    <div
      className="role-table-collapse"
      onClick={(e) => {
        e.stopPropagation();
        handleCollapse();
      }}>
      <div className="role-table-collapse__title">{title}</div>
      <div className="role-table-collapse__icon">
        <MIconButton
          aria-label="expand row"
          small={true}
          onClick={() => handleCollapse()}
          icon={isCollapseOpened ? mdiChevronUp : mdiChevronDown}
        />
      </div>
    </div>
  );
};

export default RoleTableCollapse;
