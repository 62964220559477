import {CommonError} from '../../../../../../config/constant';
import {MasterDataReviewIndicator} from '../../../../entity/masterData.string.entity';

export const MasterDataReviewIndicatorOptionListAction = {
  FETCH: 'MASTER_DATA_REVIEW_INDICATOR_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_REVIEW_INDICATOR_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_REVIEW_INDICATOR_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_REVIEW_INDICATOR_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_REVIEW_INDICATOR_OPTION_LIST_UPDATE',
};

export interface MasterDataReviewIndicatorOptionListInitialState {
  masterDataReviewIndicatorOptionListLoading: boolean;
  masterDataReviewIndicatorOptionListData: Pick<
    MasterDataReviewIndicator,
    'id' | 'name' | 'assignmentType'
  >[];
  masterDataReviewIndicatorOptionListError?: MasterDataReviewIndicatorOptionListFailedPayload;
  masterDataReviewIndicatorOptionListParams?: MasterDataReviewIndicatorOptionListFetchPayload;
  masterDataReviewIndicatorOptionListResponse?: MasterDataReviewIndicatorOptionListSuccessPayload;
}

export type MasterDataReviewIndicatorOptionListResponse = {
  status: string;
  count?: number;
  data: Pick<MasterDataReviewIndicator, 'id' | 'name' | 'assignmentType'>[];
};

export type MasterDataReviewIndicatorOptionListFailedPayload = CommonError;

export type MasterDataReviewIndicatorOptionListSuccessPayload = MasterDataReviewIndicatorOptionListResponse;

export type MasterDataReviewIndicatorOptionListUpdatePayload = MasterDataReviewIndicatorOptionListResponse;

export interface MasterDataReviewIndicatorOptionListFetchPayload {
  assignmentType: string;
}
