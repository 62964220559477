/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  MasterDataCategoryCreateAction,
  MasterDataCategoryDeleteAction,
  MasterDataCategoryEditAction,
  MasterDataCategoryListAction,
  MasterDataCategoryMoveAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {
  masterDataCategoryCreateAction,
  masterDataCategoryDeleteAction,
  masterDataCategoryEditAction,
  masterDataCategoryListAction,
  masterDataCategoryMoveAction,
} from '../../../../usecases/masterData.action';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import MasterDataCategoryFormCreateContainer from '../MasterDataCategoryFormCreateContainer';
import MasterDataCategoryFormEditContainer from '../MasterDataCategoryFormEditContainer';
import {CategoryManagementList} from './components';
// Data
import {MasterDataCategory} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataCategorySection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataCategorySection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddCategory, setAddCategory] = useState(false);
  const [isDeleteCategory, setIsDeleteCategory] = useState<
    MasterDataCategory | undefined
  >(undefined);
  const [isEditCategory, setIsEditCategory] = useState<
    MasterDataCategory | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddCategory = masterData.masterDataCategoryCreateLoading;
  const isLoadingEditCategory = masterData.masterDataCategoryEditLoading;
  const isLoadingDeleteCategory = masterData.masterDataCategoryDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataCategoryListPage,
    setMasterDataCategoryListPage,
  ] = useState<number>(0);
  const [
    masterDataCategoryListCount,
    setMasterDataCategoryListCount,
  ] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataCategoryListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataCategoryListPage(0);
  };
  //#endregion

  //#region FETCH CATEGORY
  const fetchCategoryList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataCategoryListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataCategoryListAction.reset());
    fetchCategoryList(masterDataCategoryListPage, searchValue, rowsPerPage);

    return () => {
      dispatch(masterDataCategoryListAction.reset());
    };
  }, [
    dispatch,
    fetchCategoryList,
    masterDataCategoryListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataCategoryActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataCategoryListAction.UPDATE, (): void => {
            setMasterDataCategoryListCount(
              masterData?.masterDataCategoryListResponse?.data?.totalElements ??
                0,
            );
          })
          .addCase(MasterDataCategoryListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataCategoryListAction.reset());
          })
          .addCase(MasterDataCategoryCreateAction.SUCCESS, (): void => {
            setAddCategory(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataCategoryCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataCategoryCreateAction.reset());
          })
          .addCase(MasterDataCategoryEditAction.SUCCESS, (): void => {
            setIsEditCategory(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataCategoryEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataCategoryEditAction.reset());
          })
          .addCase(MasterDataCategoryDeleteAction.SUCCESS, (): void => {
            setIsDeleteCategory(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataCategoryDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataCategoryDeleteAction.reset());
          })
          .addCase(MasterDataCategoryMoveAction.SUCCESS, (): void => {
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved success!'),
                type: 'success',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataCategoryMoveAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataCategoryMoveAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataCategoryListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataCategoryActionHandler(masterData.action);
  }, [masterData.action, masterDataCategoryActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataCategoryDeleteAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE MOVE
  const handleMove = (id: string, isUp?: boolean) => {
    dispatch(
      masterDataCategoryMoveAction.fetch({
        id,
        ...(isUp ? {isUp: true} : {isDown: true}),
        assignmentType,
      }),
    );
  };
  //#endregion

  return (
    <>
      <div className="master-data-category-section">
        <div className="master-data-category-section__header">
          <MSearchInput
            inputClassName="master-data-category-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Category')}
            setInputValue={(search: string) => {
              setMasterDataCategoryListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-category-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddCategory(true)}
              />
            </div>
          )}
        </div>
        <CategoryManagementList
          categoryList={masterData.masterDataCategoryListData}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditCategory: (_category: MasterDataCategory) =>
              setIsEditCategory(_category),
            onMoveUp: (id: string) => handleMove(id, true),
            onMoveDown: (id: string) => handleMove(id),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteCategory: (_category: MasterDataCategory) =>
              setIsDeleteCategory(_category),
          })}
        />
        <MTablePagination
          className="master-data-category-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataCategoryListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataCategoryListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddCategory(false)}
        title={t('Add Category')}
        isOpen={isAddCategory}>
        <MasterDataCategoryFormCreateContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddCategory}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditCategory && (
        <Modal
          onClose={() => setIsEditCategory(undefined)}
          isOpen={!!isEditCategory}
          title={t('Edit Category')}>
          <MasterDataCategoryFormEditContainer
            assignmentType={assignmentType}
            data={isEditCategory}
            isLoading={isLoadingEditCategory}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteCategory && (
        <AlertDialog
          onClose={() => setIsDeleteCategory(undefined)}
          onCancel={() => setIsDeleteCategory(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteCategory.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteCategory}
          isLoading={isLoadingDeleteCategory}
          image={<IllustrationTrashImage />}
          description={t(
            'Are you sure you want to delete the data?',
          )}></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddCategory(false)
              : setIsEditCategory(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataCategorySection;
