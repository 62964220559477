export const WrapperNotifyAction = {
  FETCH: 'WRAPPER_NOTIFY_FETCH',
  RESET: 'WRAPPER_NOTIFY_RESET',
};
export interface WrapperNotifyProps {
  action: string;
  isSnackbar?: boolean;
  type: 'success' | 'warning' | 'error' | 'info';
  text: string;
}

export type WrapperNotifyFetchPayload = WrapperNotifyProps;

export interface WrapperNotifyInitialState {
  notification?: WrapperNotifyProps;
}
