/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import moment, {Moment} from 'moment';
// Utils
import t from '../../../../../lang';
import {AppState} from '../../../../../config/Interface';
import {
  workReportCreateAction,
  workReportDeleteAction,
  workReportEditAction,
  workReportExportAllAction,
  workReportListAction,
} from '../../../usecases/workReport.action';
import {masterDataDepartmentListAction} from '../../../../masterData/usecases/masterData.action';
import {
  WorkReportCreateAction,
  WorkReportDeleteAction,
  WorkReportEditAction,
  WorkReportExportAllAction,
  WorkReportListAction,
} from '../../../usecases/workReport.type';
import {getOptionFromDepartmentList} from '../../../../../utils';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../../config/constant';
// Components
import {
  AlertDialog,
  MBottomSheet,
  MButton,
  Modal,
  MTablePagination,
} from '../../../../../components';
import {
  WorkReportDetail,
  WorkReportDetailHeader,
  WorkReportFilter,
  WorkReportManagementList,
} from '../../../components';
import WorkReportDownloadAllFormContainer from '../WorkReportDownloadAllFormContainer';
import WorkReportAdminFormContainer from '../WorkReportAdminFormContainer';
// Data
import {
  WorkReport,
  WorkReportOrderByType,
  WorkReportType,
} from '../../../entity/workReport.string.entity';
import {BackOfficePermissionType} from '../../../../user/entity/user.string.entity';
// Assets
import './WorkReportAdminSection.scss';
import {IllustrationTrashImage} from '../../../../../assets/illustrations';
//#endregion

const WorkReportAdminSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [workReportList, setWorkReportList] = useState<WorkReport[]>([]);
  const [isAddWorkReport, setAddWorkReport] = useState(false);
  const [isViewWorkReport, setIsViewWorkReport] = useState<
    WorkReport | undefined
  >(undefined);
  const [isEditWorkReport, setIsEditWorkReport] = useState<
    WorkReport | undefined
  >(undefined);
  const [isDeleteWorkReport, setIsDeleteWorkReport] = useState<
    WorkReport | undefined
  >(undefined);
  const [isDownloadAllWorkReport, setIsDownloadAllWorkReport] = useState(false);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const workReportData = useSelector(
    (state: AppState) => state.workReport,
    shallowEqual,
  );
  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions: BackOfficePermissionType | undefined =
    data?.role?.permissions;

  const isLoadingAddWorkReport = workReportData.workReportCreateLoading;
  const isLoadingEditWorkReport = workReportData.workReportEditLoading;
  const isLoadingDeleteWorkReport = workReportData.workReportDeleteLoading;
  const isLoadingDownloadAllWorkReport =
    workReportData.workReportExportAllLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [workReportListPage, setWorkReportListPage] = useState<number>(0);

  const [workReportListCount, setWorkReportListCount] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setWorkReportListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setWorkReportListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<WorkReportOrderByType>('name');

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: WorkReportOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FILTER
  const [
    selectedWorkReportTypeFilter,
    setSelectedWorkReportTypeFilter,
  ] = useState<WorkReportType | undefined>(undefined);
  const workReportTypeFilterOptions = [
    {label: WorkReportType.MONTHLY, value: WorkReportType.MONTHLY},
    {
      label: WorkReportType.RECONCILIATION,
      value: WorkReportType.RECONCILIATION,
    },
  ];
  const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState<
    string | undefined
  >(undefined);
  const [selectedDateFilter, setSelectedDateFilter] = useState<
    Moment | string | null
  >(null);
  //#endregion

  //#region PDF VIEWER CONTROL
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(100);

  const handlePDFChangePage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(event.currentTarget.value.replace(/[^0-9]+/g, ''));
    setPageNumber(val);
  };

  const handlePDFIncreaseScale = () => {
    if (scale < 300) {
      const increased = scale + 10;
      setScale(increased);
    }
  };

  const handlePDFDecreaseScale = () => {
    if (scale > 10) {
      const decreased = scale - 10;
      setScale(decreased);
    }
  };

  const handleCloseViewWorkReport = () => {
    setIsViewWorkReport(undefined);
    setPageNumber(1);
    setNumPages(1);
    setScale(100);
  };
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(workReportDeleteAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE DOWNLOAD
  const handleDownload = (data: WorkReport, isMain: boolean) => {
    window.location.href = isMain ? data.mainFile : data.supportFile;
  };
  //#endregion

  //#region FETCH DEPARTMENT
  const fetchDepartmentList = useCallback(() => {
    dispatch(masterDataDepartmentListAction.fetch({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(masterDataDepartmentListAction.reset());
    fetchDepartmentList();

    return () => {
      dispatch(masterDataDepartmentListAction.reset());
    };
  }, [dispatch, fetchDepartmentList]);
  //#endregion

  //#region FETCH WORK REPORT LIST
  const fetchWorkReportList = useCallback(
    (
      page?: number,
      size?: number,
      dateFilter?: Moment | string | null,
      reportTypeFilter?: WorkReportType,
      departmentFilter?: string,
    ) => {
      dispatch(
        workReportListAction.fetch({
          page,
          size,
          ...(dateFilter ? {month: (dateFilter as Moment).format('MMMM')} : {}),
          ...(dateFilter ? {year: (dateFilter as Moment).format('YYYY')} : {}),
          ...(reportTypeFilter ? {reportType: reportTypeFilter} : {}),
          ...(departmentFilter ? {departmentId: departmentFilter} : {}),
        }),
      );
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(workReportListAction.reset());
    fetchWorkReportList(
      workReportListPage,
      rowsPerPage,
      selectedDateFilter,
      selectedWorkReportTypeFilter,
      selectedDepartmentFilter,
    );

    return () => {
      dispatch(workReportListAction.reset());
    };
  }, [
    dispatch,
    fetchWorkReportList,
    workReportListPage,
    rowsPerPage,
    selectedDateFilter,
    selectedWorkReportTypeFilter,
    selectedDepartmentFilter,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const workReportActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(WorkReportListAction.UPDATE, (): void => {
            const totalElement =
              workReportData?.workReportListResponse?.data?.totalElements;
            const data: WorkReport[] = workReportData.workReportListData;
            if (data) {
              const dataTemp: WorkReport[] = data.map((item: WorkReport) => {
                return {
                  ...item,
                  year: moment()
                    .year(+item?.year)
                    .month(item.month)
                    .valueOf(),
                  month: moment().month(item.month).valueOf(),
                  uploadedTime: moment(
                    item.uploadedTime,
                    'YYYY-MM-DD',
                  ).valueOf(),
                };
              });
              setWorkReportList(dataTemp);
              setWorkReportListCount(totalElement ?? 0);
            }
          })
          .addCase(WorkReportListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(workReportListAction.reset());
          })
          .addCase(WorkReportCreateAction.SUCCESS, (): void => {
            setAddWorkReport(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(WorkReportCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(workReportCreateAction.reset());
          })
          .addCase(WorkReportEditAction.SUCCESS, (): void => {
            setIsEditWorkReport(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(WorkReportEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(workReportEditAction.reset());
          })
          .addCase(WorkReportDeleteAction.SUCCESS, (): void => {
            setIsDeleteWorkReport(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(WorkReportDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(workReportDeleteAction.reset());
          })
          .addCase(WorkReportExportAllAction.SUCCESS, (): void => {
            setIsDownloadAllWorkReport(false);
            refresh();
          })
          .addCase(WorkReportExportAllAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data export failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(workReportExportAllAction.reset());
          });
      });
    },
    [
      dispatch,
      refresh,
      workReportData.workReportListData,
      workReportData?.workReportListResponse?.data?.totalElements,
    ],
  );
  useEffect(() => {
    workReportActionHandler(workReportData.action);
  }, [workReportData.action, workReportActionHandler]);
  //#endregion

  return (
    <>
      <div className="work-report-admin-section">
        <div className="work-report-admin-section__header">
          <div className="work-report-admin-section__header--filter">
            <WorkReportFilter
              selectedWorkReportTypeFilter={selectedWorkReportTypeFilter}
              workReportTypeFilterOptions={workReportTypeFilterOptions}
              handleWorkReportTypeFilter={(workReportType) => {
                setWorkReportListPage(0);
                setSelectedWorkReportTypeFilter(
                  workReportType as WorkReportType,
                );
              }}
              selectedDepartmentFilter={selectedDepartmentFilter}
              departmentFilterOptions={getOptionFromDepartmentList(
                masterData.masterDataDepartmentListData,
              )}
              handleDepartmentFilter={(department) => {
                setWorkReportListPage(0);
                setSelectedDepartmentFilter(department);
              }}
              selectedDateFilter={selectedDateFilter}
              handleChangeDateFilter={(date: Moment | string | null) => {
                setWorkReportListPage(0);
                setSelectedDateFilter(date);
              }}
              handleResetFilter={() => {
                setWorkReportListPage(0);
                setSelectedWorkReportTypeFilter(undefined);
                setSelectedDepartmentFilter(undefined);
                setSelectedDateFilter('');
              }}
            />
          </div>
          <div className="work-report-admin-section__header--action">
            {permissions?.otherWorkReport.havePermissionCreate && (
              <div className="work-report-admin-section__header--action-add">
                <MButton
                  buttonLabel={t('Add')}
                  handleClick={() => setAddWorkReport(true)}
                />
              </div>
            )}
            {permissions?.otherWorkReport.havePermissionDownload && (
              <div className="work-report-admin-section__header--action-download">
                <MButton
                  buttonLabel={t('Download All')}
                  handleClick={() => setIsDownloadAllWorkReport(true)}
                />
              </div>
            )}
          </div>
        </div>
        <WorkReportManagementList
          workReportList={workReportList}
          sortBy={sortBy}
          orderBy={orderBy}
          isAdmin
          onHandleSort={handleSort}
          {...(permissions?.otherWorkReport.havePermissionUpdate && {
            onEditWorkReport: (_workReport: WorkReport) =>
              setIsEditWorkReport(_workReport),
          })}
          {...(permissions?.otherWorkReport.havePermissionDelete && {
            onDeleteWorkReport: (_workReport: WorkReport) =>
              setIsDeleteWorkReport(_workReport),
          })}
          {...(permissions?.otherWorkReport.havePermissionDownload && {
            onDownloadWorkReport: (isMain: boolean, _workReport: WorkReport) =>
              handleDownload(_workReport, isMain),
          })}
        />
        <MTablePagination
          className="work-report-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={workReportListCount}
          rowsPerPage={rowsPerPage}
          page={workReportListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddWorkReport(false)}
        title={t('Add Monthly Reconciliation Report')}
        isOpen={isAddWorkReport}>
        <WorkReportAdminFormContainer
          isLoading={isLoadingAddWorkReport}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isViewWorkReport && (
        <MBottomSheet
          onClose={handleCloseViewWorkReport}
          isOpen={!!isViewWorkReport}
          titleComponent={
            <WorkReportDetailHeader
              data={isViewWorkReport}
              pageNumber={pageNumber}
              numPages={numPages}
              scale={scale}
              onIncreaseScale={handlePDFIncreaseScale}
              onDecreaseScale={handlePDFDecreaseScale}
              onChangePage={handlePDFChangePage}
            />
          }>
          <WorkReportDetail
            onNumPages={(pdf) => setNumPages(pdf.numPages)}
            pageNumber={pageNumber}
            scale={scale}
            data={isViewWorkReport}
          />
        </MBottomSheet>
      )}

      {isDownloadAllWorkReport && (
        <Modal
          onClose={() => setIsDownloadAllWorkReport(false)}
          isOpen={!!isDownloadAllWorkReport}
          title={t('Choose File')}>
          <WorkReportDownloadAllFormContainer
            isSPM
            isLoading={isLoadingDownloadAllWorkReport}
            onCancel={() => setIsDownloadAllWorkReport(false)}
          />
        </Modal>
      )}

      {isEditWorkReport && (
        <Modal
          onClose={() => setIsEditWorkReport(undefined)}
          isOpen={!!isEditWorkReport}
          title={t('Edit Monthly Reconciliation Report')}>
          <WorkReportAdminFormContainer
            data={isEditWorkReport}
            isLoading={isLoadingEditWorkReport}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteWorkReport && (
        <AlertDialog
          onClose={() => setIsDeleteWorkReport(undefined)}
          onCancel={() => setIsDeleteWorkReport(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteWorkReport.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteWorkReport}
          isLoading={isLoadingDeleteWorkReport}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddWorkReport(false)
              : setIsEditWorkReport(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default WorkReportAdminSection;
