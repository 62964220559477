//#region DASHBOARD SPM
import dashboardSPMChartSection from './section/spm/dashboardSPMChartSection/dashboardSPMChartSection.saga';
//#endregion
//#region DASHBOARD OVERVIEW
import dashboardOverviewBestSection from './section/overview/dashboardOverviewBestSection/dashboardOverviewBestSection.saga';
import dashboardOverviewChartSection from './section/overview/dashboardOverviewChartSection/dashboardOverviewChartSection.saga';
import dashboardOverviewSetProfit from './section/overview/dashboardOverviewSetProfit/dashboardOverviewSetProfit.saga';
import dashboardOverviewExportAchievementFinalMonthly from './section/overview/dashboardOverviewExportAchievementFinalMonthly/dashboardOverviewExportAchievementFinalMonthly.saga';
import dashboardOverviewExportAchievementFinalQuarter from './section/overview/dashboardOverviewExportAchievementFinalQuarter/dashboardOverviewExportAchievementFinalQuarter.saga';

//#endregion
//#region DASHBOARD DEPARTMENT
import dashboardTabDepartmentList from './section/department/dashboardTabDepartmentList/dashboardTabDepartmentList.saga';
import dashboardDepartmentBestSection from './section/department/dashboardDepartmentBestSection/dashboardDepartmentBestSection.saga';
import dashboardDepartmentChartSection from './section/department/dashboardDepartmentChartSection/dashboardDepartmentChartSection.saga';
//#endregion

export default [
  //#region DASHBOARD SPM
  ...dashboardSPMChartSection,
  //#endregion
  //#region DASHBOARD DEPARTMENT
  ...dashboardOverviewSetProfit,
  ...dashboardOverviewBestSection,
  ...dashboardOverviewChartSection,
  ...dashboardOverviewExportAchievementFinalMonthly,
  ...dashboardOverviewExportAchievementFinalQuarter,
  //#endregion
  //#region DASHBOARD DEPARTMENT
  ...dashboardTabDepartmentList,
  ...dashboardDepartmentBestSection,
  ...dashboardDepartmentChartSection,
  //#endregion
];
