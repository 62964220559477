import {createAction} from '@reduxjs/toolkit';
import {
  NotificationReadAllAction,
  NotificationReadAllFailedPayload,
  NotificationReadAllFetchPayload,
  NotificationReadAllSuccessPayload,
} from './notificationReadAll.type';

export const notificationReadAllAction = {
  fetch: createAction<NotificationReadAllFetchPayload>(
    NotificationReadAllAction.FETCH,
  ),
  success: createAction<NotificationReadAllSuccessPayload>(
    NotificationReadAllAction.SUCCESS,
  ),
  failed: createAction<NotificationReadAllFailedPayload>(
    NotificationReadAllAction.FAILED,
  ),
  reset: createAction(NotificationReadAllAction.RESET),
};
