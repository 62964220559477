import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataFormControllerListAction,
  MasterDataFormControllerListFailedPayload,
  MasterDataFormControllerListFetchPayload,
  MasterDataFormControllerListSuccessPayload,
  MasterDataFormControllerListUpdatePayload,
} from './masterDataFormControllerList.type';

export const masterDataFormControllerListAction = {
  fetch: createAction<MasterDataFormControllerListFetchPayload>(
    MasterDataFormControllerListAction.FETCH,
  ),
  success: createAction<MasterDataFormControllerListSuccessPayload>(
    MasterDataFormControllerListAction.SUCCESS,
  ),
  failed: createAction<MasterDataFormControllerListFailedPayload>(
    MasterDataFormControllerListAction.FAILED,
  ),
  update: createAction<MasterDataFormControllerListUpdatePayload>(
    MasterDataFormControllerListAction.UPDATE,
  ),
  reset: createAction(MasterDataFormControllerListAction.RESET),
};
