import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDirectorateCreateAction,
  MasterDataDirectorateCreateFailedPayload,
  MasterDataDirectorateCreateFetchPayload,
  MasterDataDirectorateCreateSuccessPayload,
} from './masterDataDirectorateCreate.type';

export const masterDataDirectorateCreateAction = {
  fetch: createAction<MasterDataDirectorateCreateFetchPayload>(
    MasterDataDirectorateCreateAction.FETCH,
  ),
  success: createAction<MasterDataDirectorateCreateSuccessPayload>(
    MasterDataDirectorateCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataDirectorateCreateFailedPayload>(
    MasterDataDirectorateCreateAction.FAILED,
  ),
  reset: createAction(MasterDataDirectorateCreateAction.RESET),
};
