import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataCategoryListAction,
  MasterDataCategoryListFailedPayload,
  MasterDataCategoryListFetchPayload,
  MasterDataCategoryListSuccessPayload,
  MasterDataCategoryListUpdatePayload,
} from './masterDataCategoryList.type';

export const masterDataCategoryListAction = {
  fetch: createAction<MasterDataCategoryListFetchPayload>(
    MasterDataCategoryListAction.FETCH,
  ),
  success: createAction<MasterDataCategoryListSuccessPayload>(
    MasterDataCategoryListAction.SUCCESS,
  ),
  failed: createAction<MasterDataCategoryListFailedPayload>(
    MasterDataCategoryListAction.FAILED,
  ),
  update: createAction<MasterDataCategoryListUpdatePayload>(
    MasterDataCategoryListAction.UPDATE,
  ),
  reset: createAction(MasterDataCategoryListAction.RESET),
};
