import React, {useCallback} from 'react';
import {FieldProps} from 'formik';
import {useDropzone} from 'react-dropzone';
import {IconFilePDF, IconDummyImage} from '../../assets/icons';
import t from '../../lang';
import classnames from 'classnames';
import './MDropzone.component.style.scss';
import {bytesToSize, checkAcceptedType} from '../../utils';

interface Props extends FieldProps<string> {
  accept?: string | string[];
  maxSize?: number;
  disabled?: boolean;
  className?: string;
  errormessage?: string;
  helperText?: string;
}

const MDropzone: React.FC<Props> = ({
  accept = [],
  maxSize = 5000000,
  disabled = false,
  className,
  errormessage,
  helperText,
  field,
  form,
  ...otherProps
}: Props) => {
  const isError = errormessage || form.errors[field.name];
  const classes = classnames(
    'm-dropzone',
    {
      'm-dropzone--error': isError,
    },
    className,
  );
  const acceptedType = checkAcceptedType(accept);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles) {
        const fileTemp = acceptedFiles[0];
        form.setFieldValue(field.name, fileTemp);
      }
    },
    [form, field.name],
  );

  const onDropRejected = useCallback(
    async (rejectedFiles) => {
      const fileTemp = rejectedFiles[0];
      form.setFieldValue(field.name, fileTemp.file);
    },
    [form, field.name],
  );

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: accept,
    maxSize: maxSize,
    disabled: disabled,
    onDropRejected,
  });

  const containerClasses = classnames(isDragActive ? 'active' : '');

  const handleDelete = () => {
    form.setFieldValue(field.name, undefined);
  };

  const handlePreview = (file: File): string => {
    return URL.createObjectURL(file);
  };

  const emptyFiles = () => {
    return (
      <div className="m-dropzone-container-wrapper__empty">
        {acceptedType === 'image' ? <IconDummyImage /> : <IconFilePDF />}
        <div className="m-dropzone-container-wrapper__empty--desc">
          {acceptedType === 'image'
            ? t('Drop your image file here, or')
            : t('Drop your .pdf file here, or')}
        </div>
        <div className="m-dropzone-container-wrapper__empty--action">
          {t('Choose File')}
        </div>
      </div>
    );
  };

  const uploadedFiles = (file: File | undefined) => {
    return (
      <div className="m-dropzone-container-wrapper__uploaded">
        <div className="m-dropzone-container-wrapper__uploaded--icon">
          {acceptedType === 'image' ? (
            <img src={handlePreview(form.values[field.name])} />
          ) : (
            <IconFilePDF />
          )}
        </div>
        <div className="m-dropzone-container-wrapper__uploaded--caption">
          <div className="m-dropzone-container-wrapper__uploaded--caption-name">
            {file?.name}
          </div>
          <div className="m-dropzone-container-wrapper__uploaded--caption-size">
            {bytesToSize(file?.size, 2)}
          </div>
        </div>
        <div
          className="m-dropzone-container-wrapper__uploaded--action"
          onClick={handleDelete}>
          {t('Delete')}
        </div>
      </div>
    );
  };

  return (
    <div className={classes}>
      <div className={`m-dropzone-container ${containerClasses} ${className}`}>
        <div className="m-dropzone-container-wrapper" {...getRootProps()}>
          {form.values[field.name] ? (
            uploadedFiles(form.values[field.name])
          ) : (
            <>
              <input
                {...getInputProps()}
                type="file"
                name={field.name}
                {...otherProps}
              />
              {emptyFiles()}
            </>
          )}
        </div>
      </div>
      {(errormessage || form.errors[field.name]) && (
        <div className="m-dropzone-container__errorText error--text">
          {errormessage || form.errors[field.name]}
        </div>
      )}
      {helperText && !form.errors && (
        <div className="m-dropzone-container__helperText helper-text">
          {helperText}
        </div>
      )}
    </div>
  );
};

export default MDropzone;
