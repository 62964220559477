import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataObservationOptionListAction} from './masterDataObservationOptionList.action';
import {
  MasterDataObservationOptionListAction,
  MasterDataObservationOptionListFetchPayload,
  MasterDataObservationOptionListResponse,
  MasterDataObservationOptionListSuccessPayload,
} from './masterDataObservationOptionList.type';

function* workerFetch(
  action: GenericAction<MasterDataObservationOptionListFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataObservationOptionListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/observation/ol`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataObservationOptionListAction.success(
          res.data as MasterDataObservationOptionListResponse,
        ),
      );
      yield put(
        masterDataObservationOptionListAction.update(
          res.data as MasterDataObservationOptionListResponse,
        ),
      );
    } else {
      yield put(
        masterDataObservationOptionListAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(masterDataObservationOptionListAction.failed(e as CommonError));
  }
}

const masterDataObservationOptionListWatcher = [
  takeLatest(MasterDataObservationOptionListAction.FETCH, workerFetch),
];

export default masterDataObservationOptionListWatcher;
