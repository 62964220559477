import {createAction} from '@reduxjs/toolkit';
import {
  WrapperNotifyAction,
  WrapperNotifyFetchPayload,
} from './wrapperNotify.type';

export const wrapperNotifyAction = {
  fetch: createAction<WrapperNotifyFetchPayload>(WrapperNotifyAction.FETCH),
  reset: createAction(WrapperNotifyAction.RESET),
};
