import {CommonData, CommonError} from '../../../../config/constant';
import {SortType} from '../../../wrapper/entity';
import {FollowUp, FollowUpStatus} from '../../entity/followUp.string.entity';

export const FollowUpKadepListAction = {
  FETCH: 'FOLLOW_UP_KADEP_LIST_FETCH',
  SUCCESS: 'FOLLOW_UP_KADEP_LIST_SUCCESS',
  FAILED: 'FOLLOW_UP_KADEP_LIST_FAILED',
  RESET: 'FOLLOW_UP_KADEP_LIST_RESET',
  UPDATE: 'FOLLOW_UP_KADEP_LIST_UPDATE',
};

export interface FollowUpKadepListInitialState {
  followUpKadepListLoading: boolean;
  followUpKadepListData: FollowUp[];
  followUpKadepListError?: FollowUpKadepListFailedPayload;
  followUpKadepListParams?: FollowUpKadepListFetchPayload;
  followUpKadepListResponse?: FollowUpKadepListSuccessPayload;
}

export type FollowUpKadepListResponse = {
  status: string;
  info?: number;
  data: CommonData<FollowUp[]>;
};

export type FollowUpKadepListFailedPayload = CommonError;

export type FollowUpKadepListSuccessPayload = FollowUpKadepListResponse;

export type FollowUpKadepListUpdatePayload = FollowUpKadepListResponse;

export interface FollowUpKadepListFetchPayload {
  page?: number;
  size?: number;
  status?: FollowUpStatus;
  sort?: SortType;
  filter?: string;
  startDate?: string;
  endDate?: string;
  isExpired?: boolean;
}
