import {createAction} from '@reduxjs/toolkit';
import {
  UserAccountEditAction,
  UserAccountEditFailedPayload,
  UserAccountEditFetchPayload,
  UserAccountEditSuccessPayload,
} from './userAccountEdit.type';

export const userAccountEditAction = {
  fetch: createAction<UserAccountEditFetchPayload>(UserAccountEditAction.FETCH),
  success: createAction<UserAccountEditSuccessPayload>(
    UserAccountEditAction.SUCCESS,
  ),
  failed: createAction<UserAccountEditFailedPayload>(
    UserAccountEditAction.FAILED,
  ),
  reset: createAction(UserAccountEditAction.RESET),
};
