//#region IMPORT
// Libraries
import React from 'react';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
// Utils
import t from '../../../../lang';
// Component
import {MButton, MTextField} from '../../../../components';
// Data
import {FollowUpExpiredFormData} from '../../entity/followUp.string.entity';
// Assets
import './FollowUpCardInput.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  initialValues: FollowUpExpiredFormData;
  isLoading?: boolean;
  isDisabled?: boolean;
  label: string;
  placeholder: string;
  isDetail?: boolean;
  handleSubmitFollowUp(_note: FollowUpExpiredFormData): void;
}
//#endregion

const FollowUpCardInput: React.FC<Props> = ({
  initialValues,
  isLoading,
  isDisabled,
  label,
  placeholder,
  isDetail = false,
  handleSubmitFollowUp,
}: Props) => {
  //#region VALIDATION
  const followUpValidation = Yup.object().shape({
    note: Yup.string().required(t('Expired Note is required.')),
  });
  //#endregion

  return (
    <div className="follow-up-card-input">
      <Formik
        initialValues={initialValues}
        validationSchema={followUpValidation}
        onSubmit={handleSubmitFollowUp}>
        {({handleSubmit, isValid}): React.ReactElement => (
          <>
            <div className="follow-up-card-input__section">
              <div className="follow-up-card-input__section--field">
                <div className="input-label">{label}</div>
                <Field
                  className="user-input"
                  isTextArea={true}
                  textFieldRows={5}
                  name="note"
                  placeholder={placeholder}
                  disabled={isDisabled || isLoading}
                  component={MTextField}
                />
              </div>
            </div>

            <div className="follow-up-card-input__section--action">
              <div
                className={`follow-up-card-input__section--action${
                  isDetail ? '-detail' : '-item'
                }`}>
                <MButton
                  isDisabled={!isValid || isDisabled || isLoading}
                  isLoading={isLoading}
                  buttonLabel={t('Send Expired Note')}
                  handleClick={handleSubmit}
                />
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
export default FollowUpCardInput;
