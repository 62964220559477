import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBasicServiceTypeDeleteAction,
  MasterDataBasicServiceTypeDeleteFailedPayload,
  MasterDataBasicServiceTypeDeleteFetchPayload,
  MasterDataBasicServiceTypeDeleteSuccessPayload,
} from './masterDataBasicServiceTypeDelete.type';

export const masterDataBasicServiceTypeDeleteAction = {
  fetch: createAction<MasterDataBasicServiceTypeDeleteFetchPayload>(
    MasterDataBasicServiceTypeDeleteAction.FETCH,
  ),
  success: createAction<MasterDataBasicServiceTypeDeleteSuccessPayload>(
    MasterDataBasicServiceTypeDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataBasicServiceTypeDeleteFailedPayload>(
    MasterDataBasicServiceTypeDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataBasicServiceTypeDeleteAction.RESET),
};
