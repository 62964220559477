import {AxiosResponse} from 'axios';
import {call, put, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {post} from '../../../../services/http';
import {userForgetPasswordAction} from './userForgetPassword.action';
import {
  UserForgetPasswordAction,
  UserForgetPasswordFetchPayload,
  UserForgetPasswordSuccessPayload,
} from './userForgetPassword.type';

function* workerFetch(action: GenericAction<UserForgetPasswordFetchPayload>) {
  try {
    const res: AxiosResponse<UserForgetPasswordSuccessPayload> = yield call(
      post,
      `${Env.api}/open/forgot_password`,
      action.payload,
    );
    if (res.status === 200) {
      yield put(userForgetPasswordAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userForgetPasswordAction.failed(e.response.data.payload));
  }
}

const userForgetPasswordWatcher = [
  takeLatest(UserForgetPasswordAction.FETCH, workerFetch),
];

export default userForgetPasswordWatcher;
