import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataAssignmentLetterListAction,
  MasterDataAssignmentLetterListFailedPayload,
  MasterDataAssignmentLetterListFetchPayload,
  MasterDataAssignmentLetterListSuccessPayload,
  MasterDataAssignmentLetterListUpdatePayload,
} from './masterDataAssignmentLetterList.type';

export const masterDataAssignmentLetterListAction = {
  fetch: createAction<MasterDataAssignmentLetterListFetchPayload>(
    MasterDataAssignmentLetterListAction.FETCH,
  ),
  success: createAction<MasterDataAssignmentLetterListSuccessPayload>(
    MasterDataAssignmentLetterListAction.SUCCESS,
  ),
  failed: createAction<MasterDataAssignmentLetterListFailedPayload>(
    MasterDataAssignmentLetterListAction.FAILED,
  ),
  update: createAction<MasterDataAssignmentLetterListUpdatePayload>(
    MasterDataAssignmentLetterListAction.UPDATE,
  ),
  reset: createAction(MasterDataAssignmentLetterListAction.RESET),
};
