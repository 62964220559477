import {
  DashboardOverviewBestSectionAction,
  DashboardOverviewBestSectionSuccessPayload,
  DashboardOverviewBestSectionFailedPayload,
  DashboardOverviewBestSectionFetchPayload,
} from './dashboardOverviewBestSection.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardOverviewBestSectionInitialState} from './dashboardOverviewBestSection.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardOverviewBestSectionHandler = {
  [DashboardOverviewBestSectionAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewBestSectionFetchPayload>,
  ): DashboardState => {
    return {
      ...state,
      dashboardOverviewBestSectionParams: action.payload,
      dashboardOverviewBestSectionLoading: true,
      action: DashboardOverviewBestSectionAction.FETCH,
    };
  },
  [DashboardOverviewBestSectionAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewBestSectionSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewBestSectionLoading: false,
    dashboardOverviewBestSectionResponse: action.payload,
    action: DashboardOverviewBestSectionAction.SUCCESS,
  }),
  [DashboardOverviewBestSectionAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardOverviewBestSectionFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardOverviewBestSectionLoading: false,
    dashboardOverviewBestSectionError: action.payload,
    action: DashboardOverviewBestSectionAction.FAILED,
  }),
  [DashboardOverviewBestSectionAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardOverviewBestSectionInitialState,
    action: DashboardOverviewBestSectionAction.RESET,
  }),
};
