//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDirectorateCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataDirectorateFormCreate} from './components';
import {DirectorateFormCreateInitialValues} from './components/MasterDataDirectorateFormCreate/MasterDataDirectorateFormCreate.component';
// Assets
import './MasterDataDirectorateFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDirectorateFormCreateContainer: React.FC<Props> = ({
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: DirectorateFormCreateInitialValues = {
    directorate: [{name: ''}],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDirectorate = (
    formValue: DirectorateFormCreateInitialValues,
  ) => {
    const directorateTemp = formValue.directorate.map((item) => {
      return {name: item.name};
    });
    const formattedValue = directorateTemp || [];
    dispatch(masterDataDirectorateCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-directorate-form-create-container">
      <MasterDataDirectorateFormCreate
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitDirectorate={handleSubmitDirectorate}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default MasterDataDirectorateFormCreateContainer;
