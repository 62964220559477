import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {followUpDetailAction} from './followUpDetail.action';
import {
  FollowUpDetailAction,
  FollowUpDetailFetchPayload,
  FollowUpDetailResponse,
  FollowUpDetailSuccessPayload,
} from './followUpDetail.type';

function* workerFetch(action: GenericAction<FollowUpDetailFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const {id} = action.payload;
    const res: AxiosResponse<FollowUpDetailSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/follow-up/find-by-id/${id}`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        followUpDetailAction.success(res.data as FollowUpDetailResponse),
      );
    } else {
      yield put(followUpDetailAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(followUpDetailAction.failed(e as CommonError));
  }
}

const followUpDetailWatcher = [
  takeLatest(FollowUpDetailAction.FETCH, workerFetch),
];

export default followUpDetailWatcher;
