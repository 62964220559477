import {createAction} from '@reduxjs/toolkit';
import {
  UserAccessCreateAction,
  UserAccessCreateFailedPayload,
  UserAccessCreateFetchPayload,
  UserAccessCreateSuccessPayload,
} from './userAccessCreate.type';

export const userAccessCreateAction = {
  fetch: createAction<UserAccessCreateFetchPayload>(
    UserAccessCreateAction.FETCH,
  ),
  success: createAction<UserAccessCreateSuccessPayload>(
    UserAccessCreateAction.SUCCESS,
  ),
  failed: createAction<UserAccessCreateFailedPayload>(
    UserAccessCreateAction.FAILED,
  ),
  reset: createAction(UserAccessCreateAction.RESET),
};
