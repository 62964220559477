import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userForgetPasswordInitialState} from './userForgetPassword.reducer';
import {
  UserForgetPasswordAction,
  UserForgetPasswordFailedPayload,
  UserForgetPasswordFetchPayload,
  UserForgetPasswordSuccessPayload,
} from './userForgetPassword.type';

export const userForgetPasswordHandler = {
  [UserForgetPasswordAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserForgetPasswordFetchPayload>,
  ): UserState => ({
    ...state,
    userForgetPasswordLoading: true,
    userForgetPasswordParams: action.payload,
    action: UserForgetPasswordAction.FETCH,
  }),
  [UserForgetPasswordAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserForgetPasswordSuccessPayload>,
  ): UserState => ({
    ...state,
    userForgetPasswordLoading: false,
    userForgetPasswordResponse: action.payload,
    action: UserForgetPasswordAction.SUCCESS,
  }),
  [UserForgetPasswordAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserForgetPasswordFailedPayload>,
  ): UserState => ({
    ...state,
    userForgetPasswordLoading: false,
    userForgetPasswordError: action.payload,
    action: UserForgetPasswordAction.FAILED,
  }),
  [UserForgetPasswordAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userForgetPasswordInitialState,
    action: UserForgetPasswordAction.RESET,
  }),
};
