//#region IMPORT
// Libraries
import React from 'react';
// Utils
import {colors} from '../../../../config/_theme';
// Component
import FollowUpChip from '../FollowUpChip';
// Data
import {FollowUpData, Findings} from '../../entity/followUp.string.entity';
// Assets
import './FollowUpCardContent.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  title?: string;
  chip?: string;
  icon?: string | React.ReactElement;
  isDetail?: boolean;
  contentData?: FollowUpData | Findings;
}
//#endregion

const FollowUpCardContent: React.FC<Props> = ({
  title,
  chip,
  icon,
  isDetail,
  contentData,
}: Props) => {
  return (
    <div className="follow-up-card-content">
      <div className="follow-up-card-content__findings">
        {title ? (
          <div className="follow-up-card-content__findings-title">{title}</div>
        ) : null}
        <div
          className={`follow-up-card-content__findings-image${
            isDetail ? '' : '-list'
          }`}>
          <img src={contentData?.image} />
          {chip ? (
            <div className="follow-up-card-content__findings-image--chip">
              <FollowUpChip text={chip} backgroundColor={colors.primary.blue} />
            </div>
          ) : null}
        </div>
      </div>
      <div className="follow-up-card-content__condition">
        <div className="follow-up-card-content__condition-title">
          {contentData?.name}
        </div>
        <div className="follow-up-card-content__condition-content">
          {icon ? (
            <div className="follow-up-card-content__condition-content--icon">
              {icon}
            </div>
          ) : null}
          <div className="follow-up-card-content__condition-content--desc">
            {contentData?.value}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FollowUpCardContent;
