//#region IMPORT
// Libraries
import React from 'react';
// Data
import {Notes} from '../../entity/followUp.string.entity';
// Assets
import './FollowUpCardNotes.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  notesData?: Notes;
}
//#endregion

const FollowUpCardNotes: React.FC<Props> = ({notesData}: Props) => {
  return (
    <div className="follow-up-card-notes">
      <div className="follow-up-card-notes">
        <div className="follow-up-card-notes__detail">
          <div className="follow-up-card-notes__detail-title">
            {notesData?.title}
          </div>
          <div className="follow-up-card-notes__detail-date">
            {notesData?.date}
          </div>
        </div>
      </div>
      <div className="follow-up-card-notes__content">{notesData?.value}</div>
    </div>
  );
};
export default FollowUpCardNotes;
