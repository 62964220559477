//#region IMPORT
// Libraries
import React from 'react';
import moment from 'moment';
// Utils
import t from '../../../../../../../lang';
// Components
import {
  FollowUpCardContent,
  FollowUpCardHeader,
  FollowUpCardNotes,
  FollowUpDivider,
} from '../../../../../components';
// Data
import {FollowUp} from '../../../../../entity/followUp.string.entity';
// Assets
import './FollowUpApprovedKadepListItem.component.style.scss';
import {IconCalendar} from '../../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp;
  isDetail?: boolean;
  onViewItem(): void;
}
//#endregion

const FollowUpApprovedKadepListItem: React.FC<Props> = ({
  data,
  isDetail,
  onViewItem,
}: Props) => {
  return (
    <div
      className="follow-up-approved-kadep-list-item"
      onClick={(e) => {
        e.stopPropagation();
        onViewItem();
      }}>
      <div className="follow-up-approved-kadep-list-item__header">
        <FollowUpCardHeader
          headerData={data}
          status={data?.status}
          statusColors="green"
        />
      </div>
      <FollowUpDivider />
      {data?.fixingImgRevision && (
        <div className="follow-up-approved-kadep-list-item__revision">
          <FollowUpCardContent
            chip={t('Revision')}
            contentData={{
              name: t('Revision Date'),
              image: data?.fixingImgRevision,
              value: data?.fixingDateRevision
                ? moment(data?.fixingDateRevision, 'YYYY-MM-DD').format(
                    'dddd, DD MMM YYYY',
                  )
                : '-',
            }}
            icon={<IconCalendar />}
            isDetail={isDetail}
          />
        </div>
      )}
      {data?.rejectNote && (
        <div className="follow-up-approved-kadep-list-item__notes">
          <FollowUpCardNotes
            notesData={{
              title: t('Coordinator Rejection Note'),
              value: data?.rejectNote || '-',
              date: data?.rejectNoteDate
                ? moment(data?.rejectNoteDate, 'YYYY-MM-DD HH:mm').format(
                    'DD/MM/YYYY HH:mm',
                  )
                : '-',
            }}
          />
        </div>
      )}
      {(data?.fixingImgRevision || data?.rejectNote) && <FollowUpDivider />}
      <div className="follow-up-approved-kadep-list-item__content">
        {data?.findingImg && (
          <FollowUpCardContent
            contentData={{
              name: data?.findingIndicator || '-',
              image: data?.findingImg,
              value: data?.finding || '-',
            }}
            title={t('Findings')}
            isDetail={isDetail}
          />
        )}
        {data?.fixingImg && (
          <>
            <FollowUpDivider orientations="vertical" />
            <FollowUpCardContent
              contentData={{
                name: t('Fixing Date'),
                image: data?.fixingImg,
                value: data?.fixingDate
                  ? moment(data?.fixingDate, 'YYYY-MM-DD').format(
                      'dddd, DD MMM YYYY',
                    )
                  : '-',
              }}
              title={t('Follow Up')}
              icon={<IconCalendar />}
              isDetail={isDetail}
            />
          </>
        )}
      </div>
      {data?.chiefDeptNote && (
        <div className="follow-up-approved-kadep-list-item__notes">
          <FollowUpCardNotes
            notesData={{
              title: t('Chief of Department Note'),
              value: data?.chiefDeptNote || '-',
              date: data?.chiefDeptNoteDate
                ? moment(data?.chiefDeptNoteDate, 'YYYY-MM-DD HH:mm').format(
                    'DD/MM/YYYY HH:mm',
                  )
                : '-',
            }}
          />
        </div>
      )}
    </div>
  );
};
export default FollowUpApprovedKadepListItem;
