/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../../../utils';
// Components
import MasterDataDepartmentManagementListTableHeader from '../MasterDataDepartmentManagementListTableHeader';
import MasterDataDepartmentManagementListTableItem from '../MasterDataDepartmentManagementListTableItem';
// Data
import {
  MasterDataDepartment,
  MasterDataDepartmentOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  departmentList: MasterDataDepartment[];
  sortBy: SortTable;
  orderBy: MasterDataDepartmentOrderByType;
  onEditDepartment?(_department: MasterDataDepartment): void;
  onDeleteDepartment?(_department: MasterDataDepartment): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataDepartmentOrderByType,
  ): void;
}
//#endregion

const MasterDataDepartmentManagementList: React.FC<Props> = ({
  departmentList,
  sortBy,
  orderBy,
  onEditDepartment,
  onDeleteDepartment,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataDepartmentManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            departmentList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <MasterDataDepartmentManagementListTableItem
              {...(onEditDepartment && {
                onEditItem: () => onEditDepartment(item),
              })}
              {...(onDeleteDepartment && {
                onDeleteItem: () => onDeleteDepartment(item),
              })}
              key={`master-data-basic-service-type-list-item-data-${item.id}-${index}`}
              data={item}
              index={index + 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataDepartmentManagementList;
