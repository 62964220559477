//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {TableCell, TableRow} from '@mui/material';
import {mdiDotsHorizontal} from '@mdi/js';
// Components
import MIconButton from '../../../../../../../../components/MIconButton';
// Data
import {MasterDataDetailObservation} from '../../../../../../entity/masterData.string.entity';
// Assets
import './DetailObservationManagementListTableItem.component.style.scss';
import {IconEdit, IconDelete} from '../../../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: MasterDataDetailObservation;
  onDeleteItem?(): void;
  onEditItem?(): void;
}
//#endregion

const DetailObservationManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  onDeleteItem,
  onEditItem,
}: Props) => {
  //#region GENERAL
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  //#endregion

  return (
    <TableRow className="detail-observation-management-list-table-item">
      <TableCell className="item-data__cell" align="center" width="5%">
        {index}
      </TableCell>
      <TableCell className="item-data__cell" width="45%">
        {data?.name}
      </TableCell>
      {onEditItem || onDeleteItem ? (
        <TableCell align="right" className="item-data__cell table-action">
          {isOptionOpened ? (
            <div className="item-status-action">
              {onEditItem && (
                <MIconButton
                  onClick={() => {
                    onEditItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconEdit />}
                />
              )}
              {onDeleteItem && (
                <MIconButton
                  onClick={() => {
                    onDeleteItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconDelete />}
                />
              )}
            </div>
          ) : (
            <MIconButton
              onClick={() => setIsOptionOpened(true)}
              icon={mdiDotsHorizontal}
            />
          )}
        </TableCell>
      ) : (
        <TableCell />
      )}
    </TableRow>
  );
};

export default DetailObservationManagementListTableItem;
