import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataCategoryDeleteAction,
  MasterDataCategoryDeleteFailedPayload,
  MasterDataCategoryDeleteFetchPayload,
  MasterDataCategoryDeleteSuccessPayload,
} from './masterDataCategoryDelete.type';

export const masterDataCategoryDeleteAction = {
  fetch: createAction<MasterDataCategoryDeleteFetchPayload>(
    MasterDataCategoryDeleteAction.FETCH,
  ),
  success: createAction<MasterDataCategoryDeleteSuccessPayload>(
    MasterDataCategoryDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataCategoryDeleteFailedPayload>(
    MasterDataCategoryDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataCategoryDeleteAction.RESET),
};
