import {CommonError} from '../../../../../../config/constant';

export const MasterDataIndicatorValueDeleteAction = {
  FETCH: 'MASTER_DATA_INDICATOR_VALUE_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_INDICATOR_VALUE_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_INDICATOR_VALUE_DELETE_FAILED',
  RESET: 'MASTER_DATA_INDICATOR_VALUE_DELETE_RESET',
};

export interface MasterDataIndicatorValueDeleteInitialState {
  masterDataIndicatorValueDeleteLoading: boolean;
  masterDataIndicatorValueDeleteParams?: MasterDataIndicatorValueDeleteFetchPayload;
  masterDataIndicatorValueDeleteError?: MasterDataIndicatorValueDeleteFailedPayload;
  masterDataIndicatorValueDeleteResponse?: MasterDataIndicatorValueDeleteSuccessPayload;
}

export interface MasterDataIndicatorValueDeleteFetchPayload {
  id: string;
}
export type MasterDataIndicatorValueDeleteFailedPayload = CommonError;

export interface MasterDataIndicatorValueDeleteSuccessPayload {}
