import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {post} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataCategoryCreateAction} from './masterDataCategoryCreate.action';
import {
  MasterDataCategoryCreateAction,
  MasterDataCategoryCreateFetchPayload,
  MasterDataCategoryCreateSuccessPayload,
} from './masterDataCategoryCreate.type';

function* workerFetch(
  action: GenericAction<MasterDataCategoryCreateFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);

  try {
    const res: AxiosResponse<MasterDataCategoryCreateSuccessPayload> = yield call(
      post,
      `${Env.api}/secured/category/save`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataCategoryCreateAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(masterDataCategoryCreateAction.failed(e.response.data.payload));
  }
}

const masterDataCategoryCreateWatcher = [
  takeLatest(MasterDataCategoryCreateAction.FETCH, workerFetch),
];

export default masterDataCategoryCreateWatcher;
