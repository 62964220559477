//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import OtpInput from 'react-otp-input';
import {useTimer} from 'react-timer-hook';
import moment from 'moment';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../usecases/user.reducer';
import {userOTPPasswordAction} from '../../usecases/userOTPPassword/userOTPPassword.action';
import {userForgetPasswordAction} from '../../usecases/userForgetPassword/userForgetPassword.action';
import {UserOTPPasswordAction} from '../../usecases/user.type';
import {actionHandler} from '../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {MButton} from '../../../../components';
// Data
import {PATH} from '../../../wrapper/entity';
// Assets
import './UserOTP.scss';
//#endregion

//#region INTERFACE
interface Props {
  email: string;
  isLoading?: boolean;
}
//#endregion

const UserOTP: React.FC<Props> = ({email, isLoading}: Props) => {
  //#region GENERAL
  const user: UserState = useSelector((state: AppState) => state.user);
  const [otpPressed, setOTPPressed] = useState(1);
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState('');
  const [isError, setIsError] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const history = useHistory();
  //#endregion

  //#region ACTION HANDLER
  const userActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(UserOTPPasswordAction.SUCCESS, (): void => {
            if (user.userOTPPasswordResponse) {
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Verification success!'),
                  type: 'success',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
              history.push(PATH.CHANGE_PASSWORD);
            }
          })
          .addCase(UserOTPPasswordAction.FAILED, (): void => {
            setIsError(true);
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Verification failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(userOTPPasswordAction.reset());
          });
      });
    },
    [dispatch, history, user.userOTPPasswordResponse],
  );
  useEffect(() => {
    userActionHandler(user.action);
  }, [user.action, userActionHandler]);
  //#endregion

  //#region FORGET PASSWORD ACTION
  const onResend = (): void => {
    dispatch(userForgetPasswordAction.fetch({email}));
  };
  //#endregion

  //#region TIMER
  const {restart, seconds, minutes} = useTimer({
    expiryTimestamp: moment().add(30, 'seconds').toDate(),
    onExpire: () => setIsResendDisabled(false),
  });
  const handleResetTimer = () => {
    setIsResendDisabled(true);
    onResend();
    setOTPPressed((v) => v + 1);
    if (otpPressed < 3) {
      restart(moment().add(30, 'seconds').toDate());
    } else {
      restart(moment().add(5, 'minutes').toDate());
    }
    setIsError(false);
  };
  //#endregion

  //#region ON SUBMIT
  const onSubmit = (): void => {
    dispatch(userOTPPasswordAction.fetch({email: email, otp: OTP}));
  };
  //#endregion

  return (
    <div className="user-otp">
      <div className="user-otp-container">
        <div className="user-otp-container__header">{t('Security Code')}</div>
        <div className="user-otp-container__info">
          {`${t('Verification code has been sent to email')} `}
          <span className="user-otp-container__info--email">{email}</span>
        </div>
        <OtpInput
          containerStyle={{
            display: 'flex',
            justifyContent: 'center',
            margin: '1.6rem 0',
          }}
          className="user-otp-container__input"
          isInputNum
          isDisabled={false}
          value={OTP}
          onChange={setOTP}
          hasErrored={isError}
          numInputs={6}
        />
        <div className="user-otp-container__meta">
          <div className="user-otp-container__meta--timer">
            {`${minutes}:${seconds}`}
          </div>
          <div className="user-otp-container__meta--resend">
            {`${t('Not receive code?')} `}
            <span
              onClick={() => {
                !isResendDisabled && handleResetTimer();
              }}
              className={`meta-resend--action${
                isResendDisabled ? '-disabled' : ''
              }`}>
              {t('Resend')}
            </span>
          </div>
        </div>
        <div className="user-otp-container__action">
          <MButton
            isLoading={isLoading}
            isDisabled={OTP === ''}
            buttonLabel={t('Verification')}
            handleClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default UserOTP;
