/* eslint-disable max-lines */
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Redirect, useHistory} from 'react-router';
import {MSearchInput} from '../../../../components';
import {AppState} from '../../../../config/Interface';
import t from '../../../../lang';
import {PATH} from '../../../wrapper/entity';
import {wrapperNotifyAction} from '../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {actionHandler} from '../../../wrapper/utils';
import {
  MasterDataManagement,
  MasterDataManagementData,
  MasterDataManagementType,
} from '../../entity/masterData.string.entity';
import {masterDataCounterListAction} from '../../usecases/masterData.action';
import {MasterDataCounterListAction} from '../../usecases/masterData.type';
import {MasterDataManagementWrapperList} from './components';
import './MasterDataManagementWrapper.scss';

//#region INTERFACE

//#endregion
const MasterDataManagementWrapper: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const [masterDataManagementMenu, setMasterDataManagementMenu] = useState<
    MasterDataManagement[]
  >([
    ...(permissions?.masterRouteData
      ? [
          {
            id: 'masterDataWrapper-1',
            dataCount: 0,
            name: MasterDataManagementData.RUTE,
            route: PATH.MASTER_DATA_OPERATIONAL_RUTE,
            type: MasterDataManagementType.OPERATIONAL,
          },
        ]
      : []),
    ...(permissions?.masterBusData
      ? [
          {
            id: 'masterDataWrapper-2',
            dataCount: 0,
            name: MasterDataManagementData.BUS,
            route: PATH.MASTER_DATA_OPERATIONAL_BUS,
            type: MasterDataManagementType.OPERATIONAL,
          },
        ]
      : []),
    ...(permissions?.masterEvaluationForm
      ? [
          {
            id: 'masterDataWrapper-3',
            dataCount: 0,
            name: MasterDataManagementData.HALTE,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=HALTE`,
            type: MasterDataManagementType.FORM,
            tabValue: 0,
          },
          {
            id: 'masterDataWrapper-4',
            dataCount: 0,
            name: MasterDataManagementData.BUSBRTTENGAH,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSBRTTENGAH`,
            type: MasterDataManagementType.FORM,
          },
          {
            id: 'masterDataWrapper-5',
            dataCount: 0,
            name: MasterDataManagementData.BUSBRTUJUNG,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSBRTUJUNG`,
            type: MasterDataManagementType.FORM,
          },
          {
            id: 'masterDataWrapper-6',
            dataCount: 0,
            name: MasterDataManagementData.BUSNONBRTTENGAH,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSNONBRTTENGAH`,
            type: MasterDataManagementType.FORM,
          },
          {
            id: 'masterDataWrapper-7',
            dataCount: 0,
            name: MasterDataManagementData.BUSNONBRTUJUNG,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSNONBRTUJUNG`,
            type: MasterDataManagementType.FORM,
          },
          {
            id: 'masterDataWrapper-8',
            dataCount: 0,
            name: MasterDataManagementData.BUSKECILTENGAH,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSKECILTENGAH`,
            type: MasterDataManagementType.FORM,
          },
          {
            id: 'masterDataWrapper-9',
            dataCount: 0,
            name: MasterDataManagementData.BUSKECILUJUNG,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSKECILUJUNG`,
            type: MasterDataManagementType.FORM,
          },
          {
            id: 'masterDataWrapper-10',
            dataCount: 0,
            name: MasterDataManagementData.TRANSCARE,
            route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=TRANSCARE`,
            type: MasterDataManagementType.FORM,
          },
        ]
      : []),
    ...(permissions?.masterOrganization
      ? [
          {
            id: 'masterDataWrapper-11',
            dataCount: 0,
            name: MasterDataManagementData.ORGANIZATION,
            route: PATH.MASTER_DATA_OFFICE,
            type: MasterDataManagementType.OFFICE,
          },
        ]
      : []),
    ...(permissions?.masterWorkAssignmentInstruction
      ? [
          {
            id: 'masterDataWrapper-12',
            dataCount: 0,
            name: MasterDataManagementData.INSTRUKSIKERJA,
            route: PATH.MASTER_DATA_OFFICE_WORK_INSTRUCTION,
            type: MasterDataManagementType.OFFICE,
          },
        ]
      : []),
    ...(permissions?.masterWorkAssignment
      ? [
          {
            id: 'masterDataWrapper-13',
            dataCount: 0,
            name: MasterDataManagementData.SURATTUGAS,
            route: PATH.MASTER_DATA_OFFICE_ASSIGNMENT_LETTER,
            type: MasterDataManagementType.OFFICE,
          },
        ]
      : []),
  ]);
  //#endregion

  //#region FETCH MASTER DATA COUNTER LIST
  const fetchMasterDataCounterList = useCallback(() => {
    dispatch(masterDataCounterListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataCounterListAction.reset());
    fetchMasterDataCounterList();

    return () => {
      dispatch(masterDataCounterListAction.reset());
    };
  }, [dispatch, fetchMasterDataCounterList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataCounterActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataCounterListAction.SUCCESS, (): void => {
            const masterDataCount =
              masterData.masterDataCounterListResponse?.data;
            if (masterDataCount) {
              setMasterDataManagementMenu([
                ...(permissions?.masterRouteData
                  ? [
                      {
                        id: 'masterDataWrapper-1',
                        dataCount: masterDataCount.route ?? 0,
                        name: MasterDataManagementData.RUTE,
                        route: PATH.MASTER_DATA_OPERATIONAL_RUTE,
                        type: MasterDataManagementType.OPERATIONAL,
                      },
                    ]
                  : []),
                ...(permissions?.masterBusData
                  ? [
                      {
                        id: 'masterDataWrapper-2',
                        dataCount: masterDataCount.bus ?? 0,
                        name: MasterDataManagementData.BUS,
                        route: PATH.MASTER_DATA_OPERATIONAL_BUS,
                        type: MasterDataManagementType.OPERATIONAL,
                      },
                    ]
                  : []),
                ...(permissions?.masterEvaluationForm
                  ? [
                      {
                        id: 'masterDataWrapper-3',
                        dataCount: masterDataCount.busStop ?? 0,
                        name: MasterDataManagementData.HALTE,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=HALTE`,
                        type: MasterDataManagementType.FORM,
                        tabValue: 0,
                      },
                      {
                        id: 'masterDataWrapper-4',
                        dataCount: masterDataCount.busBrtMiddle ?? 0,
                        name: MasterDataManagementData.BUSBRTTENGAH,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSBRTTENGAH`,
                        type: MasterDataManagementType.FORM,
                      },
                      {
                        id: 'masterDataWrapper-5',
                        dataCount: masterDataCount.busBrtPeak ?? 0,
                        name: MasterDataManagementData.BUSBRTUJUNG,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSBRTUJUNG`,
                        type: MasterDataManagementType.FORM,
                      },
                      {
                        id: 'masterDataWrapper-6',
                        dataCount: masterDataCount.busNonBrtMiddle ?? 0,
                        name: MasterDataManagementData.BUSNONBRTTENGAH,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSNONBRTTENGAH`,
                        type: MasterDataManagementType.FORM,
                      },
                      {
                        id: 'masterDataWrapper-7',
                        dataCount: masterDataCount.busNonBrtPeak ?? 0,
                        name: MasterDataManagementData.BUSNONBRTUJUNG,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSNONBRTUJUNG`,
                        type: MasterDataManagementType.FORM,
                      },
                      {
                        id: 'masterDataWrapper-8',
                        dataCount: masterDataCount.busMiniMiddle ?? 0,
                        name: MasterDataManagementData.BUSKECILTENGAH,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSKECILTENGAH`,
                        type: MasterDataManagementType.FORM,
                      },
                      {
                        id: 'masterDataWrapper-9',
                        dataCount: masterDataCount.busMiniPeak ?? 0,
                        name: MasterDataManagementData.BUSKECILUJUNG,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=BUSKECILUJUNG`,
                        type: MasterDataManagementType.FORM,
                      },
                      {
                        id: 'masterDataWrapper-10',
                        dataCount: masterDataCount.transcare ?? 0,
                        name: MasterDataManagementData.TRANSCARE,
                        route: `${PATH.MASTER_DATA_FORM_ASSESSMENT}?type=TRANSCARE`,
                        type: MasterDataManagementType.FORM,
                      },
                    ]
                  : []),
                ...(permissions?.masterOrganization
                  ? [
                      {
                        id: 'masterDataWrapper-11',
                        dataCount: masterDataCount.organization ?? 0,
                        name: MasterDataManagementData.ORGANIZATION,
                        route: PATH.MASTER_DATA_OFFICE,
                        type: MasterDataManagementType.OFFICE,
                      },
                    ]
                  : []),
                ...(permissions?.masterWorkAssignmentInstruction
                  ? [
                      {
                        id: 'masterDataWrapper-12',
                        dataCount: masterDataCount.workInstruction ?? 0,
                        name: MasterDataManagementData.INSTRUKSIKERJA,
                        route: PATH.MASTER_DATA_OFFICE_WORK_INSTRUCTION,
                        type: MasterDataManagementType.OFFICE,
                      },
                    ]
                  : []),
                ...(permissions?.masterWorkAssignment
                  ? [
                      {
                        id: 'masterDataWrapper-13',
                        dataCount: masterDataCount.assignmentLetter ?? 0,
                        name: MasterDataManagementData.SURATTUGAS,
                        route: PATH.MASTER_DATA_OFFICE_ASSIGNMENT_LETTER,
                        type: MasterDataManagementType.OFFICE,
                      },
                    ]
                  : []),
              ]);
            }
          })
          .addCase(MasterDataCounterListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataCounterListAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData.masterDataCounterListResponse?.data,
      permissions?.masterBusData,
      permissions?.masterEvaluationForm,
      permissions?.masterOrganization,
      permissions?.masterRouteData,
      permissions?.masterWorkAssignment,
      permissions?.masterWorkAssignmentInstruction,
    ],
  );
  useEffect(() => {
    masterDataCounterActionHandler(masterData.action);
  }, [masterData.action, masterDataCounterActionHandler]);
  //#endregion

  //#region HANDLE OPEN DETAIL ROLE
  const handleOpenDetailMasterDataWrapper = (
    _masterDataWrapper: MasterDataManagement,
  ) => {
    if (_masterDataWrapper.route) {
      history.push(_masterDataWrapper.route);
    }
  };
  //#endregion

  //#region FORMATTED DATA
  const filteredMasterDataMenu: MasterDataManagement[] = masterDataManagementMenu.filter(
    (item: MasterDataManagement) => {
      const filteredItem = item.name.toLowerCase();
      return filteredItem.indexOf(searchValue.toLowerCase()) > -1;
    },
  );
  const formattedMasterDataMenu =
    searchValue === '' ? masterDataManagementMenu : filteredMasterDataMenu;

  //#endregion

  if (
    !permissions?.masterBusData &&
    !permissions?.masterRouteData &&
    !permissions?.masterEvaluationForm &&
    !permissions?.masterOrganization &&
    !permissions?.masterWorkAssignment &&
    !permissions?.masterWorkAssignmentInstruction
  )
    return <Redirect to={PATH.HOME} />;
  return (
    <div className="master-data-management-wrapper">
      <>
        <div className="master-data-management-wrapper__header">
          <MSearchInput
            inputClassName="master-data-management-wrapper__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data')}
            setInputValue={setSearchValue}
          />
        </div>

        <div className="master-data-management-wrapper__content">
          <MasterDataManagementWrapperList
            onClickItem={(_masterDataWrapper: MasterDataManagement) => {
              handleOpenDetailMasterDataWrapper(_masterDataWrapper);
            }}
            data={formattedMasterDataMenu}
          />
        </div>
      </>
    </div>
  );
};

export default MasterDataManagementWrapper;
