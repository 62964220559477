import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDivisionEditAction,
  MasterDataDivisionEditFailedPayload,
  MasterDataDivisionEditFetchPayload,
  MasterDataDivisionEditSuccessPayload,
} from './masterDataDivisionEdit.type';

export const masterDataDivisionEditAction = {
  fetch: createAction<MasterDataDivisionEditFetchPayload>(
    MasterDataDivisionEditAction.FETCH,
  ),
  success: createAction<MasterDataDivisionEditSuccessPayload>(
    MasterDataDivisionEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataDivisionEditFailedPayload>(
    MasterDataDivisionEditAction.FAILED,
  ),
  reset: createAction(MasterDataDivisionEditAction.RESET),
};
