import {GenericAction} from '../../../../config/constant';
import {WorkReportState} from '../workReport.reducer';
import {workReportDeleteInitialState} from './workReportDelete.reducer';
import {
  WorkReportDeleteAction,
  WorkReportDeleteFailedPayload,
  WorkReportDeleteFetchPayload,
  WorkReportDeleteSuccessPayload,
} from './workReportDelete.type';

export const workReportDeleteHandler = {
  [WorkReportDeleteAction.FETCH]: (
    state: WorkReportState,
    action: GenericAction<WorkReportDeleteFetchPayload>,
  ): WorkReportState => ({
    ...state,
    workReportDeleteLoading: true,
    workReportDeleteParams: action.payload,
    action: WorkReportDeleteAction.FETCH,
  }),
  [WorkReportDeleteAction.SUCCESS]: (
    state: WorkReportState,
    action: GenericAction<WorkReportDeleteSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportDeleteParams: undefined,
    workReportDeleteLoading: false,
    workReportDeleteResponse: action.payload,
    action: WorkReportDeleteAction.SUCCESS,
  }),
  [WorkReportDeleteAction.FAILED]: (
    state: WorkReportState,
    action: GenericAction<WorkReportDeleteFailedPayload>,
  ): WorkReportState => ({
    ...state,
    workReportDeleteLoading: false,
    workReportDeleteError: action.payload,
    action: WorkReportDeleteAction.FAILED,
  }),
  [WorkReportDeleteAction.RESET]: (
    state: WorkReportState,
  ): WorkReportState => ({
    ...state,
    ...workReportDeleteInitialState,
    action: WorkReportDeleteAction.RESET,
  }),
};
