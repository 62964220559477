import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataFormControllerEditInitialState} from './masterDataFormControllerEdit.reducer';
import {
  MasterDataFormControllerEditAction,
  MasterDataFormControllerEditFailedPayload,
  MasterDataFormControllerEditFetchPayload,
  MasterDataFormControllerEditSuccessPayload,
} from './masterDataFormControllerEdit.type';

export const masterDataFormControllerEditHandler = {
  [MasterDataFormControllerEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerEditLoading: true,
    masterDataFormControllerEditParams: action.payload,
    action: MasterDataFormControllerEditAction.FETCH,
  }),
  [MasterDataFormControllerEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerEditParams: undefined,
    masterDataFormControllerEditLoading: false,
    masterDataFormControllerEditResponse: action.payload,
    action: MasterDataFormControllerEditAction.SUCCESS,
  }),
  [MasterDataFormControllerEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerEditLoading: false,
    masterDataFormControllerEditError: action.payload,
    action: MasterDataFormControllerEditAction.FAILED,
  }),
  [MasterDataFormControllerEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataFormControllerEditInitialState,
    action: MasterDataFormControllerEditAction.RESET,
  }),
};
