//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {Menu, MenuItem, TableCell, TableRow} from '@mui/material';
import {mdiDotsHorizontal} from '@mdi/js';
import moment from 'moment';
// Utils
import t from '../../../../lang';
// Components
import {MIconButton} from '../../../../components';
// Data
import {
  WorkReport,
  WorkReportType,
} from '../../entity/workReport.string.entity';
// Assets
import {IconEdit, IconDelete, IconDownload} from '../../../../assets/icons';
import './WorkReportManagementListTableItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: WorkReport;
  isAdmin?: boolean;
  onViewItem?(): void;
  onDeleteItem?(): void;
  onEditItem?(): void;
  onDownloadItem?(isMain: boolean): void;
}
//#endregion

const WorkReportManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  isAdmin,
  onViewItem,
  onDeleteItem,
  onEditItem,
  onDownloadItem,
}: Props) => {
  //#region GENERAL
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  const [
    menuDownloadWorkReport,
    setMenuDownloadWorkReport,
  ] = React.useState<null | HTMLElement>(null);

  const openMenuDownloadWorkReport = Boolean(menuDownloadWorkReport);

  const menuDownloadOptions = [
    {label: t('Main'), isMain: true},
    ...(data?.supportFile ? [{label: t('Support'), isMain: false}] : []),
  ];
  //#endregion

  return (
    <TableRow
      onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
        e.stopPropagation();
        onViewItem && onViewItem();
      }}
      className="work-report-management-list-table-item">
      <TableCell className="item-data__cell" align="center" width="5%">
        {index}
      </TableCell>
      <TableCell className="item-data__cell">
        <div className="item-status-col">
          <span>{data?.name}</span>
          <div className="item-status-col__desc">
            {`${data?.supportFile ? '2' : '1'} ${t('file')}`}
          </div>
        </div>
      </TableCell>
      <TableCell className="item-data__cell">{data?.reportNumber}</TableCell>
      <TableCell className="item-data__cell">{data?.reportType}</TableCell>
      <TableCell className="item-data__cell">
        {moment(data?.month).format('MMMM')}
      </TableCell>
      <TableCell className="item-data__cell">
        {moment(data?.year).format('YYYY')}
      </TableCell>
      <TableCell className="item-data__cell">
        {data?.departmentName || t('SPM')}
      </TableCell>
      <TableCell className="item-data__cell">
        {moment(data?.uploadedTime).format('DD/MM/YYYY')}
      </TableCell>
      {(onEditItem || onDeleteItem || onDownloadItem) && (
        <TableCell align="right" className="item-data__cell table-action">
          {isOptionOpened ? (
            <div className="item-status-action">
              {onDownloadItem && (
                <>
                  <MIconButton
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                      setMenuDownloadWorkReport(event.currentTarget)
                    }
                    icon={<IconDownload />}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={menuDownloadWorkReport}
                    open={openMenuDownloadWorkReport}
                    onClose={() => setMenuDownloadWorkReport(null)}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}>
                    {menuDownloadOptions.map((menuItem, idx: number) => (
                      <MenuItem
                        key={`menu-download-item-${data?.id}-${idx}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          onDownloadItem(menuItem.isMain);
                          setMenuDownloadWorkReport(null);
                          setIsOptionOpened(false);
                        }}>
                        {menuItem.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              )}
              {onEditItem &&
                !(isAdmin && data?.reportType === WorkReportType.MONTHLY) && (
                  <MIconButton
                    onClick={() => {
                      onEditItem();
                      setIsOptionOpened(false);
                    }}
                    icon={<IconEdit />}
                  />
                )}
              {onDeleteItem && (
                <MIconButton
                  onClick={() => {
                    onDeleteItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconDelete />}
                />
              )}
            </div>
          ) : (
            <MIconButton
              onClick={() => setIsOptionOpened(true)}
              icon={mdiDotsHorizontal}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default WorkReportManagementListTableItem;
