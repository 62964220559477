import {createAction} from '@reduxjs/toolkit';
import {
  UserOTPPasswordAction,
  UserOTPPasswordFailedPayload,
  UserOTPPasswordFetchPayload,
  UserOTPPasswordSuccessPayload,
} from './userOTPPassword.type';

export const userOTPPasswordAction = {
  fetch: createAction<UserOTPPasswordFetchPayload>(UserOTPPasswordAction.FETCH),
  success: createAction<UserOTPPasswordSuccessPayload>(
    UserOTPPasswordAction.SUCCESS,
  ),
  failed: createAction<UserOTPPasswordFailedPayload>(
    UserOTPPasswordAction.FAILED,
  ),
  reset: createAction(UserOTPPasswordAction.RESET),
};
