import * as React from 'react';

const IconCaretArrowDown = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 12}
    height={props.height || 10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4.869 9.63c-.058-.056-.306-.27-.51-.468C3.076 7.997.976 4.958.335 3.367.232 3.125.014 2.514 0 2.188c0-.313.072-.61.218-.895.204-.355.525-.64.904-.795.263-.1 1.05-.256 1.064-.256C3.047.086 4.446 0 5.992 0c1.473 0 2.815.086 3.689.213.014.015.992.17 1.327.341.612.313.992.924.992 1.578v.056c-.015.426-.395 1.321-.409 1.321-.642 1.505-2.639 4.474-3.966 5.668 0 0-.341.336-.554.482A1.783 1.783 0 016.007 10c-.423 0-.817-.128-1.138-.37z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconCaretArrowDown;
