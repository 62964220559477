import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataIndicatorValueSetPositiveValueAction,
  MasterDataIndicatorValueSetPositiveValueFailedPayload,
  MasterDataIndicatorValueSetPositiveValueFetchPayload,
  MasterDataIndicatorValueSetPositiveValueSuccessPayload,
} from './masterDataIndicatorValueSetPositiveValue.type';

export const masterDataIndicatorValueSetPositiveValueAction = {
  fetch: createAction<MasterDataIndicatorValueSetPositiveValueFetchPayload>(
    MasterDataIndicatorValueSetPositiveValueAction.FETCH,
  ),
  success: createAction<MasterDataIndicatorValueSetPositiveValueSuccessPayload>(
    MasterDataIndicatorValueSetPositiveValueAction.SUCCESS,
  ),
  failed: createAction<MasterDataIndicatorValueSetPositiveValueFailedPayload>(
    MasterDataIndicatorValueSetPositiveValueAction.FAILED,
  ),
  reset: createAction(MasterDataIndicatorValueSetPositiveValueAction.RESET),
};
