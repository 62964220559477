import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationValueCreateAction,
  MasterDataObservationValueCreateFailedPayload,
  MasterDataObservationValueCreateFetchPayload,
  MasterDataObservationValueCreateSuccessPayload,
} from './masterDataObservationValueCreate.type';

export const masterDataObservationValueCreateAction = {
  fetch: createAction<MasterDataObservationValueCreateFetchPayload>(
    MasterDataObservationValueCreateAction.FETCH,
  ),
  success: createAction<MasterDataObservationValueCreateSuccessPayload>(
    MasterDataObservationValueCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationValueCreateFailedPayload>(
    MasterDataObservationValueCreateAction.FAILED,
  ),
  reset: createAction(MasterDataObservationValueCreateAction.RESET),
};
