/* eslint-disable @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Utils
import {SortTable} from '../../../../../../../../config/constant';
import {getComparator, stableSort} from '../../../../../../../../utils';
// Components
import MasterDataRuteBRTManagementListTableHeader from '../../../MasterDataRuteBRTSection/components/MasterDataRuteBRTManagementListTableHeader';
import MasterDataRuteBRTManagementListTableItem from '../../../MasterDataRuteBRTSection/components/MasterDataRuteBRTManagementListTableItem';
// Data
import {
  MasterDataRute,
  MasterDataRuteOrderByType,
} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  ruteNonBRTList: MasterDataRute[];
  sortBy: SortTable;
  orderBy: MasterDataRuteOrderByType;
  onEditRoute(route: MasterDataRute): void;
  onDeleteRoute(route: MasterDataRute): void;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataRuteOrderByType,
  ): void;
}
//#endregion

const MasterDataRuteNonBRTManagementList: React.FC<Props> = ({
  ruteNonBRTList,
  sortBy,
  orderBy,
  onEditRoute,
  onDeleteRoute,
  onHandleSort,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: 'calc(60vh)'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <MasterDataRuteBRTManagementListTableHeader
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={onHandleSort}
        />
        <TableBody>
          {stableSort(
            ruteNonBRTList as any[],
            getComparator(sortBy, orderBy),
          ).map((item: any, index: number) => (
            <MasterDataRuteBRTManagementListTableItem
              key={`master-data-rute-nonbrt-list-item-data-${item.id}-${index}`}
              data={item}
              index={index + 1}
              onEditItem={() => {
                onEditRoute(item);
              }}
              onDeleteItem={() => {
                onDeleteRoute(item);
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MasterDataRuteNonBRTManagementList;
