//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {TableCell, TableRow} from '@mui/material';
import {mdiDotsHorizontal} from '@mdi/js';
// Utils
import t from '../../../../../../../../lang';
// Components
import {MSwitch} from '../../../../../../../../components';
import MIconButton from '../../../../../../../../components/MIconButton';
// Data
import {MasterDataIndicatorValue} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataIndicatorValueManagementListTableItem.component.style.scss';
import {IconEdit, IconDelete} from '../../../../../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  index: number;
  data?: MasterDataIndicatorValue;
  onDeleteItem?(): void;
  onEditItem?(): void;
  onActivateItem?(): void;
}
//#endregion
const MasterDataIndicatorValueManagementListTableItem: React.FC<Props> = ({
  index,
  data,
  onDeleteItem,
  onEditItem,
  onActivateItem,
}: Props) => {
  //#region GENERAL
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  //#endregion

  return (
    <TableRow className="master-data-indicator-value-management-list-table-item">
      <TableCell className="item-data__cell" align="center" width="5%">
        {index}
      </TableCell>
      <TableCell className="item-data__cell" width="45%">
        {data?.name}
      </TableCell>
      <TableCell className="item-data__cell">
        <div
          className="item-status-row"
          onClick={(e) => {
            onActivateItem && e.stopPropagation();
            onActivateItem && onActivateItem();
          }}>
          {onActivateItem && (
            <MSwitch
              checked={data?.isPositiveValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.stopPropagation();
                onActivateItem();
              }}
            />
          )}
          <span>{data?.isPositiveValue ? t('Positive') : t('Negative')}</span>
        </div>
      </TableCell>
      {onEditItem || onDeleteItem ? (
        <TableCell align="right" className="item-data__cell table-action">
          {isOptionOpened ? (
            <div className="item-status-action">
              {onEditItem && (
                <MIconButton
                  onClick={() => {
                    onEditItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconEdit />}
                />
              )}
              {onDeleteItem && (
                <MIconButton
                  onClick={() => {
                    onDeleteItem();
                    setIsOptionOpened(false);
                  }}
                  icon={<IconDelete />}
                />
              )}
            </div>
          ) : (
            <MIconButton
              onClick={() => setIsOptionOpened(true)}
              icon={mdiDotsHorizontal}
            />
          )}
        </TableCell>
      ) : (
        <TableCell />
      )}
    </TableRow>
  );
};

export default MasterDataIndicatorValueManagementListTableItem;
