import {CommonError} from '../../../../../../config/constant';
import {MasterDataObservationValueFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationValueCreateAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_VALUE_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_VALUE_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_VALUE_CREATE_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_VALUE_CREATE_RESET',
};

export interface MasterDataObservationValueCreateInitialState {
  masterDataObservationValueCreateLoading: boolean;
  masterDataObservationValueCreateParams?: MasterDataObservationValueCreateFetchPayload;
  masterDataObservationValueCreateError?: MasterDataObservationValueCreateFailedPayload;
  masterDataObservationValueCreateResponse?: MasterDataObservationValueCreateSuccessPayload;
}

export type MasterDataObservationValueCreateFetchPayload = Omit<
  MasterDataObservationValueFormData,
  'id'
>[];

export type MasterDataObservationValueCreateFailedPayload = CommonError;

export interface MasterDataObservationValueCreateSuccessPayload {}
