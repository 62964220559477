import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataIndicatorValueSetPositiveValueInitialState} from './masterDataIndicatorValueSetPositiveValue.reducer';
import {
  MasterDataIndicatorValueSetPositiveValueAction,
  MasterDataIndicatorValueSetPositiveValueFailedPayload,
  MasterDataIndicatorValueSetPositiveValueFetchPayload,
  MasterDataIndicatorValueSetPositiveValueSuccessPayload,
} from './masterDataIndicatorValueSetPositiveValue.type';

export const masterDataIndicatorValueSetPositiveValueHandler = {
  [MasterDataIndicatorValueSetPositiveValueAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueSetPositiveValueFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueSetPositiveValueLoading: true,
    masterDataIndicatorValueSetPositiveValueParams: action.payload,
    action: MasterDataIndicatorValueSetPositiveValueAction.FETCH,
  }),
  [MasterDataIndicatorValueSetPositiveValueAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueSetPositiveValueSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueSetPositiveValueParams: undefined,
    masterDataIndicatorValueSetPositiveValueLoading: false,
    masterDataIndicatorValueSetPositiveValueResponse: action.payload,
    action: MasterDataIndicatorValueSetPositiveValueAction.SUCCESS,
  }),
  [MasterDataIndicatorValueSetPositiveValueAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueSetPositiveValueFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueSetPositiveValueLoading: false,
    masterDataIndicatorValueSetPositiveValueError: action.payload,
    action: MasterDataIndicatorValueSetPositiveValueAction.FAILED,
  }),
  [MasterDataIndicatorValueSetPositiveValueAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataIndicatorValueSetPositiveValueInitialState,
    action: MasterDataIndicatorValueSetPositiveValueAction.RESET,
  }),
};
