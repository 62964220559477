import * as React from 'react';

const IconDownload = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 21}
    height={props.height || 20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.73 5.29V1.283c0-.427.34-.782.77-.782.385 0 .711.298.763.677l.007.105V5.29h4.78c2.38 0 4.335 1.949 4.445 4.38l.005.215v5.04c0 2.447-1.887 4.456-4.232 4.569l-.208.005H4.94c-2.38 0-4.326-1.94-4.435-4.379L.5 14.905v-5.03c0-2.447 1.878-4.466 4.222-4.58l.208-.004h4.8v6.402l-1.6-1.652a.755.755 0 00-1.09 0 .81.81 0 00-.22.568c0 .157.045.32.14.459l.08.099 2.91 3.015c.14.155.34.237.55.237a.735.735 0 00.465-.166l.075-.071 2.91-3.015c.3-.31.3-.816 0-1.126a.755.755 0 00-1.004-.077l-.086.077-1.59 1.652V5.291H9.73z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconDownload;
