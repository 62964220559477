import * as React from 'react';

const IconCash = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width={props.width || 56}
    height={props.height || 57}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.75 48.916a11.667 11.667 0 100-23.333 11.667 11.667 0 000 23.334zM51.334 37.25a14.583 14.583 0 11-29.167 0 14.583 14.583 0 0129.167 0z"
      fill={props.fill || '#27AE60'}
    />
    <path
      d="M32.194 40.003c.137 1.738 1.511 3.092 3.976 3.255v1.283h1.093V43.25c2.553-.178 4.043-1.543 4.043-3.52 0-1.803-1.137-2.73-3.18-3.209l-.862-.204v-3.5c1.096.126 1.79.724 1.957 1.552h1.919c-.137-1.677-1.575-2.987-3.877-3.13v-1.28H36.17v1.313c-2.179.212-3.66 1.522-3.66 3.377 0 1.64 1.102 2.683 2.936 3.11l.724.177v3.71c-1.12-.17-1.864-.787-2.03-1.642H32.19h.003zm3.967-3.95c-1.076-.247-1.66-.758-1.66-1.522 0-.857.63-1.499 1.669-1.685v3.208h-.009zm1.26 2.177c1.31.303 1.91.793 1.91 1.66 0 .988-.75 1.665-2.068 1.79v-3.485l.158.035z"
      fill={props.fill || '#27AE60'}
    />
    <path
      d="M7.584 5.167a2.917 2.917 0 00-2.917 2.916v23.333a2.917 2.917 0 002.917 2.917h11.908c.17-1.003.423-1.977.753-2.916h-6.828a5.833 5.833 0 00-5.833-5.834V13.917a5.833 5.833 0 005.833-5.834h29.167a5.833 5.833 0 005.833 5.833v10.29a17.564 17.564 0 012.917 3.366V8.083a2.917 2.917 0 00-2.917-2.917H7.584z"
      fill={props.fill || '#27AE60'}
    />
    <path
      d="M33.828 19.992l.006-.242a5.833 5.833 0 10-9.135 4.813 17.446 17.446 0 019.129-4.57z"
      fill={props.fill || '#27AE60'}
    />
  </svg>
);

export default IconCash;
