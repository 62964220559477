import {
  MasterDataBusListAction,
  MasterDataBusListSuccessPayload,
  MasterDataBusListFailedPayload,
  MasterDataBusListFetchPayload,
} from './masterDataBusList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataBusListInitialState} from './masterDataBusList.reducer';

export const masterDataBusListHandler = {
  [MasterDataBusListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataBusListLoading: true,
      masterDataBusListParams: action.payload,
      action: MasterDataBusListAction.FETCH,
    };
  },
  [MasterDataBusListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusListLoading: false,
    masterDataBusListResponse: action.payload,
    action: MasterDataBusListAction.SUCCESS,
  }),
  [MasterDataBusListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusListLoading: false,
    masterDataBusListData: [
      ...state.masterDataBusListData,
      ...action.payload.data.content,
    ],
    action: MasterDataBusListAction.UPDATE,
  }),
  [MasterDataBusListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusListLoading: false,
    masterDataBusListError: action.payload,
    action: MasterDataBusListAction.FAILED,
  }),
  [MasterDataBusListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBusListInitialState,
    action: MasterDataBusListAction.RESET,
  }),
};
