//#region IMPORT
// Libraries
import React, {useEffect, useState} from 'react';
// Components
import {FollowUpApprovedSection} from '../sections';
// Data
import {FollowUp} from '../../entity/followUp.string.entity';
// Assets
import './FollowUpKadivManagementWrapper.scss';
//#endregion

//#region INTERFACE
interface Props {
  defaultOpenedDetail?: FollowUp;
}
//#endregion

const FollowUpKadivManagementWrapper: React.FC<Props> = ({
  defaultOpenedDetail,
}: Props) => {
  //#region GENERAL
  const [openedDetail, setOpenedDetail] = useState<FollowUp | undefined>(
    defaultOpenedDetail,
  );
  //#endregion

  //#region HANDLE CHANGE ROUTE TAB
  useEffect(() => {
    let changeOpenedDetail = true;
    if (changeOpenedDetail) {
      setOpenedDetail(defaultOpenedDetail);
    }

    return () => {
      changeOpenedDetail = false;
    };
  }, [defaultOpenedDetail]);
  //#endregion

  return (
    <div className="follow-up-kadiv-management-wrapper">
      <FollowUpApprovedSection defaultOpenedDetail={openedDetail} />
    </div>
  );
};

export default FollowUpKadivManagementWrapper;
