import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {httpPut} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataDetailObservationDeleteAction} from './masterDataDetailObservationDelete.action';
import {
  MasterDataDetailObservationDeleteAction,
  MasterDataDetailObservationDeleteFetchPayload,
  MasterDataDetailObservationDeleteSuccessPayload,
} from './masterDataDetailObservationDelete.type';

function* workerFetch(
  action: GenericAction<MasterDataDetailObservationDeleteFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id} = action.payload;
  try {
    const res: AxiosResponse<MasterDataDetailObservationDeleteSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/detail-observation/delete/${id}`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataDetailObservationDeleteAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(
      masterDataDetailObservationDeleteAction.failed(e.response.data.payload),
    );
  }
}

const masterDataDetailObservationDeleteWatcher = [
  takeLatest(MasterDataDetailObservationDeleteAction.FETCH, workerFetch),
];

export default masterDataDetailObservationDeleteWatcher;
