import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {EvaluationReportManagement} from '../../entity/evaluationReport.string.entity';
import {evaluationReportListAction} from './evaluationReportList.action';
import {
  EvaluationReportListAction,
  EvaluationReportListFetchPayload,
  EvaluationReportListResponse,
  EvaluationReportListSuccessPayload,
} from './evaluationReportList.type';

function* workerFetch(action: GenericAction<EvaluationReportListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {sort, ...rest} = action.payload;
  try {
    const res: AxiosResponse<EvaluationReportListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/review/list`,
      {
        sort: `date,${sort?.toLowerCase()}`,
        ...rest,
      },
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      const formattedResponseData: EvaluationReportManagement[] =
        (res.data as EvaluationReportListResponse)?.data?.content || [];
      yield put(
        evaluationReportListAction.success(
          res.data as EvaluationReportListResponse,
        ),
      );
      yield put(evaluationReportListAction.update(formattedResponseData));
    } else {
      yield put(evaluationReportListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(evaluationReportListAction.failed(e as CommonError));
  }
}

const evaluationReportListWatcher = [
  takeLatest(EvaluationReportListAction.FETCH, workerFetch),
];

export default evaluationReportListWatcher;
