//#region IMPORT
// Libraries
import React from 'react';
import moment from 'moment';
// Components
import NotificationListItem from '../NotificationListItem';
// Data
import {
  NotificationManagement,
  NotificationProps,
} from '../../../../../entity/notification.string.entity';
// Assets
import './NotificationList.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: NotificationManagement[];
  handleClick?(_notifProps: NotificationProps): void;
}
//#endregion

const NotificationList: React.FC<Props> = ({data, handleClick}: Props) => {
  return (
    <div className="notification-list">
      {data?.map((item: NotificationManagement, index: number) => (
        <div key={index} className="notification-list__container">
          <div className="notification-list__container-date">
            {moment(item.date, 'YYYY-MM-DD').format('DD MMMM YYYY')}
          </div>
          <div className="notification-list__container-content">
            {item?.notification?.map(
              (
                notificationDetail: NotificationProps,
                notificationIndex: number,
              ) => {
                return (
                  <NotificationListItem
                    key={notificationIndex}
                    data={notificationDetail}
                    handleClick={handleClick}
                  />
                );
              },
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationList;
