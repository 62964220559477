import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataAssignmentLetterListAction} from './masterDataAssignmentLetterList.action';
import {
  MasterDataAssignmentLetterListAction,
  MasterDataAssignmentLetterListFetchPayload,
  MasterDataAssignmentLetterListResponse,
  MasterDataAssignmentLetterListSuccessPayload,
} from './masterDataAssignmentLetterList.type';

function* workerFetch(
  action: GenericAction<MasterDataAssignmentLetterListFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataAssignmentLetterListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/assignment-letter/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataAssignmentLetterListAction.success(
          res.data as MasterDataAssignmentLetterListResponse,
        ),
      );
      yield put(
        masterDataAssignmentLetterListAction.update(
          res.data as MasterDataAssignmentLetterListResponse,
        ),
      );
    } else {
      yield put(
        masterDataAssignmentLetterListAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(masterDataAssignmentLetterListAction.failed(e as CommonError));
  }
}

const masterDataAssignmentLetterListWatcher = [
  takeLatest(MasterDataAssignmentLetterListAction.FETCH, workerFetch),
];

export default masterDataAssignmentLetterListWatcher;
