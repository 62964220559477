import {CommonError} from '../../../../../../config/constant';

export const MasterDataDivisionCreateAction = {
  FETCH: 'MASTER_DATA_DIVISION_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_DIVISION_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_DIVISION_CREATE_FAILED',
  RESET: 'MASTER_DATA_DIVISION_CREATE_RESET',
};

export interface MasterDataDivisionCreateInitialState {
  masterDataDivisionCreateLoading: boolean;
  masterDataDivisionCreateParams?: MasterDataDivisionCreateFetchPayload;
  masterDataDivisionCreateError?: MasterDataDivisionCreateFailedPayload;
  masterDataDivisionCreateResponse?: MasterDataDivisionCreateSuccessPayload;
}

export interface MasterDataDivisionCreateFetchPayload {
  directorateId: string;
  data: {
    name: string;
  }[];
}
export type MasterDataDivisionCreateFailedPayload = CommonError;

export interface MasterDataDivisionCreateSuccessPayload {}
