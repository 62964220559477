import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataIndicatorValueEditInitialState} from './masterDataIndicatorValueEdit.reducer';
import {
  MasterDataIndicatorValueEditAction,
  MasterDataIndicatorValueEditFailedPayload,
  MasterDataIndicatorValueEditFetchPayload,
  MasterDataIndicatorValueEditSuccessPayload,
} from './masterDataIndicatorValueEdit.type';

export const masterDataIndicatorValueEditHandler = {
  [MasterDataIndicatorValueEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueEditLoading: true,
    masterDataIndicatorValueEditParams: action.payload,
    action: MasterDataIndicatorValueEditAction.FETCH,
  }),
  [MasterDataIndicatorValueEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueEditParams: undefined,
    masterDataIndicatorValueEditLoading: false,
    masterDataIndicatorValueEditResponse: action.payload,
    action: MasterDataIndicatorValueEditAction.SUCCESS,
  }),
  [MasterDataIndicatorValueEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataIndicatorValueEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataIndicatorValueEditLoading: false,
    masterDataIndicatorValueEditError: action.payload,
    action: MasterDataIndicatorValueEditAction.FAILED,
  }),
  [MasterDataIndicatorValueEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataIndicatorValueEditInitialState,
    action: MasterDataIndicatorValueEditAction.RESET,
  }),
};
