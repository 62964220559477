import {CommonError} from '../../../../../../config/constant';
import {MasterDataReviewIndicatorFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataReviewIndicatorEditAction = {
  FETCH: 'MASTER_DATA_REVIEW_INDICATOR_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_REVIEW_INDICATOR_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_REVIEW_INDICATOR_EDIT_FAILED',
  RESET: 'MASTER_DATA_REVIEW_INDICATOR_EDIT_RESET',
};

export interface MasterDataReviewIndicatorEditInitialState {
  masterDataReviewIndicatorEditLoading: boolean;
  masterDataReviewIndicatorEditParams?: MasterDataReviewIndicatorEditFetchPayload;
  masterDataReviewIndicatorEditError?: MasterDataReviewIndicatorEditFailedPayload;
  masterDataReviewIndicatorEditResponse?: MasterDataReviewIndicatorEditSuccessPayload;
}

export type MasterDataReviewIndicatorEditFetchPayload = MasterDataReviewIndicatorFormData;

export type MasterDataReviewIndicatorEditFailedPayload = CommonError;

export interface MasterDataReviewIndicatorEditSuccessPayload {}
