import {CommonError} from '../../../../config/constant';
import {RoleFormData} from '../../entity/user.string.entity';

export const UserRoleAccessEditAction = {
  FETCH: 'USER_ROLE_ACCESS_EDIT_FETCH',
  SUCCESS: 'USER_ROLE_ACCESS_EDIT_SUCCESS',
  FAILED: 'USER_ROLE_ACCESS_EDIT_FAILED',
  RESET: 'USER_ROLE_ACCESS_EDIT_RESET',
};

export interface UserRoleAccessEditInitialState {
  userRoleAccessEditLoading: boolean;
  userRoleAccessEditParams?: UserRoleAccessEditFetchPayload;
  userRoleAccessEditError?: UserRoleAccessEditFailedPayload;
  userRoleAccessEditResponse?: UserRoleAccessEditSuccessPayload;
}

export type UserRoleAccessEditFetchPayload = RoleFormData;
export type UserRoleAccessEditFailedPayload = CommonError;

export interface UserRoleAccessEditSuccessPayload {}
