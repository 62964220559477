import {CommonError} from '../../../../../../config/constant';
import {MasterDataObservation} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationOptionListAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_OBSERVATION_OPTION_LIST_UPDATE',
};

export interface MasterDataObservationOptionListInitialState {
  masterDataObservationOptionListLoading: boolean;
  masterDataObservationOptionListData: MasterDataObservation[];
  masterDataObservationOptionListError?: MasterDataObservationOptionListFailedPayload;
  masterDataObservationOptionListParams?: MasterDataObservationOptionListFetchPayload;
  masterDataObservationOptionListResponse?: MasterDataObservationOptionListSuccessPayload;
}

export type MasterDataObservationOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataObservation[];
};

export type MasterDataObservationOptionListFailedPayload = CommonError;

export type MasterDataObservationOptionListSuccessPayload = MasterDataObservationOptionListResponse;

export type MasterDataObservationOptionListUpdatePayload = MasterDataObservationOptionListResponse;

export interface MasterDataObservationOptionListFetchPayload {
  assignmentType: string;
}
