import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataObservationValue} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationValueListAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_VALUE_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_VALUE_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_VALUE_LIST_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_VALUE_LIST_RESET',
  UPDATE: 'MASTER_DATA_OBSERVATION_VALUE_LIST_UPDATE',
};

export interface MasterDataObservationValueListInitialState {
  masterDataObservationValueListLoading: boolean;
  masterDataObservationValueListData: MasterDataObservationValue[];
  masterDataObservationValueListError?: MasterDataObservationValueListFailedPayload;
  masterDataObservationValueListParams?: MasterDataObservationValueListFetchPayload;
  masterDataObservationValueListResponse?: MasterDataObservationValueListSuccessPayload;
}

export type MasterDataObservationValueListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataObservationValue[]>;
};

export type MasterDataObservationValueListFailedPayload = CommonError;

export type MasterDataObservationValueListSuccessPayload = MasterDataObservationValueListResponse;

export type MasterDataObservationValueListUpdatePayload = MasterDataObservationValueListResponse;

export interface MasterDataObservationValueListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
