import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataCategoryEditAction,
  MasterDataCategoryEditFailedPayload,
  MasterDataCategoryEditFetchPayload,
  MasterDataCategoryEditSuccessPayload,
} from './masterDataCategoryEdit.type';

export const masterDataCategoryEditAction = {
  fetch: createAction<MasterDataCategoryEditFetchPayload>(
    MasterDataCategoryEditAction.FETCH,
  ),
  success: createAction<MasterDataCategoryEditSuccessPayload>(
    MasterDataCategoryEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataCategoryEditFailedPayload>(
    MasterDataCategoryEditAction.FAILED,
  ),
  reset: createAction(MasterDataCategoryEditAction.RESET),
};
