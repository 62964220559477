import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataIndicatorValueCreateAction,
  MasterDataIndicatorValueCreateFailedPayload,
  MasterDataIndicatorValueCreateFetchPayload,
  MasterDataIndicatorValueCreateSuccessPayload,
} from './masterDataIndicatorValueCreate.type';

export const masterDataIndicatorValueCreateAction = {
  fetch: createAction<MasterDataIndicatorValueCreateFetchPayload>(
    MasterDataIndicatorValueCreateAction.FETCH,
  ),
  success: createAction<MasterDataIndicatorValueCreateSuccessPayload>(
    MasterDataIndicatorValueCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataIndicatorValueCreateFailedPayload>(
    MasterDataIndicatorValueCreateAction.FAILED,
  ),
  reset: createAction(MasterDataIndicatorValueCreateAction.RESET),
};
