import {createReducer} from '@reduxjs/toolkit';

import {FollowUpListInitialState} from './followUpList/followUpList.type';
import {FollowUpKadepListInitialState} from './followUpKadepList/followUpKadepList.type';
import {FollowUpExpiredNoteCreateInitialState} from './followUpExpiredNoteCreate/followUpExpiredNoteCreate.type';
import {FollowUpPICConfirmInitialState} from './followUpPICConfirm/followUpPICConfirm.type';
import {FollowUpPICCompleteInitialState} from './followUpPICComplete/followUpPICComplete.type';
import {FollowUpDetailInitialState} from './followUpDetail/followUpDetail.type';

import {followUpListInitialState} from './followUpList/followUpList.reducer';
import {followUpKadepListInitialState} from './followUpKadepList/followUpKadepList.reducer';
import {followUpExpiredNoteCreateInitialState} from './followUpExpiredNoteCreate/followUpExpiredNoteCreate.reducer';
import {followUpPICConfirmInitialState} from './followUpPICConfirm/followUpPICConfirm.reducer';
import {followUpPICCompleteInitialState} from './followUpPICComplete/followUpPICComplete.reducer';
import {followUpDetailInitialState} from './followUpDetail/followUpDetail.reducer';

import followUpHandler from './followUp.handler';

export interface FollowUpState
  extends FollowUpListInitialState,
    FollowUpPICConfirmInitialState,
    FollowUpPICCompleteInitialState,
    FollowUpKadepListInitialState,
    FollowUpExpiredNoteCreateInitialState,
    FollowUpDetailInitialState {
  action: string;
}

export const followUpInitialState: FollowUpState = {
  ...followUpListInitialState,
  ...followUpKadepListInitialState,
  ...followUpExpiredNoteCreateInitialState,
  ...followUpPICConfirmInitialState,
  ...followUpPICCompleteInitialState,
  ...followUpDetailInitialState,
  action: '',
};

export default createReducer(followUpInitialState, followUpHandler);
