//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
// Utils
import {
  workReportCreateAction,
  workReportEditAction,
} from '../../../usecases/workReport.action';
import {urlToFile} from '../../../../../utils';
// Components
import WorkReportAdminForm from './components/WorkReportAdminForm';
// Data
import {
  WorkReport,
  WorkReportFormData,
  WorkReportFormDataTemp,
  WorkReportType,
} from '../../../entity/workReport.string.entity';
// Assets
import './WorkReportAdminFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: WorkReport;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const WorkReportAdminFormContainer: React.FC<Props> = ({
  data,
  isLoading = false,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();

  const fileType = 'application/vnd.ms-excel ';

  const [currentMainFile, setCurrentMainFile] = useState<File>();
  const [currentSupportFile, setCurrentSupportFile] = useState<File>();
  //#endregion

  //#region FILE INITIALVALUES
  useEffect(() => {
    let isDispatch = true;
    if (isDispatch) {
      urlToFile(
        data?.mainFile || '',
        data?.mainFileName || '',
        fileType,
      ).then((res) => setCurrentMainFile(res));

      if (data?.supportFile) {
        urlToFile(
          data?.supportFile || '',
          data?.supportFileName || '',
          fileType,
        ).then((res) => setCurrentSupportFile(res));
      }
    }
    return () => {
      isDispatch = false;
    };
  }, [
    data?.mainFile,
    data?.mainFileName,
    data?.supportFile,
    data?.supportFileName,
  ]);
  //#endregion

  //#region INITIAL VALUES
  const initialValues: WorkReportFormDataTemp = {
    id: data?.id || '',
    name: data?.name || '',
    year: data?.year ? moment(data.year) : '',
    month: data?.month ? moment(data.month) : '',
    reportType: WorkReportType.RECONCILIATION,
    mainFile: currentMainFile || undefined,
    supportFile: currentSupportFile || undefined,
  };
  //#endregion

  //#region HANDLE UPLOAD WORK REPORT
  const handleUploadWorkReport = useCallback(
    (payload: WorkReportFormDataTemp) => {
      const {month, year} = payload;
      const formDataPayload = {
        ...payload,
        name: `Laporan ${payload.reportType} ${month} ${year}`,
        month,
        year,
      } as WorkReportFormData;
      if (formDataPayload.id) {
        dispatch(workReportEditAction.fetch(formDataPayload));
      } else {
        dispatch(workReportCreateAction.fetch(formDataPayload));
      }
    },
    [dispatch],
  );
  //#endregion

  //#region HANDLE SUBMIT
  const handleSubmit = (_workReport: WorkReportFormDataTemp) => {
    handleUploadWorkReport(_workReport);
  };
  //#endregion

  return (
    <div className="work-report-admin-form-container">
      <WorkReportAdminForm
        isLoading={isLoading}
        initialValues={initialValues}
        handleSubmitWorkReport={handleSubmit}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default WorkReportAdminFormContainer;
