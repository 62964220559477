import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {httpPut} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../user.reducer';
import {userAccessDeleteAction} from './userAccessDelete.action';
import {
  UserAccessDeleteAction,
  UserAccessDeleteFetchPayload,
  UserAccessDeleteSuccessPayload,
} from './userAccessDelete.type';

function* workerFetch(action: GenericAction<UserAccessDeleteFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id} = action.payload;
  try {
    const res: AxiosResponse<UserAccessDeleteSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/user/delete/${id}`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(userAccessDeleteAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userAccessDeleteAction.failed(e.response.data.payload));
  }
}

const userAccessDeleteWatcher = [
  takeLatest(UserAccessDeleteAction.FETCH, workerFetch),
];

export default userAccessDeleteWatcher;
