//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {
  masterDataRouteCreateAction,
  masterDataRouteEditAction,
} from '../../../../usecases/masterData.action';

// Components
import RouteForm from './components/RouteForm';
// Data
import {
  IMasterDataRouteForm,
  MasterDataRute,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataRouteFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: MasterDataRute;
  isLoading?: boolean;
  assignmentType?: string;
  onCancel(): void;
}
//#endregion

const MasterDataRouteFormContainer: React.FC<Props> = ({
  data,
  isLoading,
  assignmentType,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: IMasterDataRouteForm = {
    id: data?.id,
    corridor: data?.corridor || '',
    assignmentType: assignmentType || '',
    name: data?.route || '',
    busStop: data?.busStop || '',
    busStopCode: data?.busStopCode || '',
  };
  //#endregion

  return (
    <div className="master-data-route-form-container">
      <RouteForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitRoute={(route) => {
          if (route.id) {
            dispatch(
              masterDataRouteEditAction.fetch({...route, id: route.id || ''}),
            );
            return;
          }
          dispatch(masterDataRouteCreateAction.fetch(route));
        }}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default MasterDataRouteFormContainer;
