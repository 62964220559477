import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataReviewIndicatorMoveAction,
  MasterDataReviewIndicatorMoveFailedPayload,
  MasterDataReviewIndicatorMoveFetchPayload,
  MasterDataReviewIndicatorMoveSuccessPayload,
} from './masterDataReviewIndicatorMove.type';

export const masterDataReviewIndicatorMoveAction = {
  fetch: createAction<MasterDataReviewIndicatorMoveFetchPayload>(
    MasterDataReviewIndicatorMoveAction.FETCH,
  ),
  success: createAction<MasterDataReviewIndicatorMoveSuccessPayload>(
    MasterDataReviewIndicatorMoveAction.SUCCESS,
  ),
  failed: createAction<MasterDataReviewIndicatorMoveFailedPayload>(
    MasterDataReviewIndicatorMoveAction.FAILED,
  ),
  reset: createAction(MasterDataReviewIndicatorMoveAction.RESET),
};
