import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../user.reducer';
import {userAccessListAction} from './userAccessList.action';
import {
  UserAccessListAction,
  UserAccessListFetchPayload,
  UserAccessListResponse,
  UserAccessListSuccessPayload,
} from './userAccessList.type';

function* workerFetch(action: GenericAction<UserAccessListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<UserAccessListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/user/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.data) {
      yield put(
        userAccessListAction.success(res.data as UserAccessListResponse),
      );
      yield put(
        userAccessListAction.update(res.data as UserAccessListResponse),
      );
    } else {
      yield put(userAccessListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(userAccessListAction.failed(e as CommonError));
  }
}

const userAccessListWatcher = [
  takeLatest(UserAccessListAction.FETCH, workerFetch),
];

export default userAccessListWatcher;
