//#region IMPORT
// Libraries
import React from 'react';
import {TableHead, TableRow, TableCell} from '@mui/material';
// Utils
import t from '../../../../../../../../lang';
//#endregion

const FormControllerManagementListTableHeader: React.FC = () => {
  return (
    <TableHead className="form-controller-management-list-table-header">
      <TableRow className="form-controller-management-list-table-header__row">
        <TableCell
          align="center"
          padding="none"
          width="3%"
          className="table-header-label"></TableCell>
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="table-header-label">
          {t('No')}
        </TableCell>
        <TableCell align="left" padding="normal" className="table-header-label">
          {t('Category')}
        </TableCell>
        <TableCell align="left" padding="normal" className="table-header-label">
          {t('Basic Service Type')}
        </TableCell>
        <TableCell align="left" padding="normal" className="table-header-label">
          {t('Review Indicator')}
        </TableCell>
        <TableCell align="left" padding="normal" className="table-header-label">
          {t('Detail Indicator')}
        </TableCell>
        <TableCell align="left" padding="normal" className="table-header-label">
          {t('Indicator Value')}
        </TableCell>

        <TableCell
          className="form-controller-management-list-table-header__row--action table-header-label"
          align="right"
          padding="none"
        />
      </TableRow>
    </TableHead>
  );
};

export default FormControllerManagementListTableHeader;
