//#region IMPORT
import TablePagination from '@mui/material/TablePagination';
import React from 'react';
import t from '../../lang';
import './MTablePagination.scss';
//#endregion

interface Props {
  count: number;
  rowsPerPage: number;
  page: number;
  rowsPerPageOptions: number[];
  className?: string;
  onChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ): void;
  onChangeRowsPerPage(e: React.ChangeEvent<HTMLInputElement>): void;
}

const MTablePagination: React.FC<Props> = ({
  count,
  rowsPerPage,
  page,
  rowsPerPageOptions,
  className,
  onChangePage,
  onChangeRowsPerPage,
}: Props) => {
  return (
    <div className={`m-table-pagination ${className}`}>
      <TablePagination
        labelDisplayedRows={({from, to, count}) =>
          `${from}-${to} ${t('of')} ${count}`
        }
        labelRowsPerPage={t('Rows Per Page')}
        className="m-table-pagination__component"
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </div>
  );
};

export default MTablePagination;
