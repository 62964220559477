//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import {MasterDataObservationValueCreateFetchPayload} from '../../../../usecases/masterData.type';
// Utils
import {masterDataObservationValueCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataObservationValueFormCreate} from './components';
import {ObservationValueFormCreateInitialValues} from './components/MasterDataObservationValueFormCreate/MasterDataObservationValueFormCreate.component';
// Assets
import './MasterDataObservationValueFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataObservationValueFormCreateContainer: React.FC<Props> = ({
  assignmentType,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: ObservationValueFormCreateInitialValues = {
    observationValue: [{name: ''}],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitObservationValue = (
    formValue: ObservationValueFormCreateInitialValues,
  ) => {
    const formattedValue: MasterDataObservationValueCreateFetchPayload =
      formValue?.observationValue.map((item) => ({
        name: item.name,
        assignmentType,
      })) || [];
    dispatch(masterDataObservationValueCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-observation-value-form-create-container">
      <MasterDataObservationValueFormCreate
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitObservationValue={handleSubmitObservationValue}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataObservationValueFormCreateContainer;
