/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {InputAdornment, TextareaAutosize, TextField} from '@mui/material';
import classnames from 'classnames';
import {FieldProps, FormikErrors} from 'formik';
import isArray from 'lodash/isArray';
import CurrencyInput from '../CurrencyInput';
import MSpinner from '../MSpinner';
import './MTextField.scss';
import {IconEye, IconEyeOff} from '../../assets/icons';

interface Props extends FieldProps<string> {
  className?: string;
  disabled?: boolean;
  type?: string;
  errormessage?: string;
  helperText?: string;
  isTextArea?: boolean;
  isNumber?: boolean;
  isDecimal?: boolean;
  hasCounter?: boolean;
  maxLength?: number;
  prefix?: string | React.ReactElement;
  postfix?: string | React.ReactElement;
  isLoading?: boolean;
  isCurrency?: boolean;
  decimalScale?: number;
  textFieldRows?: number;
  maxCurrency?: string;
  handleChangeInput?(e: string): void;
  handleBlurInput?(e: string): void;
}
const MTextField: React.FC<Props> = ({
  className,
  disabled,
  type,
  helperText,
  errormessage,
  isTextArea,
  isNumber,
  isDecimal,
  hasCounter,
  form,
  field,
  prefix,
  postfix,
  isLoading,
  maxLength,
  isCurrency,
  decimalScale,
  maxCurrency,
  textFieldRows,
  handleChangeInput,
  handleBlurInput,
  ...otherProps
}: Props) => {
  //#region GENERAL
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const fieldErrorValidate = ():
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined => {
    if (!field?.name) return;

    if (field?.name.includes('.')) {
      const chunks = field.name.split('.');
      const fieldArr = form?.errors[chunks[0]];
      if (isArray(fieldArr)) {
        return fieldArr[chunks[1] as any] &&
          Object.getOwnPropertyDescriptor(fieldArr[chunks[1] as any], chunks[2])
            ?.value
          ? Object.getOwnPropertyDescriptor(
              fieldArr[chunks[1] as any],
              chunks[2],
            )?.value
          : undefined;
      }
      return form?.errors[chunks[0]] as string;
    }

    return form?.errors[field?.name];
  };

  const isError =
    errormessage || form.errors[field.name] || fieldErrorValidate();

  const classes = classnames(
    'mtextfield',
    {
      'mtextfield--error': isError,
    },
    className,
  );
  //#endregion
  if (isCurrency) {
    return (
      <div className={classes}>
        <TextField
          className={`mtextfield__input ${className || ''}`}
          disabled={!isLoading && disabled}
          {...field}
          {...otherProps}
          onBlur={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ): void => {
            const val = e.target.value;
            handleBlurInput && handleBlurInput(val);
          }}
          InputProps={{
            inputComponent: CurrencyInput as any,
            inputProps: {
              maxCurrency: maxCurrency,
              decimalScale: decimalScale,
            },
          }}
        />

        {isError && (
          <div className="mtextfield__errorText error--text">
            {errormessage || form.errors[field.name] || fieldErrorValidate()}
          </div>
        )}
        {helperText && !form.errors && (
          <div className="mtextfield__helperText helper-text">{helperText}</div>
        )}
      </div>
    );
  }
  return (
    <div className={classes}>
      <TextField
        className={`mtextfield__input ${className || ''}`}
        disabled={!isLoading && disabled}
        type={isPasswordVisible ? 'text' : type}
        {...field}
        {...otherProps}
        InputProps={{
          ...(isTextArea
            ? {
                inputComponent: TextareaAutosize as any,
                inputProps: {minRows: textFieldRows || 3},
              }
            : {}),
          ...(prefix
            ? {
                startAdornment: (
                  <InputAdornment position="start">{prefix}</InputAdornment>
                ),
              }
            : {}),
          ...(isLoading || postfix
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading ? <MSpinner isSmall /> : postfix}
                  </InputAdornment>
                ),
              }
            : {}),
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          const val = e.currentTarget.value;
          const isNumberInput = /^\d*[.,]?\d*$/.test(val);
          const isText = /[a-z\W]/gi.test(val);
          const isZeroPrefix = /^[0]\d{1,}/.test(val);
          const isValDecimal = new RegExp(
            `^[0-9]+.?[0-9]{0,${decimalScale ?? 2}}$`,
          ).test(val);
          const isCommaPrefix = /^,/.test(val);
          const isDoubleZero = /^0{2,}/.test(val);
          const isPrefixWhiteSpacePrevented = /^$|^\S/.test(val);
          const isDoubleWhiteSpace = /\s{2,}/.test(val);
          const isMaxLength = maxLength && val.length > maxLength;
          // NUMBER
          if (
            form &&
            field &&
            isNumber &&
            isNumberInput &&
            !isText &&
            !isZeroPrefix
          ) {
            form.setFieldValue(field.name, val);
            handleChangeInput && handleChangeInput(val);
          }
          // NUMBER WITH DECIMALS
          if (
            form &&
            field &&
            isNumberInput &&
            isValDecimal &&
            !isCommaPrefix &&
            !isDoubleZero &&
            !isZeroPrefix &&
            isDecimal
          ) {
            form.setFieldValue(field.name, val);
            handleChangeInput && handleChangeInput(val);
          }
          // NORMAL INPUT
          if (
            !isNumber &&
            isPrefixWhiteSpacePrevented &&
            !isDoubleWhiteSpace &&
            !isTextArea
          ) {
            form?.handleChange(e);
            handleChangeInput && handleChangeInput(val);
          }
          if (isTextArea && (!maxLength || !isMaxLength)) {
            form?.handleChange(e);
            handleChangeInput && handleChangeInput(val);
          }
        }}
        onBlur={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ): void => {
          const val = e.currentTarget.value;
          handleBlurInput && handleBlurInput(val);
        }}
        onClick={(e) => e.stopPropagation()}
      />
      {type === 'password' && (
        <div
          className="password-icon"
          onClick={() => setPasswordVisible((_prevPass) => !_prevPass)}>
          {isPasswordVisible ? <IconEye /> : <IconEyeOff />}
        </div>
      )}

      {isError && (
        <div className="mtextfield__errorText error--text">
          {errormessage || form.errors[field.name] || fieldErrorValidate()}
        </div>
      )}
      {helperText && !form.errors && (
        <div className="mtextfield__helperText helper-text">{helperText}</div>
      )}
      {hasCounter && (
        <div className="mtextfield__counter helper--text">
          {field.value.length}/{maxLength}
        </div>
      )}
    </div>
  );
};

export default MTextField;
