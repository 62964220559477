//#region IMPORT
// Libraries
import React from 'react';
import moment from 'moment';
import {Table, TableBody, TableCell, TableRow} from '@mui/material';
// Utils
import t from '../../../../../../../lang';
import {colors} from '../../../../../../../config/_theme';
import {followUpManagementDataToIcon} from '../../../../../../followUp/utils';
// Components
import {FollowUpChip} from '../../../../../../followUp/components';
// Data
import {EvaluationReportDetailData} from '../../../../../entity/evaluationReport.string.entity';
import {AssignmentType} from '../../../../../../wrapper/entity';
// Assets
import './EvaluationReportDetailHeader.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: EvaluationReportDetailData;
}
//#endregion

const EvaluationReportDetailHeader: React.FC<Props> = ({data}: Props) => {
  //#region GENERAL
  const {icon} = followUpManagementDataToIcon(
    data?.assignmentType || AssignmentType.HALTE,
  );
  //#endregion

  const detailHeaderTableItem = (title: string, value?: string) => (
    <TableRow>
      <TableCell padding="none" align="left" width="10%">
        {title}
      </TableCell>
      <TableCell
        align="left"
        width="100%"
        className="evaluation-report-detail-header__table-text">
        {value ?? '-'}
      </TableCell>
    </TableRow>
  );

  const detailHeaderTable = () => (
    <div className="evaluation-report-detail-header__table">
      <Table size="small">
        <TableBody>
          {data?.busStopCode &&
            detailHeaderTableItem(t('Bus Stop Code'), data?.busStopCode)}
          {data?.corridor &&
            detailHeaderTableItem(
              t(
                data.assignmentType === AssignmentType.BUSKECIL ||
                  data.assignmentType === AssignmentType.BUSKECILTENGAH ||
                  data.assignmentType === AssignmentType.BUSKECILUJUNG
                  ? 'Route Code'
                  : 'Corridor',
              ),
              data?.corridor,
            )}
          {data?.routeName &&
            detailHeaderTableItem(t('Route'), data?.routeName)}
          {data?.location &&
            detailHeaderTableItem(t('Location'), data?.location)}
          {data?.busType && detailHeaderTableItem(t('Bus Type'), data?.busType)}
          {detailHeaderTableItem(t('Officer Name'), data?.officerName ?? '-')}
        </TableBody>
      </Table>
    </div>
  );

  return (
    <div className="evaluation-report-detail-header">
      <div className="evaluation-report-detail-header__upper">
        <div className="evaluation-report-detail-header__upper-icon">
          {icon}
        </div>
        <div className="evaluation-report-detail-header__upper-title">
          {data?.bodyNumber || data?.busStop || '-'}
        </div>
        <div className="evaluation-report-detail-header__upper-date">
          {moment(data?.date, 'YYYY-MM-DD HH:mm').format(
            'DD MMM YYYY, HH:mm',
          ) || '-'}
        </div>
        {data?.routeType && (
          <div className="evaluation-report-detail-header__upper-type">
            <FollowUpChip
              text={data?.routeType}
              backgroundColor={colors.primary.blue1}
              color={colors.primary.blue}
            />
          </div>
        )}
        <div className="evaluation-report-detail-header__upper-number">
          {data?.reviewReportCode || '-'}
        </div>
      </div>

      {detailHeaderTable()}
    </div>
  );
};
export default EvaluationReportDetailHeader;
