/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  masterDataBusCreateAction,
  masterDataBusDeleteAction,
  masterDataBusEditAction,
  masterDataBusListAction,
  masterDataBusOptionListAction,
} from '../../../../usecases/masterData.action';
import {
  MasterDataBusCreateAction,
  MasterDataBusDeleteAction,
  MasterDataBusEditAction,
  MasterDataBusListAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  AlertDialog,
  MButton,
  MDropdown,
  Modal,
  MSearchInput,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataBusManagementList} from './components';
// Data
import {PATH} from '../../../../../wrapper/entity';
import {
  MasterDataBus,
  MasterDataBusOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataBusSection.scss';
import MasterDataBusFormContainer from '../MasterDataBusFormContainer';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

const MasterDataBusSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [busTypeFilter, setBusTypeFilter] = useState('');
  const [isAddBus, setIsAddBus] = useState(false);
  const [isEditBus, setIsEditBus] = useState<MasterDataBus | undefined>(
    undefined,
  );
  const [isDeleteBus, setIsDeleteBus] = useState<MasterDataBus | undefined>(
    undefined,
  );

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const isLoadingAddBus = masterData.masterDataBusCreateLoading;
  const isLoadingEditBus = masterData.masterDataBusEditLoading;
  const isLoadingDeleteBus = masterData.masterDataBusDeleteLoading;
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  //#endregion

  //#region PAGINATION CONTROL
  const [masterDataBusListPage, setMasterDataBusListPage] = useState<number>(0);

  const [masterDataBusListCount, setMasterDataBusListCount] = useState<number>(
    0,
  );

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataBusListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataBusListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataBusOrderByType>(
    'bodyNumber',
  );

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataBusOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH BUS LIST
  const fetchBusList = useCallback(
    (page?: number, size?: number, search?: string, busType?: string) => {
      dispatch(
        masterDataBusListAction.fetch({
          page,
          search,
          size,
          ...(busType ? {busType} : {}),
        }),
      );
    },
    [dispatch],
  );
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataBusDeleteAction.fetch({id}));
  };
  //#endregion

  //#region FETCH BUS OPTION LIST
  const fetchBusOptionList = useCallback(() => {
    dispatch(masterDataBusOptionListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataBusListAction.reset());
    fetchBusList(
      masterDataBusListPage,
      rowsPerPage,
      searchValue,
      busTypeFilter,
    );

    return () => {
      dispatch(masterDataBusListAction.reset());
    };
  }, [
    dispatch,
    fetchBusList,
    masterDataBusListPage,
    rowsPerPage,
    searchValue,
    busTypeFilter,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region REFRESH OPTION LIST
  const refreshOption = useCallback(() => {
    dispatch(masterDataBusOptionListAction.reset());
    fetchBusOptionList();

    return () => {
      dispatch(masterDataBusOptionListAction.reset());
    };
  }, [dispatch, fetchBusOptionList]);

  useEffect(() => {
    refreshOption();
  }, [refreshOption]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataBusActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataBusListAction.UPDATE, (): void => {
            setMasterDataBusListCount(
              masterData?.masterDataBusListResponse?.data?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataBusListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBusListAction.reset());
          })
          .addCase(MasterDataBusCreateAction.SUCCESS, (): void => {
            setIsAddBus(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataBusCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBusCreateAction.reset());
          })
          .addCase(MasterDataBusEditAction.SUCCESS, (): void => {
            setIsEditBus(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataBusEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBusEditAction.reset());
          })
          .addCase(MasterDataBusDeleteAction.SUCCESS, (): void => {
            setIsDeleteBus(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataBusDeleteAction.FAILED, (): void => {
            const errorMessage = masterData.masterDataBusDeleteError?.info
              ?.message
              ? t(masterData.masterDataBusDeleteError?.info?.message)
              : t('Data deleted failed!');
            dispatch(
              wrapperNotifyAction.fetch({
                text: errorMessage,
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataBusDeleteAction.reset());
          });
      });
    },
    [
      dispatch,
      refresh,
      masterData?.masterDataBusListResponse?.data?.totalElements,
      masterData.masterDataBusDeleteError?.info?.message,
    ],
  );
  useEffect(() => {
    masterDataBusActionHandler(masterData.action);
  }, [masterData.action, masterDataBusActionHandler]);
  //#endregion

  if (!permissions?.masterBusData) return <Redirect to={PATH.HOME} />;
  return (
    <>
      <div className="master-data-bus-section">
        <div className="master-data-bus-section__header">
          <div className="master-data-bus-section__header--action">
            <MSearchInput
              inputClassName="master-data-bus-section__header--search"
              inputValue={searchValue}
              placeholder={t('Search Master Data Bus')}
              setInputValue={(search: string) => {
                setMasterDataBusListPage(0);
                setSearchValue(search);
              }}
            />

            <MDropdown
              inputWidth="20rem"
              placeholder={t('Bus Type')}
              inputValue={busTypeFilter}
              options={masterData.masterDataBusOptionListData.map(
                (optionItem) => ({label: optionItem, value: optionItem}),
              )}
              handleChangeSelect={(value) => {
                setMasterDataBusListPage(0);
                setBusTypeFilter(value);
              }}
            />
          </div>
          <div className="master-data-bus-section__header--add-action">
            <MButton
              buttonLabel={t('Add')}
              handleClick={() => setIsAddBus(true)}
            />
          </div>
        </div>
        <MasterDataBusManagementList
          busList={masterData.masterDataBusListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          onEditBus={setIsEditBus}
          onDeleteBus={setIsDeleteBus}
        />
        <MTablePagination
          className="master-data-bus-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataBusListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataBusListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setIsAddBus(false)}
        title={t('Add Bus')}
        isOpen={isAddBus}>
        <MasterDataBusFormContainer
          isLoading={isLoadingAddBus}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditBus && (
        <Modal
          onClose={() => setIsEditBus(undefined)}
          isOpen={!!isEditBus}
          title={t('Edit Bus')}>
          <MasterDataBusFormContainer
            data={isEditBus}
            isLoading={isLoadingEditBus}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteBus && (
        <AlertDialog
          onClose={() => setIsDeleteBus(undefined)}
          onCancel={() => setIsDeleteBus(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteBus.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteBus}
          isLoading={isLoadingDeleteBus}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setIsAddBus(false)
              : setIsEditBus(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataBusSection;
