import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailIndicatorEditAction,
  MasterDataDetailIndicatorEditFailedPayload,
  MasterDataDetailIndicatorEditFetchPayload,
  MasterDataDetailIndicatorEditSuccessPayload,
} from './masterDataDetailIndicatorEdit.type';

export const masterDataDetailIndicatorEditAction = {
  fetch: createAction<MasterDataDetailIndicatorEditFetchPayload>(
    MasterDataDetailIndicatorEditAction.FETCH,
  ),
  success: createAction<MasterDataDetailIndicatorEditSuccessPayload>(
    MasterDataDetailIndicatorEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailIndicatorEditFailedPayload>(
    MasterDataDetailIndicatorEditAction.FAILED,
  ),
  reset: createAction(MasterDataDetailIndicatorEditAction.RESET),
};
