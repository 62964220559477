import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDepartmentOptionListAction,
  MasterDataDepartmentOptionListFailedPayload,
  MasterDataDepartmentOptionListFetchPayload,
  MasterDataDepartmentOptionListSuccessPayload,
  MasterDataDepartmentOptionListUpdatePayload,
} from './masterDataDepartmentOptionList.type';

export const masterDataDepartmentOptionListAction = {
  fetch: createAction<MasterDataDepartmentOptionListFetchPayload>(
    MasterDataDepartmentOptionListAction.FETCH,
  ),
  success: createAction<MasterDataDepartmentOptionListSuccessPayload>(
    MasterDataDepartmentOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDepartmentOptionListFailedPayload>(
    MasterDataDepartmentOptionListAction.FAILED,
  ),
  update: createAction<MasterDataDepartmentOptionListUpdatePayload>(
    MasterDataDepartmentOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataDepartmentOptionListAction.RESET),
};
