import React, {useCallback} from 'react';
import {FieldProps} from 'formik';
import {Button, InputLabel} from '@mui/material';
import t from '../../lang';
import {bytesToSize} from '../../utils';
import {IconFileExcel, IconUpload} from '../../assets/icons';
import './MFileInput.scss';

interface Props extends FieldProps<string> {
  disabled?: boolean;
  accept?: string[];
  className?: string;
  errormessage?: string;
  helperText?: string;
  icon?: string | React.ReactElement;
  label?: string;
  placeholder?: string;
  labelClassName: string;
}

const MFileInput: React.FC<Props> = ({
  disabled = false,
  accept,
  className,
  errormessage,
  helperText,
  icon = <IconUpload />,
  label,
  placeholder = t('Upload File'),
  labelClassName,
  field,
  form,
  ...otherProps
}: Props) => {
  const acceptedType = accept?.join(', ');

  const onChangeInput = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles) {
        const fileTemp = acceptedFiles[0];
        form.setFieldValue(field.name, fileTemp);
      }
    },
    [form, field.name],
  );

  const handleDelete = () => {
    form.setFieldValue(field.name, undefined);
  };

  const uploadedFiles = (file: File | undefined) => {
    return (
      <div className="m-file-upload__result">
        <div className="m-file-upload__result--icon">
          <IconFileExcel />
        </div>
        <div className="m-file-upload__result--caption">
          <div className="m-file-upload__result--caption-name">
            {file?.name}
          </div>
          <div className="m-file-upload__result--caption-size">
            {bytesToSize(file?.size, 2)}
          </div>
        </div>
        <div className="m-file-upload__result--action" onClick={handleDelete}>
          {t('Delete')}
        </div>
      </div>
    );
  };

  return (
    <div className={`m-file-upload ${className}`}>
      <InputLabel className="m-file-upload__label">{label}</InputLabel>
      {form.values[field.name] && !form.errors[field.name] ? (
        uploadedFiles(form.values[field.name])
      ) : (
        <div className="m-file-upload__form">
          <input
            {...(acceptedType ? {accept: acceptedType} : {})}
            className="m-file-upload__form-input"
            id={`m-file-upload__form-input--${field.name}`}
            type="file"
            name={field.name}
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const file = e.target.files;
                onChangeInput(file);
              }
            }}
            {...otherProps}
          />
          <label htmlFor={`m-file-upload__form-input--${field.name}`}>
            <div className={`m-file-upload__form-button ${labelClassName}`}>
              <Button component="span" startIcon={icon} disabled={disabled}>
                {placeholder}
              </Button>
            </div>
          </label>
        </div>
      )}

      {(errormessage || form.errors[field.name]) && (
        <div className="m-file-upload__errorText error--text">
          {errormessage || form.errors[field.name]}
        </div>
      )}
      {helperText && !form.errors && (
        <div className="m-file-upload__helperText helper-text">
          {helperText}
        </div>
      )}
    </div>
  );
};

export default MFileInput;
