import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataCategoryListAction} from './masterDataCategoryList.action';
import {
  MasterDataCategoryListAction,
  MasterDataCategoryListFetchPayload,
  MasterDataCategoryListResponse,
  MasterDataCategoryListSuccessPayload,
} from './masterDataCategoryList.type';

function* workerFetch(
  action: GenericAction<MasterDataCategoryListFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataCategoryListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/category/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataCategoryListAction.success(
          res.data as MasterDataCategoryListResponse,
        ),
      );
      yield put(
        masterDataCategoryListAction.update(
          res.data as MasterDataCategoryListResponse,
        ),
      );
    } else {
      yield put(masterDataCategoryListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(masterDataCategoryListAction.failed(e as CommonError));
  }
}

const masterDataCategoryListWatcher = [
  takeLatest(MasterDataCategoryListAction.FETCH, workerFetch),
];

export default masterDataCategoryListWatcher;
