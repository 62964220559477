import {
  DashboardSPMChartSectionAction,
  DashboardSPMChartSectionSuccessPayload,
  DashboardSPMChartSectionFailedPayload,
  DashboardSPMChartSectionFetchPayload,
} from './dashboardSPMChartSection.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardSPMChartSectionInitialState} from './dashboardSPMChartSection.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardSPMChartSectionHandler = {
  [DashboardSPMChartSectionAction.FETCH]: (
    state: DashboardState,
    action: GenericAction<DashboardSPMChartSectionFetchPayload>,
  ): DashboardState => {
    return {
      ...state,
      dashboardSPMChartSectionParams: action.payload,
      dashboardSPMChartSectionLoading: true,
      action: DashboardSPMChartSectionAction.FETCH,
    };
  },
  [DashboardSPMChartSectionAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardSPMChartSectionSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardSPMChartSectionLoading: false,
    dashboardSPMChartSectionResponse: action.payload,
    action: DashboardSPMChartSectionAction.SUCCESS,
  }),
  [DashboardSPMChartSectionAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardSPMChartSectionFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardSPMChartSectionLoading: false,
    dashboardSPMChartSectionError: action.payload,
    action: DashboardSPMChartSectionAction.FAILED,
  }),
  [DashboardSPMChartSectionAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardSPMChartSectionInitialState,
    action: DashboardSPMChartSectionAction.RESET,
  }),
};
