import {CommonError} from '../../../../../../config/constant';
import {MasterDataDirectorateFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataDirectorateCreateAction = {
  FETCH: 'MASTER_DATA_DIRECTORATE_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_DIRECTORATE_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_DIRECTORATE_CREATE_FAILED',
  RESET: 'MASTER_DATA_DIRECTORATE_CREATE_RESET',
};

export interface MasterDataDirectorateCreateInitialState {
  masterDataDirectorateCreateLoading: boolean;
  masterDataDirectorateCreateParams?: MasterDataDirectorateCreateFetchPayload;
  masterDataDirectorateCreateError?: MasterDataDirectorateCreateFailedPayload;
  masterDataDirectorateCreateResponse?: MasterDataDirectorateCreateSuccessPayload;
}

export type MasterDataDirectorateCreateFetchPayload = Omit<
  MasterDataDirectorateFormData,
  'id'
>[];

export type MasterDataDirectorateCreateFailedPayload = CommonError;

export interface MasterDataDirectorateCreateSuccessPayload {}
