import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './MSpinner.scss';

interface Props {
  isSmall?: boolean;
  size?: number;
  color?: 'inherit' | 'primary' | 'secondary';
  className?: string;
}
const MSpinner: React.FC<Props> = ({
  isSmall,
  size,
  color,
  className,
}: Props) => (
  <div className={`spinner ${className || ''}`}>
    <CircularProgress
      size={size ? size : isSmall ? 10 : undefined}
      color={color || 'primary'}
    />
  </div>
);

export default MSpinner;
