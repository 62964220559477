import {createAction} from '@reduxjs/toolkit';
import {
  WorkReportDeleteAction,
  WorkReportDeleteFailedPayload,
  WorkReportDeleteFetchPayload,
  WorkReportDeleteSuccessPayload,
} from './workReportDelete.type';

export const workReportDeleteAction = {
  fetch: createAction<WorkReportDeleteFetchPayload>(
    WorkReportDeleteAction.FETCH,
  ),
  success: createAction<WorkReportDeleteSuccessPayload>(
    WorkReportDeleteAction.SUCCESS,
  ),
  failed: createAction<WorkReportDeleteFailedPayload>(
    WorkReportDeleteAction.FAILED,
  ),
  reset: createAction(WorkReportDeleteAction.RESET),
};
