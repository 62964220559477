//#region IMPORT
// Libraries
import React, {useCallback, useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
import {AppState} from '../../../../../../../../config/Interface';
import {UserState} from '../../../../../../../user/usecases/user.reducer';
import {userRoleAccessListAction} from '../../../../../../../user/usecases/userRoleAccessList/userRoleAccessList.action';
import {
  getOptionFromRoleList,
  getOptionFromDepartmentList,
} from '../../../../../../../../utils';
import {MasterDataState} from '../../../../../../usecases/masterData.reducer';
import {masterDataDepartmentListAction} from '../../../../../../usecases/masterData.action';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../../../components';
// Data
import {MasterDataReviewIndicatorFormData} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataReviewIndicatorForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  initialValues: MasterDataReviewIndicatorFormData;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitReviewIndicator(
    formValue: MasterDataReviewIndicatorFormData,
  ): void;
}
//#endregion

const MasterDataReviewIndicatorForm: React.FC<Props> = ({
  initialValues,
  isLoading,
  handleSubmitReviewIndicator,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const user: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const masterData: MasterDataState = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const dispatch = useDispatch();
  //#endregion

  //#region FETCH ROLE
  const fetchRoleList = useCallback(() => {
    dispatch(userRoleAccessListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region FETCH DEPARTMENT
  const fetchDepartmentList = useCallback(() => {
    dispatch(masterDataDepartmentListAction.fetch({size: 100}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(userRoleAccessListAction.reset());
    dispatch(masterDataDepartmentListAction.reset());
    fetchRoleList();
    fetchDepartmentList();

    return () => {
      dispatch(userRoleAccessListAction.reset());
      dispatch(masterDataDepartmentListAction.reset());
    };
  }, [dispatch, fetchDepartmentList, fetchRoleList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const reviewIndicatorValidation = Yup.object().shape({
    name: Yup.string()
      .required(t('Review Indicator is required.'))
      .min(
        3,
        `${t('Review Indicator')} ${t('must be filled in at least')} ${3} ${t(
          'letters',
        )}.`,
      ),
    target: Yup.number().required(t('Target is required.')),
    spmWeight: Yup.number().required(t('SPM Weight is required.')),
    sla: Yup.number().required(t('SLA is required.')),
    departmentId: Yup.string().required(t('Department is required.')),
    roleId: Yup.string().required(t('Role is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-review-indicator-form">
      <div className="master-data-review-indicator-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={reviewIndicatorValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitReviewIndicator}>
          {({handleSubmit, isValid}): React.ReactElement => (
            <>
              <div className="master-data-review-indicator-form__section">
                <div className="master-data-review-indicator-form__section--field">
                  <div className="input-label">{t('Review Indicator')}</div>
                  <Field
                    name="name"
                    type="text"
                    placeholder={t('Enter Review Indicator')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
                <div className="master-data-review-indicator-form__section--field">
                  <div className="input-label">{t('Target')}</div>
                  <Field
                    name="target"
                    isNumber
                    placeholder={t('Enter Target')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
                <div className="master-data-review-indicator-form__section--field">
                  <div className="input-label">{t('SPM Weight')}</div>
                  <Field
                    name="spmWeight"
                    isDecimal
                    isNumber
                    decimalScale={3}
                    placeholder={t('Enter SPM Weight')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
                <div className="master-data-review-indicator-form__section--field">
                  <div className="input-label">{t('SLA')}</div>
                  <Field
                    name="sla"
                    isNumber
                    placeholder={t('Enter SLA')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
                <div className="master-data-review-indicator-form__section--field">
                  <div className="input-label">{t('Department PIC')}</div>
                  <Field
                    name="departmentId"
                    placeholder={t('Choose Department PIC')}
                    options={[
                      ...getOptionFromDepartmentList(
                        masterData.masterDataDepartmentListData,
                      ),
                      {label: 'Not Exist', value: ''},
                    ]}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
                <div className="master-data-review-indicator-form__section--field">
                  <div className="input-label">{t('Role')}</div>
                  <Field
                    name="roleId"
                    placeholder={t('Choose Role')}
                    options={[
                      ...getOptionFromRoleList(user.userRoleAccessListData),
                      {label: 'Not Exist', value: ''},
                    ]}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
              </div>

              <div className="master-data-review-indicator-form__form--action">
                <div className="master-data-review-indicator-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="master-data-review-indicator-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={initialValues?.id ? t('Save') : t('Add')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataReviewIndicatorForm;
