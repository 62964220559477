import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationListAction,
  MasterDataObservationListFailedPayload,
  MasterDataObservationListFetchPayload,
  MasterDataObservationListSuccessPayload,
  MasterDataObservationListUpdatePayload,
} from './masterDataObservationList.type';

export const masterDataObservationListAction = {
  fetch: createAction<MasterDataObservationListFetchPayload>(
    MasterDataObservationListAction.FETCH,
  ),
  success: createAction<MasterDataObservationListSuccessPayload>(
    MasterDataObservationListAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationListFailedPayload>(
    MasterDataObservationListAction.FAILED,
  ),
  update: createAction<MasterDataObservationListUpdatePayload>(
    MasterDataObservationListAction.UPDATE,
  ),
  reset: createAction(MasterDataObservationListAction.RESET),
};
