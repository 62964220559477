import * as React from 'react';

const IconTrophy = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.711 1.667a1.875 1.875 0 011.863 1.666h1.057a1.459 1.459 0 011.453 1.338l.005.12v2.5a2.709 2.709 0 01-2.555 2.705l-.175.005a4.798 4.798 0 01-3.943 3.291v1.291h1.46a2.708 2.708 0 012.704 2.555l.005.153v.417a.626.626 0 01-.54.62l-.085.005H5.625a.625.625 0 01-.62-.54L5 17.708v-.416a2.708 2.708 0 012.554-2.705l.155-.004h1.458v-1.29A4.798 4.798 0 015.227 10L5.203 10a2.708 2.708 0 01-2.708-2.708v-2.5c0-.805.653-1.459 1.458-1.459h1.057a1.875 1.875 0 011.864-1.667h5.836zm2.92 2.916h-1.045v4.153a1.458 1.458 0 001.248-1.316l.005-.128v-2.5a.208.208 0 00-.16-.203l-.048-.006zm-10.63 0H3.953a.208.208 0 00-.208.208v2.5A1.458 1.458 0 005 8.736V4.583z"
      fill={props.fill || '#F48519'}
    />
  </svg>
);

export default IconTrophy;
