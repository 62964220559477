import {CommonError} from '../../../../../../config/constant';
import {MasterDataBasicServiceTypeFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataBasicServiceTypeEditAction = {
  FETCH: 'MASTER_DATA_BASIC_SERVICE_TYPE_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_BASIC_SERVICE_TYPE_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_BASIC_SERVICE_TYPE_EDIT_FAILED',
  RESET: 'MASTER_DATA_BASIC_SERVICE_TYPE_EDIT_RESET',
};

export interface MasterDataBasicServiceTypeEditInitialState {
  masterDataBasicServiceTypeEditLoading: boolean;
  masterDataBasicServiceTypeEditParams?: MasterDataBasicServiceTypeEditFetchPayload;
  masterDataBasicServiceTypeEditError?: MasterDataBasicServiceTypeEditFailedPayload;
  masterDataBasicServiceTypeEditResponse?: MasterDataBasicServiceTypeEditSuccessPayload;
}

export type MasterDataBasicServiceTypeEditFetchPayload = MasterDataBasicServiceTypeFormData;

export type MasterDataBasicServiceTypeEditFailedPayload = CommonError;

export interface MasterDataBasicServiceTypeEditSuccessPayload {}
