import {
  MasterDataDetailIndicatorListAction,
  MasterDataDetailIndicatorListSuccessPayload,
  MasterDataDetailIndicatorListFailedPayload,
  MasterDataDetailIndicatorListFetchPayload,
} from './masterDataDetailIndicatorList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDetailIndicatorListInitialState} from './masterDataDetailIndicatorList.reducer';

export const masterDataDetailIndicatorListHandler = {
  [MasterDataDetailIndicatorListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDetailIndicatorListLoading: true,
      masterDataDetailIndicatorListParams: action.payload,
      action: MasterDataDetailIndicatorListAction.FETCH,
    };
  },
  [MasterDataDetailIndicatorListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorListLoading: false,
    masterDataDetailIndicatorListResponse: action.payload,
    action: MasterDataDetailIndicatorListAction.SUCCESS,
  }),
  [MasterDataDetailIndicatorListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorListLoading: false,
    masterDataDetailIndicatorListData: [
      ...state.masterDataDetailIndicatorListData,
      ...action.payload.data.content,
    ],
    action: MasterDataDetailIndicatorListAction.UPDATE,
  }),
  [MasterDataDetailIndicatorListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailIndicatorListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailIndicatorListLoading: false,
    masterDataDetailIndicatorListError: action.payload,
    action: MasterDataDetailIndicatorListAction.FAILED,
  }),
  [MasterDataDetailIndicatorListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailIndicatorListInitialState,
    action: MasterDataDetailIndicatorListAction.RESET,
  }),
};
