import {createAction} from '@reduxjs/toolkit';
import {
  FollowUpPICCompleteAction,
  FollowUpPICCompleteFailedPayload,
  FollowUpPICCompleteFetchPayload,
  FollowUpPICCompleteSuccessPayload,
} from './followUpPICComplete.type';

export const followUpPICCompleteAction = {
  fetch: createAction<FollowUpPICCompleteFetchPayload>(
    FollowUpPICCompleteAction.FETCH,
  ),
  success: createAction<FollowUpPICCompleteSuccessPayload>(
    FollowUpPICCompleteAction.SUCCESS,
  ),
  failed: createAction<FollowUpPICCompleteFailedPayload>(
    FollowUpPICCompleteAction.FAILED,
  ),
  reset: createAction(FollowUpPICCompleteAction.RESET),
};
