import * as React from 'react';

const IconInfo = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width={24 || props.width}
    height={24 || props.height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12S6.202 22.5 12 22.5 22.5 17.798 22.5 12 17.798 1.5 12 1.5Zm.75 15.563a.188.188 0 0 1-.188.187h-1.124a.188.188 0 0 1-.188-.188v-6.375c0-.103.084-.187.188-.187h1.124c.104 0 .188.084.188.188v6.374ZM12 9a1.125 1.125 0 0 1 0-2.25A1.125 1.125 0 0 1 12 9Z"
      fill={props.fill || '#4A90E2'}
    />
  </svg>
);

export default IconInfo;
