import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailObservationDeleteAction,
  MasterDataDetailObservationDeleteFailedPayload,
  MasterDataDetailObservationDeleteFetchPayload,
  MasterDataDetailObservationDeleteSuccessPayload,
} from './masterDataDetailObservationDelete.type';

export const masterDataDetailObservationDeleteAction = {
  fetch: createAction<MasterDataDetailObservationDeleteFetchPayload>(
    MasterDataDetailObservationDeleteAction.FETCH,
  ),
  success: createAction<MasterDataDetailObservationDeleteSuccessPayload>(
    MasterDataDetailObservationDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailObservationDeleteFailedPayload>(
    MasterDataDetailObservationDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataDetailObservationDeleteAction.RESET),
};
