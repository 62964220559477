//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import moment from 'moment';
// Utils
import t from '../../../../../../../lang';
// Components
import {
  MButton,
  MDatepickerInput,
  MFileInput,
  MTextField,
} from '../../../../../../../components';
// Data
import {WorkReportFormDataTemp} from '../../../../../entity/workReport.string.entity';
// Assets
import './WorkReportForm.component.style.scss';
//#endregion

interface Props {
  isLoading?: boolean;
  initialValues: WorkReportFormDataTemp;
  handleCancel(): void;
  handleSubmitWorkReport(_workReport: WorkReportFormDataTemp): void;
}
const WorkReportForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitWorkReport,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const isEdit = !!initialValues?.id;
  const acceptedType = ['.xls', '.xlsx', '.csv'];
  const maxSize = 5 * 1000 * 1024;
  //#endregion

  //#region VALIDATION
  const workReportPICValidation = Yup.object().shape({
    year: Yup.string().required(t('Year is required.')),
    month: Yup.string().required(t('Month is required.')),
    departmentName: Yup.string().required(t('Department is required.')),
    mainFile: Yup.mixed()
      .required(t('Main File is required.'))
      .test(
        'file-invalid-type',
        t('Main File Format does not supported.'),
        (value) =>
          !value ||
          (value && acceptedType.some((ext) => value.name.includes(ext))),
      )
      .test(
        'file-too-large',
        t('Maximum Main File Size is 5MB.'),
        (value) => !value || (value && value.size <= maxSize),
      )
      .test(
        'file-last-modified',
        t('Unable to Add File in Previous Month.'),
        (value) =>
          !value ||
          isEdit ||
          (value &&
            moment(value.lastModifiedDate).format('M') ===
              moment().format('M') &&
            moment(value.lastModifiedDate).format('YYYY') ===
              moment().format('YYYY')),
      ),
    supportFile: Yup.mixed()
      .test(
        'file-invalid-type',
        t('Support File Format does not supported.'),
        (value) =>
          !value ||
          (value && acceptedType.some((ext) => value.name.includes(ext))),
      )
      .test(
        'file-too-large',
        t('Maximum Support File Size is 5MB.'),
        (value) => !value || (value && value.size <= maxSize),
      )
      .test(
        'file-last-modified',
        t('Unable to Add File in Previous Month.'),
        (value) =>
          !value ||
          isEdit ||
          (value &&
            moment(value.lastModifiedDate).format('M') ===
              moment().format('M') &&
            moment(value.lastModifiedDate).format('YYYY') ===
              moment().format('YYYY')),
      ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="work-report-form">
      <div className="work-report-form__form">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={workReportPICValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitWorkReport}>
          {({handleSubmit, values, isValid}): React.ReactElement => (
            <>
              <div className="work-report-form__section">
                <div className="work-report-form__section--field">
                  <div className="input-label">{t('Year')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="year"
                    inputValue={values.year}
                    placeholder={t('Choose Year')}
                    toolbarText={t('Choose Year')}
                    views={['year']}
                    format="YYYY"
                    maxDate={moment().endOf('year')}
                    component={MDatepickerInput}
                  />
                </div>
                <div className="work-report-form__section--field">
                  <div className="input-label">{t('Month')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="month"
                    inputValue={values.month}
                    placeholder={t('Choose Month')}
                    toolbarText={t('Choose Month')}
                    views={['month']}
                    format="MMMM"
                    component={MDatepickerInput}
                  />
                </div>
                <div className="work-report-form__section--field">
                  <div className="input-label">{t('Department')}</div>
                  <Field
                    disabled
                    className="user-input"
                    name="departmentName"
                    placeholder={t('Enter Department')}
                    component={MTextField}
                  />
                </div>
                <div className="work-report-form__section--field">
                  <div className="input-label">{t('Main File')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="mainFile"
                    accept={acceptedType}
                    component={MFileInput}
                  />
                </div>
                <div className="work-report-form__section--field">
                  <div className="input-label">{t('Support File')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="supportFile"
                    accept={acceptedType}
                    component={MFileInput}
                  />
                </div>
              </div>

              <div className="work-report-form__form--action">
                <div className="work-report-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="work-report-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={!isEdit ? t('Add') : t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default WorkReportForm;
