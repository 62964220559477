import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataRouteDeleteInitialState} from './masterDataRouteDelete.reducer';
import {
  MasterDataRouteDeleteAction,
  MasterDataRouteDeleteFailedPayload,
  MasterDataRouteDeleteFetchPayload,
  MasterDataRouteDeleteSuccessPayload,
} from './masterDataRouteDelete.type';

export const masterDataRouteDeleteHandler = {
  [MasterDataRouteDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteDeleteLoading: true,
    masterDataRouteDeleteParams: action.payload,
    action: MasterDataRouteDeleteAction.FETCH,
  }),
  [MasterDataRouteDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteDeleteParams: undefined,
    masterDataRouteDeleteLoading: false,
    masterDataRouteDeleteResponse: action.payload,
    action: MasterDataRouteDeleteAction.SUCCESS,
  }),
  [MasterDataRouteDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataRouteDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataRouteDeleteLoading: false,
    masterDataRouteDeleteError: action.payload,
    action: MasterDataRouteDeleteAction.FAILED,
  }),
  [MasterDataRouteDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataRouteDeleteInitialState,
    action: MasterDataRouteDeleteAction.RESET,
  }),
};
