import {CommonError} from '../../../../config/constant';

export const WorkReportDeleteAction = {
  FETCH: 'WORK_REPORT_DELETE_FETCH',
  SUCCESS: 'WORK_REPORT_DELETE_SUCCESS',
  FAILED: 'WORK_REPORT_DELETE_FAILED',
  RESET: 'WORK_REPORT_DELETE_RESET',
};

export interface WorkReportDeleteInitialState {
  workReportDeleteLoading: boolean;
  workReportDeleteParams?: WorkReportDeleteFetchPayload;
  workReportDeleteError?: WorkReportDeleteFailedPayload;
  workReportDeleteResponse?: WorkReportDeleteSuccessPayload;
}

export type WorkReportDeleteResponse = {
  status: string;
  info?: number;
  data: [];
};

export interface WorkReportDeleteFetchPayload {
  id: string;
}

export type WorkReportDeleteFailedPayload = CommonError;

export type WorkReportDeleteSuccessPayload = WorkReportDeleteResponse;
