import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {dashboardOverviewChartSectionAction} from './dashboardOverviewChartSection.action';
import {
  DashboardOverviewChartSectionAction,
  DashboardOverviewChartSectionFetchPayload,
  DashboardOverviewChartSectionResponse,
  DashboardOverviewChartSectionSuccessPayload,
} from './dashboardOverviewChartSection.type';

function* workerFetch(
  action: GenericAction<DashboardOverviewChartSectionFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<DashboardOverviewChartSectionSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/dashboard/achievement`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        dashboardOverviewChartSectionAction.success(
          res.data as DashboardOverviewChartSectionResponse,
        ),
      );
    } else {
      yield put(
        dashboardOverviewChartSectionAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(dashboardOverviewChartSectionAction.failed(e as CommonError));
  }
}

const dashboardOverviewChartSectionWatcher = [
  takeLatest(DashboardOverviewChartSectionAction.FETCH, workerFetch),
];

export default dashboardOverviewChartSectionWatcher;
