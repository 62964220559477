//#region IMPORT
// Libraries
import React from 'react';
// Utils
import t from '../../../../lang';
// Data
import {
  BackOfficePermissionType,
  RolePermission,
  RoleTableData,
} from '../../entity/user.string.entity';
// Components
import RoleTable from '../RoleTable';
//#endregion

//#region INTERFACE
interface Props {
  isDisabled?: boolean;
  roleValues?: BackOfficePermissionType;
  setFieldValue?: (
    field: string,
    value: RolePermission | boolean,
    shouldValidate?: boolean | undefined,
  ) => void;
}
//#endregion

const RoleTableBackOffice: React.FC<Props> = ({
  roleValues,
  isDisabled,
  setFieldValue,
}: Props) => {
  return (
    <>
      <RoleTable
        isDisabled={isDisabled}
        headerTitle={t('Chart')}
        title={t('Dashboard Overview')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={dashboardOverviewPermissions}
      />
      <RoleTable
        headerTitle={t('Chart')}
        isDisabled={isDisabled}
        valueType={'single'}
        title={t('Dashboard Internal SPM')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={dashboardInternalSPMPermissions}
      />
      <RoleTable
        headerTitle={t('Chart')}
        isDisabled={isDisabled}
        valueType={'single'}
        title={t('Dashboard Department')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={dashboardDepartmentPermissions}
      />
      <RoleTable
        headerTitle={t('Chart')}
        isDisabled={isDisabled}
        title={t('Dashboard Notes')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={dashboardNotesPermissions}
      />
      <RoleTable
        isDisabled={isDisabled}
        title={t('Follow Up')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={followUpPermissions}
      />
      <RoleTable
        isDisabled={isDisabled}
        title={t('Master Data')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={masterDataPermissions}
      />
      <RoleTable
        valueType={'single'}
        isDisabled={isDisabled}
        headerTitle={t('Notification')}
        title={t('Notification')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={notificationPermissions}
      />
      <RoleTable
        isDisabled={isDisabled}
        valueType={'download'}
        title={t('Lainnya')}
        roleValues={roleValues}
        setFieldValue={setFieldValue}
        permissionList={otherPermissions}
      />
    </>
  );
};

export default RoleTableBackOffice;

const dashboardOverviewPermissions: RoleTableData[] = [
  {
    label: t('Achievement Final Chart'),
    permissions: 'dashboardOverviewAchievementFinalChart',
  },
  {
    label: t('Achievement Quarterly Chart'),
    permissions: 'dashboardOverviewAchievementQuarterlyChart',
  },
  {
    label: t('Profit And Fine Chart'),
    permissions: 'dashboardOverviewProfitAndFineChart',
  },
  {
    label: t('Best Bus Stop Chart'),
    permissions: 'dashboardOverviewBestBusStopChart',
  },
  {
    label: t('Best Operator Chart'),
    permissions: 'dashboardOverviewBestOperatorChart',
  },
];

const dashboardInternalSPMPermissions: RoleTableData[] = [
  {
    label: t('Service Achievement Final Chart'),
    permissions: 'hasDashboardInternalServiceAchievementFinalChart',
  },
  {
    label: t('Gap Chart'),
    permissions: 'hasDashboardInternalGapChart',
  },
  {
    label: t('Weight Chart'),
    permissions: 'hasDashboardInternalWeightChart',
  },
  {
    label: t('Finding Chart'),
    permissions: 'hasDashboardInternalFindingChart',
  },
  {
    label: t('Rated Service Chart'),
    permissions: 'hasDashboardInternalRatedServiceChart',
  },
];

const dashboardDepartmentPermissions: RoleTableData[] = [
  {
    label: t('Department Achievement Chart'),
    permissions: 'hasDashboardPICAchivementChart',
  },
  {
    label: t('Department Weight Chart'),
    permissions: 'hasDashboardPICDifferenceChart',
  },
  {
    label: t('Profit And Fine Forecast Chart'),
    permissions: 'hasDashboardPICProfitAndFineChart',
  },
  {
    label: t('Department Finding Chart'),
    permissions: 'hasDashboardPICFindingChart',
  },
  {
    label: t('Best Department Chart'),
    permissions: 'hasDashboardPICBestDepartmentChart',
  },
];

const dashboardNotesPermissions: RoleTableData[] = [
  {
    label: t('Directorate Notes'),
    permissions: 'dashboardOverviewDirectorateNotes',
  },
  {
    label: t('Analytics Qualitative Notes'),
    permissions: 'dashboardOverviewAnalyticsQualitative',
  },
];

const followUpPermissions: RoleTableData[] = [
  {
    label: t('Tab In Progress'),
    permissions: 'followUpTabInProgress',
  },
  {
    label: t('Tab Waiting For Verification'),
    permissions: 'followUpTabWaitingForVerification',
  },
  {
    label: t('Tab Approved'),
    permissions: 'followUpTabApprove',
  },
  {
    label: t('Tab Rejected'),
    permissions: 'followUpTabRejected',
  },
  {
    label: t('Tab Expired'),
    permissions: 'followUpTabExpired',
  },
];

const masterDataPermissions: RoleTableData[] = [
  {
    label: t('Master Data Bus'),
    permissions: 'masterBusData',
  },
  {
    label: t('Master Data Route'),
    permissions: 'masterRouteData',
  },
  {
    label: t('Evaluation Form'),
    permissions: 'masterEvaluationForm',
  },
  {
    label: t('Assignment Letter'),
    permissions: 'masterWorkAssignment',
  },
  {
    label: t('Work Instruction'),
    permissions: 'masterWorkAssignmentInstruction',
  },
  {
    label: t('Organization List'),
    permissions: 'masterOrganization',
  },
];

const notificationPermissions: RoleTableData[] = [
  {
    label: t('Notification All Finding'),
    permissions: 'notificationAllFinding',
  },
  {
    label: t('Notification Finding Confirm Reminder'),
    permissions: 'notificationConfirmReminder',
  },
  {
    label: t('Notification Expired Finding'),
    permissions: 'notificationExpiredFinding',
  },
  {
    label: t('Notification Expired Notes from Chief'),
    permissions: 'notificationExpiredNotesFromChief',
  },
  {
    label: t('Notification Expired Notes from Directorate'),
    permissions: 'notificationExpiredNotesFromDirectorate',
  },
  {
    label: t('Notification Finding Per Department'),
    permissions: 'notificationFindingPerDepartment',
  },
  {
    label: t('Notification Rejected Follow Up'),
    permissions: 'notificationRejectedFollowUp',
  },
];

const otherPermissions: RoleTableData[] = [
  {
    label: t('Work Report'),
    permissions: 'otherWorkReport',
  },
  {
    label: t('Assignment Report'),
    permissions: 'otherAssignmentReport',
  },
  {
    label: t('Assignment Form'),
    permissions: 'otherAssignmentForm',
  },
];
