import {AxiosResponse} from 'axios';
import moment from 'moment';
import {call, put, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {post} from '../../../../services/http';
import {userDataAction} from '../userData/userData.action';
import {userLoginAction} from './userLogin.action';
import {
  UserLoginAction,
  UserLoginFetchPayload,
  UserLoginSuccessPayload,
} from './userLogin.type';

function* workerFetch(action: GenericAction<UserLoginFetchPayload>) {
  try {
    const res: AxiosResponse<UserLoginSuccessPayload> = yield call(
      post,
      `${Env.api}/open/login`,
      {...action.payload, isRemember: true},
    );
    if (res.status === 200) {
      const {data} = res.data;
      yield put(userLoginAction.success(res.data));
      yield put(
        userDataAction.fetch({
          accessToken: data?.accessToken || '',
          tokenType: 'Bearer',
          data: data,
          isLogin: true,
          isFirstLogin: data?.isFirstLogin,
          lastSuccessLogin: moment().toISOString(),
          userLevel: data?.userLevel,
        }),
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(userLoginAction.failed(e.response.data.payload));
  }
}

const userLoginWatcher = [takeLatest(UserLoginAction.FETCH, workerFetch)];

export default userLoginWatcher;
