import {AxiosResponse} from 'axios';
import moment from 'moment';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {
  downloadBlobResponse,
  generateFileName,
  generateHeaders,
} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {FILEEXT} from '../../../wrapper/entity';
import {evaluationReportExportAction} from './evaluationReportExport.action';
import {
  EvaluationReportExportAction,
  EvaluationReportExportFetchPayload,
  EvaluationReportExportResponse,
  EvaluationReportExportSuccessPayload,
} from './evaluationReportExport.type';

function* workerFetch(
  action: GenericAction<EvaluationReportExportFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {payload} = action;
  try {
    const res: AxiosResponse<EvaluationReportExportSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/review/export-review`,
      payload,
      {
        ...generateHeaders({
          authorization: {
            token: userData?.accessToken || '',
            tokenType: userData?.tokenType || '',
          },
        }),
        responseType: 'blob',
      },
    );
    if (res.status === 200 && res.data) {
      downloadBlobResponse(
        [(res.data as unknown) as Blob],
        generateFileName(
          `Laporan_penilaian_${
            payload.assignmentType ? `${payload.assignmentType}_` : ''
          }${payload.routeType ? `${payload.routeType}_` : ''} ${
            payload.startDate
          }_${payload.endDate}_${moment().unix()}`,
          FILEEXT.XLSX,
        ),
      );
      yield put(
        evaluationReportExportAction.success(({
          file: res.data,
        } as unknown) as EvaluationReportExportResponse),
      );
    } else {
      yield put(evaluationReportExportAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(evaluationReportExportAction.failed(e as CommonError));
  }
}

const evaluationReportExportWatcher = [
  takeLatest(EvaluationReportExportAction.FETCH, workerFetch),
];

export default evaluationReportExportWatcher;
