import {CommonError} from '../../../../../../config/constant';

export const MasterDataBusOptionListAction = {
  FETCH: 'MASTER_DATA_BUS_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_BUS_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_BUS_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_BUS_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_BUS_OPTION_LIST_UPDATE',
};

export interface MasterDataBusOptionListInitialState {
  masterDataBusOptionListLoading: boolean;
  masterDataBusOptionListData: Array<string>;
  masterDataBusOptionListError?: MasterDataBusOptionListFailedPayload;
  masterDataBusOptionListParams?: MasterDataBusOptionListFetchPayload;
  masterDataBusOptionListResponse?: MasterDataBusOptionListSuccessPayload;
}

export type MasterDataBusOptionListResponse = {
  status: string;
  count?: number;
  data: Array<string>;
};

export type MasterDataBusOptionListFailedPayload = CommonError;

export type MasterDataBusOptionListSuccessPayload = MasterDataBusOptionListResponse;

export type MasterDataBusOptionListUpdatePayload = MasterDataBusOptionListResponse;

export interface MasterDataBusOptionListFetchPayload {}
