import {CommonError} from '../../../../../../config/constant';
import {MasterDataObservationValueFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationValueEditAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_VALUE_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_VALUE_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_VALUE_EDIT_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_VALUE_EDIT_RESET',
};

export interface MasterDataObservationValueEditInitialState {
  masterDataObservationValueEditLoading: boolean;
  masterDataObservationValueEditParams?: MasterDataObservationValueEditFetchPayload;
  masterDataObservationValueEditError?: MasterDataObservationValueEditFailedPayload;
  masterDataObservationValueEditResponse?: MasterDataObservationValueEditSuccessPayload;
}

export type MasterDataObservationValueEditFetchPayload = MasterDataObservationValueFormData;

export type MasterDataObservationValueEditFailedPayload = CommonError;

export interface MasterDataObservationValueEditSuccessPayload {}
