import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailIndicatorDeleteAction,
  MasterDataDetailIndicatorDeleteFailedPayload,
  MasterDataDetailIndicatorDeleteFetchPayload,
  MasterDataDetailIndicatorDeleteSuccessPayload,
} from './masterDataDetailIndicatorDelete.type';

export const masterDataDetailIndicatorDeleteAction = {
  fetch: createAction<MasterDataDetailIndicatorDeleteFetchPayload>(
    MasterDataDetailIndicatorDeleteAction.FETCH,
  ),
  success: createAction<MasterDataDetailIndicatorDeleteSuccessPayload>(
    MasterDataDetailIndicatorDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailIndicatorDeleteFailedPayload>(
    MasterDataDetailIndicatorDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataDetailIndicatorDeleteAction.RESET),
};
