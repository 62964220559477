import {createAction} from '@reduxjs/toolkit';
import {
  WorkReportCreateAction,
  WorkReportCreateFailedPayload,
  WorkReportCreateFetchPayload,
  WorkReportCreateSuccessPayload,
} from './workReportCreate.type';

export const workReportCreateAction = {
  fetch: createAction<WorkReportCreateFetchPayload>(
    WorkReportCreateAction.FETCH,
  ),
  success: createAction<WorkReportCreateSuccessPayload>(
    WorkReportCreateAction.SUCCESS,
  ),
  failed: createAction<WorkReportCreateFailedPayload>(
    WorkReportCreateAction.FAILED,
  ),
  reset: createAction(WorkReportCreateAction.RESET),
};
