import {CommonError} from '../../../../../../config/constant';

export const MasterDataBasicServiceTypeDeleteAction = {
  FETCH: 'MASTER_DATA_BASIC_SERVICE_TYPE_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_BASIC_SERVICE_TYPE_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_BASIC_SERVICE_TYPE_DELETE_FAILED',
  RESET: 'MASTER_DATA_BASIC_SERVICE_TYPE_DELETE_RESET',
};

export interface MasterDataBasicServiceTypeDeleteInitialState {
  masterDataBasicServiceTypeDeleteLoading: boolean;
  masterDataBasicServiceTypeDeleteParams?: MasterDataBasicServiceTypeDeleteFetchPayload;
  masterDataBasicServiceTypeDeleteError?: MasterDataBasicServiceTypeDeleteFailedPayload;
  masterDataBasicServiceTypeDeleteResponse?: MasterDataBasicServiceTypeDeleteSuccessPayload;
}

export interface MasterDataBasicServiceTypeDeleteFetchPayload {
  id: string;
}
export type MasterDataBasicServiceTypeDeleteFailedPayload = CommonError;

export interface MasterDataBasicServiceTypeDeleteSuccessPayload {}
