import {
  NotificationReadAllAction,
  NotificationReadAllSuccessPayload,
  NotificationReadAllFailedPayload,
  NotificationReadAllFetchPayload,
} from './notificationReadAll.type';
import {NotificationState} from '../notification.reducer';
import {GenericAction} from '../../../../config/constant';
import {notificationReadAllInitialState} from './notificationReadAll.reducer';

export const notificationReadAllHandler = {
  [NotificationReadAllAction.FETCH]: (
    state: NotificationState,
    action: GenericAction<NotificationReadAllFetchPayload>,
  ): NotificationState => {
    return {
      ...state,
      notificationReadAllLoading: true,
      notificationReadAllParams: action.payload,
      action: NotificationReadAllAction.FETCH,
    };
  },
  [NotificationReadAllAction.SUCCESS]: (
    state: NotificationState,
    action: GenericAction<NotificationReadAllSuccessPayload>,
  ): NotificationState => ({
    ...state,
    notificationReadAllParams: undefined,
    notificationReadAllLoading: false,
    notificationReadAllResponse: action.payload,
    action: NotificationReadAllAction.SUCCESS,
  }),
  [NotificationReadAllAction.FAILED]: (
    state: NotificationState,
    action: GenericAction<NotificationReadAllFailedPayload>,
  ): NotificationState => ({
    ...state,
    notificationReadAllLoading: false,
    notificationReadAllError: action.payload,
    action: NotificationReadAllAction.FAILED,
  }),
  [NotificationReadAllAction.RESET]: (
    state: NotificationState,
  ): NotificationState => ({
    ...state,
    ...notificationReadAllInitialState,
    action: NotificationReadAllAction.RESET,
  }),
};
