import {createAction} from '@reduxjs/toolkit';
import {
  WorkReportListAction,
  WorkReportListFailedPayload,
  WorkReportListFetchPayload,
  WorkReportListSuccessPayload,
  WorkReportListUpdatePayload,
} from './workReportList.type';

export const workReportListAction = {
  fetch: createAction<WorkReportListFetchPayload>(WorkReportListAction.FETCH),
  success: createAction<WorkReportListSuccessPayload>(
    WorkReportListAction.SUCCESS,
  ),
  failed: createAction<WorkReportListFailedPayload>(
    WorkReportListAction.FAILED,
  ),
  update: createAction<WorkReportListUpdatePayload>(
    WorkReportListAction.UPDATE,
  ),
  reset: createAction(WorkReportListAction.RESET),
};
