import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDetailObservationCreateInitialState} from './masterDataDetailObservationCreate.reducer';
import {
  MasterDataDetailObservationCreateAction,
  MasterDataDetailObservationCreateFailedPayload,
  MasterDataDetailObservationCreateFetchPayload,
  MasterDataDetailObservationCreateSuccessPayload,
} from './masterDataDetailObservationCreate.type';

export const masterDataDetailObservationCreateHandler = {
  [MasterDataDetailObservationCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationCreateLoading: true,
    masterDataDetailObservationCreateParams: action.payload,
    action: MasterDataDetailObservationCreateAction.FETCH,
  }),
  [MasterDataDetailObservationCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationCreateParams: undefined,
    masterDataDetailObservationCreateLoading: false,
    masterDataDetailObservationCreateResponse: action.payload,
    action: MasterDataDetailObservationCreateAction.SUCCESS,
  }),
  [MasterDataDetailObservationCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationCreateLoading: false,
    masterDataDetailObservationCreateError: action.payload,
    action: MasterDataDetailObservationCreateAction.FAILED,
  }),
  [MasterDataDetailObservationCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailObservationCreateInitialState,
    action: MasterDataDetailObservationCreateAction.RESET,
  }),
};
