import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataIndicatorValueEditAction,
  MasterDataIndicatorValueEditFailedPayload,
  MasterDataIndicatorValueEditFetchPayload,
  MasterDataIndicatorValueEditSuccessPayload,
} from './masterDataIndicatorValueEdit.type';

export const masterDataIndicatorValueEditAction = {
  fetch: createAction<MasterDataIndicatorValueEditFetchPayload>(
    MasterDataIndicatorValueEditAction.FETCH,
  ),
  success: createAction<MasterDataIndicatorValueEditSuccessPayload>(
    MasterDataIndicatorValueEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataIndicatorValueEditFailedPayload>(
    MasterDataIndicatorValueEditAction.FAILED,
  ),
  reset: createAction(MasterDataIndicatorValueEditAction.RESET),
};
