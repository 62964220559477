import {LocationChangeAction, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Reducer,
  Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {PersistPartial} from 'redux-persist/es/persistReducer';
import {Persistor, persistReducer, persistStore} from 'redux-persist';
import logger from 'redux-logger';
import ReduxSaga from './ReduxSaga';
import {ReduxPersist, ReduxReducer} from '../config';
import {AppState} from '../config/Interface';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

export const history = createBrowserHistory();
const persistConfig = ReduxPersist.storeConfig;
const finalReducer: Reducer<
  AppState & PersistPartial,
  AnyAction & LocationChangeAction
> = persistReducer(persistConfig, combineReducers(ReduxReducer(history)));

const sagaMiddleware = createSagaMiddleware();
const middlewares =
  process.env.REACT_APP_ENV === 'production'
    ? [sagaMiddleware, routerMiddleware(history)]
    : [sagaMiddleware, logger, routerMiddleware(history)];

const store: Store = createStore(
  finalReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

sagaMiddleware.run(ReduxSaga);

const persistor: Persistor = persistStore(store);
export {persistor, store};
