import {createAction} from '@reduxjs/toolkit';
import {
  WorkReportEditAction,
  WorkReportEditFailedPayload,
  WorkReportEditFetchPayload,
  WorkReportEditSuccessPayload,
} from './workReportEdit.type';

export const workReportEditAction = {
  fetch: createAction<WorkReportEditFetchPayload>(WorkReportEditAction.FETCH),
  success: createAction<WorkReportEditSuccessPayload>(
    WorkReportEditAction.SUCCESS,
  ),
  failed: createAction<WorkReportEditFailedPayload>(
    WorkReportEditAction.FAILED,
  ),
  reset: createAction(WorkReportEditAction.RESET),
};
