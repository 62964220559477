//#region IMPORT
// Libraries
import React from 'react';
import {TableHead, TableRow, TableCell, TableSortLabel} from '@mui/material';
// Utils
import t from '../../../../../../../../lang';
import {SortTable} from '../../../../../../../../config/constant';
// Data
import {MasterDataAssignmentLetterOrderByType} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  sortBy: SortTable;
  orderBy: MasterDataAssignmentLetterOrderByType;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataAssignmentLetterOrderByType,
  ): void;
}
//#endregion

const MasterDataAssignmentLetterManagementListTableHeader: React.FC<Props> = ({
  sortBy,
  orderBy,
  onHandleSort,
}: Props) => {
  //#region SORT HANDLER
  const createSortHandler = (
    property: MasterDataAssignmentLetterOrderByType,
  ) => (event: React.MouseEvent<unknown>) => {
    onHandleSort(event, property);
  };
  //#endregion

  return (
    <TableHead className="master-assignment-letter-management-list-table-header">
      <TableRow className="master-assignment-letter-management-list-table-header__row">
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="table-header-label">
          {t('No')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'number' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'number'}
            direction={orderBy === 'number' ? sortBy : 'asc'}
            onClick={createSortHandler('number')}>
            {t('Letter Number')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'publishDate' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'publishDate'}
            direction={orderBy === 'publishDate' ? sortBy : 'asc'}
            onClick={createSortHandler('publishDate')}>
            {t('Publish Date')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'roleName' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'roleName'}
            direction={orderBy === 'roleName' ? sortBy : 'asc'}
            onClick={createSortHandler('roleName')}>
            {t('User')}
          </TableSortLabel>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          className="table-header-label"
          sortDirection={orderBy === 'isActive' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'isActive'}
            direction={orderBy === 'isActive' ? sortBy : 'asc'}
            onClick={createSortHandler('isActive')}>
            {t('Status')}
          </TableSortLabel>
        </TableCell>

        <TableCell
          className="master-assignment-letter-management-list-table-header__row--action table-header-label"
          align="right"
          padding="none"
        />
      </TableRow>
    </TableHead>
  );
};

export default MasterDataAssignmentLetterManagementListTableHeader;
