import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDepartmentListAction,
  MasterDataDepartmentListFailedPayload,
  MasterDataDepartmentListFetchPayload,
  MasterDataDepartmentListSuccessPayload,
  MasterDataDepartmentListUpdatePayload,
} from './masterDataDepartmentList.type';

export const masterDataDepartmentListAction = {
  fetch: createAction<MasterDataDepartmentListFetchPayload>(
    MasterDataDepartmentListAction.FETCH,
  ),
  success: createAction<MasterDataDepartmentListSuccessPayload>(
    MasterDataDepartmentListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDepartmentListFailedPayload>(
    MasterDataDepartmentListAction.FAILED,
  ),
  update: createAction<MasterDataDepartmentListUpdatePayload>(
    MasterDataDepartmentListAction.UPDATE,
  ),
  reset: createAction(MasterDataDepartmentListAction.RESET),
};
