//#region IMPORT

import React, {ReactElement} from 'react';
import {Modal} from '..';
import {IconError, IconSuccess} from '../../assets/icons';

import './MNotificationDialog.scss';
//#endregion

interface Props {
  isOpen: boolean;
  type: 'success' | 'warning' | 'error' | 'info';
  text: string;
  onClose(): void;
}

const MNotificationDialog: React.FC<Props> = ({
  isOpen,
  type,
  text,
  onClose,
}: Props) => {
  const getNotificationDialogIcon = (
    type: 'success' | 'warning' | 'error' | 'info',
  ): React.ReactElement | undefined => {
    const icon: {[key: string]: ReactElement | undefined} = {
      ['success']: <IconSuccess />,
      ['error']: <IconError />,
      DEFAULT: undefined,
    };
    return icon[type] || icon.DEFAULT;
  };
  const notificationDialogIcon = getNotificationDialogIcon(type);

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className="m-notification-dialog">
        {notificationDialogIcon && (
          <div className="m-notification-dialog__image">
            {notificationDialogIcon}
          </div>
        )}
        <div>
          <h6 className="m-notification-dialog__description">{text}</h6>
        </div>
      </div>
    </Modal>
  );
};

export default MNotificationDialog;
