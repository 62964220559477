//#region IMPORT
//IMPORT MODULE
import React from 'react';
import {useLocation} from 'react-router';
//IMPORT CONFIG
import t from '../../../../lang';
import {PATH} from '../../entity';
import SidebarItem from '../SidebarItem';
import './Sidebar.component.style.scss';
import SidebarGroup from '../SidebarGroup';
import {List, Drawer, Box, Toolbar} from '@mui/material';
import {
  IconDashboard,
  IconMasterData,
  IconUserAccess,
  IconFollowUp,
  IconWorkReport,
  IconEvaluationReport,
} from '../../../../assets/icons';
import {shallowEqual, useSelector} from 'react-redux';
import {AppState} from '../../../../config/Interface';
import {BackOfficePermissionType} from '../../../user/entity/user.string.entity';

//#endregion
const drawerWidth = '27rem';
interface Props {
  isDrawer?: boolean;
  onClose?(): void;
}
const Sidebar: React.FC<Props> = ({isDrawer, onClose}: Props) => {
  //#region GENERAL
  const {pathname} = useLocation();
  const {data, userLevel} = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const permissions: BackOfficePermissionType | undefined =
    data?.role?.permissions;
  const userLevelData: number = userLevel
    ? Number(userLevel?.replace('USERLEVEL', ''))
    : 8;
  //#endregion

  if (pathname === PATH.LAPORAN_PENILAIAN_DETAIL) return null;
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden',
        },
      }}>
      <Toolbar />
      <Box
        sx={{overflow: 'auto', marginTop: '1.6rem'}}
        className={`sidebar${isDrawer ? '-drawer' : ''}`}>
        <List component="nav" className="sidebar-list">
          <SidebarGroup>
            <>
              <SidebarItem
                includedPath={[PATH.HOME]}
                onClose={onClose}
                icon={<IconDashboard />}
                path={PATH.HOME}
                title={t('Dashboard')}
              />

              {!!permissions?.otherAssignmentReport && (
                <SidebarItem
                  onClose={onClose}
                  icon={<IconEvaluationReport />}
                  path={PATH.LAPORAN_PENILAIAN}
                  title={t('Evaluation Report')}
                  includedPath={[PATH.LAPORAN_PENILAIAN]}
                />
              )}

              {userLevelData > 0 && !!permissions?.otherWorkReport && (
                <SidebarItem
                  onClose={onClose}
                  icon={<IconWorkReport />}
                  path={PATH.LAPORAN_KERJA}
                  title={t('Work Report')}
                  includedPath={[PATH.LAPORAN_KERJA]}
                />
              )}
              {userLevelData > 0 &&
                (!!permissions?.followUpTabApprove ||
                  !!permissions?.followUpTabExpired ||
                  !!permissions?.followUpTabInProgress ||
                  !!permissions?.followUpTabRejected ||
                  !!permissions?.followUpTabWaitingForVerification) && (
                  <SidebarItem
                    onClose={onClose}
                    icon={<IconFollowUp />}
                    path={PATH.TINDAK_LANJUT}
                    title={t('Follow Up')}
                    includedPath={[PATH.TINDAK_LANJUT]}
                  />
                )}
              {(!!permissions?.masterBusData ||
                !!permissions?.masterRouteData ||
                !!permissions?.masterEvaluationForm ||
                !!permissions?.masterWorkAssignment ||
                !!permissions?.masterWorkAssignmentInstruction) && (
                <SidebarItem
                  onClose={onClose}
                  icon={<IconMasterData />}
                  path={PATH.MASTER_DATA}
                  title={t('Master Data')}
                  includedPath={[
                    PATH.MASTER_DATA,
                    PATH.MASTER_DATA_OFFICE,
                    PATH.MASTER_DATA_FORM_ASSESSMENT,
                    PATH.MASTER_DATA_OPERATIONAL,
                    PATH.MASTER_DATA_OFFICE_WORK_INSTRUCTION,
                    PATH.MASTER_DATA_OFFICE_ASSIGNMENT_LETTER,
                    PATH.MASTER_DATA_OPERATIONAL_BUS,
                    PATH.MASTER_DATA_OPERATIONAL_RUTE,
                  ]}
                />
              )}
              {data?.role?.userAccessManagement && (
                <SidebarItem
                  onClose={onClose}
                  includedPath={[
                    PATH.USER_ACCESS,
                    PATH.USER_ACCESS_EDIT_ROLE,
                    PATH.USER_ACCESS_CREATE_ROLE,
                    PATH.USER_ACCESS_DETAIL_ROLE,
                  ]}
                  icon={<IconUserAccess />}
                  path={PATH.USER_ACCESS}
                  title={t('User Access')}
                />
              )}
            </>
          </SidebarGroup>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
