//#region IMPORT
// Libraries
import React from 'react';
import {TableHead, TableRow, TableCell, TableSortLabel} from '@mui/material';
// Utils
import t from '../../../../../../../../lang';
import {SortTable} from '../../../../../../../../config/constant';
// Data
import {MasterDataDetailObservationOrderByType} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  sortBy: SortTable;
  orderBy: MasterDataDetailObservationOrderByType;
  onHandleSort(
    event: React.MouseEvent<unknown>,
    property: MasterDataDetailObservationOrderByType,
  ): void;
}
//#endregion

const DetailObservationManagementListTableHeader: React.FC<Props> = ({
  sortBy,
  orderBy,
  onHandleSort,
}: Props) => {
  //#region SORT HANDLER
  const createSortHandler = (
    property: MasterDataDetailObservationOrderByType,
  ) => (event: React.MouseEvent<unknown>) => {
    onHandleSort(event, property);
  };
  //#endregion

  return (
    <TableHead className="detail-observation-management-list-table-header">
      <TableRow className="detail-observation-management-list-table-header__row">
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="table-header-label">
          {t('No')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="45%"
          className="table-header-label"
          sortDirection={orderBy === 'name' ? sortBy : false}>
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? sortBy : 'asc'}
            onClick={createSortHandler('name')}>
            {t('Detail Observation')}
          </TableSortLabel>
        </TableCell>

        <TableCell
          className="detail-observation-management-list-table-header__row--action table-header-label"
          align="right"
          padding="none"
        />
      </TableRow>
    </TableHead>
  );
};

export default DetailObservationManagementListTableHeader;
