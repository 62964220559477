import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataAssignmentLetterSetActiveAction,
  MasterDataAssignmentLetterSetActiveFailedPayload,
  MasterDataAssignmentLetterSetActiveFetchPayload,
  MasterDataAssignmentLetterSetActiveSuccessPayload,
} from './masterDataAssignmentLetterSetActive.type';

export const masterDataAssignmentLetterSetActiveAction = {
  fetch: createAction<MasterDataAssignmentLetterSetActiveFetchPayload>(
    MasterDataAssignmentLetterSetActiveAction.FETCH,
  ),
  success: createAction<MasterDataAssignmentLetterSetActiveSuccessPayload>(
    MasterDataAssignmentLetterSetActiveAction.SUCCESS,
  ),
  failed: createAction<MasterDataAssignmentLetterSetActiveFailedPayload>(
    MasterDataAssignmentLetterSetActiveAction.FAILED,
  ),
  reset: createAction(MasterDataAssignmentLetterSetActiveAction.RESET),
};
