import {CommonError} from '../../../../../../config/constant';
import {MasterDataObservationFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationCreateAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_CREATE_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_CREATE_RESET',
};

export interface MasterDataObservationCreateInitialState {
  masterDataObservationCreateLoading: boolean;
  masterDataObservationCreateParams?: MasterDataObservationCreateFetchPayload;
  masterDataObservationCreateError?: MasterDataObservationCreateFailedPayload;
  masterDataObservationCreateResponse?: MasterDataObservationCreateSuccessPayload;
}

export type MasterDataObservationCreateFetchPayload = Omit<
  MasterDataObservationFormData,
  'id'
>[];

export type MasterDataObservationCreateFailedPayload = CommonError;

export interface MasterDataObservationCreateSuccessPayload {}
