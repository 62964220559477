import {
  WorkReportListAction,
  WorkReportListSuccessPayload,
  WorkReportListFailedPayload,
  WorkReportListFetchPayload,
} from './workReportList.type';
import {WorkReportState} from '../workReport.reducer';
import {GenericAction} from '../../../../config/constant';
import {workReportListInitialState} from './workReportList.reducer';

export const workReportListHandler = {
  [WorkReportListAction.FETCH]: (
    state: WorkReportState,
    action: GenericAction<WorkReportListFetchPayload>,
  ): WorkReportState => {
    return {
      ...state,
      workReportListLoading: true,
      workReportListParams: action.payload,
      action: WorkReportListAction.FETCH,
    };
  },
  [WorkReportListAction.SUCCESS]: (
    state: WorkReportState,
    action: GenericAction<WorkReportListSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportListLoading: false,
    workReportListResponse: action.payload,
    action: WorkReportListAction.SUCCESS,
  }),
  [WorkReportListAction.UPDATE]: (
    state: WorkReportState,
    action: GenericAction<WorkReportListSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportListLoading: false,
    workReportListData: [
      ...state.workReportListData,
      ...action.payload.data.content,
    ],
    action: WorkReportListAction.UPDATE,
  }),
  [WorkReportListAction.FAILED]: (
    state: WorkReportState,
    action: GenericAction<WorkReportListFailedPayload>,
  ): WorkReportState => ({
    ...state,
    workReportListLoading: false,
    workReportListError: action.payload,
    action: WorkReportListAction.FAILED,
  }),
  [WorkReportListAction.RESET]: (state: WorkReportState): WorkReportState => ({
    ...state,
    ...workReportListInitialState,
    action: WorkReportListAction.RESET,
  }),
};
