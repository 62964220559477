import {createAction} from '@reduxjs/toolkit';
import {
  UserRoleAccessEditAction,
  UserRoleAccessEditFailedPayload,
  UserRoleAccessEditFetchPayload,
  UserRoleAccessEditSuccessPayload,
} from './userRoleAccessEdit.type';

export const userRoleAccessEditAction = {
  fetch: createAction<UserRoleAccessEditFetchPayload>(
    UserRoleAccessEditAction.FETCH,
  ),
  success: createAction<UserRoleAccessEditSuccessPayload>(
    UserRoleAccessEditAction.SUCCESS,
  ),
  failed: createAction<UserRoleAccessEditFailedPayload>(
    UserRoleAccessEditAction.FAILED,
  ),
  reset: createAction(UserRoleAccessEditAction.RESET),
};
