import {CommonError} from '../../../../../../config/constant';
import {
  DashboardOverviewExportExcel,
  DASHBOARDQUARTERINTERVAL,
} from '../../../../entity/dashboard.string.entity';

export const DashboardOverviewExportAchievementFinalQuarterAction = {
  FETCH: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_QUARTER_FETCH',
  SUCCESS: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_QUARTER_SUCCESS',
  FAILED: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_QUARTER_FAILED',
  RESET: 'DASHBOARD_OVERVIEW_EXPORT_ACHIEVEMENT_FINAL_QUARTER_RESET',
};
export interface DashboardOverviewExportAchievementFinalQuarterInitialState {
  dashboardOverviewExportAchievementFinalQuarterLoading: boolean;
  dashboardOverviewExportAchievementFinalQuarterError?: DashboardOverviewExportAchievementFinalQuarterFailedPayload;
  dashboardOverviewExportAchievementFinalQuarterResponse?: DashboardOverviewExportAchievementFinalQuarterSuccessPayload;
  dashboardOverviewExportAchievementFinalQuarterParams?: DashboardOverviewExportAchievementFinalQuarterFetchPayload;
}

export interface DashboardOverviewExportAchievementFinalQuarterFetchPayload {
  quarter: DASHBOARDQUARTERINTERVAL;
  year: number;
}

export interface DashboardOverviewExportAchievementFinalQuarterResponse {
  status: string;
  info?: number;
  data: DashboardOverviewExportExcel;
}

export type DashboardOverviewExportAchievementFinalQuarterFailedPayload = CommonError;

export type DashboardOverviewExportAchievementFinalQuarterSuccessPayload = DashboardOverviewExportAchievementFinalQuarterResponse;

export type DashboardOverviewExportAchievementFinalQuarterUpdatePayload = DashboardOverviewExportAchievementFinalQuarterResponse;
