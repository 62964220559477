//#region IMPORT
import {ListItem, ListItemIcon} from '@mui/material';
import Icon from '@mdi/react';
import React from 'react';
import {useHistory, useLocation} from 'react-router';

import './SidebarItem.component.style.scss';
//#endregion

interface Props {
  icon: string | React.ReactElement;
  title: string;
  path: string;
  includedPath: string[];
  onClose?(): void;
}

const SidebarItem: React.FC<Props> = ({
  icon,
  title,
  path,
  includedPath,
  onClose,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const isSelected = includedPath.includes(location.pathname);
  const renderIcon = (icon: string | React.ReactElement) => {
    if (typeof icon === 'string') return <Icon path={icon} />;
    return icon;
  };
  return (
    <ListItem
      selected={isSelected}
      button
      className="sidebar-item"
      onClick={() => {
        history.push(path);
        onClose && onClose();
      }}>
      <ListItemIcon
        className={`sidebar-item__icon${isSelected ? '--selected' : ''}`}>
        {renderIcon(icon)}
      </ListItemIcon>
      <div
        className={`sidebar-item__title${
          isSelected ? '--selected' : ''
        } body1`}>
        {title}
      </div>
    </ListItem>
  );
};
export default SidebarItem;
