import {CommonError} from '../../../../../../config/constant';

export const MasterDataFormControllerMoveAction = {
  FETCH: 'MASTER_DATA_FORM_CONTROLLER_MOVE_FETCH',
  SUCCESS: 'MASTER_DATA_FORM_CONTROLLER_MOVE_SUCCESS',
  FAILED: 'MASTER_DATA_FORM_CONTROLLER_MOVE_FAILED',
  RESET: 'MASTER_DATA_FORM_CONTROLLER_MOVE_RESET',
};

export interface MasterDataFormControllerMoveInitialState {
  masterDataFormControllerMoveLoading: boolean;
  masterDataFormControllerMoveParams?: MasterDataFormControllerMoveFetchPayload;
  masterDataFormControllerMoveError?: MasterDataFormControllerMoveFailedPayload;
  masterDataFormControllerMoveResponse?: MasterDataFormControllerMoveSuccessPayload;
}

export interface MasterDataFormControllerMoveFetchPayload {
  id: string;
  isUp?: boolean;
  isDown?: boolean;
}
export type MasterDataFormControllerMoveFailedPayload = CommonError;

export interface MasterDataFormControllerMoveSuccessPayload {}
