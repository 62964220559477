import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {httpPut} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {followUpPICConfirmAction} from './followUpPICConfirm.action';
import {
  FollowUpPICConfirmAction,
  FollowUpPICConfirmFetchPayload,
  FollowUpPICConfirmSuccessPayload,
} from './followUpPICConfirm.type';

function* workerFetch(action: GenericAction<FollowUpPICConfirmFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id} = action.payload;
  try {
    const res: AxiosResponse<FollowUpPICConfirmSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/follow-up/confirm/${id}`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(followUpPICConfirmAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(followUpPICConfirmAction.failed(e.response.data.payload));
  }
}

const followUpPICConfirmWatcher = [
  takeLatest(FollowUpPICConfirmAction.FETCH, workerFetch),
];

export default followUpPICConfirmWatcher;
