//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
import {MasterDataObservationCreateFetchPayload} from '../../../../usecases/masterData.type';
// Utils
import {masterDataObservationCreateAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataObservationFormCreate} from './components';
import {ObservationFormCreateInitialValues} from './components/MasterDataObservationFormCreate/MasterDataObservationFormCreate.component';
// Assets
import './MasterDataObservationFormCreateContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataObservationFormCreateContainer: React.FC<Props> = ({
  assignmentType,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: ObservationFormCreateInitialValues = {
    observation: [{name: ''}],
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitObservation = (
    formValue: ObservationFormCreateInitialValues,
  ) => {
    const formattedValue: MasterDataObservationCreateFetchPayload =
      formValue?.observation.map((item) => ({
        name: item.name,
        assignmentType,
      })) || [];
    dispatch(masterDataObservationCreateAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-observation-form-create-container">
      <MasterDataObservationFormCreate
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitObservation={handleSubmitObservation}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataObservationFormCreateContainer;
