import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../user.reducer';
import {userAccountDetailAction} from './userAccountDetail.action';
import {userDataAction} from '../userData/userData.action';
import {
  UserAccountDetailAction,
  UserAccountDetailFetchPayload,
  UserAccountDetailResponse,
  UserAccountDetailSuccessPayload,
} from './userAccountDetail.type';
import {UserData} from '../../entity/user.string.entity';

function* workerFetch(action: GenericAction<UserAccountDetailFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {userId} = action.payload;
  try {
    const res: AxiosResponse<UserAccountDetailSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/user/find-by-id/${userId}`,
      undefined,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.data) {
      const {data} = res.data;
      yield put(
        userAccountDetailAction.success(res.data as UserAccountDetailResponse),
      );
      yield put(
        userDataAction.fetch({
          accessToken: userData?.accessToken ?? '',
          tokenType: userData?.tokenType,
          data: {
            ...userData?.data,
            name: data?.name,
            phoneNumber: data?.phoneNumber,
            profilePicture: data?.profilePicture,
          } as UserData,
          isLogin: userData?.isLogin,
          isFirstLogin: userData?.isFirstLogin,
          lastSuccessLogin: userData?.lastSuccessLogin,
          userLevel: userData?.userLevel,
        }),
      );
    } else {
      yield put(userAccountDetailAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(userAccountDetailAction.failed(e as CommonError));
  }
}

const userAccountDetailWatcher = [
  takeLatest(UserAccountDetailAction.FETCH, workerFetch),
];

export default userAccountDetailWatcher;
