import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataFormControllerDeleteInitialState} from './masterDataFormControllerDelete.reducer';
import {
  MasterDataFormControllerDeleteAction,
  MasterDataFormControllerDeleteFailedPayload,
  MasterDataFormControllerDeleteFetchPayload,
  MasterDataFormControllerDeleteSuccessPayload,
} from './masterDataFormControllerDelete.type';

export const masterDataFormControllerDeleteHandler = {
  [MasterDataFormControllerDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerDeleteLoading: true,
    masterDataFormControllerDeleteParams: action.payload,
    action: MasterDataFormControllerDeleteAction.FETCH,
  }),
  [MasterDataFormControllerDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerDeleteParams: undefined,
    masterDataFormControllerDeleteLoading: false,
    masterDataFormControllerDeleteResponse: action.payload,
    action: MasterDataFormControllerDeleteAction.SUCCESS,
  }),
  [MasterDataFormControllerDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataFormControllerDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataFormControllerDeleteLoading: false,
    masterDataFormControllerDeleteError: action.payload,
    action: MasterDataFormControllerDeleteAction.FAILED,
  }),
  [MasterDataFormControllerDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataFormControllerDeleteInitialState,
    action: MasterDataFormControllerDeleteAction.RESET,
  }),
};
