import {GenericAction} from '../../../../config/constant';
import {WorkReportState} from '../workReport.reducer';
import {workReportEditInitialState} from './workReportEdit.reducer';
import {
  WorkReportEditAction,
  WorkReportEditFailedPayload,
  WorkReportEditFetchPayload,
  WorkReportEditSuccessPayload,
} from './workReportEdit.type';

export const workReportEditHandler = {
  [WorkReportEditAction.FETCH]: (
    state: WorkReportState,
    action: GenericAction<WorkReportEditFetchPayload>,
  ): WorkReportState => ({
    ...state,
    workReportEditLoading: true,
    workReportEditParams: action.payload,
    action: WorkReportEditAction.FETCH,
  }),
  [WorkReportEditAction.SUCCESS]: (
    state: WorkReportState,
    action: GenericAction<WorkReportEditSuccessPayload>,
  ): WorkReportState => ({
    ...state,
    workReportEditParams: undefined,
    workReportEditLoading: false,
    workReportEditResponse: action.payload,
    action: WorkReportEditAction.SUCCESS,
  }),
  [WorkReportEditAction.FAILED]: (
    state: WorkReportState,
    action: GenericAction<WorkReportEditFailedPayload>,
  ): WorkReportState => ({
    ...state,
    workReportEditLoading: false,
    workReportEditError: action.payload,
    action: WorkReportEditAction.FAILED,
  }),
  [WorkReportEditAction.RESET]: (state: WorkReportState): WorkReportState => ({
    ...state,
    ...workReportEditInitialState,
    action: WorkReportEditAction.RESET,
  }),
};
