//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {userAccessCreateAction} from '../../usecases/userAccessCreate/userAccessCreate.action';
import {userAccessEditAction} from '../../usecases/userAccessEdit/userAccessEdit.action';
// Components
import UserForm from './components/UserForm';
// Data
import {
  User,
  UserAccessFormData,
  UserLevel,
  UserType,
} from '../../entity/user.string.entity';
// Assets
import './UserAccessManagementUserFormContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: User;
  typeUser?: UserType;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const UserAccessManagementUserFormContainer: React.FC<Props> = ({
  data,
  typeUser,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: UserAccessFormData = {
    id: data?.id || '',
    nik: data?.nik || '',
    name: data?.name || '',
    directorateId: data?.directorateId || '',
    divisionId: data?.divisionId || '',
    departmentId: data?.departmentId || '',
    email: data?.email || '',
    roleId: data?.role?.id || '',
    type: data?.type || typeUser || UserType.INTERNAL,
    userLevel: data?.role?.userLevel
      ? +data.role.userLevel.replace('USERLEVEL', '')
      : +UserLevel.PETUGAS.replace('USERLEVEL', ''),
  };
  //#endregion

  return (
    <div className="user-access-management-user-form-container">
      <UserForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitUser={(_user) => {
          if (_user.id) {
            dispatch(userAccessEditAction.fetch(_user));
          } else {
            dispatch(userAccessCreateAction.fetch(_user));
          }
        }}
        handleCancel={onCancel}
      />
    </div>
  );
};

export default UserAccessManagementUserFormContainer;
