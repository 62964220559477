import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDetailObservationEditInitialState} from './masterDataDetailObservationEdit.reducer';
import {
  MasterDataDetailObservationEditAction,
  MasterDataDetailObservationEditFailedPayload,
  MasterDataDetailObservationEditFetchPayload,
  MasterDataDetailObservationEditSuccessPayload,
} from './masterDataDetailObservationEdit.type';

export const masterDataDetailObservationEditHandler = {
  [MasterDataDetailObservationEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationEditLoading: true,
    masterDataDetailObservationEditParams: action.payload,
    action: MasterDataDetailObservationEditAction.FETCH,
  }),
  [MasterDataDetailObservationEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationEditParams: undefined,
    masterDataDetailObservationEditLoading: false,
    masterDataDetailObservationEditResponse: action.payload,
    action: MasterDataDetailObservationEditAction.SUCCESS,
  }),
  [MasterDataDetailObservationEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDetailObservationEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDetailObservationEditLoading: false,
    masterDataDetailObservationEditError: action.payload,
    action: MasterDataDetailObservationEditAction.FAILED,
  }),
  [MasterDataDetailObservationEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDetailObservationEditInitialState,
    action: MasterDataDetailObservationEditAction.RESET,
  }),
};
