import {CommonError} from '../../../../../../config/constant';
import {MasterDataCategoryFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataCategoryEditAction = {
  FETCH: 'MASTER_DATA_CATEGORY_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_CATEGORY_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_CATEGORY_EDIT_FAILED',
  RESET: 'MASTER_DATA_CATEGORY_EDIT_RESET',
};

export interface MasterDataCategoryEditInitialState {
  masterDataCategoryEditLoading: boolean;
  masterDataCategoryEditParams?: MasterDataCategoryEditFetchPayload;
  masterDataCategoryEditError?: MasterDataCategoryEditFailedPayload;
  masterDataCategoryEditResponse?: MasterDataCategoryEditSuccessPayload;
}

export type MasterDataCategoryEditFetchPayload = MasterDataCategoryFormData;

export type MasterDataCategoryEditFailedPayload = CommonError;

export interface MasterDataCategoryEditSuccessPayload {}
