import {
  UserAccessListAction,
  UserAccessListSuccessPayload,
  UserAccessListFailedPayload,
  UserAccessListFetchPayload,
} from './userAccessList.type';
import {UserState} from '../user.reducer';
import {GenericAction} from '../../../../config/constant';
import {userAccessListInitialState} from './userAccessList.reducer';

export const userAccessListHandler = {
  [UserAccessListAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserAccessListFetchPayload>,
  ): UserState => {
    return {
      ...state,
      userAccessListLoading: true,
      userAccessListParams: action.payload,
      action: UserAccessListAction.FETCH,
    };
  },
  [UserAccessListAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserAccessListSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccessListLoading: false,
    userAccessListResponse: action.payload,
    action: UserAccessListAction.SUCCESS,
  }),
  [UserAccessListAction.UPDATE]: (
    state: UserState,
    action: GenericAction<UserAccessListSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccessListLoading: false,
    userAccessListData: [
      ...state.userAccessListData,
      ...action.payload.data.content,
    ],
    action: UserAccessListAction.UPDATE,
  }),
  [UserAccessListAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserAccessListFailedPayload>,
  ): UserState => ({
    ...state,
    userAccessListLoading: false,
    userAccessListError: action.payload,
    action: UserAccessListAction.FAILED,
  }),
  [UserAccessListAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userAccessListInitialState,
    action: UserAccessListAction.RESET,
  }),
};
