//#region IMPORT
// Libraries
import {Grid} from '@mui/material';
import React from 'react';
// Utils
import {getAlphabeticNum} from '../../../../utils';
// Components
import {EvaluationReportCardContent} from '..';
// Data
import {
  EvaluationDetailObservation,
  EvaluationReportFindings,
  EvaluationReportPreviewFindings,
} from '../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportObservation.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  reviewIndex: number;
  observationData?: EvaluationReportFindings;
  handlePreview(_previewFindings: EvaluationReportPreviewFindings): void;
}
//#endregion

const EvaluationReportObservation: React.FC<Props> = ({
  reviewIndex,
  observationData,
  handlePreview,
}: Props) => {
  //#region SINGLE OBSERVATION
  const singleObservation = () => {
    const singleObservationContent: EvaluationReportPreviewFindings = {
      name: observationData?.observationName ?? '',
      value:
        (observationData?.multipleObservationValue &&
        !!observationData?.multipleObservationValue.length
          ? observationData?.multipleObservationValue
          : observationData?.observationValue) ?? '',
      image: observationData?.observationImg ?? '',
    };

    return (
      <Grid
        item
        xs={6}
        key={`evaluation-report-observation__detail-${observationData?.observationId}-${reviewIndex}`}
        onClick={(e) => {
          e.stopPropagation();
          !!singleObservationContent.image &&
            handlePreview(singleObservationContent);
        }}>
        <div
          className={`evaluation-report-observation__card${
            singleObservationContent.image ? 's' : ''
          }`}>
          <EvaluationReportCardContent contentData={singleObservationContent} />
        </div>
      </Grid>
    );
  };
  //#endregion

  //#region MULTI OBSERVATION
  const multiObservation = (
    multiObservation: EvaluationDetailObservation[],
  ) => {
    return (
      <div className="evaluation-report-observation__multi">
        <div className="evaluation-report-observation__multi-title">
          {observationData?.observationName ?? ''}
        </div>
        <Grid container spacing={3}>
          {multiObservation.map(
            (
              multiObservationItem: EvaluationDetailObservation,
              multiObservationIdx: number,
            ) => {
              const detailObservationContent: EvaluationReportPreviewFindings = {
                name: `${getAlphabeticNum(multiObservationIdx + 1)}. ${
                  multiObservationItem.detailObservationName
                }`,
                value:
                  multiObservationItem.multipleObservationValue &&
                  !!multiObservationItem.multipleObservationValue.length
                    ? multiObservationItem.multipleObservationValue
                    : multiObservationItem.observationValue,
                image: multiObservationItem.observationImg,
              };
              return (
                <Grid
                  item
                  xs={6}
                  key={`evaluation-report-observation__detail-${observationData?.observationId}-${multiObservationIdx}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    !!detailObservationContent.image &&
                      handlePreview(detailObservationContent);
                  }}>
                  <div
                    className={`evaluation-report-observation__card${
                      detailObservationContent.image ? 's' : ''
                    }`}>
                    <EvaluationReportCardContent
                      contentData={detailObservationContent}
                    />
                  </div>
                </Grid>
              );
            },
          )}
        </Grid>
      </div>
    );
  };
  //#endregion

  //#region OBSERVATION RESULT
  const observationResult = () => {
    const detailObservations: EvaluationDetailObservation[] =
      observationData?.detailObservations || [];

    if (detailObservations && !!detailObservations?.length) {
      return multiObservation(detailObservations);
    }
    return singleObservation();
  };
  //#endregion

  return (
    <div className="evaluation-report-observation">{observationResult()}</div>
  );
};
export default EvaluationReportObservation;
