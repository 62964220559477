/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  MasterDataDetailObservationCreateAction,
  MasterDataDetailObservationDeleteAction,
  MasterDataDetailObservationEditAction,
  MasterDataDetailObservationListAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {
  masterDataDetailObservationCreateAction,
  masterDataDetailObservationDeleteAction,
  masterDataDetailObservationEditAction,
  masterDataDetailObservationListAction,
} from '../../../../usecases/masterData.action';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import MasterDataDetailObservationFormCreateContainer from '../MasterDataDetailObservationFormCreateContainer';
import MasterDataDetailObservationFormEditContainer from '../MasterDataDetailObservationFormEditContainer';
import {DetailObservationManagementList} from './components';
// Data
import {
  MasterDataDetailObservation,
  MasterDataDetailObservationOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
import './MasterDataDetailObservationSection.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataDetailObservationSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddDetailObservation, setAddDetailObservation] = useState(false);
  const [isDeleteDetailObservation, setIsDeleteDetailObservation] = useState<
    MasterDataDetailObservation | undefined
  >(undefined);
  const [isEditDetailObservation, setIsEditDetailObservation] = useState<
    MasterDataDetailObservation | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddDetailObservation =
    masterData.masterDataDetailObservationCreateLoading;
  const isLoadingEditDetailObservation =
    masterData.masterDataDetailObservationEditLoading;
  const isLoadingDeleteDetailObservation =
    masterData.masterDataDetailObservationDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataDetailObservationListPage,
    setMasterDataDetailObservationListPage,
  ] = useState<number>(0);
  const [
    masterDataDetailObservationListCount,
    setMasterDataDetailObservationListCount,
  ] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataDetailObservationListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataDetailObservationListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [
    orderBy,
    setOrderBy,
  ] = useState<MasterDataDetailObservationOrderByType>('name');

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataDetailObservationOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH DETAIL OBSERVATION
  const fetchDetailObservationList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataDetailObservationListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataDetailObservationListAction.reset());
    fetchDetailObservationList(
      masterDataDetailObservationListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataDetailObservationListAction.reset());
    };
  }, [
    dispatch,
    fetchDetailObservationList,
    masterDataDetailObservationListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataDetailObservationActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataDetailObservationListAction.UPDATE, (): void => {
            setMasterDataDetailObservationListCount(
              masterData?.masterDataDetailObservationListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataDetailObservationListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailObservationListAction.reset());
          })
          .addCase(
            MasterDataDetailObservationCreateAction.SUCCESS,
            (): void => {
              setAddDetailObservation(false);
              refresh();
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Data added success!'),
                  type: 'success',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
            },
          )
          .addCase(MasterDataDetailObservationCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailObservationCreateAction.reset());
          })
          .addCase(MasterDataDetailObservationEditAction.SUCCESS, (): void => {
            setIsEditDetailObservation(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDetailObservationEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailObservationEditAction.reset());
          })
          .addCase(
            MasterDataDetailObservationDeleteAction.SUCCESS,
            (): void => {
              setIsDeleteDetailObservation(undefined);
              refresh();
              dispatch(
                wrapperNotifyAction.fetch({
                  text: t('Data deleted success!'),
                  type: 'success',
                  action: WrapperNotifyAction.FETCH,
                }),
              );
            },
          )
          .addCase(MasterDataDetailObservationDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDetailObservationDeleteAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataDetailObservationListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataDetailObservationActionHandler(masterData.action);
  }, [masterData.action, masterDataDetailObservationActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataDetailObservationDeleteAction.fetch({id}));
  };
  //#endregion

  return (
    <>
      <div className="master-data-detail-observation-section">
        <div className="master-data-detail-observation-section__header">
          <MSearchInput
            inputClassName="master-data-detail-observation-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Detail Observation')}
            setInputValue={(search: string) => {
              setMasterDataDetailObservationListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-detail-observation-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddDetailObservation(true)}
              />
            </div>
          )}
        </div>
        <DetailObservationManagementList
          detailObservationList={masterData.masterDataDetailObservationListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditDetailObservation: (
              _detailObservation: MasterDataDetailObservation,
            ) => setIsEditDetailObservation(_detailObservation),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteDetailObservation: (
              _detailObservation: MasterDataDetailObservation,
            ) => setIsDeleteDetailObservation(_detailObservation),
          })}
        />
        <MTablePagination
          className="master-data-detail-observation-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataDetailObservationListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataDetailObservationListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddDetailObservation(false)}
        title={t('Add Detail Observation')}
        isOpen={isAddDetailObservation}>
        <MasterDataDetailObservationFormCreateContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddDetailObservation}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditDetailObservation && (
        <Modal
          onClose={() => setIsEditDetailObservation(undefined)}
          isOpen={!!isEditDetailObservation}
          title={t('Edit Detail Observation')}>
          <MasterDataDetailObservationFormEditContainer
            assignmentType={assignmentType}
            data={isEditDetailObservation}
            isLoading={isLoadingEditDetailObservation}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteDetailObservation && (
        <AlertDialog
          onClose={() => setIsDeleteDetailObservation(undefined)}
          onCancel={() => setIsDeleteDetailObservation(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteDetailObservation.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteDetailObservation}
          isLoading={isLoadingDeleteDetailObservation}
          image={<IllustrationTrashImage />}
          description={t(
            'Are you sure you want to delete the data?',
          )}></AlertDialog>
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddDetailObservation(false)
              : setIsEditDetailObservation(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataDetailObservationSection;
