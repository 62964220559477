//#region IMPORT
// Libraries
import React from 'react';
import {Avatar} from '@mui/material';
// Utils
import t from '../../../../../../lang';
import {toInitial} from '../../../../../../utils';
// Data
import {RoleOverview} from '../../../../entity/user.string.entity';
// Assets
import './UserAccessManagementListItemRoleSection.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  item: RoleOverview;
  onClickItem(): void;
}
//#endregion

const UserAccessManagementListItemRoleSection: React.FC<Props> = ({
  item,
  onClickItem,
}: Props) => {
  //#region GENERAL
  const initialAvatar: string = toInitial(item.name);
  //#endregion

  return (
    <div
      className="user-access-management-list-item-role-section"
      onClick={onClickItem}>
      <div className="user-access-management-list-item-role-section__icon">
        <Avatar
          className="user-access-management-list-item-role-section__icon-avatar"
          alt={item.name}>
          {initialAvatar}
        </Avatar>
      </div>
      <div className="user-access-management-list-item-role-section__meta">
        <div className="user-access-management-list-item-role-section__meta--name">
          {item.name}
        </div>
        <div className="user-access-management-list-item-role-section__meta--info">{`${
          item.accessCount
        } ${t('Access')}`}</div>
        <div className="user-access-management-list-item-role-section__meta--info">{`${'1'} ${t(
          'User',
        )}`}</div>
      </div>
    </div>
  );
};

export default UserAccessManagementListItemRoleSection;
