//#region IMPORT
// Libraries
import React from 'react';
import Icon from '@mdi/react';
import {mdiAccount} from '@mdi/js';
// Utils
import t from '../../../../lang';
import {colors} from '../../../../config/_theme';
// Components
import {MButton} from '../../../../components';
import {UserManagementDetailItem} from './component';
// Data
import {User, UserType} from '../../entity/user.string.entity';
// Assets
import './UserManagementDetail.scss';
import {IconArrowUpCircle} from '../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  data: User;
  typeUser: UserType;
  onEdit(): void;
  onCancel(): void;
}
//#endregion

const UserManagementDetail: React.FC<Props> = ({
  data,
  typeUser,
  onEdit,
}: Props) => {
  return (
    <div className="user-management-detail">
      <div className="user-management-detail__meta-user">
        <div className="user-management-detail__meta-user--icon">
          <Icon path={mdiAccount} color={colors.icon} />
        </div>
        <div className="user-management-detail__meta-user--name">
          {data.name ?? '-'}
        </div>
        <div className="user-management-detail__meta-user--chip">
          <span>{typeUser ?? '-'}</span>
        </div>
        <div className="user-management-detail__meta-user--status">
          <IconArrowUpCircle
            fill={data.isActive ? colors.secondary.green : colors.secondary.red}
          />
          <span
            className={`meta-user-status${data.isActive ? '--active' : ''}`}>
            {data.isActive ? t('Active') : t('Inactive')}
          </span>
        </div>
      </div>

      {typeUser === UserType.INTERNAL ? (
        <>
          <div className="user-management-detail__section">
            <UserManagementDetailItem
              title={t('Directorate')}
              subtitle={data.directorate ?? '-'}
            />
          </div>

          <div className="user-management-detail__section">
            <UserManagementDetailItem
              title={t('Division')}
              subtitle={data.division ?? '-'}
            />
          </div>

          <div className="user-management-detail__section">
            <UserManagementDetailItem
              title={t('Department')}
              subtitle={data.department ?? '-'}
            />
          </div>
        </>
      ) : null}

      <div className="user-management-detail__section">
        <UserManagementDetailItem
          title={t('Email')}
          subtitle={data.email ?? '-'}
        />
      </div>

      <div className="user-management-detail__section">
        <UserManagementDetailItem
          title={t('Role')}
          subtitle={data.role.name ?? '-'}
        />
      </div>

      <div className="user-management-detail__action">
        <div className="user-management-detail__action--edit">
          <MButton buttonLabel={t('Edit')} handleClick={onEdit} />
        </div>
      </div>
    </div>
  );
};

export default UserManagementDetail;
