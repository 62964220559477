//#region IMPORT
// Libraries
import React from 'react';
import Grid from '@mui/material/Grid';
// Components
import FollowUpOnProgressListItem from '../FollowUpOnProgressListItem';
// Data
import {
  FollowUp,
  FollowUpLoadingItem,
} from '../../../../../entity/followUp.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp[];
  isLoading?: FollowUpLoadingItem;
  isCanConfirm?: boolean;
  isCanComplete?: boolean;
  isSPMOfficer?: boolean;
  onViewItem(item: FollowUp): void;
  onAction(item: FollowUp): void;
}
//#endregion

const FollowUpOnProgressList: React.FC<Props> = ({
  data,
  isLoading,
  isCanConfirm,
  isCanComplete,
  isSPMOfficer,
  onViewItem,
  onAction,
}: Props) => {
  return (
    <div className="follow-up-on-progress-list__container">
      <Grid container spacing={3}>
        {data?.map((item: FollowUp, index: number) => {
          return (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              key={`follow-up-on-progress-list-item-data-${item.id}-${index}`}>
              <FollowUpOnProgressListItem
                isSPMOfficer={isSPMOfficer}
                data={item}
                isLoading={isLoading?.isLoading && isLoading.id === item.id}
                isCanConfirm={isCanConfirm}
                isCanComplete={isCanComplete}
                onViewItem={() => onViewItem(item)}
                onAction={() => onAction(item)}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default FollowUpOnProgressList;
