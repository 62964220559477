import {CommonError} from '../../../../../../config/constant';

export const MasterDataCategoryMoveAction = {
  FETCH: 'MASTER_DATA_CATEGORY_MOVE_FETCH',
  SUCCESS: 'MASTER_DATA_CATEGORY_MOVE_SUCCESS',
  FAILED: 'MASTER_DATA_CATEGORY_MOVE_FAILED',
  RESET: 'MASTER_DATA_CATEGORY_MOVE_RESET',
};

export interface MasterDataCategoryMoveInitialState {
  masterDataCategoryMoveLoading: boolean;
  masterDataCategoryMoveParams?: MasterDataCategoryMoveFetchPayload;
  masterDataCategoryMoveError?: MasterDataCategoryMoveFailedPayload;
  masterDataCategoryMoveResponse?: MasterDataCategoryMoveSuccessPayload;
}

export interface MasterDataCategoryMoveFetchPayload {
  id: string;
  isUp?: boolean;
  isDown?: boolean;
  assignmentType: string;
}
export type MasterDataCategoryMoveFailedPayload = CommonError;

export interface MasterDataCategoryMoveSuccessPayload {}
