import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataBusEditInitialState} from './masterDataBusEdit.reducer';
import {
  MasterDataBusEditAction,
  MasterDataBusEditFailedPayload,
  MasterDataBusEditFetchPayload,
  MasterDataBusEditSuccessPayload,
} from './masterDataBusEdit.type';

export const masterDataBusEditHandler = {
  [MasterDataBusEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusEditLoading: true,
    masterDataBusEditParams: action.payload,
    action: MasterDataBusEditAction.FETCH,
  }),
  [MasterDataBusEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusEditParams: undefined,
    masterDataBusEditLoading: false,
    masterDataBusEditResponse: action.payload,
    action: MasterDataBusEditAction.SUCCESS,
  }),
  [MasterDataBusEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusEditLoading: false,
    masterDataBusEditError: action.payload,
    action: MasterDataBusEditAction.FAILED,
  }),
  [MasterDataBusEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBusEditInitialState,
    action: MasterDataBusEditAction.RESET,
  }),
};
