import {
  MasterDataObservationValueListAction,
  MasterDataObservationValueListSuccessPayload,
  MasterDataObservationValueListFailedPayload,
  MasterDataObservationValueListFetchPayload,
} from './masterDataObservationValueList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataObservationValueListInitialState} from './masterDataObservationValueList.reducer';

export const masterDataObservationValueListHandler = {
  [MasterDataObservationValueListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataObservationValueListLoading: true,
      masterDataObservationValueListParams: action.payload,
      action: MasterDataObservationValueListAction.FETCH,
    };
  },
  [MasterDataObservationValueListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueListLoading: false,
    masterDataObservationValueListResponse: action.payload,
    action: MasterDataObservationValueListAction.SUCCESS,
  }),
  [MasterDataObservationValueListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueListLoading: false,
    masterDataObservationValueListData: [
      ...state.masterDataObservationValueListData,
      ...action.payload.data.content,
    ],
    action: MasterDataObservationValueListAction.UPDATE,
  }),
  [MasterDataObservationValueListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationValueListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationValueListLoading: false,
    masterDataObservationValueListError: action.payload,
    action: MasterDataObservationValueListAction.FAILED,
  }),
  [MasterDataObservationValueListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationValueListInitialState,
    action: MasterDataObservationValueListAction.RESET,
  }),
};
