import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataReviewIndicatorDeleteInitialState} from './masterDataReviewIndicatorDelete.reducer';
import {
  MasterDataReviewIndicatorDeleteAction,
  MasterDataReviewIndicatorDeleteFailedPayload,
  MasterDataReviewIndicatorDeleteFetchPayload,
  MasterDataReviewIndicatorDeleteSuccessPayload,
} from './masterDataReviewIndicatorDelete.type';

export const masterDataReviewIndicatorDeleteHandler = {
  [MasterDataReviewIndicatorDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorDeleteLoading: true,
    masterDataReviewIndicatorDeleteParams: action.payload,
    action: MasterDataReviewIndicatorDeleteAction.FETCH,
  }),
  [MasterDataReviewIndicatorDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorDeleteParams: undefined,
    masterDataReviewIndicatorDeleteLoading: false,
    masterDataReviewIndicatorDeleteResponse: action.payload,
    action: MasterDataReviewIndicatorDeleteAction.SUCCESS,
  }),
  [MasterDataReviewIndicatorDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorDeleteLoading: false,
    masterDataReviewIndicatorDeleteError: action.payload,
    action: MasterDataReviewIndicatorDeleteAction.FAILED,
  }),
  [MasterDataReviewIndicatorDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataReviewIndicatorDeleteInitialState,
    action: MasterDataReviewIndicatorDeleteAction.RESET,
  }),
};
