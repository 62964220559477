import {CommonError} from '../../../../../../config/constant';
import {MasterDataBasicServiceTypeFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataBasicServiceTypeCreateAction = {
  FETCH: 'MASTER_DATA_BASIC_SERVICE_TYPE_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_BASIC_SERVICE_TYPE_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_BASIC_SERVICE_TYPE_CREATE_FAILED',
  RESET: 'MASTER_DATA_BASIC_SERVICE_TYPE_CREATE_RESET',
};

export interface MasterDataBasicServiceTypeCreateInitialState {
  masterDataBasicServiceTypeCreateLoading: boolean;
  masterDataBasicServiceTypeCreateParams?: MasterDataBasicServiceTypeCreateFetchPayload;
  masterDataBasicServiceTypeCreateError?: MasterDataBasicServiceTypeCreateFailedPayload;
  masterDataBasicServiceTypeCreateResponse?: MasterDataBasicServiceTypeCreateSuccessPayload;
}

export type MasterDataBasicServiceTypeCreateFetchPayload = Omit<
  MasterDataBasicServiceTypeFormData,
  'id'
>[];

export type MasterDataBasicServiceTypeCreateFailedPayload = CommonError;

export interface MasterDataBasicServiceTypeCreateSuccessPayload {}
