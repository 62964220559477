import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userOTPPasswordInitialState} from './userOTPPassword.reducer';
import {
  UserOTPPasswordAction,
  UserOTPPasswordFailedPayload,
  UserOTPPasswordFetchPayload,
  UserOTPPasswordSuccessPayload,
} from './userOTPPassword.type';

export const userOTPPasswordHandler = {
  [UserOTPPasswordAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserOTPPasswordFetchPayload>,
  ): UserState => ({
    ...state,
    userOTPPasswordLoading: true,
    userOTPPasswordParams: action.payload,
    action: UserOTPPasswordAction.FETCH,
  }),
  [UserOTPPasswordAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserOTPPasswordSuccessPayload>,
  ): UserState => ({
    ...state,
    userOTPPasswordLoading: false,
    userOTPPasswordResponse: action.payload,
    action: UserOTPPasswordAction.SUCCESS,
  }),
  [UserOTPPasswordAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserOTPPasswordFailedPayload>,
  ): UserState => ({
    ...state,
    userOTPPasswordLoading: false,
    userOTPPasswordError: action.payload,
    action: UserOTPPasswordAction.FAILED,
  }),
  [UserOTPPasswordAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userOTPPasswordInitialState,
    action: UserOTPPasswordAction.RESET,
  }),
};
