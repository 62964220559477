import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataObservationEditInitialState} from './masterDataObservationEdit.reducer';
import {
  MasterDataObservationEditAction,
  MasterDataObservationEditFailedPayload,
  MasterDataObservationEditFetchPayload,
  MasterDataObservationEditSuccessPayload,
} from './masterDataObservationEdit.type';

export const masterDataObservationEditHandler = {
  [MasterDataObservationEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationEditLoading: true,
    masterDataObservationEditParams: action.payload,
    action: MasterDataObservationEditAction.FETCH,
  }),
  [MasterDataObservationEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationEditParams: undefined,
    masterDataObservationEditLoading: false,
    masterDataObservationEditResponse: action.payload,
    action: MasterDataObservationEditAction.SUCCESS,
  }),
  [MasterDataObservationEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationEditLoading: false,
    masterDataObservationEditError: action.payload,
    action: MasterDataObservationEditAction.FAILED,
  }),
  [MasterDataObservationEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationEditInitialState,
    action: MasterDataObservationEditAction.RESET,
  }),
};
