//#region IMPORT
// Libraries
import React from 'react';
import {TableHead, TableRow, TableCell} from '@mui/material';
// Utils
import t from '../../../../../../../../lang';
//#endregion

const MasterDataDepartmentManagementListTableHeader: React.FC = () => {
  return (
    <TableHead className="master-data-department-management-list-table-header">
      <TableRow className="master-data-department-management-list-table-header__row">
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="table-header-label">
          {t('No')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="45%"
          className="table-header-label">
          {t('Basic Service Type')}
        </TableCell>

        <TableCell
          className="master-data-department-management-list-table-header__row--action table-header-label"
          align="right"
          padding="none"
        />
      </TableRow>
    </TableHead>
  );
};

export default MasterDataDepartmentManagementListTableHeader;
