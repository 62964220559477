//#region IMPORT
import Snackbar from '@mui/material/Snackbar';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MNotificationDialog} from '../../../../components';
import {AppState} from '../../../../config/Interface';
import {WrapperState} from '../../usecases/wrapper.reducer';
import {wrapperNotifyAction} from '../../usecases/wrapper.type';
import {WrapperNotifyAction} from '../../usecases/WrapperNotify/wrapperNotify.type';

//#endregion

const Alert: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const wrapper: WrapperState = useSelector((state: AppState) => state.wrapper);
  const [isAlertVisible, setAlertVisibility] = useState(false);

  const notification = wrapper.notification;
  const handleCloseAlert = useCallback(() => {
    setAlertVisibility(false);
    dispatch(wrapperNotifyAction.reset());
  }, [dispatch]);
  //#endregion

  //#region ACTION HANDLER
  const actionWrapperHandler = useCallback(
    (_action: string) => {
      const actions = {
        [WrapperNotifyAction.FETCH as string]: (): void => {
          if (wrapper.notification) {
            setAlertVisibility(true);
          }
        },
        DEFAULT: (): void => {},
      };
      return (actions[_action] || actions.DEFAULT)();
    },
    [wrapper.notification],
  );

  useEffect(() => actionWrapperHandler(wrapper.action));
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeId: any;
    if (isAlertVisible) {
      timeId = setTimeout(() => {
        handleCloseAlert();
      }, 2000);
    }
    return () => {
      clearTimeout(timeId);
    };
  }, [handleCloseAlert, isAlertVisible]);
  //#endregion

  if (notification)
    return (
      <div>
        {notification.isSnackbar ? (
          <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            open={isAlertVisible}
            autoHideDuration={2000}
            onClose={handleCloseAlert}
            message={notification.text}
          />
        ) : (
          <MNotificationDialog
            isOpen={isAlertVisible}
            type={notification.type}
            text={notification.text}
            onClose={handleCloseAlert}
          />
        )}
      </div>
    );
  return null;
};

export default Alert;
