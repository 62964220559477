/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  masterDataReviewIndicatorListAction,
  masterDataReviewIndicatorDeleteAction,
  masterDataReviewIndicatorMoveAction,
  masterDataReviewIndicatorCreateAction,
  masterDataReviewIndicatorEditAction,
} from '../../../../usecases/masterData.action';
import {
  MasterDataReviewIndicatorCreateAction,
  MasterDataReviewIndicatorDeleteAction,
  MasterDataReviewIndicatorEditAction,
  MasterDataReviewIndicatorListAction,
  MasterDataReviewIndicatorMoveAction,
} from '../../../../usecases/masterData.type';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataReviewIndicatorManagementList} from './components';
import MasterDataReviewIndicatorFormContainer from '../MasterDataReviewIndicatorFormContainer';
// Data
import {MasterDataReviewIndicator} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataReviewIndicatorSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
}
//#endregion

const MasterDataReviewIndicatorSection: React.FC<Props> = ({
  assignmentType,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddReviewIndicator, setAddReviewIndicator] = useState(false);
  const [isDeleteReviewIndicator, setIsDeleteReviewIndicator] = useState<
    MasterDataReviewIndicator | undefined
  >(undefined);
  const [isEditReviewIndicator, setIsEditReviewIndicator] = useState<
    MasterDataReviewIndicator | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddReviewIndicator =
    masterData.masterDataReviewIndicatorCreateLoading;
  const isLoadingEditReviewIndicator =
    masterData.masterDataReviewIndicatorEditLoading;
  const isLoadingDeleteReviewIndicator =
    masterData.masterDataReviewIndicatorDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataReviewIndicatorListPage,
    setMasterDataReviewIndicatorListPage,
  ] = useState<number>(0);
  const [
    masterDataReviewIndicatorListCount,
    setMasterDataReviewIndicatorListCount,
  ] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataReviewIndicatorListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataReviewIndicatorListPage(0);
  };
  //#endregion

  //#region FETCH REVIEW INDICATOR
  const fetchReviewIndicatorList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(
        masterDataReviewIndicatorListAction.fetch({
          page,
          search,
          size,
          assignmentType,
        }),
      );
    },
    [assignmentType, dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataReviewIndicatorListAction.reset());
    fetchReviewIndicatorList(
      masterDataReviewIndicatorListPage,
      searchValue,
      rowsPerPage,
    );

    return () => {
      dispatch(masterDataReviewIndicatorListAction.reset());
    };
  }, [
    dispatch,
    fetchReviewIndicatorList,
    masterDataReviewIndicatorListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  //#endregion

  //#region ACTION HANDLER
  const masterDataReviewIndicatorActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataReviewIndicatorListAction.UPDATE, (): void => {
            setMasterDataReviewIndicatorListCount(
              masterData?.masterDataReviewIndicatorListResponse?.data
                ?.totalElements ?? 0,
            );
          })
          .addCase(MasterDataReviewIndicatorListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataReviewIndicatorListAction.reset());
          })
          .addCase(MasterDataReviewIndicatorCreateAction.SUCCESS, (): void => {
            setAddReviewIndicator(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataReviewIndicatorCreateAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataReviewIndicatorCreateAction.reset());
          })
          .addCase(MasterDataReviewIndicatorEditAction.SUCCESS, (): void => {
            setIsEditReviewIndicator(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataReviewIndicatorEditAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataReviewIndicatorEditAction.reset());
          })
          .addCase(MasterDataReviewIndicatorDeleteAction.SUCCESS, (): void => {
            setIsDeleteReviewIndicator(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataReviewIndicatorDeleteAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataReviewIndicatorDeleteAction.reset());
          })
          .addCase(MasterDataReviewIndicatorMoveAction.SUCCESS, (): void => {
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved success!'),
                type: 'success',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataReviewIndicatorMoveAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data moved failed!'),
                type: 'error',
                isSnackbar: true,
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataReviewIndicatorMoveAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData?.masterDataReviewIndicatorListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataReviewIndicatorActionHandler(masterData.action);
  }, [masterData.action, masterDataReviewIndicatorActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataReviewIndicatorDeleteAction.fetch({id}));
  };
  //#endregion

  //#region HANDLE MOVE
  const handleMove = (id: string, isUp?: boolean) => {
    dispatch(
      masterDataReviewIndicatorMoveAction.fetch({
        id,
        ...(isUp ? {isUp: true} : {isDown: true}),
        assignmentType,
      }),
    );
  };
  //#endregion

  return (
    <>
      <div className="master-data-review-indicator-section">
        <div className="master-data-review-indicator-section__header">
          <MSearchInput
            inputClassName="master-data-review-indicator-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Review Indicator')}
            setInputValue={(search: string) => {
              setMasterDataReviewIndicatorListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterEvaluationForm.havePermissionCreate && (
            <div className="master-data-review-indicator-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddReviewIndicator(true)}
              />
            </div>
          )}
        </div>
        <MasterDataReviewIndicatorManagementList
          ReviewIndicatorList={masterData.masterDataReviewIndicatorListData}
          {...(permissions?.masterEvaluationForm.havePermissionUpdate && {
            onEditReviewIndicator: (
              _ReviewIndicator: MasterDataReviewIndicator,
            ) => setIsEditReviewIndicator(_ReviewIndicator),
            onMoveUp: (id: string) => handleMove(id, true),
            onMoveDown: (id: string) => handleMove(id),
          })}
          {...(permissions?.masterEvaluationForm.havePermissionDelete && {
            onDeleteReviewIndicator: (
              _ReviewIndicator: MasterDataReviewIndicator,
            ) => setIsDeleteReviewIndicator(_ReviewIndicator),
          })}
        />
        <MTablePagination
          className="master-data-review-indicator-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataReviewIndicatorListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataReviewIndicatorListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddReviewIndicator(false)}
        title={t('Add Review Indicator')}
        isOpen={isAddReviewIndicator}>
        <MasterDataReviewIndicatorFormContainer
          assignmentType={assignmentType}
          isLoading={isLoadingAddReviewIndicator}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditReviewIndicator && (
        <Modal
          onClose={() => setIsEditReviewIndicator(undefined)}
          isOpen={!!isEditReviewIndicator}
          title={t('Edit Review Indicator')}>
          <MasterDataReviewIndicatorFormContainer
            assignmentType={assignmentType}
            data={isEditReviewIndicator}
            isLoading={isLoadingEditReviewIndicator}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteReviewIndicator && (
        <AlertDialog
          onClose={() => setIsDeleteReviewIndicator(undefined)}
          onCancel={() => setIsDeleteReviewIndicator(undefined)}
          onConfirm={() => {
            isDeleteReviewIndicator?.id &&
              handleDelete(isDeleteReviewIndicator.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteReviewIndicator}
          isLoading={isLoadingDeleteReviewIndicator}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddReviewIndicator(false)
              : setIsEditReviewIndicator(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataReviewIndicatorSection;
