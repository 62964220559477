import {createReducer} from '@reduxjs/toolkit';
import dashboardHandler from './dashboard.handler';

//#region TYPE
//#region DASHBOARD SPM
import {DashboardSPMChartSectionInitialState} from './section/spm/dashboardSPMChartSection/dashboardSPMChartSection.type';
//#endregion
//#region DASHBOARD OVERVIEW
import {DashboardOverviewSetProfitInitialState} from './section/overview/dashboardOverviewSetProfit/dashboardOverviewSetProfit.type';
import {DashboardOverviewBestSectionInitialState} from './section/overview/dashboardOverviewBestSection/dashboardOverviewBestSection.type';
import {DashboardOverviewChartSectionInitialState} from './section/overview/dashboardOverviewChartSection/dashboardOverviewChartSection.type';
import {DashboardOverviewExportAchievementFinalMonthlyInitialState} from './section/overview/dashboardOverviewExportAchievementFinalMonthly/dashboardOverviewExportAchievementFinalMonthly.type';
import {DashboardOverviewExportAchievementFinalQuarterInitialState} from './section/overview/dashboardOverviewExportAchievementFinalQuarter/dashboardOverviewExportAchievementFinalQuarter.type';

//#endregion
//#region DASHBOARD DEPARTMENT
import {DashboardTabDepartmentListInitialState} from './section/department/dashboardTabDepartmentList/dashboardTabDepartmentList.type';
import {DashboardDepartmentBestSectionInitialState} from './section/department/dashboardDepartmentBestSection/dashboardDepartmentBestSection.type';
import {DashboardDepartmentChartSectionInitialState} from './section/department/dashboardDepartmentChartSection/dashboardDepartmentChartSection.type';
//#endregion
//#endregion

//#region REDUCER
//#region DASHBOARD SPM
import {dashboardSPMChartSectionInitialState} from './section/spm/dashboardSPMChartSection/dashboardSPMChartSection.reducer';
//#endregion
//#region DASHBOARD OVERVIEW
import {dashboardOverviewSetProfitInitialState} from './section/overview/dashboardOverviewSetProfit/dashboardOverviewSetProfit.reducer';
import {dashboardOverviewBestSectionInitialState} from './section/overview/dashboardOverviewBestSection/dashboardOverviewBestSection.reducer';
import {dashboardOverviewChartSectionInitialState} from './section/overview/dashboardOverviewChartSection/dashboardOverviewChartSection.reducer';
import {dashboardOverviewExportAchievementFinalMonthlyInitialState} from './section/overview/dashboardOverviewExportAchievementFinalMonthly/dashboardOverviewExportAchievementFinalMonthly.reducer';
import {dashboardOverviewExportAchievementFinalQuarterInitialState} from './section/overview/dashboardOverviewExportAchievementFinalQuarter/dashboardOverviewExportAchievementFinalQuarter.reducer';
//#endregion
//#region DASHBOARD DEPARTMENT
import {dashboardTabDepartmentListInitialState} from './section/department/dashboardTabDepartmentList/dashboardTabDepartmentList.reducer';
import {dashboardDepartmentBestSectionInitialState} from './section/department/dashboardDepartmentBestSection/dashboardDepartmentBestSection.reducer';
import {dashboardDepartmentChartSectionInitialState} from './section/department/dashboardDepartmentChartSection/dashboardDepartmentChartSection.reducer';
//#endregion
//#endregion

export interface DashboardState
  extends DashboardTabDepartmentListInitialState,
    DashboardDepartmentBestSectionInitialState,
    DashboardDepartmentChartSectionInitialState,
    DashboardOverviewBestSectionInitialState,
    DashboardOverviewChartSectionInitialState,
    DashboardOverviewSetProfitInitialState,
    DashboardOverviewExportAchievementFinalMonthlyInitialState,
    DashboardOverviewExportAchievementFinalQuarterInitialState,
    DashboardSPMChartSectionInitialState {
  action: string;
}

export const dashboardInitialState: DashboardState = {
  ...dashboardTabDepartmentListInitialState,
  ...dashboardDepartmentBestSectionInitialState,
  ...dashboardDepartmentChartSectionInitialState,
  ...dashboardOverviewBestSectionInitialState,
  ...dashboardOverviewChartSectionInitialState,
  ...dashboardOverviewSetProfitInitialState,
  ...dashboardOverviewExportAchievementFinalMonthlyInitialState,
  ...dashboardOverviewExportAchievementFinalQuarterInitialState,
  ...dashboardSPMChartSectionInitialState,
  action: '',
};

export default createReducer(dashboardInitialState, dashboardHandler);
