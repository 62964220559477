import {createAction} from '@reduxjs/toolkit';
import {
  WorkReportUploadAction,
  WorkReportUploadFailedPayload,
  WorkReportUploadFetchPayload,
  WorkReportUploadSuccessPayload,
} from './workReportUpload.type';

export const workReportUploadAction = {
  fetch: createAction<WorkReportUploadFetchPayload>(
    WorkReportUploadAction.FETCH,
  ),
  success: createAction<WorkReportUploadSuccessPayload>(
    WorkReportUploadAction.SUCCESS,
  ),
  failed: createAction<WorkReportUploadFailedPayload>(
    WorkReportUploadAction.FAILED,
  ),
  reset: createAction(WorkReportUploadAction.RESET),
};
