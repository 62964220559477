//#region IMPORT
// Libraries
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
// Utils
import t from '../../../../lang';
// Components
import {MTabPanel, MTabs} from '../../../../components';
import {FollowUpApprovedSection, FollowUpOnProgressSection} from '../sections';
// Data
import {
  FollowUp,
  FollowUpStatus,
  followUpTabContentType,
} from '../../entity/followUp.string.entity';
import {PATH} from '../../../wrapper/entity';
// Assets
import './FollowUpSPMManagementWrapper.scss';
//#endregion

//#region INTERFACE
interface Props {
  defaultOpenedDetail?: FollowUp;
  defaultOpenedTab: number;
}
//#endregion

const FollowUpSPMManagementWrapper: React.FC<Props> = ({
  defaultOpenedDetail,
  defaultOpenedTab,
}: Props) => {
  //#region GENERAL
  const history = useHistory();
  //#endregion

  //#region HANDLE TAB
  const [tabValue, setTabValue] = useState(defaultOpenedTab);
  const [openedDetail, setOpenedDetail] = useState<FollowUp | undefined>(
    defaultOpenedDetail,
  );
  const handleChangeTab = (_: unknown, newValue: number) => {
    if (!newValue) history.push(PATH.TINDAK_LANJUT);
    else {
      const followUpType: string = followUpTabLabel[newValue].enum;
      history.push({
        pathname: PATH.TINDAK_LANJUT,
        search: `?followUpType=${followUpType}`,
      });
    }
    setTabValue(newValue);
  };
  const followUpTabLabel: followUpTabContentType[] = [
    {
      label: t('On Progress'),
      enum: FollowUpStatus.ONPROGRESS,
      component: (
        <FollowUpOnProgressSection defaultOpenedDetail={openedDetail} />
      ),
    },
    {
      label: t('Approved'),
      enum: FollowUpStatus.ACCEPTED,
      component: <FollowUpApprovedSection defaultOpenedDetail={openedDetail} />,
    },
  ];
  //#endregion

  //#region HANDLE CHANGE ROUTE TAB
  useEffect(() => {
    let changeRouteTab = true;
    if (changeRouteTab) {
      setTabValue(defaultOpenedTab);
      setOpenedDetail(defaultOpenedDetail);
    }

    return () => {
      changeRouteTab = false;
    };
  }, [defaultOpenedDetail, defaultOpenedTab]);
  //#endregion

  return (
    <div className="follow-up-spm-management-wrapper">
      <div className="follow-up-spm-management-wrapper__tab">
        <MTabs
          variant="scrollable"
          tabsValue={tabValue}
          handleChangeTab={handleChangeTab}
          tabLabel={followUpTabLabel.map((item) => item.label)}
        />
      </div>
      <div className="follow-up-spm-management-wrapper__tab-panel">
        {followUpTabLabel.map((item: followUpTabContentType, index: number) => (
          <MTabPanel
            key={`follow-up-spm-management-tab-label-${item.label}-${index}`}
            value={tabValue}
            index={index}
            className="follow-up-spm-management-wrapper__tab-panel--item">
            {item.component}
          </MTabPanel>
        ))}
      </div>
    </div>
  );
};

export default FollowUpSPMManagementWrapper;
