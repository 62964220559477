import {CommonError} from '../../../../../../config/constant';

export const MasterDataObservationValueSetPositiveValueAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_VALUE_SET_POSITIVE_VALUE_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_VALUE_SET_POSITIVE_VALUE_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_VALUE_SET_POSITIVE_VALUE_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_VALUE_SET_POSITIVE_VALUE_RESET',
};

export interface MasterDataObservationValueSetPositiveValueInitialState {
  masterDataObservationValueSetPositiveValueLoading: boolean;
  masterDataObservationValueSetPositiveValueParams?: MasterDataObservationValueSetPositiveValueFetchPayload;
  masterDataObservationValueSetPositiveValueError?: MasterDataObservationValueSetPositiveValueFailedPayload;
  masterDataObservationValueSetPositiveValueResponse?: MasterDataObservationValueSetPositiveValueSuccessPayload;
}

export interface MasterDataObservationValueSetPositiveValueFetchPayload {
  id: string;
}
export type MasterDataObservationValueSetPositiveValueFailedPayload = CommonError;

export interface MasterDataObservationValueSetPositiveValueSuccessPayload {}
