import {AxiosResponse} from 'axios';
import moment from 'moment';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {
  downloadBlobResponse,
  generateFileName,
  generateHeaders,
} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {FILEEXT} from '../../../wrapper/entity';
import {workReportExportAllAction} from './workReportExportAll.action';
import {
  WorkReportExportAllAction,
  WorkReportExportAllFetchPayload,
  WorkReportExportAllResponse,
  WorkReportExportAllSuccessPayload,
} from './workReportExportAll.type';

function* workerFetch(action: GenericAction<WorkReportExportAllFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {payload} = action;
  const formattedPayload = {
    ...payload,
    startMonth: moment(payload.startMonth, 'YYYY-MMMM')
      .locale('en')
      .format('YYYY-MMMM'),
    endMonth: moment(payload.endMonth, 'YYYY-MMMM')
      .locale('en')
      .format('YYYY-MMMM'),
  };
  try {
    const res: AxiosResponse<WorkReportExportAllSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/work-report/export-all`,
      formattedPayload,
      {
        ...generateHeaders({
          authorization: {
            token: userData?.accessToken || '',
            tokenType: userData?.tokenType || '',
          },
        }),
        responseType: 'blob',
      },
    );
    if (res.status === 200 && res.data) {
      const startMonth = moment(payload.startMonth, 'YYYY-MMMM').format(
        'MMMM YYYY',
      );
      const endMonth = moment(payload.endMonth, 'YYYY-MMMM').format(
        'MMMM YYYY',
      );
      downloadBlobResponse(
        [(res.data as unknown) as Blob],
        generateFileName(
          `${action.payload.reportType} ${startMonth}-${endMonth}`,
          FILEEXT.XLSX,
        ),
      );
      yield put(
        workReportExportAllAction.success(({
          file: res.data,
        } as unknown) as WorkReportExportAllResponse),
      );
    } else {
      yield put(workReportExportAllAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(workReportExportAllAction.failed(e as CommonError));
  }
}

const workReportExportAllWatcher = [
  takeLatest(WorkReportExportAllAction.FETCH, workerFetch),
];

export default workReportExportAllWatcher;
