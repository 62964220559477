import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {httpPut} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataDirectorateEditAction} from './masterDataDirectorateEdit.action';
import {
  MasterDataDirectorateEditAction,
  MasterDataDirectorateEditFetchPayload,
  MasterDataDirectorateEditSuccessPayload,
} from './masterDataDirectorateEdit.type';

function* workerFetch(
  action: GenericAction<MasterDataDirectorateEditFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id, name} = action.payload;
  try {
    const res: AxiosResponse<MasterDataDirectorateEditSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/directorate/save/${id}`,
      {name},
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.status === 200) {
      yield put(masterDataDirectorateEditAction.success(res.data));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(masterDataDirectorateEditAction.failed(e.response.data));
  }
}

const masterDataDirectorateEditWatcher = [
  takeLatest(MasterDataDirectorateEditAction.FETCH, workerFetch),
];

export default masterDataDirectorateEditWatcher;
