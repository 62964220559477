//#region IMPORT
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import useInterval from '@use-it/interval';
import {notificationListAction} from '../../../notification/usecases/notification.action';
import {PATH} from '../../entity';

//#endregion

const Notification: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const location = useLocation();
  const pageNumber = 0;
  const pageSize = 20;
  //#endregion

  //#region FETCH NOTIFICATION LIST
  const fetchNotificationList = useCallback(
    (page?: number, size?: number) => {
      dispatch(notificationListAction.fetch({page, size}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(notificationListAction.reset());
    if (location.pathname !== PATH.NOTIFICATION) {
      fetchNotificationList(pageNumber, pageSize);
    }

    return () => {
      dispatch(notificationListAction.reset());
    };
  }, [dispatch, fetchNotificationList, location.pathname]);

  refresh();
  useInterval(() => {
    refresh();
  }, 300000);
  //#endregion

  return null;
};

export default Notification;
