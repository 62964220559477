import {CommonError} from '../../../../../../config/constant';
import {DashboardDepartmentChartSection} from '../../../../entity/dashboard.string.entity';

export const DashboardDepartmentChartSectionAction = {
  FETCH: 'DASHBOARD_DEPARTMENT_CHART_SECTION_FETCH',
  SUCCESS: 'DASHBOARD_DEPARTMENT_CHART_SECTION_SUCCESS',
  FAILED: 'DASHBOARD_DEPARTMENT_CHART_SECTION_FAILED',
  RESET: 'DASHBOARD_DEPARTMENT_CHART_SECTION_RESET',
};

export interface DashboardDepartmentChartSectionInitialState {
  dashboardDepartmentChartSectionLoading: boolean;
  dashboardDepartmentChartSectionError?: DashboardDepartmentChartSectionFailedPayload;
  dashboardDepartmentChartSectionResponse?: DashboardDepartmentChartSectionSuccessPayload;
  dashboardDepartmentChartSectionParams?: DashboardDepartmentChartSectionFetchPayload;
}

export interface DashboardDepartmentChartSectionFetchPayload {
  departmentId: string;
  year: number;
  month: number;
}

export interface DashboardDepartmentChartSectionResponse {
  status: string;
  info?: number;
  data: DashboardDepartmentChartSection;
}

export type DashboardDepartmentChartSectionFailedPayload = CommonError;

export type DashboardDepartmentChartSectionSuccessPayload = DashboardDepartmentChartSectionResponse;

export type DashboardDepartmentChartSectionUpdatePayload = DashboardDepartmentChartSectionResponse;
