import * as React from 'react';

const IconHome = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    width={props.width || 20}
    height={props.height || 20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M7.612 17.311v-2.548c0-.65.531-1.177 1.186-1.177h2.396c.314 0 .616.124.838.345.223.22.348.52.348.832v2.548c-.002.27.105.53.297.723.192.192.453.3.725.3h1.634a2.884 2.884 0 0 0 2.036-.833c.54-.535.844-1.262.844-2.02V8.224a2.06 2.06 0 0 0-.746-1.585L11.611 2.23a2.581 2.581 0 0 0-3.29.06L2.889 6.637a2.062 2.062 0 0 0-.806 1.585v7.251a2.87 2.87 0 0 0 2.88 2.86H6.56c.566 0 1.026-.453 1.03-1.015l.022-.008Z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconHome;
