import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataCategoryMoveAction,
  MasterDataCategoryMoveFailedPayload,
  MasterDataCategoryMoveFetchPayload,
  MasterDataCategoryMoveSuccessPayload,
} from './masterDataCategoryMove.type';

export const masterDataCategoryMoveAction = {
  fetch: createAction<MasterDataCategoryMoveFetchPayload>(
    MasterDataCategoryMoveAction.FETCH,
  ),
  success: createAction<MasterDataCategoryMoveSuccessPayload>(
    MasterDataCategoryMoveAction.SUCCESS,
  ),
  failed: createAction<MasterDataCategoryMoveFailedPayload>(
    MasterDataCategoryMoveAction.FAILED,
  ),
  reset: createAction(MasterDataCategoryMoveAction.RESET),
};
