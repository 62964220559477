import {CommonError} from '../../../../../../config/constant';
import {MasterDataAssignmentLetterFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataAssignmentLetterCreateAction = {
  FETCH: 'MASTER_DATA_ASSIGNMENT_LETTER_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_ASSIGNMENT_LETTER_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_ASSIGNMENT_LETTER_CREATE_FAILED',
  RESET: 'MASTER_DATA_ASSIGNMENT_LETTER_CREATE_RESET',
};

export interface MasterDataAssignmentLetterCreateInitialState {
  masterDataAssignmentLetterCreateLoading: boolean;
  masterDataAssignmentLetterCreateParams?: MasterDataAssignmentLetterCreateFetchPayload;
  masterDataAssignmentLetterCreateError?: MasterDataAssignmentLetterCreateFailedPayload;
  masterDataAssignmentLetterCreateResponse?: MasterDataAssignmentLetterCreateSuccessPayload;
}

export type MasterDataAssignmentLetterCreateFetchPayload = MasterDataAssignmentLetterFormData;

export type MasterDataAssignmentLetterCreateFailedPayload = CommonError;

export interface MasterDataAssignmentLetterCreateSuccessPayload {}
