import {UserData, UserLevel} from '../../entity/user.string.entity';

export const UserDataAction = {
  FETCH: 'USER_DATA_FETCH',
  RESET: 'USER_DATA_RESET',
  LOGOUT: 'USER_DATA_LOGOUT',
};

export interface UserDataInitialState {
  accessToken?: string;
  refreshToken?: string;
  tokenType?: string;
  data?: UserData;
  isLogin?: boolean;
  isFirstLogin?: boolean;
  lastSuccessLogin?: string;
  userLevel?: UserLevel;
}

export type UserDataFetchPayload = {
  accessToken?: string;
  tokenType?: string;
  data?: UserData;
  isLogin?: boolean;
  isFirstLogin?: boolean;
  lastSuccessLogin?: string;
  userLevel?: UserLevel;
};
