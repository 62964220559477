//#region IMPORT
// Libraries
import React, {ReactElement} from 'react';
import * as Yup from 'yup';
import {Field, FieldArray, FieldArrayRenderProps, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
// Components
import {MButton, MTextField} from '../../../../../../../../components';
// Data
import {MasterDataIndicatorValueFormData} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataIndicatorValueFormCreateForm.component.style.scss';
//#endregion

//#region TYPE
export interface IndicatorValueFormCreateInitialValues {
  indicatorValues: Pick<MasterDataIndicatorValueFormData, 'name'>[];
}
//#endregion

//#region INTERFACE
interface Props {
  initialValues: IndicatorValueFormCreateInitialValues;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitIndicatorValue(
    formValue: IndicatorValueFormCreateInitialValues,
  ): void;
}
//#endregion

const MasterDataIndicatorValueFormCreateForm: React.FC<Props> = ({
  initialValues,
  isLoading,
  handleSubmitIndicatorValue,
  handleCancel,
}: Props) => {
  //#region VALIDATION
  const IndicatorValueValidation = Yup.object().shape({
    indicatorValues: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required(t('Indicator Value is required.'))
          .min(
            3,
            `${t('Indicator Value')} ${t(
              'must be filled in at least',
            )} ${3} ${t('letters')}.`,
          ),
      }),
    ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-indicator-value-create-form">
      <div className="master-data-indicator-value-create-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={IndicatorValueValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitIndicatorValue}>
          {({handleSubmit, values, isValid}): React.ReactElement => (
            <>
              <FieldArray
                name="indicatorValues"
                render={(arrayHelper: FieldArrayRenderProps): ReactElement => (
                  <div className="master-data-indicator-value-create-form__section">
                    {values.indicatorValues.map((_, index: number) => (
                      <div
                        key={`master-data-indicator-value-create-item-${index}`}
                        className="master-data-indicator-value-create-form__section--field">
                        <div className="input-label">
                          {`${t('Indicator Value')} ${index + 1}`}
                        </div>
                        <Field
                          className="user-input"
                          name={`indicatorValues.${index}.name`}
                          type="text"
                          placeholder={t('Enter Indicator Value')}
                          disabled={isLoading}
                          component={MTextField}
                        />
                      </div>
                    ))}

                    <div
                      className="master-data-indicator-value-create-form__form--add"
                      onClick={
                        isLoading
                          ? undefined
                          : () => arrayHelper.push({name: ''})
                      }>
                      + {t('Add Indicator Value')}
                    </div>
                  </div>
                )}
              />

              <div className="master-data-indicator-value-create-form__form--action">
                <div className="master-data-indicator-value-create-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="master-data-indicator-value-create-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={t('Add')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataIndicatorValueFormCreateForm;
