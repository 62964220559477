import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataReviewIndicatorCreateInitialState} from './masterDataReviewIndicatorCreate.reducer';
import {
  MasterDataReviewIndicatorCreateAction,
  MasterDataReviewIndicatorCreateFailedPayload,
  MasterDataReviewIndicatorCreateFetchPayload,
  MasterDataReviewIndicatorCreateSuccessPayload,
} from './masterDataReviewIndicatorCreate.type';

export const masterDataReviewIndicatorCreateHandler = {
  [MasterDataReviewIndicatorCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorCreateLoading: true,
    masterDataReviewIndicatorCreateParams: action.payload,
    action: MasterDataReviewIndicatorCreateAction.FETCH,
  }),
  [MasterDataReviewIndicatorCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorCreateParams: undefined,
    masterDataReviewIndicatorCreateLoading: false,
    masterDataReviewIndicatorCreateResponse: action.payload,
    action: MasterDataReviewIndicatorCreateAction.SUCCESS,
  }),
  [MasterDataReviewIndicatorCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataReviewIndicatorCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataReviewIndicatorCreateLoading: false,
    masterDataReviewIndicatorCreateError: action.payload,
    action: MasterDataReviewIndicatorCreateAction.FAILED,
  }),
  [MasterDataReviewIndicatorCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataReviewIndicatorCreateInitialState,
    action: MasterDataReviewIndicatorCreateAction.RESET,
  }),
};
