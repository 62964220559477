import React, {useRef} from 'react';
import {DatePicker} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {InputLabel, TextField} from '@mui/material';
import t from '../../lang';
import {IconCaretArrowDown} from '../../assets/icons';
import './MMonthpicker.component.style.scss';
import moment, {Moment} from 'moment';

interface Props {
  disabled?: boolean;
  className?: string;
  inputValue?: Moment | string | null;
  format?: string;
  label?: string;
  placeholder?: string;
  inputWidth?: string | number;
  handleChange?(date: Moment | string | null): void;
}

const MMonthpicker: React.FC<Props> = ({
  disabled = false,
  className,
  inputValue,
  format = 'MMM',
  label,
  placeholder = t('Month'),
  inputWidth = '14rem',
  handleChange,
}: Props) => {
  const datepickerRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={`m-monthpicker ${className}`} style={{width: inputWidth}}>
      {label ? (
        <InputLabel className="m-monthpicker__label">{label}</InputLabel>
      ) : null}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          disabled={disabled}
          inputFormat={format}
          showToolbar={false}
          showTodayButton={false}
          disableMaskedInput
          value={inputValue || null}
          views={['month']}
          OpenPickerButtonProps={{
            ref: datepickerRef,
          }}
          PaperProps={{className: 'm-monthpicker__paper'}}
          InputAdornmentProps={{style: {marginLeft: 0}}}
          onChange={(value) => {
            const date = moment(value, format);
            handleChange && handleChange(date);
          }}
          components={{OpenPickerIcon: IconCaretArrowDown}}
          renderInput={(params) => {
            const {inputProps} = params;
            const customInputProps = {
              ...inputProps,
              placeholder: placeholder,
              readOnly: true,
            };
            const customParams = {...params, inputProps: customInputProps};

            return (
              <TextField
                className="m-monthpicker__input"
                {...customParams}
                onClick={() =>
                  datepickerRef.current && datepickerRef.current.click()
                }
              />
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MMonthpicker;
