import {CommonError} from '../../../../../../config/constant';

export const MasterDataBasicServiceTypeMoveAction = {
  FETCH: 'MASTER_DATA_BASIC_SERVICE_TYPE_MOVE_FETCH',
  SUCCESS: 'MASTER_DATA_BASIC_SERVICE_TYPE_MOVE_SUCCESS',
  FAILED: 'MASTER_DATA_BASIC_SERVICE_TYPE_MOVE_FAILED',
  RESET: 'MASTER_DATA_BASIC_SERVICE_TYPE_MOVE_RESET',
};

export interface MasterDataBasicServiceTypeMoveInitialState {
  masterDataBasicServiceTypeMoveLoading: boolean;
  masterDataBasicServiceTypeMoveParams?: MasterDataBasicServiceTypeMoveFetchPayload;
  masterDataBasicServiceTypeMoveError?: MasterDataBasicServiceTypeMoveFailedPayload;
  masterDataBasicServiceTypeMoveResponse?: MasterDataBasicServiceTypeMoveSuccessPayload;
}

export interface MasterDataBasicServiceTypeMoveFetchPayload {
  id: string;
  isUp?: boolean;
  isDown?: boolean;
  assignmentType: string;
}
export type MasterDataBasicServiceTypeMoveFailedPayload = CommonError;

export interface MasterDataBasicServiceTypeMoveSuccessPayload {}
