//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
// Components
import {MTabPanel, MTabs} from '../../../../components';
import UserAccessManagementRoleSection from '../UserAccessManagementRoleSection';
import UserAccessManagementUserSection from '../UserAccessManagementUserSection';
// Data
import {PATH} from '../../../wrapper/entity';
// Assets
import './UserAccessManagement.scss';
//#endregion

const UserAccessManagement: React.FC = () => {
  //#region GENERAL
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  //#endregion

  //#region HANDLE TAB
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };
  //#endregion

  if (!data?.role?.userAccessManagement) return <Redirect to={PATH.HOME} />;
  return (
    <div className="user-access-management">
      <div className="user-access-management__tab">
        <MTabs
          tabsValue={tabValue}
          handleChangeTab={handleChangeTab}
          tabLabel={[t('User Access'), t('Role Access')]}
        />
      </div>
      <div className="user-access-management__tab-panel">
        <MTabPanel
          value={tabValue}
          index={0}
          className="user-access-management__tab-panel--item">
          <UserAccessManagementUserSection />
        </MTabPanel>
        <MTabPanel
          value={tabValue}
          index={1}
          className="user-access-management__tab-panel--item">
          <UserAccessManagementRoleSection />
        </MTabPanel>
      </div>
    </div>
  );
};

export default UserAccessManagement;
