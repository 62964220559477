import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDirectorateCreateInitialState} from './masterDataDirectorateCreate.reducer';
import {
  MasterDataDirectorateCreateAction,
  MasterDataDirectorateCreateFailedPayload,
  MasterDataDirectorateCreateFetchPayload,
  MasterDataDirectorateCreateSuccessPayload,
} from './masterDataDirectorateCreate.type';

export const masterDataDirectorateCreateHandler = {
  [MasterDataDirectorateCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateCreateLoading: true,
    masterDataDirectorateCreateParams: action.payload,
    action: MasterDataDirectorateCreateAction.FETCH,
  }),
  [MasterDataDirectorateCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateCreateParams: undefined,
    masterDataDirectorateCreateLoading: false,
    masterDataDirectorateCreateResponse: action.payload,
    action: MasterDataDirectorateCreateAction.SUCCESS,
  }),
  [MasterDataDirectorateCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDirectorateCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDirectorateCreateLoading: false,
    masterDataDirectorateCreateError: action.payload,
    action: MasterDataDirectorateCreateAction.FAILED,
  }),
  [MasterDataDirectorateCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDirectorateCreateInitialState,
    action: MasterDataDirectorateCreateAction.RESET,
  }),
};
