import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userAccessSetActiveInitialState} from './userAccessSetActive.reducer';
import {
  UserAccessSetActiveAction,
  UserAccessSetActiveFailedPayload,
  UserAccessSetActiveFetchPayload,
  UserAccessSetActiveSuccessPayload,
} from './userAccessSetActive.type';

export const userAccessSetActiveHandler = {
  [UserAccessSetActiveAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserAccessSetActiveFetchPayload>,
  ): UserState => ({
    ...state,
    userAccessSetActiveLoading: true,
    userAccessSetActiveParams: action.payload,
    action: UserAccessSetActiveAction.FETCH,
  }),
  [UserAccessSetActiveAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserAccessSetActiveSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccessSetActiveParams: undefined,
    userAccessSetActiveLoading: false,
    userAccessSetActiveResponse: action.payload,
    action: UserAccessSetActiveAction.SUCCESS,
  }),
  [UserAccessSetActiveAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserAccessSetActiveFailedPayload>,
  ): UserState => ({
    ...state,
    userAccessSetActiveLoading: false,
    userAccessSetActiveError: action.payload,
    action: UserAccessSetActiveAction.FAILED,
  }),
  [UserAccessSetActiveAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userAccessSetActiveInitialState,
    action: UserAccessSetActiveAction.RESET,
  }),
};
