import {
  EvaluationReportListAction,
  EvaluationReportListSuccessPayload,
  EvaluationReportListFailedPayload,
  EvaluationReportListFetchPayload,
  EvaluationReportListUpdatePayload,
} from './evaluationReportList.type';
import {EvaluationReportState} from '../evaluationReport.reducer';
import {GenericAction} from '../../../../config/constant';
import {evaluationReportListInitialState} from './evaluationReportList.reducer';

export const evaluationReportListHandler = {
  [EvaluationReportListAction.FETCH]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportListFetchPayload>,
  ): EvaluationReportState => {
    return {
      ...state,
      evaluationReportListLoading: true,
      evaluationReportListParams: action.payload,
      action: EvaluationReportListAction.FETCH,
    };
  },
  [EvaluationReportListAction.SUCCESS]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportListSuccessPayload>,
  ): EvaluationReportState => ({
    ...state,
    evaluationReportListLoading: false,
    evaluationReportListResponse: action.payload,
    action: EvaluationReportListAction.SUCCESS,
  }),
  [EvaluationReportListAction.UPDATE]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportListUpdatePayload>,
  ): EvaluationReportState => ({
    ...state,
    evaluationReportListLoading: false,
    evaluationReportListData: [
      ...state.evaluationReportListData,
      ...action.payload,
    ],
    action: EvaluationReportListAction.UPDATE,
  }),
  [EvaluationReportListAction.FAILED]: (
    state: EvaluationReportState,
    action: GenericAction<EvaluationReportListFailedPayload>,
  ): EvaluationReportState => ({
    ...state,
    evaluationReportListLoading: false,
    evaluationReportListError: action.payload,
    action: EvaluationReportListAction.FAILED,
  }),
  [EvaluationReportListAction.RESET]: (
    state: EvaluationReportState,
  ): EvaluationReportState => ({
    ...state,
    ...evaluationReportListInitialState,
    action: EvaluationReportListAction.RESET,
  }),
};
