//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Components
import FormControllerManagementListTableHeader from '../FormControllerManagementListTableHeader';
import FormControllerManagementListTableItem from '../FormControllerManagementListTableItem';
// Data
import {MasterDataFormController} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  formControllerList: MasterDataFormController[];
  onEditFormController?(_formController: MasterDataFormController): void;
  onDeleteFormController?(_formController: MasterDataFormController): void;
  onMoveUp?(id: string): void;
  onMoveDown?(id: string): void;
}
//#endregion

const FormControllerManagementList: React.FC<Props> = ({
  formControllerList,
  onEditFormController,
  onDeleteFormController,
  onMoveDown,
  onMoveUp,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        aria-label="collapsible table"
        className="master-data-management-list__container-table"
        style={{tableLayout: 'fixed'}}>
        <FormControllerManagementListTableHeader />
        <TableBody>
          {formControllerList.map(
            (item: MasterDataFormController, index: number) => (
              <FormControllerManagementListTableItem
                {...(onEditFormController && {
                  onEditItem: () => onEditFormController(item),
                })}
                {...(onDeleteFormController && {
                  onDeleteItem: () => onDeleteFormController(item),
                })}
                {...(onMoveUp && {
                  onMoveUpItem: () => onMoveUp(item.id),
                })}
                {...(onMoveDown && {
                  onMoveDownItem: () => onMoveDown(item.id),
                })}
                key={`form-controller-list-item-data-${item.id}-${index}`}
                data={item}
                index={index + 1}
                isFirst={index === 0}
                isLast={index === formControllerList.length - 1}
              />
            ),
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormControllerManagementList;
