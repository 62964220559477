//#region IMPORT

import React from 'react';
import {Modal, MButton} from '../../components';

import './AlertDialog.scss';
//#endregion

interface Props {
  image?: React.ReactElement;
  isOpen: boolean;
  title?: string;
  description?: string;
  confirmText: string;
  cancelText?: string;
  isReverseButton?: boolean;
  isLoading?: boolean;
  onClose(): void;
  onCancel?(): void;
  onConfirm(): void;
}

const AlertDialog: React.FC<Props> = ({
  image,
  isOpen,
  title,
  description,
  confirmText,
  cancelText,
  isReverseButton,
  isLoading,
  onClose,
  onCancel,
  onConfirm,
}: Props) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} title={title} maxWidth="xs">
      <div className="alert-dialog">
        {image && <div className="alert-dialog__image">{image}</div>}
        {description && (
          <div>
            <h6 className="alert-dialog__description">{description}</h6>
          </div>
        )}
        <div
          className="alert-dialog__form--action"
          style={{flexDirection: isReverseButton ? 'row-reverse' : 'row'}}>
          <div
            className={`alert-dialog-form-action${
              !isReverseButton ? '__highlight' : ''
            }`}>
            <MButton
              buttonLabel={confirmText}
              handleClick={onConfirm}
              isLoading={isLoading}
            />
          </div>
          {cancelText && onCancel && (
            <div
              className={`alert-dialog-form-action${
                isReverseButton ? '__highlight' : ''
              }`}>
              <MButton buttonLabel={cancelText} handleClick={onCancel} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AlertDialog;
