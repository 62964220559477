import Box from '@mui/material/Box';
import React from 'react';
import './MTabPanel.scss';

interface Props {
  value: number;
  index: number;
  children: React.ReactElement;
  className?: string;
}

const MTabPanel: React.FC<Props> = ({
  value,
  index,
  children,
  className,
}: Props) => {
  const isHidden = value !== index;
  return (
    <div
      role="tabpanel"
      hidden={isHidden}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}>
      {!isHidden && (
        <Box className={`m-tab-panel ${className ?? ''}`} sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
};

export default MTabPanel;
