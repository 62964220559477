//#region IMPORT
// Libraries
import React from 'react';
// Assets
import './DashboardTooltip.component.style.scss';
//#endregion

//#region INTERFACE
export interface dataTable {
  name: string;
  value: string | number;
}
interface Props {
  data: dataTable[];
}
//#endregion

const DashboardTooltip: React.FC<Props> = ({data}: Props) => {
  return (
    <div className="dashboard-tooltip">
      <table>
        <tbody>
          {data.map((tableItem: dataTable, idx: number) => (
            <tr key={`dashboard-tooltip-item${idx}`}>
              <td>{tableItem.name}</td>
              <td>{tableItem.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default DashboardTooltip;
