import {GenericAction} from '../../../../config/constant';
import {FollowUpState} from '../followUp.reducer';
import {followUpPICCompleteInitialState} from './followUpPICComplete.reducer';
import {
  FollowUpPICCompleteAction,
  FollowUpPICCompleteFailedPayload,
  FollowUpPICCompleteFetchPayload,
  FollowUpPICCompleteSuccessPayload,
} from './followUpPICComplete.type';

export const followUpPICCompleteHandler = {
  [FollowUpPICCompleteAction.FETCH]: (
    state: FollowUpState,
    action: GenericAction<FollowUpPICCompleteFetchPayload>,
  ): FollowUpState => ({
    ...state,
    followUpPICCompleteLoading: true,
    followUpPICCompleteParams: action.payload,
    action: FollowUpPICCompleteAction.FETCH,
  }),
  [FollowUpPICCompleteAction.SUCCESS]: (
    state: FollowUpState,
    action: GenericAction<FollowUpPICCompleteSuccessPayload>,
  ): FollowUpState => ({
    ...state,
    followUpPICCompleteParams: undefined,
    followUpPICCompleteLoading: false,
    followUpPICCompleteResponse: action.payload,
    action: FollowUpPICCompleteAction.SUCCESS,
  }),
  [FollowUpPICCompleteAction.FAILED]: (
    state: FollowUpState,
    action: GenericAction<FollowUpPICCompleteFailedPayload>,
  ): FollowUpState => ({
    ...state,
    followUpPICCompleteLoading: false,
    followUpPICCompleteError: action.payload,
    action: FollowUpPICCompleteAction.FAILED,
  }),
  [FollowUpPICCompleteAction.RESET]: (state: FollowUpState): FollowUpState => ({
    ...state,
    ...followUpPICCompleteInitialState,
    action: FollowUpPICCompleteAction.RESET,
  }),
};
