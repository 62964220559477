import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userAccountEditInitialState} from './userAccountEdit.reducer';
import {
  UserAccountEditAction,
  UserAccountEditFailedPayload,
  UserAccountEditFetchPayload,
  UserAccountEditSuccessPayload,
} from './userAccountEdit.type';

export const userAccountEditHandler = {
  [UserAccountEditAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserAccountEditFetchPayload>,
  ): UserState => ({
    ...state,
    userAccountEditLoading: true,
    userAccountEditParams: action.payload,
    action: UserAccountEditAction.FETCH,
  }),
  [UserAccountEditAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserAccountEditSuccessPayload>,
  ): UserState => ({
    ...state,
    userAccountEditParams: undefined,
    userAccountEditLoading: false,
    userAccountEditResponse: action.payload,
    action: UserAccountEditAction.SUCCESS,
  }),
  [UserAccountEditAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserAccountEditFailedPayload>,
  ): UserState => ({
    ...state,
    userAccountEditLoading: false,
    userAccountEditError: action.payload,
    action: UserAccountEditAction.FAILED,
  }),
  [UserAccountEditAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userAccountEditInitialState,
    action: UserAccountEditAction.RESET,
  }),
};
