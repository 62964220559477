import {
  DashboardTabDepartmentListAction,
  DashboardTabDepartmentListSuccessPayload,
  DashboardTabDepartmentListFailedPayload,
} from './dashboardTabDepartmentList.type';
import {DashboardState} from '../../../dashboard.reducer';
import {dashboardTabDepartmentListInitialState} from './dashboardTabDepartmentList.reducer';
import {GenericAction} from '../../../../../../config/constant';

export const dashboardTabDepartmentListHandler = {
  [DashboardTabDepartmentListAction.FETCH]: (
    state: DashboardState,
  ): DashboardState => {
    return {
      ...state,
      dashboardTabDepartmentListLoading: true,
      action: DashboardTabDepartmentListAction.FETCH,
    };
  },
  [DashboardTabDepartmentListAction.SUCCESS]: (
    state: DashboardState,
    action: GenericAction<DashboardTabDepartmentListSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardTabDepartmentListLoading: false,
    dashboardTabDepartmentListResponse: action.payload,
    action: DashboardTabDepartmentListAction.SUCCESS,
  }),
  [DashboardTabDepartmentListAction.UPDATE]: (
    state: DashboardState,
    action: GenericAction<DashboardTabDepartmentListSuccessPayload>,
  ): DashboardState => ({
    ...state,
    dashboardTabDepartmentListLoading: false,
    dashboardTabDepartmentListData: [
      ...state.dashboardTabDepartmentListData,
      ...action.payload.data,
    ],
    action: DashboardTabDepartmentListAction.UPDATE,
  }),
  [DashboardTabDepartmentListAction.FAILED]: (
    state: DashboardState,
    action: GenericAction<DashboardTabDepartmentListFailedPayload>,
  ): DashboardState => ({
    ...state,
    dashboardTabDepartmentListLoading: false,
    dashboardTabDepartmentListError: action.payload,
    action: DashboardTabDepartmentListAction.FAILED,
  }),
  [DashboardTabDepartmentListAction.RESET]: (
    state: DashboardState,
  ): DashboardState => ({
    ...state,
    ...dashboardTabDepartmentListInitialState,
    action: DashboardTabDepartmentListAction.RESET,
  }),
};
