import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataCategoryCreateInitialState} from './masterDataCategoryCreate.reducer';
import {
  MasterDataCategoryCreateAction,
  MasterDataCategoryCreateFailedPayload,
  MasterDataCategoryCreateFetchPayload,
  MasterDataCategoryCreateSuccessPayload,
} from './masterDataCategoryCreate.type';

export const masterDataCategoryCreateHandler = {
  [MasterDataCategoryCreateAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryCreateFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryCreateLoading: true,
    masterDataCategoryCreateParams: action.payload,
    action: MasterDataCategoryCreateAction.FETCH,
  }),
  [MasterDataCategoryCreateAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryCreateSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryCreateParams: undefined,
    masterDataCategoryCreateLoading: false,
    masterDataCategoryCreateResponse: action.payload,
    action: MasterDataCategoryCreateAction.SUCCESS,
  }),
  [MasterDataCategoryCreateAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryCreateFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryCreateLoading: false,
    masterDataCategoryCreateError: action.payload,
    action: MasterDataCategoryCreateAction.FAILED,
  }),
  [MasterDataCategoryCreateAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataCategoryCreateInitialState,
    action: MasterDataCategoryCreateAction.RESET,
  }),
};
