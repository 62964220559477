//#region IMPORT
// Libraries
import React, {useState} from 'react';
// Utils
import t from '../../../../lang';
// Components
import {MTabPanel, MTabs} from '../../../../components';
// Data
import {PermissionType, RolePermission} from '../../entity/user.string.entity';
// Assets
import RoleTableMobileApps from '../RoleTableMobileApps';
import RoleTableBackOffice from '../RoleTableBackOffice';
//#endregion

//#region INTERFACE
interface Props {
  isDisabled?: boolean;
  roleValues?: PermissionType;
  setFieldValue?: (
    field: string,
    value: RolePermission | boolean,
    shouldValidate?: boolean | undefined,
  ) => void;
}
//#endregion

const RoleTableWrapper: React.FC<Props> = ({
  isDisabled,
  setFieldValue,
  roleValues,
}: Props) => {
  //#region GENERAL
  const [tabValue, setTabValue] = useState(0);
  //#endregion

  //#region HANDLE TAB
  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };
  //#endregion

  return (
    <div className="role-table-wrapper">
      <div className="role-table-wrapper__tab">
        <MTabs
          tabsValue={tabValue}
          handleChangeTab={handleChangeTab}
          tabLabel={[t('Mobile Apps'), t('Back Office')]}
        />
      </div>
      <div className="role-table-wrapper__tab-panel">
        <MTabPanel
          value={tabValue}
          index={0}
          className="role-table-wrapper__tab-panel--item">
          <RoleTableMobileApps
            isDisabled={isDisabled}
            roleValues={roleValues?.mobilePermissions}
            setFieldValue={setFieldValue}
          />
        </MTabPanel>
        <MTabPanel
          value={tabValue}
          index={1}
          className="role-table-wrapper__tab-panel--item">
          <RoleTableBackOffice
            isDisabled={isDisabled}
            roleValues={roleValues?.backOfficePermissions}
            setFieldValue={setFieldValue}
          />
        </MTabPanel>
      </div>
    </div>
  );
};

export default RoleTableWrapper;
