import React, {ReactElement} from 'react';

//#region NOTIFICATION
export enum NotificationType {
  FOLLOWUP = 'follow-up',
}

export enum NotificationBadgeType {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  COMMENTED = 'COMMENTED',
  EXPIRED = 'EXPIRED',
}

export interface NotificationBadgeDetail {
  badge?: ReactElement;
  color?: string;
}

export interface NotificationManagement {
  id: string;
  date: string;
  notification: NotificationProps[];
}

export interface NotificationDetail {
  id: string;
  icon?: React.ReactElement;
  image?: string;
  markAsRead: boolean;
  notificationType: NotificationType;
  notificationBadgeType: NotificationBadgeType;
  title: string;
  desc: string;
  time: string;
}

export interface NotificationProps {
  id: string;
  notifSubject?: string;
  notifMessage?: string;
  notifDirection: string;
  notifImg?: string;
  notifType?: string;
  notifTypeDetail?: NotificationBadgeType | string;
  notifRead?: boolean;
  notifTime?: string;
  transFollowUpId?: string;
  icon?: React.ReactElement;
}

//#endregion
