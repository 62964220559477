import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataDepartment} from '../../../../entity/masterData.string.entity';

export const MasterDataDepartmentListAction = {
  FETCH: 'MASTER_DATA_DEPARTMENT_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DEPARTMENT_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DEPARTMENT_LIST_FAILED',
  RESET: 'MASTER_DATA_DEPARTMENT_LIST_RESET',
  UPDATE: 'MASTER_DATA_DEPARTMENT_LIST_UPDATE',
};

export interface MasterDataDepartmentListInitialState {
  masterDataDepartmentListLoading: boolean;
  masterDataDepartmentListData: MasterDataDepartment[];
  masterDataDepartmentListError?: MasterDataDepartmentListFailedPayload;
  masterDataDepartmentListParams?: MasterDataDepartmentListFetchPayload;
  masterDataDepartmentListResponse?: MasterDataDepartmentListSuccessPayload;
}

export type MasterDataDepartmentListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataDepartment[]>;
};

export type MasterDataDepartmentListFailedPayload = CommonError;

export type MasterDataDepartmentListSuccessPayload = MasterDataDepartmentListResponse;

export type MasterDataDepartmentListUpdatePayload = MasterDataDepartmentListResponse;

export interface MasterDataDepartmentListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
}
