import {createTheme} from '@mui/material/';
import {colors} from './_theme';

const theme = createTheme({
  //#region COLOR PALLETE
  palette: {
    primary: {
      main: '#0235c0',
      dark: '#002690',
      light: '#4c73df',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#262626',
      dark: '#676767',
      light: '#676767',
      contrastText: '#ffffff',
    },
    success: {
      main: '#4caf50',
      dark: '#3b873e',
      light: '#7bc67e',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#262626',
      secondary: '#676767',
      disabled: '#a5a5a5',
    },
    error: {
      main: '#f44336',
      dark: '#e31b0c',
      light: '#f88078',
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196f3',
      dark: '#0b79d0',
      light: '#64b6f7',
    },
    warning: {
      main: '#ff9800',
      dark: '#c77700',
      light: '#ffb547',
    },
    divider: '#e5e5e5',

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },

  typography: {
    fontFamily: `'Nunito', sans-serif`,
    body1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: '2.2rem',
      color: colors.text.primary,
      letterspacing: '0.01rem',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.5rem',
      lineHeight: '2.6rem',
      color: colors.text.primary,
      letterspacing: '0.015rem',
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.4rem',
      lineHeight: '2rem',
      color: colors.text.primary,
      letterspacing: '0.015rem',
    },
    overline: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.2rem',
      lineHeight: '3.2rem',
      color: colors.text.primary,
      letterspacing: '0.1rem',
      textTransform: 'uppercase',
    },
  },
  //#endregion
});

export default theme;
