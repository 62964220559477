import {createAction} from '@reduxjs/toolkit';
import {
  NotificationListAction,
  NotificationListFailedPayload,
  NotificationListFetchPayload,
  NotificationListSuccessPayload,
  NotificationListUpdatePayload,
} from './notificationList.type';

export const notificationListAction = {
  fetch: createAction<NotificationListFetchPayload>(
    NotificationListAction.FETCH,
  ),
  success: createAction<NotificationListSuccessPayload>(
    NotificationListAction.SUCCESS,
  ),
  failed: createAction<NotificationListFailedPayload>(
    NotificationListAction.FAILED,
  ),
  update: createAction<NotificationListUpdatePayload>(
    NotificationListAction.UPDATE,
  ),
  reset: createAction(NotificationListAction.RESET),
};
