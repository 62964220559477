import * as React from 'react';

const IconDummyImage = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 65}
    height={props.height || 64}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.866 9.054c-6.871 0-11.312 4.713-11.312 11.982V42.94c0 2.04.38 3.861 1.027 5.437.028-.033.64-.778 1.436-1.75 1.556-1.896 3.82-4.654 3.836-4.669 1.845-2.106 5.31-5.247 9.856-3.346.996.413 1.882.979 2.697 1.5l.236.15c1.529 1.022 2.427 1.502 3.36 1.422.387-.053.75-.168 1.094-.381 1.298-.8 4.662-5.568 5.67-6.995.147-.21.244-.347.276-.39 2.907-3.786 7.387-4.8 11.12-2.56.502.3 4.094 2.808 5.283 3.817v-14.14c0-7.268-4.44-11.98-11.336-11.98H20.866zm23.243-3.72c9.005 0 15.056 6.3 15.056 15.702V42.94c0 .236-.025.458-.05.68a8.214 8.214 0 00-.043.472c-.006.097-.009.194-.012.291-.003.13-.007.26-.018.389-.005.05-.015.1-.025.148-.01.048-.02.097-.025.148a18.152 18.152 0 01-.562 2.983l-.012.04c-.213.753-.463 1.47-.765 2.155-.053.116-.108.228-.164.34l-.11.226c-.326.653-.678 1.28-1.086 1.861-.075.108-.156.209-.236.31a9.57 9.57 0 00-.159.202c-.424.55-.866 1.075-1.368 1.552-.1.095-.208.182-.316.27-.068.054-.135.108-.2.165-.516.443-1.04.867-1.62 1.23-.126.078-.26.145-.394.211-.086.043-.172.086-.256.132-.59.326-1.184.643-1.832.888-.156.06-.322.102-.488.145-.119.03-.237.061-.352.098l-.173.053c-.579.18-1.156.36-1.78.467-.36.063-.743.087-1.127.112-.166.01-.332.02-.496.034-.177.014-.35.035-.523.056-.282.035-.565.07-.864.07H20.866c-1.002 0-1.96-.102-2.885-.254l-.099-.016c-3.608-.62-6.602-2.362-8.7-4.989-.015 0-.021-.01-.029-.023a.125.125 0 00-.02-.028c-2.11-2.655-3.301-6.226-3.301-10.418V21.036c0-9.403 6.056-15.702 15.034-15.702H44.11zM29.833 22.707c0 3.613-3.023 6.626-6.653 6.626-3.192 0-5.913-2.331-6.522-5.35a6.422 6.422 0 01-.158-1.4c0-3.639 2.956-6.583 6.61-6.583 1.86 0 3.545.782 4.752 2.03 1.212 1.205 1.971 2.865 1.971 4.677z"
      fill={props.fill || '#EBEBEB'}
    />
  </svg>
);

export default IconDummyImage;
