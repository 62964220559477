import * as React from 'react';

const IconSuccess = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 80}
    height={props.height || 80}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#a)" fill={props.fill || '#4A90E2'}>
      <path d="M37.714 51.678a2.34 2.34 0 0 1-1.797-.816L23.587 37.47c-.898-.98-.817-2.532.163-3.43.98-.898 2.532-.816 3.43.163l10.615 11.514 29.397-29.315c.98-.98 2.532-.98 3.43 0 .898.98.98 2.531 0 3.43L39.43 50.942c-.409.49-1.062.735-1.715.735Z" />
      <path d="M40 72.5C22.117 72.5 7.5 57.883 7.5 40S22.117 7.5 40 7.5c1.388 0 2.45 1.062 2.45 2.45 0 1.388-1.062 2.45-2.45 2.45-15.189 0-27.6 12.411-27.6 27.6 0 15.188 12.411 27.6 27.6 27.6C55.19 67.6 67.6 55.19 67.6 40c0-1.388 1.062-2.45 2.45-2.45 1.388 0 2.45 1.062 2.45 2.45 0 17.883-14.617 32.5-32.5 32.5Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(7.5 7.5)" d="M0 0h65v65H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default IconSuccess;
