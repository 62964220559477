import {CommonError} from '../../../../../../config/constant';
import {MasterDataDepartmentOption} from '../../../../entity/masterData.string.entity';

export const MasterDataDepartmentOptionListAction = {
  FETCH: 'MASTER_DATA_DEPARTMENT_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_DEPARTMENT_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_DEPARTMENT_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_DEPARTMENT_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_DEPARTMENT_OPTION_LIST_UPDATE',
};

export interface MasterDataDepartmentOptionListInitialState {
  masterDataDepartmentOptionListLoading: boolean;
  masterDataDepartmentOptionListData: MasterDataDepartmentOption[];
  masterDataDepartmentOptionListError?: MasterDataDepartmentOptionListFailedPayload;
  masterDataDepartmentOptionListParams?: MasterDataDepartmentOptionListFetchPayload;
  masterDataDepartmentOptionListResponse?: MasterDataDepartmentOptionListSuccessPayload;
}

export type MasterDataDepartmentOptionListResponse = {
  status: string;
  count?: number;
  data: MasterDataDepartmentOption[];
};

export type MasterDataDepartmentOptionListFailedPayload = CommonError;

export type MasterDataDepartmentOptionListSuccessPayload = MasterDataDepartmentOptionListResponse;

export type MasterDataDepartmentOptionListUpdatePayload = MasterDataDepartmentOptionListResponse;

export interface MasterDataDepartmentOptionListFetchPayload {
  divisionId: string;
}
