/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {
  MasterDataDivisionCreateAction,
  MasterDataDivisionDeleteAction,
  MasterDataDivisionEditAction,
  MasterDataDivisionListAction,
} from '../../../../usecases/masterData.type';
import {
  masterDataDivisionCreateAction,
  masterDataDivisionEditAction,
  masterDataDivisionListAction,
} from '../../../../usecases/masterData.action';
import {masterDataDivisionDeleteAction} from '../../../../usecases/masterData.action';
import {actionHandler} from '../../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
import {SortTable} from '../../../../../../config/constant';
// Components
import {
  MButton,
  MSearchInput,
  Modal,
  AlertDialog,
  MTablePagination,
} from '../../../../../../components';
import {MasterDataDivisionManagementList} from './components';
import MasterDataDivisionFormCreateContainer from '../MasterDataDivisionFormCreateContainer';
import MasterDataDivisionFormEditContainer from '../MasterDataDivisionFormEditContainer';
// Data
import {
  MasterDataDivision,
  MasterDataDivisionOrderByType,
} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataDivisionSection.scss';
import {IllustrationTrashImage} from '../../../../../../assets/illustrations';
//#endregion

const MasterDataDivisionSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [isAddDivision, setAddDivision] = useState(false);
  const [isDeleteDivision, setIsDeleteDivision] = useState<
    MasterDataDivision | undefined
  >(undefined);
  const [isEditDivision, setIsEditDivision] = useState<
    MasterDataDivision | undefined
  >(undefined);
  const [isCancelForm, setIsCancelForm] = useState<
    'Create' | 'Edit' | undefined
  >(undefined);

  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;

  const isLoadingAddDivision = masterData.masterDataDivisionCreateLoading;
  const isLoadingEditDivision = masterData.masterDataDivisionEditLoading;
  const isLoadingDeleteDivision = masterData.masterDataDivisionDeleteLoading;
  //#endregion

  //#region PAGINATION CONTROL
  const [
    masterDataDivisionListPage,
    setMasterDataDivisionListPage,
  ] = useState<number>(0);

  const [
    masterDataDivisionListCount,
    setMasterDataDivisionListCount,
  ] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_: unknown, _page: number) => {
    setMasterDataDivisionListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setMasterDataDivisionListPage(0);
  };
  //#endregion

  //#region SORT CONTROL
  const [sortBy, setSortBy] = useState<SortTable>('asc');
  const [orderBy, setOrderBy] = useState<MasterDataDivisionOrderByType>('name');

  const handleSort = (
    event: React.MouseEvent<unknown>,
    property: MasterDataDivisionOrderByType,
  ) => {
    const isAsc = orderBy === property && sortBy === 'asc';
    setSortBy(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region FETCH DIVISION
  const fetchDivisionList = useCallback(
    (page?: number, search?: string, size?: number) => {
      dispatch(masterDataDivisionListAction.fetch({page, search, size}));
    },
    [dispatch],
  );
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataDivisionListAction.reset());
    fetchDivisionList(masterDataDivisionListPage, searchValue, rowsPerPage);

    return () => {
      dispatch(masterDataDivisionListAction.reset());
    };
  }, [
    dispatch,
    fetchDivisionList,
    masterDataDivisionListPage,
    searchValue,
    rowsPerPage,
  ]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const masterDataDivisionActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(MasterDataDivisionListAction.UPDATE, (): void => {
            setMasterDataDivisionListCount(
              masterData?.masterDataDivisionListResponse?.data?.totalElements ??
                0,
            );
          })
          .addCase(MasterDataDivisionListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDivisionListAction.reset());
          })
          .addCase(MasterDataDivisionCreateAction.SUCCESS, (): void => {
            setAddDivision(false);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data added success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDivisionCreateAction.FAILED, (): void => {
            const errorMessage = masterData.masterDataDivisionCreateError?.info
              ?.message
              ? t(masterData.masterDataDivisionCreateError?.info?.message)
              : t('Data added failed!');
            dispatch(
              wrapperNotifyAction.fetch({
                text: errorMessage,
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDivisionCreateAction.reset());
          })
          .addCase(MasterDataDivisionEditAction.SUCCESS, (): void => {
            setIsEditDivision(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data saved success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDivisionEditAction.FAILED, (): void => {
            const errorMessage = masterData.masterDataDivisionEditError?.info
              ?.message
              ? t(masterData.masterDataDivisionEditError?.info?.message)
              : t('Data saved failed!');
            dispatch(
              wrapperNotifyAction.fetch({
                text: errorMessage,
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDivisionEditAction.reset());
          })
          .addCase(MasterDataDivisionDeleteAction.SUCCESS, (): void => {
            setIsDeleteDivision(undefined);
            refresh();
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data deleted success!'),
                type: 'success',
                action: WrapperNotifyAction.FETCH,
              }),
            );
          })
          .addCase(MasterDataDivisionDeleteAction.FAILED, (): void => {
            const errorMessage = masterData.masterDataDivisionDeleteError?.info
              ?.message
              ? t(masterData.masterDataDivisionDeleteError?.info?.message)
              : t('Data deleted failed!');
            dispatch(
              wrapperNotifyAction.fetch({
                text: errorMessage,
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(masterDataDivisionDeleteAction.reset());
          });
      });
    },
    [
      dispatch,
      masterData.masterDataDivisionCreateError,
      masterData.masterDataDivisionEditError,
      masterData.masterDataDivisionDeleteError,
      masterData?.masterDataDivisionListResponse?.data?.totalElements,
      refresh,
    ],
  );
  useEffect(() => {
    masterDataDivisionActionHandler(masterData.action);
  }, [masterData.action, masterDataDivisionActionHandler]);
  //#endregion

  //#region HANDLE DELETE
  const handleDelete = (id: string) => {
    dispatch(masterDataDivisionDeleteAction.fetch({id}));
  };
  //#endregion

  return (
    <>
      <div className="master-data-division-section">
        <div className="master-data-division-section__header">
          <MSearchInput
            inputClassName="master-data-division-section__header--search"
            inputValue={searchValue}
            placeholder={t('Search Master Data Division')}
            setInputValue={(search: string) => {
              setMasterDataDivisionListPage(0);
              setSearchValue(search);
            }}
          />

          {permissions?.masterOrganization.havePermissionCreate && (
            <div className="master-data-division-section__header--add-action">
              <MButton
                buttonLabel={t('Add')}
                handleClick={() => setAddDivision(true)}
              />
            </div>
          )}
        </div>
        <MasterDataDivisionManagementList
          divisionList={masterData.masterDataDivisionListData}
          sortBy={sortBy}
          orderBy={orderBy}
          onHandleSort={handleSort}
          {...(permissions?.masterOrganization.havePermissionUpdate && {
            onEditDivision: (_division: MasterDataDivision) =>
              setIsEditDivision(_division),
          })}
          {...(permissions?.masterOrganization.havePermissionDelete && {
            onDeleteDivision: (_division: MasterDataDivision) =>
              setIsDeleteDivision(_division),
          })}
        />
        <MTablePagination
          className="master-data-division-list-pagination"
          rowsPerPageOptions={[25, 50, 100]}
          count={masterDataDivisionListCount}
          rowsPerPage={rowsPerPage}
          page={masterDataDivisionListPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <Modal
        onClose={() => setAddDivision(false)}
        title={t('Add Division')}
        isOpen={isAddDivision}>
        <MasterDataDivisionFormCreateContainer
          isLoading={isLoadingAddDivision}
          onCancel={() => setIsCancelForm('Create')}
        />
      </Modal>

      {isEditDivision && (
        <Modal
          onClose={() => setIsEditDivision(undefined)}
          isOpen={!!isEditDivision}
          title={t('Edit Division')}>
          <MasterDataDivisionFormEditContainer
            data={isEditDivision}
            isLoading={isLoadingEditDivision}
            onCancel={() => setIsCancelForm('Edit')}
          />
        </Modal>
      )}

      {isDeleteDivision && (
        <AlertDialog
          onClose={() => setIsDeleteDivision(undefined)}
          onCancel={() => setIsDeleteDivision(undefined)}
          onConfirm={() => {
            handleDelete(isDeleteDivision.id);
          }}
          cancelText={t('Cancel')}
          confirmText={t('Delete')}
          isOpen={!!isDeleteDivision}
          isLoading={isLoadingDeleteDivision}
          image={<IllustrationTrashImage />}
          description={t('Are you sure you want to delete the data?')}
        />
      )}

      {isCancelForm && (
        <AlertDialog
          onClose={() => setIsCancelForm(undefined)}
          onCancel={() => setIsCancelForm(undefined)}
          onConfirm={() => {
            isCancelForm === 'Create'
              ? setAddDivision(false)
              : setIsEditDivision(undefined);
            setIsCancelForm(undefined);
          }}
          cancelText={t('Back')}
          confirmText={t('Yes, Cancel')}
          isOpen={!!isCancelForm}
          title={t('Are you sure?')}
          description={t('Process will not be saved')}></AlertDialog>
      )}
    </>
  );
};

export default MasterDataDivisionSection;
