import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../../../config/Config';
import {CommonError, GenericAction} from '../../../../../../config/constant';
import {AppState} from '../../../../../../config/Interface';
import {get} from '../../../../../../services/http';
import {generateHeaders} from '../../../../../../utils';
import {UserState} from '../../../../../user/usecases/user.reducer';
import {masterDataWorkInstructionListAction} from './masterDataWorkInstructionList.action';
import {
  MasterDataWorkInstructionListAction,
  MasterDataWorkInstructionListFetchPayload,
  MasterDataWorkInstructionListResponse,
  MasterDataWorkInstructionListSuccessPayload,
} from './masterDataWorkInstructionList.type';

function* workerFetch(
  action: GenericAction<MasterDataWorkInstructionListFetchPayload>,
) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<MasterDataWorkInstructionListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/assignment-type/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        masterDataWorkInstructionListAction.success(
          res.data as MasterDataWorkInstructionListResponse,
        ),
      );
      yield put(
        masterDataWorkInstructionListAction.update(
          res.data as MasterDataWorkInstructionListResponse,
        ),
      );
    } else {
      yield put(
        masterDataWorkInstructionListAction.failed(res.data as CommonError),
      );
    }
  } catch (e) {
    yield put(masterDataWorkInstructionListAction.failed(e as CommonError));
  }
}

const masterDataWorkInstructionListWatcher = [
  takeLatest(MasterDataWorkInstructionListAction.FETCH, workerFetch),
];

export default masterDataWorkInstructionListWatcher;
