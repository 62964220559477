import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataObservationDeleteInitialState} from './masterDataObservationDelete.reducer';
import {
  MasterDataObservationDeleteAction,
  MasterDataObservationDeleteFailedPayload,
  MasterDataObservationDeleteFetchPayload,
  MasterDataObservationDeleteSuccessPayload,
} from './masterDataObservationDelete.type';

export const masterDataObservationDeleteHandler = {
  [MasterDataObservationDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationDeleteLoading: true,
    masterDataObservationDeleteParams: action.payload,
    action: MasterDataObservationDeleteAction.FETCH,
  }),
  [MasterDataObservationDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationDeleteParams: undefined,
    masterDataObservationDeleteLoading: false,
    masterDataObservationDeleteResponse: action.payload,
    action: MasterDataObservationDeleteAction.SUCCESS,
  }),
  [MasterDataObservationDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataObservationDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataObservationDeleteLoading: false,
    masterDataObservationDeleteError: action.payload,
    action: MasterDataObservationDeleteAction.FAILED,
  }),
  [MasterDataObservationDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataObservationDeleteInitialState,
    action: MasterDataObservationDeleteAction.RESET,
  }),
};
