import {CommonError} from '../../../../../../config/constant';

export const MasterDataRouteDeleteAction = {
  FETCH: 'MASTER_DATA_ROUTE_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_ROUTE_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_ROUTE_DELETE_FAILED',
  RESET: 'MASTER_DATA_ROUTE_DELETE_RESET',
};

export interface MasterDataRouteDeleteInitialState {
  masterDataRouteDeleteLoading: boolean;
  masterDataRouteDeleteParams?: MasterDataRouteDeleteFetchPayload;
  masterDataRouteDeleteError?: MasterDataRouteDeleteFailedPayload;
  masterDataRouteDeleteResponse?: MasterDataRouteDeleteSuccessPayload;
}

export interface MasterDataRouteDeleteFetchPayload {
  id: string;
}
export type MasterDataRouteDeleteFailedPayload = CommonError;

export interface MasterDataRouteDeleteSuccessPayload {}
