import React, {ReactElement} from 'react';
import {
  ImageBusBRT,
  ImageBusKecil,
  ImageBusNonBRT,
  ImageHalte,
  ImageOffice,
  ImageRute,
  ImageTranscare,
} from '../../../assets/images';
import {SelectItemType} from '../../../components/MSelect/MSelect.component';
import {
  MasterDataBasicServiceType,
  MasterDataCategory,
  MasterDataDepartment,
  MasterDataDetailIndicator,
  MasterDataDetailObservation,
  MasterDataIndicatorValue,
  MasterDataManagementData,
  MasterDataObservation,
  MasterDataObservationValue,
  MasterDataReviewIndicator,
} from '../entity/masterData.string.entity';

export const masterDataManagementDataToIcon = (
  masterDataType: MasterDataManagementData,
): string | ReactElement => {
  const obj: {[key: string]: string | ReactElement} = {
    [MasterDataManagementData.HALTE]: <ImageHalte />,
    [MasterDataManagementData.BUS]: <ImageBusBRT />,
    [MasterDataManagementData.RUTE]: <ImageRute />,
    [MasterDataManagementData.BUSBRTTENGAH]: <ImageBusBRT />,
    [MasterDataManagementData.BUSBRTUJUNG]: <ImageBusBRT />,
    [MasterDataManagementData.BUSNONBRTTENGAH]: <ImageBusNonBRT />,
    [MasterDataManagementData.BUSNONBRTUJUNG]: <ImageBusNonBRT />,
    [MasterDataManagementData.BUSKECILTENGAH]: <ImageBusKecil />,
    [MasterDataManagementData.BUSKECILUJUNG]: <ImageBusKecil />,
    [MasterDataManagementData.TRANSCARE]: <ImageTranscare />,
    [MasterDataManagementData.ORGANIZATION]: <ImageOffice />,
    [MasterDataManagementData.INSTRUKSIKERJA]: <ImageOffice />,
    [MasterDataManagementData.SURATTUGAS]: <ImageOffice />,
    DEFAULT: <ImageBusBRT />,
  };
  return obj[masterDataType] || obj.DEFAULT;
};

export const masterDataManagementDataToLabel = (
  masterDataType: string | MasterDataManagementData,
): string => {
  const val: MasterDataManagementData =
    MasterDataManagementData[
      masterDataType as keyof typeof MasterDataManagementData
    ];
  const obj: {[key: string]: string} = {
    [MasterDataManagementData.HALTE]: 'Bus Stop Form',
    [MasterDataManagementData.BUSBRTTENGAH]: 'Middle Bus BRT Form',
    [MasterDataManagementData.BUSBRTUJUNG]: 'End Bus BRT Form',
    [MasterDataManagementData.BUSNONBRTTENGAH]: 'Middle Bus Non BRT Form',
    [MasterDataManagementData.BUSNONBRTUJUNG]: 'End Bus Non BRT Form',
    [MasterDataManagementData.BUSKECILTENGAH]: 'Middle Mini Bus Form',
    [MasterDataManagementData.BUSKECILUJUNG]: 'End Mini Bus Form',
    [MasterDataManagementData.TRANSCARE]: 'Transcare Form',
    DEFAULT: 'Bus Stop Form',
  };
  return obj[val] || obj.DEFAULT;
};

export const getOptionFromDepartmentList = (
  data: MasterDataDepartment[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromCategoryList = (
  data: MasterDataCategory[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromObservationList = (
  data: MasterDataObservation[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromObservationValueList = (
  data: MasterDataObservationValue[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromDetailObservationList = (
  data: MasterDataDetailObservation[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromBasicServiceTypeList = (
  data: MasterDataBasicServiceType[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromReviewIndicatorList = (
  data: Pick<MasterDataReviewIndicator, 'id' | 'assignmentType' | 'name'>[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id || ''}));
};

export const getOptionFromDetailIndicatorList = (
  data: MasterDataDetailIndicator[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromIndicatorValueList = (
  data: MasterDataIndicatorValue[],
): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item.name, value: item.id}));
};

export const getOptionFromBusTypeList = (data: string[]): SelectItemType[] => {
  if (!data) return [];
  return data.map((item) => ({label: item, value: item}));
};
