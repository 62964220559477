import {createReducer} from '@reduxjs/toolkit';
import wrapperHandler from './wrapper.handler';
import {wrapperNotifyInitialState} from './WrapperNotify/wrapperNotify.reducer';
import {WrapperNotifyInitialState} from './WrapperNotify/wrapperNotify.type';
export interface WrapperState extends WrapperNotifyInitialState {
  action: string;
}

export const wrapperInitialState: WrapperState = {
  ...wrapperNotifyInitialState,
  action: '',
};

export default createReducer(wrapperInitialState, wrapperHandler);
