import {CommonError} from '../../../../../../config/constant';
import {MasterDataObservationValue} from '../../../../entity/masterData.string.entity';

export const MasterDataObservationValueOptionListAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_VALUE_OPTION_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_VALUE_OPTION_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_VALUE_OPTION_LIST_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_VALUE_OPTION_LIST_RESET',
  UPDATE: 'MASTER_DATA_OBSERVATION_VALUE_OPTION_LIST_UPDATE',
};

export interface MasterDataObservationValueOptionListInitialState {
  masterDataObservationValueOptionListLoading: boolean;
  masterDataObservationValueOptionListData: Omit<
    MasterDataObservationValue,
    'isPositiveValue'
  >[];
  masterDataObservationValueOptionListError?: MasterDataObservationValueOptionListFailedPayload;
  masterDataObservationValueOptionListParams?: MasterDataObservationValueOptionListFetchPayload;
  masterDataObservationValueOptionListResponse?: MasterDataObservationValueOptionListSuccessPayload;
}

export type MasterDataObservationValueOptionListResponse = {
  status: string;
  count?: number;
  data: Omit<MasterDataObservationValue, 'isPositiveValue'>[];
};

export type MasterDataObservationValueOptionListFailedPayload = CommonError;

export type MasterDataObservationValueOptionListSuccessPayload = MasterDataObservationValueOptionListResponse;

export type MasterDataObservationValueOptionListUpdatePayload = MasterDataObservationValueOptionListResponse;

export interface MasterDataObservationValueOptionListFetchPayload {
  assignmentType: string;
}
