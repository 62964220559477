import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataBasicServiceTypeDeleteInitialState} from './masterDataBasicServiceTypeDelete.reducer';
import {
  MasterDataBasicServiceTypeDeleteAction,
  MasterDataBasicServiceTypeDeleteFailedPayload,
  MasterDataBasicServiceTypeDeleteFetchPayload,
  MasterDataBasicServiceTypeDeleteSuccessPayload,
} from './masterDataBasicServiceTypeDelete.type';

export const masterDataBasicServiceTypeDeleteHandler = {
  [MasterDataBasicServiceTypeDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeDeleteLoading: true,
    masterDataBasicServiceTypeDeleteParams: action.payload,
    action: MasterDataBasicServiceTypeDeleteAction.FETCH,
  }),
  [MasterDataBasicServiceTypeDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeDeleteParams: undefined,
    masterDataBasicServiceTypeDeleteLoading: false,
    masterDataBasicServiceTypeDeleteResponse: action.payload,
    action: MasterDataBasicServiceTypeDeleteAction.SUCCESS,
  }),
  [MasterDataBasicServiceTypeDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeDeleteLoading: false,
    masterDataBasicServiceTypeDeleteError: action.payload,
    action: MasterDataBasicServiceTypeDeleteAction.FAILED,
  }),
  [MasterDataBasicServiceTypeDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBasicServiceTypeDeleteInitialState,
    action: MasterDataBasicServiceTypeDeleteAction.RESET,
  }),
};
