import {createAction} from '@reduxjs/toolkit';
import {
  UserRoleAccessDeleteAction,
  UserRoleAccessDeleteFailedPayload,
  UserRoleAccessDeleteFetchPayload,
  UserRoleAccessDeleteSuccessPayload,
} from './userRoleAccessDelete.type';

export const userRoleAccessDeleteAction = {
  fetch: createAction<UserRoleAccessDeleteFetchPayload>(
    UserRoleAccessDeleteAction.FETCH,
  ),
  success: createAction<UserRoleAccessDeleteSuccessPayload>(
    UserRoleAccessDeleteAction.SUCCESS,
  ),
  failed: createAction<UserRoleAccessDeleteFailedPayload>(
    UserRoleAccessDeleteAction.FAILED,
  ),
  reset: createAction(UserRoleAccessDeleteAction.RESET),
};
