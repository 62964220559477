import {createReducer} from '@reduxjs/toolkit';

import {NotificationListInitialState} from './notificationList/notificationList.type';
import {NotificationReadAllInitialState} from './notificationReadAll/notificationReadAll.type';

import {notificationListInitialState} from './notificationList/notificationList.reducer';
import {notificationReadAllInitialState} from './notificationReadAll/notificationReadAll.reducer';

import notificationHandler from './notification.handler';

export interface NotificationState
  extends NotificationListInitialState,
    NotificationReadAllInitialState {
  action: string;
}

export const NotificationInitialState: NotificationState = {
  ...notificationListInitialState,
  ...notificationReadAllInitialState,
  action: '',
};

export default createReducer(NotificationInitialState, notificationHandler);
