import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDivisionEditInitialState} from './masterDataDivisionEdit.reducer';
import {
  MasterDataDivisionEditAction,
  MasterDataDivisionEditFailedPayload,
  MasterDataDivisionEditFetchPayload,
  MasterDataDivisionEditSuccessPayload,
} from './masterDataDivisionEdit.type';

export const masterDataDivisionEditHandler = {
  [MasterDataDivisionEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionEditLoading: true,
    masterDataDivisionEditParams: action.payload,
    action: MasterDataDivisionEditAction.FETCH,
  }),
  [MasterDataDivisionEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionEditParams: undefined,
    masterDataDivisionEditLoading: false,
    masterDataDivisionEditResponse: action.payload,
    action: MasterDataDivisionEditAction.SUCCESS,
  }),
  [MasterDataDivisionEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionEditLoading: false,
    masterDataDivisionEditError: action.payload,
    action: MasterDataDivisionEditAction.FAILED,
  }),
  [MasterDataDivisionEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDivisionEditInitialState,
    action: MasterDataDivisionEditAction.RESET,
  }),
};
