//#region IMPORT
// Libraries
import React from 'react';
import {Paper, Table, TableBody, TableContainer} from '@mui/material';
// Components
import CategoryManagementListTableHeader from '../CategoryManagementListTableHeader';
import CategoryManagementListTableItem from '../CategoryManagementListTableItem';
// Data
import {MasterDataCategory} from '../../../../../../entity/masterData.string.entity';
//#endregion

//#region INTERFACE
interface Props {
  categoryList: MasterDataCategory[];
  onEditCategory?(_category: MasterDataCategory): void;
  onDeleteCategory?(_category: MasterDataCategory): void;
  onMoveUp?(id: string): void;
  onMoveDown?(id: string): void;
}
//#endregion

const CategoryManagementList: React.FC<Props> = ({
  categoryList,
  onEditCategory,
  onDeleteCategory,
  onMoveUp,
  onMoveDown,
}: Props) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      sx={{height: '60vh'}}
      className="master-data-management-list__container">
      <Table
        stickyHeader
        aria-label="sticky table"
        className="master-data-management-list__container-table">
        <CategoryManagementListTableHeader />
        <TableBody>
          {categoryList.map((item: MasterDataCategory, index: number) => (
            <CategoryManagementListTableItem
              {...(onEditCategory && {
                onEditItem: () => onEditCategory(item),
              })}
              {...(onDeleteCategory && {
                onDeleteItem: () => onDeleteCategory(item),
              })}
              {...(onMoveUp && {
                onMoveUpItem: () => onMoveUp(item.id),
              })}
              {...(onMoveDown && {
                onMoveDownItem: () => onMoveDown(item.id),
              })}
              key={`category-list-item-data-${item.id}-${index}`}
              data={item}
              index={index + 1}
              isFirst={index === 0}
              isLast={index === categoryList.length - 1}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoryManagementList;
