import {
  MasterDataDivisionListAction,
  MasterDataDivisionListSuccessPayload,
  MasterDataDivisionListFailedPayload,
  MasterDataDivisionListFetchPayload,
} from './masterDataDivisionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataDivisionListInitialState} from './masterDataDivisionList.reducer';

export const masterDataDivisionListHandler = {
  [MasterDataDivisionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataDivisionListLoading: true,
      masterDataDivisionListParams: action.payload,
      action: MasterDataDivisionListAction.FETCH,
    };
  },
  [MasterDataDivisionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionListLoading: false,
    masterDataDivisionListResponse: action.payload,
    action: MasterDataDivisionListAction.SUCCESS,
  }),
  [MasterDataDivisionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionListLoading: false,
    masterDataDivisionListData: [
      ...state.masterDataDivisionListData,
      ...action.payload.data.content,
    ],
    action: MasterDataDivisionListAction.UPDATE,
  }),
  [MasterDataDivisionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDivisionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDivisionListLoading: false,
    masterDataDivisionListError: action.payload,
    action: MasterDataDivisionListAction.FAILED,
  }),
  [MasterDataDivisionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDivisionListInitialState,
    action: MasterDataDivisionListAction.RESET,
  }),
};
