import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataCategoryEditInitialState} from './masterDataCategoryEdit.reducer';
import {
  MasterDataCategoryEditAction,
  MasterDataCategoryEditFailedPayload,
  MasterDataCategoryEditFetchPayload,
  MasterDataCategoryEditSuccessPayload,
} from './masterDataCategoryEdit.type';

export const masterDataCategoryEditHandler = {
  [MasterDataCategoryEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryEditLoading: true,
    masterDataCategoryEditParams: action.payload,
    action: MasterDataCategoryEditAction.FETCH,
  }),
  [MasterDataCategoryEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryEditParams: undefined,
    masterDataCategoryEditLoading: false,
    masterDataCategoryEditResponse: action.payload,
    action: MasterDataCategoryEditAction.SUCCESS,
  }),
  [MasterDataCategoryEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryEditLoading: false,
    masterDataCategoryEditError: action.payload,
    action: MasterDataCategoryEditAction.FAILED,
  }),
  [MasterDataCategoryEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataCategoryEditInitialState,
    action: MasterDataCategoryEditAction.RESET,
  }),
};
