import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataObservationCreateAction,
  MasterDataObservationCreateFailedPayload,
  MasterDataObservationCreateFetchPayload,
  MasterDataObservationCreateSuccessPayload,
} from './masterDataObservationCreate.type';

export const masterDataObservationCreateAction = {
  fetch: createAction<MasterDataObservationCreateFetchPayload>(
    MasterDataObservationCreateAction.FETCH,
  ),
  success: createAction<MasterDataObservationCreateSuccessPayload>(
    MasterDataObservationCreateAction.SUCCESS,
  ),
  failed: createAction<MasterDataObservationCreateFailedPayload>(
    MasterDataObservationCreateAction.FAILED,
  ),
  reset: createAction(MasterDataObservationCreateAction.RESET),
};
