import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataCategoryMoveInitialState} from './masterDataCategoryMove.reducer';
import {
  MasterDataCategoryMoveAction,
  MasterDataCategoryMoveFailedPayload,
  MasterDataCategoryMoveFetchPayload,
  MasterDataCategoryMoveSuccessPayload,
} from './masterDataCategoryMove.type';

export const masterDataCategoryMoveHandler = {
  [MasterDataCategoryMoveAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryMoveFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryMoveLoading: true,
    masterDataCategoryMoveParams: action.payload,
    action: MasterDataCategoryMoveAction.FETCH,
  }),
  [MasterDataCategoryMoveAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryMoveSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryMoveParams: undefined,
    masterDataCategoryMoveLoading: false,
    masterDataCategoryMoveResponse: action.payload,
    action: MasterDataCategoryMoveAction.SUCCESS,
  }),
  [MasterDataCategoryMoveAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataCategoryMoveFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataCategoryMoveLoading: false,
    masterDataCategoryMoveError: action.payload,
    action: MasterDataCategoryMoveAction.FAILED,
  }),
  [MasterDataCategoryMoveAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataCategoryMoveInitialState,
    action: MasterDataCategoryMoveAction.RESET,
  }),
};
