import {CommonError} from '../../../../../../config/constant';

export const MasterDataObservationDeleteAction = {
  FETCH: 'MASTER_DATA_OBSERVATION_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_OBSERVATION_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_OBSERVATION_DELETE_FAILED',
  RESET: 'MASTER_DATA_OBSERVATION_DELETE_RESET',
};

export interface MasterDataObservationDeleteInitialState {
  masterDataObservationDeleteLoading: boolean;
  masterDataObservationDeleteParams?: MasterDataObservationDeleteFetchPayload;
  masterDataObservationDeleteError?: MasterDataObservationDeleteFailedPayload;
  masterDataObservationDeleteResponse?: MasterDataObservationDeleteSuccessPayload;
}

export interface MasterDataObservationDeleteFetchPayload {
  id: string;
}
export type MasterDataObservationDeleteFailedPayload = CommonError;

export interface MasterDataObservationDeleteSuccessPayload {}
