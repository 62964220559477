import {CommonError} from '../../../../config/constant';
import {EvaluationReportDetailData} from '../../entity/evaluationReport.string.entity';

export const EvaluationReportDetailAction = {
  FETCH: 'EVALUATION_REPORT_DETAIL_FETCH',
  SUCCESS: 'EVALUATION_REPORT_DETAIL_SUCCESS',
  FAILED: 'EVALUATION_REPORT_DETAIL_FAILED',
  RESET: 'EVALUATION_REPORT_DETAIL_RESET',
  UPDATE: 'EVALUATION_REPORT_DETAIL_UPDATE',
};

export interface EvaluationReportDetailInitialState {
  evaluationReportDetailLoading: boolean;
  evaluationReportDetailError?: EvaluationReportDetailFailedPayload;
  evaluationReportDetailParams?: EvaluationReportDetailFetchPayload;
  evaluationReportDetailResponse?: EvaluationReportDetailSuccessPayload;
}

export type EvaluationReportDetailResponse = {
  status: string;
  data: EvaluationReportDetailData;
};

export type EvaluationReportDetailFailedPayload = CommonError;

export type EvaluationReportDetailSuccessPayload = EvaluationReportDetailResponse;

export interface EvaluationReportDetailFetchPayload {
  transReviewId?: string;
}
