import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBusDeleteAction,
  MasterDataBusDeleteFailedPayload,
  MasterDataBusDeleteFetchPayload,
  MasterDataBusDeleteSuccessPayload,
} from './masterDataBusDelete.type';

export const masterDataBusDeleteAction = {
  fetch: createAction<MasterDataBusDeleteFetchPayload>(
    MasterDataBusDeleteAction.FETCH,
  ),
  success: createAction<MasterDataBusDeleteSuccessPayload>(
    MasterDataBusDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataBusDeleteFailedPayload>(
    MasterDataBusDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataBusDeleteAction.RESET),
};
