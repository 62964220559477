import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataDepartmentEditInitialState} from './masterDataDepartmentEdit.reducer';
import {
  MasterDataDepartmentEditAction,
  MasterDataDepartmentEditFailedPayload,
  MasterDataDepartmentEditFetchPayload,
  MasterDataDepartmentEditSuccessPayload,
} from './masterDataDepartmentEdit.type';

export const masterDataDepartmentEditHandler = {
  [MasterDataDepartmentEditAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentEditFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentEditLoading: true,
    masterDataDepartmentEditParams: action.payload,
    action: MasterDataDepartmentEditAction.FETCH,
  }),
  [MasterDataDepartmentEditAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentEditSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentEditParams: undefined,
    masterDataDepartmentEditLoading: false,
    masterDataDepartmentEditResponse: action.payload,
    action: MasterDataDepartmentEditAction.SUCCESS,
  }),
  [MasterDataDepartmentEditAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataDepartmentEditFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataDepartmentEditLoading: false,
    masterDataDepartmentEditError: action.payload,
    action: MasterDataDepartmentEditAction.FAILED,
  }),
  [MasterDataDepartmentEditAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataDepartmentEditInitialState,
    action: MasterDataDepartmentEditAction.RESET,
  }),
};
