import {CommonError} from '../../../../../../config/constant';
import {MasterDataFormControllerFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataFormControllerCreateAction = {
  FETCH: 'MASTER_DATA_FORM_CONTROLLER_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_FORM_CONTROLLER_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_FORM_CONTROLLER_CREATE_FAILED',
  RESET: 'MASTER_DATA_FORM_CONTROLLER_CREATE_RESET',
};

export interface MasterDataFormControllerCreateInitialState {
  masterDataFormControllerCreateLoading: boolean;
  masterDataFormControllerCreateParams?: MasterDataFormControllerCreateFetchPayload;
  masterDataFormControllerCreateError?: MasterDataFormControllerCreateFailedPayload;
  masterDataFormControllerCreateResponse?: MasterDataFormControllerCreateSuccessPayload;
}

export type MasterDataFormControllerCreateFetchPayload = Omit<
  MasterDataFormControllerFormData,
  'id'
>;

export type MasterDataFormControllerCreateFailedPayload = CommonError;

export interface MasterDataFormControllerCreateSuccessPayload {}
