import {CommonError} from '../../../../config/constant';
import {UserDetail} from '../../entity/user.string.entity';

export const UserAccountDetailAction = {
  FETCH: 'USER_ACCOUNT_DETAIL_FETCH',
  SUCCESS: 'USER_ACCOUNT_DETAIL_SUCCESS',
  FAILED: 'USER_ACCOUNT_DETAIL_FAILED',
  RESET: 'USER_ACCOUNT_DETAIL_RESET',
  UPDATE: 'USER_ACCOUNT_DETAIL_UPDATE',
};

export interface UserAccountDetailInitialState {
  userAccountDetailLoading: boolean;
  userAccountDetailError?: UserAccountDetailFailedPayload;
  userAccountDetailParams?: UserAccountDetailFetchPayload;
  userAccountDetailResponse?: UserAccountDetailSuccessPayload;
}

export type UserAccountDetailResponse = {
  success: boolean;
  data: UserDetail;
};

export type UserAccountDetailFailedPayload = CommonError;

export type UserAccountDetailSuccessPayload = UserAccountDetailResponse;

export interface UserAccountDetailFetchPayload {
  userId?: string;
}
