import {CommonError} from '../../../../../../config/constant';

export const MasterDataReviewIndicatorMoveAction = {
  FETCH: 'MASTER_DATA_REVIEW_INDICATOR_MOVE_FETCH',
  SUCCESS: 'MASTER_DATA_REVIEW_INDICATOR_MOVE_SUCCESS',
  FAILED: 'MASTER_DATA_REVIEW_INDICATOR_MOVE_FAILED',
  RESET: 'MASTER_DATA_REVIEW_INDICATOR_MOVE_RESET',
};

export interface MasterDataReviewIndicatorMoveInitialState {
  masterDataReviewIndicatorMoveLoading: boolean;
  masterDataReviewIndicatorMoveParams?: MasterDataReviewIndicatorMoveFetchPayload;
  masterDataReviewIndicatorMoveError?: MasterDataReviewIndicatorMoveFailedPayload;
  masterDataReviewIndicatorMoveResponse?: MasterDataReviewIndicatorMoveSuccessPayload;
}

export interface MasterDataReviewIndicatorMoveFetchPayload {
  id: string;
  isUp?: boolean;
  isDown?: boolean;
  assignmentType: string;
}
export type MasterDataReviewIndicatorMoveFailedPayload = CommonError;

export interface MasterDataReviewIndicatorMoveSuccessPayload {}
