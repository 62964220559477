//#region FORM
//#region FORM CONTROLLER
import {masterDataFormControllerCreateHandler} from './section/form/masterDataFormControllerCreate/masterDataFormControllerCreate.handler';
import {masterDataFormControllerListHandler} from './section/form/masterDataFormControllerList/masterDataFormControllerList.handler';
import {masterDataFormControllerDeleteHandler} from './section/form/masterDataFormControllerDelete/masterDataFormControllerDelete.handler';
import {masterDataFormControllerEditHandler} from './section/form/masterDataFormControllerEdit/masterDataFormControllerEdit.handler';
import {masterDataFormControllerMoveHandler} from './section/form/masterDataFormControllerMove/masterDataFormControllerMove.handler';
//#endregion
//#region BASIC SERVICE TYPE
import {masterDataBasicServiceTypeCreateHandler} from './section/form/masterDataBasicServiceTypeCreate/masterDataBasicServiceTypeCreate.handler';
import {masterDataBasicServiceTypeListHandler} from './section/form/masterDataBasicServiceTypeList/masterDataBasicServiceTypeList.handler';
import {masterDataBasicServiceTypeMoveHandler} from './section/form/masterDataBasicServiceTypeMove/masterDataBasicServiceTypeMove.handler';
import {masterDataBasicServiceTypeOptionListHandler} from './section/form/masterDataBasicServiceTypeOptionList/masterDataBasicServiceTypeOptionList.handler';
import {masterDataBasicServiceTypeDeleteHandler} from './section/form/masterDataBasicServiceTypeDelete/masterDataBasicServiceTypeDelete.handler';
import {masterDataBasicServiceTypeEditHandler} from './section/form/masterDataBasicServiceTypeEdit/masterDataBasicServiceTypeEdit.handler';
//#endregion
//#region CATEGORY
import {masterDataCategoryCreateHandler} from './section/form/masterDataCategoryCreate/masterDataCategoryCreate.handler';
import {masterDataCategoryListHandler} from './section/form/masterDataCategoryList/masterDataCategoryList.handler';
import {masterDataCategoryMoveHandler} from './section/form/masterDataCategoryMove/masterDataCategoryMove.handler';
import {masterDataCategoryOptionListHandler} from './section/form/masterDataCategoryOptionList/masterDataCategoryOptionList.handler';
import {masterDataCategoryDeleteHandler} from './section/form/masterDataCategoryDelete/masterDataCategoryDelete.handler';
import {masterDataCategoryEditHandler} from './section/form/masterDataCategoryEdit/masterDataCategoryEdit.handler';
//#endregion
//#region DETAIL OBSERVATION
import {masterDataDetailObservationCreateHandler} from './section/form/masterDataDetailObservationCreate/masterDataDetailObservationCreate.handler';
import {masterDataDetailObservationListHandler} from './section/form/masterDataDetailObservationList/masterDataDetailObservationList.handler';
import {masterDataDetailObservationOptionListHandler} from './section/form/masterDataDetailObservationOptionList/masterDataDetailObservationOptionList.handler';
import {masterDataDetailObservationDeleteHandler} from './section/form/masterDataDetailObservationDelete/masterDataDetailObservationDelete.handler';
import {masterDataDetailObservationEditHandler} from './section/form/masterDataDetailObservationEdit/masterDataDetailObservationEdit.handler';
//#endregion
//#region REVIEW INDICATOR
import {masterDataReviewIndicatorCreateHandler} from './section/form/masterDataReviewIndicatorCreate/masterDataReviewIndicatorCreate.handler';
import {masterDataReviewIndicatorListHandler} from './section/form/masterDataReviewIndicatorList/masterDataReviewIndicatorList.handler';
import {masterDataReviewIndicatorMoveHandler} from './section/form/masterDataReviewIndicatorMove/masterDataReviewIndicatorMove.handler';
import {masterDataReviewIndicatorOptionListHandler} from './section/form/masterDataReviewIndicatorOptionList/masterDataReviewIndicatorOptionList.handler';
import {masterDataReviewIndicatorDeleteHandler} from './section/form/masterDataReviewIndicatorDelete/masterDataReviewIndicatorDelete.handler';
import {masterDataReviewIndicatorEditHandler} from './section/form/masterDataReviewIndicatorEdit/masterDataReviewIndicatorEdit.handler';
//#endregion
//#region DETAIL INDICATOR
import {masterDataDetailIndicatorCreateHandler} from './section/form/masterDataDetailIndicatorCreate/masterDataDetailIndicatorCreate.handler';
import {masterDataDetailIndicatorListHandler} from './section/form/masterDataDetailIndicatorList/masterDataDetailIndicatorList.handler';
import {masterDataDetailIndicatorOptionListHandler} from './section/form/masterDataDetailIndicatorOptionList/masterDataDetailIndicatorOptionList.handler';
import {masterDataDetailIndicatorDeleteHandler} from './section/form/masterDataDetailIndicatorDelete/masterDataDetailIndicatorDelete.handler';
import {masterDataDetailIndicatorEditHandler} from './section/form/masterDataDetailIndicatorEdit/masterDataDetailIndicatorEdit.handler';
//#endregion
//#region INDICATOR VALUE
import {masterDataIndicatorValueCreateHandler} from './section/form/masterDataIndicatorValueCreate/masterDataIndicatorValueCreate.handler';
import {masterDataIndicatorValueListHandler} from './section/form/masterDataIndicatorValueList/masterDataIndicatorValueList.handler';
import {masterDataIndicatorValueOptionListHandler} from './section/form/masterDataIndicatorValueOptionList/masterDataIndicatorValueOptionList.handler';
import {masterDataIndicatorValueDeleteHandler} from './section/form/masterDataIndicatorValueDelete/masterDataIndicatorValueDelete.handler';
import {masterDataIndicatorValueSetPositiveValueHandler} from './section/form/masterDataIndicatorValueSetPositiveValue/masterDataIndicatorValueSetPositiveValue.handler';
import {masterDataIndicatorValueEditHandler} from './section/form/masterDataIndicatorValueEdit/masterDataIndicatorValueEdit.handler';
//#endregion
//#region OBSERVATION VALUE
import {masterDataObservationValueCreateHandler} from './section/form/masterDataObservationValueCreate/masterDataObservationValueCreate.handler';
import {masterDataObservationValueListHandler} from './section/form/masterDataObservationValueList/masterDataObservationValueList.handler';
import {masterDataObservationValueOptionListHandler} from './section/form/masterDataObservationValueOptionList/masterDataObservationValueOptionList.handler';
import {masterDataObservationValueDeleteHandler} from './section/form/masterDataObservationValueDelete/masterDataObservationValueDelete.handler';
import {masterDataObservationValueSetPositiveValueHandler} from './section/form/masterDataObservationValueSetPositiveValue/masterDataObservationValueSetPositiveValue.handler';
import {masterDataObservationValueEditHandler} from './section/form/masterDataObservationValueEdit/masterDataObservationValueEdit.handler';
//#endregion
//#region OBSERVATION
import {masterDataObservationCreateHandler} from './section/form/masterDataObservationCreate/masterDataObservationCreate.handler';
import {masterDataObservationListHandler} from './section/form/masterDataObservationList/masterDataObservationList.handler';
import {masterDataObservationOptionListHandler} from './section/form/masterDataObservationOptionList/masterDataObservationOptionList.handler';
import {masterDataObservationDeleteHandler} from './section/form/masterDataObservationDelete/masterDataObservationDelete.handler';
import {masterDataObservationEditHandler} from './section/form/masterDataObservationEdit/masterDataObservationEdit.handler';
//#endregion
//#endregion

//#region OFFICE
//#region DIRECTORATE
import {masterDataDirectorateCreateHandler} from './section/office/masterDataDirectorateCreate/masterDataDirectorateCreate.handler';
import {masterDataDirectorateListHandler} from './section/office/masterDataDirectorateList/masterDataDirectorateList.handler';
import {masterDataDirectorateDeleteHandler} from './section/office/masterDataDirectorateDelete/masterDataDirectorateDelete.handler';
import {masterDataDirectorateEditHandler} from './section/office/masterDataDirectorateEdit/masterDataDirectorateEdit.handler';
import {masterDataDirectorateOptionListHandler} from './section/office/masterDataDirectorateOptionList/masterDataDirectorateOptionList.handler';
//#endregion
//#region DIVISION
import {masterDataDivisionCreateHandler} from './section/office/masterDataDivisionCreate/masterDataDivisionCreate.handler';
import {masterDataDivisionListHandler} from './section/office/masterDataDivisionList/masterDataDivisionList.handler';
import {masterDataDivisionDeleteHandler} from './section/office/masterDataDivisionDelete/masterDataDivisionDelete.handler';
import {masterDataDivisionEditHandler} from './section/office/masterDataDivisionEdit/masterDataDivisionEdit.handler';
import {masterDataDivisionOptionListHandler} from './section/office/masterDataDivisionOptionList/masterDataDivisionOptionList.handler';
//#endregion
//#region DEPARTMENT
import {masterDataDepartmentCreateHandler} from './section/office/masterDataDepartmentCreate/masterDataDepartmentCreate.handler';
import {masterDataDepartmentListHandler} from './section/office/masterDataDepartmentList/masterDataDepartmentList.handler';
import {masterDataDepartmentDeleteHandler} from './section/office/masterDataDepartmentDelete/masterDataDepartmentDelete.handler';
import {masterDataDepartmentEditHandler} from './section/office/masterDataDepartmentEdit/masterDataDepartmentEdit.handler';
import {masterDataDepartmentOptionListHandler} from './section/office/masterDataDepartmentOptionList/masterDataDepartmentOptionList.handler';
//#endregion
//#region WORK INSTRUCTION
import {masterDataWorkInstructionListHandler} from './section/office/masterDataWorkInstructionList/masterDataWorkInstructionList.handler';
import {masterDataWorkInstructionEditHandler} from './section/office/masterDataWorkInstructionEdit/masterDataWorkInstructionEdit.handler';
//#endregion
//#region ASSIGNMENT LETTER
import {masterDataAssignmentLetterCreateHandler} from './section/office/masterDataAssignmentLetterCreate/masterDataAssignmentLetterCreate.handler';
import {masterDataAssignmentLetterListHandler} from './section/office/masterDataAssignmentLetterList/masterDataAssignmentLetterList.handler';
import {masterDataAssignmentLetterDeleteHandler} from './section/office/masterDataAssignmentLetterDelete/masterDataAssignmentLetterDelete.handler';
import {masterDataAssignmentLetterEditHandler} from './section/office/masterDataAssignmentLetterEdit/masterDataAssignmentLetterEdit.handler';
import {masterDataAssignmentLetterSetActiveHandler} from './section/office/masterDataAssignmentLetterSetActive/masterDataAssignmentLetterSetActive.handler';
//#endregion
//#endregion

//#region OPERATIONAL
//#region BUS
import {masterDataBusListHandler} from './section/operational/masterDataBusList/masterDataBusList.handler';
import {masterDataBusCreateHandler} from './section/operational/masterDataBusCreate/masterDataBusCreate.handler';
import {masterDataBusDeleteHandler} from './section/operational/masterDataBusDelete/masterDataBusDelete.handler';
import {masterDataBusEditHandler} from './section/operational/masterDataBusEdit/masterDataBusEdit.handler';
import {masterDataBusOptionListHandler} from './section/operational/masterDataBusOptionList/masterDataBusOptionList.handler';
//#endregion
//#region RUTE
import {masterDataRuteListHandler} from './section/operational/masterDataRuteList/masterDataRuteList.handler';
import {masterDataRouteCreateHandler} from './section/operational/masterDataRouteCreate/masterDataRouteCreate.handler';
import {masterDataRouteDeleteHandler} from './section/operational/masterDataRouteDelete/masterDataRouteDelete.handler';
import {masterDataRouteEditHandler} from './section/operational/masterDataRouteEdit/masterDataRouteEdit.handler';
//#endregion
//#region COUNTER
import {masterDataCounterListHandler} from './section/operational/masterDataCounterList/masterDataCounterList.handler';
//#endregion
//#endregion

export default {
  //#region FORM
  //#region FORM CONTROLLER
  ...masterDataFormControllerCreateHandler,
  ...masterDataFormControllerListHandler,
  ...masterDataFormControllerDeleteHandler,
  ...masterDataFormControllerEditHandler,
  ...masterDataFormControllerMoveHandler,
  //#endregion
  //#region BASIC SERVICE TYPE
  ...masterDataBasicServiceTypeCreateHandler,
  ...masterDataBasicServiceTypeListHandler,
  ...masterDataBasicServiceTypeMoveHandler,
  ...masterDataBasicServiceTypeOptionListHandler,
  ...masterDataBasicServiceTypeDeleteHandler,
  ...masterDataBasicServiceTypeEditHandler,
  //#endregion
  //#region CATERGORY
  ...masterDataCategoryCreateHandler,
  ...masterDataCategoryListHandler,
  ...masterDataCategoryMoveHandler,
  ...masterDataCategoryDeleteHandler,
  ...masterDataCategoryEditHandler,
  ...masterDataCategoryOptionListHandler,
  //#endregion
  //#region DETAIL OBSERVATION
  ...masterDataDetailObservationCreateHandler,
  ...masterDataDetailObservationListHandler,
  ...masterDataDetailObservationOptionListHandler,
  ...masterDataDetailObservationDeleteHandler,
  ...masterDataDetailObservationEditHandler,
  //#endregion
  //#region REVIEW INDICATOR
  ...masterDataReviewIndicatorCreateHandler,
  ...masterDataReviewIndicatorListHandler,
  ...masterDataReviewIndicatorOptionListHandler,
  ...masterDataReviewIndicatorDeleteHandler,
  ...masterDataReviewIndicatorEditHandler,
  ...masterDataReviewIndicatorMoveHandler,
  //#endregion
  //#region DETAIL INDICATOR
  ...masterDataDetailIndicatorCreateHandler,
  ...masterDataDetailIndicatorListHandler,
  ...masterDataDetailIndicatorOptionListHandler,
  ...masterDataDetailIndicatorDeleteHandler,
  ...masterDataDetailIndicatorEditHandler,
  //#endregion
  //#region INDICATOR VALUE
  ...masterDataIndicatorValueCreateHandler,
  ...masterDataIndicatorValueListHandler,
  ...masterDataIndicatorValueOptionListHandler,
  ...masterDataIndicatorValueDeleteHandler,
  ...masterDataIndicatorValueEditHandler,
  ...masterDataIndicatorValueSetPositiveValueHandler,
  //#endregion
  //#region OBSERVATION VALUE
  ...masterDataObservationValueCreateHandler,
  ...masterDataObservationValueListHandler,
  ...masterDataObservationValueOptionListHandler,
  ...masterDataObservationValueDeleteHandler,
  ...masterDataObservationValueEditHandler,
  ...masterDataObservationValueSetPositiveValueHandler,
  //#endregion
  //#region OBSERVATION
  ...masterDataObservationCreateHandler,
  ...masterDataObservationListHandler,
  ...masterDataObservationOptionListHandler,
  ...masterDataObservationDeleteHandler,
  ...masterDataObservationEditHandler,
  //#endregion
  //#endregion

  //#region OFFICE
  //#region DIRECTORATE
  ...masterDataDirectorateCreateHandler,
  ...masterDataDirectorateListHandler,
  ...masterDataDirectorateDeleteHandler,
  ...masterDataDirectorateEditHandler,
  ...masterDataDirectorateOptionListHandler,
  //#endregion
  //#region DIVISION
  ...masterDataDivisionCreateHandler,
  ...masterDataDivisionListHandler,
  ...masterDataDivisionDeleteHandler,
  ...masterDataDivisionEditHandler,
  ...masterDataDivisionOptionListHandler,
  //#endregion
  //#region DEPARTMENT
  ...masterDataDepartmentCreateHandler,
  ...masterDataDepartmentListHandler,
  ...masterDataDepartmentDeleteHandler,
  ...masterDataDepartmentEditHandler,
  ...masterDataDepartmentOptionListHandler,
  //#endregion
  //#region WORK INSTRUCTION
  ...masterDataWorkInstructionListHandler,
  ...masterDataWorkInstructionEditHandler,
  //#endregion
  //#region ASSIGNMENT LETTER
  ...masterDataAssignmentLetterCreateHandler,
  ...masterDataAssignmentLetterListHandler,
  ...masterDataAssignmentLetterDeleteHandler,
  ...masterDataAssignmentLetterEditHandler,
  ...masterDataAssignmentLetterSetActiveHandler,
  //#endregion
  //#endregion

  //#region OPERATIONAL
  //#region BUS
  ...masterDataBusCreateHandler,
  ...masterDataBusEditHandler,
  ...masterDataBusDeleteHandler,
  ...masterDataBusListHandler,
  ...masterDataBusOptionListHandler,
  //#endregion
  //#region RUTE
  ...masterDataRuteListHandler,
  ...masterDataRouteCreateHandler,
  ...masterDataRouteEditHandler,
  ...masterDataRouteDeleteHandler,
  //#endregion
  //#region COUNTER
  ...masterDataCounterListHandler,
  //#endregion
  //#endregion
};
