import * as React from 'react';

const IconUserProfile = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.height || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.411 6.076A4.395 4.395 0 0110 10.486a4.396 4.396 0 01-4.412-4.41A4.395 4.395 0 0110 1.666a4.394 4.394 0 014.411 4.41zM10 18.333c-3.615 0-6.667-.587-6.667-2.854 0-2.267 3.071-2.834 6.667-2.834 3.615 0 6.666.588 6.666 2.854 0 2.268-3.07 2.834-6.666 2.834z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconUserProfile;
