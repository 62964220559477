import {CommonError} from '../../../../../../config/constant';

export const MasterDataDirectorateDeleteAction = {
  FETCH: 'MASTER_DATA_DIRECTORATE_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_DIRECTORATE_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_DIRECTORATE_DELETE_FAILED',
  RESET: 'MASTER_DATA_DIRECTORATE_DELETE_RESET',
};

export interface MasterDataDirectorateDeleteInitialState {
  masterDataDirectorateDeleteLoading: boolean;
  masterDataDirectorateDeleteParams?: MasterDataDirectorateDeleteFetchPayload;
  masterDataDirectorateDeleteError?: MasterDataDirectorateDeleteFailedPayload;
  masterDataDirectorateDeleteResponse?: MasterDataDirectorateDeleteSuccessPayload;
}

export interface MasterDataDirectorateDeleteFetchPayload {
  id: string;
}
export type MasterDataDirectorateDeleteFailedPayload = CommonError;

export interface MasterDataDirectorateDeleteSuccessPayload {}
