import {CommonError} from '../../../../../../config/constant';
import {DashboardTabDepartment} from '../../../../entity/dashboard.string.entity';

export const DashboardTabDepartmentListAction = {
  FETCH: 'DASHBOARD_TAB_DEPARTMENT_LIST_FETCH',
  SUCCESS: 'DASHBOARD_TAB_DEPARTMENT_LIST_SUCCESS',
  FAILED: 'DASHBOARD_TAB_DEPARTMENT_LIST_FAILED',
  RESET: 'DASHBOARD_TAB_DEPARTMENT_LIST_RESET',
  UPDATE: 'DASHBOARD_TAB_DEPARTMENT_LIST_UPDATE',
};

export interface DashboardTabDepartmentListInitialState {
  dashboardTabDepartmentListLoading: boolean;
  dashboardTabDepartmentListData: DashboardTabDepartment[];
  dashboardTabDepartmentListError?: DashboardTabDepartmentListFailedPayload;
  dashboardTabDepartmentListResponse?: DashboardTabDepartmentListSuccessPayload;
}

export type DashboardTabDepartmentListResponse = {
  status: string;
  info?: number;
  data: DashboardTabDepartment[];
};

export type DashboardTabDepartmentListFailedPayload = CommonError;

export type DashboardTabDepartmentListSuccessPayload = DashboardTabDepartmentListResponse;

export type DashboardTabDepartmentListUpdatePayload = DashboardTabDepartmentListResponse;
