import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataBasicServiceTypeMoveInitialState} from './masterDataBasicServiceTypeMove.reducer';
import {
  MasterDataBasicServiceTypeMoveAction,
  MasterDataBasicServiceTypeMoveFailedPayload,
  MasterDataBasicServiceTypeMoveFetchPayload,
  MasterDataBasicServiceTypeMoveSuccessPayload,
} from './masterDataBasicServiceTypeMove.type';

export const masterDataBasicServiceTypeMoveHandler = {
  [MasterDataBasicServiceTypeMoveAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeMoveFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeMoveLoading: true,
    masterDataBasicServiceTypeMoveParams: action.payload,
    action: MasterDataBasicServiceTypeMoveAction.FETCH,
  }),
  [MasterDataBasicServiceTypeMoveAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeMoveSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeMoveParams: undefined,
    masterDataBasicServiceTypeMoveLoading: false,
    masterDataBasicServiceTypeMoveResponse: action.payload,
    action: MasterDataBasicServiceTypeMoveAction.SUCCESS,
  }),
  [MasterDataBasicServiceTypeMoveAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBasicServiceTypeMoveFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBasicServiceTypeMoveLoading: false,
    masterDataBasicServiceTypeMoveError: action.payload,
    action: MasterDataBasicServiceTypeMoveAction.FAILED,
  }),
  [MasterDataBasicServiceTypeMoveAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBasicServiceTypeMoveInitialState,
    action: MasterDataBasicServiceTypeMoveAction.RESET,
  }),
};
