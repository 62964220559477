import React, {useRef} from 'react';
import {FieldProps} from 'formik';
import {DatePicker, PickersDay} from '@mui/lab';
import {DatePickerView} from '@mui/lab/DatePicker/shared';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {InputLabel, TextField} from '@mui/material';
import t from '../../lang';
import classnames from 'classnames';
import {IconCalendar} from '../../assets/icons';
import './MDatepickerInput.component.style.scss';
import moment, {Moment} from 'moment';

interface Props extends FieldProps<string> {
  disabled?: boolean;
  className?: string;
  inputValue: string;
  errormessage?: string;
  toolbarText?: string;
  format?: string;
  helperText?: string;
  label?: string;
  placeholder: string;
  views?: DatePickerView[];
  maxDate?: string | number | Moment;
  minDate?: string | number | Moment;
  handleChange?(date: Moment | string | null): void;
}

const MDatepickerInput: React.FC<Props> = ({
  disabled = false,
  className,
  inputValue,
  field,
  helperText,
  toolbarText = t('Choose Date'),
  format = 'DD/MM/YYYY',
  errormessage,
  label,
  placeholder,
  form,
  views,
  maxDate,
  minDate,
  handleChange,
  ...otherProps
}: Props) => {
  const datepickerRef = useRef<HTMLButtonElement>(null);
  const isError = errormessage || form.errors[field.name];
  const classes = classnames(
    'm-datepicker-input',
    {
      'm-datepicker-input--error': isError,
    },
    className,
  );

  return (
    <div className={`${classes} ${className}`}>
      {label ? (
        <InputLabel className="m-datepicker-input__label">{label}</InputLabel>
      ) : null}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          disabled={disabled}
          inputFormat={format}
          disableMaskedInput
          showToolbar={true}
          showTodayButton={false}
          allowSameDateSelection={true}
          showDaysOutsideCurrentMonth={true}
          value={inputValue ? moment(inputValue, format) : null}
          views={views}
          minDate={minDate}
          maxDate={maxDate}
          OpenPickerButtonProps={{
            ref: datepickerRef,
          }}
          onChange={(value) => {
            try {
              const date = moment(value, format).format(format);
              form.setFieldValue(field.name, date);
              handleChange && handleChange(date);
            } catch (error) {
              console.log({error});
            }
          }}
          ToolbarComponent={() => (
            <div className="m-datepicker-input__toolbar">{toolbarText}</div>
          )}
          components={{OpenPickerIcon: IconCalendar}}
          InputAdornmentProps={{position: 'start'}}
          renderDay={(day, _value, DayComponentProps) => {
            const isWeekend = moment(day).day() === 0;
            if (DayComponentProps.today) {
              return (
                <PickersDay
                  className="m-datepicker-input__date-today"
                  {...DayComponentProps}
                />
              );
            } else if (DayComponentProps.selected) {
              return (
                <PickersDay
                  className="m-datepicker-input__date-selected"
                  {...DayComponentProps}
                />
              );
            } else if (isWeekend) {
              return (
                <PickersDay
                  className="m-datepicker-input__date-weekend"
                  {...DayComponentProps}
                />
              );
            } else if (DayComponentProps.outsideCurrentMonth) {
              return (
                <PickersDay
                  className="m-datepicker-input__date-outside"
                  {...DayComponentProps}
                />
              );
            }
            return (
              <PickersDay
                className="m-datepicker-input__date"
                {...DayComponentProps}
              />
            );
          }}
          renderInput={(params) => {
            const {inputProps} = params;
            const customInputProps = {
              ...inputProps,
              placeholder: placeholder,
              readOnly: true,
            };
            const customParams = {...params, inputProps: customInputProps};

            return (
              <TextField
                className="m-datepicker-input__input"
                {...customParams}
                {...field}
                {...otherProps}
                onClick={() =>
                  datepickerRef.current && datepickerRef.current.click()
                }
              />
            );
          }}
        />
      </LocalizationProvider>

      {(errormessage || form.errors[field.name]) && (
        <div className="m-datepicker-input__errorText error--text">
          {errormessage || form.errors[field.name]}
        </div>
      )}

      {helperText && !form.errors && (
        <div className="m-datepicker-input__helperText helper-text">
          {helperText}
        </div>
      )}
    </div>
  );
};

export default MDatepickerInput;
