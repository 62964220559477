//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataObservationEditAction} from '../../../../usecases/masterData.action';
// Components
import {MasterDataObservationFormEdit} from './components';
// Data
import {MasterDataObservationFormData} from '../../../../entity/masterData.string.entity';
// Assets
import './MasterDataObservationFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  assignmentType: string;
  data: MasterDataObservationFormData;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataObservationFormEditContainer: React.FC<Props> = ({
  assignmentType,
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataObservationFormData = {
    id: data.id || '',
    name: data.name || '',
    assignmentType: data?.assignmentType || assignmentType,
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitObservation = (
    formValue: MasterDataObservationFormData,
  ) => {
    const observationTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: observationTemp || '',
    };
    dispatch(masterDataObservationEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-observation-form-edit-container">
      <MasterDataObservationFormEdit
        initialValues={initialValues}
        isLoading={isLoading}
        handleSubmitObservation={handleSubmitObservation}
        handleCancel={onCancel}
      />
    </div>
  );
  //#endregion
};

export default MasterDataObservationFormEditContainer;
