//#region IMPORT
// Libraries
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../../lang';
import {AppState} from '../../../../../../../../config/Interface';
import {getOptionFromDirectorateList} from '../../../../../../../../utils';
import {masterDataDirectorateOptionListAction} from '../../../../../../usecases/masterData.action';
import {MasterDataDivisionEditFetchPayload} from '../../../../../../usecases/masterData.type';
// Components
import {MButton, MSelect, MTextField} from '../../../../../../../../components';
// Assets
import './MasterDataDivisionFormEditForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  initialValues: MasterDataDivisionEditFetchPayload;
  isLoading?: boolean;
  handleCancel(): void;
  handleSubmitDivision(formValue: MasterDataDivisionEditFetchPayload): void;
}
//#endregion

const MasterDataDivisionFormCreateForm: React.FC<Props> = ({
  initialValues,
  isLoading,
  handleSubmitDivision,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );

  const isLoadingDirectorate =
    masterData.masterDataDirectorateOptionListLoading;
  //#endregion

  //#region FETCH DIRECTORATE
  const fetchDirectorateOptionList = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH
  const refresh = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.reset());
    fetchDirectorateOptionList();

    return () => {
      dispatch(masterDataDirectorateOptionListAction.reset());
    };
  }, [dispatch, fetchDirectorateOptionList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const divisionValidation = Yup.object().shape({
    directorateId: Yup.string().required(t('Directorate is required.')),
    name: Yup.string()
      .required(t('Division is required.'))
      .min(
        3,
        `${t('Division')} ${t('must be filled in at least')} ${3} ${t(
          'letters',
        )}.`,
      ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-division-form-edit-form">
      <div className="master-data-division-form-edit-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={divisionValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitDivision}>
          {({handleSubmit, isValid}): React.ReactElement => (
            <>
              <div className="master-data-division-form-edit-form__section">
                <div className="master-data-division-form-edit-form__section--field">
                  <div className="input-label">{t('Directorate')}</div>
                  <Field
                    className="user-input"
                    name="directorateId"
                    placeholder={t('Choose Directorate')}
                    options={getOptionFromDirectorateList(
                      masterData.masterDataDirectorateOptionListData,
                    )}
                    disabled={isLoading || isLoadingDirectorate}
                    component={MSelect}
                  />
                </div>
                <div className="master-data-division-form-edit-form__section--field">
                  <div className="input-label">{t('Division')}</div>
                  <Field
                    className="user-input"
                    name="name"
                    type="text"
                    placeholder={t('Enter Division')}
                    disabled={isLoading}
                    component={MTextField}
                  />
                </div>
              </div>

              <div className="master-data-division-form-edit-form__form--action">
                <div className="master-data-division-form-edit-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="master-data-division-form-edit-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    buttonLabel={t('Save')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default MasterDataDivisionFormCreateForm;
