//#region IMPORT
// Libraries
import React from 'react';
import {Avatar, Badge} from '@mui/material';
import moment from 'moment';
// Utils
import {colors} from '../../../../../../../config/_theme';
import {getNotificationBadge} from '../../../../../utils';
// Data
import {NotificationProps} from '../../../../../entity/notification.string.entity';
// Assets
import './NotificationListItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: NotificationProps;
  handleClick?(_notifProps: NotificationProps): void;
}
//#endregion

const NotificationListItem: React.FC<Props> = ({data, handleClick}: Props) => {
  return (
    <div className="notification-list-item-wrapper">
      <Badge
        variant="dot"
        color="primary"
        invisible={data.notifRead}
        sx={{marginLeft: '1.6rem'}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div
          className="notification-list-item"
          onClick={(event) => {
            event.stopPropagation();
            data && handleClick && handleClick(data);
          }}>
          <div className="notification-list-item__icon">
            <Badge
              overlap="circular"
              invisible={!data.notifTypeDetail}
              badgeContent={
                <Avatar
                  sx={{
                    bgcolor: getNotificationBadge(data.notifTypeDetail)?.color,
                    width: '3.2rem',
                    height: '3.2rem',
                  }}>
                  {getNotificationBadge(data.notifTypeDetail)?.badge}
                </Avatar>
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
              <Avatar
                src={!data.icon ? data.notifImg : undefined}
                sx={{
                  bgcolor: colors.primary.blue,
                  width: '5.6rem',
                  height: '5.6rem',
                }}>
                {data.icon ?? null}
              </Avatar>
            </Badge>
          </div>
          <div className="notification-list-item__content">
            <div className="notification-list-item__content--text">
              <span>{data.notifSubject}</span> {data.notifMessage}
            </div>
            <div className="notification-list-item__content--time">
              {moment(data.notifTime, 'YYYY-MM-DD HH:mm').format(
                'DD MMM YYYY HH:mm',
              )}
            </div>
          </div>
        </div>
      </Badge>
    </div>
  );
};
export default NotificationListItem;
