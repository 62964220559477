//#region IMPORT
// Libraries
import React from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
import moment from 'moment';
// Utils
import t from '../../../../../../../lang';
// Components
import {
  MButton,
  MSelect,
  MDatepickerInput,
} from '../../../../../../../components';
// Data
import {EvaluationReportExportFormData} from '../../../../../entity/evaluationReport.string.entity';
// Assets
import './EvaluationReportExportForm.component.style.scss';
import {AssignmentType} from '../../../../../../wrapper/entity';
import {routeType} from '../../../../../../followUp/entity/followUp.string.entity';
//#endregion

interface Props {
  isLoading?: boolean;
  initialValues: EvaluationReportExportFormData;
  handleCancel(): void;
  handleDownload(evaluationReport: EvaluationReportExportFormData): void;
}
const EvaluationReportDownloadForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleCancel,
  handleDownload,
}: Props) => {
  //#region GENERAL
  const assignmentTypeOptions = [
    {
      label: 'Halte',
      value: AssignmentType.HALTE,
    },
    {
      label: 'Bus BRT',
      value: AssignmentType.BUSBRT,
    },
    {
      label: 'Bus Non BRT',
      value: AssignmentType.BUSNONBRT,
    },
    {
      label: 'Bus Kecil',
      value: AssignmentType.BUSKECIL,
    },
    {
      label: 'Transcare',
      value: AssignmentType.TRANSCARE,
    },
  ];
  const routeTypeOptions = [
    {
      label: routeType.UJUNG,
      value: routeType.UJUNG,
    },
    {
      label: routeType.TENGAH,
      value: routeType.TENGAH,
    },
  ];
  //#endregion

  //#region VALIDATION
  const evaluationReportDownloadFormValidation = Yup.object().shape({
    startDate: Yup.string().required(t('Start Date is required.')),
    endDate: Yup.string().required(t('End Date is required.')),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="evaluation-report-export-form">
      <div className="evaluation-report-export-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={evaluationReportDownloadFormValidation}
          onSubmit={handleDownload}>
          {({
            handleSubmit,
            values,
            isValid,
            setFieldValue,
          }): React.ReactElement => (
            <>
              <div className="evaluation-report-export-form__section">
                <div className="evaluation-report-export-form__section--group">
                  <div className="evaluation-report-export-form__section--field">
                    <div className="input-label">{t('Start Date')}</div>
                    <Field
                      disabled={isLoading}
                      className="user-input"
                      name="startDate"
                      handleChange={() => {
                        setFieldValue('endDate', undefined);
                      }}
                      inputValue={values.startDate}
                      placeholder={t('Choose Start Date')}
                      maxDate={moment().endOf('year')}
                      component={MDatepickerInput}
                    />
                  </div>
                  <div className="evaluation-report-export-form__section--field">
                    <div className="input-label">{t('End Date')}</div>
                    <Field
                      disabled={isLoading}
                      minDate={
                        values?.startDate
                          ? moment(values?.startDate, 'DD/MM/YYYY')
                          : undefined
                      }
                      className="user-input"
                      name="endDate"
                      inputValue={values.endDate}
                      placeholder={t('Choose End Date')}
                      maxDate={moment().endOf('year')}
                      component={MDatepickerInput}
                    />
                  </div>
                </div>

                <div className="evaluation-report-export-form__section--field">
                  <div className="input-label">{t('Assignment type')}</div>
                  <Field
                    className="user-input"
                    name="assignmentType"
                    placeholder={t('Choose Assignment type')}
                    options={assignmentTypeOptions}
                    handleChangeSelect={() => {
                      setFieldValue('routeType', undefined);
                    }}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
                {[
                  AssignmentType.BUSBRT as string,
                  AssignmentType.BUSNONBRT,
                  AssignmentType.BUSKECIL,
                ].includes(values?.assignmentType || '') && (
                  <div className="evaluation-report-export-form__section--field">
                    <div className="input-label">{t('Route type')}</div>
                    <Field
                      className="user-input"
                      name="routeType"
                      placeholder={t('Choose Route type')}
                      options={routeTypeOptions}
                      disabled={isLoading}
                      component={MSelect}
                    />
                  </div>
                )}
              </div>

              <div className="evaluation-report-export-form__section">
                <div className="evaluation-report-export-form__form--action">
                  <div className="evaluation-report-export-form-action__canceled">
                    <MButton
                      isDisabled={isLoading}
                      buttonLabel={t('Cancel')}
                      handleClick={handleCancel}
                    />
                  </div>
                  <div className="evaluation-report-export-form-action">
                    <MButton
                      isDisabled={!isValid || isLoading}
                      isLoading={isLoading}
                      buttonLabel={t('Download File')}
                      handleClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default EvaluationReportDownloadForm;
