import {createAction} from '@reduxjs/toolkit';
import {
  UserAccessEditAction,
  UserAccessEditFailedPayload,
  UserAccessEditFetchPayload,
  UserAccessEditSuccessPayload,
} from './userAccessEdit.type';

export const userAccessEditAction = {
  fetch: createAction<UserAccessEditFetchPayload>(UserAccessEditAction.FETCH),
  success: createAction<UserAccessEditSuccessPayload>(
    UserAccessEditAction.SUCCESS,
  ),
  failed: createAction<UserAccessEditFailedPayload>(
    UserAccessEditAction.FAILED,
  ),
  reset: createAction(UserAccessEditAction.RESET),
};
