//#region IMPORT
// Libraries
import React from 'react';
import {useDispatch} from 'react-redux';
// Utils
import {masterDataDivisionEditAction} from '../../../../usecases/masterData.action';
import {MasterDataDivisionEditFetchPayload} from '../../../../usecases/masterData.type';
// Components
import {MasterDataDivisionFormEditForm} from './components';
// Assets
import './MasterDataDivisionFormEditContainer.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: MasterDataDivisionEditFetchPayload;
  isLoading?: boolean;
  onCancel(): void;
}
//#endregion

const MasterDataDivisionFormEditContainer: React.FC<Props> = ({
  data,
  isLoading,
  onCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  //#endregion

  //#region INITIAL VALUES
  const initialValues: MasterDataDivisionEditFetchPayload = {
    id: data.id || '',
    directorateId: data.directorateId,
    name: data.name || '',
  };
  //#endregion

  //#region SUBMIT FORM
  const handleSubmitDivision = (
    formValue: MasterDataDivisionEditFetchPayload,
  ) => {
    const divisionTemp = formValue.name;
    const formattedValue = {
      ...formValue,
      name: divisionTemp || '',
    };
    dispatch(masterDataDivisionEditAction.fetch(formattedValue));
  };
  //#endregion

  //#region RENDER
  return (
    <div className="master-data-division-form-edit-container">
      <MasterDataDivisionFormEditForm
        initialValues={initialValues}
        isLoading={isLoading}
        handleCancel={onCancel}
        handleSubmitDivision={handleSubmitDivision}
      />
    </div>
  );
};

export default MasterDataDivisionFormEditContainer;
