import {CommonError} from '../../../../../../config/constant';

export const MasterDataCategoryDeleteAction = {
  FETCH: 'MASTER_DATA_CATEGORY_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_CATEGORY_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_CATEGORY_DELETE_FAILED',
  RESET: 'MASTER_DATA_CATEGORY_DELETE_RESET',
};

export interface MasterDataCategoryDeleteInitialState {
  masterDataCategoryDeleteLoading: boolean;
  masterDataCategoryDeleteParams?: MasterDataCategoryDeleteFetchPayload;
  masterDataCategoryDeleteError?: MasterDataCategoryDeleteFailedPayload;
  masterDataCategoryDeleteResponse?: MasterDataCategoryDeleteSuccessPayload;
}

export interface MasterDataCategoryDeleteFetchPayload {
  id: string;
}
export type MasterDataCategoryDeleteFailedPayload = CommonError;

export interface MasterDataCategoryDeleteSuccessPayload {}
