import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {get} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {workReportListAction} from './workReportList.action';
import {
  WorkReportListAction,
  WorkReportListFetchPayload,
  WorkReportListResponse,
  WorkReportListSuccessPayload,
} from './workReportList.type';

function* workerFetch(action: GenericAction<WorkReportListFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  try {
    const res: AxiosResponse<WorkReportListSuccessPayload> = yield call(
      get,
      `${Env.api}/secured/work-report/find-all`,
      action.payload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(
        workReportListAction.success(res.data as WorkReportListResponse),
      );
      yield put(
        workReportListAction.update(res.data as WorkReportListResponse),
      );
    } else {
      yield put(workReportListAction.failed(res.data as CommonError));
    }
  } catch (e) {
    yield put(workReportListAction.failed(e as CommonError));
  }
}

const workReportListWatcher = [
  takeLatest(WorkReportListAction.FETCH, workerFetch),
];

export default workReportListWatcher;
