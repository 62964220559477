//#region IMPORT
// Libraries
import React, {useMemo} from 'react';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../../lang';
// Components
import {
  MButton,
  MDropzone,
  MDatepickerInput,
} from '../../../../../../../components';
// Data
import {FollowUpFormData} from '../../../../../../followUp/entity/followUp.string.entity';
// Assets
import './FollowUpOnProgressForm.component.style.scss';
import moment from 'moment';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: FollowUpFormData;
  findingDate?: string;
  findingDueDate?: string;
  handleCancel(): void;
  handleSubmitFollowUp(_followUp: FollowUpFormData): void;
}
//#endregion

const FollowUpOnProgressForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  findingDate,
  findingDueDate,
  handleSubmitFollowUp,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const acceptedType = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
  const maxSize = 5 * 1000 * 1024;
  const minDate = useMemo(() => {
    if (!findingDate) return moment();
    return moment(findingDate, 'YYYY-MM-DD HH:mm');
  }, [findingDate]);
  const maxDate = useMemo(() => {
    if (!findingDueDate) return moment().add(3, 'day');
    return moment(findingDueDate, 'YYYY-MM-DD HH:mm');
  }, [findingDueDate]);
  //#endregion

  //#region VALIDATION
  const followUpOnProgressValidation = Yup.object().shape({
    fixingDate: Yup.string().required(t('Fixing Date is required.')),
    file: Yup.mixed()
      .required(t('File is required.'))
      .test(
        'file-invalid-type',
        t('File Format does not supported.'),
        (value) => !value || (value && acceptedType.includes(value.type)),
      )
      .test(
        'file-too-large',
        t('Maximum File Size is 5MB.'),
        (value) => !value || (value && value.size <= maxSize),
      ),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="follow-up-on-progress-form">
      <div className="follow-up-on-progress-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={followUpOnProgressValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitFollowUp}>
          {({handleSubmit, values, isValid}): React.ReactElement => {
            return (
              <>
                <div className="follow-up-on-progress-form__section">
                  <div className="follow-up-on-progress-form__section--field">
                    <div className="input-label">{t('Fixing Date')}</div>
                    <Field
                      disabled={isLoading}
                      className="user-input"
                      name="fixingDate"
                      inputValue={values.fixingDate}
                      minDate={minDate}
                      maxDate={maxDate}
                      placeholder={t('Choose Date')}
                      component={MDatepickerInput}
                    />
                  </div>
                  <div className="follow-up-on-progress-form__section--field">
                    <Field
                      disabled={isLoading}
                      className="user-input"
                      name="file"
                      accept={acceptedType}
                      maxSize={maxSize}
                      component={MDropzone}
                    />
                  </div>
                </div>

                <div className="follow-up-on-progress-form__form--action">
                  <div className="follow-up-on-progress-form-action__canceled">
                    <MButton
                      isDisabled={isLoading}
                      buttonLabel={t('Cancel')}
                      handleClick={handleCancel}
                    />
                  </div>
                  <div className="follow-up-on-progress-form-action">
                    <MButton
                      isDisabled={!isValid || isLoading}
                      isLoading={isLoading}
                      buttonLabel={t('Send Follow Up')}
                      handleClick={handleSubmit}
                    />
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default FollowUpOnProgressForm;
