//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import t from '../../../../lang';
import {AppState} from '../../../../config/Interface';
// Components
import {MTabPanel, MTabs} from '../../../../components';
import MasterDataRuteBRTSection from '../sections/operational/MasterDataRuteBRTSection';
import MasterDataRuteNonBRTSection from '../sections/operational/MasterDataRuteNonBRTSection';
import MasterDataRuteBusKecilSection from '../sections/operational/MasterDataRuteBusKecilSection';
// Data
import {PATH, tabContentType} from '../../../wrapper/entity';
// Assets
import './MasterDataManagementOperational.scss';
//#endregion

const MasterDataManagementOperational: React.FC = () => {
  //#region GENERAL
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;
  //#endregion

  //#region HANDLE TAB
  const [tabValue, setTabValue] = useState(0);
  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };
  const masterDataTabLabel: tabContentType[] = [
    {label: t('BRT'), component: <MasterDataRuteBRTSection />},
    {label: t('Non BRT'), component: <MasterDataRuteNonBRTSection />},
    {label: t('Bus Kecil'), component: <MasterDataRuteBusKecilSection />},
  ];
  //#endregion

  if (!permissions?.masterRouteData) return <Redirect to={PATH.HOME} />;
  return (
    <div className="master-data-management-operational">
      <div className="master-data-management-operational__tab">
        <MTabs
          variant="scrollable"
          tabsValue={tabValue}
          handleChangeTab={handleChangeTab}
          tabLabel={masterDataTabLabel.map((item) => item.label)}
        />
      </div>
      <div className="master-data-management-operational__tab-panel">
        {masterDataTabLabel.map((item: tabContentType, index: number) => (
          <MTabPanel
            key={`master-data-tab-label-${item.label}-${index}`}
            value={tabValue}
            index={index}
            className="master-data-management-operational__tab-panel--item">
            {item.component}
          </MTabPanel>
        ))}
      </div>
    </div>
  );
};

export default MasterDataManagementOperational;
