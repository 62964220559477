import {CommonError} from '../../../../config/constant';

export const UserRoleAccessDeleteAction = {
  FETCH: 'USER_ROLE_ACCESS_DELETE_FETCH',
  SUCCESS: 'USER_ROLE_ACCESS_DELETE_SUCCESS',
  FAILED: 'USER_ROLE_ACCESS_DELETE_FAILED',
  RESET: 'USER_ROLE_ACCESS_DELETE_RESET',
};

export interface UserRoleAccessDeleteInitialState {
  userRoleAccessDeleteLoading: boolean;
  userRoleAccessDeleteParams?: UserRoleAccessDeleteFetchPayload;
  userRoleAccessDeleteError?: UserRoleAccessDeleteFailedPayload;
  userRoleAccessDeleteResponse?: UserRoleAccessDeleteSuccessPayload;
}

export type UserRoleAccessDeleteFetchPayload = string;
export type UserRoleAccessDeleteFailedPayload = CommonError;

export interface UserRoleAccessDeleteSuccessPayload {}
