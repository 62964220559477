import * as React from 'react';

const IconPaperUpload = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 16}
    height={props.height || 16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.297 8.594a.487.487 0 0 0 .693 0 .492.492 0 0 0 .006-.7l-1.894-1.92a.512.512 0 0 0-.343-.147.512.512 0 0 0-.35.147L5.52 7.893a.498.498 0 0 0 0 .7.487.487 0 0 0 .693 0l1.05-1.067v3.22a.491.491 0 1 0 .983 0v-3.22l1.05 1.067Zm3.588-2.577c.155-.002.324-.004.478-.004.165 0 .303.134.303.3v5.36c0 1.654-1.333 2.994-2.97 2.994H5.449c-1.723 0-3.116-1.407-3.116-3.14V4.34c0-1.653 1.327-3.006 2.977-3.006h3.525c.165 0 .303.14.303.306v2.147c0 1.22.99 2.22 2.205 2.226.277 0 .524.003.74.004l.455.003.347-.003Zm.184-.973c-.542.002-1.181 0-1.641-.004-.73 0-1.332-.608-1.332-1.345V1.938c0-.288.345-.43.542-.223l1.945 2.042c.266.28.513.538.713.75a.318.318 0 0 1-.227.537Z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconPaperUpload;
