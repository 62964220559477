//#region IMPORT
// Libraries
import React from 'react';
import NumberFormat from 'react-number-format';
// Utils
import t from '../../../../lang';
import {colors} from '../../../../config/_theme';
// Components
import {MButton} from '../../../../components';
// Assets
import './DashboardFinance.component.style.scss';
import {
  IconCaretArrowUp,
  IconCaretArrowDown,
  IconEdit,
} from '../../../../assets/icons';
//#endregion

//#region INTERFACE
interface Props {
  icon: React.ReactElement;
  hasBigTitle?: boolean;
  title: string;
  value?: number;
  desc?: string;
  info?: string | number;
  currency?: string;
  isFine?: boolean;
  handleEdit?(): void;
}
//#endregion

const DashboardFinance: React.FC<Props> = ({
  icon,
  hasBigTitle = true,
  title,
  value,
  desc,
  info,
  currency = 'Rp',
  isFine = false,
  handleEdit,
}: Props) => {
  //#region GENERAL
  const isDecreased = Number(info) < 0;
  //#endregion

  return (
    <div className="dashboard-finance">
      {title && hasBigTitle && (
        <div className="dashboard-finance__header">
          <div className="dashboard-finance__header--bigtitle">{title}</div>
          <div className="dashboard-finance__header--action">
            {handleEdit && (
              <div className="dashboard-finance__header--action-edit">
                <MButton
                  handleClick={handleEdit}
                  buttonLabel={t('Edit')}
                  startIcon={<IconEdit />}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="dashboard-finance__content">
        <div
          className="dashboard-finance__content--icon"
          style={{
            backgroundColor: isFine
              ? colors.secondary.red1
              : colors.secondary.green1,
          }}>
          {icon}
        </div>
        <div className="dashboard-finance__content--value">
          <div className="dashboard-finance__content--value-title">{title}</div>
          <div className="dashboard-finance__content--value-number">
            <NumberFormat
              value={value}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              prefix={`${currency}. `}
              displayType="text"
            />
          </div>
          {desc && (
            <div className="dashboard-finance__content--value-desc">{desc}</div>
          )}
        </div>
        {(info || info == 0) && (
          <div
            className="dashboard-finance__content--info"
            style={
              isFine
                ? {
                    color: isDecreased
                      ? colors.secondary.green
                      : colors.secondary.red,
                  }
                : {
                    color: isDecreased
                      ? colors.secondary.red
                      : colors.secondary.green,
                  }
            }>
            {isDecreased ? (
              <IconCaretArrowDown
                fill={isFine ? colors.secondary.green : colors.secondary.red}
              />
            ) : (
              <IconCaretArrowUp
                fill={isFine ? colors.secondary.red : colors.secondary.green}
              />
            )}
            {`${info}%`}
          </div>
        )}
      </div>
    </div>
  );
};
export default DashboardFinance;
