import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailIndicatorOptionListAction,
  MasterDataDetailIndicatorOptionListFailedPayload,
  MasterDataDetailIndicatorOptionListFetchPayload,
  MasterDataDetailIndicatorOptionListSuccessPayload,
  MasterDataDetailIndicatorOptionListUpdatePayload,
} from './masterDataDetailIndicatorOptionList.type';

export const masterDataDetailIndicatorOptionListAction = {
  fetch: createAction<MasterDataDetailIndicatorOptionListFetchPayload>(
    MasterDataDetailIndicatorOptionListAction.FETCH,
  ),
  success: createAction<MasterDataDetailIndicatorOptionListSuccessPayload>(
    MasterDataDetailIndicatorOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailIndicatorOptionListFailedPayload>(
    MasterDataDetailIndicatorOptionListAction.FAILED,
  ),
  update: createAction<MasterDataDetailIndicatorOptionListUpdatePayload>(
    MasterDataDetailIndicatorOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataDetailIndicatorOptionListAction.RESET),
};
