import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataBusEditAction,
  MasterDataBusEditFailedPayload,
  MasterDataBusEditFetchPayload,
  MasterDataBusEditSuccessPayload,
} from './masterDataBusEdit.type';

export const masterDataBusEditAction = {
  fetch: createAction<MasterDataBusEditFetchPayload>(
    MasterDataBusEditAction.FETCH,
  ),
  success: createAction<MasterDataBusEditSuccessPayload>(
    MasterDataBusEditAction.SUCCESS,
  ),
  failed: createAction<MasterDataBusEditFailedPayload>(
    MasterDataBusEditAction.FAILED,
  ),
  reset: createAction(MasterDataBusEditAction.RESET),
};
