import {createAction} from '@reduxjs/toolkit';
import {
  FollowUpPICConfirmAction,
  FollowUpPICConfirmFailedPayload,
  FollowUpPICConfirmFetchPayload,
  FollowUpPICConfirmSuccessPayload,
} from './followUpPICConfirm.type';

export const followUpPICConfirmAction = {
  fetch: createAction<FollowUpPICConfirmFetchPayload>(
    FollowUpPICConfirmAction.FETCH,
  ),
  success: createAction<FollowUpPICConfirmSuccessPayload>(
    FollowUpPICConfirmAction.SUCCESS,
  ),
  failed: createAction<FollowUpPICConfirmFailedPayload>(
    FollowUpPICConfirmAction.FAILED,
  ),
  reset: createAction(FollowUpPICConfirmAction.RESET),
};
