import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataFormControllerMoveAction,
  MasterDataFormControllerMoveFailedPayload,
  MasterDataFormControllerMoveFetchPayload,
  MasterDataFormControllerMoveSuccessPayload,
} from './masterDataFormControllerMove.type';

export const masterDataFormControllerMoveAction = {
  fetch: createAction<MasterDataFormControllerMoveFetchPayload>(
    MasterDataFormControllerMoveAction.FETCH,
  ),
  success: createAction<MasterDataFormControllerMoveSuccessPayload>(
    MasterDataFormControllerMoveAction.SUCCESS,
  ),
  failed: createAction<MasterDataFormControllerMoveFailedPayload>(
    MasterDataFormControllerMoveAction.FAILED,
  ),
  reset: createAction(MasterDataFormControllerMoveAction.RESET),
};
