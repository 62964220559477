import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDetailObservationListAction,
  MasterDataDetailObservationListFailedPayload,
  MasterDataDetailObservationListFetchPayload,
  MasterDataDetailObservationListSuccessPayload,
  MasterDataDetailObservationListUpdatePayload,
} from './masterDataDetailObservationList.type';

export const masterDataDetailObservationListAction = {
  fetch: createAction<MasterDataDetailObservationListFetchPayload>(
    MasterDataDetailObservationListAction.FETCH,
  ),
  success: createAction<MasterDataDetailObservationListSuccessPayload>(
    MasterDataDetailObservationListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDetailObservationListFailedPayload>(
    MasterDataDetailObservationListAction.FAILED,
  ),
  update: createAction<MasterDataDetailObservationListUpdatePayload>(
    MasterDataDetailObservationListAction.UPDATE,
  ),
  reset: createAction(MasterDataDetailObservationListAction.RESET),
};
