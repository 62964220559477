import {createAction} from '@reduxjs/toolkit';
import {
  EvaluationReportListAction,
  EvaluationReportListFailedPayload,
  EvaluationReportListFetchPayload,
  EvaluationReportListSuccessPayload,
  EvaluationReportListUpdatePayload,
} from './evaluationReportList.type';

export const evaluationReportListAction = {
  fetch: createAction<EvaluationReportListFetchPayload>(
    EvaluationReportListAction.FETCH,
  ),
  success: createAction<EvaluationReportListSuccessPayload>(
    EvaluationReportListAction.SUCCESS,
  ),
  failed: createAction<EvaluationReportListFailedPayload>(
    EvaluationReportListAction.FAILED,
  ),
  update: createAction<EvaluationReportListUpdatePayload>(
    EvaluationReportListAction.UPDATE,
  ),
  reset: createAction(EvaluationReportListAction.RESET),
};
