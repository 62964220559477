import {CommonError} from '../../../../../../config/constant';
import {MasterDataAssignmentLetterFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataAssignmentLetterEditAction = {
  FETCH: 'MASTER_DATA_ASSIGNMENT_LETTER_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_ASSIGNMENT_LETTER_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_ASSIGNMENT_LETTER_EDIT_FAILED',
  RESET: 'MASTER_DATA_ASSIGNMENT_LETTER_EDIT_RESET',
};

export interface MasterDataAssignmentLetterEditInitialState {
  masterDataAssignmentLetterEditLoading: boolean;
  masterDataAssignmentLetterEditParams?: MasterDataAssignmentLetterEditFetchPayload;
  masterDataAssignmentLetterEditError?: MasterDataAssignmentLetterEditFailedPayload;
  masterDataAssignmentLetterEditResponse?: MasterDataAssignmentLetterEditSuccessPayload;
}

export type MasterDataAssignmentLetterEditFetchPayload = MasterDataAssignmentLetterFormData;

export type MasterDataAssignmentLetterEditFailedPayload = CommonError;

export interface MasterDataAssignmentLetterEditSuccessPayload {}
