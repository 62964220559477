//#region IMPORT
// Libraries
import React, {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
// Utils
import t from '../../../../../../lang';
import {useLinkParams} from '../../../../../wrapper/hooks';
import {AppState} from '../../../../../../config/Interface';
import {masterDataManagementDataToLabel} from '../../../../utils';
// Components
import {MTabPanel, MTabs} from '../../../../../../components';
import MasterDataBasicServiceTypeSection from '../MasterDataBasicServiceTypeSection';
import MasterDataFormControllerSection from '../MasterDataFormControllerSection';
import MasterDataCategorySection from '../MasterDataCategorySection';
import MasterDataDetailIndicatorSection from '../MasterDataDetailIndicatorSection';
import MasterDataDetailObservationSection from '../MasterDataDetailObservationSection';
import MasterDataIndicatorValueSection from '../MasterDataIndicatorValueSection';
import MasterDataObservationSection from '../MasterDataObservationSection';
import MasterDataObservationValueSection from '../MasterDataObservationValueSection';
import MasterDataReviewIndicatorSection from '../MasterDataReviewIndicatorSection';
// Data
import {MasterDataManagementData} from '../../../../entity/masterData.string.entity';
import {PATH, tabContentType} from '../../../../../wrapper/entity';
// Assets
import './MasterDataManagementFormAssessment.scss';
//#endregion

const MasterDataManagementFormAssessment: React.FC = () => {
  //#region GENERAL
  const {data} = useSelector((state: AppState) => state.user, shallowEqual);
  const permissions = data?.role?.permissions;
  //#endregion

  //#region HANDLE TAB
  const [tabValue, setTabValue] = useState(0);
  const urlParams: MasterDataManagementData =
    (useLinkParams().get('type') as MasterDataManagementData) ||
    MasterDataManagementData.HALTE;
  const assignmentType = urlParams.replaceAll(' ', '').toUpperCase();
  const handleChangeTab = (_: unknown, newValue: number) => {
    setTabValue(newValue);
  };
  const masterDataTabLabel: tabContentType[] = [
    {
      label: t(masterDataManagementDataToLabel(urlParams)),
      component: (
        <MasterDataFormControllerSection assignmentType={assignmentType} />
      ),
    },
    {
      label: t('Category'),
      component: <MasterDataCategorySection assignmentType={assignmentType} />,
    },
    {
      label: t('Basic Service Type'),
      component: (
        <MasterDataBasicServiceTypeSection assignmentType={assignmentType} />
      ),
    },
    {
      label: t('Review Indicator'),
      component: (
        <MasterDataReviewIndicatorSection assignmentType={assignmentType} />
      ),
    },
    {
      label: t('Detail Indicator'),
      component: (
        <MasterDataDetailIndicatorSection assignmentType={assignmentType} />
      ),
    },
    {
      label: t('Indicator Value'),
      component: (
        <MasterDataIndicatorValueSection assignmentType={assignmentType} />
      ),
    },
    {
      label: t('Observation'),
      component: (
        <MasterDataObservationSection assignmentType={assignmentType} />
      ),
    },
    {
      label: t('Detail Observation'),
      component: (
        <MasterDataDetailObservationSection assignmentType={assignmentType} />
      ),
    },
    {
      label: t('Observation Value'),
      component: (
        <MasterDataObservationValueSection assignmentType={assignmentType} />
      ),
    },
  ];
  //#endregion

  if (!permissions?.masterEvaluationForm) return <Redirect to={PATH.HOME} />;
  return (
    <div className="master-data-management-form-assessment">
      <div className="master-data-management-form-assessment__tab">
        <MTabs
          variant="scrollable"
          tabsValue={tabValue}
          handleChangeTab={handleChangeTab}
          tabLabel={masterDataTabLabel.map((item) => item.label)}
        />
      </div>
      <div className="master-data-management-form-assessment__tab-panel">
        {masterDataTabLabel.map((item: tabContentType, index: number) => (
          <MTabPanel
            key={`master-data-tab-label-${item.label}-${index}`}
            value={tabValue}
            index={index}
            className="master-data-management-form-assessment__tab-panel--item">
            {item.component}
          </MTabPanel>
        ))}
      </div>
    </div>
  );
};

export default MasterDataManagementFormAssessment;
