import {AxiosResponse} from 'axios';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {Env} from '../../../../config/Config';
import {CommonError, GenericAction} from '../../../../config/constant';
import {AppState} from '../../../../config/Interface';
import {httpPut} from '../../../../services/http';
import {generateHeaders} from '../../../../utils';
import {UserState} from '../../../user/usecases/user.reducer';
import {workReportEditAction} from './workReportEdit.action';
import {
  WorkReportEditAction,
  WorkReportEditFetchPayload,
  WorkReportEditSuccessPayload,
} from './workReportEdit.type';

function* workerFetch(action: GenericAction<WorkReportEditFetchPayload>) {
  const userData: UserState = yield select((state: AppState) => state.user);
  const {id} = action.payload;
  const formDataPayload = new FormData();
  formDataPayload.append('name', action.payload.name);
  formDataPayload.append('year', action.payload.year as string);
  formDataPayload.append('month', action.payload.month as string);
  formDataPayload.append('reportType', action.payload.reportType);
  formDataPayload.append('mainFile', action.payload.mainFile as Blob);
  action.payload.supportFile &&
    formDataPayload.append('supportFile', action.payload.supportFile as Blob);
  action.payload.departmentId &&
    formDataPayload.append('departmentId', action.payload.departmentId);

  try {
    const res: AxiosResponse<WorkReportEditSuccessPayload> = yield call(
      httpPut,
      `${Env.api}/secured/work-report/upload/${id}`,
      formDataPayload,
      generateHeaders({
        authorization: {
          token: userData?.accessToken || '',
          tokenType: userData?.tokenType || '',
        },
      }),
    );
    if (res.data.status === 'success') {
      yield put(workReportEditAction.success(res.data));
    } else {
      yield put(workReportEditAction.failed(res.data as CommonError));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    yield put(workReportEditAction.failed(e.response.data.payload));
  }
}

const workReportEditWatcher = [
  takeLatest(WorkReportEditAction.FETCH, workerFetch),
];

export default workReportEditWatcher;
