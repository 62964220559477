/* eslint-disable  @typescript-eslint/no-explicit-any */
//#region IMPORT
// Libraries
import React from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// Data
import {MasterDataAssignmentLetter} from '../../../../../../entity/masterData.string.entity';
// Assets
import './MasterDataAssignmentLetterManagementDetail.component.style.scss';

//#endregion

//#region INTERFACE
interface Props {
  data?: MasterDataAssignmentLetter;
  pageNumber?: number;
  numPages?: number;
  scale?: number;
  onNumPages?(pdf: any): void;
}
//#endregion

const MasterDataAssignmentLetterManagementDetail: React.FC<Props> = ({
  data,
  pageNumber = 1,
  numPages = 1,
  scale = 100,
  onNumPages,
}: Props) => {
  const fixedScale = scale / 100;
  return (
    <div className="master-data-assignment-letter-management-detail">
      <Document
        className="master-data-assignment-letter-management-detail__document"
        file={data?.file}
        onLoadSuccess={(pdf: any) => onNumPages && onNumPages(pdf)}>
        <div className="master-data-assignment-letter-management-detail__pagelist">
          {Array.from(new Array(numPages), (element, idx) => (
            <Page
              key={`master-data-assignment-letter-management-detail__page-${
                idx + 1
              }`}
              className="master-data-assignment-letter-management-detail__pagelist--pageitem"
              inputRef={
                pageNumber === idx + 1
                  ? (ref: any) => ref && ref.scrollIntoView()
                  : null
              }
              pageNumber={idx + 1}
              scale={fixedScale}
            />
          ))}
        </div>
      </Document>
    </div>
  );
};

export default MasterDataAssignmentLetterManagementDetail;
