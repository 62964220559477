import {
  MasterDataBusOptionListAction,
  MasterDataBusOptionListSuccessPayload,
  MasterDataBusOptionListFailedPayload,
  MasterDataBusOptionListFetchPayload,
} from './masterDataBusOptionList.type';
import {MasterDataState} from '../../../masterData.reducer';
import {GenericAction} from '../../../../../../config/constant';
import {masterDataBusOptionListInitialState} from './masterDataBusOptionList.reducer';

export const masterDataBusOptionListHandler = {
  [MasterDataBusOptionListAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusOptionListFetchPayload>,
  ): MasterDataState => {
    return {
      ...state,
      masterDataBusOptionListLoading: true,
      masterDataBusOptionListParams: action.payload,
      action: MasterDataBusOptionListAction.FETCH,
    };
  },
  [MasterDataBusOptionListAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusOptionListLoading: false,
    masterDataBusOptionListResponse: action.payload,
    action: MasterDataBusOptionListAction.SUCCESS,
  }),
  [MasterDataBusOptionListAction.UPDATE]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusOptionListSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusOptionListLoading: false,
    masterDataBusOptionListData: [
      ...state.masterDataBusOptionListData,
      ...action.payload.data,
    ],
    action: MasterDataBusOptionListAction.UPDATE,
  }),
  [MasterDataBusOptionListAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataBusOptionListFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataBusOptionListLoading: false,
    masterDataBusOptionListError: action.payload,
    action: MasterDataBusOptionListAction.FAILED,
  }),
  [MasterDataBusOptionListAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataBusOptionListInitialState,
    action: MasterDataBusOptionListAction.RESET,
  }),
};
