import {CommonError} from '../../../../../../config/constant';

export const MasterDataAssignmentLetterDeleteAction = {
  FETCH: 'MASTER_DATA_ASSIGNMENT_LETTER_DELETE_FETCH',
  SUCCESS: 'MASTER_DATA_ASSIGNMENT_LETTER_DELETE_SUCCESS',
  FAILED: 'MASTER_DATA_ASSIGNMENT_LETTER_DELETE_FAILED',
  RESET: 'MASTER_DATA_ASSIGNMENT_LETTER_DELETE_RESET',
};

export interface MasterDataAssignmentLetterDeleteInitialState {
  masterDataAssignmentLetterDeleteLoading: boolean;
  masterDataAssignmentLetterDeleteParams?: MasterDataAssignmentLetterDeleteFetchPayload;
  masterDataAssignmentLetterDeleteError?: MasterDataAssignmentLetterDeleteFailedPayload;
  masterDataAssignmentLetterDeleteResponse?: MasterDataAssignmentLetterDeleteSuccessPayload;
}

export interface MasterDataAssignmentLetterDeleteFetchPayload {
  id: string;
}
export type MasterDataAssignmentLetterDeleteFailedPayload = CommonError;

export interface MasterDataAssignmentLetterDeleteSuccessPayload {}
