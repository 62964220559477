import {userLoginHandler} from './userLogin/userLogin.handler';
import {userDataHandler} from './userData/userData.handler';
import {userForgetPasswordHandler} from './userForgetPassword/userForgetPassword.handler';
import {userOTPPasswordHandler} from './userOTPPassword/userOTPPassword.handler';
import {userChangePasswordHandler} from './userChangePassword/userChangePassword.handler';
import {userAccessCreateHandler} from './userAccessCreate/userAccessCreate.handler';
import {userAccessDeleteHandler} from './userAccessDelete/userAccessDelete.handler';
import {userAccessEditHandler} from './userAccessEdit/userAccessEdit.handler';
import {userAccessListHandler} from './userAccessList/userAccessList.handler';
import {userAccessSetActiveHandler} from './userAccessSetActive/userAccessSetActive.handler';
import {userRoleAccessCreateHandler} from './userRoleAccessCreate/userRoleAccessCreate.handler';
import {userRoleAccessDeleteHandler} from './userRoleAccessDelete/userRoleAccessDelete.handler';
import {userRoleAccessEditHandler} from './userRoleAccessEdit/userRoleAccessEdit.handler';
import {userRoleAccessListHandler} from './userRoleAccessList/userRoleAccessList.handler';
import {userAccountEditHandler} from './userAccountEdit/userAccountEdit.handler';
import {userAccountDetailHandler} from './userAccountDetail/userAccountDetail.handler';

export default {
  ...userLoginHandler,
  ...userDataHandler,
  ...userForgetPasswordHandler,
  ...userOTPPasswordHandler,
  ...userChangePasswordHandler,
  ...userAccessCreateHandler,
  ...userAccessDeleteHandler,
  ...userAccessEditHandler,
  ...userAccessListHandler,
  ...userAccessSetActiveHandler,
  ...userRoleAccessCreateHandler,
  ...userRoleAccessDeleteHandler,
  ...userRoleAccessEditHandler,
  ...userRoleAccessListHandler,
  ...userAccountEditHandler,
  ...userAccountDetailHandler,
};
