import {createAction} from '@reduxjs/toolkit';
import {
  DashboardOverviewChartSectionAction,
  DashboardOverviewChartSectionFailedPayload,
  DashboardOverviewChartSectionFetchPayload,
  DashboardOverviewChartSectionSuccessPayload,
} from './dashboardOverviewChartSection.type';

export const dashboardOverviewChartSectionAction = {
  fetch: createAction<DashboardOverviewChartSectionFetchPayload>(
    DashboardOverviewChartSectionAction.FETCH,
  ),
  success: createAction<DashboardOverviewChartSectionSuccessPayload>(
    DashboardOverviewChartSectionAction.SUCCESS,
  ),
  failed: createAction<DashboardOverviewChartSectionFailedPayload>(
    DashboardOverviewChartSectionAction.FAILED,
  ),
  reset: createAction(DashboardOverviewChartSectionAction.RESET),
};
