import {CommonData, CommonError} from '../../../../../../config/constant';
import {MasterDataCategory} from '../../../../entity/masterData.string.entity';

export const MasterDataCategoryListAction = {
  FETCH: 'MASTER_DATA_CATEGORY_LIST_FETCH',
  SUCCESS: 'MASTER_DATA_CATEGORY_LIST_SUCCESS',
  FAILED: 'MASTER_DATA_CATEGORY_LIST_FAILED',
  RESET: 'MASTER_DATA_CATEGORY_LIST_RESET',
  UPDATE: 'MASTER_DATA_CATEGORY_LIST_UPDATE',
};

export interface MasterDataCategoryListInitialState {
  masterDataCategoryListLoading: boolean;
  masterDataCategoryListData: MasterDataCategory[];
  masterDataCategoryListError?: MasterDataCategoryListFailedPayload;
  masterDataCategoryListParams?: MasterDataCategoryListFetchPayload;
  masterDataCategoryListResponse?: MasterDataCategoryListSuccessPayload;
}

export type MasterDataCategoryListResponse = {
  status: string;
  count?: number;
  data: CommonData<MasterDataCategory[]>;
};

export type MasterDataCategoryListFailedPayload = CommonError;

export type MasterDataCategoryListSuccessPayload = MasterDataCategoryListResponse;

export type MasterDataCategoryListUpdatePayload = MasterDataCategoryListResponse;

export interface MasterDataCategoryListFetchPayload {
  size?: number;
  page?: number;
  search?: string;
  assignmentType: string;
}
