/* eslint-disable max-lines */
//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Field, Formik} from 'formik';
// Utils
import t from '../../../../../../lang';
import {AppState} from '../../../../../../config/Interface';
import {UserState} from '../../../../usecases/user.reducer';
import {userRoleAccessListAction} from '../../../../usecases/userRoleAccessList/userRoleAccessList.action';
import {masterDataDirectorateOptionListAction} from '../../../../../masterData/usecases/masterData.action';
import {masterDataDivisionOptionListAction} from '../../../../../masterData/usecases/section/office/masterDataDivisionOptionList/masterDataDivisionOptionList.action';
import {masterDataDepartmentOptionListAction} from '../../../../../masterData/usecases/masterData.action';
import {
  getOptionFromRoleList,
  getOptionFromDirectorateList,
  getOptionFromDivisionList,
  getOptionFromDepartmentList,
  getUserLevelFromRoleId,
} from '../../../../../../utils';
// Components
import {
  MButton,
  MSelect,
  MTextField,
  MRadioButton,
} from '../../../../../../components';
// Data
import {
  UserType,
  UserAccessFormData,
  UserLevel,
} from '../../../../entity/user.string.entity';
import {emailRegex, emailTJRegex} from '../../../../../../config/constant';
// Assets
import './UserForm.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  isLoading?: boolean;
  initialValues: UserAccessFormData;
  handleCancel(): void;
  handleSubmitUser(_user: UserAccessFormData): void;
}
//#endregion

const UserForm: React.FC<Props> = ({
  isLoading,
  initialValues,
  handleSubmitUser,
  handleCancel,
}: Props) => {
  //#region GENERAL
  const dispatch = useDispatch();
  const isEdit = !!initialValues?.id;
  const [directorateId, setDirectorateId] = useState(
    initialValues?.directorateId ?? '',
  );
  const [divisionId, setDivisionId] = useState(initialValues?.divisionId ?? '');
  const userLevelDirectorate = Number(
    UserLevel.DIREKSI.replace('USERLEVEL', ''),
  );
  const userLevelDivision = Number(
    UserLevel.KEPALADIVISI.replace('USERLEVEL', ''),
  );
  const user: UserState = useSelector(
    (state: AppState) => state.user,
    shallowEqual,
  );
  const masterData = useSelector(
    (state: AppState) => state.masterData,
    shallowEqual,
  );
  //#endregion

  //#region FETCH DIRECTORATE
  const fetchDirectorateOptionList = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region FETCH DIVISION
  const fetchDivisionOptionList = useCallback(() => {
    if (directorateId)
      dispatch(
        masterDataDivisionOptionListAction.fetch({
          directorateId: directorateId,
        }),
      );
  }, [dispatch, directorateId]);

  useEffect(() => {
    dispatch(masterDataDivisionOptionListAction.reset());
    fetchDivisionOptionList();

    return () => {
      dispatch(masterDataDivisionOptionListAction.reset());
    };
  }, [dispatch, fetchDivisionOptionList]);
  //#endregion

  //#region FETCH DEPARTMENT
  const fetchDepartmentOptionList = useCallback(() => {
    if (divisionId)
      dispatch(
        masterDataDepartmentOptionListAction.fetch({
          divisionId: divisionId,
        }),
      );
  }, [dispatch, divisionId]);

  useEffect(() => {
    dispatch(masterDataDepartmentOptionListAction.reset());
    fetchDepartmentOptionList();

    return () => {
      dispatch(masterDataDepartmentOptionListAction.reset());
    };
  }, [dispatch, fetchDepartmentOptionList]);
  //#endregion

  //#region FETCH ROLE
  const fetchRoleList = useCallback(() => {
    dispatch(userRoleAccessListAction.fetch({}));
  }, [dispatch]);
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(masterDataDirectorateOptionListAction.reset());
    dispatch(masterDataDivisionOptionListAction.reset());
    dispatch(masterDataDepartmentOptionListAction.reset());
    dispatch(userRoleAccessListAction.reset());
    fetchDirectorateOptionList();
    fetchRoleList();

    return () => {
      dispatch(masterDataDirectorateOptionListAction.reset());
      dispatch(masterDataDivisionOptionListAction.reset());
      dispatch(masterDataDepartmentOptionListAction.reset());
      dispatch(userRoleAccessListAction.reset());
    };
  }, [dispatch, fetchDirectorateOptionList, fetchRoleList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region VALIDATION
  const userAccessValidation = Yup.object().shape({
    type: Yup.string(),
    userLevel: Yup.number(),
    nik: Yup.string().when('type', {
      is: UserType.INTERNAL,
      then: Yup.string().required(t('NIK is required.')),
      otherwise: Yup.string().notRequired(),
    }),
    name: Yup.string()
      .required(t('Fullname is required.'))
      .min(
        3,
        `${t('Fullname')} ${t('must be filled in at least')} ${3} ${t(
          'letters',
        )}.`,
      ),
    email: Yup.string()
      .required(t('Email is required.'))
      .matches(emailRegex, t('Must be a valid email.'))
      .when('type', {
        is: UserType.INTERNAL,
        then: Yup.string().matches(
          emailTJRegex,
          t('Must be a valid TransJakarta email.'),
        ),
        otherwise: Yup.string(),
      }),
    roleId: Yup.string().required(t('Role is required.')),
    directorateId: Yup.string().when(['type', 'userLevel'], {
      is: (type: UserType, userLevel: number) => {
        if (type === UserType.INTERNAL && userLevel >= userLevelDirectorate)
          return true;
        return false;
      },
      then: Yup.string().required(t('Directorate is required.')),
      otherwise: Yup.string().notRequired(),
    }),
    divisionId: Yup.string().when(['type', 'userLevel'], {
      is: (type: UserType, userLevel: number) => {
        if (type === UserType.INTERNAL && userLevel >= userLevelDivision)
          return true;
        return false;
      },
      then: Yup.string().required(t('Division is required.')),
      otherwise: Yup.string().notRequired(),
    }),
    departmentId: Yup.string().when(['type', 'userLevel'], {
      is: (type: UserType, userLevel: number) => {
        if (type === UserType.INTERNAL && userLevel > userLevelDivision)
          return true;
        return false;
      },
      then: Yup.string().required(t('Department is required.')),
      otherwise: Yup.string().notRequired(),
    }),
  });
  //#endregion

  //#region RENDER
  return (
    <div className="user-form">
      <div className="user-form__form">
        <Formik
          initialValues={initialValues}
          validationSchema={userAccessValidation}
          validateOnBlur={false}
          validateOnMount={false}
          onSubmit={handleSubmitUser}>
          {({
            handleSubmit,
            values,
            setFieldValue,
            isValid,
          }): React.ReactElement => (
            <>
              <div className="user-form__section">
                <div className="user-form__section--field">
                  <div className="input-label">{t('Category')}</div>
                  <Field
                    className="user-input"
                    name="type"
                    options={[
                      {label: 'Internal', value: UserType.INTERNAL},
                      {label: 'External', value: UserType.EXTERNAL},
                    ]}
                    handleOnChange={() => {
                      setFieldValue('nik', '', true);
                      setFieldValue('directorateId', '', true);
                      setFieldValue('divisionId', '', true);
                      setFieldValue('departmentId', '', true);
                    }}
                    disabled={isEdit || isLoading}
                    component={MRadioButton}
                  />
                </div>
                {values.type === UserType.INTERNAL && (
                  <div className="user-form__section--field">
                    <div className="input-label">{t('NIK')}</div>
                    <Field
                      className="user-input"
                      name="nik"
                      placeholder={t('Enter NIK')}
                      disabled={isLoading}
                      component={MTextField}
                    />
                  </div>
                )}
                <div className="user-form__section--field">
                  <div className="input-label">{t('Fullname')}</div>
                  <Field
                    disabled={isLoading}
                    className="user-input"
                    name="name"
                    placeholder={t('Enter Fullname')}
                    component={MTextField}
                  />
                </div>
                <div className="user-form__section--field">
                  <div className="input-label">{t('Email')}</div>
                  <Field
                    disabled={isEdit || isLoading}
                    className="user-input"
                    name="email"
                    type="email"
                    placeholder={t('Enter Email')}
                    component={MTextField}
                  />
                </div>
                <div className="user-form__section--field">
                  <div className="input-label">{t('Role')}</div>
                  <Field
                    className="user-input"
                    name="roleId"
                    placeholder={t('Choose Role')}
                    options={getOptionFromRoleList(user.userRoleAccessListData)}
                    handleChangeSelect={(roleId: string) => {
                      setFieldValue('directorateId', '', true);
                      setFieldValue('divisionId', '', true);
                      setFieldValue('departmentId', '', true);
                      const userLevel: number = +getUserLevelFromRoleId(
                        user.userRoleAccessListData,
                        roleId,
                      ).replace('USERLEVEL', '');
                      setFieldValue('userLevel', userLevel, true);
                    }}
                    disabled={isLoading}
                    component={MSelect}
                  />
                </div>
                {values.type === UserType.INTERNAL && (
                  <>
                    {values.userLevel >= userLevelDirectorate && (
                      <div className="user-form__section--field">
                        <div className="input-label">{t('Directorate')}</div>
                        <Field
                          className="user-input"
                          name="directorateId"
                          placeholder={t('Choose Directorate')}
                          options={getOptionFromDirectorateList(
                            masterData.masterDataDirectorateOptionListData,
                          )}
                          handleChangeSelect={(e: string) => {
                            setDirectorateId(e);
                            setFieldValue('divisionId', '', true);
                            setFieldValue('departmentId', '', true);
                          }}
                          disabled={isLoading}
                          component={MSelect}
                        />
                      </div>
                    )}
                    {values.userLevel >= userLevelDivision && (
                      <div className="user-form__section--field">
                        <div className="input-label">{t('Division')}</div>
                        <Field
                          className="user-input"
                          name="divisionId"
                          placeholder={t('Choose Division')}
                          options={getOptionFromDivisionList(
                            masterData.masterDataDivisionOptionListData,
                          )}
                          handleChangeSelect={(e: string) => {
                            setDivisionId(e);
                            setFieldValue('departmentId', '', true);
                          }}
                          disabled={isLoading || !directorateId}
                          component={MSelect}
                        />
                      </div>
                    )}
                    {values.userLevel > userLevelDivision && (
                      <div className="user-form__section--field">
                        <div className="input-label">{t('Department')}</div>
                        <Field
                          className="user-input"
                          name="departmentId"
                          placeholder={t('Choose Department')}
                          options={getOptionFromDepartmentList(
                            masterData.masterDataDepartmentOptionListData,
                          )}
                          disabled={isLoading || !divisionId}
                          component={MSelect}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="user-form__form--action">
                <div className="user-form-action__canceled">
                  <MButton
                    isDisabled={isLoading}
                    buttonLabel={t('Cancel')}
                    handleClick={handleCancel}
                  />
                </div>
                <div className="user-form-action">
                  <MButton
                    isDisabled={!isValid || isLoading}
                    isLoading={isLoading}
                    buttonLabel={!isEdit ? t('Add') : t('Edit')}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
  //#endregion
};

export default UserForm;
