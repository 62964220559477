//#region IMPORT
// Libraries
import React from 'react';
import {TableHead, TableRow, TableCell} from '@mui/material';
// Utils
import t from '../../../../../../../../lang';
//#endregion

const MasterDataReviewIndicatorManagementListTableHeader: React.FC = () => {
  return (
    <TableHead className="master-data-review-indicator-management-list-table-header">
      <TableRow className="master-data-review-indicator-management-list-table-header__row">
        <TableCell
          align="center"
          padding="normal"
          width="5%"
          className="table-header-label">
          {t('No')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="15%"
          className="table-header-label">
          {t('Review Indicator')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="10%"
          className="table-header-label">
          {t('Target')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="10%"
          className="table-header-label">
          {t('SPM Weight')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="10%"
          className="table-header-label">
          {t('SLA')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="20%"
          className="table-header-label">
          {t('Department PIC')}
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          width="10%"
          className="table-header-label">
          {t('Role')}
        </TableCell>

        <TableCell
          className="master-data-review-indicator-management-list-table-header__row--action table-header-label"
          align="right"
          padding="none"
        />
      </TableRow>
    </TableHead>
  );
};

export default MasterDataReviewIndicatorManagementListTableHeader;
