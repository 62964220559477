//#region IMPORT
// Libraries
import React from 'react';
import moment from 'moment';
// Utils
import t from '../../../../../../../lang';
// Components
import {
  FollowUpCardContent,
  FollowUpCardHeader,
  FollowUpCardInput,
  FollowUpCardNotes,
  FollowUpDivider,
} from '../../../../../components';
// Data
import {
  FollowUp,
  FollowUpExpiredFormData,
} from '../../../../../entity/followUp.string.entity';
// Assets
import './FollowUpExpiredListItem.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data?: FollowUp;
  isLoading?: boolean;
  isDetail?: boolean;
  isCanSendNote?: boolean;
  onViewItem(): void;
  handleSubmitExpired(note: FollowUpExpiredFormData): void;
}
//#endregion

const FollowUpExpiredListItem: React.FC<Props> = ({
  data,
  isLoading,
  isDetail,
  isCanSendNote,
  onViewItem,
  handleSubmitExpired,
}: Props) => {
  return (
    <div
      className="follow-up-expired-list-item"
      onClick={(e) => {
        e.stopPropagation();
        onViewItem();
      }}>
      <div className="follow-up-expired-list-item__header">
        <FollowUpCardHeader headerData={data} />
      </div>
      <FollowUpDivider />
      <div className="follow-up-expired-list-item__content">
        {data?.findingImg && (
          <FollowUpCardContent
            contentData={{
              name: data?.findingIndicator || '-',
              image: data?.findingImg,
              value: data?.finding || '-',
            }}
            title={t('Findings')}
            isDetail={isDetail}
          />
        )}
      </div>
      <FollowUpDivider />
      {data?.chiefDeptNote ? (
        <div className="follow-up-approved-list-item__notes">
          <FollowUpCardNotes
            notesData={{
              title: t('Chief of Department Note'),
              value: data?.chiefDeptNote || '-',
              date: data?.chiefDeptNoteDate
                ? moment(data?.chiefDeptNoteDate, 'YYYY-MM-DD HH:mm').format(
                    'DD/MM/YYYY HH:mm',
                  )
                : '-',
            }}
          />
        </div>
      ) : (
        <div className="follow-up-expired-list-item__input">
          <FollowUpCardInput
            initialValues={{id: data?.id, note: ''}}
            label={t('Expired Note')}
            placeholder={t('Enter Expired Note')}
            isLoading={isLoading}
            isDisabled={!isCanSendNote}
            isDetail={isDetail}
            handleSubmitFollowUp={handleSubmitExpired}
          />
        </div>
      )}
    </div>
  );
};
export default FollowUpExpiredListItem;
