import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDirectorateOptionListAction,
  MasterDataDirectorateOptionListFailedPayload,
  MasterDataDirectorateOptionListFetchPayload,
  MasterDataDirectorateOptionListSuccessPayload,
  MasterDataDirectorateOptionListUpdatePayload,
} from './masterDataDirectorateOptionList.type';

export const masterDataDirectorateOptionListAction = {
  fetch: createAction<MasterDataDirectorateOptionListFetchPayload>(
    MasterDataDirectorateOptionListAction.FETCH,
  ),
  success: createAction<MasterDataDirectorateOptionListSuccessPayload>(
    MasterDataDirectorateOptionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDirectorateOptionListFailedPayload>(
    MasterDataDirectorateOptionListAction.FAILED,
  ),
  update: createAction<MasterDataDirectorateOptionListUpdatePayload>(
    MasterDataDirectorateOptionListAction.UPDATE,
  ),
  reset: createAction(MasterDataDirectorateOptionListAction.RESET),
};
