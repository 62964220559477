import {createAction} from '@reduxjs/toolkit';
import {
  FollowUpExpiredNoteCreateAction,
  FollowUpExpiredNoteCreateFailedPayload,
  FollowUpExpiredNoteCreateFetchPayload,
  FollowUpExpiredNoteCreateSuccessPayload,
} from './followUpExpiredNoteCreate.type';

export const followUpExpiredNoteCreateAction = {
  fetch: createAction<FollowUpExpiredNoteCreateFetchPayload>(
    FollowUpExpiredNoteCreateAction.FETCH,
  ),
  success: createAction<FollowUpExpiredNoteCreateSuccessPayload>(
    FollowUpExpiredNoteCreateAction.SUCCESS,
  ),
  failed: createAction<FollowUpExpiredNoteCreateFailedPayload>(
    FollowUpExpiredNoteCreateAction.FAILED,
  ),
  reset: createAction(FollowUpExpiredNoteCreateAction.RESET),
};
