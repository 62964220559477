//#region IMPORT
// Libraries
import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RangeInput} from '@mui/lab/DateRangePicker/RangeTypes';
import {Moment} from 'moment';
// Utils
import t from '../../../../../lang';
import {AppState} from '../../../../../config/Interface';
import {FollowUpState} from '../../../usecases/followUp.reducer';
import {followUpListAction} from '../../../usecases/followUp.action';
import {FollowUpListAction} from '../../../usecases/followUp.type';
import {actionHandler} from '../../../../wrapper/utils';
import {wrapperNotifyAction} from '../../../../wrapper/usecases/wrapper.type';
import {WrapperNotifyAction} from '../../../../wrapper/usecases/WrapperNotify/wrapperNotify.type';
// Components
import {Modal, MSpinner, MTablePagination} from '../../../../../components';
import {FollowUpEmpty, FollowUpFilter} from '../../../components';
import {FollowUpWaitingList, FollowUpWaitingDetail} from './components';
// Data
import {AssignmentType, SortType} from '../../../../wrapper/entity';
import {
  FollowUp,
  followUpFilterOption,
  FollowUpStatus,
} from '../../../entity/followUp.string.entity';
// Assets
import './FollowUpWaitingSection.scss';
//#endregion

const FollowUpWaitingSection: React.FC = () => {
  //#region GENERAL
  const dispatch = useDispatch();

  const followUpState: FollowUpState = useSelector(
    (state: AppState) => state.followUp,
    shallowEqual,
  );
  const isLoadingFollowUp: boolean = followUpState.followUpListLoading;

  const [isViewFollowUp, setIsViewFollowUp] = useState<FollowUp | undefined>(
    undefined,
  );
  //#endregion

  //#region FILTER
  const [selectedToggleFilter, setSelectedToggleFilter] = useState<
    string | null
  >(followUpFilterOption.ALL);

  const [selectedCheckmarksFilter, setSelectedCheckmarksFilter] = useState<
    string[]
  >([
    AssignmentType.HALTE,
    AssignmentType.BUSBRT,
    AssignmentType.BUSNONBRT,
    AssignmentType.BUSKECIL,
    AssignmentType.TRANSCARE,
  ]);

  const [selectedDateFilter, setSelectedDateFilter] = useState<
    RangeInput<unknown>
  >([null, null]);
  //#endregion

  //#region SORT
  const [selectedSort, setSelectedSort] = useState<SortType>(SortType.DESC);
  //#endregion

  //#region PAGINATION CONTROL
  const [followUpListPage, setFollowUpListPage] = useState<number>(0);

  const [followUpListCount, setFollowUpListCount] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = (_: unknown, _page: number) => {
    setFollowUpListPage(_page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setFollowUpListPage(0);
  };
  //#endregion

  //#region FETCH FOLLOW UP
  const fetchFollowUpList = useCallback(() => {
    dispatch(
      followUpListAction.fetch({
        page: followUpListPage,
        size: rowsPerPage,
        status: FollowUpStatus.WAITING,
        ...(selectedCheckmarksFilter.length === 0
          ? {}
          : {filter: selectedCheckmarksFilter.join()}),
        sort: selectedSort,
        ...(selectedDateFilter.filter((item) => item).length === 2
          ? {
              startDate: (selectedDateFilter[0] as Moment).format('YYYY-MM-DD'),
              endDate: (selectedDateFilter[1] as Moment).format('YYYY-MM-DD'),
            }
          : {}),
        ...(selectedToggleFilter === followUpFilterOption.NORMAL
          ? {isExpired: false}
          : selectedToggleFilter === followUpFilterOption.EXPIRED
          ? {isExpired: true}
          : {}),
      }),
    );
  }, [
    dispatch,
    followUpListPage,
    rowsPerPage,
    selectedCheckmarksFilter,
    selectedDateFilter,
    selectedSort,
    selectedToggleFilter,
  ]);
  //#endregion
  //#endregion

  //#region REFRESH LIST
  const refresh = useCallback(() => {
    dispatch(followUpListAction.reset());
    fetchFollowUpList();

    return () => {
      dispatch(followUpListAction.reset());
    };
  }, [dispatch, fetchFollowUpList]);

  useEffect(() => {
    refresh();
  }, [refresh]);
  //#endregion

  //#region ACTION HANDLER
  const followUpWaitingActionHandler = useCallback(
    (_action: string) => {
      actionHandler(_action, (builder) => {
        builder
          .addCase(FollowUpListAction.SUCCESS, (): void => {
            const totalElement =
              followUpState.followUpListResponse?.data?.followUps
                ?.totalElements;
            setFollowUpListCount(totalElement ?? 0);
          })
          .addCase(FollowUpListAction.FAILED, (): void => {
            dispatch(
              wrapperNotifyAction.fetch({
                text: t('Data fetch failed!'),
                type: 'error',
                action: WrapperNotifyAction.FETCH,
              }),
            );
            dispatch(followUpListAction.reset());
          });
      });
    },
    [
      dispatch,
      followUpState.followUpListResponse?.data?.followUps?.totalElements,
    ],
  );
  useEffect(() => {
    followUpWaitingActionHandler(followUpState.action);
  }, [followUpState.action, followUpWaitingActionHandler]);
  //#endregion

  //#region DATA LOAD
  const emptyData = () => {
    return (
      <div className="follow-up-waiting-section__content--empty">
        <FollowUpEmpty text={t('No follow ups are being verified')} />
      </div>
    );
  };

  const loadingData = () => {
    return (
      <div className="follow-up-waiting-section__content--loading">
        <MSpinner size={40} />
      </div>
    );
  };

  const dataLoad = () => {
    if (isLoadingFollowUp) {
      return loadingData();
    } else {
      if (followUpState.followUpListData.length === 0) return emptyData();
      return (
        <div className="follow-up-waiting-section__content--list">
          <FollowUpWaitingList
            data={followUpState.followUpListData}
            onViewItem={(_followUp: FollowUp) => setIsViewFollowUp(_followUp)}
          />
          <MTablePagination
            className="follow-up-list-pagination"
            rowsPerPageOptions={[20, 30, 40]}
            count={followUpListCount}
            rowsPerPage={rowsPerPage}
            page={followUpListPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      );
    }
  };
  //#endregion

  return (
    <>
      <div className="follow-up-waiting-section">
        <div className="follow-up-waiting-section__filter">
          <FollowUpFilter
            selectedToggleFilter={selectedToggleFilter}
            handleChangeToggleFilter={(
              e: React.MouseEvent<HTMLElement>,
              value: string | null,
            ) => {
              setFollowUpListPage(0);
              setSelectedToggleFilter(value);
            }}
            selectedCheckmarksFilter={selectedCheckmarksFilter}
            checkmarksFilterPlaceholder={t('Choose Type')}
            handleChangeCheckmarksFilter={(value: string) => {
              setFollowUpListPage(0);
              setSelectedCheckmarksFilter(
                typeof value === 'string' ? value.split(',') : value,
              );
            }}
            selectedDateFilter={selectedDateFilter}
            handleChangeDateFilter={(date: RangeInput<unknown>) => {
              setFollowUpListPage(0);
              setSelectedDateFilter(date);
            }}
            handleResetFilter={() => {
              setFollowUpListPage(0);
              setSelectedToggleFilter(followUpFilterOption.ALL);
              setSelectedCheckmarksFilter([
                AssignmentType.HALTE,
                AssignmentType.BUSBRT,
                AssignmentType.BUSNONBRT,
                AssignmentType.BUSKECIL,
                AssignmentType.TRANSCARE,
              ]);
              setSelectedDateFilter([null, null]);
            }}
            selectedSort={selectedSort}
            handleSort={(sort) => {
              setSelectedSort(sort as SortType);
            }}
          />
        </div>
        <div className="follow-up-waiting-section__content">{dataLoad()}</div>
      </div>

      {isViewFollowUp && (
        <Modal
          onClose={() => setIsViewFollowUp(undefined)}
          isOpen={!!isViewFollowUp}
          maxWidth="md">
          <FollowUpWaitingDetail detailData={isViewFollowUp} />
        </Modal>
      )}
    </>
  );
};

export default FollowUpWaitingSection;
