import {GenericAction} from '../../../../../../config/constant';
import {MasterDataState} from '../../../masterData.reducer';
import {masterDataAssignmentLetterDeleteInitialState} from './masterDataAssignmentLetterDelete.reducer';
import {
  MasterDataAssignmentLetterDeleteAction,
  MasterDataAssignmentLetterDeleteFailedPayload,
  MasterDataAssignmentLetterDeleteFetchPayload,
  MasterDataAssignmentLetterDeleteSuccessPayload,
} from './masterDataAssignmentLetterDelete.type';

export const masterDataAssignmentLetterDeleteHandler = {
  [MasterDataAssignmentLetterDeleteAction.FETCH]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterDeleteFetchPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterDeleteLoading: true,
    masterDataAssignmentLetterDeleteParams: action.payload,
    action: MasterDataAssignmentLetterDeleteAction.FETCH,
  }),
  [MasterDataAssignmentLetterDeleteAction.SUCCESS]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterDeleteSuccessPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterDeleteParams: undefined,
    masterDataAssignmentLetterDeleteLoading: false,
    masterDataAssignmentLetterDeleteResponse: action.payload,
    action: MasterDataAssignmentLetterDeleteAction.SUCCESS,
  }),
  [MasterDataAssignmentLetterDeleteAction.FAILED]: (
    state: MasterDataState,
    action: GenericAction<MasterDataAssignmentLetterDeleteFailedPayload>,
  ): MasterDataState => ({
    ...state,
    masterDataAssignmentLetterDeleteLoading: false,
    masterDataAssignmentLetterDeleteError: action.payload,
    action: MasterDataAssignmentLetterDeleteAction.FAILED,
  }),
  [MasterDataAssignmentLetterDeleteAction.RESET]: (
    state: MasterDataState,
  ): MasterDataState => ({
    ...state,
    ...masterDataAssignmentLetterDeleteInitialState,
    action: MasterDataAssignmentLetterDeleteAction.RESET,
  }),
};
