import {CommonError} from '../../../../../../config/constant';
import {MasterDataCategoryFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataCategoryCreateAction = {
  FETCH: 'MASTER_DATA_CATEGORY_CREATE_FETCH',
  SUCCESS: 'MASTER_DATA_CATEGORY_CREATE_SUCCESS',
  FAILED: 'MASTER_DATA_CATEGORY_CREATE_FAILED',
  RESET: 'MASTER_DATA_CATEGORY_CREATE_RESET',
};

export interface MasterDataCategoryCreateInitialState {
  masterDataCategoryCreateLoading: boolean;
  masterDataCategoryCreateParams?: MasterDataCategoryCreateFetchPayload;
  masterDataCategoryCreateError?: MasterDataCategoryCreateFailedPayload;
  masterDataCategoryCreateResponse?: MasterDataCategoryCreateSuccessPayload;
}

export type MasterDataCategoryCreateFetchPayload = Omit<
  MasterDataCategoryFormData,
  'id'
>[];

export type MasterDataCategoryCreateFailedPayload = CommonError;

export interface MasterDataCategoryCreateSuccessPayload {}
