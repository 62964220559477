import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataReviewIndicatorDeleteAction,
  MasterDataReviewIndicatorDeleteFailedPayload,
  MasterDataReviewIndicatorDeleteFetchPayload,
  MasterDataReviewIndicatorDeleteSuccessPayload,
} from './masterDataReviewIndicatorDelete.type';

export const masterDataReviewIndicatorDeleteAction = {
  fetch: createAction<MasterDataReviewIndicatorDeleteFetchPayload>(
    MasterDataReviewIndicatorDeleteAction.FETCH,
  ),
  success: createAction<MasterDataReviewIndicatorDeleteSuccessPayload>(
    MasterDataReviewIndicatorDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataReviewIndicatorDeleteFailedPayload>(
    MasterDataReviewIndicatorDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataReviewIndicatorDeleteAction.RESET),
};
