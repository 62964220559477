import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userChangePasswordInitialState} from './userChangePassword.reducer';
import {
  UserChangePasswordAction,
  UserChangePasswordFailedPayload,
  UserChangePasswordFetchPayload,
  UserChangePasswordSuccessPayload,
} from './userChangePassword.type';

export const userChangePasswordHandler = {
  [UserChangePasswordAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserChangePasswordFetchPayload>,
  ): UserState => ({
    ...state,
    userChangePasswordLoading: true,
    userChangePasswordParams: action.payload,
    action: UserChangePasswordAction.FETCH,
  }),
  [UserChangePasswordAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserChangePasswordSuccessPayload>,
  ): UserState => ({
    ...state,
    userChangePasswordParams: undefined,
    userChangePasswordLoading: false,
    userChangePasswordResponse: action.payload,
    action: UserChangePasswordAction.SUCCESS,
  }),
  [UserChangePasswordAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserChangePasswordFailedPayload>,
  ): UserState => ({
    ...state,
    userChangePasswordLoading: false,
    userChangePasswordError: action.payload,
    action: UserChangePasswordAction.FAILED,
  }),
  [UserChangePasswordAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userChangePasswordInitialState,
    action: UserChangePasswordAction.RESET,
  }),
};
