//#region IMPORT
// Libraries
import React from 'react';
import {Redirect} from 'react-router';
import {useSelector} from 'react-redux';
// Utils
import {AppState} from '../../../../config/Interface';
import {UserState} from '../../../user/usecases/user.reducer';
// Components
import {WorkReportSection, WorkReportAdminSection} from '../sections';
// Data
import {PATH} from '../../../wrapper/entity';
import {BackOfficePermissionType} from '../../../user/entity/user.string.entity';
// Assets
import './WorkReportManagementWrapper.scss';
//#endregion

const WorkReportManagementWrapper: React.FC = () => {
  //#region GENERAL
  const {data, userLevel}: UserState = useSelector(
    (state: AppState) => state.user,
  );
  const permissions: BackOfficePermissionType | undefined =
    data?.role?.permissions;
  const userLevelData: number = userLevel
    ? Number(userLevel?.replace('USERLEVEL', ''))
    : 8;
  //#endregion

  //#region HANDLE ROLE
  const getSection = () => {
    const isChiefOfDepartment =
      userLevelData === 4 &&
      !!permissions?.followUpTabApprove &&
      !!permissions?.followUpTabExpired;
    const isPIC =
      userLevelData === 6 &&
      !!permissions?.followUpTabApprove &&
      !!permissions?.followUpTabInProgress &&
      !!permissions?.followUpTabRejected &&
      !!permissions?.followUpTabWaitingForVerification;
    const isAdminSPM =
      userLevelData === 9 ||
      (userLevelData === 5 &&
        !!permissions?.followUpTabApprove &&
        !!permissions?.followUpTabInProgress);
    const isChiefofDivision = userLevelData === 3;

    if (!userLevelData) return <Redirect to={PATH.HOME} />;
    if (isChiefOfDepartment || isChiefofDivision || isPIC)
      return <WorkReportSection />;
    if (isAdminSPM) return <WorkReportAdminSection />;
    return <Redirect to={PATH.HOME} />;
  };
  //#endregion

  return <div className="work-report-management-wrapper">{getSection()}</div>;
};

export default WorkReportManagementWrapper;
