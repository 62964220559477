import {CommonError} from '../../../../../../config/constant';
import {MasterDataDirectorateFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataDirectorateEditAction = {
  FETCH: 'MASTER_DATA_DIRECTORATE_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_DIRECTORATE_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_DIRECTORATE_EDIT_FAILED',
  RESET: 'MASTER_DATA_DIRECTORATE_EDIT_RESET',
};

export interface MasterDataDirectorateEditInitialState {
  masterDataDirectorateEditLoading: boolean;
  masterDataDirectorateEditParams?: MasterDataDirectorateEditFetchPayload;
  masterDataDirectorateEditError?: MasterDataDirectorateEditFailedPayload;
  masterDataDirectorateEditResponse?: MasterDataDirectorateEditSuccessPayload;
}

export type MasterDataDirectorateEditFetchPayload = MasterDataDirectorateFormData;

export type MasterDataDirectorateEditFailedPayload = CommonError;

export interface MasterDataDirectorateEditSuccessPayload {}
