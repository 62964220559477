import {CommonError} from '../../../../../../config/constant';
import {MasterDataFormControllerFormData} from '../../../../entity/masterData.string.entity';

export const MasterDataFormControllerEditAction = {
  FETCH: 'MASTER_DATA_FORM_CONTROLLER_EDIT_FETCH',
  SUCCESS: 'MASTER_DATA_FORM_CONTROLLER_EDIT_SUCCESS',
  FAILED: 'MASTER_DATA_FORM_CONTROLLER_EDIT_FAILED',
  RESET: 'MASTER_DATA_FORM_CONTROLLER_EDIT_RESET',
};

export interface MasterDataFormControllerEditInitialState {
  masterDataFormControllerEditLoading: boolean;
  masterDataFormControllerEditParams?: MasterDataFormControllerEditFetchPayload;
  masterDataFormControllerEditError?: MasterDataFormControllerEditFailedPayload;
  masterDataFormControllerEditResponse?: MasterDataFormControllerEditSuccessPayload;
}

export type MasterDataFormControllerEditFetchPayload = MasterDataFormControllerFormData;

export type MasterDataFormControllerEditFailedPayload = CommonError;

export interface MasterDataFormControllerEditSuccessPayload {}
