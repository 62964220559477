import {CommonError} from '../../../../config/constant';
import {User, UserAccessFormData} from '../../entity/user.string.entity';

export const UserAccessCreateAction = {
  FETCH: 'USER_ACCESS_CREATE_FETCH',
  SUCCESS: 'USER_ACCESS_CREATE_SUCCESS',
  FAILED: 'USER_ACCESS_CREATE_FAILED',
  RESET: 'USER_ACCESS_CREATE_RESET',
};

export interface UserAccessCreateInitialState {
  userAccessCreateLoading: boolean;
  userAccessCreateParams?: UserAccessCreateFetchPayload;
  userAccessCreateError?: UserAccessCreateFailedPayload;
  userAccessCreateResponse?: UserAccessCreateSuccessPayload;
}

export type UserAccessCreateFetchPayload = Omit<UserAccessFormData, 'id'>;

export type UserAccessCreateFailedPayload = CommonError;

export type UserAccessCreateSuccessPayload = {
  data: User;
};
