import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataDivisionListAction,
  MasterDataDivisionListFailedPayload,
  MasterDataDivisionListFetchPayload,
  MasterDataDivisionListSuccessPayload,
  MasterDataDivisionListUpdatePayload,
} from './masterDataDivisionList.type';

export const masterDataDivisionListAction = {
  fetch: createAction<MasterDataDivisionListFetchPayload>(
    MasterDataDivisionListAction.FETCH,
  ),
  success: createAction<MasterDataDivisionListSuccessPayload>(
    MasterDataDivisionListAction.SUCCESS,
  ),
  failed: createAction<MasterDataDivisionListFailedPayload>(
    MasterDataDivisionListAction.FAILED,
  ),
  update: createAction<MasterDataDivisionListUpdatePayload>(
    MasterDataDivisionListAction.UPDATE,
  ),
  reset: createAction(MasterDataDivisionListAction.RESET),
};
