import {createAction} from '@reduxjs/toolkit';
import {
  MasterDataFormControllerDeleteAction,
  MasterDataFormControllerDeleteFailedPayload,
  MasterDataFormControllerDeleteFetchPayload,
  MasterDataFormControllerDeleteSuccessPayload,
} from './masterDataFormControllerDelete.type';

export const masterDataFormControllerDeleteAction = {
  fetch: createAction<MasterDataFormControllerDeleteFetchPayload>(
    MasterDataFormControllerDeleteAction.FETCH,
  ),
  success: createAction<MasterDataFormControllerDeleteSuccessPayload>(
    MasterDataFormControllerDeleteAction.SUCCESS,
  ),
  failed: createAction<MasterDataFormControllerDeleteFailedPayload>(
    MasterDataFormControllerDeleteAction.FAILED,
  ),
  reset: createAction(MasterDataFormControllerDeleteAction.RESET),
};
