import React from 'react';
import {Checkbox, ListItemText, MenuItem, Select} from '@mui/material';
import t from '../../lang';
import {colors} from '../../config/_theme';
import {OptionType} from '../../modules/wrapper/entity';
import './MCheckMarks.component.style.scss';
import {
  IconCaretArrowDown,
  IconCheckboxFilled,
  IconCheckboxOutline,
} from '../../assets/icons';

interface Props {
  className?: string;
  selected: string[];
  options: OptionType[];
  placeholder?: string;
  iconComponent?: React.ElementType;
  handleChangeCheckmarks?(e: unknown): void;
}

const MCheckMarks: React.FC<Props> = ({
  className,
  selected,
  options,
  placeholder,
  iconComponent = IconCaretArrowDown,
  handleChangeCheckmarks,
  ...otherProps
}: Props) => {
  return (
    <div className={`m-checkmarks ${className}`}>
      <Select
        className="m-checkmarks__field"
        placeholder={placeholder}
        displayEmpty
        multiple
        value={selected}
        IconComponent={iconComponent}
        onChange={(e) => {
          const val = e?.target?.value;
          handleChangeCheckmarks && handleChangeCheckmarks(val);
        }}
        renderValue={(selected) => {
          const selectedLabel = options
            .filter((optionItem) =>
              selected.some(
                (selectedItem) => selectedItem === optionItem.value,
              ),
            )
            .map((item) => item.label);
          if (selectedLabel.length === 0) {
            return (
              <div className="m-checkmarks__field--placeholder">
                {placeholder}
              </div>
            );
          } else if (selectedLabel.length > 2) {
            const first = selectedLabel[0];
            return `${first} & ${selectedLabel.length - 1} ${t('others')}`;
          }
          return selectedLabel.join(', ');
        }}
        {...otherProps}>
        {options.map((_option: OptionType, idx: number) => (
          <MenuItem
            key={`option-item-${_option}-${idx}`}
            className="m-checkmarks__field--item"
            value={_option.value}>
            <Checkbox
              checked={selected.indexOf(_option.value) > -1}
              icon={<IconCheckboxOutline />}
              checkedIcon={<IconCheckboxFilled fill={colors.primary.blue} />}
            />
            <ListItemText primary={t(_option.label)} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default MCheckMarks;
