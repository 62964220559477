import * as React from 'react';

const IconChecklist = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.64 5.232a1 1 0 0 1 .128 1.408l-10 12a1 1 0 0 1-1.475.067l-6-6a1 1 0 1 1 1.414-1.414l5.226 5.226 9.299-11.16a1 1 0 0 1 1.408-.127Z"
      fill={props.fill || '#BDBDBD'}
    />
  </svg>
);

export default IconChecklist;
