//#region IMPORT
// Libraries
import React from 'react';
import {Avatar, AvatarGroup} from '@mui/material';
// Utils
import t from '../../../../lang';
import {toInitial} from '../../../../utils';
// Data
import {DashboardCommentData} from '../../entity/dashboard.string.entity';
// Assets
import './DashboardCommentPreview.component.style.scss';
//#endregion

//#region INTERFACE
interface Props {
  data: DashboardCommentData[];
  maxAvatar?: number;
  handleShowAllComment?(): void;
}
//#endregion

const DashboardCommentPreview: React.FC<Props> = ({
  data,
  maxAvatar = 2,
  handleShowAllComment,
}: Props) => {
  const getCommentator = (data: DashboardCommentData[]): string => {
    if (data.length == 1) return data[0].userName;
    else if (data.length == 2)
      return `${data[0].userName} ${t('and')} ${data[1].userName}`;
    else if (data.length > 2)
      return `${data[0].userName} ${t('and')} ${data.length - 1} ${t('more')}`;
    return '';
  };

  return (
    <div
      className="dashboard-comment-preview"
      onClick={(e) => {
        e.stopPropagation();
        handleShowAllComment && handleShowAllComment();
      }}>
      <div className="dashboard-comment-preview__icon">
        <AvatarGroup max={maxAvatar}>
          {data.map((item: DashboardCommentData, idx: number) => {
            const initialAvatar: string = toInitial(item.userName);
            return item.profilePicture ? (
              <Avatar
                className="dashboard-comment-preview__icon--avatar"
                key={`dashboard-comment-preview__icon-${item.id}-${idx}`}
                alt={item.userName}
                src={item.profilePicture}
              />
            ) : (
              <Avatar
                className="dashboard-comment-preview__icon--avatar"
                key={`dashboard-comment-preview__icon-${item.id}-${idx}`}
                alt={item.userName}>
                {initialAvatar}
              </Avatar>
            );
          })}
        </AvatarGroup>
      </div>
      <div className="dashboard-comment-preview__content">
        <span>{getCommentator(data)}</span> {t('comment on this chart')}
      </div>
    </div>
  );
};
export default DashboardCommentPreview;
