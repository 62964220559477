import {GenericAction} from '../../../../config/constant';
import {UserState} from '../user.reducer';
import {userRoleAccessDeleteInitialState} from './userRoleAccessDelete.reducer';
import {
  UserRoleAccessDeleteAction,
  UserRoleAccessDeleteFailedPayload,
  UserRoleAccessDeleteFetchPayload,
  UserRoleAccessDeleteSuccessPayload,
} from './userRoleAccessDelete.type';

export const userRoleAccessDeleteHandler = {
  [UserRoleAccessDeleteAction.FETCH]: (
    state: UserState,
    action: GenericAction<UserRoleAccessDeleteFetchPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessDeleteLoading: true,
    userRoleAccessDeleteParams: action.payload,
    action: UserRoleAccessDeleteAction.FETCH,
  }),
  [UserRoleAccessDeleteAction.SUCCESS]: (
    state: UserState,
    action: GenericAction<UserRoleAccessDeleteSuccessPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessDeleteParams: undefined,
    userRoleAccessDeleteLoading: false,
    userRoleAccessDeleteResponse: action.payload,
    action: UserRoleAccessDeleteAction.SUCCESS,
  }),
  [UserRoleAccessDeleteAction.FAILED]: (
    state: UserState,
    action: GenericAction<UserRoleAccessDeleteFailedPayload>,
  ): UserState => ({
    ...state,
    userRoleAccessDeleteLoading: false,
    userRoleAccessDeleteError: action.payload,
    action: UserRoleAccessDeleteAction.FAILED,
  }),
  [UserRoleAccessDeleteAction.RESET]: (state: UserState): UserState => ({
    ...state,
    ...userRoleAccessDeleteInitialState,
    action: UserRoleAccessDeleteAction.RESET,
  }),
};
